import Select from 'react-select';
import { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { useSearchParams } from 'react-router-dom';


const options = [
    { value: "Alchemist", label: "Alchemist" },
    { value: "Axe", label: "Axe" },
    { value: "Bristleback", label: "Bristleback" },
    { value: "Centaur Warrunner", label: "Centaur Warrunner" },
    { value: "Chaos Knight", label: "Chaos Knight" },
    { value: "Dawnbreaker", label: "Dawnbreaker" },
    { value: "Doom", label: "Doom" },
    { value: "Dragon Knight", label: "Dragon Knight" },
    { value: "Earth Spirit", label: "Earth Spirit" },
    { value: "Earthshaker", label: "Earthshaker" },
    { value: "Elder Titan", label: "Elder Titan" },
    { value: "Huskar", label: "Huskar" },
    { value: "Kunkka", label: "Kunkka" },
    { value: "Legion Commander", label: "Legion Commander" },
    { value: "Lifestealer", label: "Lifestealer" },
    { value: "Mars", label: "Mars" },
    { value: "Night Stalker", label: "Night Stalker" },
    { value: "Ogre Magi", label: "Ogre Magi" },
    { value: "Omniknight", label: "Omniknight" },
    { value: "Primal Beast", label: "Primal Beast" },
    { value: "Pudge", label: "Pudge" },
    { value: "Slardar", label: "Slardar" },
    { value: "Spirit Breaker", label: "Spirit Breaker" },
    { value: "Sven", label: "Sven" },
    { value: "Tidehunter", label: "Tidehunter" },
    { value: "Tiny", label: "Tiny" },
    { value: "Treant Protector", label: "Treant Protector" },
    { value: "Tusk", label: "Tusk" },
    { value: "Underlord", label: "Underlord" },
    { value: "Undying", label: "Undying" },
    { value: "Wraith King", label: "Wraith King" },
    { value: "Anti-Mage", label: "Anti-Mage" },
    { value: "Arc Warden", label: "Arc Warden" },
    { value: "Bloodseeker", label: "Bloodseeker" },
    { value: "Bounty Hunter", label: "Bounty Hunter" },
    { value: "Clinkz", label: "Clinkz" },
    { value: "Drow Ranger", label: "Drow Ranger" },
    { value: "Ember Spirit", label: "Ember Spirit" },
    { value: "Faceless Void", label: "Faceless Void" },
    { value: "Gyrocopter", label: "Gyrocopter" },
    { value: "Hoodwink", label: "Hoodwink" },
    { value: "Juggernaut", label: "Juggernaut" },
    { value: "Luna", label: "Luna" },
    { value: "Medusa", label: "Medusa" },
    { value: "Meepo", label: "Meepo" },
    { value: "Monkey King", label: "Monkey King" },
    { value: "Morphling", label: "Morphling" },
    { value: "Naga Siren", label: "Naga Siren" },
    { value: "Phantom Assassin", label: "Phantom Assassin" },
    { value: "Phantom Lancer", label: "Phantom Lancer" },
    { value: "Razor", label: "Razor" },
    { value: "Riki", label: "Riki" },
    { value: "Shadow Fiend", label: "Shadow Fiend" },
    { value: "Slark", label: "Slark" },
    { value: "Sniper", label: "Sniper" },
    { value: "Spectre", label: "Spectre" },
    { value: "Templar Assassin", label: "Templar Assassin" },
    { value: "Terrorblade", label: "Terrorblade" },
    { value: "Troll Warlord", label: "Troll Warlord" },
    { value: "Ursa", label: "Ursa" },
    { value: "Viper", label: "Viper" },
    { value: "Weaver", label: "Weaver" },
    { value: "Ancient Apparition", label: "Ancient Apparition" },
    { value: "Crystal Maiden", label: "Crystal Maiden" },
    { value: "Death Prophet", label: "Death Prophet" },
    { value: "Disruptor", label: "Disruptor" },
    { value: "Enchantress", label: "Enchantress" },
    { value: "Grimstroke", label: "Grimstroke" },
    { value: "Jakiro", label: "Jakiro" },
    { value: "Keeper of the Light", label: "Keeper of the Light" },
    { value: "Leshrac", label: "Leshrac" },
    { value: "Lich", label: "Lich" },
    { value: "Lina", label: "Lina" },
    { value: "Lion", label: "Lion" },
    { value: "Muerta", label: "Muerta" },
    { value: "Nature's Prophet", label: "Nature's Prophet" },
    { value: "Necrophos", label: "Necrophos" },
    { value: "Oracle", label: "Oracle" },
    { value: "Outworld Destroyer", label: "Outworld Destroyer" },
    { value: "Puck", label: "Puck" },
    { value: "Pugna", label: "Pugna" },
    { value: "Queen of Pain", label: "Queen of Pain" },
    { value: "Ringmaster", label: "Ringmaster"},
    { value: "Rubick", label: "Rubick" },
    { value: "Shadow Demon", label: "Shadow Demon" },
    { value: "Shadow Shaman", label: "Shadow Shaman" },
    { value: "Silencer", label: "Silencer" },
    { value: "Skywrath Mage", label: "Skywrath Mage" },
    { value: "Storm Spirit", label: "Storm Spirit" },
    { value: "Tinker", label: "Tinker" },
    { value: "Warlock", label: "Warlock" },
    { value: "Witch Doctor", label: "Witch Doctor" },
    { value: "Zeus", label: "Zeus" },
    { value: "Abaddon", label: "Abaddon" },
    { value: "Bane", label: "Bane" },
    { value: "Batrider", label: "Batrider" },
    { value: "Beastmaster", label: "Beastmaster" },
    { value: "Brewmaster", label: "Brewmaster" },
    { value: "Broodmother", label: "Broodmother" },
    { value: "Chen", label: "Chen" },
    { value: "Clockwerk", label: "Clockwerk" },
    { value: "Dark Seer", label: "Dark Seer" },
    { value: "Dark Willow", label: "Dark Willow" },
    { value: "Dazzle", label: "Dazzle" },
    { value: "Enigma", label: "Enigma" },
    { value: "Invoker", label: "Invoker" },
    { value: "Io", label: "Io" },
    { value: "Lone Druid", label: "Lone Druid" },
    { value: "Lycan", label: "Lycan" },
    { value: "Magnus", label: "Magnus" },
    { value: "Marci", label: "Marci" },
    { value: "Mirana", label: "Mirana" },
    { value: "Nyx Assassin", label: "Nyx Assassin" },
    { value: "Pangolier", label: "Pangolier" },
    { value: "Phoenix", label: "Phoenix" },
    { value: "Sand King", label: "Sand King" },
    { value: "Snapfire", label: "Snapfire" },
    { value: "Techies", label: "Techies" },
    { value: "Timbersaw", label: "Timbersaw" },
    { value: "Vengeful Spirit", label: "Vengeful Spirit" },
    { value: "Venomancer", label: "Venomancer" },
    { value: "Visage", label: "Visage" },
    { value: "Void Spirit", label: "Void Spirit" },
    { value: "Windranger", label: "Windranger" },
    { value: "Winter Wyvern", label: "Winter Wyvern" },
]

const allColumns = {
    "Alchemist": {
        name: "Alchemist",
        selector: row => row["Alchemist"],
        sortable: true,
    },
    "Axe": {
        name: "Axe",
        selector: row => row["Axe"],
        sortable: true,
    },
    "Bristleback": {
        name: "Bristleback",
        selector: row => row["Bristleback"],
        sortable: true,
    },
    "Centaur Warrunner": {
        name: "Centaur Warrunner",
        selector: row => row["Centaur Warrunner"],
        sortable: true,
    },
    "Chaos Knight": {
        name: "Chaos Knight",
        selector: row => row["Chaos Knight"],
        sortable: true,
    },
    "Dawnbreaker": {
        name: "Dawnbreaker",
        selector: row => row["Dawnbreaker"],
        sortable: true,
    },
    "Doom": {
        name: "Doom",
        selector: row => row["Doom"],
        sortable: true,
    },
    "Dragon Knight": {
        name: "Dragon Knight",
        selector: row => row["Dragon Knight"],
        sortable: true,
    },
    "Earth Spirit": {
        name: "Earth Spirit",
        selector: row => row["Earth Spirit"],
        sortable: true,
    },
    "Earthshaker": {
        name: "Earthshaker",
        selector: row => row["Earthshaker"],
        sortable: true,
    },
    "Elder Titan": {
        name: "Elder Titan",
        selector: row => row["Elder Titan"],
        sortable: true,
    },
    "Huskar": {
        name: "Huskar",
        selector: row => row["Huskar"],
        sortable: true,
    },
    "Kunkka": {
        name: "Kunkka",
        selector: row => row["Kunkka"],
        sortable: true,
    },
    "Legion Commander": {
        name: "Legion Commander",
        selector: row => row["Legion Commander"],
        sortable: true,
    },
    "Lifestealer": {
        name: "Lifestealer",
        selector: row => row["Lifestealer"],
        sortable: true,
    },
    "Mars": {
        name: "Mars",
        selector: row => row["Mars"],
        sortable: true,
    },
    "Night Stalker": {
        name: "Night Stalker",
        selector: row => row["Night Stalker"],
        sortable: true,
    },
    "Ogre Magi": {
        name: "Ogre Magi",
        selector: row => row["Ogre Magi"],
        sortable: true,
    },
    "Omniknight": {
        name: "Omniknight",
        selector: row => row["Omniknight"],
        sortable: true,
    },
    "Primal Beast": {
        name: "Primal Beast",
        selector: row => row["Primal Beast"],
        sortable: true,
    },
    "Pudge": {
        name: "Pudge",
        selector: row => row["Pudge"],
        sortable: true,
    },
    "Slardar": {
        name: "Slardar",
        selector: row => row["Slardar"],
        sortable: true,
    },
    "Spirit Breaker": {
        name: "Spirit Breaker",
        selector: row => row["Spirit Breaker"],
        sortable: true,
    },
    "Sven": {
        name: "Sven",
        selector: row => row["Sven"],
        sortable: true,
    },
    "Tidehunter": {
        name: "Tidehunter",
        selector: row => row["Tidehunter"],
        sortable: true,
    },
    "Tiny": {
        name: "Tiny",
        selector: row => row["Tiny"],
        sortable: true,
    },
    "Treant Protector": {
        name: "Treant Protector",
        selector: row => row["Treant Protector"],
        sortable: true,
    },
    "Tusk": {
        name: "Tusk",
        selector: row => row["Tusk"],
        sortable: true,
    },
    "Underlord": {
        name: "Underlord",
        selector: row => row["Underlord"],
        sortable: true,
    },
    "Undying": {
        name: "Undying",
        selector: row => row["Undying"],
        sortable: true,
    },
    "Wraith King": {
        name: "Wraith King",
        selector: row => row["Wraith King"],
        sortable: true,
    },
    "Anti-Mage": {
        name: "Anti-Mage",
        selector: row => row["Anti-Mage"],
        sortable: true,
    },
    "Arc Warden": {
        name: "Arc Warden",
        selector: row => row["Arc Warden"],
        sortable: true,
    },
    "Bloodseeker": {
        name: "Bloodseeker",
        selector: row => row["Bloodseeker"],
        sortable: true,
    },
    "Bounty Hunter": {
        name: "Bounty Hunter",
        selector: row => row["Bounty Hunter"],
        sortable: true,
    },
    "Clinkz": {
        name: "Clinkz",
        selector: row => row["Clinkz"],
        sortable: true,
    },
    "Drow Ranger": {
        name: "Drow Ranger",
        selector: row => row["Drow Ranger"],
        sortable: true,
    },
    "Ember Spirit": {
        name: "Ember Spirit",
        selector: row => row["Ember Spirit"],
        sortable: true,
    },
    "Faceless Void": {
        name: "Faceless Void",
        selector: row => row["Faceless Void"],
        sortable: true,
    },
    "Gyrocopter": {
        name: "Gyrocopter",
        selector: row => row["Gyrocopter"],
        sortable: true,
    },
    "Hoodwink": {
        name: "Hoodwink",
        selector: row => row["Hoodwink"],
        sortable: true,
    },
    "Juggernaut": {
        name: "Juggernaut",
        selector: row => row["Juggernaut"],
        sortable: true,
    },
    "Luna": {
        name: "Luna",
        selector: row => row["Luna"],
        sortable: true,
    },
    "Medusa": {
        name: "Medusa",
        selector: row => row["Medusa"],
        sortable: true,
    },
    "Meepo": {
        name: "Meepo",
        selector: row => row["Meepo"],
        sortable: true,
    },
    "Monkey King": {
        name: "Monkey King",
        selector: row => row["Monkey King"],
        sortable: true,
    },
    "Morphling": {
        name: "Morphling",
        selector: row => row["Morphling"],
        sortable: true,
    },
    "Naga Siren": {
        name: "Naga Siren",
        selector: row => row["Naga Siren"],
        sortable: true,
    },
    "Phantom Assassin": {
        name: "Phantom Assassin",
        selector: row => row["Phantom Assassin"],
        sortable: true,
    },
    "Phantom Lancer": {
        name: "Phantom Lancer",
        selector: row => row["Phantom Lancer"],
        sortable: true,
    },
    "Razor": {
        name: "Razor",
        selector: row => row["Razor"],
        sortable: true,
    },
    "Riki": {
        name: "Riki",
        selector: row => row["Riki"],
        sortable: true,
    },
    "Shadow Fiend": {
        name: "Shadow Fiend",
        selector: row => row["Shadow Fiend"],
        sortable: true,
    },
    "Slark": {
        name: "Slark",
        selector: row => row["Slark"],
        sortable: true,
    },
    "Sniper": {
        name: "Sniper",
        selector: row => row["Sniper"],
        sortable: true,
    },
    "Spectre": {
        name: "Spectre",
        selector: row => row["Spectre"],
        sortable: true,
    },
    "Templar Assassin": {
        name: "Templar Assassin",
        selector: row => row["Templar Assassin"],
        sortable: true,
    },
    "Terrorblade": {
        name: "Terrorblade",
        selector: row => row["Terrorblade"],
        sortable: true,
    },
    "Troll Warlord": {
        name: "Troll Warlord",
        selector: row => row["Troll Warlord"],
        sortable: true,
    },
    "Ursa": {
        name: "Ursa",
        selector: row => row["Ursa"],
        sortable: true,
    },
    "Viper": {
        name: "Viper",
        selector: row => row["Viper"],
        sortable: true,
    },
    "Weaver": {
        name: "Weaver",
        selector: row => row["Weaver"],
        sortable: true,
    },
    "Ancient Apparition": {
        name: "Ancient Apparition",
        selector: row => row["Ancient Apparition"],
        sortable: true,
    },
    "Crystal Maiden": {
        name: "Crystal Maiden",
        selector: row => row["Crystal Maiden"],
        sortable: true,
    },
    "Death Prophet": {
        name: "Death Prophet",
        selector: row => row["Death Prophet"],
        sortable: true,
    },
    "Disruptor": {
        name: "Disruptor",
        selector: row => row["Disruptor"],
        sortable: true,
    },
    "Enchantress": {
        name: "Enchantress",
        selector: row => row["Enchantress"],
        sortable: true,
    },
    "Grimstroke": {
        name: "Grimstroke",
        selector: row => row["Grimstroke"],
        sortable: true,
    },
    "Jakiro": {
        name: "Jakiro",
        selector: row => row["Jakiro"],
        sortable: true,
    },
    "Keeper of the Light": {
        name: "Keeper of the Light",
        selector: row => row["Keeper of the Light"],
        sortable: true,
    },
    "Leshrac": {
        name: "Leshrac",
        selector: row => row["Leshrac"],
        sortable: true,
    },
    "Lich": {
        name: "Lich",
        selector: row => row["Lich"],
        sortable: true,
    },
    "Lina": {
        name: "Lina",
        selector: row => row["Lina"],
        sortable: true,
    },
    "Lion": {
        name: "Lion",
        selector: row => row["Lion"],
        sortable: true,
    },
    "Muerta": {
        name: "Muerta",
        selector: row => row["Muerta"],
        sortable: true,
    },
    "Nature's Prophet": {
        name: "Nature's Prophet",
        selector: row => row["Nature's Prophet"],
        sortable: true,
    },
    "Necrophos": {
        name: "Necrophos",
        selector: row => row["Necrophos"],
        sortable: true,
    },
    "Oracle": {
        name: "Oracle",
        selector: row => row["Oracle"],
        sortable: true,
    },
    "Outworld Destroyer": {
        name: "Outworld Destroyer",
        selector: row => row["Outworld Destroyer"],
        sortable: true,
    },
    "Puck": {
        name: "Puck",
        selector: row => row["Puck"],
        sortable: true,
    },
    "Pugna": {
        name: "Pugna",
        selector: row => row["Pugna"],
        sortable: true,
    },
    "Queen of Pain": {
        name: "Queen of Pain",
        selector: row => row["Queen of Pain"],
        sortable: true,
    },
    "Ringmaster": {
        name: "Ringmaster",
        selector: row => row["Ringmaster"],
        sortable: true,
    },
    "Rubick": {
        name: "Rubick",
        selector: row => row["Rubick"],
        sortable: true,
    },
    "Shadow Demon": {
        name: "Shadow Demon",
        selector: row => row["Shadow Demon"],
        sortable: true,
    },
    "Shadow Shaman": {
        name: "Shadow Shaman",
        selector: row => row["Shadow Shaman"],
        sortable: true,
    },
    "Silencer": {
        name: "Silencer",
        selector: row => row["Silencer"],
        sortable: true,
    },
    "Skywrath Mage": {
        name: "Skywrath Mage",
        selector: row => row["Skywrath Mage"],
        sortable: true,
    },
    "Storm Spirit": {
        name: "Storm Spirit",
        selector: row => row["Storm Spirit"],
        sortable: true,
    },
    "Tinker": {
        name: "Tinker",
        selector: row => row["Tinker"],
        sortable: true,
    },
    "Warlock": {
        name: "Warlock",
        selector: row => row["Warlock"],
        sortable: true,
    },
    "Witch Doctor": {
        name: "Witch Doctor",
        selector: row => row["Witch Doctor"],
        sortable: true,
    },
    "Zeus": {
        name: "Zeus",
        selector: row => row["Zeus"],
        sortable: true,
    },
    "Abaddon": {
        name: "Abaddon",
        selector: row => row["Abaddon"],
        sortable: true,
    },
    "Bane": {
        name: "Bane",
        selector: row => row["Bane"],
        sortable: true,
    },
    "Batrider": {
        name: "Batrider",
        selector: row => row["Batrider"],
        sortable: true,
    },
    "Beastmaster": {
        name: "Beastmaster",
        selector: row => row["Beastmaster"],
        sortable: true,
    },
    "Brewmaster": {
        name: "Brewmaster",
        selector: row => row["Brewmaster"],
        sortable: true,
    },
    "Broodmother": {
        name: "Broodmother",
        selector: row => row["Broodmother"],
        sortable: true,
    },
    "Chen": {
        name: "Chen",
        selector: row => row["Chen"],
        sortable: true,
    },
    "Clockwerk": {
        name: "Clockwerk",
        selector: row => row["Clockwerk"],
        sortable: true,
    },
    "Dark Seer": {
        name: "Dark Seer",
        selector: row => row["Dark Seer"],
        sortable: true,
    },
    "Dark Willow": {
        name: "Dark Willow",
        selector: row => row["Dark Willow"],
        sortable: true,
    },
    "Dazzle": {
        name: "Dazzle",
        selector: row => row["Dazzle"],
        sortable: true,
    },
    "Enigma": {
        name: "Enigma",
        selector: row => row["Enigma"],
        sortable: true,
    },
    "Invoker": {
        name: "Invoker",
        selector: row => row["Invoker"],
        sortable: true,
    },
    "Io": {
        name: "Io",
        selector: row => row["Io"],
        sortable: true,
    },
    "Lone Druid": {
        name: "Lone Druid",
        selector: row => row["Lone Druid"],
        sortable: true,
    },
    "Lycan": {
        name: "Lycan",
        selector: row => row["Lycan"],
        sortable: true,
    },
    "Magnus": {
        name: "Magnus",
        selector: row => row["Magnus"],
        sortable: true,
    },
    "Marci": {
        name: "Marci",
        selector: row => row["Marci"],
        sortable: true,
    },
    "Mirana": {
        name: "Mirana",
        selector: row => row["Mirana"],
        sortable: true,
    },
    "Nyx Assassin": {
        name: "Nyx Assassin",
        selector: row => row["Nyx Assassin"],
        sortable: true,
    },
    "Pangolier": {
        name: "Pangolier",
        selector: row => row["Pangolier"],
        sortable: true,
    },
    "Phoenix": {
        name: "Phoenix",
        selector: row => row["Phoenix"],
        sortable: true,
    },
    "Sand King": {
        name: "Sand King",
        selector: row => row["Sand King"],
        sortable: true,
    },
    "Snapfire": {
        name: "Snapfire",
        selector: row => row["Snapfire"],
        sortable: true,
    },
    "Techies": {
        name: "Techies",
        selector: row => row["Techies"],
        sortable: true,
    },
    "Timbersaw": {
        name: "Timbersaw",
        selector: row => row["Timbersaw"],
        sortable: true,
    },
    "Vengeful Spirit": {
        name: "Vengeful Spirit",
        selector: row => row["Vengeful Spirit"],
        sortable: true,
    },
    "Venomancer": {
        name: "Venomancer",
        selector: row => row["Venomancer"],
        sortable: true,
    },
    "Visage": {
        name: "Visage",
        selector: row => row["Visage"],
        sortable: true,
    },
    "Void Spirit": {
        name: "Void Spirit",
        selector: row => row["Void Spirit"],
        sortable: true,
    },
    "Windranger": {
        name: "Windranger",
        selector: row => row["Windranger"],
        sortable: true,
    },
    "Winter Wyvern": {
        name: "Winter Wyvern",
        selector: row => row["Winter Wyvern"],
        sortable: true,
    },
}


const allData = {
"Alchemist": [
    { name: 'Abaddon', 'Alchemist': -1.54 },
    { name: 'Ancient Apparition', 'Alchemist': 1.837 },
    { name: 'Anti-Mage', 'Alchemist': 0.478 },
    { name: 'Arc Warden', 'Alchemist': 0.481 },
    { name: 'Axe', 'Alchemist': 0.071 },
    { name: 'Bane', 'Alchemist': 0.94 },
    { name: 'Batrider', 'Alchemist': 1.831 },
    { name: 'Beastmaster', 'Alchemist': -0.505 },
    { name: 'Bloodseeker', 'Alchemist': 0.569 },
    { name: 'Bounty Hunter', 'Alchemist': 1.299 },
    { name: 'Brewmaster', 'Alchemist': -0.657 },
    { name: 'Bristleback', 'Alchemist': 1.388 },
    { name: 'Broodmother', 'Alchemist': -0.844 },
    { name: 'Centaur Warrunner', 'Alchemist': -2.147 },
    { name: 'Chaos Knight', 'Alchemist': -0.014 },
    { name: 'Chen', 'Alchemist': 0.227 },
    { name: 'Clinkz', 'Alchemist': 1.881 },
    { name: 'Clockwerk', 'Alchemist': -0.685 },
    { name: 'Crystal Maiden', 'Alchemist': 0.839 },
    { name: 'Dark Seer', 'Alchemist': 0.101 },
    { name: 'Dark Willow', 'Alchemist': -0.445 },
    { name: 'Dawnbreaker', 'Alchemist': -0.307 },
    { name: 'Dazzle', 'Alchemist': 0.41 },
    { name: 'Death Prophet', 'Alchemist': 0.354 },
    { name: 'Disruptor', 'Alchemist': -0.676 },
    { name: 'Doom', 'Alchemist': 1.613 },
    { name: 'Dragon Knight', 'Alchemist': -0.309 },
    { name: 'Drow Ranger', 'Alchemist': 0.737 },
    { name: 'Earth Spirit', 'Alchemist': -0.576 },
    { name: 'Earthshaker', 'Alchemist': -0.774 },
    { name: 'Elder Titan', 'Alchemist': 0.566 },
    { name: 'Ember Spirit', 'Alchemist': -1.229 },
    { name: 'Enchantress', 'Alchemist': 0.551 },
    { name: 'Enigma', 'Alchemist': -2.437 },
    { name: 'Faceless Void', 'Alchemist': -0.246 },
    { name: 'Grimstroke', 'Alchemist': 0.359 },
    { name: 'Gyrocopter', 'Alchemist': 0.23 },
    { name: 'Hoodwink', 'Alchemist': 0.678 },
    { name: 'Huskar', 'Alchemist': 1.498 },
    { name: 'Invoker', 'Alchemist': 0.417 },
    { name: 'Io', 'Alchemist': -0.125 },
    { name: 'Jakiro', 'Alchemist': 0.008 },
    { name: 'Juggernaut', 'Alchemist': 0.952 },
    { name: 'Keeper of the Light', 'Alchemist': 1.707 },
    { name: 'Kunkka', 'Alchemist': -1.697 },
    { name: 'Legion Commander', 'Alchemist': -0.211 },
    { name: 'Leshrac', 'Alchemist': 1.714 },
    { name: 'Lich', 'Alchemist': 0.299 },
    { name: 'Lifestealer', 'Alchemist': -0.196 },
    { name: 'Lina', 'Alchemist': 0.733 },
    { name: 'Lion', 'Alchemist': 0.303 },
    { name: 'Lone Druid', 'Alchemist': -0.125 },
    { name: 'Luna', 'Alchemist': 0.067 },
    { name: 'Lycan', 'Alchemist': -1.293 },
    { name: 'Magnus', 'Alchemist': -0.479 },
    { name: 'Marci', 'Alchemist': 0.558 },
    { name: 'Mars', 'Alchemist': -0.478 },
    { name: 'Medusa', 'Alchemist': -3.032 },
    { name: 'Meepo', 'Alchemist': -2.347 },
    { name: 'Mirana', 'Alchemist': 0.621 },
    { name: 'Monkey King', 'Alchemist': 1.741 },
    { name: 'Morphling', 'Alchemist': 0.353 },
    { name: 'Muerta', 'Alchemist': 1.385 },
    { name: 'Naga Siren', 'Alchemist': -1.076 },
    { name: "Nature's Prophet", 'Alchemist': 0.332 },
    { name: 'Necrophos', 'Alchemist': 0.293 },
    { name: 'Night Stalker', 'Alchemist': -0.581 },
    { name: 'Nyx Assassin', 'Alchemist': 1.256 },
    { name: 'Ogre Magi', 'Alchemist': 0.185 },
    { name: 'Omniknight', 'Alchemist': -0.015 },
    { name: 'Oracle', 'Alchemist': -1.711 },
    { name: 'Outworld Destroyer', 'Alchemist': 0.329 },
    { name: 'Pangolier', 'Alchemist': -0.685 },
    { name: 'Phantom Assassin', 'Alchemist': 1.163 },
    { name: 'Phantom Lancer', 'Alchemist': -0.335 },
    { name: 'Phoenix', 'Alchemist': -1.244 },
    { name: 'Primal Beast', 'Alchemist': 0.09 },
    { name: 'Puck', 'Alchemist': -0.985 },
    { name: 'Pudge', 'Alchemist': 0.303 },
    { name: 'Pugna', 'Alchemist': 0.11 },
    { name: 'Queen of Pain', 'Alchemist': 1.299 },
    { name: 'Razor', 'Alchemist': 0.67 },
    { name: 'Riki', 'Alchemist': -0.091 },
    { name: 'Ringmaster', 'Alchemist': -0.299 },
    { name: 'Rubick', 'Alchemist': -0.688 },
    { name: 'Sand King', 'Alchemist': 0.99 },
    { name: 'Shadow Demon', 'Alchemist': -0.099 },
    { name: 'Shadow Fiend', 'Alchemist': 0.2 },
    { name: 'Shadow Shaman', 'Alchemist': -1.75 },
    { name: 'Silencer', 'Alchemist': -0.979 },
    { name: 'Skywrath Mage', 'Alchemist': 0.49 },
    { name: 'Slardar', 'Alchemist': 0.578 },
    { name: 'Slark', 'Alchemist': 0.849 },
    { name: 'Snapfire', 'Alchemist': -0.268 },
    { name: 'Sniper', 'Alchemist': 0.604 },
    { name: 'Spectre', 'Alchemist': -0.499 },
    { name: 'Spirit Breaker', 'Alchemist': 0.398 },
    { name: 'Storm Spirit', 'Alchemist': 0.444 },
    { name: 'Sven', 'Alchemist': -0.701 },
    { name: 'Techies', 'Alchemist': 0.181 },
    { name: 'Templar Assassin', 'Alchemist': -0.807 },
    { name: 'Terrorblade', 'Alchemist': 1.806 },
    { name: 'Tidehunter', 'Alchemist': -1.128 },
    { name: 'Timbersaw', 'Alchemist': 1.317 },
    { name: 'Tinker', 'Alchemist': -1.626 },
    { name: 'Tiny', 'Alchemist': -1.489 },
    { name: 'Treant Protector', 'Alchemist': -0.225 },
    { name: 'Troll Warlord', 'Alchemist': 0.076 },
    { name: 'Tusk', 'Alchemist': 1.022 },
    { name: 'Underlord', 'Alchemist': -1.367 },
    { name: 'Undying', 'Alchemist': -0.525 },
    { name: 'Ursa', 'Alchemist': -0.812 },
    { name: 'Vengeful Spirit', 'Alchemist': -0.165 },
    { name: 'Venomancer', 'Alchemist': 0.748 },
    { name: 'Viper', 'Alchemist': 1.02 },
    { name: 'Visage', 'Alchemist': -1.241 },
    { name: 'Void Spirit', 'Alchemist': -2.054 },
    { name: 'Warlock', 'Alchemist': 0.054 },
    { name: 'Weaver', 'Alchemist': 0.525 },
    { name: 'Windranger', 'Alchemist': -0.366 },
    { name: 'Winter Wyvern', 'Alchemist': 0.836 },
    { name: 'Witch Doctor', 'Alchemist': 0.01 },
    { name: 'Wraith King', 'Alchemist': -2.477 },
    { name: 'Zeus', 'Alchemist': -0.092 },
],
"Axe": [
    { name: 'Abaddon', 'Axe': 2.457 },
    { name: 'Alchemist', 'Axe': 0.363 },
    { name: 'Ancient Apparition', 'Axe': 1.031 },
    { name: 'Anti-Mage', 'Axe': -4.605 },
    { name: 'Arc Warden', 'Axe': -2.119 },
    { name: 'Bane', 'Axe': 1.086 },
    { name: 'Batrider', 'Axe': 2.562 },
    { name: 'Beastmaster', 'Axe': 2.246 },
    { name: 'Bloodseeker', 'Axe': 1.127 },
    { name: 'Bounty Hunter', 'Axe': 0.437 },
    { name: 'Brewmaster', 'Axe': 0.362 },
    { name: 'Bristleback', 'Axe': -2.126 },
    { name: 'Broodmother', 'Axe': -2.874 },
    { name: 'Centaur Warrunner', 'Axe': 1.415 },
    { name: 'Chaos Knight', 'Axe': -1.437 },
    { name: 'Chen', 'Axe': -0.354 },
    { name: 'Clinkz', 'Axe': -0.986 },
    { name: 'Clockwerk', 'Axe': 1.042 },
    { name: 'Crystal Maiden', 'Axe': -0.11 },
    { name: 'Dark Seer', 'Axe': -0.766 },
    { name: 'Dark Willow', 'Axe': -1.583 },
    { name: 'Dawnbreaker', 'Axe': 0.455 },
    { name: 'Dazzle', 'Axe': -0.551 },
    { name: 'Death Prophet', 'Axe': 2.258 },
    { name: 'Disruptor', 'Axe': 0.202 },
    { name: 'Doom', 'Axe': 2.387 },
    { name: 'Dragon Knight', 'Axe': 2.206 },
    { name: 'Drow Ranger', 'Axe': 0.229 },
    { name: 'Earth Spirit', 'Axe': 1.573 },
    { name: 'Earthshaker', 'Axe': 0.07 },
    { name: 'Elder Titan', 'Axe': -0.487 },
    { name: 'Ember Spirit', 'Axe': 1.349 },
    { name: 'Enchantress', 'Axe': 0.765 },
    { name: 'Enigma', 'Axe': -0.969 },
    { name: 'Faceless Void', 'Axe': -0.397 },
    { name: 'Grimstroke', 'Axe': 0.653 },
    { name: 'Gyrocopter', 'Axe': 1.424 },
    { name: 'Hoodwink', 'Axe': -0.893 },
    { name: 'Huskar', 'Axe': -1.484 },
    { name: 'Invoker', 'Axe': 0.397 },
    { name: 'Io', 'Axe': 0.607 },
    { name: 'Jakiro', 'Axe': 0.987 },
    { name: 'Juggernaut', 'Axe': -0.584 },
    { name: 'Keeper of the Light', 'Axe': 1.672 },
    { name: 'Kunkka', 'Axe': 0.7 },
    { name: 'Legion Commander', 'Axe': -0.941 },
    { name: 'Leshrac', 'Axe': 3.683 },
    { name: 'Lich', 'Axe': 0.097 },
    { name: 'Lifestealer', 'Axe': 1.995 },
    { name: 'Lina', 'Axe': -1.785 },
    { name: 'Lion', 'Axe': -0.068 },
    { name: 'Lone Druid', 'Axe': 0.025 },
    { name: 'Luna', 'Axe': 0.734 },
    { name: 'Lycan', 'Axe': -1.639 },
    { name: 'Magnus', 'Axe': 0.532 },
    { name: 'Marci', 'Axe': -1.121 },
    { name: 'Mars', 'Axe': 0.753 },
    { name: 'Medusa', 'Axe': -1.031 },
    { name: 'Meepo', 'Axe': -0.597 },
    { name: 'Mirana', 'Axe': -0.584 },
    { name: 'Monkey King', 'Axe': 1.638 },
    { name: 'Morphling', 'Axe': -1.28 },
    { name: 'Muerta', 'Axe': -0.943 },
    { name: 'Naga Siren', 'Axe': -3.066 },
    { name: "Nature's Prophet", 'Axe': 0.499 },
    { name: 'Necrophos', 'Axe': 2.894 },
    { name: 'Night Stalker', 'Axe': 0.773 },
    { name: 'Nyx Assassin', 'Axe': -0.792 },
    { name: 'Ogre Magi', 'Axe': 0.94 },
    { name: 'Omniknight', 'Axe': 0.776 },
    { name: 'Oracle', 'Axe': -0.458 },
    { name: 'Outworld Destroyer', 'Axe': 3.268 },
    { name: 'Pangolier', 'Axe': 0.475 },
    { name: 'Phantom Assassin', 'Axe': -3.32 },
    { name: 'Phantom Lancer', 'Axe': -1.929 },
    { name: 'Phoenix', 'Axe': 1.608 },
    { name: 'Primal Beast', 'Axe': 2.551 },
    { name: 'Puck', 'Axe': -0.407 },
    { name: 'Pudge', 'Axe': 1.787 },
    { name: 'Pugna', 'Axe': 1.923 },
    { name: 'Queen of Pain', 'Axe': 1.356 },
    { name: 'Razor', 'Axe': 1.179 },
    { name: 'Riki', 'Axe': -0.706 },
    { name: 'Ringmaster', 'Axe': 1.207 },
    { name: 'Rubick', 'Axe': -0.949 },
    { name: 'Sand King', 'Axe': 1.201 },
    { name: 'Shadow Demon', 'Axe': 1.43 },
    { name: 'Shadow Fiend', 'Axe': 0.898 },
    { name: 'Shadow Shaman', 'Axe': -0.318 },
    { name: 'Silencer', 'Axe': 1.166 },
    { name: 'Skywrath Mage', 'Axe': 1.146 },
    { name: 'Slardar', 'Axe': 0.357 },
    { name: 'Slark', 'Axe': -0.14 },
    { name: 'Snapfire', 'Axe': 1.074 },
    { name: 'Sniper', 'Axe': -1.502 },
    { name: 'Spectre', 'Axe': 3.042 },
    { name: 'Spirit Breaker', 'Axe': 1.092 },
    { name: 'Storm Spirit', 'Axe': -1.001 },
    { name: 'Sven', 'Axe': 1.026 },
    { name: 'Techies', 'Axe': 0.642 },
    { name: 'Templar Assassin', 'Axe': 0.643 },
    { name: 'Terrorblade', 'Axe': -3.509 },
    { name: 'Tidehunter', 'Axe': 0.846 },
    { name: 'Timbersaw', 'Axe': 2.859 },
    { name: 'Tinker', 'Axe': 0.933 },
    { name: 'Tiny', 'Axe': 2.206 },
    { name: 'Treant Protector', 'Axe': 1.043 },
    { name: 'Troll Warlord', 'Axe': -3.966 },
    { name: 'Tusk', 'Axe': 1.051 },
    { name: 'Underlord', 'Axe': 0.531 },
    { name: 'Undying', 'Axe': -0.208 },
    { name: 'Ursa', 'Axe': 1.269 },
    { name: 'Vengeful Spirit', 'Axe': 0.102 },
    { name: 'Venomancer', 'Axe': 3.521 },
    { name: 'Viper', 'Axe': 1.506 },
    { name: 'Visage', 'Axe': -1.916 },
    { name: 'Void Spirit', 'Axe': -1.327 },
    { name: 'Warlock', 'Axe': 1.674 },
    { name: 'Weaver', 'Axe': -1.282 },
    { name: 'Windranger', 'Axe': -1.892 },
    { name: 'Winter Wyvern', 'Axe': -0.011 },
    { name: 'Witch Doctor', 'Axe': 2.437 },
    { name: 'Wraith King', 'Axe': 1.588 },
    { name: 'Zeus', 'Axe': 1.585 },
],
"Bristleback": [
    { name: 'Abaddon', 'Bristleback': 0.244 },
    { name: 'Alchemist', 'Bristleback': -1.382 },
    { name: 'Ancient Apparition', 'Bristleback': 2.874 },
    { name: 'Anti-Mage', 'Bristleback': 0.457 },
    { name: 'Arc Warden', 'Bristleback': -2.477 },
    { name: 'Axe', 'Bristleback': 2.807 },
    { name: 'Bane', 'Bristleback': -0.31 },
    { name: 'Batrider', 'Bristleback': 0.803 },
    { name: 'Beastmaster', 'Bristleback': -3.001 },
    { name: 'Bloodseeker', 'Bristleback': -1.693 },
    { name: 'Bounty Hunter', 'Bristleback': 0.968 },
    { name: 'Brewmaster', 'Bristleback': -1.515 },
    { name: 'Broodmother', 'Bristleback': -1.804 },
    { name: 'Centaur Warrunner', 'Bristleback': -3.521 },
    { name: 'Chaos Knight', 'Bristleback': -1.098 },
    { name: 'Chen', 'Bristleback': 0.476 },
    { name: 'Clinkz', 'Bristleback': -2.625 },
    { name: 'Clockwerk', 'Bristleback': 0.266 },
    { name: 'Crystal Maiden', 'Bristleback': -1.092 },
    { name: 'Dark Seer', 'Bristleback': -0.715 },
    { name: 'Dark Willow', 'Bristleback': -1.037 },
    { name: 'Dawnbreaker', 'Bristleback': -2.52 },
    { name: 'Dazzle', 'Bristleback': 1.429 },
    { name: 'Death Prophet', 'Bristleback': -1.077 },
    { name: 'Disruptor', 'Bristleback': 0.799 },
    { name: 'Doom', 'Bristleback': -0.275 },
    { name: 'Dragon Knight', 'Bristleback': 2.722 },
    { name: 'Drow Ranger', 'Bristleback': 3.075 },
    { name: 'Earth Spirit', 'Bristleback': 1.223 },
    { name: 'Earthshaker', 'Bristleback': -1.998 },
    { name: 'Elder Titan', 'Bristleback': -1.526 },
    { name: 'Ember Spirit', 'Bristleback': 1.487 },
    { name: 'Enchantress', 'Bristleback': -1.561 },
    { name: 'Enigma', 'Bristleback': -3.485 },
    { name: 'Faceless Void', 'Bristleback': -1.411 },
    { name: 'Grimstroke', 'Bristleback': -0.232 },
    { name: 'Gyrocopter', 'Bristleback': -1.283 },
    { name: 'Hoodwink', 'Bristleback': 3.782 },
    { name: 'Huskar', 'Bristleback': -2.542 },
    { name: 'Invoker', 'Bristleback': -1.745 },
    { name: 'Io', 'Bristleback': -0.964 },
    { name: 'Jakiro', 'Bristleback': 0.257 },
    { name: 'Juggernaut', 'Bristleback': 3.156 },
    { name: 'Keeper of the Light', 'Bristleback': 0.785 },
    { name: 'Kunkka', 'Bristleback': -1.293 },
    { name: 'Legion Commander', 'Bristleback': 4.505 },
    { name: 'Leshrac', 'Bristleback': -2.602 },
    { name: 'Lich', 'Bristleback': -1.646 },
    { name: 'Lifestealer', 'Bristleback': -2.81 },
    { name: 'Lina', 'Bristleback': -1.431 },
    { name: 'Lion', 'Bristleback': 0.275 },
    { name: 'Lone Druid', 'Bristleback': -2.489 },
    { name: 'Luna', 'Bristleback': -2.524 },
    { name: 'Lycan', 'Bristleback': -1.855 },
    { name: 'Magnus', 'Bristleback': 0.01 },
    { name: 'Marci', 'Bristleback': -0.847 },
    { name: 'Mars', 'Bristleback': 0.334 },
    { name: 'Medusa', 'Bristleback': -1.311 },
    { name: 'Meepo', 'Bristleback': -4.159 },
    { name: 'Mirana', 'Bristleback': 0.199 },
    { name: 'Monkey King', 'Bristleback': -0.161 },
    { name: 'Morphling', 'Bristleback': 2.143 },
    { name: 'Muerta', 'Bristleback': -1.143 },
    { name: 'Naga Siren', 'Bristleback': 1.198 },
    { name: "Nature's Prophet", 'Bristleback': 0.664 },
    { name: 'Necrophos', 'Bristleback': 1.818 },
    { name: 'Night Stalker', 'Bristleback': -1.895 },
    { name: 'Nyx Assassin', 'Bristleback': 1.099 },
    { name: 'Ogre Magi', 'Bristleback': 1.139 },
    { name: 'Omniknight', 'Bristleback': -0.826 },
    { name: 'Oracle', 'Bristleback': -1.252 },
    { name: 'Outworld Destroyer', 'Bristleback': -1.132 },
    { name: 'Pangolier', 'Bristleback': -0.503 },
    { name: 'Phantom Assassin', 'Bristleback': 0.059 },
    { name: 'Phantom Lancer', 'Bristleback': 0.342 },
    { name: 'Phoenix', 'Bristleback': -0.545 },
    { name: 'Primal Beast', 'Bristleback': 3.112 },
    { name: 'Puck', 'Bristleback': 1.001 },
    { name: 'Pudge', 'Bristleback': -0.605 },
    { name: 'Pugna', 'Bristleback': -0.679 },
    { name: 'Queen of Pain', 'Bristleback': 0.058 },
    { name: 'Razor', 'Bristleback': -1.971 },
    { name: 'Riki', 'Bristleback': -4.393 },
    { name: 'Ringmaster', 'Bristleback': -1.616 },
    { name: 'Rubick', 'Bristleback': -0.446 },
    { name: 'Sand King', 'Bristleback': -2.156 },
    { name: 'Shadow Demon', 'Bristleback': 1.732 },
    { name: 'Shadow Fiend', 'Bristleback': -0.917 },
    { name: 'Shadow Shaman', 'Bristleback': 1.196 },
    { name: 'Silencer', 'Bristleback': 1.956 },
    { name: 'Skywrath Mage', 'Bristleback': -1.514 },
    { name: 'Slardar', 'Bristleback': 0.077 },
    { name: 'Slark', 'Bristleback': 5.207 },
    { name: 'Snapfire', 'Bristleback': -0.131 },
    { name: 'Sniper', 'Bristleback': 1.1 },
    { name: 'Spectre', 'Bristleback': -0.618 },
    { name: 'Spirit Breaker', 'Bristleback': 0.988 },
    { name: 'Storm Spirit', 'Bristleback': 2.16 },
    { name: 'Sven', 'Bristleback': -1.229 },
    { name: 'Techies', 'Bristleback': -1.449 },
    { name: 'Templar Assassin', 'Bristleback': -1.204 },
    { name: 'Terrorblade', 'Bristleback': -0.647 },
    { name: 'Tidehunter', 'Bristleback': -1.911 },
    { name: 'Timbersaw', 'Bristleback': -0.543 },
    { name: 'Tinker', 'Bristleback': 0.031 },
    { name: 'Tiny', 'Bristleback': -1.04 },
    { name: 'Treant Protector', 'Bristleback': -1.052 },
    { name: 'Troll Warlord', 'Bristleback': -2.043 },
    { name: 'Tusk', 'Bristleback': 0.125 },
    { name: 'Underlord', 'Bristleback': -1.331 },
    { name: 'Undying', 'Bristleback': -0.18 },
    { name: 'Ursa', 'Bristleback': -3.376 },
    { name: 'Vengeful Spirit', 'Bristleback': 0.415 },
    { name: 'Venomancer', 'Bristleback': 2.599 },
    { name: 'Viper', 'Bristleback': 5.53 },
    { name: 'Visage', 'Bristleback': -3.28 },
    { name: 'Void Spirit', 'Bristleback': 1.128 },
    { name: 'Warlock', 'Bristleback': -2.716 },
    { name: 'Weaver', 'Bristleback': -0.885 },
    { name: 'Windranger', 'Bristleback': -2.695 },
    { name: 'Winter Wyvern', 'Bristleback': 0.681 },
    { name: 'Witch Doctor', 'Bristleback': -2.202 },
    { name: 'Wraith King', 'Bristleback': -0.859 },
    { name: 'Zeus', 'Bristleback': -1.328 },
],
"Centaur Warrunner": [
    { name: 'Abaddon', 'Centaur Warrunner': 0.266 },
    { name: 'Alchemist', 'Centaur Warrunner': 2.162 },
    { name: 'Ancient Apparition', 'Centaur Warrunner': -0.044 },
    { name: 'Anti-Mage', 'Centaur Warrunner': -0.032 },
    { name: 'Arc Warden', 'Centaur Warrunner': -1.164 },
    { name: 'Axe', 'Centaur Warrunner': -1.474 },
    { name: 'Bane', 'Centaur Warrunner': 0.203 },
    { name: 'Batrider', 'Centaur Warrunner': 0.834 },
    { name: 'Beastmaster', 'Centaur Warrunner': 0.942 },
    { name: 'Bloodseeker', 'Centaur Warrunner': 1.242 },
    { name: 'Bounty Hunter', 'Centaur Warrunner': 1.219 },
    { name: 'Brewmaster', 'Centaur Warrunner': 1.316 },
    { name: 'Bristleback', 'Centaur Warrunner': 3.505 },
    { name: 'Broodmother', 'Centaur Warrunner': 0.548 },
    { name: 'Chaos Knight', 'Centaur Warrunner': -0.423 },
    { name: 'Chen', 'Centaur Warrunner': 3.359 },
    { name: 'Clinkz', 'Centaur Warrunner': -0.279 },
    { name: 'Clockwerk', 'Centaur Warrunner': 0.405 },
    { name: 'Crystal Maiden', 'Centaur Warrunner': 0.04 },
    { name: 'Dark Seer', 'Centaur Warrunner': 0.312 },
    { name: 'Dark Willow', 'Centaur Warrunner': 0.573 },
    { name: 'Dawnbreaker', 'Centaur Warrunner': 0.013 },
    { name: 'Dazzle', 'Centaur Warrunner': 2.316 },
    { name: 'Death Prophet', 'Centaur Warrunner': 1.409 },
    { name: 'Disruptor', 'Centaur Warrunner': 1.181 },
    { name: 'Doom', 'Centaur Warrunner': 1.85 },
    { name: 'Dragon Knight', 'Centaur Warrunner': 0.496 },
    { name: 'Drow Ranger', 'Centaur Warrunner': -2.061 },
    { name: 'Earth Spirit', 'Centaur Warrunner': -0.089 },
    { name: 'Earthshaker', 'Centaur Warrunner': 0.37 },
    { name: 'Elder Titan', 'Centaur Warrunner': 5.131 },
    { name: 'Ember Spirit', 'Centaur Warrunner': 3.104 },
    { name: 'Enchantress', 'Centaur Warrunner': 0.253 },
    { name: 'Enigma', 'Centaur Warrunner': 1.415 },
    { name: 'Faceless Void', 'Centaur Warrunner': -0.2 },
    { name: 'Grimstroke', 'Centaur Warrunner': -0.227 },
    { name: 'Gyrocopter', 'Centaur Warrunner': -0.749 },
    { name: 'Hoodwink', 'Centaur Warrunner': -1.889 },
    { name: 'Huskar', 'Centaur Warrunner': 1.591 },
    { name: 'Invoker', 'Centaur Warrunner': 1.244 },
    { name: 'Io', 'Centaur Warrunner': 1.302 },
    { name: 'Jakiro', 'Centaur Warrunner': -0.525 },
    { name: 'Juggernaut', 'Centaur Warrunner': 2.054 },
    { name: 'Keeper of the Light', 'Centaur Warrunner': 0.964 },
    { name: 'Kunkka', 'Centaur Warrunner': 0.201 },
    { name: 'Legion Commander', 'Centaur Warrunner': -0.278 },
    { name: 'Leshrac', 'Centaur Warrunner': -0.181 },
    { name: 'Lich', 'Centaur Warrunner': -1.2 },
    { name: 'Lifestealer', 'Centaur Warrunner': 4.687 },
    { name: 'Lina', 'Centaur Warrunner': -0.395 },
    { name: 'Lion', 'Centaur Warrunner': -0.815 },
    { name: 'Lone Druid', 'Centaur Warrunner': 1.985 },
    { name: 'Luna', 'Centaur Warrunner': -0.905 },
    { name: 'Lycan', 'Centaur Warrunner': 1.933 },
    { name: 'Magnus', 'Centaur Warrunner': -0.064 },
    { name: 'Marci', 'Centaur Warrunner': -0.303 },
    { name: 'Mars', 'Centaur Warrunner': 0.768 },
    { name: 'Medusa', 'Centaur Warrunner': -3.581 },
    { name: 'Meepo', 'Centaur Warrunner': 1.271 },
    { name: 'Mirana', 'Centaur Warrunner': -1.012 },
    { name: 'Monkey King', 'Centaur Warrunner': 1.39 },
    { name: 'Morphling', 'Centaur Warrunner': -0.065 },
    { name: 'Muerta', 'Centaur Warrunner': -2.887 },
    { name: 'Naga Siren', 'Centaur Warrunner': -0.607 },
    { name: "Nature's Prophet", 'Centaur Warrunner': 0.706 },
    { name: 'Necrophos', 'Centaur Warrunner': 1.771 },
    { name: 'Night Stalker', 'Centaur Warrunner': 1.007 },
    { name: 'Nyx Assassin', 'Centaur Warrunner': -0.033 },
    { name: 'Ogre Magi', 'Centaur Warrunner': 0.265 },
    { name: 'Omniknight', 'Centaur Warrunner': 0.941 },
    { name: 'Oracle', 'Centaur Warrunner': 2.157 },
    { name: 'Outworld Destroyer', 'Centaur Warrunner': 0.428 },
    { name: 'Pangolier', 'Centaur Warrunner': 1.402 },
    { name: 'Phantom Assassin', 'Centaur Warrunner': -1.712 },
    { name: 'Phantom Lancer', 'Centaur Warrunner': -0.938 },
    { name: 'Phoenix', 'Centaur Warrunner': -0.169 },
    { name: 'Primal Beast', 'Centaur Warrunner': -0.771 },
    { name: 'Puck', 'Centaur Warrunner': -0.427 },
    { name: 'Pudge', 'Centaur Warrunner': -1.105 },
    { name: 'Pugna', 'Centaur Warrunner': -0.33 },
    { name: 'Queen of Pain', 'Centaur Warrunner': 0.189 },
    { name: 'Razor', 'Centaur Warrunner': -1.161 },
    { name: 'Riki', 'Centaur Warrunner': -1.048 },
    { name: 'Ringmaster', 'Centaur Warrunner': 0.304 },
    { name: 'Rubick', 'Centaur Warrunner': -0.497 },
    { name: 'Sand King', 'Centaur Warrunner': -1.929 },
    { name: 'Shadow Demon', 'Centaur Warrunner': 0.432 },
    { name: 'Shadow Fiend', 'Centaur Warrunner': 0.071 },
    { name: 'Shadow Shaman', 'Centaur Warrunner': 0.462 },
    { name: 'Silencer', 'Centaur Warrunner': -1.347 },
    { name: 'Skywrath Mage', 'Centaur Warrunner': -0.473 },
    { name: 'Slardar', 'Centaur Warrunner': 1.712 },
    { name: 'Slark', 'Centaur Warrunner': -0.094 },
    { name: 'Snapfire', 'Centaur Warrunner': -0.9 },
    { name: 'Sniper', 'Centaur Warrunner': -3.248 },
    { name: 'Spectre', 'Centaur Warrunner': -1.243 },
    { name: 'Spirit Breaker', 'Centaur Warrunner': -0.474 },
    { name: 'Storm Spirit', 'Centaur Warrunner': -0.473 },
    { name: 'Sven', 'Centaur Warrunner': 1.742 },
    { name: 'Techies', 'Centaur Warrunner': -0.961 },
    { name: 'Templar Assassin', 'Centaur Warrunner': 1.425 },
    { name: 'Terrorblade', 'Centaur Warrunner': 0.37 },
    { name: 'Tidehunter', 'Centaur Warrunner': 2.09 },
    { name: 'Timbersaw', 'Centaur Warrunner': 1.606 },
    { name: 'Tinker', 'Centaur Warrunner': 1.994 },
    { name: 'Tiny', 'Centaur Warrunner': 0.038 },
    { name: 'Treant Protector', 'Centaur Warrunner': 1.364 },
    { name: 'Troll Warlord', 'Centaur Warrunner': -0.344 },
    { name: 'Tusk', 'Centaur Warrunner': 0.486 },
    { name: 'Underlord', 'Centaur Warrunner': 1.817 },
    { name: 'Undying', 'Centaur Warrunner': 1.907 },
    { name: 'Ursa', 'Centaur Warrunner': 1.353 },
    { name: 'Vengeful Spirit', 'Centaur Warrunner': 0.182 },
    { name: 'Venomancer', 'Centaur Warrunner': 0.986 },
    { name: 'Viper', 'Centaur Warrunner': -0.077 },
    { name: 'Visage', 'Centaur Warrunner': -0.11 },
    { name: 'Void Spirit', 'Centaur Warrunner': -0.011 },
    { name: 'Warlock', 'Centaur Warrunner': 1.114 },
    { name: 'Weaver', 'Centaur Warrunner': 0.507 },
    { name: 'Windranger', 'Centaur Warrunner': -3.175 },
    { name: 'Winter Wyvern', 'Centaur Warrunner': -0.597 },
    { name: 'Witch Doctor', 'Centaur Warrunner': 1.933 },
    { name: 'Wraith King', 'Centaur Warrunner': 0.812 },
    { name: 'Zeus', 'Centaur Warrunner': -1.343 },
],
"Chaos Knight": [
    { name: 'Abaddon', 'Chaos Knight': -0.441 },
    { name: 'Alchemist', 'Chaos Knight': 0.017 },
    { name: 'Ancient Apparition', 'Chaos Knight': -0.923 },
    { name: 'Anti-Mage', 'Chaos Knight': 1.52 },
    { name: 'Arc Warden', 'Chaos Knight': -1.859 },
    { name: 'Axe', 'Chaos Knight': 2.143 },
    { name: 'Bane', 'Chaos Knight': -2.042 },
    { name: 'Batrider', 'Chaos Knight': 0.338 },
    { name: 'Beastmaster', 'Chaos Knight': 0.62 },
    { name: 'Bloodseeker', 'Chaos Knight': -1.156 },
    { name: 'Bounty Hunter', 'Chaos Knight': -2.321 },
    { name: 'Brewmaster', 'Chaos Knight': 1.854 },
    { name: 'Bristleback', 'Chaos Knight': 1.116 },
    { name: 'Broodmother', 'Chaos Knight': -0.873 },
    { name: 'Centaur Warrunner', 'Chaos Knight': 0.479 },
    { name: 'Chen', 'Chaos Knight': -2.971 },
    { name: 'Clinkz', 'Chaos Knight': -2.554 },
    { name: 'Clockwerk', 'Chaos Knight': -0.978 },
    { name: 'Crystal Maiden', 'Chaos Knight': 1.916 },
    { name: 'Dark Seer', 'Chaos Knight': 4.163 },
    { name: 'Dark Willow', 'Chaos Knight': -0.186 },
    { name: 'Dawnbreaker', 'Chaos Knight': 1.95 },
    { name: 'Dazzle', 'Chaos Knight': 0.955 },
    { name: 'Death Prophet', 'Chaos Knight': -1.758 },
    { name: 'Disruptor', 'Chaos Knight': -0.413 },
    { name: 'Doom', 'Chaos Knight': -1.022 },
    { name: 'Dragon Knight', 'Chaos Knight': 0.461 },
    { name: 'Drow Ranger', 'Chaos Knight': -2.248 },
    { name: 'Earth Spirit', 'Chaos Knight': -0.606 },
    { name: 'Earthshaker', 'Chaos Knight': 2.562 },
    { name: 'Elder Titan', 'Chaos Knight': -0.091 },
    { name: 'Ember Spirit', 'Chaos Knight': 1.599 },
    { name: 'Enchantress', 'Chaos Knight': -1.08 },
    { name: 'Enigma', 'Chaos Knight': 1.515 },
    { name: 'Faceless Void', 'Chaos Knight': -1.938 },
    { name: 'Grimstroke', 'Chaos Knight': -0.571 },
    { name: 'Gyrocopter', 'Chaos Knight': 0.432 },
    { name: 'Hoodwink', 'Chaos Knight': 0.744 },
    { name: 'Huskar', 'Chaos Knight': -4.382 },
    { name: 'Invoker', 'Chaos Knight': 1.289 },
    { name: 'Io', 'Chaos Knight': -0.202 },
    { name: 'Jakiro', 'Chaos Knight': 2.517 },
    { name: 'Juggernaut', 'Chaos Knight': 0.302 },
    { name: 'Keeper of the Light', 'Chaos Knight': -0.348 },
    { name: 'Kunkka', 'Chaos Knight': 2.968 },
    { name: 'Legion Commander', 'Chaos Knight': -2.577 },
    { name: 'Leshrac', 'Chaos Knight': 1.312 },
    { name: 'Lich', 'Chaos Knight': 1.385 },
    { name: 'Lifestealer', 'Chaos Knight': -0.881 },
    { name: 'Lina', 'Chaos Knight': 2.106 },
    { name: 'Lion', 'Chaos Knight': -0.258 },
    { name: 'Lone Druid', 'Chaos Knight': -1.988 },
    { name: 'Luna', 'Chaos Knight': 0.839 },
    { name: 'Lycan', 'Chaos Knight': -1.297 },
    { name: 'Magnus', 'Chaos Knight': 1.098 },
    { name: 'Marci', 'Chaos Knight': -0.419 },
    { name: 'Mars', 'Chaos Knight': -0.988 },
    { name: 'Medusa', 'Chaos Knight': 2.871 },
    { name: 'Meepo', 'Chaos Knight': 1.112 },
    { name: 'Mirana', 'Chaos Knight': -1.051 },
    { name: 'Monkey King', 'Chaos Knight': 2.044 },
    { name: 'Morphling', 'Chaos Knight': -0.498 },
    { name: 'Muerta', 'Chaos Knight': 0.101 },
    { name: 'Naga Siren', 'Chaos Knight': 4.047 },
    { name: "Nature's Prophet", 'Chaos Knight': -1.3 },
    { name: 'Necrophos', 'Chaos Knight': 1.095 },
    { name: 'Night Stalker', 'Chaos Knight': -2.824 },
    { name: 'Nyx Assassin', 'Chaos Knight': -2.39 },
    { name: 'Ogre Magi', 'Chaos Knight': -4.028 },
    { name: 'Omniknight', 'Chaos Knight': -0.673 },
    { name: 'Oracle', 'Chaos Knight': -2.198 },
    { name: 'Outworld Destroyer', 'Chaos Knight': -2.737 },
    { name: 'Pangolier', 'Chaos Knight': 1.635 },
    { name: 'Phantom Assassin', 'Chaos Knight': 0.606 },
    { name: 'Phantom Lancer', 'Chaos Knight': 3.889 },
    { name: 'Phoenix', 'Chaos Knight': 3.611 },
    { name: 'Primal Beast', 'Chaos Knight': 0.654 },
    { name: 'Puck', 'Chaos Knight': 0.207 },
    { name: 'Pudge', 'Chaos Knight': -2.084 },
    { name: 'Pugna', 'Chaos Knight': -1.969 },
    { name: 'Queen of Pain', 'Chaos Knight': -1.451 },
    { name: 'Razor', 'Chaos Knight': -1.066 },
    { name: 'Riki', 'Chaos Knight': -0.343 },
    { name: 'Ringmaster', 'Chaos Knight': -0.47 },
    { name: 'Rubick', 'Chaos Knight': 0.414 },
    { name: 'Sand King', 'Chaos Knight': 4.634 },
    { name: 'Shadow Demon', 'Chaos Knight': -1.568 },
    { name: 'Shadow Fiend', 'Chaos Knight': 0.84 },
    { name: 'Shadow Shaman', 'Chaos Knight': -2.081 },
    { name: 'Silencer', 'Chaos Knight': -1.471 },
    { name: 'Skywrath Mage', 'Chaos Knight': -2.316 },
    { name: 'Slardar', 'Chaos Knight': -1.521 },
    { name: 'Slark', 'Chaos Knight': -0.983 },
    { name: 'Snapfire', 'Chaos Knight': 0.684 },
    { name: 'Sniper', 'Chaos Knight': -1.054 },
    { name: 'Spectre', 'Chaos Knight': -0.274 },
    { name: 'Spirit Breaker', 'Chaos Knight': -0.694 },
    { name: 'Storm Spirit', 'Chaos Knight': -0.38 },
    { name: 'Sven', 'Chaos Knight': 1.045 },
    { name: 'Techies', 'Chaos Knight': 1.265 },
    { name: 'Templar Assassin', 'Chaos Knight': -1.51 },
    { name: 'Terrorblade', 'Chaos Knight': 2.029 },
    { name: 'Tidehunter', 'Chaos Knight': 3.899 },
    { name: 'Timbersaw', 'Chaos Knight': 2.616 },
    { name: 'Tinker', 'Chaos Knight': -1.62 },
    { name: 'Tiny', 'Chaos Knight': 1.13 },
    { name: 'Treant Protector', 'Chaos Knight': 1.104 },
    { name: 'Troll Warlord', 'Chaos Knight': 1.484 },
    { name: 'Tusk', 'Chaos Knight': -0.373 },
    { name: 'Underlord', 'Chaos Knight': 2.477 },
    { name: 'Undying', 'Chaos Knight': 0.026 },
    { name: 'Ursa', 'Chaos Knight': -1.862 },
    { name: 'Vengeful Spirit', 'Chaos Knight': -1.882 },
    { name: 'Venomancer', 'Chaos Knight': 0.962 },
    { name: 'Viper', 'Chaos Knight': -3.93 },
    { name: 'Visage', 'Chaos Knight': -2.477 },
    { name: 'Void Spirit', 'Chaos Knight': -0.338 },
    { name: 'Warlock', 'Chaos Knight': 4.909 },
    { name: 'Weaver', 'Chaos Knight': -0.65 },
    { name: 'Windranger', 'Chaos Knight': 0.531 },
    { name: 'Winter Wyvern', 'Chaos Knight': 1.459 },
    { name: 'Witch Doctor', 'Chaos Knight': 1.233 },
    { name: 'Wraith King', 'Chaos Knight': -1.156 },
    { name: 'Zeus', 'Chaos Knight': -1.912 },
],
"Dawnbreaker": [
    { name: 'Abaddon', 'Dawnbreaker': -0.61 },
    { name: 'Alchemist', 'Dawnbreaker': 0.318 },
    { name: 'Ancient Apparition', 'Dawnbreaker': 1.685 },
    { name: 'Anti-Mage', 'Dawnbreaker': 0.408 },
    { name: 'Arc Warden', 'Dawnbreaker': -0.568 },
    { name: 'Axe', 'Dawnbreaker': 0.063 },
    { name: 'Bane', 'Dawnbreaker': -0.853 },
    { name: 'Batrider', 'Dawnbreaker': -1.23 },
    { name: 'Beastmaster', 'Dawnbreaker': -2.229 },
    { name: 'Bloodseeker', 'Dawnbreaker': 0.851 },
    { name: 'Bounty Hunter', 'Dawnbreaker': 0.534 },
    { name: 'Brewmaster', 'Dawnbreaker': -1.952 },
    { name: 'Bristleback', 'Dawnbreaker': 2.636 },
    { name: 'Broodmother', 'Dawnbreaker': -0.554 },
    { name: 'Centaur Warrunner', 'Dawnbreaker': 0.094 },
    { name: 'Chaos Knight', 'Dawnbreaker': -1.955 },
    { name: 'Chen', 'Dawnbreaker': -1.717 },
    { name: 'Clinkz', 'Dawnbreaker': 0.818 },
    { name: 'Clockwerk', 'Dawnbreaker': 0.522 },
    { name: 'Crystal Maiden', 'Dawnbreaker': -1.912 },
    { name: 'Dark Seer', 'Dawnbreaker': 0.47 },
    { name: 'Dark Willow', 'Dawnbreaker': 2.041 },
    { name: 'Dazzle', 'Dawnbreaker': -0.444 },
    { name: 'Death Prophet', 'Dawnbreaker': 1.595 },
    { name: 'Disruptor', 'Dawnbreaker': 0.719 },
    { name: 'Doom', 'Dawnbreaker': -0.156 },
    { name: 'Dragon Knight', 'Dawnbreaker': 0.576 },
    { name: 'Drow Ranger', 'Dawnbreaker': 1.881 },
    { name: 'Earth Spirit', 'Dawnbreaker': -0.42 },
    { name: 'Earthshaker', 'Dawnbreaker': 0.167 },
    { name: 'Elder Titan', 'Dawnbreaker': 0.237 },
    { name: 'Ember Spirit', 'Dawnbreaker': -1.002 },
    { name: 'Enchantress', 'Dawnbreaker': 0.503 },
    { name: 'Enigma', 'Dawnbreaker': -0.703 },
    { name: 'Faceless Void', 'Dawnbreaker': 0.249 },
    { name: 'Grimstroke', 'Dawnbreaker': 0.3 },
    { name: 'Gyrocopter', 'Dawnbreaker': 0.322 },
    { name: 'Hoodwink', 'Dawnbreaker': -0.151 },
    { name: 'Huskar', 'Dawnbreaker': 0.273 },
    { name: 'Invoker', 'Dawnbreaker': 0.304 },
    { name: 'Io', 'Dawnbreaker': 0.273 },
    { name: 'Jakiro', 'Dawnbreaker': 1.282 },
    { name: 'Juggernaut', 'Dawnbreaker': 0.901 },
    { name: 'Keeper of the Light', 'Dawnbreaker': 1.548 },
    { name: 'Kunkka', 'Dawnbreaker': 0.574 },
    { name: 'Legion Commander', 'Dawnbreaker': -3.477 },
    { name: 'Leshrac', 'Dawnbreaker': -1.008 },
    { name: 'Lich', 'Dawnbreaker': 0.756 },
    { name: 'Lifestealer', 'Dawnbreaker': 0.09 },
    { name: 'Lina', 'Dawnbreaker': 1.072 },
    { name: 'Lion', 'Dawnbreaker': -0.095 },
    { name: 'Lone Druid', 'Dawnbreaker': 0.999 },
    { name: 'Luna', 'Dawnbreaker': 0.016 },
    { name: 'Lycan', 'Dawnbreaker': 1.243 },
    { name: 'Magnus', 'Dawnbreaker': -0.408 },
    { name: 'Marci', 'Dawnbreaker': -1.138 },
    { name: 'Mars', 'Dawnbreaker': -0.271 },
    { name: 'Medusa', 'Dawnbreaker': -0.055 },
    { name: 'Meepo', 'Dawnbreaker': -3.944 },
    { name: 'Mirana', 'Dawnbreaker': 0.759 },
    { name: 'Monkey King', 'Dawnbreaker': 0.76 },
    { name: 'Morphling', 'Dawnbreaker': 1.286 },
    { name: 'Muerta', 'Dawnbreaker': 0.679 },
    { name: 'Naga Siren', 'Dawnbreaker': -3.348 },
    { name: "Nature's Prophet", 'Dawnbreaker': -1.893 },
    { name: 'Necrophos', 'Dawnbreaker': -0.814 },
    { name: 'Night Stalker', 'Dawnbreaker': -0.301 },
    { name: 'Nyx Assassin', 'Dawnbreaker': 1.847 },
    { name: 'Ogre Magi', 'Dawnbreaker': 0.131 },
    { name: 'Omniknight', 'Dawnbreaker': -0.381 },
    { name: 'Oracle', 'Dawnbreaker': -1.178 },
    { name: 'Outworld Destroyer', 'Dawnbreaker': 1.369 },
    { name: 'Pangolier', 'Dawnbreaker': -0.075 },
    { name: 'Phantom Assassin', 'Dawnbreaker': -1.87 },
    { name: 'Phantom Lancer', 'Dawnbreaker': 0.154 },
    { name: 'Phoenix', 'Dawnbreaker': 2.372 },
    { name: 'Primal Beast', 'Dawnbreaker': -0.572 },
    { name: 'Puck', 'Dawnbreaker': 0.3 },
    { name: 'Pudge', 'Dawnbreaker': -1.432 },
    { name: 'Pugna', 'Dawnbreaker': 0.798 },
    { name: 'Queen of Pain', 'Dawnbreaker': -0.074 },
    { name: 'Razor', 'Dawnbreaker': 0.543 },
    { name: 'Riki', 'Dawnbreaker': 0.426 },
    { name: 'Ringmaster', 'Dawnbreaker': 0.779 },
    { name: 'Rubick', 'Dawnbreaker': 0.407 },
    { name: 'Sand King', 'Dawnbreaker': -0.361 },
    { name: 'Shadow Demon', 'Dawnbreaker': -1.747 },
    { name: 'Shadow Fiend', 'Dawnbreaker': 0.6 },
    { name: 'Shadow Shaman', 'Dawnbreaker': -1.432 },
    { name: 'Silencer', 'Dawnbreaker': 2.392 },
    { name: 'Skywrath Mage', 'Dawnbreaker': -0.265 },
    { name: 'Slardar', 'Dawnbreaker': 0.776 },
    { name: 'Slark', 'Dawnbreaker': -2.848 },
    { name: 'Snapfire', 'Dawnbreaker': 0.719 },
    { name: 'Sniper', 'Dawnbreaker': 1.126 },
    { name: 'Spectre', 'Dawnbreaker': -2.117 },
    { name: 'Spirit Breaker', 'Dawnbreaker': -1.056 },
    { name: 'Storm Spirit', 'Dawnbreaker': -0.739 },
    { name: 'Sven', 'Dawnbreaker': 1.029 },
    { name: 'Techies', 'Dawnbreaker': -0.053 },
    { name: 'Templar Assassin', 'Dawnbreaker': 0.632 },
    { name: 'Terrorblade', 'Dawnbreaker': -0.642 },
    { name: 'Tidehunter', 'Dawnbreaker': -1.467 },
    { name: 'Timbersaw', 'Dawnbreaker': 1.157 },
    { name: 'Tinker', 'Dawnbreaker': -0.341 },
    { name: 'Tiny', 'Dawnbreaker': -0.978 },
    { name: 'Treant Protector', 'Dawnbreaker': -0.783 },
    { name: 'Troll Warlord', 'Dawnbreaker': 1.61 },
    { name: 'Tusk', 'Dawnbreaker': -2.244 },
    { name: 'Underlord', 'Dawnbreaker': 0.678 },
    { name: 'Undying', 'Dawnbreaker': -0.215 },
    { name: 'Ursa', 'Dawnbreaker': 1.115 },
    { name: 'Vengeful Spirit', 'Dawnbreaker': 0.091 },
    { name: 'Venomancer', 'Dawnbreaker': 0.426 },
    { name: 'Viper', 'Dawnbreaker': 0.339 },
    { name: 'Visage', 'Dawnbreaker': 0.887 },
    { name: 'Void Spirit', 'Dawnbreaker': -1.046 },
    { name: 'Warlock', 'Dawnbreaker': -0.343 },
    { name: 'Weaver', 'Dawnbreaker': 1.398 },
    { name: 'Windranger', 'Dawnbreaker': 0.932 },
    { name: 'Winter Wyvern', 'Dawnbreaker': 1.487 },
    { name: 'Witch Doctor', 'Dawnbreaker': 0.501 },
    { name: 'Wraith King', 'Dawnbreaker': -0.824 },
    { name: 'Zeus', 'Dawnbreaker': -0.293 },
],
"Doom": [
    { name: 'Abaddon', 'Doom': -2.889 },
    { name: 'Alchemist', 'Doom': -1.567 },
    { name: 'Ancient Apparition', 'Doom': -0.678 },
    { name: 'Anti-Mage', 'Doom': 1.332 },
    { name: 'Arc Warden', 'Doom': 4.708 },
    { name: 'Axe', 'Doom': -1.737 },
    { name: 'Bane', 'Doom': 0.334 },
    { name: 'Batrider', 'Doom': 1.599 },
    { name: 'Beastmaster', 'Doom': 0.43 },
    { name: 'Bloodseeker', 'Doom': -1.28 },
    { name: 'Bounty Hunter', 'Doom': 0.597 },
    { name: 'Brewmaster', 'Doom': 0.627 },
    { name: 'Bristleback', 'Doom': 0.367 },
    { name: 'Broodmother', 'Doom': 2.297 },
    { name: 'Centaur Warrunner', 'Doom': -1.723 },
    { name: 'Chaos Knight', 'Doom': 1.093 },
    { name: 'Chen', 'Doom': -1.317 },
    { name: 'Clinkz', 'Doom': 1.147 },
    { name: 'Clockwerk', 'Doom': -0.167 },
    { name: 'Crystal Maiden', 'Doom': 1.375 },
    { name: 'Dark Seer', 'Doom': -2.468 },
    { name: 'Dark Willow', 'Doom': 0.478 },
    { name: 'Dawnbreaker', 'Doom': 0.136 },
    { name: 'Dazzle', 'Doom': -0.226 },
    { name: 'Death Prophet', 'Doom': -0.611 },
    { name: 'Disruptor', 'Doom': -0.02 },
    { name: 'Dragon Knight', 'Doom': -0.802 },
    { name: 'Drow Ranger', 'Doom': 0.527 },
    { name: 'Earth Spirit', 'Doom': -0.68 },
    { name: 'Earthshaker', 'Doom': -0.984 },
    { name: 'Elder Titan', 'Doom': -1.046 },
    { name: 'Ember Spirit', 'Doom': -0.683 },
    { name: 'Enchantress', 'Doom': -0.471 },
    { name: 'Enigma', 'Doom': 1.513 },
    { name: 'Faceless Void', 'Doom': -2.867 },
    { name: 'Grimstroke', 'Doom': 0.158 },
    { name: 'Gyrocopter', 'Doom': -0.458 },
    { name: 'Hoodwink', 'Doom': 0.594 },
    { name: 'Huskar', 'Doom': -2.405 },
    { name: 'Invoker', 'Doom': 1.976 },
    { name: 'Io', 'Doom': -1.711 },
    { name: 'Jakiro', 'Doom': 0.479 },
    { name: 'Juggernaut', 'Doom': -1.02 },
    { name: 'Keeper of the Light', 'Doom': 1.409 },
    { name: 'Kunkka', 'Doom': -0.62 },
    { name: 'Legion Commander', 'Doom': -0.281 },
    { name: 'Leshrac', 'Doom': -0.773 },
    { name: 'Lich', 'Doom': -0.12 },
    { name: 'Lifestealer', 'Doom': -1.359 },
    { name: 'Lina', 'Doom': 2.378 },
    { name: 'Lion', 'Doom': -0.32 },
    { name: 'Lone Druid', 'Doom': 1.586 },
    { name: 'Luna', 'Doom': 0.48 },
    { name: 'Lycan', 'Doom': 0.96 },
    { name: 'Magnus', 'Doom': 0.585 },
    { name: 'Marci', 'Doom': 1.484 },
    { name: 'Mars', 'Doom': -0.625 },
    { name: 'Medusa', 'Doom': 1.989 },
    { name: 'Meepo', 'Doom': 3.382 },
    { name: 'Mirana', 'Doom': 0.918 },
    { name: 'Monkey King', 'Doom': -0.258 },
    { name: 'Morphling', 'Doom': -1.495 },
    { name: 'Muerta', 'Doom': 0.517 },
    { name: 'Naga Siren', 'Doom': -0.758 },
    { name: "Nature's Prophet", 'Doom': 1.126 },
    { name: 'Necrophos', 'Doom': -4.236 },
    { name: 'Night Stalker', 'Doom': 0.589 },
    { name: 'Nyx Assassin', 'Doom': -0.549 },
    { name: 'Ogre Magi', 'Doom': -0.269 },
    { name: 'Omniknight', 'Doom': -3.272 },
    { name: 'Oracle', 'Doom': -1.015 },
    { name: 'Outworld Destroyer', 'Doom': 0.344 },
    { name: 'Pangolier', 'Doom': -0.246 },
    { name: 'Phantom Assassin', 'Doom': -0.928 },
    { name: 'Phantom Lancer', 'Doom': -1.43 },
    { name: 'Phoenix', 'Doom': 0.016 },
    { name: 'Primal Beast', 'Doom': -0.53 },
    { name: 'Puck', 'Doom': -0.459 },
    { name: 'Pudge', 'Doom': -0.625 },
    { name: 'Pugna', 'Doom': -0.657 },
    { name: 'Queen of Pain', 'Doom': 0.359 },
    { name: 'Razor', 'Doom': 0.44 },
    { name: 'Riki', 'Doom': -0.492 },
    { name: 'Ringmaster', 'Doom': 0.087 },
    { name: 'Rubick', 'Doom': 1.479 },
    { name: 'Sand King', 'Doom': -0.381 },
    { name: 'Shadow Demon', 'Doom': -0.403 },
    { name: 'Shadow Fiend', 'Doom': 0.793 },
    { name: 'Shadow Shaman', 'Doom': -0.49 },
    { name: 'Silencer', 'Doom': 0.542 },
    { name: 'Skywrath Mage', 'Doom': 0.776 },
    { name: 'Slardar', 'Doom': -2.026 },
    { name: 'Slark', 'Doom': -1.201 },
    { name: 'Snapfire', 'Doom': -0.563 },
    { name: 'Sniper', 'Doom': 0.292 },
    { name: 'Spectre', 'Doom': 1.433 },
    { name: 'Spirit Breaker', 'Doom': 0.323 },
    { name: 'Storm Spirit', 'Doom': 0.094 },
    { name: 'Sven', 'Doom': -0.735 },
    { name: 'Techies', 'Doom': 0.134 },
    { name: 'Templar Assassin', 'Doom': 0.097 },
    { name: 'Terrorblade', 'Doom': 0.828 },
    { name: 'Tidehunter', 'Doom': -0.924 },
    { name: 'Timbersaw', 'Doom': -1.6 },
    { name: 'Tinker', 'Doom': -1.283 },
    { name: 'Tiny', 'Doom': -2.123 },
    { name: 'Treant Protector', 'Doom': -0.731 },
    { name: 'Troll Warlord', 'Doom': -1.389 },
    { name: 'Tusk', 'Doom': 0.344 },
    { name: 'Underlord', 'Doom': -1.031 },
    { name: 'Undying', 'Doom': 0.006 },
    { name: 'Ursa', 'Doom': -0.377 },
    { name: 'Vengeful Spirit', 'Doom': 0.475 },
    { name: 'Venomancer', 'Doom': 0.853 },
    { name: 'Viper', 'Doom': 1.244 },
    { name: 'Visage', 'Doom': 0.397 },
    { name: 'Void Spirit', 'Doom': -0.37 },
    { name: 'Warlock', 'Doom': 0.101 },
    { name: 'Weaver', 'Doom': 0.583 },
    { name: 'Windranger', 'Doom': 0.836 },
    { name: 'Winter Wyvern', 'Doom': -1.478 },
    { name: 'Witch Doctor', 'Doom': 1.548 },
    { name: 'Wraith King', 'Doom': 1.543 },
    { name: 'Zeus', 'Doom': 0.019 },
],
"Dragon Knight": [
    { name: 'Abaddon', 'Dragon Knight': -0.307 },
    { name: 'Alchemist', 'Dragon Knight': 0.229 },
    { name: 'Ancient Apparition', 'Dragon Knight': 1.01 },
    { name: 'Anti-Mage', 'Dragon Knight': 0.358 },
    { name: 'Arc Warden', 'Dragon Knight': 1.425 },
    { name: 'Axe', 'Dragon Knight': -1.93 },
    { name: 'Bane', 'Dragon Knight': 0.362 },
    { name: 'Batrider', 'Dragon Knight': 1.319 },
    { name: 'Beastmaster', 'Dragon Knight': 1.059 },
    { name: 'Bloodseeker', 'Dragon Knight': -0.053 },
    { name: 'Bounty Hunter', 'Dragon Knight': 0.63 },
    { name: 'Brewmaster', 'Dragon Knight': 0.218 },
    { name: 'Bristleback', 'Dragon Knight': -2.736 },
    { name: 'Broodmother', 'Dragon Knight': -0.498 },
    { name: 'Centaur Warrunner', 'Dragon Knight': -0.544 },
    { name: 'Chaos Knight', 'Dragon Knight': -0.489 },
    { name: 'Chen', 'Dragon Knight': 1.077 },
    { name: 'Clinkz', 'Dragon Knight': -0.676 },
    { name: 'Clockwerk', 'Dragon Knight': -0.512 },
    { name: 'Crystal Maiden', 'Dragon Knight': 1.358 },
    { name: 'Dark Seer', 'Dragon Knight': 1.258 },
    { name: 'Dark Willow', 'Dragon Knight': 0.331 },
    { name: 'Dawnbreaker', 'Dragon Knight': -0.621 },
    { name: 'Dazzle', 'Dragon Knight': 0.402 },
    { name: 'Death Prophet', 'Dragon Knight': -1.129 },
    { name: 'Disruptor', 'Dragon Knight': -1.004 },
    { name: 'Doom', 'Dragon Knight': 0.84 },
    { name: 'Drow Ranger', 'Dragon Knight': -1.284 },
    { name: 'Earth Spirit', 'Dragon Knight': 0.47 },
    { name: 'Earthshaker', 'Dragon Knight': -0.989 },
    { name: 'Elder Titan', 'Dragon Knight': 0.348 },
    { name: 'Ember Spirit', 'Dragon Knight': -0.974 },
    { name: 'Enchantress', 'Dragon Knight': 0.287 },
    { name: 'Enigma', 'Dragon Knight': 0.394 },
    { name: 'Faceless Void', 'Dragon Knight': 0.406 },
    { name: 'Grimstroke', 'Dragon Knight': -0.516 },
    { name: 'Gyrocopter', 'Dragon Knight': -0.051 },
    { name: 'Hoodwink', 'Dragon Knight': 0.011 },
    { name: 'Huskar', 'Dragon Knight': 0.682 },
    { name: 'Invoker', 'Dragon Knight': 0.192 },
    { name: 'Io', 'Dragon Knight': -1.196 },
    { name: 'Jakiro', 'Dragon Knight': 0.209 },
    { name: 'Juggernaut', 'Dragon Knight': -0.32 },
    { name: 'Keeper of the Light', 'Dragon Knight': 0.427 },
    { name: 'Kunkka', 'Dragon Knight': -0.259 },
    { name: 'Legion Commander', 'Dragon Knight': -1.857 },
    { name: 'Leshrac', 'Dragon Knight': -0.05 },
    { name: 'Lich', 'Dragon Knight': 0.907 },
    { name: 'Lifestealer', 'Dragon Knight': 0.363 },
    { name: 'Lina', 'Dragon Knight': 0.329 },
    { name: 'Lion', 'Dragon Knight': -1.081 },
    { name: 'Lone Druid', 'Dragon Knight': 0.031 },
    { name: 'Luna', 'Dragon Knight': -0.069 },
    { name: 'Lycan', 'Dragon Knight': -1.71 },
    { name: 'Magnus', 'Dragon Knight': 0.384 },
    { name: 'Marci', 'Dragon Knight': -0.003 },
    { name: 'Mars', 'Dragon Knight': 0.367 },
    { name: 'Medusa', 'Dragon Knight': -0.745 },
    { name: 'Meepo', 'Dragon Knight': 1.521 },
    { name: 'Mirana', 'Dragon Knight': -0.398 },
    { name: 'Monkey King', 'Dragon Knight': 0.521 },
    { name: 'Morphling', 'Dragon Knight': -0.878 },
    { name: 'Muerta', 'Dragon Knight': 1.08 },
    { name: 'Naga Siren', 'Dragon Knight': -0.384 },
    { name: "Nature's Prophet", 'Dragon Knight': -0.961 },
    { name: 'Necrophos', 'Dragon Knight': 1.438 },
    { name: 'Night Stalker', 'Dragon Knight': -0.801 },
    { name: 'Nyx Assassin', 'Dragon Knight': -1.692 },
    { name: 'Ogre Magi', 'Dragon Knight': 0.999 },
    { name: 'Omniknight', 'Dragon Knight': -0.184 },
    { name: 'Oracle', 'Dragon Knight': -0.912 },
    { name: 'Outworld Destroyer', 'Dragon Knight': 0.915 },
    { name: 'Pangolier', 'Dragon Knight': -0.747 },
    { name: 'Phantom Assassin', 'Dragon Knight': -0.612 },
    { name: 'Phantom Lancer', 'Dragon Knight': 0.729 },
    { name: 'Phoenix', 'Dragon Knight': 0.381 },
    { name: 'Primal Beast', 'Dragon Knight': -0.713 },
    { name: 'Puck', 'Dragon Knight': -2.382 },
    { name: 'Pudge', 'Dragon Knight': -0.116 },
    { name: 'Pugna', 'Dragon Knight': -0.302 },
    { name: 'Queen of Pain', 'Dragon Knight': -0.351 },
    { name: 'Razor', 'Dragon Knight': 0.801 },
    { name: 'Riki', 'Dragon Knight': -1.502 },
    { name: 'Ringmaster', 'Dragon Knight': 1.129 },
    { name: 'Rubick', 'Dragon Knight': -0.152 },
    { name: 'Sand King', 'Dragon Knight': 1.235 },
    { name: 'Shadow Demon', 'Dragon Knight': -0.065 },
    { name: 'Shadow Fiend', 'Dragon Knight': -0.943 },
    { name: 'Shadow Shaman', 'Dragon Knight': -0.498 },
    { name: 'Silencer', 'Dragon Knight': -1.374 },
    { name: 'Skywrath Mage', 'Dragon Knight': -1.607 },
    { name: 'Slardar', 'Dragon Knight': -0.757 },
    { name: 'Slark', 'Dragon Knight': 1.974 },
    { name: 'Snapfire', 'Dragon Knight': -0.16 },
    { name: 'Sniper', 'Dragon Knight': 0.195 },
    { name: 'Spectre', 'Dragon Knight': 0.182 },
    { name: 'Spirit Breaker', 'Dragon Knight': 0.113 },
    { name: 'Storm Spirit', 'Dragon Knight': -0.61 },
    { name: 'Sven', 'Dragon Knight': -1.021 },
    { name: 'Techies', 'Dragon Knight': -0.182 },
    { name: 'Templar Assassin', 'Dragon Knight': -1.011 },
    { name: 'Terrorblade', 'Dragon Knight': 1.972 },
    { name: 'Tidehunter', 'Dragon Knight': 0.326 },
    { name: 'Timbersaw', 'Dragon Knight': 0.759 },
    { name: 'Tinker', 'Dragon Knight': -0.063 },
    { name: 'Tiny', 'Dragon Knight': -0.379 },
    { name: 'Treant Protector', 'Dragon Knight': 1.13 },
    { name: 'Troll Warlord', 'Dragon Knight': -0.247 },
    { name: 'Tusk', 'Dragon Knight': -0.25 },
    { name: 'Underlord', 'Dragon Knight': 1.484 },
    { name: 'Undying', 'Dragon Knight': 0.846 },
    { name: 'Ursa', 'Dragon Knight': 0.251 },
    { name: 'Vengeful Spirit', 'Dragon Knight': -0.522 },
    { name: 'Venomancer', 'Dragon Knight': 1.783 },
    { name: 'Viper', 'Dragon Knight': 3.079 },
    { name: 'Visage', 'Dragon Knight': 0.443 },
    { name: 'Void Spirit', 'Dragon Knight': -1.459 },
    { name: 'Warlock', 'Dragon Knight': 0.497 },
    { name: 'Weaver', 'Dragon Knight': -1.075 },
    { name: 'Windranger', 'Dragon Knight': 0.556 },
    { name: 'Winter Wyvern', 'Dragon Knight': 1.256 },
    { name: 'Witch Doctor', 'Dragon Knight': 1.269 },
    { name: 'Wraith King', 'Dragon Knight': -1.84 },
    { name: 'Zeus', 'Dragon Knight': -0.444 },
],
"Earth Spirit": [
    { name: 'Abaddon', 'Earth Spirit': 1.56 },
    { name: 'Alchemist', 'Earth Spirit': 0.588 },
    { name: 'Ancient Apparition', 'Earth Spirit': -0.659 },
    { name: 'Anti-Mage', 'Earth Spirit': 1.223 },
    { name: 'Arc Warden', 'Earth Spirit': -1.147 },
    { name: 'Axe', 'Earth Spirit': -1.037 },
    { name: 'Bane', 'Earth Spirit': -0.093 },
    { name: 'Batrider', 'Earth Spirit': 2.537 },
    { name: 'Beastmaster', 'Earth Spirit': -0.138 },
    { name: 'Bloodseeker', 'Earth Spirit': -0.509 },
    { name: 'Bounty Hunter', 'Earth Spirit': 0.95 },
    { name: 'Brewmaster', 'Earth Spirit': -0.113 },
    { name: 'Bristleback', 'Earth Spirit': -1.235 },
    { name: 'Broodmother', 'Earth Spirit': 0.61 },
    { name: 'Centaur Warrunner', 'Earth Spirit': 0.293 },
    { name: 'Chaos Knight', 'Earth Spirit': 0.694 },
    { name: 'Chen', 'Earth Spirit': 0.386 },
    { name: 'Clinkz', 'Earth Spirit': -1.491 },
    { name: 'Clockwerk', 'Earth Spirit': 0.31 },
    { name: 'Crystal Maiden', 'Earth Spirit': -0.926 },
    { name: 'Dark Seer', 'Earth Spirit': -0.751 },
    { name: 'Dark Willow', 'Earth Spirit': -0.006 },
    { name: 'Dawnbreaker', 'Earth Spirit': 0.465 },
    { name: 'Dazzle', 'Earth Spirit': -0.782 },
    { name: 'Death Prophet', 'Earth Spirit': 1.344 },
    { name: 'Disruptor', 'Earth Spirit': -0.455 },
    { name: 'Doom', 'Earth Spirit': 0.648 },
    { name: 'Dragon Knight', 'Earth Spirit': -0.455 },
    { name: 'Drow Ranger', 'Earth Spirit': -2.395 },
    { name: 'Earthshaker', 'Earth Spirit': 0.53 },
    { name: 'Elder Titan', 'Earth Spirit': 1.33 },
    { name: 'Ember Spirit', 'Earth Spirit': 0.115 },
    { name: 'Enchantress', 'Earth Spirit': -0.671 },
    { name: 'Enigma', 'Earth Spirit': 2.327 },
    { name: 'Faceless Void', 'Earth Spirit': 0.452 },
    { name: 'Grimstroke', 'Earth Spirit': 0.444 },
    { name: 'Gyrocopter', 'Earth Spirit': 0.619 },
    { name: 'Hoodwink', 'Earth Spirit': -1.555 },
    { name: 'Huskar', 'Earth Spirit': 2.667 },
    { name: 'Invoker', 'Earth Spirit': -0.304 },
    { name: 'Io', 'Earth Spirit': 2.622 },
    { name: 'Jakiro', 'Earth Spirit': -0.3 },
    { name: 'Juggernaut', 'Earth Spirit': 1.952 },
    { name: 'Keeper of the Light', 'Earth Spirit': -1.491 },
    { name: 'Kunkka', 'Earth Spirit': -0.188 },
    { name: 'Legion Commander', 'Earth Spirit': 1.77 },
    { name: 'Leshrac', 'Earth Spirit': 0.51 },
    { name: 'Lich', 'Earth Spirit': -0.815 },
    { name: 'Lifestealer', 'Earth Spirit': 3.034 },
    { name: 'Lina', 'Earth Spirit': 0.576 },
    { name: 'Lion', 'Earth Spirit': 0.826 },
    { name: 'Lone Druid', 'Earth Spirit': 2.633 },
    { name: 'Luna', 'Earth Spirit': 0.515 },
    { name: 'Lycan', 'Earth Spirit': 0.003 },
    { name: 'Magnus', 'Earth Spirit': 0.363 },
    { name: 'Marci', 'Earth Spirit': 2.189 },
    { name: 'Mars', 'Earth Spirit': -1.931 },
    { name: 'Medusa', 'Earth Spirit': -0.964 },
    { name: 'Meepo', 'Earth Spirit': 2.878 },
    { name: 'Mirana', 'Earth Spirit': -0.636 },
    { name: 'Monkey King', 'Earth Spirit': 0.825 },
    { name: 'Morphling', 'Earth Spirit': -1.203 },
    { name: 'Muerta', 'Earth Spirit': 0.361 },
    { name: 'Naga Siren', 'Earth Spirit': 0.66 },
    { name: "Nature's Prophet", 'Earth Spirit': -0.782 },
    { name: 'Necrophos', 'Earth Spirit': 0.964 },
    { name: 'Night Stalker', 'Earth Spirit': -0.257 },
    { name: 'Nyx Assassin', 'Earth Spirit': -1.713 },
    { name: 'Ogre Magi', 'Earth Spirit': 0.599 },
    { name: 'Omniknight', 'Earth Spirit': 1.365 },
    { name: 'Oracle', 'Earth Spirit': 0.363 },
    { name: 'Outworld Destroyer', 'Earth Spirit': -0.684 },
    { name: 'Pangolier', 'Earth Spirit': 0.964 },
    { name: 'Phantom Assassin', 'Earth Spirit': -1.855 },
    { name: 'Phantom Lancer', 'Earth Spirit': -0.389 },
    { name: 'Phoenix', 'Earth Spirit': 0.222 },
    { name: 'Primal Beast', 'Earth Spirit': 1.808 },
    { name: 'Puck', 'Earth Spirit': -0.999 },
    { name: 'Pudge', 'Earth Spirit': -0.96 },
    { name: 'Pugna', 'Earth Spirit': 0.349 },
    { name: 'Queen of Pain', 'Earth Spirit': -1.386 },
    { name: 'Razor', 'Earth Spirit': -1.294 },
    { name: 'Riki', 'Earth Spirit': 0.708 },
    { name: 'Ringmaster', 'Earth Spirit': -0.581 },
    { name: 'Rubick', 'Earth Spirit': -2.06 },
    { name: 'Sand King', 'Earth Spirit': -1.066 },
    { name: 'Shadow Demon', 'Earth Spirit': -2.003 },
    { name: 'Shadow Fiend', 'Earth Spirit': 1.296 },
    { name: 'Shadow Shaman', 'Earth Spirit': 1.607 },
    { name: 'Silencer', 'Earth Spirit': 1.503 },
    { name: 'Skywrath Mage', 'Earth Spirit': 0.938 },
    { name: 'Slardar', 'Earth Spirit': 0.784 },
    { name: 'Slark', 'Earth Spirit': 0.838 },
    { name: 'Snapfire', 'Earth Spirit': 0.308 },
    { name: 'Sniper', 'Earth Spirit': -2.326 },
    { name: 'Spectre', 'Earth Spirit': -1.141 },
    { name: 'Spirit Breaker', 'Earth Spirit': 0.927 },
    { name: 'Storm Spirit', 'Earth Spirit': -0.375 },
    { name: 'Sven', 'Earth Spirit': 1.463 },
    { name: 'Techies', 'Earth Spirit': -0.197 },
    { name: 'Templar Assassin', 'Earth Spirit': 0.403 },
    { name: 'Terrorblade', 'Earth Spirit': 0.763 },
    { name: 'Tidehunter', 'Earth Spirit': 1.176 },
    { name: 'Timbersaw', 'Earth Spirit': -0.821 },
    { name: 'Tinker', 'Earth Spirit': 0.791 },
    { name: 'Tiny', 'Earth Spirit': -0.094 },
    { name: 'Treant Protector', 'Earth Spirit': -0.027 },
    { name: 'Troll Warlord', 'Earth Spirit': 3.578 },
    { name: 'Tusk', 'Earth Spirit': -0.924 },
    { name: 'Underlord', 'Earth Spirit': -0.723 },
    { name: 'Undying', 'Earth Spirit': 0.539 },
    { name: 'Ursa', 'Earth Spirit': 1.261 },
    { name: 'Vengeful Spirit', 'Earth Spirit': 0.356 },
    { name: 'Venomancer', 'Earth Spirit': -1.053 },
    { name: 'Viper', 'Earth Spirit': -0.798 },
    { name: 'Visage', 'Earth Spirit': 0.155 },
    { name: 'Void Spirit', 'Earth Spirit': 0.199 },
    { name: 'Warlock', 'Earth Spirit': -1.084 },
    { name: 'Weaver', 'Earth Spirit': 0.778 },
    { name: 'Windranger', 'Earth Spirit': -1.278 },
    { name: 'Winter Wyvern', 'Earth Spirit': -0.203 },
    { name: 'Witch Doctor', 'Earth Spirit': 1.516 },
    { name: 'Wraith King', 'Earth Spirit': 1.066 },
    { name: 'Zeus', 'Earth Spirit': -0.358 },
],
"Earthshaker": [
    { name: 'Abaddon', 'Earthshaker': -2.535 },
    { name: 'Alchemist', 'Earthshaker': 0.659 },
    { name: 'Ancient Apparition', 'Earthshaker': 0.271 },
    { name: 'Anti-Mage', 'Earthshaker': -1.896 },
    { name: 'Arc Warden', 'Earthshaker': -1.485 },
    { name: 'Axe', 'Earthshaker': 0.12 },
    { name: 'Bane', 'Earthshaker': -0.216 },
    { name: 'Batrider', 'Earthshaker': 0.52 },
    { name: 'Beastmaster', 'Earthshaker': -0.649 },
    { name: 'Bloodseeker', 'Earthshaker': 0.689 },
    { name: 'Bounty Hunter', 'Earthshaker': -0.731 },
    { name: 'Brewmaster', 'Earthshaker': -1.384 },
    { name: 'Bristleback', 'Earthshaker': 1.832 },
    { name: 'Broodmother', 'Earthshaker': -6.763 },
    { name: 'Centaur Warrunner', 'Earthshaker': -0.493 },
    { name: 'Chaos Knight', 'Earthshaker': -2.341 },
    { name: 'Chen', 'Earthshaker': -0.959 },
    { name: 'Clinkz', 'Earthshaker': 1.641 },
    { name: 'Clockwerk', 'Earthshaker': 1.718 },
    { name: 'Crystal Maiden', 'Earthshaker': -0.232 },
    { name: 'Dark Seer', 'Earthshaker': -2.394 },
    { name: 'Dark Willow', 'Earthshaker': 0.193 },
    { name: 'Dawnbreaker', 'Earthshaker': -0.153 },
    { name: 'Dazzle', 'Earthshaker': 0.1 },
    { name: 'Death Prophet', 'Earthshaker': 0.84 },
    { name: 'Disruptor', 'Earthshaker': 0.904 },
    { name: 'Doom', 'Earthshaker': 1.118 },
    { name: 'Dragon Knight', 'Earthshaker': 1.001 },
    { name: 'Drow Ranger', 'Earthshaker': 0.79 },
    { name: 'Earth Spirit', 'Earthshaker': -0.315 },
    { name: 'Elder Titan', 'Earthshaker': 1.184 },
    { name: 'Ember Spirit', 'Earthshaker': -0.451 },
    { name: 'Enchantress', 'Earthshaker': 1.056 },
    { name: 'Enigma', 'Earthshaker': -0.767 },
    { name: 'Faceless Void', 'Earthshaker': -0.134 },
    { name: 'Grimstroke', 'Earthshaker': 0.936 },
    { name: 'Gyrocopter', 'Earthshaker': 1.295 },
    { name: 'Hoodwink', 'Earthshaker': 0.796 },
    { name: 'Huskar', 'Earthshaker': 1.628 },
    { name: 'Invoker', 'Earthshaker': 0.094 },
    { name: 'Io', 'Earthshaker': -2.044 },
    { name: 'Jakiro', 'Earthshaker': 0.759 },
    { name: 'Juggernaut', 'Earthshaker': 0.182 },
    { name: 'Keeper of the Light', 'Earthshaker': -0.244 },
    { name: 'Kunkka', 'Earthshaker': -0.435 },
    { name: 'Legion Commander', 'Earthshaker': -0.819 },
    { name: 'Leshrac', 'Earthshaker': 0.078 },
    { name: 'Lich', 'Earthshaker': 0.151 },
    { name: 'Lifestealer', 'Earthshaker': 0.746 },
    { name: 'Lina', 'Earthshaker': 0.61 },
    { name: 'Lion', 'Earthshaker': 0.902 },
    { name: 'Lone Druid', 'Earthshaker': -2.537 },
    { name: 'Luna', 'Earthshaker': 0.535 },
    { name: 'Lycan', 'Earthshaker': -2.947 },
    { name: 'Magnus', 'Earthshaker': -0.834 },
    { name: 'Marci', 'Earthshaker': -1.411 },
    { name: 'Mars', 'Earthshaker': 0.069 },
    { name: 'Medusa', 'Earthshaker': -1.225 },
    { name: 'Meepo', 'Earthshaker': -6.908 },
    { name: 'Mirana', 'Earthshaker': 0.438 },
    { name: 'Monkey King', 'Earthshaker': 0.654 },
    { name: 'Morphling', 'Earthshaker': -0.922 },
    { name: 'Muerta', 'Earthshaker': 0.881 },
    { name: 'Naga Siren', 'Earthshaker': -2.84 },
    { name: "Nature's Prophet", 'Earthshaker': 0.033 },
    { name: 'Necrophos', 'Earthshaker': 1.198 },
    { name: 'Night Stalker', 'Earthshaker': 2.133 },
    { name: 'Nyx Assassin', 'Earthshaker': -0.131 },
    { name: 'Ogre Magi', 'Earthshaker': 0.255 },
    { name: 'Omniknight', 'Earthshaker': -1.701 },
    { name: 'Oracle', 'Earthshaker': -1.676 },
    { name: 'Outworld Destroyer', 'Earthshaker': -0.634 },
    { name: 'Pangolier', 'Earthshaker': -0.378 },
    { name: 'Phantom Assassin', 'Earthshaker': 0.742 },
    { name: 'Phantom Lancer', 'Earthshaker': -2.025 },
    { name: 'Phoenix', 'Earthshaker': 0.277 },
    { name: 'Primal Beast', 'Earthshaker': -0.764 },
    { name: 'Puck', 'Earthshaker': 0.103 },
    { name: 'Pudge', 'Earthshaker': -0.701 },
    { name: 'Pugna', 'Earthshaker': -1.154 },
    { name: 'Queen of Pain', 'Earthshaker': -0.026 },
    { name: 'Razor', 'Earthshaker': 0.534 },
    { name: 'Riki', 'Earthshaker': 0.33 },
    { name: 'Ringmaster', 'Earthshaker': -0.48 },
    { name: 'Rubick', 'Earthshaker': -0.396 },
    { name: 'Sand King', 'Earthshaker': 0.746 },
    { name: 'Shadow Demon', 'Earthshaker': -1.552 },
    { name: 'Shadow Fiend', 'Earthshaker': -0.15 },
    { name: 'Shadow Shaman', 'Earthshaker': -0.048 },
    { name: 'Silencer', 'Earthshaker': 1.221 },
    { name: 'Skywrath Mage', 'Earthshaker': 0.467 },
    { name: 'Slardar', 'Earthshaker': 0.408 },
    { name: 'Slark', 'Earthshaker': -2.81 },
    { name: 'Snapfire', 'Earthshaker': 0.78 },
    { name: 'Sniper', 'Earthshaker': 1.902 },
    { name: 'Spectre', 'Earthshaker': 1.549 },
    { name: 'Spirit Breaker', 'Earthshaker': 0.249 },
    { name: 'Storm Spirit', 'Earthshaker': -0.466 },
    { name: 'Sven', 'Earthshaker': -0.303 },
    { name: 'Techies', 'Earthshaker': 0.698 },
    { name: 'Templar Assassin', 'Earthshaker': 2.15 },
    { name: 'Terrorblade', 'Earthshaker': -1.303 },
    { name: 'Tidehunter', 'Earthshaker': 0.99 },
    { name: 'Timbersaw', 'Earthshaker': -0.267 },
    { name: 'Tinker', 'Earthshaker': -1.669 },
    { name: 'Tiny', 'Earthshaker': 0.796 },
    { name: 'Treant Protector', 'Earthshaker': -0.411 },
    { name: 'Troll Warlord', 'Earthshaker': 0.386 },
    { name: 'Tusk', 'Earthshaker': -0.428 },
    { name: 'Underlord', 'Earthshaker': -0.205 },
    { name: 'Undying', 'Earthshaker': -1.199 },
    { name: 'Ursa', 'Earthshaker': -0.418 },
    { name: 'Vengeful Spirit', 'Earthshaker': -0.359 },
    { name: 'Venomancer', 'Earthshaker': 1.493 },
    { name: 'Viper', 'Earthshaker': 1.485 },
    { name: 'Visage', 'Earthshaker': -2.087 },
    { name: 'Void Spirit', 'Earthshaker': -0.819 },
    { name: 'Warlock', 'Earthshaker': -1.707 },
    { name: 'Weaver', 'Earthshaker': -0.493 },
    { name: 'Windranger', 'Earthshaker': 0.244 },
    { name: 'Winter Wyvern', 'Earthshaker': 0.404 },
    { name: 'Witch Doctor', 'Earthshaker': -0.544 },
    { name: 'Wraith King', 'Earthshaker': 0.722 },
    { name: 'Zeus', 'Earthshaker': 0.627 },
],
"Elder Titan": [
    { name: 'Abaddon', 'Elder Titan': -3.516 },
    { name: 'Alchemist', 'Elder Titan': -0.641 },
    { name: 'Ancient Apparition', 'Elder Titan': -0.223 },
    { name: 'Anti-Mage', 'Elder Titan': -0.645 },
    { name: 'Arc Warden', 'Elder Titan': -2.161 },
    { name: 'Axe', 'Elder Titan': 1.084 },
    { name: 'Bane', 'Elder Titan': 0.359 },
    { name: 'Batrider', 'Elder Titan': 4.144 },
    { name: 'Beastmaster', 'Elder Titan': -0.832 },
    { name: 'Bloodseeker', 'Elder Titan': -0.54 },
    { name: 'Bounty Hunter', 'Elder Titan': 0.268 },
    { name: 'Brewmaster', 'Elder Titan': 1.384 },
    { name: 'Bristleback', 'Elder Titan': 1.499 },
    { name: 'Broodmother', 'Elder Titan': 0.612 },
    { name: 'Centaur Warrunner', 'Elder Titan': -5.433 },
    { name: 'Chaos Knight', 'Elder Titan': 0.012 },
    { name: 'Chen', 'Elder Titan': 0.81 },
    { name: 'Clinkz', 'Elder Titan': 3.166 },
    { name: 'Clockwerk', 'Elder Titan': -0.421 },
    { name: 'Crystal Maiden', 'Elder Titan': 0.348 },
    { name: 'Dark Seer', 'Elder Titan': 2.079 },
    { name: 'Dark Willow', 'Elder Titan': 0.232 },
    { name: 'Dawnbreaker', 'Elder Titan': -0.273 },
    { name: 'Dazzle', 'Elder Titan': 0.703 },
    { name: 'Death Prophet', 'Elder Titan': -0.975 },
    { name: 'Disruptor', 'Elder Titan': 0.159 },
    { name: 'Doom', 'Elder Titan': 0.981 },
    { name: 'Dragon Knight', 'Elder Titan': -0.514 },
    { name: 'Drow Ranger', 'Elder Titan': 0.051 },
    { name: 'Earth Spirit', 'Elder Titan': -1.309 },
    { name: 'Earthshaker', 'Elder Titan': -1.487 },
    { name: 'Ember Spirit', 'Elder Titan': -1.368 },
    { name: 'Enchantress', 'Elder Titan': 0.818 },
    { name: 'Enigma', 'Elder Titan': -0.445 },
    { name: 'Faceless Void', 'Elder Titan': -2.794 },
    { name: 'Grimstroke', 'Elder Titan': -0.861 },
    { name: 'Gyrocopter', 'Elder Titan': 0.58 },
    { name: 'Hoodwink', 'Elder Titan': 1.938 },
    { name: 'Huskar', 'Elder Titan': 1.898 },
    { name: 'Invoker', 'Elder Titan': 0.613 },
    { name: 'Io', 'Elder Titan': -1.336 },
    { name: 'Jakiro', 'Elder Titan': -0.221 },
    { name: 'Juggernaut', 'Elder Titan': 0.592 },
    { name: 'Keeper of the Light', 'Elder Titan': 1.267 },
    { name: 'Kunkka', 'Elder Titan': -1.106 },
    { name: 'Legion Commander', 'Elder Titan': 0.182 },
    { name: 'Leshrac', 'Elder Titan': 0.819 },
    { name: 'Lich', 'Elder Titan': 0.503 },
    { name: 'Lifestealer', 'Elder Titan': 0.449 },
    { name: 'Lina', 'Elder Titan': 0.261 },
    { name: 'Lion', 'Elder Titan': 0.515 },
    { name: 'Lone Druid', 'Elder Titan': -4.275 },
    { name: 'Luna', 'Elder Titan': -0.927 },
    { name: 'Lycan', 'Elder Titan': 0.226 },
    { name: 'Magnus', 'Elder Titan': -0.335 },
    { name: 'Marci', 'Elder Titan': 0.415 },
    { name: 'Mars', 'Elder Titan': -1.221 },
    { name: 'Medusa', 'Elder Titan': 0.336 },
    { name: 'Meepo', 'Elder Titan': -11.492 },
    { name: 'Mirana', 'Elder Titan': 1.445 },
    { name: 'Monkey King', 'Elder Titan': 0.629 },
    { name: 'Morphling', 'Elder Titan': -1.032 },
    { name: 'Muerta', 'Elder Titan': 2.057 },
    { name: 'Naga Siren', 'Elder Titan': -1.276 },
    { name: "Nature's Prophet", 'Elder Titan': 3.134 },
    { name: 'Necrophos', 'Elder Titan': -0.456 },
    { name: 'Night Stalker', 'Elder Titan': -0.658 },
    { name: 'Nyx Assassin', 'Elder Titan': 0.173 },
    { name: 'Ogre Magi', 'Elder Titan': -0.254 },
    { name: 'Omniknight', 'Elder Titan': -1.73 },
    { name: 'Oracle', 'Elder Titan': 0.992 },
    { name: 'Outworld Destroyer', 'Elder Titan': -0.745 },
    { name: 'Pangolier', 'Elder Titan': -0.128 },
    { name: 'Phantom Assassin', 'Elder Titan': 3.724 },
    { name: 'Phantom Lancer', 'Elder Titan': 0.086 },
    { name: 'Phoenix', 'Elder Titan': -0.083 },
    { name: 'Primal Beast', 'Elder Titan': 1.227 },
    { name: 'Puck', 'Elder Titan': 0.661 },
    { name: 'Pudge', 'Elder Titan': -1.039 },
    { name: 'Pugna', 'Elder Titan': 1.169 },
    { name: 'Queen of Pain', 'Elder Titan': -0.589 },
    { name: 'Razor', 'Elder Titan': -1.54 },
    { name: 'Riki', 'Elder Titan': 0.509 },
    { name: 'Ringmaster', 'Elder Titan': -0.874 },
    { name: 'Rubick', 'Elder Titan': -2.098 },
    { name: 'Sand King', 'Elder Titan': -0.609 },
    { name: 'Shadow Demon', 'Elder Titan': 0.757 },
    { name: 'Shadow Fiend', 'Elder Titan': 0.911 },
    { name: 'Shadow Shaman', 'Elder Titan': 0.84 },
    { name: 'Silencer', 'Elder Titan': 0.989 },
    { name: 'Skywrath Mage', 'Elder Titan': 1.173 },
    { name: 'Slardar', 'Elder Titan': -2.743 },
    { name: 'Slark', 'Elder Titan': -3.558 },
    { name: 'Snapfire', 'Elder Titan': 1.206 },
    { name: 'Sniper', 'Elder Titan': 1.257 },
    { name: 'Spectre', 'Elder Titan': -1.115 },
    { name: 'Spirit Breaker', 'Elder Titan': 1.517 },
    { name: 'Storm Spirit', 'Elder Titan': 1.746 },
    { name: 'Sven', 'Elder Titan': 1.335 },
    { name: 'Techies', 'Elder Titan': 1.394 },
    { name: 'Templar Assassin', 'Elder Titan': 2.677 },
    { name: 'Terrorblade', 'Elder Titan': -1.175 },
    { name: 'Tidehunter', 'Elder Titan': -3.454 },
    { name: 'Timbersaw', 'Elder Titan': -1.497 },
    { name: 'Tinker', 'Elder Titan': -2.572 },
    { name: 'Tiny', 'Elder Titan': -2.273 },
    { name: 'Treant Protector', 'Elder Titan': 0.895 },
    { name: 'Troll Warlord', 'Elder Titan': 4.586 },
    { name: 'Tusk', 'Elder Titan': -0.251 },
    { name: 'Underlord', 'Elder Titan': -1.656 },
    { name: 'Undying', 'Elder Titan': -2.284 },
    { name: 'Ursa', 'Elder Titan': 0.827 },
    { name: 'Vengeful Spirit', 'Elder Titan': -0.69 },
    { name: 'Venomancer', 'Elder Titan': 0.889 },
    { name: 'Viper', 'Elder Titan': 0.62 },
    { name: 'Visage', 'Elder Titan': 3.133 },
    { name: 'Void Spirit', 'Elder Titan': -0.933 },
    { name: 'Warlock', 'Elder Titan': -0.693 },
    { name: 'Weaver', 'Elder Titan': 1.17 },
    { name: 'Windranger', 'Elder Titan': 1.88 },
    { name: 'Winter Wyvern', 'Elder Titan': 1.646 },
    { name: 'Witch Doctor', 'Elder Titan': -0.268 },
    { name: 'Wraith King', 'Elder Titan': 2.916 },
    { name: 'Zeus', 'Elder Titan': 1.451 },
],
"Huskar": [
    { name: 'Abaddon', 'Huskar': 0.489 },
    { name: 'Alchemist', 'Huskar': -1.478 },
    { name: 'Ancient Apparition', 'Huskar': 7.961 },
    { name: 'Anti-Mage', 'Huskar': -5.915 },
    { name: 'Arc Warden', 'Huskar': 0.505 },
    { name: 'Axe', 'Huskar': 2.529 },
    { name: 'Bane', 'Huskar': -0.814 },
    { name: 'Batrider', 'Huskar': -1.422 },
    { name: 'Beastmaster', 'Huskar': -0.08 },
    { name: 'Bloodseeker', 'Huskar': 0.746 },
    { name: 'Bounty Hunter', 'Huskar': -2.202 },
    { name: 'Brewmaster', 'Huskar': 0.236 },
    { name: 'Bristleback', 'Huskar': 2.666 },
    { name: 'Broodmother', 'Huskar': -0.024 },
    { name: 'Centaur Warrunner', 'Huskar': -1.522 },
    { name: 'Chaos Knight', 'Huskar': 4.475 },
    { name: 'Chen', 'Huskar': -2.33 },
    { name: 'Clinkz', 'Huskar': -0.379 },
    { name: 'Clockwerk', 'Huskar': -2.641 },
    { name: 'Crystal Maiden', 'Huskar': 0.075 },
    { name: 'Dark Seer', 'Huskar': 0.607 },
    { name: 'Dark Willow', 'Huskar': -0.524 },
    { name: 'Dawnbreaker', 'Huskar': -0.318 },
    { name: 'Dazzle', 'Huskar': 0.151 },
    { name: 'Death Prophet', 'Huskar': 0.64 },
    { name: 'Disruptor', 'Huskar': -1.443 },
    { name: 'Doom', 'Huskar': 2.381 },
    { name: 'Dragon Knight', 'Huskar': -0.668 },
    { name: 'Drow Ranger', 'Huskar': 1.208 },
    { name: 'Earth Spirit', 'Huskar': -2.593 },
    { name: 'Earthshaker', 'Huskar': -1.606 },
    { name: 'Elder Titan', 'Huskar': -1.942 },
    { name: 'Ember Spirit', 'Huskar': -6.179 },
    { name: 'Enchantress', 'Huskar': 1.049 },
    { name: 'Enigma', 'Huskar': 1.661 },
    { name: 'Faceless Void', 'Huskar': -2.583 },
    { name: 'Grimstroke', 'Huskar': -0.68 },
    { name: 'Gyrocopter', 'Huskar': -0.921 },
    { name: 'Hoodwink', 'Huskar': 1.886 },
    { name: 'Invoker', 'Huskar': 0.002 },
    { name: 'Io', 'Huskar': -1.517 },
    { name: 'Jakiro', 'Huskar': -0.085 },
    { name: 'Juggernaut', 'Huskar': -1.161 },
    { name: 'Keeper of the Light', 'Huskar': -2.689 },
    { name: 'Kunkka', 'Huskar': -1.918 },
    { name: 'Legion Commander', 'Huskar': 1.035 },
    { name: 'Leshrac', 'Huskar': 0.102 },
    { name: 'Lich', 'Huskar': 0.458 },
    { name: 'Lifestealer', 'Huskar': -0.858 },
    { name: 'Lina', 'Huskar': 1.127 },
    { name: 'Lion', 'Huskar': 0.76 },
    { name: 'Lone Druid', 'Huskar': -1.88 },
    { name: 'Luna', 'Huskar': 1.514 },
    { name: 'Lycan', 'Huskar': -2.393 },
    { name: 'Magnus', 'Huskar': -1.043 },
    { name: 'Marci', 'Huskar': -0.177 },
    { name: 'Mars', 'Huskar': -2.223 },
    { name: 'Medusa', 'Huskar': 0.519 },
    { name: 'Meepo', 'Huskar': -3.859 },
    { name: 'Mirana', 'Huskar': 0.325 },
    { name: 'Monkey King', 'Huskar': 0.2 },
    { name: 'Morphling', 'Huskar': 0.448 },
    { name: 'Muerta', 'Huskar': 0.911 },
    { name: 'Naga Siren', 'Huskar': 2.781 },
    { name: "Nature's Prophet", 'Huskar': -0.023 },
    { name: 'Necrophos', 'Huskar': 4.953 },
    { name: 'Night Stalker', 'Huskar': -3.986 },
    { name: 'Nyx Assassin', 'Huskar': -1.438 },
    { name: 'Ogre Magi', 'Huskar': -1.141 },
    { name: 'Omniknight', 'Huskar': -3.098 },
    { name: 'Oracle', 'Huskar': -1.992 },
    { name: 'Outworld Destroyer', 'Huskar': -1.177 },
    { name: 'Pangolier', 'Huskar': -4.413 },
    { name: 'Phantom Assassin', 'Huskar': 3.32 },
    { name: 'Phantom Lancer', 'Huskar': 2.372 },
    { name: 'Phoenix', 'Huskar': -2.693 },
    { name: 'Primal Beast', 'Huskar': -1.157 },
    { name: 'Puck', 'Huskar': -2.749 },
    { name: 'Pudge', 'Huskar': 0.631 },
    { name: 'Pugna', 'Huskar': -2.91 },
    { name: 'Queen of Pain', 'Huskar': -3.241 },
    { name: 'Razor', 'Huskar': 0.778 },
    { name: 'Riki', 'Huskar': 0.587 },
    { name: 'Ringmaster', 'Huskar': -1.002 },
    { name: 'Rubick', 'Huskar': -1.49 },
    { name: 'Sand King', 'Huskar': -0.968 },
    { name: 'Shadow Demon', 'Huskar': -0.112 },
    { name: 'Shadow Fiend', 'Huskar': 0.651 },
    { name: 'Shadow Shaman', 'Huskar': 1.122 },
    { name: 'Silencer', 'Huskar': -0.49 },
    { name: 'Skywrath Mage', 'Huskar': -0.598 },
    { name: 'Slardar', 'Huskar': -1.374 },
    { name: 'Slark', 'Huskar': -0.471 },
    { name: 'Snapfire', 'Huskar': -0.759 },
    { name: 'Sniper', 'Huskar': 1.655 },
    { name: 'Spectre', 'Huskar': 0.213 },
    { name: 'Spirit Breaker', 'Huskar': 0.087 },
    { name: 'Storm Spirit', 'Huskar': -1.798 },
    { name: 'Sven', 'Huskar': -1.286 },
    { name: 'Techies', 'Huskar': -0.153 },
    { name: 'Templar Assassin', 'Huskar': -0.763 },
    { name: 'Terrorblade', 'Huskar': 2.205 },
    { name: 'Tidehunter', 'Huskar': -2.043 },
    { name: 'Timbersaw', 'Huskar': 2.188 },
    { name: 'Tinker', 'Huskar': -1.635 },
    { name: 'Tiny', 'Huskar': -2.154 },
    { name: 'Treant Protector', 'Huskar': -1.473 },
    { name: 'Troll Warlord', 'Huskar': 1.299 },
    { name: 'Tusk', 'Huskar': 0.264 },
    { name: 'Underlord', 'Huskar': -1.191 },
    { name: 'Undying', 'Huskar': 1.099 },
    { name: 'Ursa', 'Huskar': -0.589 },
    { name: 'Vengeful Spirit', 'Huskar': 0.717 },
    { name: 'Venomancer', 'Huskar': -0.162 },
    { name: 'Viper', 'Huskar': 6.76 },
    { name: 'Visage', 'Huskar': -0.349 },
    { name: 'Void Spirit', 'Huskar': -3.871 },
    { name: 'Warlock', 'Huskar': 0.199 },
    { name: 'Weaver', 'Huskar': -0.701 },
    { name: 'Windranger', 'Huskar': 2.25 },
    { name: 'Winter Wyvern', 'Huskar': -2.108 },
    { name: 'Witch Doctor', 'Huskar': 1.578 },
    { name: 'Wraith King', 'Huskar': 1.926 },
    { name: 'Zeus', 'Huskar': -2.028 },
],
"Kunkka": [
    { name: 'Abaddon', 'Kunkka': -0.742 },
    { name: 'Alchemist', 'Kunkka': 1.7 },
    { name: 'Ancient Apparition', 'Kunkka': 1.139 },
    { name: 'Anti-Mage', 'Kunkka': 0.072 },
    { name: 'Arc Warden', 'Kunkka': -2.557 },
    { name: 'Axe', 'Kunkka': -0.492 },
    { name: 'Bane', 'Kunkka': 0.889 },
    { name: 'Batrider', 'Kunkka': 0.778 },
    { name: 'Beastmaster', 'Kunkka': -1.899 },
    { name: 'Bloodseeker', 'Kunkka': 0.921 },
    { name: 'Bounty Hunter', 'Kunkka': 1.098 },
    { name: 'Brewmaster', 'Kunkka': -0.13 },
    { name: 'Bristleback', 'Kunkka': 1.289 },
    { name: 'Broodmother', 'Kunkka': -1.828 },
    { name: 'Centaur Warrunner', 'Kunkka': -0.205 },
    { name: 'Chaos Knight', 'Kunkka': -3.005 },
    { name: 'Chen', 'Kunkka': -2.425 },
    { name: 'Clinkz', 'Kunkka': 0.426 },
    { name: 'Clockwerk', 'Kunkka': -0.274 },
    { name: 'Crystal Maiden', 'Kunkka': -0.42 },
    { name: 'Dark Seer', 'Kunkka': 0.32 },
    { name: 'Dark Willow', 'Kunkka': 0.156 },
    { name: 'Dawnbreaker', 'Kunkka': -0.526 },
    { name: 'Dazzle', 'Kunkka': 0.869 },
    { name: 'Death Prophet', 'Kunkka': 0.564 },
    { name: 'Disruptor', 'Kunkka': -0.184 },
    { name: 'Doom', 'Kunkka': 0.628 },
    { name: 'Dragon Knight', 'Kunkka': 0.14 },
    { name: 'Drow Ranger', 'Kunkka': -0.236 },
    { name: 'Earth Spirit', 'Kunkka': 0.267 },
    { name: 'Earthshaker', 'Kunkka': 0.309 },
    { name: 'Elder Titan', 'Kunkka': 1.062 },
    { name: 'Ember Spirit', 'Kunkka': 2.102 },
    { name: 'Enchantress', 'Kunkka': 1.094 },
    { name: 'Enigma', 'Kunkka': -1.368 },
    { name: 'Faceless Void', 'Kunkka': 2.381 },
    { name: 'Grimstroke', 'Kunkka': 0.235 },
    { name: 'Gyrocopter', 'Kunkka': -0.644 },
    { name: 'Hoodwink', 'Kunkka': 0.08 },
    { name: 'Huskar', 'Kunkka': 1.935 },
    { name: 'Invoker', 'Kunkka': 0.531 },
    { name: 'Io', 'Kunkka': -1.6 },
    { name: 'Jakiro', 'Kunkka': 0.446 },
    { name: 'Juggernaut', 'Kunkka': 1.802 },
    { name: 'Keeper of the Light', 'Kunkka': 1.696 },
    { name: 'Legion Commander', 'Kunkka': -2.25 },
    { name: 'Leshrac', 'Kunkka': -0.681 },
    { name: 'Lich', 'Kunkka': -0.235 },
    { name: 'Lifestealer', 'Kunkka': 3.067 },
    { name: 'Lina', 'Kunkka': -0.707 },
    { name: 'Lion', 'Kunkka': -0.713 },
    { name: 'Lone Druid', 'Kunkka': -0.988 },
    { name: 'Luna', 'Kunkka': -1.91 },
    { name: 'Lycan', 'Kunkka': -0.619 },
    { name: 'Magnus', 'Kunkka': -0.21 },
    { name: 'Marci', 'Kunkka': -2.152 },
    { name: 'Mars', 'Kunkka': -1.206 },
    { name: 'Medusa', 'Kunkka': -1.825 },
    { name: 'Meepo', 'Kunkka': -2.539 },
    { name: 'Mirana', 'Kunkka': -0.582 },
    { name: 'Monkey King', 'Kunkka': 1.147 },
    { name: 'Morphling', 'Kunkka': 1.113 },
    { name: 'Muerta', 'Kunkka': -2.084 },
    { name: 'Naga Siren', 'Kunkka': 0.068 },
    { name: "Nature's Prophet", 'Kunkka': 0.564 },
    { name: 'Necrophos', 'Kunkka': 1.462 },
    { name: 'Night Stalker', 'Kunkka': -0.656 },
    { name: 'Nyx Assassin', 'Kunkka': -1.244 },
    { name: 'Ogre Magi', 'Kunkka': 1.208 },
    { name: 'Omniknight', 'Kunkka': 0.617 },
    { name: 'Oracle', 'Kunkka': -0.091 },
    { name: 'Outworld Destroyer', 'Kunkka': -0.597 },
    { name: 'Pangolier', 'Kunkka': 1.279 },
    { name: 'Phantom Assassin', 'Kunkka': 0.495 },
    { name: 'Phantom Lancer', 'Kunkka': -0.434 },
    { name: 'Phoenix', 'Kunkka': 0.41 },
    { name: 'Primal Beast', 'Kunkka': -0.245 },
    { name: 'Puck', 'Kunkka': 0.293 },
    { name: 'Pudge', 'Kunkka': -1.635 },
    { name: 'Pugna', 'Kunkka': 0.467 },
    { name: 'Queen of Pain', 'Kunkka': -0.082 },
    { name: 'Razor', 'Kunkka': -0.003 },
    { name: 'Riki', 'Kunkka': -0.119 },
    { name: 'Ringmaster', 'Kunkka': -0.091 },
    { name: 'Rubick', 'Kunkka': 1.585 },
    { name: 'Sand King', 'Kunkka': -0.211 },
    { name: 'Shadow Demon', 'Kunkka': -0.437 },
    { name: 'Shadow Fiend', 'Kunkka': -0.662 },
    { name: 'Shadow Shaman', 'Kunkka': -0.166 },
    { name: 'Silencer', 'Kunkka': -0.092 },
    { name: 'Skywrath Mage', 'Kunkka': -0.343 },
    { name: 'Slardar', 'Kunkka': 1.892 },
    { name: 'Slark', 'Kunkka': -0.633 },
    { name: 'Snapfire', 'Kunkka': -0.339 },
    { name: 'Sniper', 'Kunkka': -0.294 },
    { name: 'Spectre', 'Kunkka': -0.909 },
    { name: 'Spirit Breaker', 'Kunkka': -0.428 },
    { name: 'Storm Spirit', 'Kunkka': 0.21 },
    { name: 'Sven', 'Kunkka': 0.132 },
    { name: 'Techies', 'Kunkka': -0.048 },
    { name: 'Templar Assassin', 'Kunkka': 0.448 },
    { name: 'Terrorblade', 'Kunkka': -0.229 },
    { name: 'Tidehunter', 'Kunkka': -0.159 },
    { name: 'Timbersaw', 'Kunkka': 2.053 },
    { name: 'Tinker', 'Kunkka': 1.61 },
    { name: 'Tiny', 'Kunkka': -0.536 },
    { name: 'Treant Protector', 'Kunkka': -0.313 },
    { name: 'Troll Warlord', 'Kunkka': -1.221 },
    { name: 'Tusk', 'Kunkka': 0.292 },
    { name: 'Underlord', 'Kunkka': 1.048 },
    { name: 'Undying', 'Kunkka': 1.848 },
    { name: 'Ursa', 'Kunkka': 0.463 },
    { name: 'Vengeful Spirit', 'Kunkka': -0.904 },
    { name: 'Venomancer', 'Kunkka': 1.584 },
    { name: 'Viper', 'Kunkka': 0.372 },
    { name: 'Visage', 'Kunkka': 0.766 },
    { name: 'Void Spirit', 'Kunkka': 0.191 },
    { name: 'Warlock', 'Kunkka': 0.606 },
    { name: 'Weaver', 'Kunkka': 0.654 },
    { name: 'Windranger', 'Kunkka': -0.867 },
    { name: 'Winter Wyvern', 'Kunkka': 1.407 },
    { name: 'Witch Doctor', 'Kunkka': 0.132 },
    { name: 'Wraith King', 'Kunkka': -0.107 },
    { name: 'Zeus', 'Kunkka': -0.033 },
],
"Legion Commander": [
    { name: 'Abaddon', 'Legion Commander': 0.944 },
    { name: 'Alchemist', 'Legion Commander': 0.534 },
    { name: 'Ancient Apparition', 'Legion Commander': -0.12 },
    { name: 'Anti-Mage', 'Legion Commander': -2.104 },
    { name: 'Arc Warden', 'Legion Commander': -2.003 },
    { name: 'Axe', 'Legion Commander': 0.951 },
    { name: 'Bane', 'Legion Commander': 0.983 },
    { name: 'Batrider', 'Legion Commander': 0.847 },
    { name: 'Beastmaster', 'Legion Commander': 1.038 },
    { name: 'Bloodseeker', 'Legion Commander': -1.596 },
    { name: 'Bounty Hunter', 'Legion Commander': -1.416 },
    { name: 'Brewmaster', 'Legion Commander': -0.538 },
    { name: 'Bristleback', 'Legion Commander': -3.738 },
    { name: 'Broodmother', 'Legion Commander': -0.891 },
    { name: 'Centaur Warrunner', 'Legion Commander': 0.328 },
    { name: 'Chaos Knight', 'Legion Commander': 2.655 },
    { name: 'Chen', 'Legion Commander': 0.789 },
    { name: 'Clinkz', 'Legion Commander': -1.771 },
    { name: 'Clockwerk', 'Legion Commander': 0.177 },
    { name: 'Crystal Maiden', 'Legion Commander': -0.477 },
    { name: 'Dark Seer', 'Legion Commander': -0.872 },
    { name: 'Dark Willow', 'Legion Commander': -0.864 },
    { name: 'Dawnbreaker', 'Legion Commander': 3.434 },
    { name: 'Dazzle', 'Legion Commander': 2.079 },
    { name: 'Death Prophet', 'Legion Commander': 0.346 },
    { name: 'Disruptor', 'Legion Commander': -0.63 },
    { name: 'Doom', 'Legion Commander': 0.964 },
    { name: 'Dragon Knight', 'Legion Commander': 2.013 },
    { name: 'Drow Ranger', 'Legion Commander': 0.686 },
    { name: 'Earth Spirit', 'Legion Commander': -0.977 },
    { name: 'Earthshaker', 'Legion Commander': 0.882 },
    { name: 'Elder Titan', 'Legion Commander': 0.193 },
    { name: 'Ember Spirit', 'Legion Commander': -1.727 },
    { name: 'Enchantress', 'Legion Commander': 0.298 },
    { name: 'Enigma', 'Legion Commander': -0.232 },
    { name: 'Faceless Void', 'Legion Commander': -1.556 },
    { name: 'Grimstroke', 'Legion Commander': 0.17 },
    { name: 'Gyrocopter', 'Legion Commander': 0.211 },
    { name: 'Hoodwink', 'Legion Commander': 0.122 },
    { name: 'Huskar', 'Legion Commander': -0.316 },
    { name: 'Invoker', 'Legion Commander': 0.461 },
    { name: 'Io', 'Legion Commander': 1.231 },
    { name: 'Jakiro', 'Legion Commander': -1.118 },
    { name: 'Juggernaut', 'Legion Commander': -0.784 },
    { name: 'Keeper of the Light', 'Legion Commander': -0.627 },
    { name: 'Kunkka', 'Legion Commander': 2.178 },
    { name: 'Leshrac', 'Legion Commander': -0.269 },
    { name: 'Lich', 'Legion Commander': 0.471 },
    { name: 'Lifestealer', 'Legion Commander': 0.073 },
    { name: 'Lina', 'Legion Commander': -0.739 },
    { name: 'Lion', 'Legion Commander': 1.461 },
    { name: 'Lone Druid', 'Legion Commander': 1.686 },
    { name: 'Luna', 'Legion Commander': 0.691 },
    { name: 'Lycan', 'Legion Commander': 0.376 },
    { name: 'Magnus', 'Legion Commander': 1.138 },
    { name: 'Marci', 'Legion Commander': 1.192 },
    { name: 'Mars', 'Legion Commander': 2.043 },
    { name: 'Medusa', 'Legion Commander': 2.442 },
    { name: 'Meepo', 'Legion Commander': -0.788 },
    { name: 'Mirana', 'Legion Commander': -0.078 },
    { name: 'Monkey King', 'Legion Commander': 2.762 },
    { name: 'Morphling', 'Legion Commander': 1.143 },
    { name: 'Muerta', 'Legion Commander': -1.333 },
    { name: 'Naga Siren', 'Legion Commander': -0.229 },
    { name: "Nature's Prophet", 'Legion Commander': -0.005 },
    { name: 'Necrophos', 'Legion Commander': -0.643 },
    { name: 'Night Stalker', 'Legion Commander': -0.371 },
    { name: 'Nyx Assassin', 'Legion Commander': -0.033 },
    { name: 'Ogre Magi', 'Legion Commander': -0.937 },
    { name: 'Omniknight', 'Legion Commander': 2.803 },
    { name: 'Oracle', 'Legion Commander': 2.225 },
    { name: 'Outworld Destroyer', 'Legion Commander': 1.098 },
    { name: 'Pangolier', 'Legion Commander': 1.201 },
    { name: 'Phantom Assassin', 'Legion Commander': 0.113 },
    { name: 'Phantom Lancer', 'Legion Commander': -0.909 },
    { name: 'Phoenix', 'Legion Commander': -1.231 },
    { name: 'Primal Beast', 'Legion Commander': 1.824 },
    { name: 'Puck', 'Legion Commander': -0.982 },
    { name: 'Pudge', 'Legion Commander': 1.68 },
    { name: 'Pugna', 'Legion Commander': 1.38 },
    { name: 'Queen of Pain', 'Legion Commander': -1.63 },
    { name: 'Razor', 'Legion Commander': -0.052 },
    { name: 'Riki', 'Legion Commander': 1.144 },
    { name: 'Ringmaster', 'Legion Commander': 1.65 },
    { name: 'Rubick', 'Legion Commander': -0.986 },
    { name: 'Sand King', 'Legion Commander': -0.554 },
    { name: 'Shadow Demon', 'Legion Commander': 2.396 },
    { name: 'Shadow Fiend', 'Legion Commander': 1.249 },
    { name: 'Shadow Shaman', 'Legion Commander': 0.455 },
    { name: 'Silencer', 'Legion Commander': -1.589 },
    { name: 'Skywrath Mage', 'Legion Commander': -0.053 },
    { name: 'Slardar', 'Legion Commander': 0.78 },
    { name: 'Slark', 'Legion Commander': -0.823 },
    { name: 'Snapfire', 'Legion Commander': 0.903 },
    { name: 'Sniper', 'Legion Commander': 0.208 },
    { name: 'Spectre', 'Legion Commander': 0.08 },
    { name: 'Spirit Breaker', 'Legion Commander': 2.068 },
    { name: 'Storm Spirit', 'Legion Commander': -0.708 },
    { name: 'Sven', 'Legion Commander': 0.771 },
    { name: 'Techies', 'Legion Commander': 1.989 },
    { name: 'Templar Assassin', 'Legion Commander': 2.531 },
    { name: 'Terrorblade', 'Legion Commander': -0.781 },
    { name: 'Tidehunter', 'Legion Commander': 0.258 },
    { name: 'Timbersaw', 'Legion Commander': 0.435 },
    { name: 'Tinker', 'Legion Commander': 0.195 },
    { name: 'Tiny', 'Legion Commander': 2.477 },
    { name: 'Treant Protector', 'Legion Commander': 1.413 },
    { name: 'Troll Warlord', 'Legion Commander': 1.488 },
    { name: 'Tusk', 'Legion Commander': 2.777 },
    { name: 'Underlord', 'Legion Commander': -0.869 },
    { name: 'Undying', 'Legion Commander': -0.705 },
    { name: 'Ursa', 'Legion Commander': 1.155 },
    { name: 'Vengeful Spirit', 'Legion Commander': 1.661 },
    { name: 'Venomancer', 'Legion Commander': -1.232 },
    { name: 'Viper', 'Legion Commander': -1.5 },
    { name: 'Visage', 'Legion Commander': 0.673 },
    { name: 'Void Spirit', 'Legion Commander': -0.478 },
    { name: 'Warlock', 'Legion Commander': -0.401 },
    { name: 'Weaver', 'Legion Commander': -1.648 },
    { name: 'Windranger', 'Legion Commander': -0.361 },
    { name: 'Winter Wyvern', 'Legion Commander': 2.756 },
    { name: 'Witch Doctor', 'Legion Commander': -0.281 },
    { name: 'Wraith King', 'Legion Commander': 1.713 },
    { name: 'Zeus', 'Legion Commander': 1.039 },
],
"Lifestealer": [
    { name: 'Abaddon', 'Lifestealer': 0.11 },
    { name: 'Alchemist', 'Lifestealer': 0.22 },
    { name: 'Ancient Apparition', 'Lifestealer': 0.774 },
    { name: 'Anti-Mage', 'Lifestealer': 1.36 },
    { name: 'Arc Warden', 'Lifestealer': 0.95 },
    { name: 'Axe', 'Lifestealer': -1.896 },
    { name: 'Bane', 'Lifestealer': 1.472 },
    { name: 'Batrider', 'Lifestealer': 1.292 },
    { name: 'Beastmaster', 'Lifestealer': 1.286 },
    { name: 'Bloodseeker', 'Lifestealer': -1.285 },
    { name: 'Bounty Hunter', 'Lifestealer': 0.404 },
    { name: 'Brewmaster', 'Lifestealer': -1.034 },
    { name: 'Bristleback', 'Lifestealer': 2.819 },
    { name: 'Broodmother', 'Lifestealer': 3.866 },
    { name: 'Centaur Warrunner', 'Lifestealer': -4.735 },
    { name: 'Chaos Knight', 'Lifestealer': 0.872 },
    { name: 'Chen', 'Lifestealer': 1.137 },
    { name: 'Clinkz', 'Lifestealer': 2.937 },
    { name: 'Clockwerk', 'Lifestealer': -1.235 },
    { name: 'Crystal Maiden', 'Lifestealer': 0.284 },
    { name: 'Dark Seer', 'Lifestealer': 0.545 },
    { name: 'Dark Willow', 'Lifestealer': -0.242 },
    { name: 'Dawnbreaker', 'Lifestealer': -0.042 },
    { name: 'Dazzle', 'Lifestealer': 1.889 },
    { name: 'Death Prophet', 'Lifestealer': 2.076 },
    { name: 'Disruptor', 'Lifestealer': -0.201 },
    { name: 'Doom', 'Lifestealer': 1.417 },
    { name: 'Dragon Knight', 'Lifestealer': -0.416 },
    { name: 'Drow Ranger', 'Lifestealer': 1.355 },
    { name: 'Earth Spirit', 'Lifestealer': -2.803 },
    { name: 'Earthshaker', 'Lifestealer': -0.911 },
    { name: 'Elder Titan', 'Lifestealer': -0.461 },
    { name: 'Ember Spirit', 'Lifestealer': -0.23 },
    { name: 'Enchantress', 'Lifestealer': 1.616 },
    { name: 'Enigma', 'Lifestealer': 3.267 },
    { name: 'Faceless Void', 'Lifestealer': 1.467 },
    { name: 'Grimstroke', 'Lifestealer': 1.652 },
    { name: 'Gyrocopter', 'Lifestealer': -1.176 },
    { name: 'Hoodwink', 'Lifestealer': -0.24 },
    { name: 'Huskar', 'Lifestealer': 0.911 },
    { name: 'Invoker', 'Lifestealer': -0.899 },
    { name: 'Io', 'Lifestealer': 1.794 },
    { name: 'Jakiro', 'Lifestealer': -0.857 },
    { name: 'Juggernaut', 'Lifestealer': 1.339 },
    { name: 'Keeper of the Light', 'Lifestealer': 0.216 },
    { name: 'Kunkka', 'Lifestealer': -3.044 },
    { name: 'Legion Commander', 'Lifestealer': 0.145 },
    { name: 'Leshrac', 'Lifestealer': -1.311 },
    { name: 'Lich', 'Lifestealer': 0.269 },
    { name: 'Lina', 'Lifestealer': 0.282 },
    { name: 'Lion', 'Lifestealer': -0.52 },
    { name: 'Lone Druid', 'Lifestealer': 1.731 },
    { name: 'Luna', 'Lifestealer': -0.739 },
    { name: 'Lycan', 'Lifestealer': -0.522 },
    { name: 'Magnus', 'Lifestealer': 0.368 },
    { name: 'Marci', 'Lifestealer': 1.877 },
    { name: 'Mars', 'Lifestealer': -2.81 },
    { name: 'Medusa', 'Lifestealer': 0.93 },
    { name: 'Meepo', 'Lifestealer': 1.665 },
    { name: 'Mirana', 'Lifestealer': 0.532 },
    { name: 'Monkey King', 'Lifestealer': 0.947 },
    { name: 'Morphling', 'Lifestealer': 1.39 },
    { name: 'Muerta', 'Lifestealer': 0.266 },
    { name: 'Naga Siren', 'Lifestealer': 1.548 },
    { name: "Nature's Prophet", 'Lifestealer': 2.916 },
    { name: 'Necrophos', 'Lifestealer': 0.684 },
    { name: 'Night Stalker', 'Lifestealer': 0.374 },
    { name: 'Nyx Assassin', 'Lifestealer': -2.255 },
    { name: 'Ogre Magi', 'Lifestealer': -0.413 },
    { name: 'Omniknight', 'Lifestealer': 0.51 },
    { name: 'Oracle', 'Lifestealer': 0.275 },
    { name: 'Outworld Destroyer', 'Lifestealer': -0.13 },
    { name: 'Pangolier', 'Lifestealer': 1.245 },
    { name: 'Phantom Assassin', 'Lifestealer': 1.864 },
    { name: 'Phantom Lancer', 'Lifestealer': -0.083 },
    { name: 'Phoenix', 'Lifestealer': -2.584 },
    { name: 'Primal Beast', 'Lifestealer': -0.714 },
    { name: 'Puck', 'Lifestealer': -0.242 },
    { name: 'Pudge', 'Lifestealer': -0.986 },
    { name: 'Pugna', 'Lifestealer': 0.436 },
    { name: 'Queen of Pain', 'Lifestealer': 1.49 },
    { name: 'Razor', 'Lifestealer': 2.823 },
    { name: 'Riki', 'Lifestealer': 1.612 },
    { name: 'Ringmaster', 'Lifestealer': 0.28 },
    { name: 'Rubick', 'Lifestealer': -1.38 },
    { name: 'Sand King', 'Lifestealer': -1.748 },
    { name: 'Shadow Demon', 'Lifestealer': -0.277 },
    { name: 'Shadow Fiend', 'Lifestealer': -0.945 },
    { name: 'Shadow Shaman', 'Lifestealer': 0.082 },
    { name: 'Silencer', 'Lifestealer': 0.631 },
    { name: 'Skywrath Mage', 'Lifestealer': 0.765 },
    { name: 'Slardar', 'Lifestealer': 2.739 },
    { name: 'Slark', 'Lifestealer': 0.357 },
    { name: 'Snapfire', 'Lifestealer': -0.211 },
    { name: 'Sniper', 'Lifestealer': 0.213 },
    { name: 'Spectre', 'Lifestealer': -3.298 },
    { name: 'Spirit Breaker', 'Lifestealer': 0.956 },
    { name: 'Storm Spirit', 'Lifestealer': 1.48 },
    { name: 'Sven', 'Lifestealer': -0.771 },
    { name: 'Techies', 'Lifestealer': -1.873 },
    { name: 'Templar Assassin', 'Lifestealer': 0.526 },
    { name: 'Terrorblade', 'Lifestealer': -0.066 },
    { name: 'Tidehunter', 'Lifestealer': -0.217 },
    { name: 'Timbersaw', 'Lifestealer': -0.885 },
    { name: 'Tinker', 'Lifestealer': 0.276 },
    { name: 'Tiny', 'Lifestealer': -2.899 },
    { name: 'Treant Protector', 'Lifestealer': 0.609 },
    { name: 'Troll Warlord', 'Lifestealer': 0.856 },
    { name: 'Tusk', 'Lifestealer': 2.375 },
    { name: 'Underlord', 'Lifestealer': -2.012 },
    { name: 'Undying', 'Lifestealer': -0.068 },
    { name: 'Ursa', 'Lifestealer': 0.307 },
    { name: 'Vengeful Spirit', 'Lifestealer': -0.614 },
    { name: 'Venomancer', 'Lifestealer': 0.3 },
    { name: 'Viper', 'Lifestealer': 1.473 },
    { name: 'Visage', 'Lifestealer': 2.142 },
    { name: 'Void Spirit', 'Lifestealer': 0.093 },
    { name: 'Warlock', 'Lifestealer': -0.796 },
    { name: 'Weaver', 'Lifestealer': 0.713 },
    { name: 'Windranger', 'Lifestealer': 0.985 },
    { name: 'Winter Wyvern', 'Lifestealer': 1.349 },
    { name: 'Witch Doctor', 'Lifestealer': 1.609 },
    { name: 'Wraith King', 'Lifestealer': -2.553 },
    { name: 'Zeus', 'Lifestealer': -1.528 },
],
"Mars": [
    { name: 'Abaddon', 'Mars': -0.642 },
    { name: 'Alchemist', 'Mars': 0.443 },
    { name: 'Ancient Apparition', 'Mars': 0.147 },
    { name: 'Anti-Mage', 'Mars': 3.267 },
    { name: 'Arc Warden', 'Mars': -1.435 },
    { name: 'Axe', 'Mars': 0.007 },
    { name: 'Bane', 'Mars': -0.448 },
    { name: 'Batrider', 'Mars': 0.737 },
    { name: 'Beastmaster', 'Mars': 0.518 },
    { name: 'Bloodseeker', 'Mars': 1.234 },
    { name: 'Bounty Hunter', 'Mars': 0.572 },
    { name: 'Brewmaster', 'Mars': 0.034 },
    { name: 'Bristleback', 'Mars': -0.302 },
    { name: 'Broodmother', 'Mars': -0.318 },
    { name: 'Centaur Warrunner', 'Mars': -0.675 },
    { name: 'Chaos Knight', 'Mars': 1.02 },
    { name: 'Chen', 'Mars': 1.227 },
    { name: 'Clinkz', 'Mars': -0.721 },
    { name: 'Clockwerk', 'Mars': 0.018 },
    { name: 'Crystal Maiden', 'Mars': -0.707 },
    { name: 'Dark Seer', 'Mars': 0.607 },
    { name: 'Dark Willow', 'Mars': 0.392 },
    { name: 'Dawnbreaker', 'Mars': 0.197 },
    { name: 'Dazzle', 'Mars': -0.546 },
    { name: 'Death Prophet', 'Mars': 0.905 },
    { name: 'Disruptor', 'Mars': 0.19 },
    { name: 'Doom', 'Mars': 0.627 },
    { name: 'Dragon Knight', 'Mars': -0.323 },
    { name: 'Drow Ranger', 'Mars': -3.591 },
    { name: 'Earth Spirit', 'Mars': 1.84 },
    { name: 'Earthshaker', 'Mars': 0.131 },
    { name: 'Elder Titan', 'Mars': 0.991 },
    { name: 'Ember Spirit', 'Mars': 1.755 },
    { name: 'Enchantress', 'Mars': -2.045 },
    { name: 'Enigma', 'Mars': 1.362 },
    { name: 'Faceless Void', 'Mars': 2.551 },
    { name: 'Grimstroke', 'Mars': -0.771 },
    { name: 'Gyrocopter', 'Mars': -1.056 },
    { name: 'Hoodwink', 'Mars': -1.335 },
    { name: 'Huskar', 'Mars': 2.196 },
    { name: 'Invoker', 'Mars': 0.003 },
    { name: 'Io', 'Mars': -0.735 },
    { name: 'Jakiro', 'Mars': 0.468 },
    { name: 'Juggernaut', 'Mars': 0.878 },
    { name: 'Keeper of the Light', 'Mars': 1.303 },
    { name: 'Kunkka', 'Mars': 1.177 },
    { name: 'Legion Commander', 'Mars': -1.57 },
    { name: 'Leshrac', 'Mars': -0.356 },
    { name: 'Lich', 'Mars': 0.64 },
    { name: 'Lifestealer', 'Mars': 2.857 },
    { name: 'Lina', 'Mars': -0.951 },
    { name: 'Lion', 'Mars': -0.828 },
    { name: 'Lone Druid', 'Mars': 2.476 },
    { name: 'Luna', 'Mars': -0.329 },
    { name: 'Lycan', 'Mars': -0.125 },
    { name: 'Magnus', 'Mars': -0.382 },
    { name: 'Marci', 'Mars': -0.943 },
    { name: 'Medusa', 'Mars': -1.523 },
    { name: 'Meepo', 'Mars': 0.997 },
    { name: 'Mirana', 'Mars': -1.84 },
    { name: 'Monkey King', 'Mars': 0.862 },
    { name: 'Morphling', 'Mars': 0.689 },
    { name: 'Muerta', 'Mars': -0.018 },
    { name: 'Naga Siren', 'Mars': -0.704 },
    { name: "Nature's Prophet", 'Mars': -0.526 },
    { name: 'Necrophos', 'Mars': 0.624 },
    { name: 'Night Stalker', 'Mars': 0.046 },
    { name: 'Nyx Assassin', 'Mars': 0.496 },
    { name: 'Ogre Magi', 'Mars': 0.009 },
    { name: 'Omniknight', 'Mars': 1.194 },
    { name: 'Oracle', 'Mars': -0.187 },
    { name: 'Outworld Destroyer', 'Mars': -0.278 },
    { name: 'Pangolier', 'Mars': 0.163 },
    { name: 'Phantom Assassin', 'Mars': 0.759 },
    { name: 'Phantom Lancer', 'Mars': 2.671 },
    { name: 'Phoenix', 'Mars': 0.805 },
    { name: 'Primal Beast', 'Mars': -0.328 },
    { name: 'Puck', 'Mars': 1.535 },
    { name: 'Pudge', 'Mars': -0.798 },
    { name: 'Pugna', 'Mars': 0.57 },
    { name: 'Queen of Pain', 'Mars': 2.156 },
    { name: 'Razor', 'Mars': 0.164 },
    { name: 'Riki', 'Mars': 1.332 },
    { name: 'Ringmaster', 'Mars': 1.216 },
    { name: 'Rubick', 'Mars': -0.601 },
    { name: 'Sand King', 'Mars': 0.664 },
    { name: 'Shadow Demon', 'Mars': 0.559 },
    { name: 'Shadow Fiend', 'Mars': -0.186 },
    { name: 'Shadow Shaman', 'Mars': -0.793 },
    { name: 'Silencer', 'Mars': -0.392 },
    { name: 'Skywrath Mage', 'Mars': -0.895 },
    { name: 'Slardar', 'Mars': 0.182 },
    { name: 'Slark', 'Mars': -3.775 },
    { name: 'Snapfire', 'Mars': -0.21 },
    { name: 'Sniper', 'Mars': -2.46 },
    { name: 'Spectre', 'Mars': 0.892 },
    { name: 'Spirit Breaker', 'Mars': -1.763 },
    { name: 'Storm Spirit', 'Mars': 1.91 },
    { name: 'Sven', 'Mars': 0.354 },
    { name: 'Techies', 'Mars': -0.695 },
    { name: 'Templar Assassin', 'Mars': -0.262 },
    { name: 'Terrorblade', 'Mars': 0.211 },
    { name: 'Tidehunter', 'Mars': 0.021 },
    { name: 'Timbersaw', 'Mars': -1.207 },
    { name: 'Tinker', 'Mars': 1.184 },
    { name: 'Tiny', 'Mars': -0.365 },
    { name: 'Treant Protector', 'Mars': -0.848 },
    { name: 'Troll Warlord', 'Mars': -0.929 },
    { name: 'Tusk', 'Mars': 0.358 },
    { name: 'Underlord', 'Mars': 0.34 },
    { name: 'Undying', 'Mars': 1.168 },
    { name: 'Ursa', 'Mars': 0.189 },
    { name: 'Vengeful Spirit', 'Mars': -0.08 },
    { name: 'Venomancer', 'Mars': 0.584 },
    { name: 'Viper', 'Mars': -0.756 },
    { name: 'Visage', 'Mars': -0.099 },
    { name: 'Void Spirit', 'Mars': 1.831 },
    { name: 'Warlock', 'Mars': 0.577 },
    { name: 'Weaver', 'Mars': -0.004 },
    { name: 'Windranger', 'Mars': -2.113 },
    { name: 'Winter Wyvern', 'Mars': -0.387 },
    { name: 'Witch Doctor', 'Mars': -0.678 },
    { name: 'Wraith King', 'Mars': 0.399 },
    { name: 'Zeus', 'Mars': -0.984 },
],
"Night Stalker": [
    { name: 'Abaddon', 'Night Stalker': 0.161 },
    { name: 'Alchemist', 'Night Stalker': 0.594 },
    { name: 'Ancient Apparition', 'Night Stalker': -1.865 },
    { name: 'Anti-Mage', 'Night Stalker': -1.435 },
    { name: 'Arc Warden', 'Night Stalker': 3.766 },
    { name: 'Axe', 'Night Stalker': -0.848 },
    { name: 'Bane', 'Night Stalker': 0.974 },
    { name: 'Batrider', 'Night Stalker': 1.435 },
    { name: 'Beastmaster', 'Night Stalker': 3.177 },
    { name: 'Bloodseeker', 'Night Stalker': 2.736 },
    { name: 'Bounty Hunter', 'Night Stalker': 2.032 },
    { name: 'Brewmaster', 'Night Stalker': 0.847 },
    { name: 'Bristleback', 'Night Stalker': 1.846 },
    { name: 'Broodmother', 'Night Stalker': 3.004 },
    { name: 'Centaur Warrunner', 'Night Stalker': -1.059 },
    { name: 'Chaos Knight', 'Night Stalker': 2.82 },
    { name: 'Chen', 'Night Stalker': 2.448 },
    { name: 'Clinkz', 'Night Stalker': 0.612 },
    { name: 'Clockwerk', 'Night Stalker': 0.471 },
    { name: 'Crystal Maiden', 'Night Stalker': 0.689 },
    { name: 'Dark Seer', 'Night Stalker': 0.983 },
    { name: 'Dark Willow', 'Night Stalker': 2.028 },
    { name: 'Dawnbreaker', 'Night Stalker': 0.322 },
    { name: 'Dazzle', 'Night Stalker': -0.149 },
    { name: 'Death Prophet', 'Night Stalker': -0.731 },
    { name: 'Disruptor', 'Night Stalker': 0.835 },
    { name: 'Doom', 'Night Stalker': -0.525 },
    { name: 'Dragon Knight', 'Night Stalker': 0.653 },
    { name: 'Drow Ranger', 'Night Stalker': -1.062 },
    { name: 'Earth Spirit', 'Night Stalker': 0.339 },
    { name: 'Earthshaker', 'Night Stalker': -2.614 },
    { name: 'Elder Titan', 'Night Stalker': 0.751 },
    { name: 'Ember Spirit', 'Night Stalker': -1.391 },
    { name: 'Enchantress', 'Night Stalker': 2.388 },
    { name: 'Enigma', 'Night Stalker': 1.432 },
    { name: 'Faceless Void', 'Night Stalker': -2.406 },
    { name: 'Grimstroke', 'Night Stalker': -0.726 },
    { name: 'Gyrocopter', 'Night Stalker': 2.218 },
    { name: 'Hoodwink', 'Night Stalker': -0.492 },
    { name: 'Huskar', 'Night Stalker': 3.987 },
    { name: 'Invoker', 'Night Stalker': -0.027 },
    { name: 'Io', 'Night Stalker': 0.927 },
    { name: 'Jakiro', 'Night Stalker': -0.099 },
    { name: 'Juggernaut', 'Night Stalker': -3.002 },
    { name: 'Keeper of the Light', 'Night Stalker': 0.673 },
    { name: 'Kunkka', 'Night Stalker': 0.641 },
    { name: 'Legion Commander', 'Night Stalker': 0.403 },
    { name: 'Leshrac', 'Night Stalker': -0.214 },
    { name: 'Lich', 'Night Stalker': 0.598 },
    { name: 'Lifestealer', 'Night Stalker': -0.445 },
    { name: 'Lina', 'Night Stalker': 0.461 },
    { name: 'Lion', 'Night Stalker': -0.506 },
    { name: 'Lone Druid', 'Night Stalker': 3.92 },
    { name: 'Luna', 'Night Stalker': 2.325 },
    { name: 'Lycan', 'Night Stalker': 3.181 },
    { name: 'Magnus', 'Night Stalker': -0.263 },
    { name: 'Marci', 'Night Stalker': 2.582 },
    { name: 'Mars', 'Night Stalker': -0.101 },
    { name: 'Medusa', 'Night Stalker': 0.588 },
    { name: 'Meepo', 'Night Stalker': 3.559 },
    { name: 'Mirana', 'Night Stalker': 0.494 },
    { name: 'Monkey King', 'Night Stalker': 0.783 },
    { name: 'Morphling', 'Night Stalker': -0.685 },
    { name: 'Muerta', 'Night Stalker': 0.135 },
    { name: 'Naga Siren', 'Night Stalker': 4.441 },
    { name: "Nature's Prophet", 'Night Stalker': -0.931 },
    { name: 'Necrophos', 'Night Stalker': -0.039 },
    { name: 'Nyx Assassin', 'Night Stalker': -1.546 },
    { name: 'Ogre Magi', 'Night Stalker': 1.594 },
    { name: 'Omniknight', 'Night Stalker': -0.112 },
    { name: 'Oracle', 'Night Stalker': -1.285 },
    { name: 'Outworld Destroyer', 'Night Stalker': -0.907 },
    { name: 'Pangolier', 'Night Stalker': -0.371 },
    { name: 'Phantom Assassin', 'Night Stalker': 1.489 },
    { name: 'Phantom Lancer', 'Night Stalker': 1.253 },
    { name: 'Phoenix', 'Night Stalker': 2.52 },
    { name: 'Primal Beast', 'Night Stalker': -0.273 },
    { name: 'Puck', 'Night Stalker': -4.452 },
    { name: 'Pudge', 'Night Stalker': -1.01 },
    { name: 'Pugna', 'Night Stalker': -1.042 },
    { name: 'Queen of Pain', 'Night Stalker': -1.632 },
    { name: 'Razor', 'Night Stalker': 2.227 },
    { name: 'Riki', 'Night Stalker': -0.727 },
    { name: 'Ringmaster', 'Night Stalker': -0.15 },
    { name: 'Rubick', 'Night Stalker': -1.149 },
    { name: 'Sand King', 'Night Stalker': -1.027 },
    { name: 'Shadow Demon', 'Night Stalker': 0.071 },
    { name: 'Shadow Fiend', 'Night Stalker': 0.035 },
    { name: 'Shadow Shaman', 'Night Stalker': 0.679 },
    { name: 'Silencer', 'Night Stalker': -1.768 },
    { name: 'Skywrath Mage', 'Night Stalker': -1.551 },
    { name: 'Slardar', 'Night Stalker': -0.585 },
    { name: 'Slark', 'Night Stalker': -2.882 },
    { name: 'Snapfire', 'Night Stalker': -0.814 },
    { name: 'Sniper', 'Night Stalker': 0.853 },
    { name: 'Spectre', 'Night Stalker': 0.792 },
    { name: 'Spirit Breaker', 'Night Stalker': 1.758 },
    { name: 'Storm Spirit', 'Night Stalker': -2.289 },
    { name: 'Sven', 'Night Stalker': 0.92 },
    { name: 'Techies', 'Night Stalker': 1.238 },
    { name: 'Templar Assassin', 'Night Stalker': 2.664 },
    { name: 'Terrorblade', 'Night Stalker': 3.777 },
    { name: 'Tidehunter', 'Night Stalker': -1.086 },
    { name: 'Timbersaw', 'Night Stalker': -1.997 },
    { name: 'Tinker', 'Night Stalker': 0.168 },
    { name: 'Tiny', 'Night Stalker': 0.256 },
    { name: 'Treant Protector', 'Night Stalker': 1.257 },
    { name: 'Troll Warlord', 'Night Stalker': 2.519 },
    { name: 'Tusk', 'Night Stalker': 1.132 },
    { name: 'Underlord', 'Night Stalker': -0.647 },
    { name: 'Undying', 'Night Stalker': 0.713 },
    { name: 'Ursa', 'Night Stalker': -0.98 },
    { name: 'Vengeful Spirit', 'Night Stalker': 1.328 },
    { name: 'Venomancer', 'Night Stalker': 2.246 },
    { name: 'Viper', 'Night Stalker': 2.425 },
    { name: 'Visage', 'Night Stalker': 1.55 },
    { name: 'Void Spirit', 'Night Stalker': -3.05 },
    { name: 'Warlock', 'Night Stalker': -0.561 },
    { name: 'Weaver', 'Night Stalker': -0.324 },
    { name: 'Windranger', 'Night Stalker': 1.206 },
    { name: 'Winter Wyvern', 'Night Stalker': 1.166 },
    { name: 'Witch Doctor', 'Night Stalker': -1.289 },
    { name: 'Wraith King', 'Night Stalker': 2.164 },
    { name: 'Zeus', 'Night Stalker': -0.336 },
],
"Ogre Magi": [
    { name: 'Abaddon', 'Ogre Magi': 0.395 },
    { name: 'Alchemist', 'Ogre Magi': -0.04 },
    { name: 'Ancient Apparition', 'Ogre Magi': 0.212 },
    { name: 'Anti-Mage', 'Ogre Magi': 2.584 },
    { name: 'Arc Warden', 'Ogre Magi': 0.884 },
    { name: 'Axe', 'Ogre Magi': -0.93 },
    { name: 'Bane', 'Ogre Magi': -0.256 },
    { name: 'Batrider', 'Ogre Magi': 0.431 },
    { name: 'Beastmaster', 'Ogre Magi': 2.515 },
    { name: 'Bloodseeker', 'Ogre Magi': -0.45 },
    { name: 'Bounty Hunter', 'Ogre Magi': -0.213 },
    { name: 'Brewmaster', 'Ogre Magi': 0.55 },
    { name: 'Bristleback', 'Ogre Magi': -0.959 },
    { name: 'Broodmother', 'Ogre Magi': 1.995 },
    { name: 'Centaur Warrunner', 'Ogre Magi': -0.325 },
    { name: 'Chaos Knight', 'Ogre Magi': 3.771 },
    { name: 'Chen', 'Ogre Magi': 1.875 },
    { name: 'Clinkz', 'Ogre Magi': -0.562 },
    { name: 'Clockwerk', 'Ogre Magi': -0.23 },
    { name: 'Crystal Maiden', 'Ogre Magi': 0.401 },
    { name: 'Dark Seer', 'Ogre Magi': 0.965 },
    { name: 'Dark Willow', 'Ogre Magi': 0.746 },
    { name: 'Dawnbreaker', 'Ogre Magi': 0.038 },
    { name: 'Dazzle', 'Ogre Magi': 0.084 },
    { name: 'Death Prophet', 'Ogre Magi': -0.074 },
    { name: 'Disruptor', 'Ogre Magi': -0.247 },
    { name: 'Doom', 'Ogre Magi': 0.739 },
    { name: 'Dragon Knight', 'Ogre Magi': -0.758 },
    { name: 'Drow Ranger', 'Ogre Magi': -1.338 },
    { name: 'Earth Spirit', 'Ogre Magi': -0.239 },
    { name: 'Earthshaker', 'Ogre Magi': -0.216 },
    { name: 'Elder Titan', 'Ogre Magi': 0.302 },
    { name: 'Ember Spirit', 'Ogre Magi': -0.084 },
    { name: 'Enchantress', 'Ogre Magi': -1.014 },
    { name: 'Enigma', 'Ogre Magi': 1.78 },
    { name: 'Faceless Void', 'Ogre Magi': 0.002 },
    { name: 'Grimstroke', 'Ogre Magi': 0.827 },
    { name: 'Gyrocopter', 'Ogre Magi': -0.16 },
    { name: 'Hoodwink', 'Ogre Magi': -0.695 },
    { name: 'Huskar', 'Ogre Magi': 1.429 },
    { name: 'Invoker', 'Ogre Magi': 1.137 },
    { name: 'Io', 'Ogre Magi': 0.247 },
    { name: 'Jakiro', 'Ogre Magi': 0.128 },
    { name: 'Juggernaut', 'Ogre Magi': 0.988 },
    { name: 'Keeper of the Light', 'Ogre Magi': -0.193 },
    { name: 'Kunkka', 'Ogre Magi': -1.098 },
    { name: 'Legion Commander', 'Ogre Magi': 0.935 },
    { name: 'Leshrac', 'Ogre Magi': -0.43 },
    { name: 'Lich', 'Ogre Magi': 0.337 },
    { name: 'Lifestealer', 'Ogre Magi': 0.419 },
    { name: 'Lina', 'Ogre Magi': 0.021 },
    { name: 'Lion', 'Ogre Magi': 0.191 },
    { name: 'Lone Druid', 'Ogre Magi': 1.187 },
    { name: 'Luna', 'Ogre Magi': 0.391 },
    { name: 'Lycan', 'Ogre Magi': 0.09 },
    { name: 'Magnus', 'Ogre Magi': 1.222 },
    { name: 'Marci', 'Ogre Magi': 0.382 },
    { name: 'Mars', 'Ogre Magi': 0.37 },
    { name: 'Medusa', 'Ogre Magi': -0.596 },
    { name: 'Meepo', 'Ogre Magi': 3.298 },
    { name: 'Mirana', 'Ogre Magi': 0.314 },
    { name: 'Monkey King', 'Ogre Magi': -0.489 },
    { name: 'Morphling', 'Ogre Magi': 0.256 },
    { name: 'Muerta', 'Ogre Magi': -0.137 },
    { name: 'Naga Siren', 'Ogre Magi': 3.468 },
    { name: "Nature's Prophet", 'Ogre Magi': 0.765 },
    { name: 'Necrophos', 'Ogre Magi': 0.563 },
    { name: 'Night Stalker', 'Ogre Magi': -1.616 },
    { name: 'Nyx Assassin', 'Ogre Magi': -0.605 },
    { name: 'Omniknight', 'Ogre Magi': -1.101 },
    { name: 'Oracle', 'Ogre Magi': 1.017 },
    { name: 'Outworld Destroyer', 'Ogre Magi': -0.771 },
    { name: 'Pangolier', 'Ogre Magi': -0.269 },
    { name: 'Phantom Assassin', 'Ogre Magi': -1.557 },
    { name: 'Phantom Lancer', 'Ogre Magi': 4.673 },
    { name: 'Phoenix', 'Ogre Magi': -0.048 },
    { name: 'Primal Beast', 'Ogre Magi': -0.174 },
    { name: 'Puck', 'Ogre Magi': 0.491 },
    { name: 'Pudge', 'Ogre Magi': 0.709 },
    { name: 'Pugna', 'Ogre Magi': -0.087 },
    { name: 'Queen of Pain', 'Ogre Magi': -0.018 },
    { name: 'Razor', 'Ogre Magi': 0.076 },
    { name: 'Riki', 'Ogre Magi': -1.078 },
    { name: 'Ringmaster', 'Ogre Magi': 0.252 },
    { name: 'Rubick', 'Ogre Magi': 0.021 },
    { name: 'Sand King', 'Ogre Magi': 0.245 },
    { name: 'Shadow Demon', 'Ogre Magi': 0.63 },
    { name: 'Shadow Fiend', 'Ogre Magi': -0.665 },
    { name: 'Shadow Shaman', 'Ogre Magi': 0.719 },
    { name: 'Silencer', 'Ogre Magi': -1.386 },
    { name: 'Skywrath Mage', 'Ogre Magi': 0.068 },
    { name: 'Slardar', 'Ogre Magi': -0.821 },
    { name: 'Slark', 'Ogre Magi': 2.193 },
    { name: 'Snapfire', 'Ogre Magi': -0.067 },
    { name: 'Sniper', 'Ogre Magi': -0.2 },
    { name: 'Spectre', 'Ogre Magi': 0.055 },
    { name: 'Spirit Breaker', 'Ogre Magi': -0.617 },
    { name: 'Storm Spirit', 'Ogre Magi': 0.766 },
    { name: 'Sven', 'Ogre Magi': -0.78 },
    { name: 'Techies', 'Ogre Magi': 0.568 },
    { name: 'Templar Assassin', 'Ogre Magi': -0.159 },
    { name: 'Terrorblade', 'Ogre Magi': 1.568 },
    { name: 'Tidehunter', 'Ogre Magi': 1.31 },
    { name: 'Timbersaw', 'Ogre Magi': -0.373 },
    { name: 'Tinker', 'Ogre Magi': -0.568 },
    { name: 'Tiny', 'Ogre Magi': -0.332 },
    { name: 'Treant Protector', 'Ogre Magi': -0.15 },
    { name: 'Troll Warlord', 'Ogre Magi': -1.64 },
    { name: 'Tusk', 'Ogre Magi': 0.666 },
    { name: 'Underlord', 'Ogre Magi': 0.663 },
    { name: 'Undying', 'Ogre Magi': -0.361 },
    { name: 'Ursa', 'Ogre Magi': -2.208 },
    { name: 'Vengeful Spirit', 'Ogre Magi': -0.097 },
    { name: 'Venomancer', 'Ogre Magi': 1.068 },
    { name: 'Viper', 'Ogre Magi': 0.518 },
    { name: 'Visage', 'Ogre Magi': -0.086 },
    { name: 'Void Spirit', 'Ogre Magi': -0.225 },
    { name: 'Warlock', 'Ogre Magi': 1.637 },
    { name: 'Weaver', 'Ogre Magi': -0.821 },
    { name: 'Windranger', 'Ogre Magi': -1.047 },
    { name: 'Winter Wyvern', 'Ogre Magi': 0.462 },
    { name: 'Witch Doctor', 'Ogre Magi': 0.389 },
    { name: 'Wraith King', 'Ogre Magi': -1.208 },
    { name: 'Zeus', 'Ogre Magi': -0.392 },
],
"Omniknight": [
    { name: 'Abaddon', 'Omniknight': -0.694 },
    { name: 'Alchemist', 'Omniknight': -0.009 },
    { name: 'Ancient Apparition', 'Omniknight': 3.085 },
    { name: 'Anti-Mage', 'Omniknight': -1.107 },
    { name: 'Arc Warden', 'Omniknight': -1.553 },
    { name: 'Axe', 'Omniknight': -0.916 },
    { name: 'Bane', 'Omniknight': 1.322 },
    { name: 'Batrider', 'Omniknight': 2.266 },
    { name: 'Beastmaster', 'Omniknight': 2.113 },
    { name: 'Bloodseeker', 'Omniknight': 0.518 },
    { name: 'Bounty Hunter', 'Omniknight': 1.269 },
    { name: 'Brewmaster', 'Omniknight': -0.692 },
    { name: 'Bristleback', 'Omniknight': 0.708 },
    { name: 'Broodmother', 'Omniknight': 0.98 },
    { name: 'Centaur Warrunner', 'Omniknight': -1.024 },
    { name: 'Chaos Knight', 'Omniknight': 0.622 },
    { name: 'Chen', 'Omniknight': 0.571 },
    { name: 'Clinkz', 'Omniknight': 0.204 },
    { name: 'Clockwerk', 'Omniknight': -1.145 },
    { name: 'Crystal Maiden', 'Omniknight': 0.552 },
    { name: 'Dark Seer', 'Omniknight': 1.387 },
    { name: 'Dark Willow', 'Omniknight': -0.137 },
    { name: 'Dawnbreaker', 'Omniknight': 0.416 },
    { name: 'Dazzle', 'Omniknight': 0.609 },
    { name: 'Death Prophet', 'Omniknight': 2.639 },
    { name: 'Disruptor', 'Omniknight': -0.587 },
    { name: 'Doom', 'Omniknight': 3.277 },
    { name: 'Dragon Knight', 'Omniknight': -0.051 },
    { name: 'Drow Ranger', 'Omniknight': 0.772 },
    { name: 'Earth Spirit', 'Omniknight': -1.242 },
    { name: 'Earthshaker', 'Omniknight': 1.503 },
    { name: 'Elder Titan', 'Omniknight': 1.774 },
    { name: 'Ember Spirit', 'Omniknight': -1.418 },
    { name: 'Enchantress', 'Omniknight': 1.262 },
    { name: 'Enigma', 'Omniknight': 2.227 },
    { name: 'Faceless Void', 'Omniknight': 2.352 },
    { name: 'Grimstroke', 'Omniknight': 0.722 },
    { name: 'Gyrocopter', 'Omniknight': 1.336 },
    { name: 'Hoodwink', 'Omniknight': -1.016 },
    { name: 'Huskar', 'Omniknight': 3.071 },
    { name: 'Invoker', 'Omniknight': -0.424 },
    { name: 'Io', 'Omniknight': 1.403 },
    { name: 'Jakiro', 'Omniknight': -0.466 },
    { name: 'Juggernaut', 'Omniknight': 0.601 },
    { name: 'Keeper of the Light', 'Omniknight': 1.662 },
    { name: 'Kunkka', 'Omniknight': -0.639 },
    { name: 'Legion Commander', 'Omniknight': -3.185 },
    { name: 'Leshrac', 'Omniknight': 1.485 },
    { name: 'Lich', 'Omniknight': -0.568 },
    { name: 'Lifestealer', 'Omniknight': -0.604 },
    { name: 'Lina', 'Omniknight': 0.834 },
    { name: 'Lion', 'Omniknight': -0.767 },
    { name: 'Lone Druid', 'Omniknight': 0.641 },
    { name: 'Luna', 'Omniknight': 0.887 },
    { name: 'Lycan', 'Omniknight': 0.785 },
    { name: 'Magnus', 'Omniknight': 1.301 },
    { name: 'Marci', 'Omniknight': 0.916 },
    { name: 'Mars', 'Omniknight': -1.314 },
    { name: 'Medusa', 'Omniknight': 1.184 },
    { name: 'Meepo', 'Omniknight': -0.445 },
    { name: 'Mirana', 'Omniknight': 0.141 },
    { name: 'Monkey King', 'Omniknight': 1.366 },
    { name: 'Morphling', 'Omniknight': -0.539 },
    { name: 'Muerta', 'Omniknight': 2.935 },
    { name: 'Naga Siren', 'Omniknight': 0.019 },
    { name: "Nature's Prophet", 'Omniknight': 1.052 },
    { name: 'Necrophos', 'Omniknight': 0.655 },
    { name: 'Night Stalker', 'Omniknight': 0.105 },
    { name: 'Nyx Assassin', 'Omniknight': -0.316 },
    { name: 'Ogre Magi', 'Omniknight': 0.964 },
    { name: 'Oracle', 'Omniknight': -1.247 },
    { name: 'Outworld Destroyer', 'Omniknight': 1.576 },
    { name: 'Pangolier', 'Omniknight': -0.507 },
    { name: 'Phantom Assassin', 'Omniknight': -0.876 },
    { name: 'Phantom Lancer', 'Omniknight': 2.787 },
    { name: 'Phoenix', 'Omniknight': 0.381 },
    { name: 'Primal Beast', 'Omniknight': -0.538 },
    { name: 'Puck', 'Omniknight': -0.674 },
    { name: 'Pudge', 'Omniknight': -0.764 },
    { name: 'Pugna', 'Omniknight': 2.093 },
    { name: 'Queen of Pain', 'Omniknight': 0.318 },
    { name: 'Razor', 'Omniknight': 0.892 },
    { name: 'Riki', 'Omniknight': 0.903 },
    { name: 'Ringmaster', 'Omniknight': -1.175 },
    { name: 'Rubick', 'Omniknight': -0.087 },
    { name: 'Sand King', 'Omniknight': -0.438 },
    { name: 'Shadow Demon', 'Omniknight': 4.277 },
    { name: 'Shadow Fiend', 'Omniknight': 1.72 },
    { name: 'Shadow Shaman', 'Omniknight': -1.06 },
    { name: 'Silencer', 'Omniknight': 0.995 },
    { name: 'Skywrath Mage', 'Omniknight': -0.446 },
    { name: 'Slardar', 'Omniknight': 1.487 },
    { name: 'Slark', 'Omniknight': -2.517 },
    { name: 'Snapfire', 'Omniknight': 0.81 },
    { name: 'Sniper', 'Omniknight': -0.075 },
    { name: 'Spectre', 'Omniknight': -2.791 },
    { name: 'Spirit Breaker', 'Omniknight': -1.118 },
    { name: 'Storm Spirit', 'Omniknight': -1.755 },
    { name: 'Sven', 'Omniknight': 0.205 },
    { name: 'Techies', 'Omniknight': 0.292 },
    { name: 'Templar Assassin', 'Omniknight': 1.263 },
    { name: 'Terrorblade', 'Omniknight': 0.623 },
    { name: 'Tidehunter', 'Omniknight': -0.889 },
    { name: 'Timbersaw', 'Omniknight': -0.163 },
    { name: 'Tinker', 'Omniknight': -0.42 },
    { name: 'Tiny', 'Omniknight': -0.546 },
    { name: 'Treant Protector', 'Omniknight': 0.363 },
    { name: 'Troll Warlord', 'Omniknight': 0.783 },
    { name: 'Tusk', 'Omniknight': -0.514 },
    { name: 'Underlord', 'Omniknight': -0.714 },
    { name: 'Undying', 'Omniknight': 2.233 },
    { name: 'Ursa', 'Omniknight': -0.735 },
    { name: 'Vengeful Spirit', 'Omniknight': -0.699 },
    { name: 'Venomancer', 'Omniknight': 0.944 },
    { name: 'Viper', 'Omniknight': 0.638 },
    { name: 'Visage', 'Omniknight': 0.377 },
    { name: 'Void Spirit', 'Omniknight': -1.569 },
    { name: 'Warlock', 'Omniknight': 1.339 },
    { name: 'Weaver', 'Omniknight': 0.612 },
    { name: 'Windranger', 'Omniknight': 0.439 },
    { name: 'Winter Wyvern', 'Omniknight': 0.958 },
    { name: 'Witch Doctor', 'Omniknight': 1.62 },
    { name: 'Wraith King', 'Omniknight': -1.649 },
    { name: 'Zeus', 'Omniknight': -0.662 },
],
"Primal Beast": [
    { name: 'Abaddon', 'Primal Beast': -2.058 },
    { name: 'Alchemist', 'Primal Beast': -0.095 },
    { name: 'Ancient Apparition', 'Primal Beast': -0.478 },
    { name: 'Anti-Mage', 'Primal Beast': 1.886 },
    { name: 'Arc Warden', 'Primal Beast': -2.064 },
    { name: 'Axe', 'Primal Beast': -2.238 },
    { name: 'Bane', 'Primal Beast': 1.062 },
    { name: 'Batrider', 'Primal Beast': 0.791 },
    { name: 'Beastmaster', 'Primal Beast': -1.185 },
    { name: 'Bloodseeker', 'Primal Beast': 0.391 },
    { name: 'Bounty Hunter', 'Primal Beast': -1.159 },
    { name: 'Brewmaster', 'Primal Beast': 0.228 },
    { name: 'Bristleback', 'Primal Beast': -3.164 },
    { name: 'Broodmother', 'Primal Beast': -1.768 },
    { name: 'Centaur Warrunner', 'Primal Beast': 0.881 },
    { name: 'Chaos Knight', 'Primal Beast': -0.661 },
    { name: 'Chen', 'Primal Beast': -0.225 },
    { name: 'Clinkz', 'Primal Beast': 0.518 },
    { name: 'Clockwerk', 'Primal Beast': 2.358 },
    { name: 'Crystal Maiden', 'Primal Beast': 0.25 },
    { name: 'Dark Seer', 'Primal Beast': 0.261 },
    { name: 'Dark Willow', 'Primal Beast': 1.127 },
    { name: 'Dawnbreaker', 'Primal Beast': 0.548 },
    { name: 'Dazzle', 'Primal Beast': -0.513 },
    { name: 'Death Prophet', 'Primal Beast': 0.787 },
    { name: 'Disruptor', 'Primal Beast': 2.78 },
    { name: 'Doom', 'Primal Beast': 0.541 },
    { name: 'Dragon Knight', 'Primal Beast': 0.741 },
    { name: 'Drow Ranger', 'Primal Beast': -1.928 },
    { name: 'Earth Spirit', 'Primal Beast': -1.783 },
    { name: 'Earthshaker', 'Primal Beast': 0.924 },
    { name: 'Elder Titan', 'Primal Beast': -1.22 },
    { name: 'Ember Spirit', 'Primal Beast': 1.633 },
    { name: 'Enchantress', 'Primal Beast': -1.337 },
    { name: 'Enigma', 'Primal Beast': 1.286 },
    { name: 'Faceless Void', 'Primal Beast': 2.612 },
    { name: 'Grimstroke', 'Primal Beast': 2.589 },
    { name: 'Gyrocopter', 'Primal Beast': 0.472 },
    { name: 'Hoodwink', 'Primal Beast': -1.801 },
    { name: 'Huskar', 'Primal Beast': 1.157 },
    { name: 'Invoker', 'Primal Beast': 1.209 },
    { name: 'Io', 'Primal Beast': -0.41 },
    { name: 'Jakiro', 'Primal Beast': 0.925 },
    { name: 'Juggernaut', 'Primal Beast': 1.455 },
    { name: 'Keeper of the Light', 'Primal Beast': -0.017 },
    { name: 'Kunkka', 'Primal Beast': 0.241 },
    { name: 'Legion Commander', 'Primal Beast': -1.545 },
    { name: 'Leshrac', 'Primal Beast': 0.681 },
    { name: 'Lich', 'Primal Beast': 0.147 },
    { name: 'Lifestealer', 'Primal Beast': 0.777 },
    { name: 'Lina', 'Primal Beast': 0.796 },
    { name: 'Lion', 'Primal Beast': 1.324 },
    { name: 'Lone Druid', 'Primal Beast': 0.006 },
    { name: 'Luna', 'Primal Beast': -0.784 },
    { name: 'Lycan', 'Primal Beast': -0.847 },
    { name: 'Magnus', 'Primal Beast': -0.105 },
    { name: 'Marci', 'Primal Beast': -0.424 },
    { name: 'Mars', 'Primal Beast': 0.298 },
    { name: 'Medusa', 'Primal Beast': 0.17 },
    { name: 'Meepo', 'Primal Beast': 1.09 },
    { name: 'Mirana', 'Primal Beast': 0.638 },
    { name: 'Monkey King', 'Primal Beast': -0.151 },
    { name: 'Morphling', 'Primal Beast': 0.398 },
    { name: 'Muerta', 'Primal Beast': -2.028 },
    { name: 'Naga Siren', 'Primal Beast': 0.411 },
    { name: "Nature's Prophet", 'Primal Beast': -2.246 },
    { name: 'Necrophos', 'Primal Beast': 0.226 },
    { name: 'Night Stalker', 'Primal Beast': 0.279 },
    { name: 'Nyx Assassin', 'Primal Beast': -0.017 },
    { name: 'Ogre Magi', 'Primal Beast': 0.475 },
    { name: 'Omniknight', 'Primal Beast': 0.516 },
    { name: 'Oracle', 'Primal Beast': 0.156 },
    { name: 'Outworld Destroyer', 'Primal Beast': 1.598 },
    { name: 'Pangolier', 'Primal Beast': -0.545 },
    { name: 'Phantom Assassin', 'Primal Beast': -2.798 },
    { name: 'Phantom Lancer', 'Primal Beast': -0.105 },
    { name: 'Phoenix', 'Primal Beast': -0.237 },
    { name: 'Puck', 'Primal Beast': 0.593 },
    { name: 'Pudge', 'Primal Beast': -0.767 },
    { name: 'Pugna', 'Primal Beast': -1.237 },
    { name: 'Queen of Pain', 'Primal Beast': 1.362 },
    { name: 'Razor', 'Primal Beast': -2.866 },
    { name: 'Riki', 'Primal Beast': -0.961 },
    { name: 'Ringmaster', 'Primal Beast': 1.425 },
    { name: 'Rubick', 'Primal Beast': -0.615 },
    { name: 'Sand King', 'Primal Beast': 0.47 },
    { name: 'Shadow Demon', 'Primal Beast': 1.085 },
    { name: 'Shadow Fiend', 'Primal Beast': -0.909 },
    { name: 'Shadow Shaman', 'Primal Beast': 0.897 },
    { name: 'Silencer', 'Primal Beast': 0.853 },
    { name: 'Skywrath Mage', 'Primal Beast': 0.633 },
    { name: 'Slardar', 'Primal Beast': 1.1 },
    { name: 'Slark', 'Primal Beast': -1.535 },
    { name: 'Snapfire', 'Primal Beast': -0.296 },
    { name: 'Sniper', 'Primal Beast': -2.684 },
    { name: 'Spectre', 'Primal Beast': -1.71 },
    { name: 'Spirit Breaker', 'Primal Beast': -0.744 },
    { name: 'Storm Spirit', 'Primal Beast': 0.678 },
    { name: 'Sven', 'Primal Beast': 1.411 },
    { name: 'Techies', 'Primal Beast': 0.294 },
    { name: 'Templar Assassin', 'Primal Beast': -0.769 },
    { name: 'Terrorblade', 'Primal Beast': -1.79 },
    { name: 'Tidehunter', 'Primal Beast': -0.567 },
    { name: 'Timbersaw', 'Primal Beast': -0.29 },
    { name: 'Tinker', 'Primal Beast': -1.202 },
    { name: 'Tiny', 'Primal Beast': 0.079 },
    { name: 'Treant Protector', 'Primal Beast': -0.436 },
    { name: 'Troll Warlord', 'Primal Beast': -0.679 },
    { name: 'Tusk', 'Primal Beast': 0.154 },
    { name: 'Underlord', 'Primal Beast': 1.556 },
    { name: 'Undying', 'Primal Beast': 1.649 },
    { name: 'Ursa', 'Primal Beast': 0.305 },
    { name: 'Vengeful Spirit', 'Primal Beast': -0.488 },
    { name: 'Venomancer', 'Primal Beast': 1.067 },
    { name: 'Viper', 'Primal Beast': 0.133 },
    { name: 'Visage', 'Primal Beast': -0.187 },
    { name: 'Void Spirit', 'Primal Beast': 0.639 },
    { name: 'Warlock', 'Primal Beast': -0.852 },
    { name: 'Weaver', 'Primal Beast': 1.434 },
    { name: 'Windranger', 'Primal Beast': -0.875 },
    { name: 'Winter Wyvern', 'Primal Beast': -0.27 },
    { name: 'Witch Doctor', 'Primal Beast': 2.33 },
    { name: 'Wraith King', 'Primal Beast': 0.506 },
    { name: 'Zeus', 'Primal Beast': 0.794 },
],
"Pudge": [
    { name: 'Abaddon', 'Pudge': -1.076 },
    { name: 'Alchemist', 'Pudge': -0.071 },
    { name: 'Ancient Apparition', 'Pudge': -0.358 },
    { name: 'Anti-Mage', 'Pudge': 1.332 },
    { name: 'Arc Warden', 'Pudge': -0.789 },
    { name: 'Axe', 'Pudge': -1.878 },
    { name: 'Bane', 'Pudge': -0.943 },
    { name: 'Batrider', 'Pudge': 2.106 },
    { name: 'Beastmaster', 'Pudge': 2.982 },
    { name: 'Bloodseeker', 'Pudge': -0.76 },
    { name: 'Bounty Hunter', 'Pudge': -0.297 },
    { name: 'Brewmaster', 'Pudge': 0.174 },
    { name: 'Bristleback', 'Pudge': 0.69 },
    { name: 'Broodmother', 'Pudge': 1.563 },
    { name: 'Centaur Warrunner', 'Pudge': 0.61 },
    { name: 'Chaos Knight', 'Pudge': 1.895 },
    { name: 'Chen', 'Pudge': 1.915 },
    { name: 'Clinkz', 'Pudge': -0.993 },
    { name: 'Clockwerk', 'Pudge': 0.866 },
    { name: 'Crystal Maiden', 'Pudge': -1.47 },
    { name: 'Dark Seer', 'Pudge': 0.177 },
    { name: 'Dark Willow', 'Pudge': 0.146 },
    { name: 'Dawnbreaker', 'Pudge': 1.337 },
    { name: 'Dazzle', 'Pudge': 0.879 },
    { name: 'Death Prophet', 'Pudge': 0.7 },
    { name: 'Disruptor', 'Pudge': 0.451 },
    { name: 'Doom', 'Pudge': 1.379 },
    { name: 'Dragon Knight', 'Pudge': 0.526 },
    { name: 'Drow Ranger', 'Pudge': -1.864 },
    { name: 'Earth Spirit', 'Pudge': 1.293 },
    { name: 'Earthshaker', 'Pudge': 0.782 },
    { name: 'Elder Titan', 'Pudge': 0.858 },
    { name: 'Ember Spirit', 'Pudge': 0.093 },
    { name: 'Enchantress', 'Pudge': -0.785 },
    { name: 'Enigma', 'Pudge': 0.95 },
    { name: 'Faceless Void', 'Pudge': 0.082 },
    { name: 'Grimstroke', 'Pudge': 1.505 },
    { name: 'Gyrocopter', 'Pudge': 0.252 },
    { name: 'Hoodwink', 'Pudge': 1.233 },
    { name: 'Huskar', 'Pudge': 0.28 },
    { name: 'Invoker', 'Pudge': 1.299 },
    { name: 'Io', 'Pudge': 0.637 },
    { name: 'Jakiro', 'Pudge': -0.466 },
    { name: 'Juggernaut', 'Pudge': -0.053 },
    { name: 'Keeper of the Light', 'Pudge': 0.734 },
    { name: 'Kunkka', 'Pudge': 1.216 },
    { name: 'Legion Commander', 'Pudge': -1.742 },
    { name: 'Leshrac', 'Pudge': 0.437 },
    { name: 'Lich', 'Pudge': -0.79 },
    { name: 'Lifestealer', 'Pudge': 0.817 },
    { name: 'Lina', 'Pudge': -0.236 },
    { name: 'Lion', 'Pudge': -0.163 },
    { name: 'Lone Druid', 'Pudge': 0.908 },
    { name: 'Luna', 'Pudge': -0.061 },
    { name: 'Lycan', 'Pudge': 2.069 },
    { name: 'Magnus', 'Pudge': 2.345 },
    { name: 'Marci', 'Pudge': 2.143 },
    { name: 'Mars', 'Pudge': 1.401 },
    { name: 'Medusa', 'Pudge': -0.502 },
    { name: 'Meepo', 'Pudge': 1.162 },
    { name: 'Mirana', 'Pudge': 1.119 },
    { name: 'Monkey King', 'Pudge': 2.271 },
    { name: 'Morphling', 'Pudge': 2.056 },
    { name: 'Muerta', 'Pudge': -0.908 },
    { name: 'Naga Siren', 'Pudge': 0.051 },
    { name: "Nature's Prophet", 'Pudge': -0.056 },
    { name: 'Necrophos', 'Pudge': -1.931 },
    { name: 'Night Stalker', 'Pudge': 0.364 },
    { name: 'Nyx Assassin', 'Pudge': -0.421 },
    { name: 'Ogre Magi', 'Pudge': -0.658 },
    { name: 'Omniknight', 'Pudge': -0.027 },
    { name: 'Oracle', 'Pudge': -1.259 },
    { name: 'Outworld Destroyer', 'Pudge': -0.005 },
    { name: 'Pangolier', 'Pudge': 2.469 },
    { name: 'Phantom Assassin', 'Pudge': -2.042 },
    { name: 'Phantom Lancer', 'Pudge': 0.562 },
    { name: 'Phoenix', 'Pudge': 0.552 },
    { name: 'Primal Beast', 'Pudge': 1.086 },
    { name: 'Puck', 'Pudge': 1.456 },
    { name: 'Pugna', 'Pudge': -1.798 },
    { name: 'Queen of Pain', 'Pudge': 0.743 },
    { name: 'Razor', 'Pudge': -0.608 },
    { name: 'Riki', 'Pudge': -0.728 },
    { name: 'Ringmaster', 'Pudge': 0.357 },
    { name: 'Rubick', 'Pudge': -0.53 },
    { name: 'Sand King', 'Pudge': -0.925 },
    { name: 'Shadow Demon', 'Pudge': -0.012 },
    { name: 'Shadow Fiend', 'Pudge': 0.638 },
    { name: 'Shadow Shaman', 'Pudge': -1.404 },
    { name: 'Silencer', 'Pudge': 0.408 },
    { name: 'Skywrath Mage', 'Pudge': 0.362 },
    { name: 'Slardar', 'Pudge': 1.742 },
    { name: 'Slark', 'Pudge': -0.172 },
    { name: 'Snapfire', 'Pudge': 1.046 },
    { name: 'Sniper', 'Pudge': -2.612 },
    { name: 'Spectre', 'Pudge': -1.204 },
    { name: 'Spirit Breaker', 'Pudge': -0.095 },
    { name: 'Storm Spirit', 'Pudge': 0.991 },
    { name: 'Sven', 'Pudge': 1.563 },
    { name: 'Techies', 'Pudge': 0.329 },
    { name: 'Templar Assassin', 'Pudge': 0.907 },
    { name: 'Terrorblade', 'Pudge': 2.616 },
    { name: 'Tidehunter', 'Pudge': 0.584 },
    { name: 'Timbersaw', 'Pudge': 1.711 },
    { name: 'Tinker', 'Pudge': -2.474 },
    { name: 'Tiny', 'Pudge': 1.078 },
    { name: 'Treant Protector', 'Pudge': -0.694 },
    { name: 'Troll Warlord', 'Pudge': 0.45 },
    { name: 'Tusk', 'Pudge': 1.984 },
    { name: 'Underlord', 'Pudge': -1.662 },
    { name: 'Undying', 'Pudge': 0.517 },
    { name: 'Ursa', 'Pudge': 2.444 },
    { name: 'Vengeful Spirit', 'Pudge': 0.324 },
    { name: 'Venomancer', 'Pudge': 0.306 },
    { name: 'Viper', 'Pudge': -1.943 },
    { name: 'Visage', 'Pudge': 0.416 },
    { name: 'Void Spirit', 'Pudge': 1.277 },
    { name: 'Warlock', 'Pudge': -0.816 },
    { name: 'Weaver', 'Pudge': 1.607 },
    { name: 'Windranger', 'Pudge': -0.135 },
    { name: 'Winter Wyvern', 'Pudge': -0.712 },
    { name: 'Witch Doctor', 'Pudge': -0.121 },
    { name: 'Wraith King', 'Pudge': 0.424 },
    { name: 'Zeus', 'Pudge': -0.374 },
],
"Slardar": [
    { name: 'Abaddon', 'Slardar': 0.417 },
    { name: 'Alchemist', 'Slardar': -0.614 },
    { name: 'Ancient Apparition', 'Slardar': -1.204 },
    { name: 'Anti-Mage', 'Slardar': -4.086 },
    { name: 'Arc Warden', 'Slardar': 1.579 },
    { name: 'Axe', 'Slardar': 0.172 },
    { name: 'Bane', 'Slardar': -0.967 },
    { name: 'Batrider', 'Slardar': 1.771 },
    { name: 'Beastmaster', 'Slardar': 0.61 },
    { name: 'Bloodseeker', 'Slardar': -0.207 },
    { name: 'Bounty Hunter', 'Slardar': -1.131 },
    { name: 'Brewmaster', 'Slardar': -0.222 },
    { name: 'Bristleback', 'Slardar': -0.071 },
    { name: 'Broodmother', 'Slardar': -1.619 },
    { name: 'Centaur Warrunner', 'Slardar': -1.745 },
    { name: 'Chaos Knight', 'Slardar': 1.524 },
    { name: 'Chen', 'Slardar': -0.028 },
    { name: 'Clinkz', 'Slardar': -2.168 },
    { name: 'Clockwerk', 'Slardar': 0.137 },
    { name: 'Crystal Maiden', 'Slardar': 0.177 },
    { name: 'Dark Seer', 'Slardar': -0.224 },
    { name: 'Dark Willow', 'Slardar': 1.327 },
    { name: 'Dawnbreaker', 'Slardar': -0.769 },
    { name: 'Dazzle', 'Slardar': 0.17 },
    { name: 'Death Prophet', 'Slardar': -0.935 },
    { name: 'Disruptor', 'Slardar': 0.317 },
    { name: 'Doom', 'Slardar': 2.0 },
    { name: 'Dragon Knight', 'Slardar': 0.766 },
    { name: 'Drow Ranger', 'Slardar': 2.066 },
    { name: 'Earth Spirit', 'Slardar': -0.714 },
    { name: 'Earthshaker', 'Slardar': -0.381 },
    { name: 'Elder Titan', 'Slardar': 2.474 },
    { name: 'Ember Spirit', 'Slardar': -0.983 },
    { name: 'Enchantress', 'Slardar': 0.445 },
    { name: 'Enigma', 'Slardar': 1.576 },
    { name: 'Faceless Void', 'Slardar': -1.553 },
    { name: 'Grimstroke', 'Slardar': 0.204 },
    { name: 'Gyrocopter', 'Slardar': 1.307 },
    { name: 'Hoodwink', 'Slardar': 0.186 },
    { name: 'Huskar', 'Slardar': 1.374 },
    { name: 'Invoker', 'Slardar': -1.201 },
    { name: 'Io', 'Slardar': -1.395 },
    { name: 'Jakiro', 'Slardar': 0.942 },
    { name: 'Juggernaut', 'Slardar': 1.819 },
    { name: 'Keeper of the Light', 'Slardar': -0.075 },
    { name: 'Kunkka', 'Slardar': -1.932 },
    { name: 'Legion Commander', 'Slardar': -0.453 },
    { name: 'Leshrac', 'Slardar': -1.102 },
    { name: 'Lich', 'Slardar': -0.006 },
    { name: 'Lifestealer', 'Slardar': -2.764 },
    { name: 'Lina', 'Slardar': -1.374 },
    { name: 'Lion', 'Slardar': 0.231 },
    { name: 'Lone Druid', 'Slardar': -0.183 },
    { name: 'Luna', 'Slardar': 2.711 },
    { name: 'Lycan', 'Slardar': -1.347 },
    { name: 'Magnus', 'Slardar': 0.672 },
    { name: 'Marci', 'Slardar': -1.09 },
    { name: 'Mars', 'Slardar': -0.162 },
    { name: 'Medusa', 'Slardar': 2.12 },
    { name: 'Meepo', 'Slardar': -0.872 },
    { name: 'Mirana', 'Slardar': 1.027 },
    { name: 'Monkey King', 'Slardar': 0.302 },
    { name: 'Morphling', 'Slardar': 0.328 },
    { name: 'Muerta', 'Slardar': 3.06 },
    { name: 'Naga Siren', 'Slardar': 2.248 },
    { name: "Nature's Prophet", 'Slardar': -0.536 },
    { name: 'Necrophos', 'Slardar': 0.31 },
    { name: 'Night Stalker', 'Slardar': 0.498 },
    { name: 'Nyx Assassin', 'Slardar': -1.971 },
    { name: 'Ogre Magi', 'Slardar': 1.1 },
    { name: 'Omniknight', 'Slardar': -1.552 },
    { name: 'Oracle', 'Slardar': -1.352 },
    { name: 'Outworld Destroyer', 'Slardar': -0.219 },
    { name: 'Pangolier', 'Slardar': -1.286 },
    { name: 'Phantom Assassin', 'Slardar': 0.979 },
    { name: 'Phantom Lancer', 'Slardar': 2.871 },
    { name: 'Phoenix', 'Slardar': 1.501 },
    { name: 'Primal Beast', 'Slardar': -1.067 },
    { name: 'Puck', 'Slardar': 0.06 },
    { name: 'Pudge', 'Slardar': -1.711 },
    { name: 'Pugna', 'Slardar': -1.331 },
    { name: 'Queen of Pain', 'Slardar': -0.339 },
    { name: 'Razor', 'Slardar': 1.199 },
    { name: 'Riki', 'Slardar': -1.081 },
    { name: 'Ringmaster', 'Slardar': -0.21 },
    { name: 'Rubick', 'Slardar': -0.717 },
    { name: 'Sand King', 'Slardar': -0.995 },
    { name: 'Shadow Demon', 'Slardar': -0.132 },
    { name: 'Shadow Fiend', 'Slardar': -0.069 },
    { name: 'Shadow Shaman', 'Slardar': 1.702 },
    { name: 'Silencer', 'Slardar': 0.879 },
    { name: 'Skywrath Mage', 'Slardar': -0.889 },
    { name: 'Slark', 'Slardar': 0.904 },
    { name: 'Snapfire', 'Slardar': -0.862 },
    { name: 'Sniper', 'Slardar': 0.546 },
    { name: 'Spectre', 'Slardar': -1.99 },
    { name: 'Spirit Breaker', 'Slardar': -0.095 },
    { name: 'Storm Spirit', 'Slardar': 0.131 },
    { name: 'Sven', 'Slardar': -1.76 },
    { name: 'Techies', 'Slardar': 0.567 },
    { name: 'Templar Assassin', 'Slardar': -0.819 },
    { name: 'Terrorblade', 'Slardar': 3.972 },
    { name: 'Tidehunter', 'Slardar': 3.883 },
    { name: 'Timbersaw', 'Slardar': -1.021 },
    { name: 'Tinker', 'Slardar': -1.678 },
    { name: 'Tiny', 'Slardar': 0.561 },
    { name: 'Treant Protector', 'Slardar': 0.422 },
    { name: 'Troll Warlord', 'Slardar': 3.612 },
    { name: 'Tusk', 'Slardar': 0.553 },
    { name: 'Underlord', 'Slardar': 0.296 },
    { name: 'Undying', 'Slardar': -1.096 },
    { name: 'Ursa', 'Slardar': -2.257 },
    { name: 'Vengeful Spirit', 'Slardar': 0.749 },
    { name: 'Venomancer', 'Slardar': 1.434 },
    { name: 'Viper', 'Slardar': 0.318 },
    { name: 'Visage', 'Slardar': 0.072 },
    { name: 'Void Spirit', 'Slardar': -0.803 },
    { name: 'Warlock', 'Slardar': -1.341 },
    { name: 'Weaver', 'Slardar': -1.072 },
    { name: 'Windranger', 'Slardar': 1.159 },
    { name: 'Winter Wyvern', 'Slardar': 0.639 },
    { name: 'Witch Doctor', 'Slardar': -0.552 },
    { name: 'Wraith King', 'Slardar': -0.775 },
    { name: 'Zeus', 'Slardar': 0.008 },
],
"Spirit Breaker": [
    { name: 'Abaddon', 'Spirit Breaker': -0.125 },
    { name: 'Alchemist', 'Spirit Breaker': -0.352 },
    { name: 'Ancient Apparition', 'Spirit Breaker': -1.329 },
    { name: 'Anti-Mage', 'Spirit Breaker': -0.903 },
    { name: 'Arc Warden', 'Spirit Breaker': 0.132 },
    { name: 'Axe', 'Spirit Breaker': -0.948 },
    { name: 'Bane', 'Spirit Breaker': -0.926 },
    { name: 'Batrider', 'Spirit Breaker': -0.697 },
    { name: 'Beastmaster', 'Spirit Breaker': 1.396 },
    { name: 'Bloodseeker', 'Spirit Breaker': -0.564 },
    { name: 'Bounty Hunter', 'Spirit Breaker': -0.967 },
    { name: 'Brewmaster', 'Spirit Breaker': -0.242 },
    { name: 'Bristleback', 'Spirit Breaker': -0.935 },
    { name: 'Broodmother', 'Spirit Breaker': -3.716 },
    { name: 'Centaur Warrunner', 'Spirit Breaker': 0.345 },
    { name: 'Chaos Knight', 'Spirit Breaker': 0.662 },
    { name: 'Chen', 'Spirit Breaker': -1.221 },
    { name: 'Clinkz', 'Spirit Breaker': -0.707 },
    { name: 'Clockwerk', 'Spirit Breaker': 2.692 },
    { name: 'Crystal Maiden', 'Spirit Breaker': 0.95 },
    { name: 'Dark Seer', 'Spirit Breaker': 0.693 },
    { name: 'Dark Willow', 'Spirit Breaker': 1.04 },
    { name: 'Dawnbreaker', 'Spirit Breaker': 0.961 },
    { name: 'Dazzle', 'Spirit Breaker': 0.315 },
    { name: 'Death Prophet', 'Spirit Breaker': -0.648 },
    { name: 'Disruptor', 'Spirit Breaker': 0.778 },
    { name: 'Doom', 'Spirit Breaker': -0.031 },
    { name: 'Dragon Knight', 'Spirit Breaker': -0.069 },
    { name: 'Drow Ranger', 'Spirit Breaker': -0.702 },
    { name: 'Earth Spirit', 'Spirit Breaker': -0.669 },
    { name: 'Earthshaker', 'Spirit Breaker': -0.256 },
    { name: 'Elder Titan', 'Spirit Breaker': -1.358 },
    { name: 'Ember Spirit', 'Spirit Breaker': 1.112 },
    { name: 'Enchantress', 'Spirit Breaker': 1.008 },
    { name: 'Enigma', 'Spirit Breaker': 0.732 },
    { name: 'Faceless Void', 'Spirit Breaker': 0.241 },
    { name: 'Grimstroke', 'Spirit Breaker': 0.245 },
    { name: 'Gyrocopter', 'Spirit Breaker': -0.108 },
    { name: 'Hoodwink', 'Spirit Breaker': -1.454 },
    { name: 'Huskar', 'Spirit Breaker': 0.137 },
    { name: 'Invoker', 'Spirit Breaker': 1.157 },
    { name: 'Io', 'Spirit Breaker': 0.055 },
    { name: 'Jakiro', 'Spirit Breaker': 1.461 },
    { name: 'Juggernaut', 'Spirit Breaker': -1.666 },
    { name: 'Keeper of the Light', 'Spirit Breaker': -0.94 },
    { name: 'Kunkka', 'Spirit Breaker': 0.27 },
    { name: 'Legion Commander', 'Spirit Breaker': -2.05 },
    { name: 'Leshrac', 'Spirit Breaker': 0.536 },
    { name: 'Lich', 'Spirit Breaker': 1.232 },
    { name: 'Lifestealer', 'Spirit Breaker': -0.946 },
    { name: 'Lina', 'Spirit Breaker': -1.178 },
    { name: 'Lion', 'Spirit Breaker': -0.011 },
    { name: 'Lone Druid', 'Spirit Breaker': 0.731 },
    { name: 'Luna', 'Spirit Breaker': -0.345 },
    { name: 'Lycan', 'Spirit Breaker': -2.299 },
    { name: 'Magnus', 'Spirit Breaker': 0.048 },
    { name: 'Marci', 'Spirit Breaker': -0.581 },
    { name: 'Mars', 'Spirit Breaker': 1.85 },
    { name: 'Medusa', 'Spirit Breaker': 0.224 },
    { name: 'Meepo', 'Spirit Breaker': 2.316 },
    { name: 'Mirana', 'Spirit Breaker': -0.528 },
    { name: 'Monkey King', 'Spirit Breaker': 0.28 },
    { name: 'Morphling', 'Spirit Breaker': 2.316 },
    { name: 'Muerta', 'Spirit Breaker': 0.169 },
    { name: 'Naga Siren', 'Spirit Breaker': 1.957 },
    { name: "Nature's Prophet", 'Spirit Breaker': -0.619 },
    { name: 'Necrophos', 'Spirit Breaker': 2.617 },
    { name: 'Night Stalker', 'Spirit Breaker': -1.844 },
    { name: 'Nyx Assassin', 'Spirit Breaker': -0.153 },
    { name: 'Ogre Magi', 'Spirit Breaker': 0.675 },
    { name: 'Omniknight', 'Spirit Breaker': 0.802 },
    { name: 'Oracle', 'Spirit Breaker': 0.89 },
    { name: 'Outworld Destroyer', 'Spirit Breaker': -0.491 },
    { name: 'Pangolier', 'Spirit Breaker': -0.337 },
    { name: 'Phantom Assassin', 'Spirit Breaker': -1.176 },
    { name: 'Phantom Lancer', 'Spirit Breaker': 1.184 },
    { name: 'Phoenix', 'Spirit Breaker': 0.116 },
    { name: 'Primal Beast', 'Spirit Breaker': 0.768 },
    { name: 'Puck', 'Spirit Breaker': -0.163 },
    { name: 'Pudge', 'Spirit Breaker': 0.243 },
    { name: 'Pugna', 'Spirit Breaker': -1.322 },
    { name: 'Queen of Pain', 'Spirit Breaker': 0.607 },
    { name: 'Razor', 'Spirit Breaker': 0.432 },
    { name: 'Riki', 'Spirit Breaker': -1.064 },
    { name: 'Ringmaster', 'Spirit Breaker': 0.371 },
    { name: 'Rubick', 'Spirit Breaker': -0.023 },
    { name: 'Sand King', 'Spirit Breaker': 1.041 },
    { name: 'Shadow Demon', 'Spirit Breaker': -0.021 },
    { name: 'Shadow Fiend', 'Spirit Breaker': -1.782 },
    { name: 'Shadow Shaman', 'Spirit Breaker': -0.623 },
    { name: 'Silencer', 'Spirit Breaker': -1.479 },
    { name: 'Skywrath Mage', 'Spirit Breaker': -1.105 },
    { name: 'Slardar', 'Spirit Breaker': 0.105 },
    { name: 'Slark', 'Spirit Breaker': -0.059 },
    { name: 'Snapfire', 'Spirit Breaker': -0.036 },
    { name: 'Sniper', 'Spirit Breaker': -1.765 },
    { name: 'Spectre', 'Spirit Breaker': 1.375 },
    { name: 'Storm Spirit', 'Spirit Breaker': -0.398 },
    { name: 'Sven', 'Spirit Breaker': -0.25 },
    { name: 'Techies', 'Spirit Breaker': 0.094 },
    { name: 'Templar Assassin', 'Spirit Breaker': 0.299 },
    { name: 'Terrorblade', 'Spirit Breaker': 0.396 },
    { name: 'Tidehunter', 'Spirit Breaker': 1.273 },
    { name: 'Timbersaw', 'Spirit Breaker': 0.686 },
    { name: 'Tinker', 'Spirit Breaker': 0.093 },
    { name: 'Tiny', 'Spirit Breaker': 2.189 },
    { name: 'Treant Protector', 'Spirit Breaker': 0.656 },
    { name: 'Troll Warlord', 'Spirit Breaker': 0.524 },
    { name: 'Tusk', 'Spirit Breaker': 1.501 },
    { name: 'Underlord', 'Spirit Breaker': 3.214 },
    { name: 'Undying', 'Spirit Breaker': 0.143 },
    { name: 'Ursa', 'Spirit Breaker': 0.043 },
    { name: 'Vengeful Spirit', 'Spirit Breaker': -0.245 },
    { name: 'Venomancer', 'Spirit Breaker': 1.903 },
    { name: 'Viper', 'Spirit Breaker': 0.375 },
    { name: 'Visage', 'Spirit Breaker': 0.39 },
    { name: 'Void Spirit', 'Spirit Breaker': 0.113 },
    { name: 'Warlock', 'Spirit Breaker': -0.045 },
    { name: 'Weaver', 'Spirit Breaker': -0.297 },
    { name: 'Windranger', 'Spirit Breaker': -1.07 },
    { name: 'Winter Wyvern', 'Spirit Breaker': 0.764 },
    { name: 'Witch Doctor', 'Spirit Breaker': 0.49 },
    { name: 'Wraith King', 'Spirit Breaker': 0.94 },
    { name: 'Zeus', 'Spirit Breaker': -0.006 },
],
"Sven": [
    { name: 'Abaddon', 'Sven': -1.787 },
    { name: 'Alchemist', 'Sven': 0.639 },
    { name: 'Ancient Apparition', 'Sven': 0.585 },
    { name: 'Anti-Mage', 'Sven': -1.158 },
    { name: 'Arc Warden', 'Sven': -1.032 },
    { name: 'Axe', 'Sven': -0.65 },
    { name: 'Bane', 'Sven': 0.573 },
    { name: 'Batrider', 'Sven': 0.987 },
    { name: 'Beastmaster', 'Sven': -0.581 },
    { name: 'Bloodseeker', 'Sven': 0.856 },
    { name: 'Bounty Hunter', 'Sven': 0.631 },
    { name: 'Brewmaster', 'Sven': -1.941 },
    { name: 'Bristleback', 'Sven': 1.218 },
    { name: 'Broodmother', 'Sven': 0.046 },
    { name: 'Centaur Warrunner', 'Sven': -1.72 },
    { name: 'Chaos Knight', 'Sven': -1.049 },
    { name: 'Chen', 'Sven': -0.282 },
    { name: 'Clinkz', 'Sven': 0.552 },
    { name: 'Clockwerk', 'Sven': 0.387 },
    { name: 'Crystal Maiden', 'Sven': 0.522 },
    { name: 'Dark Seer', 'Sven': -1.906 },
    { name: 'Dark Willow', 'Sven': 1.396 },
    { name: 'Dawnbreaker', 'Sven': -1.026 },
    { name: 'Dazzle', 'Sven': -0.239 },
    { name: 'Death Prophet', 'Sven': -0.854 },
    { name: 'Disruptor', 'Sven': 0.077 },
    { name: 'Doom', 'Sven': 0.757 },
    { name: 'Dragon Knight', 'Sven': 1.043 },
    { name: 'Drow Ranger', 'Sven': -0.574 },
    { name: 'Earth Spirit', 'Sven': -1.353 },
    { name: 'Earthshaker', 'Sven': 0.344 },
    { name: 'Elder Titan', 'Sven': -1.305 },
    { name: 'Ember Spirit', 'Sven': -0.385 },
    { name: 'Enchantress', 'Sven': 1.212 },
    { name: 'Enigma', 'Sven': 0.586 },
    { name: 'Faceless Void', 'Sven': 1.304 },
    { name: 'Grimstroke', 'Sven': -0.166 },
    { name: 'Gyrocopter', 'Sven': 0.198 },
    { name: 'Hoodwink', 'Sven': -0.523 },
    { name: 'Huskar', 'Sven': 1.313 },
    { name: 'Invoker', 'Sven': 0.659 },
    { name: 'Io', 'Sven': -1.887 },
    { name: 'Jakiro', 'Sven': -0.246 },
    { name: 'Juggernaut', 'Sven': -0.23 },
    { name: 'Keeper of the Light', 'Sven': 0.947 },
    { name: 'Kunkka', 'Sven': -0.164 },
    { name: 'Legion Commander', 'Sven': -0.504 },
    { name: 'Leshrac', 'Sven': -0.519 },
    { name: 'Lich', 'Sven': 0.807 },
    { name: 'Lifestealer', 'Sven': 0.779 },
    { name: 'Lina', 'Sven': 0.193 },
    { name: 'Lion', 'Sven': -0.392 },
    { name: 'Lone Druid', 'Sven': -0.189 },
    { name: 'Luna', 'Sven': 0.236 },
    { name: 'Lycan', 'Sven': -2.74 },
    { name: 'Magnus', 'Sven': -0.073 },
    { name: 'Marci', 'Sven': -0.765 },
    { name: 'Mars', 'Sven': -0.33 },
    { name: 'Medusa', 'Sven': -0.811 },
    { name: 'Meepo', 'Sven': -3.28 },
    { name: 'Mirana', 'Sven': 0.834 },
    { name: 'Monkey King', 'Sven': 1.46 },
    { name: 'Morphling', 'Sven': 0.348 },
    { name: 'Muerta', 'Sven': 0.803 },
    { name: 'Naga Siren', 'Sven': -0.797 },
    { name: "Nature's Prophet", 'Sven': 0.611 },
    { name: 'Necrophos', 'Sven': 0.598 },
    { name: 'Night Stalker', 'Sven': -0.96 },
    { name: 'Nyx Assassin', 'Sven': -1.207 },
    { name: 'Ogre Magi', 'Sven': 1.027 },
    { name: 'Omniknight', 'Sven': -0.314 },
    { name: 'Oracle', 'Sven': -0.654 },
    { name: 'Outworld Destroyer', 'Sven': 0.088 },
    { name: 'Pangolier', 'Sven': -1.045 },
    { name: 'Phantom Assassin', 'Sven': 0.358 },
    { name: 'Phantom Lancer', 'Sven': -1.7 },
    { name: 'Phoenix', 'Sven': 0.044 },
    { name: 'Primal Beast', 'Sven': -1.363 },
    { name: 'Puck', 'Sven': -1.234 },
    { name: 'Pudge', 'Sven': -1.571 },
    { name: 'Pugna', 'Sven': -0.683 },
    { name: 'Queen of Pain', 'Sven': 0.21 },
    { name: 'Razor', 'Sven': 0.787 },
    { name: 'Riki', 'Sven': 0.008 },
    { name: 'Ringmaster', 'Sven': 1.205 },
    { name: 'Rubick', 'Sven': 0.969 },
    { name: 'Sand King', 'Sven': -0.503 },
    { name: 'Shadow Demon', 'Sven': -0.165 },
    { name: 'Shadow Fiend', 'Sven': -0.655 },
    { name: 'Shadow Shaman', 'Sven': 0.479 },
    { name: 'Silencer', 'Sven': 0.004 },
    { name: 'Skywrath Mage', 'Sven': -1.057 },
    { name: 'Slardar', 'Sven': 1.767 },
    { name: 'Slark', 'Sven': 0.444 },
    { name: 'Snapfire', 'Sven': -0.944 },
    { name: 'Sniper', 'Sven': 0.345 },
    { name: 'Spectre', 'Sven': -0.878 },
    { name: 'Spirit Breaker', 'Sven': 0.297 },
    { name: 'Storm Spirit', 'Sven': -0.141 },
    { name: 'Techies', 'Sven': 0.658 },
    { name: 'Templar Assassin', 'Sven': 1.927 },
    { name: 'Terrorblade', 'Sven': 0.875 },
    { name: 'Tidehunter', 'Sven': -1.064 },
    { name: 'Timbersaw', 'Sven': -0.288 },
    { name: 'Tinker', 'Sven': -1.469 },
    { name: 'Tiny', 'Sven': -0.351 },
    { name: 'Treant Protector', 'Sven': 0.94 },
    { name: 'Troll Warlord', 'Sven': 2.321 },
    { name: 'Tusk', 'Sven': 0.545 },
    { name: 'Underlord', 'Sven': -0.119 },
    { name: 'Undying', 'Sven': 0.556 },
    { name: 'Ursa', 'Sven': -0.02 },
    { name: 'Vengeful Spirit', 'Sven': -0.373 },
    { name: 'Venomancer', 'Sven': 0.875 },
    { name: 'Viper', 'Sven': 1.617 },
    { name: 'Visage', 'Sven': -1.044 },
    { name: 'Void Spirit', 'Sven': -2.236 },
    { name: 'Warlock', 'Sven': -1.03 },
    { name: 'Weaver', 'Sven': 0.367 },
    { name: 'Windranger', 'Sven': 0.337 },
    { name: 'Winter Wyvern', 'Sven': 2.283 },
    { name: 'Witch Doctor', 'Sven': 0.657 },
    { name: 'Wraith King', 'Sven': 0.568 },
    { name: 'Zeus', 'Sven': -0.67 },
],
"Tidehunter": [
    { name: 'Abaddon', 'Tidehunter': -0.128 },
    { name: 'Alchemist', 'Tidehunter': 1.101 },
    { name: 'Ancient Apparition', 'Tidehunter': 0.649 },
    { name: 'Anti-Mage', 'Tidehunter': 1.697 },
    { name: 'Arc Warden', 'Tidehunter': -0.656 },
    { name: 'Axe', 'Tidehunter': -0.335 },
    { name: 'Bane', 'Tidehunter': -1.632 },
    { name: 'Batrider', 'Tidehunter': -1.117 },
    { name: 'Beastmaster', 'Tidehunter': 0.254 },
    { name: 'Bloodseeker', 'Tidehunter': 1.712 },
    { name: 'Bounty Hunter', 'Tidehunter': -1.724 },
    { name: 'Brewmaster', 'Tidehunter': -0.61 },
    { name: 'Bristleback', 'Tidehunter': 1.972 },
    { name: 'Broodmother', 'Tidehunter': -0.448 },
    { name: 'Centaur Warrunner', 'Tidehunter': -2.065 },
    { name: 'Chaos Knight', 'Tidehunter': -3.911 },
    { name: 'Chen', 'Tidehunter': 7.477 },
    { name: 'Clinkz', 'Tidehunter': 1.177 },
    { name: 'Clockwerk', 'Tidehunter': 0.878 },
    { name: 'Crystal Maiden', 'Tidehunter': -1.252 },
    { name: 'Dark Seer', 'Tidehunter': 1.288 },
    { name: 'Dark Willow', 'Tidehunter': 0.487 },
    { name: 'Dawnbreaker', 'Tidehunter': 1.439 },
    { name: 'Dazzle', 'Tidehunter': -1.727 },
    { name: 'Death Prophet', 'Tidehunter': 1.674 },
    { name: 'Disruptor', 'Tidehunter': 0.456 },
    { name: 'Doom', 'Tidehunter': 0.96 },
    { name: 'Dragon Knight', 'Tidehunter': -0.363 },
    { name: 'Drow Ranger', 'Tidehunter': -0.586 },
    { name: 'Earth Spirit', 'Tidehunter': -1.099 },
    { name: 'Earthshaker', 'Tidehunter': -1.041 },
    { name: 'Elder Titan', 'Tidehunter': 3.285 },
    { name: 'Ember Spirit', 'Tidehunter': -1.867 },
    { name: 'Enchantress', 'Tidehunter': 0.517 },
    { name: 'Enigma', 'Tidehunter': -0.319 },
    { name: 'Faceless Void', 'Tidehunter': -3.131 },
    { name: 'Grimstroke', 'Tidehunter': 1.662 },
    { name: 'Gyrocopter', 'Tidehunter': -0.508 },
    { name: 'Hoodwink', 'Tidehunter': 0.292 },
    { name: 'Huskar', 'Tidehunter': 2.029 },
    { name: 'Invoker', 'Tidehunter': -0.239 },
    { name: 'Io', 'Tidehunter': 0.178 },
    { name: 'Jakiro', 'Tidehunter': -1.255 },
    { name: 'Juggernaut', 'Tidehunter': 3.066 },
    { name: 'Keeper of the Light', 'Tidehunter': 0.191 },
    { name: 'Kunkka', 'Tidehunter': 0.155 },
    { name: 'Legion Commander', 'Tidehunter': 0.186 },
    { name: 'Leshrac', 'Tidehunter': 3.058 },
    { name: 'Lich', 'Tidehunter': 0.647 },
    { name: 'Lifestealer', 'Tidehunter': 0.234 },
    { name: 'Lina', 'Tidehunter': -0.774 },
    { name: 'Lion', 'Tidehunter': -0.869 },
    { name: 'Lone Druid', 'Tidehunter': -0.875 },
    { name: 'Luna', 'Tidehunter': -0.839 },
    { name: 'Lycan', 'Tidehunter': -1.345 },
    { name: 'Magnus', 'Tidehunter': -1.051 },
    { name: 'Marci', 'Tidehunter': -0.76 },
    { name: 'Mars', 'Tidehunter': -0.007 },
    { name: 'Medusa', 'Tidehunter': -2.365 },
    { name: 'Meepo', 'Tidehunter': 0.719 },
    { name: 'Mirana', 'Tidehunter': -0.007 },
    { name: 'Monkey King', 'Tidehunter': 1.49 },
    { name: 'Morphling', 'Tidehunter': -0.983 },
    { name: 'Muerta', 'Tidehunter': 1.804 },
    { name: 'Naga Siren', 'Tidehunter': -2.096 },
    { name: "Nature's Prophet", 'Tidehunter': 0.952 },
    { name: 'Necrophos', 'Tidehunter': 2.362 },
    { name: 'Night Stalker', 'Tidehunter': 1.068 },
    { name: 'Nyx Assassin', 'Tidehunter': -0.125 },
    { name: 'Ogre Magi', 'Tidehunter': -1.212 },
    { name: 'Omniknight', 'Tidehunter': 0.823 },
    { name: 'Oracle', 'Tidehunter': -0.063 },
    { name: 'Outworld Destroyer', 'Tidehunter': 2.007 },
    { name: 'Pangolier', 'Tidehunter': 1.066 },
    { name: 'Phantom Assassin', 'Tidehunter': -1.091 },
    { name: 'Phantom Lancer', 'Tidehunter': -1.223 },
    { name: 'Phoenix', 'Tidehunter': -0.839 },
    { name: 'Primal Beast', 'Tidehunter': 0.55 },
    { name: 'Puck', 'Tidehunter': -1.013 },
    { name: 'Pudge', 'Tidehunter': -0.202 },
    { name: 'Pugna', 'Tidehunter': 2.567 },
    { name: 'Queen of Pain', 'Tidehunter': -0.16 },
    { name: 'Razor', 'Tidehunter': 3.462 },
    { name: 'Riki', 'Tidehunter': -0.332 },
    { name: 'Ringmaster', 'Tidehunter': 0.312 },
    { name: 'Rubick', 'Tidehunter': 0.304 },
    { name: 'Sand King', 'Tidehunter': -0.354 },
    { name: 'Shadow Demon', 'Tidehunter': -1.296 },
    { name: 'Shadow Fiend', 'Tidehunter': 0.899 },
    { name: 'Shadow Shaman', 'Tidehunter': -0.328 },
    { name: 'Silencer', 'Tidehunter': -2.45 },
    { name: 'Skywrath Mage', 'Tidehunter': -2.089 },
    { name: 'Slardar', 'Tidehunter': -3.969 },
    { name: 'Slark', 'Tidehunter': 2.573 },
    { name: 'Snapfire', 'Tidehunter': 1.576 },
    { name: 'Sniper', 'Tidehunter': 1.23 },
    { name: 'Spectre', 'Tidehunter': -2.509 },
    { name: 'Spirit Breaker', 'Tidehunter': -1.301 },
    { name: 'Storm Spirit', 'Tidehunter': -1.156 },
    { name: 'Sven', 'Tidehunter': 1.091 },
    { name: 'Techies', 'Tidehunter': -1.106 },
    { name: 'Templar Assassin', 'Tidehunter': 0.325 },
    { name: 'Terrorblade', 'Tidehunter': 0.627 },
    { name: 'Timbersaw', 'Tidehunter': 2.066 },
    { name: 'Tinker', 'Tidehunter': 1.054 },
    { name: 'Tiny', 'Tidehunter': -0.995 },
    { name: 'Treant Protector', 'Tidehunter': -0.868 },
    { name: 'Troll Warlord', 'Tidehunter': -1.263 },
    { name: 'Tusk', 'Tidehunter': 0.933 },
    { name: 'Underlord', 'Tidehunter': 0.663 },
    { name: 'Undying', 'Tidehunter': 1.213 },
    { name: 'Ursa', 'Tidehunter': 2.007 },
    { name: 'Vengeful Spirit', 'Tidehunter': -1.536 },
    { name: 'Venomancer', 'Tidehunter': 0.705 },
    { name: 'Viper', 'Tidehunter': -0.002 },
    { name: 'Visage', 'Tidehunter': -0.05 },
    { name: 'Void Spirit', 'Tidehunter': -1.251 },
    { name: 'Warlock', 'Tidehunter': -2.15 },
    { name: 'Weaver', 'Tidehunter': 1.374 },
    { name: 'Windranger', 'Tidehunter': 0.31 },
    { name: 'Winter Wyvern', 'Tidehunter': -1.063 },
    { name: 'Witch Doctor', 'Tidehunter': 2.136 },
    { name: 'Wraith King', 'Tidehunter': -3.769 },
    { name: 'Zeus', 'Tidehunter': 0.117 },
],
"Tiny": [
    { name: 'Abaddon', 'Tiny': -0.86 },
    { name: 'Alchemist', 'Tiny': 1.283 },
    { name: 'Ancient Apparition', 'Tiny': 0.398 },
    { name: 'Anti-Mage', 'Tiny': -0.711 },
    { name: 'Arc Warden', 'Tiny': -1.376 },
    { name: 'Axe', 'Tiny': -1.805 },
    { name: 'Bane', 'Tiny': -0.34 },
    { name: 'Batrider', 'Tiny': 0.257 },
    { name: 'Beastmaster', 'Tiny': 0.25 },
    { name: 'Bloodseeker', 'Tiny': 0.677 },
    { name: 'Bounty Hunter', 'Tiny': -0.301 },
    { name: 'Brewmaster', 'Tiny': 0.868 },
    { name: 'Bristleback', 'Tiny': 0.946 },
    { name: 'Broodmother', 'Tiny': -2.471 },
    { name: 'Centaur Warrunner', 'Tiny': -0.108 },
    { name: 'Chaos Knight', 'Tiny': -1.178 },
    { name: 'Chen', 'Tiny': -0.206 },
    { name: 'Clinkz', 'Tiny': -1.176 },
    { name: 'Clockwerk', 'Tiny': 0.967 },
    { name: 'Crystal Maiden', 'Tiny': 0.005 },
    { name: 'Dark Seer', 'Tiny': -0.421 },
    { name: 'Dark Willow', 'Tiny': -0.183 },
    { name: 'Dawnbreaker', 'Tiny': 0.763 },
    { name: 'Dazzle', 'Tiny': 0.008 },
    { name: 'Death Prophet', 'Tiny': 1.354 },
    { name: 'Disruptor', 'Tiny': -0.232 },
    { name: 'Doom', 'Tiny': 2.001 },
    { name: 'Dragon Knight', 'Tiny': 0.388 },
    { name: 'Drow Ranger', 'Tiny': -1.751 },
    { name: 'Earth Spirit', 'Tiny': -0.017 },
    { name: 'Earthshaker', 'Tiny': -0.704 },
    { name: 'Elder Titan', 'Tiny': 1.853 },
    { name: 'Ember Spirit', 'Tiny': 1.207 },
    { name: 'Enchantress', 'Tiny': 0.289 },
    { name: 'Enigma', 'Tiny': -0.525 },
    { name: 'Faceless Void', 'Tiny': -0.006 },
    { name: 'Grimstroke', 'Tiny': 0.213 },
    { name: 'Gyrocopter', 'Tiny': 0.871 },
    { name: 'Hoodwink', 'Tiny': -0.843 },
    { name: 'Huskar', 'Tiny': 2.001 },
    { name: 'Invoker', 'Tiny': -0.263 },
    { name: 'Io', 'Tiny': -0.865 },
    { name: 'Jakiro', 'Tiny': 0.509 },
    { name: 'Juggernaut', 'Tiny': 0.343 },
    { name: 'Keeper of the Light', 'Tiny': 0.665 },
    { name: 'Kunkka', 'Tiny': 0.342 },
    { name: 'Legion Commander', 'Tiny': -2.232 },
    { name: 'Leshrac', 'Tiny': 1.548 },
    { name: 'Lich', 'Tiny': 0.628 },
    { name: 'Lifestealer', 'Tiny': 2.711 },
    { name: 'Lina', 'Tiny': -0.379 },
    { name: 'Lion', 'Tiny': -0.659 },
    { name: 'Lone Druid', 'Tiny': -0.309 },
    { name: 'Luna', 'Tiny': 0.181 },
    { name: 'Lycan', 'Tiny': -0.403 },
    { name: 'Magnus', 'Tiny': -0.185 },
    { name: 'Marci', 'Tiny': -0.929 },
    { name: 'Mars', 'Tiny': 0.351 },
    { name: 'Medusa', 'Tiny': -0.725 },
    { name: 'Meepo', 'Tiny': -0.677 },
    { name: 'Mirana', 'Tiny': -0.338 },
    { name: 'Monkey King', 'Tiny': 0.931 },
    { name: 'Morphling', 'Tiny': -0.77 },
    { name: 'Muerta', 'Tiny': -0.379 },
    { name: 'Naga Siren', 'Tiny': -3.289 },
    { name: "Nature's Prophet", 'Tiny': -0.61 },
    { name: 'Necrophos', 'Tiny': 3.148 },
    { name: 'Night Stalker', 'Tiny': -0.481 },
    { name: 'Nyx Assassin', 'Tiny': -1.023 },
    { name: 'Ogre Magi', 'Tiny': 0.627 },
    { name: 'Omniknight', 'Tiny': 0.214 },
    { name: 'Oracle', 'Tiny': -0.955 },
    { name: 'Outworld Destroyer', 'Tiny': 0.155 },
    { name: 'Pangolier', 'Tiny': 0.255 },
    { name: 'Phantom Assassin', 'Tiny': -2.136 },
    { name: 'Phantom Lancer', 'Tiny': -0.501 },
    { name: 'Phoenix', 'Tiny': 1.36 },
    { name: 'Primal Beast', 'Tiny': -0.159 },
    { name: 'Puck', 'Tiny': -1.86 },
    { name: 'Pudge', 'Tiny': -0.568 },
    { name: 'Pugna', 'Tiny': -0.22 },
    { name: 'Queen of Pain', 'Tiny': 0.605 },
    { name: 'Razor', 'Tiny': 0.309 },
    { name: 'Riki', 'Tiny': -2.599 },
    { name: 'Ringmaster', 'Tiny': 0.504 },
    { name: 'Rubick', 'Tiny': -0.15 },
    { name: 'Sand King', 'Tiny': 1.112 },
    { name: 'Shadow Demon', 'Tiny': -0.069 },
    { name: 'Shadow Fiend', 'Tiny': -1.656 },
    { name: 'Shadow Shaman', 'Tiny': -1.09 },
    { name: 'Silencer', 'Tiny': -0.091 },
    { name: 'Skywrath Mage', 'Tiny': -0.269 },
    { name: 'Slardar', 'Tiny': -0.595 },
    { name: 'Slark', 'Tiny': -1.434 },
    { name: 'Snapfire', 'Tiny': 0.397 },
    { name: 'Sniper', 'Tiny': -0.778 },
    { name: 'Spectre', 'Tiny': 0.943 },
    { name: 'Spirit Breaker', 'Tiny': -2.174 },
    { name: 'Storm Spirit', 'Tiny': -0.878 },
    { name: 'Sven', 'Tiny': 0.281 },
    { name: 'Techies', 'Tiny': -0.326 },
    { name: 'Templar Assassin', 'Tiny': -0.057 },
    { name: 'Terrorblade', 'Tiny': 0.166 },
    { name: 'Tidehunter', 'Tiny': 0.884 },
    { name: 'Timbersaw', 'Tiny': 1.144 },
    { name: 'Tinker', 'Tiny': -0.007 },
    { name: 'Treant Protector', 'Tiny': 0.549 },
    { name: 'Troll Warlord', 'Tiny': -0.402 },
    { name: 'Tusk', 'Tiny': -0.042 },
    { name: 'Underlord', 'Tiny': 1.728 },
    { name: 'Undying', 'Tiny': 1.528 },
    { name: 'Ursa', 'Tiny': -0.22 },
    { name: 'Vengeful Spirit', 'Tiny': -0.566 },
    { name: 'Venomancer', 'Tiny': 1.685 },
    { name: 'Viper', 'Tiny': 2.332 },
    { name: 'Visage', 'Tiny': 0.083 },
    { name: 'Void Spirit', 'Tiny': -1.004 },
    { name: 'Warlock', 'Tiny': 0.973 },
    { name: 'Weaver', 'Tiny': -0.082 },
    { name: 'Windranger', 'Tiny': -1.627 },
    { name: 'Winter Wyvern', 'Tiny': 0.688 },
    { name: 'Witch Doctor', 'Tiny': 0.711 },
    { name: 'Wraith King', 'Tiny': 0.486 },
    { name: 'Zeus', 'Tiny': 0.145 },
],
"Treant Protector": [
    { name: 'Abaddon', 'Treant Protector': -0.202 },
    { name: 'Alchemist', 'Treant Protector': 0.26 },
    { name: 'Ancient Apparition', 'Treant Protector': 2.191 },
    { name: 'Anti-Mage', 'Treant Protector': 0.131 },
    { name: 'Arc Warden', 'Treant Protector': -0.303 },
    { name: 'Axe', 'Treant Protector': -1.02 },
    { name: 'Bane', 'Treant Protector': -0.277 },
    { name: 'Batrider', 'Treant Protector': 0.986 },
    { name: 'Beastmaster', 'Treant Protector': 0.105 },
    { name: 'Bloodseeker', 'Treant Protector': -0.598 },
    { name: 'Bounty Hunter', 'Treant Protector': -0.048 },
    { name: 'Brewmaster', 'Treant Protector': 0.251 },
    { name: 'Bristleback', 'Treant Protector': 1.013 },
    { name: 'Broodmother', 'Treant Protector': -0.142 },
    { name: 'Centaur Warrunner', 'Treant Protector': -1.406 },
    { name: 'Chaos Knight', 'Treant Protector': -1.108 },
    { name: 'Chen', 'Treant Protector': -0.635 },
    { name: 'Clinkz', 'Treant Protector': -0.184 },
    { name: 'Clockwerk', 'Treant Protector': -0.097 },
    { name: 'Crystal Maiden', 'Treant Protector': 0.729 },
    { name: 'Dark Seer', 'Treant Protector': 0.63 },
    { name: 'Dark Willow', 'Treant Protector': 0.287 },
    { name: 'Dawnbreaker', 'Treant Protector': 0.821 },
    { name: 'Dazzle', 'Treant Protector': -0.046 },
    { name: 'Death Prophet', 'Treant Protector': 1.33 },
    { name: 'Disruptor', 'Treant Protector': -0.062 },
    { name: 'Doom', 'Treant Protector': 0.799 },
    { name: 'Dragon Knight', 'Treant Protector': -1.331 },
    { name: 'Drow Ranger', 'Treant Protector': -0.038 },
    { name: 'Earth Spirit', 'Treant Protector': 0.077 },
    { name: 'Earthshaker', 'Treant Protector': 0.222 },
    { name: 'Elder Titan', 'Treant Protector': -0.857 },
    { name: 'Ember Spirit', 'Treant Protector': -1.272 },
    { name: 'Enchantress', 'Treant Protector': 0.393 },
    { name: 'Enigma', 'Treant Protector': 0.392 },
    { name: 'Faceless Void', 'Treant Protector': 0.64 },
    { name: 'Grimstroke', 'Treant Protector': 0.712 },
    { name: 'Gyrocopter', 'Treant Protector': 0.023 },
    { name: 'Hoodwink', 'Treant Protector': 0.046 },
    { name: 'Huskar', 'Treant Protector': 1.468 },
    { name: 'Invoker', 'Treant Protector': 0.38 },
    { name: 'Io', 'Treant Protector': -0.357 },
    { name: 'Jakiro', 'Treant Protector': 0.043 },
    { name: 'Juggernaut', 'Treant Protector': 2.206 },
    { name: 'Keeper of the Light', 'Treant Protector': 0.889 },
    { name: 'Kunkka', 'Treant Protector': 0.297 },
    { name: 'Legion Commander', 'Treant Protector': -1.46 },
    { name: 'Leshrac', 'Treant Protector': 0.86 },
    { name: 'Lich', 'Treant Protector': 0.365 },
    { name: 'Lifestealer', 'Treant Protector': -0.633 },
    { name: 'Lina', 'Treant Protector': 1.014 },
    { name: 'Lion', 'Treant Protector': -0.566 },
    { name: 'Lone Druid', 'Treant Protector': -1.11 },
    { name: 'Luna', 'Treant Protector': -0.285 },
    { name: 'Lycan', 'Treant Protector': -0.098 },
    { name: 'Magnus', 'Treant Protector': 0.999 },
    { name: 'Marci', 'Treant Protector': -1.362 },
    { name: 'Mars', 'Treant Protector': 0.783 },
    { name: 'Medusa', 'Treant Protector': -1.136 },
    { name: 'Meepo', 'Treant Protector': -0.477 },
    { name: 'Mirana', 'Treant Protector': -0.788 },
    { name: 'Monkey King', 'Treant Protector': 0.512 },
    { name: 'Morphling', 'Treant Protector': 0.441 },
    { name: 'Muerta', 'Treant Protector': 0.471 },
    { name: 'Naga Siren', 'Treant Protector': 0.545 },
    { name: "Nature's Prophet", 'Treant Protector': -1.953 },
    { name: 'Necrophos', 'Treant Protector': 1.37 },
    { name: 'Night Stalker', 'Treant Protector': -1.275 },
    { name: 'Nyx Assassin', 'Treant Protector': -0.175 },
    { name: 'Ogre Magi', 'Treant Protector': 0.086 },
    { name: 'Omniknight', 'Treant Protector': -0.383 },
    { name: 'Oracle', 'Treant Protector': 0.656 },
    { name: 'Outworld Destroyer', 'Treant Protector': -0.276 },
    { name: 'Pangolier', 'Treant Protector': -1.319 },
    { name: 'Phantom Assassin', 'Treant Protector': -0.907 },
    { name: 'Phantom Lancer', 'Treant Protector': -0.513 },
    { name: 'Phoenix', 'Treant Protector': 2.675 },
    { name: 'Primal Beast', 'Treant Protector': 0.429 },
    { name: 'Puck', 'Treant Protector': -0.092 },
    { name: 'Pudge', 'Treant Protector': 0.549 },
    { name: 'Pugna', 'Treant Protector': 1.593 },
    { name: 'Queen of Pain', 'Treant Protector': 0.532 },
    { name: 'Razor', 'Treant Protector': -0.048 },
    { name: 'Riki', 'Treant Protector': -0.26 },
    { name: 'Ringmaster', 'Treant Protector': 0.664 },
    { name: 'Rubick', 'Treant Protector': 0.025 },
    { name: 'Sand King', 'Treant Protector': 0.648 },
    { name: 'Shadow Demon', 'Treant Protector': 0.545 },
    { name: 'Shadow Fiend', 'Treant Protector': -0.117 },
    { name: 'Shadow Shaman', 'Treant Protector': -0.834 },
    { name: 'Silencer', 'Treant Protector': 0.334 },
    { name: 'Skywrath Mage', 'Treant Protector': 0.409 },
    { name: 'Slardar', 'Treant Protector': -0.491 },
    { name: 'Slark', 'Treant Protector': 1.846 },
    { name: 'Snapfire', 'Treant Protector': 0.734 },
    { name: 'Sniper', 'Treant Protector': 0.748 },
    { name: 'Spectre', 'Treant Protector': -0.881 },
    { name: 'Spirit Breaker', 'Treant Protector': -0.852 },
    { name: 'Storm Spirit', 'Treant Protector': -0.626 },
    { name: 'Sven', 'Treant Protector': -1.034 },
    { name: 'Techies', 'Treant Protector': 0.854 },
    { name: 'Templar Assassin', 'Treant Protector': -1.848 },
    { name: 'Terrorblade', 'Treant Protector': 0.72 },
    { name: 'Tidehunter', 'Treant Protector': 0.88 },
    { name: 'Timbersaw', 'Treant Protector': 1.962 },
    { name: 'Tinker', 'Treant Protector': 0.285 },
    { name: 'Tiny', 'Treant Protector': -0.786 },
    { name: 'Troll Warlord', 'Treant Protector': 0.43 },
    { name: 'Tusk', 'Treant Protector': -0.791 },
    { name: 'Underlord', 'Treant Protector': 0.184 },
    { name: 'Undying', 'Treant Protector': 0.895 },
    { name: 'Ursa', 'Treant Protector': 0.493 },
    { name: 'Vengeful Spirit', 'Treant Protector': -0.705 },
    { name: 'Venomancer', 'Treant Protector': 0.535 },
    { name: 'Viper', 'Treant Protector': 1.475 },
    { name: 'Visage', 'Treant Protector': -0.868 },
    { name: 'Void Spirit', 'Treant Protector': -0.379 },
    { name: 'Warlock', 'Treant Protector': -0.152 },
    { name: 'Weaver', 'Treant Protector': 0.674 },
    { name: 'Windranger', 'Treant Protector': -0.535 },
    { name: 'Winter Wyvern', 'Treant Protector': -0.024 },
    { name: 'Witch Doctor', 'Treant Protector': 0.113 },
    { name: 'Wraith King', 'Treant Protector': -1.678 },
    { name: 'Zeus', 'Treant Protector': 0.281 },
],
"Tusk": [
    { name: 'Abaddon', 'Tusk': -1.925 },
    { name: 'Alchemist', 'Tusk': -1.031 },
    { name: 'Ancient Apparition', 'Tusk': -0.026 },
    { name: 'Anti-Mage', 'Tusk': -0.156 },
    { name: 'Arc Warden', 'Tusk': -1.235 },
    { name: 'Axe', 'Tusk': 0.136 },
    { name: 'Bane', 'Tusk': -0.995 },
    { name: 'Batrider', 'Tusk': 0.885 },
    { name: 'Beastmaster', 'Tusk': -0.071 },
    { name: 'Bloodseeker', 'Tusk': -0.791 },
    { name: 'Bounty Hunter', 'Tusk': -1.176 },
    { name: 'Brewmaster', 'Tusk': -1.367 },
    { name: 'Bristleback', 'Tusk': 0.028 },
    { name: 'Broodmother', 'Tusk': -0.41 },
    { name: 'Centaur Warrunner', 'Tusk': -0.224 },
    { name: 'Chaos Knight', 'Tusk': 0.5 },
    { name: 'Chen', 'Tusk': 0.008 },
    { name: 'Clinkz', 'Tusk': 0.023 },
    { name: 'Clockwerk', 'Tusk': -0.704 },
    { name: 'Crystal Maiden', 'Tusk': 1.008 },
    { name: 'Dark Seer', 'Tusk': 0.901 },
    { name: 'Dark Willow', 'Tusk': 1.633 },
    { name: 'Dawnbreaker', 'Tusk': 2.253 },
    { name: 'Dazzle', 'Tusk': -0.431 },
    { name: 'Death Prophet', 'Tusk': 0.369 },
    { name: 'Disruptor', 'Tusk': -0.327 },
    { name: 'Doom', 'Tusk': -0.358 },
    { name: 'Dragon Knight', 'Tusk': 0.457 },
    { name: 'Drow Ranger', 'Tusk': 0.532 },
    { name: 'Earth Spirit', 'Tusk': 0.834 },
    { name: 'Earthshaker', 'Tusk': 1.019 },
    { name: 'Elder Titan', 'Tusk': 0.142 },
    { name: 'Ember Spirit', 'Tusk': 1.667 },
    { name: 'Enchantress', 'Tusk': -0.108 },
    { name: 'Enigma', 'Tusk': 0.17 },
    { name: 'Faceless Void', 'Tusk': 1.31 },
    { name: 'Grimstroke', 'Tusk': -0.159 },
    { name: 'Gyrocopter', 'Tusk': 0.488 },
    { name: 'Hoodwink', 'Tusk': 0.54 },
    { name: 'Huskar', 'Tusk': -0.213 },
    { name: 'Invoker', 'Tusk': -0.901 },
    { name: 'Io', 'Tusk': -0.935 },
    { name: 'Jakiro', 'Tusk': 0.379 },
    { name: 'Juggernaut', 'Tusk': -0.438 },
    { name: 'Keeper of the Light', 'Tusk': -0.224 },
    { name: 'Kunkka', 'Tusk': -0.231 },
    { name: 'Legion Commander', 'Tusk': -2.007 },
    { name: 'Leshrac', 'Tusk': -0.145 },
    { name: 'Lich', 'Tusk': 1.376 },
    { name: 'Lifestealer', 'Tusk': -2.246 },
    { name: 'Lina', 'Tusk': -0.392 },
    { name: 'Lion', 'Tusk': 0.73 },
    { name: 'Lone Druid', 'Tusk': -1.996 },
    { name: 'Luna', 'Tusk': 0.985 },
    { name: 'Lycan', 'Tusk': -2.333 },
    { name: 'Magnus', 'Tusk': 1.102 },
    { name: 'Marci', 'Tusk': -0.464 },
    { name: 'Mars', 'Tusk': -0.26 },
    { name: 'Medusa', 'Tusk': 0.424 },
    { name: 'Meepo', 'Tusk': -0.143 },
    { name: 'Mirana', 'Tusk': 0.005 },
    { name: 'Monkey King', 'Tusk': 1.026 },
    { name: 'Morphling', 'Tusk': 0.215 },
    { name: 'Muerta', 'Tusk': 1.26 },
    { name: 'Naga Siren', 'Tusk': 1.367 },
    { name: "Nature's Prophet", 'Tusk': 0.104 },
    { name: 'Necrophos', 'Tusk': 1.08 },
    { name: 'Night Stalker', 'Tusk': -1.05 },
    { name: 'Nyx Assassin', 'Tusk': -0.93 },
    { name: 'Ogre Magi', 'Tusk': 0.032 },
    { name: 'Omniknight', 'Tusk': 0.582 },
    { name: 'Oracle', 'Tusk': -2.174 },
    { name: 'Outworld Destroyer', 'Tusk': -0.892 },
    { name: 'Pangolier', 'Tusk': -0.846 },
    { name: 'Phantom Assassin', 'Tusk': -0.934 },
    { name: 'Phantom Lancer', 'Tusk': 1.407 },
    { name: 'Phoenix', 'Tusk': 0.473 },
    { name: 'Primal Beast', 'Tusk': -0.105 },
    { name: 'Puck', 'Tusk': 0.956 },
    { name: 'Pudge', 'Tusk': -0.989 },
    { name: 'Pugna', 'Tusk': 0.18 },
    { name: 'Queen of Pain', 'Tusk': 0.533 },
    { name: 'Razor', 'Tusk': 0.322 },
    { name: 'Riki', 'Tusk': 0.491 },
    { name: 'Ringmaster', 'Tusk': -0.011 },
    { name: 'Rubick', 'Tusk': -1.457 },
    { name: 'Sand King', 'Tusk': 1.642 },
    { name: 'Shadow Demon', 'Tusk': -1.392 },
    { name: 'Shadow Fiend', 'Tusk': -0.236 },
    { name: 'Shadow Shaman', 'Tusk': 0.246 },
    { name: 'Silencer', 'Tusk': -0.311 },
    { name: 'Skywrath Mage', 'Tusk': -1.529 },
    { name: 'Slardar', 'Tusk': -0.454 },
    { name: 'Slark', 'Tusk': -0.952 },
    { name: 'Snapfire', 'Tusk': -0.218 },
    { name: 'Sniper', 'Tusk': -0.095 },
    { name: 'Spectre', 'Tusk': 0.697 },
    { name: 'Spirit Breaker', 'Tusk': -1.11 },
    { name: 'Storm Spirit', 'Tusk': 0.285 },
    { name: 'Sven', 'Tusk': -0.404 },
    { name: 'Techies', 'Tusk': 0.347 },
    { name: 'Templar Assassin', 'Tusk': 0.836 },
    { name: 'Terrorblade', 'Tusk': 1.899 },
    { name: 'Tidehunter', 'Tusk': -0.879 },
    { name: 'Timbersaw', 'Tusk': 1.962 },
    { name: 'Tinker', 'Tusk': -0.741 },
    { name: 'Tiny', 'Tusk': 0.197 },
    { name: 'Treant Protector', 'Tusk': 0.871 },
    { name: 'Troll Warlord', 'Tusk': -0.344 },
    { name: 'Underlord', 'Tusk': 0.947 },
    { name: 'Undying', 'Tusk': 1.235 },
    { name: 'Ursa', 'Tusk': -0.574 },
    { name: 'Vengeful Spirit', 'Tusk': -0.439 },
    { name: 'Venomancer', 'Tusk': -0.242 },
    { name: 'Viper', 'Tusk': 0.129 },
    { name: 'Visage', 'Tusk': -0.26 },
    { name: 'Void Spirit', 'Tusk': 0.105 },
    { name: 'Warlock', 'Tusk': -0.578 },
    { name: 'Weaver', 'Tusk': 1.458 },
    { name: 'Windranger', 'Tusk': -0.35 },
    { name: 'Winter Wyvern', 'Tusk': 0.997 },
    { name: 'Witch Doctor', 'Tusk': 0.179 },
    { name: 'Wraith King', 'Tusk': -1.016 },
    { name: 'Zeus', 'Tusk': -0.282 },
],
"Underlord": [
    { name: 'Abaddon', 'Underlord': -1.089 },
    { name: 'Alchemist', 'Underlord': 1.417 },
    { name: 'Ancient Apparition', 'Underlord': 1.634 },
    { name: 'Anti-Mage', 'Underlord': 1.36 },
    { name: 'Arc Warden', 'Underlord': -1.125 },
    { name: 'Axe', 'Underlord': -0.646 },
    { name: 'Bane', 'Underlord': 0.341 },
    { name: 'Batrider', 'Underlord': 0.492 },
    { name: 'Beastmaster', 'Underlord': -0.466 },
    { name: 'Bloodseeker', 'Underlord': 0.035 },
    { name: 'Bounty Hunter', 'Underlord': 0.696 },
    { name: 'Brewmaster', 'Underlord': -0.037 },
    { name: 'Bristleback', 'Underlord': 1.29 },
    { name: 'Broodmother', 'Underlord': 1.508 },
    { name: 'Centaur Warrunner', 'Underlord': -1.905 },
    { name: 'Chaos Knight', 'Underlord': -2.502 },
    { name: 'Chen', 'Underlord': 0.853 },
    { name: 'Clinkz', 'Underlord': 1.145 },
    { name: 'Clockwerk', 'Underlord': -1.159 },
    { name: 'Crystal Maiden', 'Underlord': 0.076 },
    { name: 'Dark Seer', 'Underlord': 0.269 },
    { name: 'Dark Willow', 'Underlord': -0.606 },
    { name: 'Dawnbreaker', 'Underlord': -0.575 },
    { name: 'Dazzle', 'Underlord': -0.13 },
    { name: 'Death Prophet', 'Underlord': 0.605 },
    { name: 'Disruptor', 'Underlord': -0.311 },
    { name: 'Doom', 'Underlord': 1.136 },
    { name: 'Dragon Knight', 'Underlord': -1.732 },
    { name: 'Drow Ranger', 'Underlord': -0.453 },
    { name: 'Earth Spirit', 'Underlord': 0.823 },
    { name: 'Earthshaker', 'Underlord': -0.089 },
    { name: 'Elder Titan', 'Underlord': 1.684 },
    { name: 'Ember Spirit', 'Underlord': -0.712 },
    { name: 'Enchantress', 'Underlord': 0.369 },
    { name: 'Enigma', 'Underlord': 0.51 },
    { name: 'Faceless Void', 'Underlord': 0.633 },
    { name: 'Grimstroke', 'Underlord': 0.18 },
    { name: 'Gyrocopter', 'Underlord': -1.262 },
    { name: 'Hoodwink', 'Underlord': 1.036 },
    { name: 'Huskar', 'Underlord': 1.259 },
    { name: 'Invoker', 'Underlord': 0.932 },
    { name: 'Io', 'Underlord': -2.535 },
    { name: 'Jakiro', 'Underlord': -0.157 },
    { name: 'Juggernaut', 'Underlord': 3.342 },
    { name: 'Keeper of the Light', 'Underlord': 1.126 },
    { name: 'Kunkka', 'Underlord': -1.062 },
    { name: 'Legion Commander', 'Underlord': 0.902 },
    { name: 'Leshrac', 'Underlord': -0.205 },
    { name: 'Lich', 'Underlord': -0.253 },
    { name: 'Lifestealer', 'Underlord': 2.006 },
    { name: 'Lina', 'Underlord': 2.119 },
    { name: 'Lion', 'Underlord': -0.432 },
    { name: 'Lone Druid', 'Underlord': -4.425 },
    { name: 'Luna', 'Underlord': -2.157 },
    { name: 'Lycan', 'Underlord': -1.129 },
    { name: 'Magnus', 'Underlord': 0.494 },
    { name: 'Marci', 'Underlord': -0.218 },
    { name: 'Mars', 'Underlord': -0.36 },
    { name: 'Medusa', 'Underlord': -2.389 },
    { name: 'Meepo', 'Underlord': -3.106 },
    { name: 'Mirana', 'Underlord': 0.281 },
    { name: 'Monkey King', 'Underlord': 1.326 },
    { name: 'Morphling', 'Underlord': 1.36 },
    { name: 'Muerta', 'Underlord': 0.5 },
    { name: 'Naga Siren', 'Underlord': -4.26 },
    { name: "Nature's Prophet", 'Underlord': -0.605 },
    { name: 'Necrophos', 'Underlord': -1.095 },
    { name: 'Night Stalker', 'Underlord': 0.645 },
    { name: 'Nyx Assassin', 'Underlord': -0.216 },
    { name: 'Ogre Magi', 'Underlord': -1.006 },
    { name: 'Omniknight', 'Underlord': 0.693 },
    { name: 'Oracle', 'Underlord': 1.192 },
    { name: 'Outworld Destroyer', 'Underlord': 2.312 },
    { name: 'Pangolier', 'Underlord': 0.704 },
    { name: 'Phantom Assassin', 'Underlord': 0.801 },
    { name: 'Phantom Lancer', 'Underlord': -2.687 },
    { name: 'Phoenix', 'Underlord': -0.652 },
    { name: 'Primal Beast', 'Underlord': -1.494 },
    { name: 'Puck', 'Underlord': -0.338 },
    { name: 'Pudge', 'Underlord': 1.363 },
    { name: 'Pugna', 'Underlord': 1.751 },
    { name: 'Queen of Pain', 'Underlord': -0.533 },
    { name: 'Razor', 'Underlord': -1.033 },
    { name: 'Riki', 'Underlord': -4.323 },
    { name: 'Ringmaster', 'Underlord': 0.774 },
    { name: 'Rubick', 'Underlord': 7.089 },
    { name: 'Sand King', 'Underlord': -1.24 },
    { name: 'Shadow Demon', 'Underlord': -0.805 },
    { name: 'Shadow Fiend', 'Underlord': 0.297 },
    { name: 'Shadow Shaman', 'Underlord': -1.049 },
    { name: 'Silencer', 'Underlord': -0.674 },
    { name: 'Skywrath Mage', 'Underlord': 0.147 },
    { name: 'Slardar', 'Underlord': -0.363 },
    { name: 'Slark', 'Underlord': 0.758 },
    { name: 'Snapfire', 'Underlord': 1.923 },
    { name: 'Sniper', 'Underlord': 1.808 },
    { name: 'Spectre', 'Underlord': -3.211 },
    { name: 'Spirit Breaker', 'Underlord': -3.732 },
    { name: 'Storm Spirit', 'Underlord': -0.559 },
    { name: 'Sven', 'Underlord': 0.067 },
    { name: 'Techies', 'Underlord': -0.742 },
    { name: 'Templar Assassin', 'Underlord': -0.677 },
    { name: 'Terrorblade', 'Underlord': -0.984 },
    { name: 'Tidehunter', 'Underlord': -0.627 },
    { name: 'Timbersaw', 'Underlord': 1.586 },
    { name: 'Tinker', 'Underlord': 1.084 },
    { name: 'Tiny', 'Underlord': -2.067 },
    { name: 'Treant Protector', 'Underlord': -0.154 },
    { name: 'Troll Warlord', 'Underlord': -1.853 },
    { name: 'Tusk', 'Underlord': -0.767 },
    { name: 'Undying', 'Underlord': 0.06 },
    { name: 'Ursa', 'Underlord': 2.885 },
    { name: 'Vengeful Spirit', 'Underlord': -1.19 },
    { name: 'Venomancer', 'Underlord': 0.232 },
    { name: 'Viper', 'Underlord': 1.215 },
    { name: 'Visage', 'Underlord': -1.406 },
    { name: 'Void Spirit', 'Underlord': -2.458 },
    { name: 'Warlock', 'Underlord': -0.26 },
    { name: 'Weaver', 'Underlord': -0.253 },
    { name: 'Windranger', 'Underlord': 1.151 },
    { name: 'Winter Wyvern', 'Underlord': 0.282 },
    { name: 'Witch Doctor', 'Underlord': 1.197 },
    { name: 'Wraith King', 'Underlord': -5.055 },
    { name: 'Zeus', 'Underlord': -1.025 },
],
"Undying": [
    { name: 'Abaddon', 'Undying': -2.759 },
    { name: 'Alchemist', 'Undying': 0.56 },
    { name: 'Ancient Apparition', 'Undying': 2.085 },
    { name: 'Anti-Mage', 'Undying': 1.367 },
    { name: 'Arc Warden', 'Undying': -0.764 },
    { name: 'Axe', 'Undying': 0.349 },
    { name: 'Bane', 'Undying': -0.373 },
    { name: 'Batrider', 'Undying': -1.182 },
    { name: 'Beastmaster', 'Undying': -0.382 },
    { name: 'Bloodseeker', 'Undying': 0.42 },
    { name: 'Bounty Hunter', 'Undying': 0.996 },
    { name: 'Brewmaster', 'Undying': -0.064 },
    { name: 'Bristleback', 'Undying': 0.102 },
    { name: 'Broodmother', 'Undying': 1.333 },
    { name: 'Centaur Warrunner', 'Undying': -1.937 },
    { name: 'Chaos Knight', 'Undying': -0.003 },
    { name: 'Chen', 'Undying': 0.799 },
    { name: 'Clinkz', 'Undying': 1.918 },
    { name: 'Clockwerk', 'Undying': -1.362 },
    { name: 'Crystal Maiden', 'Undying': -0.69 },
    { name: 'Dark Seer', 'Undying': 0.138 },
    { name: 'Dark Willow', 'Undying': 0.039 },
    { name: 'Dawnbreaker', 'Undying': 0.259 },
    { name: 'Dazzle', 'Undying': -0.657 },
    { name: 'Death Prophet', 'Undying': -2.109 },
    { name: 'Disruptor', 'Undying': -0.562 },
    { name: 'Doom', 'Undying': 0.127 },
    { name: 'Dragon Knight', 'Undying': -0.945 },
    { name: 'Drow Ranger', 'Undying': -0.163 },
    { name: 'Earth Spirit', 'Undying': -0.37 },
    { name: 'Earthshaker', 'Undying': 1.063 },
    { name: 'Elder Titan', 'Undying': 2.236 },
    { name: 'Ember Spirit', 'Undying': 1.033 },
    { name: 'Enchantress', 'Undying': 0.491 },
    { name: 'Enigma', 'Undying': -0.713 },
    { name: 'Faceless Void', 'Undying': 0.663 },
    { name: 'Grimstroke', 'Undying': 0.627 },
    { name: 'Gyrocopter', 'Undying': 1.263 },
    { name: 'Hoodwink', 'Undying': 0.025 },
    { name: 'Huskar', 'Undying': -0.92 },
    { name: 'Invoker', 'Undying': 0.293 },
    { name: 'Io', 'Undying': -0.303 },
    { name: 'Jakiro', 'Undying': 0.385 },
    { name: 'Juggernaut', 'Undying': 0.406 },
    { name: 'Keeper of the Light', 'Undying': 0.666 },
    { name: 'Kunkka', 'Undying': -1.774 },
    { name: 'Legion Commander', 'Undying': 0.793 },
    { name: 'Leshrac', 'Undying': -3.203 },
    { name: 'Lich', 'Undying': -0.829 },
    { name: 'Lifestealer', 'Undying': 0.033 },
    { name: 'Lina', 'Undying': 0.492 },
    { name: 'Lion', 'Undying': 0.178 },
    { name: 'Lone Druid', 'Undying': -1.218 },
    { name: 'Luna', 'Undying': 2.886 },
    { name: 'Lycan', 'Undying': 0.46 },
    { name: 'Magnus', 'Undying': 0.394 },
    { name: 'Marci', 'Undying': 0.849 },
    { name: 'Mars', 'Undying': -1.143 },
    { name: 'Medusa', 'Undying': 4.329 },
    { name: 'Meepo', 'Undying': 1.345 },
    { name: 'Mirana', 'Undying': 0.121 },
    { name: 'Monkey King', 'Undying': 0.952 },
    { name: 'Morphling', 'Undying': 1.507 },
    { name: 'Muerta', 'Undying': 0.714 },
    { name: 'Naga Siren', 'Undying': 1.198 },
    { name: "Nature's Prophet", 'Undying': 0.502 },
    { name: 'Necrophos', 'Undying': 0.473 },
    { name: 'Night Stalker', 'Undying': -0.732 },
    { name: 'Nyx Assassin', 'Undying': 0.097 },
    { name: 'Ogre Magi', 'Undying': 0.233 },
    { name: 'Omniknight', 'Undying': -2.201 },
    { name: 'Oracle', 'Undying': -2.569 },
    { name: 'Outworld Destroyer', 'Undying': -0.793 },
    { name: 'Pangolier', 'Undying': 0.125 },
    { name: 'Phantom Assassin', 'Undying': 0.542 },
    { name: 'Phantom Lancer', 'Undying': 2.082 },
    { name: 'Phoenix', 'Undying': 0.934 },
    { name: 'Primal Beast', 'Undying': -1.544 },
    { name: 'Puck', 'Undying': -1.398 },
    { name: 'Pudge', 'Undying': -1.194 },
    { name: 'Pugna', 'Undying': 1.083 },
    { name: 'Queen of Pain', 'Undying': -0.473 },
    { name: 'Razor', 'Undying': -0.16 },
    { name: 'Riki', 'Undying': 1.496 },
    { name: 'Ringmaster', 'Undying': -0.507 },
    { name: 'Rubick', 'Undying': 0.95 },
    { name: 'Sand King', 'Undying': 0.793 },
    { name: 'Shadow Demon', 'Undying': -1.967 },
    { name: 'Shadow Fiend', 'Undying': 0.284 },
    { name: 'Shadow Shaman', 'Undying': -0.502 },
    { name: 'Silencer', 'Undying': 0.991 },
    { name: 'Skywrath Mage', 'Undying': 0.242 },
    { name: 'Slardar', 'Undying': 1.081 },
    { name: 'Slark', 'Undying': 0.755 },
    { name: 'Snapfire', 'Undying': 0.578 },
    { name: 'Sniper', 'Undying': 1.245 },
    { name: 'Spectre', 'Undying': -3.907 },
    { name: 'Spirit Breaker', 'Undying': -0.326 },
    { name: 'Storm Spirit', 'Undying': 0.166 },
    { name: 'Sven', 'Undying': -0.566 },
    { name: 'Techies', 'Undying': -0.087 },
    { name: 'Templar Assassin', 'Undying': -0.416 },
    { name: 'Terrorblade', 'Undying': 0.713 },
    { name: 'Tidehunter', 'Undying': -1.17 },
    { name: 'Timbersaw', 'Undying': 1.817 },
    { name: 'Tinker', 'Undying': 0.75 },
    { name: 'Tiny', 'Undying': -1.701 },
    { name: 'Treant Protector', 'Undying': -0.858 },
    { name: 'Troll Warlord', 'Undying': 0.752 },
    { name: 'Tusk', 'Undying': -0.973 },
    { name: 'Underlord', 'Undying': -0.088 },
    { name: 'Ursa', 'Undying': -2.248 },
    { name: 'Vengeful Spirit', 'Undying': -0.397 },
    { name: 'Venomancer', 'Undying': 1.805 },
    { name: 'Viper', 'Undying': -0.433 },
    { name: 'Visage', 'Undying': -1.021 },
    { name: 'Void Spirit', 'Undying': -0.104 },
    { name: 'Warlock', 'Undying': 0.275 },
    { name: 'Weaver', 'Undying': 1.287 },
    { name: 'Windranger', 'Undying': 0.17 },
    { name: 'Winter Wyvern', 'Undying': -1.757 },
    { name: 'Witch Doctor', 'Undying': 1.945 },
    { name: 'Wraith King', 'Undying': -2.831 },
    { name: 'Zeus', 'Undying': -0.732 },
],
"Wraith King": [
    { name: 'Abaddon', 'Wraith King': 2.788 },
    { name: 'Alchemist', 'Wraith King': 2.767 },
    { name: 'Ancient Apparition', 'Wraith King': 0.131 },
    { name: 'Anti-Mage', 'Wraith King': 2.22 },
    { name: 'Arc Warden', 'Wraith King': -0.332 },
    { name: 'Axe', 'Wraith King': -1.853 },
    { name: 'Bane', 'Wraith King': 0.35 },
    { name: 'Batrider', 'Wraith King': 0.609 },
    { name: 'Beastmaster', 'Wraith King': 2.95 },
    { name: 'Bloodseeker', 'Wraith King': -0.671 },
    { name: 'Bounty Hunter', 'Wraith King': 1.196 },
    { name: 'Brewmaster', 'Wraith King': 2.729 },
    { name: 'Bristleback', 'Wraith King': 1.189 },
    { name: 'Broodmother', 'Wraith King': 2.679 },
    { name: 'Centaur Warrunner', 'Wraith King': -0.556 },
    { name: 'Chaos Knight', 'Wraith King': 1.587 },
    { name: 'Chen', 'Wraith King': 3.539 },
    { name: 'Clinkz', 'Wraith King': -1.0 },
    { name: 'Clockwerk', 'Wraith King': 0.179 },
    { name: 'Crystal Maiden', 'Wraith King': 1.719 },
    { name: 'Dark Seer', 'Wraith King': 3.156 },
    { name: 'Dark Willow', 'Wraith King': -0.143 },
    { name: 'Dawnbreaker', 'Wraith King': 1.331 },
    { name: 'Dazzle', 'Wraith King': 2.526 },
    { name: 'Death Prophet', 'Wraith King': 0.94 },
    { name: 'Disruptor', 'Wraith King': -0.308 },
    { name: 'Doom', 'Wraith King': -0.454 },
    { name: 'Dragon Knight', 'Wraith King': 2.103 },
    { name: 'Drow Ranger', 'Wraith King': -0.647 },
    { name: 'Earth Spirit', 'Wraith King': -0.167 },
    { name: 'Earthshaker', 'Wraith King': -0.722 },
    { name: 'Elder Titan', 'Wraith King': -2.026 },
    { name: 'Ember Spirit', 'Wraith King': 0.844 },
    { name: 'Enchantress', 'Wraith King': 0.65 },
    { name: 'Enigma', 'Wraith King': -1.961 },
    { name: 'Faceless Void', 'Wraith King': -0.407 },
    { name: 'Grimstroke', 'Wraith King': -0.448 },
    { name: 'Gyrocopter', 'Wraith King': 0.608 },
    { name: 'Hoodwink', 'Wraith King': 0.024 },
    { name: 'Huskar', 'Wraith King': -1.009 },
    { name: 'Invoker', 'Wraith King': 1.564 },
    { name: 'Io', 'Wraith King': 1.893 },
    { name: 'Jakiro', 'Wraith King': 2.154 },
    { name: 'Juggernaut', 'Wraith King': -0.087 },
    { name: 'Keeper of the Light', 'Wraith King': 1.838 },
    { name: 'Kunkka', 'Wraith King': 0.539 },
    { name: 'Legion Commander', 'Wraith King': -1.89 },
    { name: 'Leshrac', 'Wraith King': 1.497 },
    { name: 'Lich', 'Wraith King': 1.118 },
    { name: 'Lifestealer', 'Wraith King': 2.746 },
    { name: 'Lina', 'Wraith King': 0.057 },
    { name: 'Lion', 'Wraith King': -0.463 },
    { name: 'Lone Druid', 'Wraith King': 4.946 },
    { name: 'Luna', 'Wraith King': 0.104 },
    { name: 'Lycan', 'Wraith King': 1.89 },
    { name: 'Magnus', 'Wraith King': -0.447 },
    { name: 'Marci', 'Wraith King': 1.067 },
    { name: 'Mars', 'Wraith King': 0.298 },
    { name: 'Medusa', 'Wraith King': 2.181 },
    { name: 'Meepo', 'Wraith King': 2.574 },
    { name: 'Mirana', 'Wraith King': -0.513 },
    { name: 'Monkey King', 'Wraith King': 2.163 },
    { name: 'Morphling', 'Wraith King': 1.443 },
    { name: 'Muerta', 'Wraith King': -1.036 },
    { name: 'Naga Siren', 'Wraith King': 3.146 },
    { name: "Nature's Prophet", 'Wraith King': -0.232 },
    { name: 'Necrophos', 'Wraith King': 3.113 },
    { name: 'Night Stalker', 'Wraith King': -1.705 },
    { name: 'Nyx Assassin', 'Wraith King': -0.998 },
    { name: 'Ogre Magi', 'Wraith King': 1.093 },
    { name: 'Omniknight', 'Wraith King': 1.755 },
    { name: 'Oracle', 'Wraith King': 0.893 },
    { name: 'Outworld Destroyer', 'Wraith King': -1.006 },
    { name: 'Pangolier', 'Wraith King': 2.904 },
    { name: 'Phantom Assassin', 'Wraith King': -0.72 },
    { name: 'Phantom Lancer', 'Wraith King': 5.298 },
    { name: 'Phoenix', 'Wraith King': 3.353 },
    { name: 'Primal Beast', 'Wraith King': 0.177 },
    { name: 'Puck', 'Wraith King': -1.189 },
    { name: 'Pudge', 'Wraith King': -1.46 },
    { name: 'Pugna', 'Wraith King': 0.041 },
    { name: 'Queen of Pain', 'Wraith King': 0.097 },
    { name: 'Razor', 'Wraith King': 2.144 },
    { name: 'Riki', 'Wraith King': -1.385 },
    { name: 'Ringmaster', 'Wraith King': -0.75 },
    { name: 'Rubick', 'Wraith King': 1.957 },
    { name: 'Sand King', 'Wraith King': 2.019 },
    { name: 'Shadow Demon', 'Wraith King': 1.421 },
    { name: 'Shadow Fiend', 'Wraith King': -0.87 },
    { name: 'Shadow Shaman', 'Wraith King': -0.779 },
    { name: 'Silencer', 'Wraith King': -2.049 },
    { name: 'Skywrath Mage', 'Wraith King': -2.106 },
    { name: 'Slardar', 'Wraith King': 1.18 },
    { name: 'Slark', 'Wraith King': 3.257 },
    { name: 'Snapfire', 'Wraith King': 0.67 },
    { name: 'Sniper', 'Wraith King': 0.584 },
    { name: 'Spectre', 'Wraith King': -0.23 },
    { name: 'Spirit Breaker', 'Wraith King': -0.856 },
    { name: 'Storm Spirit', 'Wraith King': -0.024 },
    { name: 'Sven', 'Wraith King': -0.077 },
    { name: 'Techies', 'Wraith King': -0.82 },
    { name: 'Templar Assassin', 'Wraith King': 0.324 },
    { name: 'Terrorblade', 'Wraith King': 2.862 },
    { name: 'Tidehunter', 'Wraith King': 4.052 },
    { name: 'Timbersaw', 'Wraith King': 1.549 },
    { name: 'Tinker', 'Wraith King': 2.878 },
    { name: 'Tiny', 'Wraith King': -0.049 },
    { name: 'Treant Protector', 'Wraith King': 1.891 },
    { name: 'Troll Warlord', 'Wraith King': 1.042 },
    { name: 'Tusk', 'Wraith King': 1.89 },
    { name: 'Underlord', 'Wraith King': 4.867 },
    { name: 'Undying', 'Wraith King': 2.914 },
    { name: 'Ursa', 'Wraith King': -1.695 },
    { name: 'Vengeful Spirit', 'Wraith King': 0.519 },
    { name: 'Venomancer', 'Wraith King': 2.762 },
    { name: 'Viper', 'Wraith King': 0.477 },
    { name: 'Visage', 'Wraith King': 2.799 },
    { name: 'Void Spirit', 'Wraith King': -0.17 },
    { name: 'Warlock', 'Wraith King': 2.548 },
    { name: 'Weaver', 'Wraith King': 0.377 },
    { name: 'Windranger', 'Wraith King': 0.055 },
    { name: 'Winter Wyvern', 'Wraith King': 1.703 },
    { name: 'Witch Doctor', 'Wraith King': -1.252 },
    { name: 'Zeus', 'Wraith King': -0.067 },
],
"Anti-Mage": [
    { name: 'Abaddon', 'Anti-Mage': -3.082 },
    { name: 'Alchemist', 'Anti-Mage': -0.523 },
    { name: 'Ancient Apparition', 'Anti-Mage': -3.072 },
    { name: 'Arc Warden', 'Anti-Mage': -0.411 },
    { name: 'Axe', 'Anti-Mage': 5.082 },
    { name: 'Bane', 'Anti-Mage': 0.442 },
    { name: 'Batrider', 'Anti-Mage': 0.473 },
    { name: 'Beastmaster', 'Anti-Mage': 2.541 },
    { name: 'Bloodseeker', 'Anti-Mage': 1.4 },
    { name: 'Bounty Hunter', 'Anti-Mage': -0.779 },
    { name: 'Brewmaster', 'Anti-Mage': -0.405 },
    { name: 'Bristleback', 'Anti-Mage': -0.485 },
    { name: 'Broodmother', 'Anti-Mage': 1.758 },
    { name: 'Centaur Warrunner', 'Anti-Mage': -0.065 },
    { name: 'Chaos Knight', 'Anti-Mage': -1.461 },
    { name: 'Chen', 'Anti-Mage': -0.236 },
    { name: 'Clinkz', 'Anti-Mage': -0.27 },
    { name: 'Clockwerk', 'Anti-Mage': -2.519 },
    { name: 'Crystal Maiden', 'Anti-Mage': 0.934 },
    { name: 'Dark Seer', 'Anti-Mage': -1.41 },
    { name: 'Dark Willow', 'Anti-Mage': -0.726 },
    { name: 'Dawnbreaker', 'Anti-Mage': -0.428 },
    { name: 'Dazzle', 'Anti-Mage': 0.538 },
    { name: 'Death Prophet', 'Anti-Mage': -1.583 },
    { name: 'Disruptor', 'Anti-Mage': 1.429 },
    { name: 'Doom', 'Anti-Mage': -1.04 },
    { name: 'Dragon Knight', 'Anti-Mage': -0.332 },
    { name: 'Drow Ranger', 'Anti-Mage': 1.345 },
    { name: 'Earth Spirit', 'Anti-Mage': -1.062 },
    { name: 'Earthshaker', 'Anti-Mage': 1.919 },
    { name: 'Elder Titan', 'Anti-Mage': 0.465 },
    { name: 'Ember Spirit', 'Anti-Mage': -0.125 },
    { name: 'Enchantress', 'Anti-Mage': 2.109 },
    { name: 'Enigma', 'Anti-Mage': 0.821 },
    { name: 'Faceless Void', 'Anti-Mage': -0.993 },
    { name: 'Grimstroke', 'Anti-Mage': -0.572 },
    { name: 'Gyrocopter', 'Anti-Mage': -0.794 },
    { name: 'Hoodwink', 'Anti-Mage': 2.712 },
    { name: 'Huskar', 'Anti-Mage': 5.584 },
    { name: 'Invoker', 'Anti-Mage': -0.452 },
    { name: 'Io', 'Anti-Mage': 1.763 },
    { name: 'Jakiro', 'Anti-Mage': 1.519 },
    { name: 'Juggernaut', 'Anti-Mage': -2.633 },
    { name: 'Keeper of the Light', 'Anti-Mage': -0.618 },
    { name: 'Kunkka', 'Anti-Mage': -0.22 },
    { name: 'Legion Commander', 'Anti-Mage': 2.387 },
    { name: 'Leshrac', 'Anti-Mage': -0.27 },
    { name: 'Lich', 'Anti-Mage': -1.93 },
    { name: 'Lifestealer', 'Anti-Mage': -1.367 },
    { name: 'Lina', 'Anti-Mage': 0.424 },
    { name: 'Lion', 'Anti-Mage': 0.906 },
    { name: 'Lone Druid', 'Anti-Mage': 5.42 },
    { name: 'Luna', 'Anti-Mage': 0.875 },
    { name: 'Lycan', 'Anti-Mage': 1.398 },
    { name: 'Magnus', 'Anti-Mage': 1.273 },
    { name: 'Marci', 'Anti-Mage': 2.788 },
    { name: 'Mars', 'Anti-Mage': -2.96 },
    { name: 'Medusa', 'Anti-Mage': -9.452 },
    { name: 'Meepo', 'Anti-Mage': 6.937 },
    { name: 'Mirana', 'Anti-Mage': 1.104 },
    { name: 'Monkey King', 'Anti-Mage': 2.397 },
    { name: 'Morphling', 'Anti-Mage': -1.807 },
    { name: 'Muerta', 'Anti-Mage': -1.175 },
    { name: 'Naga Siren', 'Anti-Mage': -0.161 },
    { name: "Nature's Prophet", 'Anti-Mage': -1.222 },
    { name: 'Necrophos', 'Anti-Mage': -1.913 },
    { name: 'Night Stalker', 'Anti-Mage': 1.118 },
    { name: 'Nyx Assassin', 'Anti-Mage': 1.472 },
    { name: 'Ogre Magi', 'Anti-Mage': -2.618 },
    { name: 'Omniknight', 'Anti-Mage': 0.753 },
    { name: 'Oracle', 'Anti-Mage': -2.797 },
    { name: 'Outworld Destroyer', 'Anti-Mage': -1.403 },
    { name: 'Pangolier', 'Anti-Mage': 1.553 },
    { name: 'Phantom Assassin', 'Anti-Mage': 5.065 },
    { name: 'Phantom Lancer', 'Anti-Mage': -3.39 },
    { name: 'Phoenix', 'Anti-Mage': 0.739 },
    { name: 'Primal Beast', 'Anti-Mage': -1.762 },
    { name: 'Puck', 'Anti-Mage': 1.221 },
    { name: 'Pudge', 'Anti-Mage': -1.293 },
    { name: 'Pugna', 'Anti-Mage': -1.986 },
    { name: 'Queen of Pain', 'Anti-Mage': -1.888 },
    { name: 'Razor', 'Anti-Mage': -3.308 },
    { name: 'Riki', 'Anti-Mage': 1.593 },
    { name: 'Ringmaster', 'Anti-Mage': -1.227 },
    { name: 'Rubick', 'Anti-Mage': -0.469 },
    { name: 'Sand King', 'Anti-Mage': -1.457 },
    { name: 'Shadow Demon', 'Anti-Mage': -1.316 },
    { name: 'Shadow Fiend', 'Anti-Mage': 2.205 },
    { name: 'Shadow Shaman', 'Anti-Mage': 1.128 },
    { name: 'Silencer', 'Anti-Mage': -0.799 },
    { name: 'Skywrath Mage', 'Anti-Mage': -1.587 },
    { name: 'Slardar', 'Anti-Mage': 3.9 },
    { name: 'Slark', 'Anti-Mage': 0.591 },
    { name: 'Snapfire', 'Anti-Mage': 0.649 },
    { name: 'Sniper', 'Anti-Mage': 0.005 },
    { name: 'Spectre', 'Anti-Mage': -0.689 },
    { name: 'Spirit Breaker', 'Anti-Mage': 0.916 },
    { name: 'Storm Spirit', 'Anti-Mage': -3.998 },
    { name: 'Sven', 'Anti-Mage': 1.093 },
    { name: 'Techies', 'Anti-Mage': -0.238 },
    { name: 'Templar Assassin', 'Anti-Mage': 2.025 },
    { name: 'Terrorblade', 'Anti-Mage': 2.782 },
    { name: 'Tidehunter', 'Anti-Mage': -1.566 },
    { name: 'Timbersaw', 'Anti-Mage': 1.969 },
    { name: 'Tinker', 'Anti-Mage': -2.733 },
    { name: 'Tiny', 'Anti-Mage': 0.734 },
    { name: 'Treant Protector', 'Anti-Mage': -0.266 },
    { name: 'Troll Warlord', 'Anti-Mage': 4.007 },
    { name: 'Tusk', 'Anti-Mage': 0.418 },
    { name: 'Underlord', 'Anti-Mage': -1.501 },
    { name: 'Undying', 'Anti-Mage': -1.443 },
    { name: 'Ursa', 'Anti-Mage': 1.705 },
    { name: 'Vengeful Spirit', 'Anti-Mage': -0.684 },
    { name: 'Venomancer', 'Anti-Mage': -0.412 },
    { name: 'Viper', 'Anti-Mage': 0.072 },
    { name: 'Visage', 'Anti-Mage': -0.133 },
    { name: 'Void Spirit', 'Anti-Mage': -1.632 },
    { name: 'Warlock', 'Anti-Mage': -1.28 },
    { name: 'Weaver', 'Anti-Mage': -0.75 },
    { name: 'Windranger', 'Anti-Mage': -0.089 },
    { name: 'Winter Wyvern', 'Anti-Mage': -2.688 },
    { name: 'Witch Doctor', 'Anti-Mage': 0.683 },
    { name: 'Wraith King', 'Anti-Mage': -2.104 },
    { name: 'Zeus', 'Anti-Mage': -2.624 },
],
"Arc Warden": [
    { name: 'Abaddon', 'Arc Warden': 1.937 },
    { name: 'Alchemist', 'Arc Warden': -0.52 },
    { name: 'Ancient Apparition', 'Arc Warden': -2.26 },
    { name: 'Anti-Mage', 'Arc Warden': 0.068 },
    { name: 'Axe', 'Arc Warden': 2.448 },
    { name: 'Bane', 'Arc Warden': -1.397 },
    { name: 'Batrider', 'Arc Warden': 1.422 },
    { name: 'Beastmaster', 'Arc Warden': 1.735 },
    { name: 'Bloodseeker', 'Arc Warden': -0.896 },
    { name: 'Bounty Hunter', 'Arc Warden': -1.436 },
    { name: 'Brewmaster', 'Arc Warden': -0.112 },
    { name: 'Bristleback', 'Arc Warden': 2.411 },
    { name: 'Broodmother', 'Arc Warden': 4.837 },
    { name: 'Centaur Warrunner', 'Arc Warden': 1.127 },
    { name: 'Chaos Knight', 'Arc Warden': 1.771 },
    { name: 'Chen', 'Arc Warden': -0.758 },
    { name: 'Clinkz', 'Arc Warden': -2.805 },
    { name: 'Clockwerk', 'Arc Warden': -0.094 },
    { name: 'Crystal Maiden', 'Arc Warden': 0.405 },
    { name: 'Dark Seer', 'Arc Warden': 0.386 },
    { name: 'Dark Willow', 'Arc Warden': -1.228 },
    { name: 'Dawnbreaker', 'Arc Warden': 0.518 },
    { name: 'Dazzle', 'Arc Warden': 1.677 },
    { name: 'Death Prophet', 'Arc Warden': -0.488 },
    { name: 'Disruptor', 'Arc Warden': -1.892 },
    { name: 'Doom', 'Arc Warden': -4.816 },
    { name: 'Dragon Knight', 'Arc Warden': -1.737 },
    { name: 'Drow Ranger', 'Arc Warden': -0.731 },
    { name: 'Earth Spirit', 'Arc Warden': 1.147 },
    { name: 'Earthshaker', 'Arc Warden': 1.229 },
    { name: 'Elder Titan', 'Arc Warden': 2.119 },
    { name: 'Ember Spirit', 'Arc Warden': -0.575 },
    { name: 'Enchantress', 'Arc Warden': 0.274 },
    { name: 'Enigma', 'Arc Warden': 1.107 },
    { name: 'Faceless Void', 'Arc Warden': -0.608 },
    { name: 'Grimstroke', 'Arc Warden': -1.81 },
    { name: 'Gyrocopter', 'Arc Warden': 0.536 },
    { name: 'Hoodwink', 'Arc Warden': -1.138 },
    { name: 'Huskar', 'Arc Warden': -0.646 },
    { name: 'Invoker', 'Arc Warden': -0.203 },
    { name: 'Io', 'Arc Warden': 2.513 },
    { name: 'Jakiro', 'Arc Warden': 1.302 },
    { name: 'Juggernaut', 'Arc Warden': 0.478 },
    { name: 'Keeper of the Light', 'Arc Warden': -0.431 },
    { name: 'Kunkka', 'Arc Warden': 2.598 },
    { name: 'Legion Commander', 'Arc Warden': 2.218 },
    { name: 'Leshrac', 'Arc Warden': 0.514 },
    { name: 'Lich', 'Arc Warden': 1.611 },
    { name: 'Lifestealer', 'Arc Warden': -1.091 },
    { name: 'Lina', 'Arc Warden': 0.476 },
    { name: 'Lion', 'Arc Warden': -1.549 },
    { name: 'Lone Druid', 'Arc Warden': 2.26 },
    { name: 'Luna', 'Arc Warden': 1.28 },
    { name: 'Lycan', 'Arc Warden': 2.839 },
    { name: 'Magnus', 'Arc Warden': 0.595 },
    { name: 'Marci', 'Arc Warden': 2.433 },
    { name: 'Mars', 'Arc Warden': 1.344 },
    { name: 'Medusa', 'Arc Warden': 1.259 },
    { name: 'Meepo', 'Arc Warden': 5.152 },
    { name: 'Mirana', 'Arc Warden': -0.765 },
    { name: 'Monkey King', 'Arc Warden': 0.124 },
    { name: 'Morphling', 'Arc Warden': 1.659 },
    { name: 'Muerta', 'Arc Warden': -0.64 },
    { name: 'Naga Siren', 'Arc Warden': 1.55 },
    { name: "Nature's Prophet", 'Arc Warden': -2.033 },
    { name: 'Necrophos', 'Arc Warden': -2.87 },
    { name: 'Night Stalker', 'Arc Warden': -3.864 },
    { name: 'Nyx Assassin', 'Arc Warden': -0.944 },
    { name: 'Ogre Magi', 'Arc Warden': -1.265 },
    { name: 'Omniknight', 'Arc Warden': 1.553 },
    { name: 'Oracle', 'Arc Warden': 0.722 },
    { name: 'Outworld Destroyer', 'Arc Warden': -3.028 },
    { name: 'Pangolier', 'Arc Warden': 2.816 },
    { name: 'Phantom Assassin', 'Arc Warden': 1.086 },
    { name: 'Phantom Lancer', 'Arc Warden': 4.675 },
    { name: 'Phoenix', 'Arc Warden': -0.895 },
    { name: 'Primal Beast', 'Arc Warden': 2.02 },
    { name: 'Puck', 'Arc Warden': -1.457 },
    { name: 'Pudge', 'Arc Warden': 0.132 },
    { name: 'Pugna', 'Arc Warden': 0.377 },
    { name: 'Queen of Pain', 'Arc Warden': -1.189 },
    { name: 'Razor', 'Arc Warden': -0.722 },
    { name: 'Riki', 'Arc Warden': -0.686 },
    { name: 'Ringmaster', 'Arc Warden': 0.077 },
    { name: 'Rubick', 'Arc Warden': 0.643 },
    { name: 'Sand King', 'Arc Warden': 2.965 },
    { name: 'Shadow Demon', 'Arc Warden': 0.916 },
    { name: 'Shadow Fiend', 'Arc Warden': 0.256 },
    { name: 'Shadow Shaman', 'Arc Warden': -0.563 },
    { name: 'Silencer', 'Arc Warden': -2.465 },
    { name: 'Skywrath Mage', 'Arc Warden': -0.754 },
    { name: 'Slardar', 'Arc Warden': -1.81 },
    { name: 'Slark', 'Arc Warden': 0.029 },
    { name: 'Snapfire', 'Arc Warden': 1.78 },
    { name: 'Sniper', 'Arc Warden': -0.932 },
    { name: 'Spectre', 'Arc Warden': 1.335 },
    { name: 'Spirit Breaker', 'Arc Warden': -0.483 },
    { name: 'Storm Spirit', 'Arc Warden': -1.288 },
    { name: 'Sven', 'Arc Warden': 0.939 },
    { name: 'Techies', 'Arc Warden': 1.155 },
    { name: 'Templar Assassin', 'Arc Warden': -2.778 },
    { name: 'Terrorblade', 'Arc Warden': 1.103 },
    { name: 'Tidehunter', 'Arc Warden': 0.621 },
    { name: 'Timbersaw', 'Arc Warden': -0.125 },
    { name: 'Tinker', 'Arc Warden': -1.183 },
    { name: 'Tiny', 'Arc Warden': 1.057 },
    { name: 'Treant Protector', 'Arc Warden': 0.292 },
    { name: 'Troll Warlord', 'Arc Warden': 1.123 },
    { name: 'Tusk', 'Arc Warden': 1.191 },
    { name: 'Underlord', 'Arc Warden': 1.117 },
    { name: 'Undying', 'Arc Warden': 0.749 },
    { name: 'Ursa', 'Arc Warden': -0.477 },
    { name: 'Vengeful Spirit', 'Arc Warden': -0.152 },
    { name: 'Venomancer', 'Arc Warden': 0.02 },
    { name: 'Viper', 'Arc Warden': -2.198 },
    { name: 'Visage', 'Arc Warden': 1.071 },
    { name: 'Void Spirit', 'Arc Warden': 0.92 },
    { name: 'Warlock', 'Arc Warden': 3.641 },
    { name: 'Weaver', 'Arc Warden': -1.68 },
    { name: 'Windranger', 'Arc Warden': -1.849 },
    { name: 'Winter Wyvern', 'Arc Warden': -0.307 },
    { name: 'Witch Doctor', 'Arc Warden': 0.414 },
    { name: 'Wraith King', 'Arc Warden': 0.697 },
    { name: 'Zeus', 'Arc Warden': -0.393 },
],
"Bloodseeker": [
    { name: 'Abaddon', 'Bloodseeker': 0.011 },
    { name: 'Alchemist', 'Bloodseeker': -0.591 },
    { name: 'Ancient Apparition', 'Bloodseeker': 1.347 },
    { name: 'Anti-Mage', 'Bloodseeker': -1.78 },
    { name: 'Arc Warden', 'Bloodseeker': 0.851 },
    { name: 'Axe', 'Bloodseeker': -1.113 },
    { name: 'Bane', 'Bloodseeker': 0.156 },
    { name: 'Batrider', 'Bloodseeker': 1.337 },
    { name: 'Beastmaster', 'Bloodseeker': 2.074 },
    { name: 'Bounty Hunter', 'Bloodseeker': -0.312 },
    { name: 'Brewmaster', 'Bloodseeker': 1.99 },
    { name: 'Bristleback', 'Bloodseeker': 1.686 },
    { name: 'Broodmother', 'Bloodseeker': 1.409 },
    { name: 'Centaur Warrunner', 'Bloodseeker': -1.292 },
    { name: 'Chaos Knight', 'Bloodseeker': 1.106 },
    { name: 'Chen', 'Bloodseeker': 0.372 },
    { name: 'Clinkz', 'Bloodseeker': 0.255 },
    { name: 'Clockwerk', 'Bloodseeker': 0.345 },
    { name: 'Crystal Maiden', 'Bloodseeker': 0.627 },
    { name: 'Dark Seer', 'Bloodseeker': -1.871 },
    { name: 'Dark Willow', 'Bloodseeker': 0.23 },
    { name: 'Dawnbreaker', 'Bloodseeker': -0.84 },
    { name: 'Dazzle', 'Bloodseeker': 0.483 },
    { name: 'Death Prophet', 'Bloodseeker': -0.654 },
    { name: 'Disruptor', 'Bloodseeker': -1.435 },
    { name: 'Doom', 'Bloodseeker': 1.292 },
    { name: 'Dragon Knight', 'Bloodseeker': -0.142 },
    { name: 'Drow Ranger', 'Bloodseeker': -1.023 },
    { name: 'Earth Spirit', 'Bloodseeker': 0.623 },
    { name: 'Earthshaker', 'Bloodseeker': -1.001 },
    { name: 'Elder Titan', 'Bloodseeker': 0.581 },
    { name: 'Ember Spirit', 'Bloodseeker': 2.075 },
    { name: 'Enchantress', 'Bloodseeker': 1.403 },
    { name: 'Enigma', 'Bloodseeker': 0.286 },
    { name: 'Faceless Void', 'Bloodseeker': 1.019 },
    { name: 'Grimstroke', 'Bloodseeker': 0.315 },
    { name: 'Gyrocopter', 'Bloodseeker': 0.077 },
    { name: 'Hoodwink', 'Bloodseeker': -0.027 },
    { name: 'Huskar', 'Bloodseeker': -0.793 },
    { name: 'Invoker', 'Bloodseeker': -0.287 },
    { name: 'Io', 'Bloodseeker': 1.807 },
    { name: 'Jakiro', 'Bloodseeker': -0.217 },
    { name: 'Juggernaut', 'Bloodseeker': 3.061 },
    { name: 'Keeper of the Light', 'Bloodseeker': 2.533 },
    { name: 'Kunkka', 'Bloodseeker': -0.946 },
    { name: 'Legion Commander', 'Bloodseeker': 1.899 },
    { name: 'Leshrac', 'Bloodseeker': 0.205 },
    { name: 'Lich', 'Bloodseeker': 0.056 },
    { name: 'Lifestealer', 'Bloodseeker': 1.291 },
    { name: 'Lina', 'Bloodseeker': 1.561 },
    { name: 'Lion', 'Bloodseeker': 0.325 },
    { name: 'Lone Druid', 'Bloodseeker': -1.844 },
    { name: 'Luna', 'Bloodseeker': 0.395 },
    { name: 'Lycan', 'Bloodseeker': -0.914 },
    { name: 'Magnus', 'Bloodseeker': -0.996 },
    { name: 'Marci', 'Bloodseeker': -0.291 },
    { name: 'Mars', 'Bloodseeker': -1.322 },
    { name: 'Medusa', 'Bloodseeker': 5.634 },
    { name: 'Meepo', 'Bloodseeker': -0.393 },
    { name: 'Mirana', 'Bloodseeker': -0.774 },
    { name: 'Monkey King', 'Bloodseeker': 1.231 },
    { name: 'Morphling', 'Bloodseeker': 1.395 },
    { name: 'Muerta', 'Bloodseeker': 0.053 },
    { name: 'Naga Siren', 'Bloodseeker': 2.241 },
    { name: "Nature's Prophet", 'Bloodseeker': 2.37 },
    { name: 'Necrophos', 'Bloodseeker': 0.312 },
    { name: 'Night Stalker', 'Bloodseeker': -2.789 },
    { name: 'Nyx Assassin', 'Bloodseeker': 0.299 },
    { name: 'Ogre Magi', 'Bloodseeker': 0.38 },
    { name: 'Omniknight', 'Bloodseeker': -0.488 },
    { name: 'Oracle', 'Bloodseeker': 1.462 },
    { name: 'Outworld Destroyer', 'Bloodseeker': 0.001 },
    { name: 'Pangolier', 'Bloodseeker': -3.345 },
    { name: 'Phantom Assassin', 'Bloodseeker': -0.981 },
    { name: 'Phantom Lancer', 'Bloodseeker': -1.332 },
    { name: 'Phoenix', 'Bloodseeker': -2.321 },
    { name: 'Primal Beast', 'Bloodseeker': -0.455 },
    { name: 'Puck', 'Bloodseeker': 0.469 },
    { name: 'Pudge', 'Bloodseeker': 0.654 },
    { name: 'Pugna', 'Bloodseeker': 1.189 },
    { name: 'Queen of Pain', 'Bloodseeker': 0.289 },
    { name: 'Razor', 'Bloodseeker': -2.227 },
    { name: 'Riki', 'Bloodseeker': -2.328 },
    { name: 'Ringmaster', 'Bloodseeker': -1.374 },
    { name: 'Rubick', 'Bloodseeker': -0.169 },
    { name: 'Sand King', 'Bloodseeker': -1.703 },
    { name: 'Shadow Demon', 'Bloodseeker': 0.45 },
    { name: 'Shadow Fiend', 'Bloodseeker': 0.101 },
    { name: 'Shadow Shaman', 'Bloodseeker': 0.018 },
    { name: 'Silencer', 'Bloodseeker': -0.558 },
    { name: 'Skywrath Mage', 'Bloodseeker': 1.625 },
    { name: 'Slardar', 'Bloodseeker': 0.088 },
    { name: 'Slark', 'Bloodseeker': -3.383 },
    { name: 'Snapfire', 'Bloodseeker': -0.803 },
    { name: 'Sniper', 'Bloodseeker': 0.141 },
    { name: 'Spectre', 'Bloodseeker': -0.498 },
    { name: 'Spirit Breaker', 'Bloodseeker': 0.41 },
    { name: 'Storm Spirit', 'Bloodseeker': 2.903 },
    { name: 'Sven', 'Bloodseeker': -1.009 },
    { name: 'Techies', 'Bloodseeker': 0.069 },
    { name: 'Templar Assassin', 'Bloodseeker': 0.431 },
    { name: 'Terrorblade', 'Bloodseeker': 0.862 },
    { name: 'Tidehunter', 'Bloodseeker': -1.748 },
    { name: 'Timbersaw', 'Bloodseeker': -2.139 },
    { name: 'Tinker', 'Bloodseeker': 1.488 },
    { name: 'Tiny', 'Bloodseeker': -0.974 },
    { name: 'Treant Protector', 'Bloodseeker': 0.614 },
    { name: 'Troll Warlord', 'Bloodseeker': 1.572 },
    { name: 'Tusk', 'Bloodseeker': 0.825 },
    { name: 'Underlord', 'Bloodseeker': -0.024 },
    { name: 'Undying', 'Bloodseeker': -0.448 },
    { name: 'Ursa', 'Bloodseeker': 0.214 },
    { name: 'Vengeful Spirit', 'Bloodseeker': -0.064 },
    { name: 'Venomancer', 'Bloodseeker': 0.227 },
    { name: 'Viper', 'Bloodseeker': 2.198 },
    { name: 'Visage', 'Bloodseeker': -0.608 },
    { name: 'Void Spirit', 'Bloodseeker': -1.165 },
    { name: 'Warlock', 'Bloodseeker': -0.203 },
    { name: 'Weaver', 'Bloodseeker': -1.046 },
    { name: 'Windranger', 'Bloodseeker': -2.477 },
    { name: 'Winter Wyvern', 'Bloodseeker': 0.168 },
    { name: 'Witch Doctor', 'Bloodseeker': 2.464 },
    { name: 'Wraith King', 'Bloodseeker': 1.156 },
    { name: 'Zeus', 'Bloodseeker': -0.439 },
],
"Bounty Hunter": [
    { name: 'Abaddon', 'Bounty Hunter': 1.862 },
    { name: 'Alchemist', 'Bounty Hunter': -1.247 },
    { name: 'Ancient Apparition', 'Bounty Hunter': -0.553 },
    { name: 'Anti-Mage', 'Bounty Hunter': 0.752 },
    { name: 'Arc Warden', 'Bounty Hunter': 1.291 },
    { name: 'Axe', 'Bounty Hunter': -0.185 },
    { name: 'Bane', 'Bounty Hunter': 0.512 },
    { name: 'Batrider', 'Bounty Hunter': 0.892 },
    { name: 'Beastmaster', 'Bounty Hunter': 0.983 },
    { name: 'Bloodseeker', 'Bounty Hunter': 0.277 },
    { name: 'Brewmaster', 'Bounty Hunter': 0.329 },
    { name: 'Bristleback', 'Bounty Hunter': -0.991 },
    { name: 'Broodmother', 'Bounty Hunter': -1.251 },
    { name: 'Centaur Warrunner', 'Bounty Hunter': -1.236 },
    { name: 'Chaos Knight', 'Bounty Hunter': 2.294 },
    { name: 'Chen', 'Bounty Hunter': -0.72 },
    { name: 'Clinkz', 'Bounty Hunter': -2.381 },
    { name: 'Clockwerk', 'Bounty Hunter': -0.088 },
    { name: 'Crystal Maiden', 'Bounty Hunter': 1.444 },
    { name: 'Dark Seer', 'Bounty Hunter': 0.104 },
    { name: 'Dark Willow', 'Bounty Hunter': 1.04 },
    { name: 'Dawnbreaker', 'Bounty Hunter': -0.509 },
    { name: 'Dazzle', 'Bounty Hunter': -0.365 },
    { name: 'Death Prophet', 'Bounty Hunter': -1.787 },
    { name: 'Disruptor', 'Bounty Hunter': -0.542 },
    { name: 'Doom', 'Bounty Hunter': -0.477 },
    { name: 'Dragon Knight', 'Bounty Hunter': -0.701 },
    { name: 'Drow Ranger', 'Bounty Hunter': -0.319 },
    { name: 'Earth Spirit', 'Bounty Hunter': -0.843 },
    { name: 'Earthshaker', 'Bounty Hunter': 0.695 },
    { name: 'Elder Titan', 'Bounty Hunter': -0.195 },
    { name: 'Ember Spirit', 'Bounty Hunter': -0.522 },
    { name: 'Enchantress', 'Bounty Hunter': -0.258 },
    { name: 'Enigma', 'Bounty Hunter': 1.651 },
    { name: 'Faceless Void', 'Bounty Hunter': 0.474 },
    { name: 'Grimstroke', 'Bounty Hunter': 0.547 },
    { name: 'Gyrocopter', 'Bounty Hunter': -0.657 },
    { name: 'Hoodwink', 'Bounty Hunter': -0.487 },
    { name: 'Huskar', 'Bounty Hunter': 2.202 },
    { name: 'Invoker', 'Bounty Hunter': -1.427 },
    { name: 'Io', 'Bounty Hunter': 0.048 },
    { name: 'Jakiro', 'Bounty Hunter': 0.185 },
    { name: 'Juggernaut', 'Bounty Hunter': 0.499 },
    { name: 'Keeper of the Light', 'Bounty Hunter': 0.068 },
    { name: 'Kunkka', 'Bounty Hunter': -1.092 },
    { name: 'Legion Commander', 'Bounty Hunter': 1.782 },
    { name: 'Leshrac', 'Bounty Hunter': 0.149 },
    { name: 'Lich', 'Bounty Hunter': -0.504 },
    { name: 'Lifestealer', 'Bounty Hunter': -0.407 },
    { name: 'Lina', 'Bounty Hunter': -0.285 },
    { name: 'Lion', 'Bounty Hunter': 1.263 },
    { name: 'Lone Druid', 'Bounty Hunter': 0.102 },
    { name: 'Luna', 'Bounty Hunter': -0.079 },
    { name: 'Lycan', 'Bounty Hunter': -1.399 },
    { name: 'Magnus', 'Bounty Hunter': 1.472 },
    { name: 'Marci', 'Bounty Hunter': 0.02 },
    { name: 'Mars', 'Bounty Hunter': -0.532 },
    { name: 'Medusa', 'Bounty Hunter': -0.461 },
    { name: 'Meepo', 'Bounty Hunter': 3.105 },
    { name: 'Mirana', 'Bounty Hunter': -0.888 },
    { name: 'Monkey King', 'Bounty Hunter': -0.207 },
    { name: 'Morphling', 'Bounty Hunter': 0.648 },
    { name: 'Muerta', 'Bounty Hunter': -0.648 },
    { name: 'Naga Siren', 'Bounty Hunter': 2.068 },
    { name: "Nature's Prophet", 'Bounty Hunter': -0.085 },
    { name: 'Necrophos', 'Bounty Hunter': 0.246 },
    { name: 'Night Stalker', 'Bounty Hunter': -2.049 },
    { name: 'Nyx Assassin', 'Bounty Hunter': 0.168 },
    { name: 'Ogre Magi', 'Bounty Hunter': 0.287 },
    { name: 'Omniknight', 'Bounty Hunter': -1.259 },
    { name: 'Oracle', 'Bounty Hunter': 1.143 },
    { name: 'Outworld Destroyer', 'Bounty Hunter': -0.151 },
    { name: 'Pangolier', 'Bounty Hunter': -1.271 },
    { name: 'Phantom Assassin', 'Bounty Hunter': -1.285 },
    { name: 'Phantom Lancer', 'Bounty Hunter': 2.109 },
    { name: 'Phoenix', 'Bounty Hunter': 0.963 },
    { name: 'Primal Beast', 'Bounty Hunter': 1.164 },
    { name: 'Puck', 'Bounty Hunter': -0.085 },
    { name: 'Pudge', 'Bounty Hunter': 0.369 },
    { name: 'Pugna', 'Bounty Hunter': -0.816 },
    { name: 'Queen of Pain', 'Bounty Hunter': 0.256 },
    { name: 'Razor', 'Bounty Hunter': 0.239 },
    { name: 'Riki', 'Bounty Hunter': -1.686 },
    { name: 'Ringmaster', 'Bounty Hunter': -0.369 },
    { name: 'Rubick', 'Bounty Hunter': -0.002 },
    { name: 'Sand King', 'Bounty Hunter': -1.769 },
    { name: 'Shadow Demon', 'Bounty Hunter': -0.853 },
    { name: 'Shadow Fiend', 'Bounty Hunter': -0.384 },
    { name: 'Shadow Shaman', 'Bounty Hunter': 2.603 },
    { name: 'Silencer', 'Bounty Hunter': -0.693 },
    { name: 'Skywrath Mage', 'Bounty Hunter': 0.439 },
    { name: 'Slardar', 'Bounty Hunter': 1.147 },
    { name: 'Slark', 'Bounty Hunter': 2.179 },
    { name: 'Snapfire', 'Bounty Hunter': -0.373 },
    { name: 'Sniper', 'Bounty Hunter': -0.378 },
    { name: 'Spectre', 'Bounty Hunter': -0.452 },
    { name: 'Spirit Breaker', 'Bounty Hunter': 0.966 },
    { name: 'Storm Spirit', 'Bounty Hunter': 1.985 },
    { name: 'Sven', 'Bounty Hunter': -0.643 },
    { name: 'Techies', 'Bounty Hunter': 1.03 },
    { name: 'Templar Assassin', 'Bounty Hunter': -0.8 },
    { name: 'Terrorblade', 'Bounty Hunter': 0.793 },
    { name: 'Tidehunter', 'Bounty Hunter': 1.725 },
    { name: 'Timbersaw', 'Bounty Hunter': -1.096 },
    { name: 'Tinker', 'Bounty Hunter': -0.358 },
    { name: 'Tiny', 'Bounty Hunter': 0.203 },
    { name: 'Treant Protector', 'Bounty Hunter': 0.035 },
    { name: 'Troll Warlord', 'Bounty Hunter': 1.799 },
    { name: 'Tusk', 'Bounty Hunter': 1.337 },
    { name: 'Underlord', 'Bounty Hunter': -0.743 },
    { name: 'Undying', 'Bounty Hunter': -1.011 },
    { name: 'Ursa', 'Bounty Hunter': -0.282 },
    { name: 'Vengeful Spirit', 'Bounty Hunter': -0.153 },
    { name: 'Venomancer', 'Bounty Hunter': 0.69 },
    { name: 'Viper', 'Bounty Hunter': 0.731 },
    { name: 'Visage', 'Bounty Hunter': -0.449 },
    { name: 'Void Spirit', 'Bounty Hunter': -0.878 },
    { name: 'Warlock', 'Bounty Hunter': -1.473 },
    { name: 'Weaver', 'Bounty Hunter': -1.61 },
    { name: 'Windranger', 'Bounty Hunter': -0.887 },
    { name: 'Winter Wyvern', 'Bounty Hunter': -0.745 },
    { name: 'Witch Doctor', 'Bounty Hunter': -0.583 },
    { name: 'Wraith King', 'Bounty Hunter': -0.882 },
    { name: 'Zeus', 'Bounty Hunter': 0.812 },
],
"Clinkz": [
    { name: 'Abaddon', 'Clinkz': 0.871 },
    { name: 'Alchemist', 'Clinkz': -1.878 },
    { name: 'Ancient Apparition', 'Clinkz': -1.373 },
    { name: 'Anti-Mage', 'Clinkz': 0.168 },
    { name: 'Arc Warden', 'Clinkz': 2.694 },
    { name: 'Axe', 'Clinkz': 1.473 },
    { name: 'Bane', 'Clinkz': 0.387 },
    { name: 'Batrider', 'Clinkz': 0.094 },
    { name: 'Beastmaster', 'Clinkz': -1.449 },
    { name: 'Bloodseeker', 'Clinkz': -0.327 },
    { name: 'Bounty Hunter', 'Clinkz': 2.406 },
    { name: 'Brewmaster', 'Clinkz': 0.939 },
    { name: 'Bristleback', 'Clinkz': 2.645 },
    { name: 'Broodmother', 'Clinkz': 2.477 },
    { name: 'Centaur Warrunner', 'Clinkz': 0.284 },
    { name: 'Chaos Knight', 'Clinkz': 2.589 },
    { name: 'Chen', 'Clinkz': -0.736 },
    { name: 'Clockwerk', 'Clinkz': 0.412 },
    { name: 'Crystal Maiden', 'Clinkz': 0.78 },
    { name: 'Dark Seer', 'Clinkz': -1.511 },
    { name: 'Dark Willow', 'Clinkz': 0.13 },
    { name: 'Dawnbreaker', 'Clinkz': -0.832 },
    { name: 'Dazzle', 'Clinkz': 1.704 },
    { name: 'Death Prophet', 'Clinkz': -1.422 },
    { name: 'Disruptor', 'Clinkz': -1.275 },
    { name: 'Doom', 'Clinkz': -1.109 },
    { name: 'Dragon Knight', 'Clinkz': 0.614 },
    { name: 'Drow Ranger', 'Clinkz': 1.697 },
    { name: 'Earth Spirit', 'Clinkz': 1.528 },
    { name: 'Earthshaker', 'Clinkz': -1.918 },
    { name: 'Elder Titan', 'Clinkz': -3.081 },
    { name: 'Ember Spirit', 'Clinkz': -1.564 },
    { name: 'Enchantress', 'Clinkz': 2.513 },
    { name: 'Enigma', 'Clinkz': -2.014 },
    { name: 'Faceless Void', 'Clinkz': 0.248 },
    { name: 'Grimstroke', 'Clinkz': -1.331 },
    { name: 'Gyrocopter', 'Clinkz': -0.094 },
    { name: 'Hoodwink', 'Clinkz': -0.151 },
    { name: 'Huskar', 'Clinkz': 0.351 },
    { name: 'Invoker', 'Clinkz': -0.776 },
    { name: 'Io', 'Clinkz': 0.877 },
    { name: 'Jakiro', 'Clinkz': -0.76 },
    { name: 'Juggernaut', 'Clinkz': -2.494 },
    { name: 'Keeper of the Light', 'Clinkz': -0.509 },
    { name: 'Kunkka', 'Clinkz': -0.439 },
    { name: 'Legion Commander', 'Clinkz': 2.216 },
    { name: 'Leshrac', 'Clinkz': -2.097 },
    { name: 'Lich', 'Clinkz': -0.668 },
    { name: 'Lifestealer', 'Clinkz': -3.006 },
    { name: 'Lina', 'Clinkz': -1.589 },
    { name: 'Lion', 'Clinkz': 0.022 },
    { name: 'Lone Druid', 'Clinkz': -0.38 },
    { name: 'Luna', 'Clinkz': 0.974 },
    { name: 'Lycan', 'Clinkz': 0.607 },
    { name: 'Magnus', 'Clinkz': 0.319 },
    { name: 'Marci', 'Clinkz': -0.422 },
    { name: 'Mars', 'Clinkz': 0.713 },
    { name: 'Medusa', 'Clinkz': 0.962 },
    { name: 'Meepo', 'Clinkz': 2.857 },
    { name: 'Mirana', 'Clinkz': 1.123 },
    { name: 'Monkey King', 'Clinkz': -0.391 },
    { name: 'Morphling', 'Clinkz': 2.285 },
    { name: 'Muerta', 'Clinkz': -0.485 },
    { name: 'Naga Siren', 'Clinkz': 4.442 },
    { name: "Nature's Prophet", 'Clinkz': -0.059 },
    { name: 'Necrophos', 'Clinkz': -1.546 },
    { name: 'Night Stalker', 'Clinkz': -0.582 },
    { name: 'Nyx Assassin', 'Clinkz': 0.265 },
    { name: 'Ogre Magi', 'Clinkz': 0.625 },
    { name: 'Omniknight', 'Clinkz': -0.219 },
    { name: 'Oracle', 'Clinkz': 0.122 },
    { name: 'Outworld Destroyer', 'Clinkz': -1.098 },
    { name: 'Pangolier', 'Clinkz': -1.454 },
    { name: 'Phantom Assassin', 'Clinkz': 1.841 },
    { name: 'Phantom Lancer', 'Clinkz': 3.811 },
    { name: 'Phoenix', 'Clinkz': -2.071 },
    { name: 'Primal Beast', 'Clinkz': -0.523 },
    { name: 'Puck', 'Clinkz': -0.385 },
    { name: 'Pudge', 'Clinkz': 1.187 },
    { name: 'Pugna', 'Clinkz': -0.028 },
    { name: 'Queen of Pain', 'Clinkz': -0.723 },
    { name: 'Razor', 'Clinkz': 1.988 },
    { name: 'Riki', 'Clinkz': 2.944 },
    { name: 'Ringmaster', 'Clinkz': -0.678 },
    { name: 'Rubick', 'Clinkz': -1.583 },
    { name: 'Sand King', 'Clinkz': -1.418 },
    { name: 'Shadow Demon', 'Clinkz': 0.45 },
    { name: 'Shadow Fiend', 'Clinkz': -0.407 },
    { name: 'Shadow Shaman', 'Clinkz': 0.364 },
    { name: 'Silencer', 'Clinkz': 0.638 },
    { name: 'Skywrath Mage', 'Clinkz': -0.434 },
    { name: 'Slardar', 'Clinkz': 2.178 },
    { name: 'Slark', 'Clinkz': 0.238 },
    { name: 'Snapfire', 'Clinkz': 0.306 },
    { name: 'Sniper', 'Clinkz': 0.535 },
    { name: 'Spectre', 'Clinkz': 2.755 },
    { name: 'Spirit Breaker', 'Clinkz': 0.655 },
    { name: 'Storm Spirit', 'Clinkz': -0.143 },
    { name: 'Sven', 'Clinkz': -0.594 },
    { name: 'Techies', 'Clinkz': 1.228 },
    { name: 'Templar Assassin', 'Clinkz': -1.223 },
    { name: 'Terrorblade', 'Clinkz': 2.166 },
    { name: 'Tidehunter', 'Clinkz': -1.174 },
    { name: 'Timbersaw', 'Clinkz': -0.648 },
    { name: 'Tinker', 'Clinkz': -1.167 },
    { name: 'Tiny', 'Clinkz': 1.042 },
    { name: 'Treant Protector', 'Clinkz': 0.154 },
    { name: 'Troll Warlord', 'Clinkz': 0.567 },
    { name: 'Tusk', 'Clinkz': 0.057 },
    { name: 'Underlord', 'Clinkz': -1.165 },
    { name: 'Undying', 'Clinkz': -1.93 },
    { name: 'Ursa', 'Clinkz': -2.385 },
    { name: 'Vengeful Spirit', 'Clinkz': 0.8 },
    { name: 'Venomancer', 'Clinkz': -0.741 },
    { name: 'Viper', 'Clinkz': -0.924 },
    { name: 'Visage', 'Clinkz': -1.113 },
    { name: 'Void Spirit', 'Clinkz': 2.526 },
    { name: 'Warlock', 'Clinkz': -0.718 },
    { name: 'Weaver', 'Clinkz': -1.47 },
    { name: 'Windranger', 'Clinkz': -1.019 },
    { name: 'Winter Wyvern', 'Clinkz': -0.405 },
    { name: 'Witch Doctor', 'Clinkz': -0.997 },
    { name: 'Wraith King', 'Clinkz': 1.574 },
    { name: 'Zeus', 'Clinkz': -0.355 },
],
"Drow Ranger": [
    { name: 'Abaddon', 'Drow Ranger': -0.783 },
    { name: 'Alchemist', 'Drow Ranger': -0.731 },
    { name: 'Ancient Apparition', 'Drow Ranger': -0.547 },
    { name: 'Anti-Mage', 'Drow Ranger': -1.368 },
    { name: 'Arc Warden', 'Drow Ranger': 0.43 },
    { name: 'Axe', 'Drow Ranger': 0.102 },
    { name: 'Bane', 'Drow Ranger': -0.593 },
    { name: 'Batrider', 'Drow Ranger': 0.824 },
    { name: 'Beastmaster', 'Drow Ranger': -0.145 },
    { name: 'Bloodseeker', 'Drow Ranger': 0.795 },
    { name: 'Bounty Hunter', 'Drow Ranger': 0.255 },
    { name: 'Brewmaster', 'Drow Ranger': 0.768 },
    { name: 'Bristleback', 'Drow Ranger': -3.006 },
    { name: 'Broodmother', 'Drow Ranger': 0.377 },
    { name: 'Centaur Warrunner', 'Drow Ranger': 1.943 },
    { name: 'Chaos Knight', 'Drow Ranger': 2.143 },
    { name: 'Chen', 'Drow Ranger': 2.452 },
    { name: 'Clinkz', 'Drow Ranger': -1.719 },
    { name: 'Clockwerk', 'Drow Ranger': 1.558 },
    { name: 'Crystal Maiden', 'Drow Ranger': -1.333 },
    { name: 'Dark Seer', 'Drow Ranger': 2.303 },
    { name: 'Dark Willow', 'Drow Ranger': 0.242 },
    { name: 'Dawnbreaker', 'Drow Ranger': -1.761 },
    { name: 'Dazzle', 'Drow Ranger': -0.678 },
    { name: 'Death Prophet', 'Drow Ranger': -1.002 },
    { name: 'Disruptor', 'Drow Ranger': -1.174 },
    { name: 'Doom', 'Drow Ranger': -0.378 },
    { name: 'Dragon Knight', 'Drow Ranger': 1.259 },
    { name: 'Earth Spirit', 'Drow Ranger': 2.337 },
    { name: 'Earthshaker', 'Drow Ranger': -0.819 },
    { name: 'Elder Titan', 'Drow Ranger': -0.109 },
    { name: 'Ember Spirit', 'Drow Ranger': -0.834 },
    { name: 'Enchantress', 'Drow Ranger': 0.896 },
    { name: 'Enigma', 'Drow Ranger': -0.858 },
    { name: 'Faceless Void', 'Drow Ranger': 1.276 },
    { name: 'Grimstroke', 'Drow Ranger': -0.691 },
    { name: 'Gyrocopter', 'Drow Ranger': -0.007 },
    { name: 'Hoodwink', 'Drow Ranger': -0.077 },
    { name: 'Huskar', 'Drow Ranger': -1.027 },
    { name: 'Invoker', 'Drow Ranger': 0.727 },
    { name: 'Io', 'Drow Ranger': -1.3 },
    { name: 'Jakiro', 'Drow Ranger': -0.588 },
    { name: 'Juggernaut', 'Drow Ranger': -0.765 },
    { name: 'Keeper of the Light', 'Drow Ranger': 0.769 },
    { name: 'Kunkka', 'Drow Ranger': 0.132 },
    { name: 'Legion Commander', 'Drow Ranger': -0.536 },
    { name: 'Leshrac', 'Drow Ranger': -2.148 },
    { name: 'Lich', 'Drow Ranger': -0.359 },
    { name: 'Lifestealer', 'Drow Ranger': -1.336 },
    { name: 'Lina', 'Drow Ranger': -1.464 },
    { name: 'Lion', 'Drow Ranger': -1.2 },
    { name: 'Lone Druid', 'Drow Ranger': 0.811 },
    { name: 'Luna', 'Drow Ranger': -0.321 },
    { name: 'Lycan', 'Drow Ranger': 2.235 },
    { name: 'Magnus', 'Drow Ranger': 1.23 },
    { name: 'Marci', 'Drow Ranger': -0.177 },
    { name: 'Mars', 'Drow Ranger': 3.527 },
    { name: 'Medusa', 'Drow Ranger': -0.022 },
    { name: 'Meepo', 'Drow Ranger': -1.956 },
    { name: 'Mirana', 'Drow Ranger': 0.749 },
    { name: 'Monkey King', 'Drow Ranger': 0.432 },
    { name: 'Morphling', 'Drow Ranger': 0.681 },
    { name: 'Muerta', 'Drow Ranger': 1.916 },
    { name: 'Naga Siren', 'Drow Ranger': 2.073 },
    { name: "Nature's Prophet", 'Drow Ranger': 1.178 },
    { name: 'Necrophos', 'Drow Ranger': -2.518 },
    { name: 'Night Stalker', 'Drow Ranger': 0.839 },
    { name: 'Nyx Assassin', 'Drow Ranger': 0.437 },
    { name: 'Ogre Magi', 'Drow Ranger': 1.52 },
    { name: 'Omniknight', 'Drow Ranger': -0.937 },
    { name: 'Oracle', 'Drow Ranger': -1.274 },
    { name: 'Outworld Destroyer', 'Drow Ranger': 0.415 },
    { name: 'Pangolier', 'Drow Ranger': -2.093 },
    { name: 'Phantom Assassin', 'Drow Ranger': -0.863 },
    { name: 'Phantom Lancer', 'Drow Ranger': 4.142 },
    { name: 'Phoenix', 'Drow Ranger': -1.602 },
    { name: 'Primal Beast', 'Drow Ranger': 1.856 },
    { name: 'Puck', 'Drow Ranger': -0.003 },
    { name: 'Pudge', 'Drow Ranger': 2.448 },
    { name: 'Pugna', 'Drow Ranger': -1.294 },
    { name: 'Queen of Pain', 'Drow Ranger': 0.093 },
    { name: 'Razor', 'Drow Ranger': -0.661 },
    { name: 'Riki', 'Drow Ranger': -1.686 },
    { name: 'Ringmaster', 'Drow Ranger': 0.704 },
    { name: 'Rubick', 'Drow Ranger': -1.09 },
    { name: 'Sand King', 'Drow Ranger': 0.009 },
    { name: 'Shadow Demon', 'Drow Ranger': 0.39 },
    { name: 'Shadow Fiend', 'Drow Ranger': -0.809 },
    { name: 'Shadow Shaman', 'Drow Ranger': -1.307 },
    { name: 'Silencer', 'Drow Ranger': 0.598 },
    { name: 'Skywrath Mage', 'Drow Ranger': 0.3 },
    { name: 'Slardar', 'Drow Ranger': -1.987 },
    { name: 'Slark', 'Drow Ranger': -1.085 },
    { name: 'Snapfire', 'Drow Ranger': 0.794 },
    { name: 'Sniper', 'Drow Ranger': 1.51 },
    { name: 'Spectre', 'Drow Ranger': 3.324 },
    { name: 'Spirit Breaker', 'Drow Ranger': 0.713 },
    { name: 'Storm Spirit', 'Drow Ranger': 1.49 },
    { name: 'Sven', 'Drow Ranger': 0.556 },
    { name: 'Techies', 'Drow Ranger': -0.025 },
    { name: 'Templar Assassin', 'Drow Ranger': -0.483 },
    { name: 'Terrorblade', 'Drow Ranger': -0.347 },
    { name: 'Tidehunter', 'Drow Ranger': 0.597 },
    { name: 'Timbersaw', 'Drow Ranger': 0.197 },
    { name: 'Tinker', 'Drow Ranger': -1.903 },
    { name: 'Tiny', 'Drow Ranger': 1.754 },
    { name: 'Treant Protector', 'Drow Ranger': -0.067 },
    { name: 'Troll Warlord', 'Drow Ranger': -0.535 },
    { name: 'Tusk', 'Drow Ranger': -0.276 },
    { name: 'Underlord', 'Drow Ranger': 0.246 },
    { name: 'Undying', 'Drow Ranger': 0.039 },
    { name: 'Ursa', 'Drow Ranger': -0.979 },
    { name: 'Vengeful Spirit', 'Drow Ranger': 0.021 },
    { name: 'Venomancer', 'Drow Ranger': 0.591 },
    { name: 'Viper', 'Drow Ranger': -1.646 },
    { name: 'Visage', 'Drow Ranger': -0.074 },
    { name: 'Void Spirit', 'Drow Ranger': 0.936 },
    { name: 'Warlock', 'Drow Ranger': -0.017 },
    { name: 'Weaver', 'Drow Ranger': -2.656 },
    { name: 'Windranger', 'Drow Ranger': -0.73 },
    { name: 'Winter Wyvern', 'Drow Ranger': 0.507 },
    { name: 'Witch Doctor', 'Drow Ranger': -1.084 },
    { name: 'Wraith King', 'Drow Ranger': 0.921 },
    { name: 'Zeus', 'Drow Ranger': 2.067 },
],
"Ember Spirit": [
    { name: 'Abaddon', 'Ember Spirit': -0.575 },
    { name: 'Alchemist', 'Ember Spirit': 1.21 },
    { name: 'Ancient Apparition', 'Ember Spirit': -0.719 },
    { name: 'Anti-Mage', 'Ember Spirit': 0.117 },
    { name: 'Arc Warden', 'Ember Spirit': 0.345 },
    { name: 'Axe', 'Ember Spirit': -1.247 },
    { name: 'Bane', 'Ember Spirit': 2.241 },
    { name: 'Batrider', 'Ember Spirit': -0.121 },
    { name: 'Beastmaster', 'Ember Spirit': -2.039 },
    { name: 'Bloodseeker', 'Ember Spirit': -2.024 },
    { name: 'Bounty Hunter', 'Ember Spirit': 0.513 },
    { name: 'Brewmaster', 'Ember Spirit': 0.138 },
    { name: 'Bristleback', 'Ember Spirit': -1.429 },
    { name: 'Broodmother', 'Ember Spirit': 3.354 },
    { name: 'Centaur Warrunner', 'Ember Spirit': -3.052 },
    { name: 'Chaos Knight', 'Ember Spirit': -1.474 },
    { name: 'Chen', 'Ember Spirit': 2.382 },
    { name: 'Clinkz', 'Ember Spirit': 1.469 },
    { name: 'Clockwerk', 'Ember Spirit': -2.516 },
    { name: 'Crystal Maiden', 'Ember Spirit': 0.749 },
    { name: 'Dark Seer', 'Ember Spirit': -2.134 },
    { name: 'Dark Willow', 'Ember Spirit': 1.911 },
    { name: 'Dawnbreaker', 'Ember Spirit': 0.983 },
    { name: 'Dazzle', 'Ember Spirit': 0.865 },
    { name: 'Death Prophet', 'Ember Spirit': -0.2 },
    { name: 'Disruptor', 'Ember Spirit': 0.058 },
    { name: 'Doom', 'Ember Spirit': 0.89 },
    { name: 'Dragon Knight', 'Ember Spirit': 0.983 },
    { name: 'Drow Ranger', 'Ember Spirit': 0.828 },
    { name: 'Earth Spirit', 'Ember Spirit': 0.085 },
    { name: 'Earthshaker', 'Ember Spirit': 0.432 },
    { name: 'Elder Titan', 'Ember Spirit': 1.25 },
    { name: 'Enchantress', 'Ember Spirit': 1.284 },
    { name: 'Enigma', 'Ember Spirit': 1.343 },
    { name: 'Faceless Void', 'Ember Spirit': 0.11 },
    { name: 'Grimstroke', 'Ember Spirit': 1.531 },
    { name: 'Gyrocopter', 'Ember Spirit': 0.327 },
    { name: 'Hoodwink', 'Ember Spirit': -0.065 },
    { name: 'Huskar', 'Ember Spirit': 5.972 },
    { name: 'Invoker', 'Ember Spirit': 0.422 },
    { name: 'Io', 'Ember Spirit': 0.5 },
    { name: 'Jakiro', 'Ember Spirit': -0.483 },
    { name: 'Juggernaut', 'Ember Spirit': 1.304 },
    { name: 'Keeper of the Light', 'Ember Spirit': 0.595 },
    { name: 'Kunkka', 'Ember Spirit': -2.029 },
    { name: 'Legion Commander', 'Ember Spirit': 1.964 },
    { name: 'Leshrac', 'Ember Spirit': -0.997 },
    { name: 'Lich', 'Ember Spirit': -0.884 },
    { name: 'Lifestealer', 'Ember Spirit': 0.229 },
    { name: 'Lina', 'Ember Spirit': 0.452 },
    { name: 'Lion', 'Ember Spirit': -0.154 },
    { name: 'Lone Druid', 'Ember Spirit': 3.825 },
    { name: 'Luna', 'Ember Spirit': -0.5 },
    { name: 'Lycan', 'Ember Spirit': -0.338 },
    { name: 'Magnus', 'Ember Spirit': -1.007 },
    { name: 'Marci', 'Ember Spirit': -0.171 },
    { name: 'Mars', 'Ember Spirit': -1.501 },
    { name: 'Medusa', 'Ember Spirit': -2.682 },
    { name: 'Meepo', 'Ember Spirit': 3.025 },
    { name: 'Mirana', 'Ember Spirit': 0.271 },
    { name: 'Monkey King', 'Ember Spirit': 4.044 },
    { name: 'Morphling', 'Ember Spirit': 2.237 },
    { name: 'Muerta', 'Ember Spirit': 1.857 },
    { name: 'Naga Siren', 'Ember Spirit': 0.732 },
    { name: "Nature's Prophet", 'Ember Spirit': 1.367 },
    { name: 'Necrophos', 'Ember Spirit': 0.127 },
    { name: 'Night Stalker', 'Ember Spirit': 1.202 },
    { name: 'Nyx Assassin', 'Ember Spirit': -1.802 },
    { name: 'Ogre Magi', 'Ember Spirit': 0.108 },
    { name: 'Omniknight', 'Ember Spirit': 1.178 },
    { name: 'Oracle', 'Ember Spirit': 2.44 },
    { name: 'Outworld Destroyer', 'Ember Spirit': -0.269 },
    { name: 'Pangolier', 'Ember Spirit': -0.697 },
    { name: 'Phantom Assassin', 'Ember Spirit': 0.483 },
    { name: 'Phantom Lancer', 'Ember Spirit': -3.446 },
    { name: 'Phoenix', 'Ember Spirit': -0.024 },
    { name: 'Primal Beast', 'Ember Spirit': -1.402 },
    { name: 'Puck', 'Ember Spirit': 0.897 },
    { name: 'Pudge', 'Ember Spirit': -0.089 },
    { name: 'Pugna', 'Ember Spirit': 1.279 },
    { name: 'Queen of Pain', 'Ember Spirit': -3.604 },
    { name: 'Razor', 'Ember Spirit': -2.071 },
    { name: 'Riki', 'Ember Spirit': 1.303 },
    { name: 'Ringmaster', 'Ember Spirit': 2.281 },
    { name: 'Rubick', 'Ember Spirit': -1.651 },
    { name: 'Sand King', 'Ember Spirit': -2.369 },
    { name: 'Shadow Demon', 'Ember Spirit': -1.052 },
    { name: 'Shadow Fiend', 'Ember Spirit': 2.464 },
    { name: 'Shadow Shaman', 'Ember Spirit': 0.157 },
    { name: 'Silencer', 'Ember Spirit': 0.531 },
    { name: 'Skywrath Mage', 'Ember Spirit': 1.082 },
    { name: 'Slardar', 'Ember Spirit': 1.004 },
    { name: 'Slark', 'Ember Spirit': 4.012 },
    { name: 'Snapfire', 'Ember Spirit': 0.188 },
    { name: 'Sniper', 'Ember Spirit': 1.765 },
    { name: 'Spectre', 'Ember Spirit': -3.169 },
    { name: 'Spirit Breaker', 'Ember Spirit': -1.152 },
    { name: 'Storm Spirit', 'Ember Spirit': -2.05 },
    { name: 'Sven', 'Ember Spirit': 0.433 },
    { name: 'Techies', 'Ember Spirit': 1.208 },
    { name: 'Templar Assassin', 'Ember Spirit': 2.366 },
    { name: 'Terrorblade', 'Ember Spirit': -0.621 },
    { name: 'Tidehunter', 'Ember Spirit': 1.86 },
    { name: 'Timbersaw', 'Ember Spirit': -0.311 },
    { name: 'Tinker', 'Ember Spirit': 2.026 },
    { name: 'Tiny', 'Ember Spirit': -1.162 },
    { name: 'Treant Protector', 'Ember Spirit': 1.119 },
    { name: 'Troll Warlord', 'Ember Spirit': 2.033 },
    { name: 'Tusk', 'Ember Spirit': -1.174 },
    { name: 'Underlord', 'Ember Spirit': 0.529 },
    { name: 'Undying', 'Ember Spirit': -1.058 },
    { name: 'Ursa', 'Ember Spirit': 0.37 },
    { name: 'Vengeful Spirit', 'Ember Spirit': -1.3 },
    { name: 'Venomancer', 'Ember Spirit': -0.858 },
    { name: 'Viper', 'Ember Spirit': 0.951 },
    { name: 'Visage', 'Ember Spirit': -1.816 },
    { name: 'Void Spirit', 'Ember Spirit': -2.14 },
    { name: 'Warlock', 'Ember Spirit': -1.27 },
    { name: 'Weaver', 'Ember Spirit': 1.171 },
    { name: 'Windranger', 'Ember Spirit': 0.341 },
    { name: 'Winter Wyvern', 'Ember Spirit': 0.159 },
    { name: 'Witch Doctor', 'Ember Spirit': 0.352 },
    { name: 'Wraith King', 'Ember Spirit': -0.691 },
    { name: 'Zeus', 'Ember Spirit': -1.928 },
],
"Faceless Void": [
    { name: 'Abaddon', 'Faceless Void': 0.307 },
    { name: 'Alchemist', 'Faceless Void': 0.119 },
    { name: 'Ancient Apparition', 'Faceless Void': -0.332 },
    { name: 'Anti-Mage', 'Faceless Void': 1.122 },
    { name: 'Arc Warden', 'Faceless Void': 0.226 },
    { name: 'Axe', 'Faceless Void': 1.036 },
    { name: 'Bane', 'Faceless Void': 0.402 },
    { name: 'Batrider', 'Faceless Void': -0.955 },
    { name: 'Beastmaster', 'Faceless Void': 1.07 },
    { name: 'Bloodseeker', 'Faceless Void': -1.221 },
    { name: 'Bounty Hunter', 'Faceless Void': -0.564 },
    { name: 'Brewmaster', 'Faceless Void': -1.028 },
    { name: 'Bristleback', 'Faceless Void': 1.361 },
    { name: 'Broodmother', 'Faceless Void': 1.694 },
    { name: 'Centaur Warrunner', 'Faceless Void': 0.156 },
    { name: 'Chaos Knight', 'Faceless Void': 1.79 },
    { name: 'Chen', 'Faceless Void': -0.034 },
    { name: 'Clinkz', 'Faceless Void': -0.354 },
    { name: 'Clockwerk', 'Faceless Void': -1.558 },
    { name: 'Crystal Maiden', 'Faceless Void': 0.181 },
    { name: 'Dark Seer', 'Faceless Void': -2.494 },
    { name: 'Dark Willow', 'Faceless Void': -0.077 },
    { name: 'Dawnbreaker', 'Faceless Void': -0.413 },
    { name: 'Dazzle', 'Faceless Void': -0.717 },
    { name: 'Death Prophet', 'Faceless Void': 0.867 },
    { name: 'Disruptor', 'Faceless Void': 0.346 },
    { name: 'Doom', 'Faceless Void': 2.734 },
    { name: 'Dragon Knight', 'Faceless Void': -0.393 },
    { name: 'Drow Ranger', 'Faceless Void': -1.265 },
    { name: 'Earth Spirit', 'Faceless Void': -0.485 },
    { name: 'Earthshaker', 'Faceless Void': 0.262 },
    { name: 'Elder Titan', 'Faceless Void': 2.294 },
    { name: 'Ember Spirit', 'Faceless Void': -0.041 },
    { name: 'Enchantress', 'Faceless Void': 2.324 },
    { name: 'Enigma', 'Faceless Void': -0.2 },
    { name: 'Grimstroke', 'Faceless Void': 0.944 },
    { name: 'Gyrocopter', 'Faceless Void': -0.617 },
    { name: 'Hoodwink', 'Faceless Void': 0.784 },
    { name: 'Huskar', 'Faceless Void': 2.482 },
    { name: 'Invoker', 'Faceless Void': -1.18 },
    { name: 'Io', 'Faceless Void': -0.02 },
    { name: 'Jakiro', 'Faceless Void': -0.603 },
    { name: 'Juggernaut', 'Faceless Void': -0.985 },
    { name: 'Keeper of the Light', 'Faceless Void': -2.177 },
    { name: 'Kunkka', 'Faceless Void': -2.453 },
    { name: 'Legion Commander', 'Faceless Void': 2.2 },
    { name: 'Leshrac', 'Faceless Void': 0.101 },
    { name: 'Lich', 'Faceless Void': -0.782 },
    { name: 'Lifestealer', 'Faceless Void': -1.504 },
    { name: 'Lina', 'Faceless Void': -1.285 },
    { name: 'Lion', 'Faceless Void': 1.025 },
    { name: 'Lone Druid', 'Faceless Void': 2.72 },
    { name: 'Luna', 'Faceless Void': 0.252 },
    { name: 'Lycan', 'Faceless Void': 0.718 },
    { name: 'Magnus', 'Faceless Void': -1.649 },
    { name: 'Marci', 'Faceless Void': -0.101 },
    { name: 'Mars', 'Faceless Void': -2.452 },
    { name: 'Medusa', 'Faceless Void': -0.834 },
    { name: 'Meepo', 'Faceless Void': 2.503 },
    { name: 'Mirana', 'Faceless Void': -0.209 },
    { name: 'Monkey King', 'Faceless Void': 0.689 },
    { name: 'Morphling', 'Faceless Void': 0.118 },
    { name: 'Muerta', 'Faceless Void': -0.44 },
    { name: 'Naga Siren', 'Faceless Void': 2.668 },
    { name: "Nature's Prophet", 'Faceless Void': 0.93 },
    { name: 'Necrophos', 'Faceless Void': -0.27 },
    { name: 'Night Stalker', 'Faceless Void': 2.112 },
    { name: 'Nyx Assassin', 'Faceless Void': -0.331 },
    { name: 'Ogre Magi', 'Faceless Void': 0.309 },
    { name: 'Omniknight', 'Faceless Void': -2.528 },
    { name: 'Oracle', 'Faceless Void': 0.49 },
    { name: 'Outworld Destroyer', 'Faceless Void': 1.138 },
    { name: 'Pangolier', 'Faceless Void': -0.95 },
    { name: 'Phantom Assassin', 'Faceless Void': -0.157 },
    { name: 'Phantom Lancer', 'Faceless Void': -0.448 },
    { name: 'Phoenix', 'Faceless Void': 0.601 },
    { name: 'Primal Beast', 'Faceless Void': -2.575 },
    { name: 'Puck', 'Faceless Void': -0.465 },
    { name: 'Pudge', 'Faceless Void': 0.68 },
    { name: 'Pugna', 'Faceless Void': -1.573 },
    { name: 'Queen of Pain', 'Faceless Void': -1.624 },
    { name: 'Razor', 'Faceless Void': -1.639 },
    { name: 'Riki', 'Faceless Void': 1.285 },
    { name: 'Ringmaster', 'Faceless Void': -0.204 },
    { name: 'Rubick', 'Faceless Void': -0.003 },
    { name: 'Sand King', 'Faceless Void': -1.758 },
    { name: 'Shadow Demon', 'Faceless Void': -1.063 },
    { name: 'Shadow Fiend', 'Faceless Void': 0.457 },
    { name: 'Shadow Shaman', 'Faceless Void': 1.291 },
    { name: 'Silencer', 'Faceless Void': 1.711 },
    { name: 'Skywrath Mage', 'Faceless Void': 1.326 },
    { name: 'Slardar', 'Faceless Void': 1.48 },
    { name: 'Slark', 'Faceless Void': 1.074 },
    { name: 'Snapfire', 'Faceless Void': -1.073 },
    { name: 'Sniper', 'Faceless Void': -0.458 },
    { name: 'Spectre', 'Faceless Void': 1.376 },
    { name: 'Spirit Breaker', 'Faceless Void': -0.148 },
    { name: 'Storm Spirit', 'Faceless Void': 0.479 },
    { name: 'Sven', 'Faceless Void': -1.325 },
    { name: 'Techies', 'Faceless Void': -0.793 },
    { name: 'Templar Assassin', 'Faceless Void': -0.98 },
    { name: 'Terrorblade', 'Faceless Void': 0.231 },
    { name: 'Tidehunter', 'Faceless Void': 2.944 },
    { name: 'Timbersaw', 'Faceless Void': -2.384 },
    { name: 'Tinker', 'Faceless Void': -1.493 },
    { name: 'Tiny', 'Faceless Void': 0.005 },
    { name: 'Treant Protector', 'Faceless Void': -0.737 },
    { name: 'Troll Warlord', 'Faceless Void': 0.813 },
    { name: 'Tusk', 'Faceless Void': -1.138 },
    { name: 'Underlord', 'Faceless Void': -0.784 },
    { name: 'Undying', 'Faceless Void': -0.75 },
    { name: 'Ursa', 'Faceless Void': -0.313 },
    { name: 'Vengeful Spirit', 'Faceless Void': 0.463 },
    { name: 'Venomancer', 'Faceless Void': -0.421 },
    { name: 'Viper', 'Faceless Void': 0.143 },
    { name: 'Visage', 'Faceless Void': -1.071 },
    { name: 'Void Spirit', 'Faceless Void': 0.347 },
    { name: 'Warlock', 'Faceless Void': -1.249 },
    { name: 'Weaver', 'Faceless Void': -3.819 },
    { name: 'Windranger', 'Faceless Void': -2.891 },
    { name: 'Winter Wyvern', 'Faceless Void': -0.281 },
    { name: 'Witch Doctor', 'Faceless Void': -1.594 },
    { name: 'Wraith King', 'Faceless Void': 0.941 },
    { name: 'Zeus', 'Faceless Void': -1.409 },
],
"Gyrocopter": [
    { name: 'Abaddon', 'Gyrocopter': -0.078 },
    { name: 'Alchemist', 'Gyrocopter': -0.214 },
    { name: 'Ancient Apparition', 'Gyrocopter': 1.656 },
    { name: 'Anti-Mage', 'Gyrocopter': 0.982 },
    { name: 'Arc Warden', 'Gyrocopter': -0.57 },
    { name: 'Axe', 'Gyrocopter': -0.716 },
    { name: 'Bane', 'Gyrocopter': -0.052 },
    { name: 'Batrider', 'Gyrocopter': -0.014 },
    { name: 'Beastmaster', 'Gyrocopter': -0.635 },
    { name: 'Bloodseeker', 'Gyrocopter': -0.114 },
    { name: 'Bounty Hunter', 'Gyrocopter': 0.741 },
    { name: 'Brewmaster', 'Gyrocopter': -0.244 },
    { name: 'Bristleback', 'Gyrocopter': 1.353 },
    { name: 'Broodmother', 'Gyrocopter': 0.123 },
    { name: 'Centaur Warrunner', 'Gyrocopter': 0.954 },
    { name: 'Chaos Knight', 'Gyrocopter': -0.409 },
    { name: 'Chen', 'Gyrocopter': 3.518 },
    { name: 'Clinkz', 'Gyrocopter': 0.109 },
    { name: 'Clockwerk', 'Gyrocopter': 0.041 },
    { name: 'Crystal Maiden', 'Gyrocopter': -0.137 },
    { name: 'Dark Seer', 'Gyrocopter': -0.986 },
    { name: 'Dark Willow', 'Gyrocopter': 0.244 },
    { name: 'Dawnbreaker', 'Gyrocopter': -0.382 },
    { name: 'Dazzle', 'Gyrocopter': -1.829 },
    { name: 'Death Prophet', 'Gyrocopter': -1.212 },
    { name: 'Disruptor', 'Gyrocopter': -0.484 },
    { name: 'Doom', 'Gyrocopter': 0.41 },
    { name: 'Dragon Knight', 'Gyrocopter': 0.123 },
    { name: 'Drow Ranger', 'Gyrocopter': 0.106 },
    { name: 'Earth Spirit', 'Gyrocopter': -0.62 },
    { name: 'Earthshaker', 'Gyrocopter': -1.203 },
    { name: 'Elder Titan', 'Gyrocopter': -0.681 },
    { name: 'Ember Spirit', 'Gyrocopter': -0.132 },
    { name: 'Enchantress', 'Gyrocopter': 0.175 },
    { name: 'Enigma', 'Gyrocopter': -1.725 },
    { name: 'Faceless Void', 'Gyrocopter': 0.632 },
    { name: 'Grimstroke', 'Gyrocopter': -1.2 },
    { name: 'Hoodwink', 'Gyrocopter': -0.503 },
    { name: 'Huskar', 'Gyrocopter': 0.942 },
    { name: 'Invoker', 'Gyrocopter': -0.723 },
    { name: 'Io', 'Gyrocopter': -0.028 },
    { name: 'Jakiro', 'Gyrocopter': 0.194 },
    { name: 'Juggernaut', 'Gyrocopter': 1.45 },
    { name: 'Keeper of the Light', 'Gyrocopter': -0.377 },
    { name: 'Kunkka', 'Gyrocopter': 0.672 },
    { name: 'Legion Commander', 'Gyrocopter': 0.449 },
    { name: 'Leshrac', 'Gyrocopter': -0.962 },
    { name: 'Lich', 'Gyrocopter': -0.008 },
    { name: 'Lifestealer', 'Gyrocopter': 1.26 },
    { name: 'Lina', 'Gyrocopter': 0.116 },
    { name: 'Lion', 'Gyrocopter': -1.071 },
    { name: 'Lone Druid', 'Gyrocopter': -1.317 },
    { name: 'Luna', 'Gyrocopter': -0.138 },
    { name: 'Lycan', 'Gyrocopter': -0.718 },
    { name: 'Magnus', 'Gyrocopter': -0.879 },
    { name: 'Marci', 'Gyrocopter': -0.399 },
    { name: 'Mars', 'Gyrocopter': 1.069 },
    { name: 'Medusa', 'Gyrocopter': -0.132 },
    { name: 'Meepo', 'Gyrocopter': 0.792 },
    { name: 'Mirana', 'Gyrocopter': 0.322 },
    { name: 'Monkey King', 'Gyrocopter': -0.539 },
    { name: 'Morphling', 'Gyrocopter': -0.535 },
    { name: 'Muerta', 'Gyrocopter': 0.135 },
    { name: 'Naga Siren', 'Gyrocopter': -1.21 },
    { name: "Nature's Prophet", 'Gyrocopter': 0.243 },
    { name: 'Necrophos', 'Gyrocopter': -0.563 },
    { name: 'Night Stalker', 'Gyrocopter': -2.18 },
    { name: 'Nyx Assassin', 'Gyrocopter': 0.203 },
    { name: 'Ogre Magi', 'Gyrocopter': 0.63 },
    { name: 'Omniknight', 'Gyrocopter': -1.341 },
    { name: 'Oracle', 'Gyrocopter': 1.181 },
    { name: 'Outworld Destroyer', 'Gyrocopter': 1.237 },
    { name: 'Pangolier', 'Gyrocopter': -1.493 },
    { name: 'Phantom Assassin', 'Gyrocopter': -0.069 },
    { name: 'Phantom Lancer', 'Gyrocopter': 0.103 },
    { name: 'Phoenix', 'Gyrocopter': 0.536 },
    { name: 'Primal Beast', 'Gyrocopter': -0.452 },
    { name: 'Puck', 'Gyrocopter': -0.873 },
    { name: 'Pudge', 'Gyrocopter': 0.641 },
    { name: 'Pugna', 'Gyrocopter': -0.03 },
    { name: 'Queen of Pain', 'Gyrocopter': 0.388 },
    { name: 'Razor', 'Gyrocopter': 0.383 },
    { name: 'Riki', 'Gyrocopter': -0.11 },
    { name: 'Ringmaster', 'Gyrocopter': 0.222 },
    { name: 'Rubick', 'Gyrocopter': -0.519 },
    { name: 'Sand King', 'Gyrocopter': -0.277 },
    { name: 'Shadow Demon', 'Gyrocopter': 0.434 },
    { name: 'Shadow Fiend', 'Gyrocopter': -1.177 },
    { name: 'Shadow Shaman', 'Gyrocopter': -1.696 },
    { name: 'Silencer', 'Gyrocopter': 0.403 },
    { name: 'Skywrath Mage', 'Gyrocopter': 0.494 },
    { name: 'Slardar', 'Gyrocopter': -1.252 },
    { name: 'Slark', 'Gyrocopter': -1.106 },
    { name: 'Snapfire', 'Gyrocopter': 0.097 },
    { name: 'Sniper', 'Gyrocopter': 0.999 },
    { name: 'Spectre', 'Gyrocopter': 1.144 },
    { name: 'Spirit Breaker', 'Gyrocopter': 0.342 },
    { name: 'Storm Spirit', 'Gyrocopter': 0.212 },
    { name: 'Sven', 'Gyrocopter': -0.149 },
    { name: 'Techies', 'Gyrocopter': 0.235 },
    { name: 'Templar Assassin', 'Gyrocopter': -0.693 },
    { name: 'Terrorblade', 'Gyrocopter': -0.236 },
    { name: 'Tidehunter', 'Gyrocopter': 0.521 },
    { name: 'Timbersaw', 'Gyrocopter': -0.769 },
    { name: 'Tinker', 'Gyrocopter': 0.787 },
    { name: 'Tiny', 'Gyrocopter': -0.923 },
    { name: 'Treant Protector', 'Gyrocopter': 0.007 },
    { name: 'Troll Warlord', 'Gyrocopter': -0.809 },
    { name: 'Tusk', 'Gyrocopter': -0.425 },
    { name: 'Underlord', 'Gyrocopter': 1.328 },
    { name: 'Undying', 'Gyrocopter': -1.144 },
    { name: 'Ursa', 'Gyrocopter': 0.222 },
    { name: 'Vengeful Spirit', 'Gyrocopter': -0.131 },
    { name: 'Venomancer', 'Gyrocopter': 0.678 },
    { name: 'Viper', 'Gyrocopter': 1.458 },
    { name: 'Visage', 'Gyrocopter': -0.289 },
    { name: 'Void Spirit', 'Gyrocopter': -0.721 },
    { name: 'Warlock', 'Gyrocopter': 0.22 },
    { name: 'Weaver', 'Gyrocopter': -0.979 },
    { name: 'Windranger', 'Gyrocopter': -0.239 },
    { name: 'Winter Wyvern', 'Gyrocopter': -1.501 },
    { name: 'Witch Doctor', 'Gyrocopter': 0.65 },
    { name: 'Wraith King', 'Gyrocopter': 0.126 },
    { name: 'Zeus', 'Gyrocopter': 0.308 },
],
"Hoodwink": [
    { name: 'Abaddon', 'Hoodwink': -0.845 },
    { name: 'Alchemist', 'Hoodwink': -0.905 },
    { name: 'Ancient Apparition', 'Hoodwink': -1.262 },
    { name: 'Anti-Mage', 'Hoodwink': -2.725 },
    { name: 'Arc Warden', 'Hoodwink': 0.207 },
    { name: 'Axe', 'Hoodwink': 1.254 },
    { name: 'Bane', 'Hoodwink': 1.21 },
    { name: 'Batrider', 'Hoodwink': 0.238 },
    { name: 'Beastmaster', 'Hoodwink': -0.232 },
    { name: 'Bloodseeker', 'Hoodwink': -0.419 },
    { name: 'Bounty Hunter', 'Hoodwink': 0.203 },
    { name: 'Brewmaster', 'Hoodwink': 0.413 },
    { name: 'Bristleback', 'Hoodwink': -3.764 },
    { name: 'Broodmother', 'Hoodwink': 0.068 },
    { name: 'Centaur Warrunner', 'Hoodwink': 1.5 },
    { name: 'Chaos Knight', 'Hoodwink': -0.894 },
    { name: 'Chen', 'Hoodwink': 1.062 },
    { name: 'Clinkz', 'Hoodwink': -0.252 },
    { name: 'Clockwerk', 'Hoodwink': 0.344 },
    { name: 'Crystal Maiden', 'Hoodwink': -0.655 },
    { name: 'Dark Seer', 'Hoodwink': -1.549 },
    { name: 'Dark Willow', 'Hoodwink': -0.729 },
    { name: 'Dawnbreaker', 'Hoodwink': -0.161 },
    { name: 'Dazzle', 'Hoodwink': -0.142 },
    { name: 'Death Prophet', 'Hoodwink': -1.103 },
    { name: 'Disruptor', 'Hoodwink': -0.838 },
    { name: 'Doom', 'Hoodwink': -0.551 },
    { name: 'Dragon Knight', 'Hoodwink': -0.087 },
    { name: 'Drow Ranger', 'Hoodwink': -0.039 },
    { name: 'Earth Spirit', 'Hoodwink': 1.182 },
    { name: 'Earthshaker', 'Hoodwink': -0.805 },
    { name: 'Elder Titan', 'Hoodwink': -2.139 },
    { name: 'Ember Spirit', 'Hoodwink': -0.08 },
    { name: 'Enchantress', 'Hoodwink': -0.744 },
    { name: 'Enigma', 'Hoodwink': -0.129 },
    { name: 'Faceless Void', 'Hoodwink': -0.762 },
    { name: 'Grimstroke', 'Hoodwink': -0.268 },
    { name: 'Gyrocopter', 'Hoodwink': 0.424 },
    { name: 'Huskar', 'Hoodwink': -1.759 },
    { name: 'Invoker', 'Hoodwink': -1.135 },
    { name: 'Io', 'Hoodwink': -0.877 },
    { name: 'Jakiro', 'Hoodwink': -0.463 },
    { name: 'Juggernaut', 'Hoodwink': -0.29 },
    { name: 'Keeper of the Light', 'Hoodwink': -0.066 },
    { name: 'Kunkka', 'Hoodwink': -0.467 },
    { name: 'Legion Commander', 'Hoodwink': 0.037 },
    { name: 'Leshrac', 'Hoodwink': -0.915 },
    { name: 'Lich', 'Hoodwink': -0.701 },
    { name: 'Lifestealer', 'Hoodwink': -0.03 },
    { name: 'Lina', 'Hoodwink': -0.546 },
    { name: 'Lion', 'Hoodwink': -0.317 },
    { name: 'Lone Druid', 'Hoodwink': -1.558 },
    { name: 'Luna', 'Hoodwink': -0.36 },
    { name: 'Lycan', 'Hoodwink': -0.829 },
    { name: 'Magnus', 'Hoodwink': 0.063 },
    { name: 'Marci', 'Hoodwink': -1.139 },
    { name: 'Mars', 'Hoodwink': 1.207 },
    { name: 'Medusa', 'Hoodwink': -1.05 },
    { name: 'Meepo', 'Hoodwink': -0.049 },
    { name: 'Mirana', 'Hoodwink': 0.122 },
    { name: 'Monkey King', 'Hoodwink': -0.549 },
    { name: 'Morphling', 'Hoodwink': 0.238 },
    { name: 'Muerta', 'Hoodwink': -0.224 },
    { name: 'Naga Siren', 'Hoodwink': 0.207 },
    { name: "Nature's Prophet", 'Hoodwink': -0.536 },
    { name: 'Necrophos', 'Hoodwink': -0.966 },
    { name: 'Night Stalker', 'Hoodwink': -0.064 },
    { name: 'Nyx Assassin', 'Hoodwink': -0.422 },
    { name: 'Ogre Magi', 'Hoodwink': 0.795 },
    { name: 'Omniknight', 'Hoodwink': 0.31 },
    { name: 'Oracle', 'Hoodwink': -0.375 },
    { name: 'Outworld Destroyer', 'Hoodwink': -0.51 },
    { name: 'Pangolier', 'Hoodwink': -0.214 },
    { name: 'Phantom Assassin', 'Hoodwink': 1.046 },
    { name: 'Phantom Lancer', 'Hoodwink': 0.989 },
    { name: 'Phoenix', 'Hoodwink': -0.542 },
    { name: 'Primal Beast', 'Hoodwink': 1.443 },
    { name: 'Puck', 'Hoodwink': 1.081 },
    { name: 'Pudge', 'Hoodwink': -0.729 },
    { name: 'Pugna', 'Hoodwink': -0.206 },
    { name: 'Queen of Pain', 'Hoodwink': 0.34 },
    { name: 'Razor', 'Hoodwink': -0.311 },
    { name: 'Riki', 'Hoodwink': 0.29 },
    { name: 'Ringmaster', 'Hoodwink': -0.283 },
    { name: 'Rubick', 'Hoodwink': -0.767 },
    { name: 'Sand King', 'Hoodwink': -0.646 },
    { name: 'Shadow Demon', 'Hoodwink': 0.325 },
    { name: 'Shadow Fiend', 'Hoodwink': -1.023 },
    { name: 'Shadow Shaman', 'Hoodwink': -0.846 },
    { name: 'Silencer', 'Hoodwink': -0.85 },
    { name: 'Skywrath Mage', 'Hoodwink': -0.132 },
    { name: 'Slardar', 'Hoodwink': -0.303 },
    { name: 'Slark', 'Hoodwink': -0.353 },
    { name: 'Snapfire', 'Hoodwink': 0.078 },
    { name: 'Sniper', 'Hoodwink': 0.051 },
    { name: 'Spectre', 'Hoodwink': 0.432 },
    { name: 'Spirit Breaker', 'Hoodwink': 1.386 },
    { name: 'Storm Spirit', 'Hoodwink': -0.075 },
    { name: 'Sven', 'Hoodwink': 0.311 },
    { name: 'Techies', 'Hoodwink': 0.113 },
    { name: 'Templar Assassin', 'Hoodwink': -0.181 },
    { name: 'Terrorblade', 'Hoodwink': 0.227 },
    { name: 'Tidehunter', 'Hoodwink': -0.448 },
    { name: 'Timbersaw', 'Hoodwink': 1.263 },
    { name: 'Tinker', 'Hoodwink': -0.5 },
    { name: 'Tiny', 'Hoodwink': 0.777 },
    { name: 'Treant Protector', 'Hoodwink': -0.511 },
    { name: 'Troll Warlord', 'Hoodwink': -0.747 },
    { name: 'Tusk', 'Hoodwink': -0.3 },
    { name: 'Underlord', 'Hoodwink': -1.451 },
    { name: 'Undying', 'Hoodwink': -0.401 },
    { name: 'Ursa', 'Hoodwink': -0.543 },
    { name: 'Vengeful Spirit', 'Hoodwink': 0.341 },
    { name: 'Venomancer', 'Hoodwink': -0.091 },
    { name: 'Viper', 'Hoodwink': -0.776 },
    { name: 'Visage', 'Hoodwink': -1.693 },
    { name: 'Void Spirit', 'Hoodwink': 0.621 },
    { name: 'Warlock', 'Hoodwink': -1.096 },
    { name: 'Weaver', 'Hoodwink': 0.106 },
    { name: 'Windranger', 'Hoodwink': 0.803 },
    { name: 'Winter Wyvern', 'Hoodwink': -0.241 },
    { name: 'Witch Doctor', 'Hoodwink': -1.142 },
    { name: 'Wraith King', 'Hoodwink': -0.021 },
    { name: 'Zeus', 'Hoodwink': 0.435 },
],
"Juggernaut": [
    { name: 'Abaddon', 'Juggernaut': -0.097 },
    { name: 'Alchemist', 'Juggernaut': -0.587 },
    { name: 'Ancient Apparition', 'Juggernaut': -0.17 },
    { name: 'Anti-Mage', 'Juggernaut': 2.634 },
    { name: 'Arc Warden', 'Juggernaut': -0.63 },
    { name: 'Axe', 'Juggernaut': 0.596 },
    { name: 'Bane', 'Juggernaut': 0.885 },
    { name: 'Batrider', 'Juggernaut': 0.515 },
    { name: 'Beastmaster', 'Juggernaut': 1.32 },
    { name: 'Bloodseeker', 'Juggernaut': -2.706 },
    { name: 'Bounty Hunter', 'Juggernaut': -0.327 },
    { name: 'Brewmaster', 'Juggernaut': -0.35 },
    { name: 'Bristleback', 'Juggernaut': -2.698 },
    { name: 'Broodmother', 'Juggernaut': 2.057 },
    { name: 'Centaur Warrunner', 'Juggernaut': -1.917 },
    { name: 'Chaos Knight', 'Juggernaut': -0.009 },
    { name: 'Chen', 'Juggernaut': 2.895 },
    { name: 'Clinkz', 'Juggernaut': 2.283 },
    { name: 'Clockwerk', 'Juggernaut': -1.657 },
    { name: 'Crystal Maiden', 'Juggernaut': -1.382 },
    { name: 'Dark Seer', 'Juggernaut': 0.059 },
    { name: 'Dark Willow', 'Juggernaut': -1.256 },
    { name: 'Dawnbreaker', 'Juggernaut': -0.52 },
    { name: 'Dazzle', 'Juggernaut': 0.792 },
    { name: 'Death Prophet', 'Juggernaut': 2.011 },
    { name: 'Disruptor', 'Juggernaut': 1.308 },
    { name: 'Doom', 'Juggernaut': 1.484 },
    { name: 'Dragon Knight', 'Juggernaut': 0.542 },
    { name: 'Drow Ranger', 'Juggernaut': 0.875 },
    { name: 'Earth Spirit', 'Juggernaut': -1.252 },
    { name: 'Earthshaker', 'Juggernaut': -0.114 },
    { name: 'Elder Titan', 'Juggernaut': -0.328 },
    { name: 'Ember Spirit', 'Juggernaut': -1.165 },
    { name: 'Enchantress', 'Juggernaut': 0.97 },
    { name: 'Enigma', 'Juggernaut': 0.93 },
    { name: 'Faceless Void', 'Juggernaut': 1.335 },
    { name: 'Grimstroke', 'Juggernaut': 1.314 },
    { name: 'Gyrocopter', 'Juggernaut': -0.706 },
    { name: 'Hoodwink', 'Juggernaut': 0.442 },
    { name: 'Huskar', 'Juggernaut': 1.537 },
    { name: 'Invoker', 'Juggernaut': 0.607 },
    { name: 'Io', 'Juggernaut': 1.653 },
    { name: 'Jakiro', 'Juggernaut': -0.837 },
    { name: 'Keeper of the Light', 'Juggernaut': 0.058 },
    { name: 'Kunkka', 'Juggernaut': -1.515 },
    { name: 'Legion Commander', 'Juggernaut': 0.777 },
    { name: 'Leshrac', 'Juggernaut': 0.1 },
    { name: 'Lich', 'Juggernaut': -0.565 },
    { name: 'Lifestealer', 'Juggernaut': -1.102 },
    { name: 'Lina', 'Juggernaut': 0.15 },
    { name: 'Lion', 'Juggernaut': 1.817 },
    { name: 'Lone Druid', 'Juggernaut': 2.181 },
    { name: 'Luna', 'Juggernaut': -0.31 },
    { name: 'Lycan', 'Juggernaut': 1.809 },
    { name: 'Magnus', 'Juggernaut': -0.443 },
    { name: 'Marci', 'Juggernaut': 0.013 },
    { name: 'Mars', 'Juggernaut': -0.28 },
    { name: 'Medusa', 'Juggernaut': -0.975 },
    { name: 'Meepo', 'Juggernaut': 1.211 },
    { name: 'Mirana', 'Juggernaut': 0.712 },
    { name: 'Monkey King', 'Juggernaut': -0.034 },
    { name: 'Morphling', 'Juggernaut': 3.279 },
    { name: 'Muerta', 'Juggernaut': 2.563 },
    { name: 'Naga Siren', 'Juggernaut': -1.303 },
    { name: "Nature's Prophet", 'Juggernaut': 2.295 },
    { name: 'Necrophos', 'Juggernaut': 0.406 },
    { name: 'Night Stalker', 'Juggernaut': 2.534 },
    { name: 'Nyx Assassin', 'Juggernaut': -0.872 },
    { name: 'Ogre Magi', 'Juggernaut': -0.981 },
    { name: 'Omniknight', 'Juggernaut': -0.706 },
    { name: 'Oracle', 'Juggernaut': 0.525 },
    { name: 'Outworld Destroyer', 'Juggernaut': 0.39 },
    { name: 'Pangolier', 'Juggernaut': 1.131 },
    { name: 'Phantom Assassin', 'Juggernaut': -0.277 },
    { name: 'Phantom Lancer', 'Juggernaut': 0.87 },
    { name: 'Phoenix', 'Juggernaut': -2.119 },
    { name: 'Primal Beast', 'Juggernaut': -0.942 },
    { name: 'Puck', 'Juggernaut': 1.339 },
    { name: 'Pudge', 'Juggernaut': -0.07 },
    { name: 'Pugna', 'Juggernaut': 1.383 },
    { name: 'Queen of Pain', 'Juggernaut': 1.106 },
    { name: 'Razor', 'Juggernaut': 0.736 },
    { name: 'Riki', 'Juggernaut': 2.185 },
    { name: 'Ringmaster', 'Juggernaut': -1.344 },
    { name: 'Rubick', 'Juggernaut': -0.132 },
    { name: 'Sand King', 'Juggernaut': -1.704 },
    { name: 'Shadow Demon', 'Juggernaut': 0.004 },
    { name: 'Shadow Fiend', 'Juggernaut': 0.144 },
    { name: 'Shadow Shaman', 'Juggernaut': 0.517 },
    { name: 'Silencer', 'Juggernaut': 0.692 },
    { name: 'Skywrath Mage', 'Juggernaut': 1.848 },
    { name: 'Slardar', 'Juggernaut': -1.371 },
    { name: 'Slark', 'Juggernaut': 1.054 },
    { name: 'Snapfire', 'Juggernaut': 0.81 },
    { name: 'Sniper', 'Juggernaut': 0.652 },
    { name: 'Spectre', 'Juggernaut': -1.156 },
    { name: 'Spirit Breaker', 'Juggernaut': 1.678 },
    { name: 'Storm Spirit', 'Juggernaut': 1.279 },
    { name: 'Sven', 'Juggernaut': 0.451 },
    { name: 'Techies', 'Juggernaut': -0.657 },
    { name: 'Templar Assassin', 'Juggernaut': 1.817 },
    { name: 'Terrorblade', 'Juggernaut': 0.435 },
    { name: 'Tidehunter', 'Juggernaut': -2.377 },
    { name: 'Timbersaw', 'Juggernaut': -0.172 },
    { name: 'Tinker', 'Juggernaut': -0.836 },
    { name: 'Tiny', 'Juggernaut': 0.033 },
    { name: 'Treant Protector', 'Juggernaut': -1.976 },
    { name: 'Troll Warlord', 'Juggernaut': 0.39 },
    { name: 'Tusk', 'Juggernaut': 1.183 },
    { name: 'Underlord', 'Juggernaut': -3.148 },
    { name: 'Undying', 'Juggernaut': -0.428 },
    { name: 'Ursa', 'Juggernaut': 1.543 },
    { name: 'Vengeful Spirit', 'Juggernaut': -0.47 },
    { name: 'Venomancer', 'Juggernaut': -0.83 },
    { name: 'Viper', 'Juggernaut': -1.827 },
    { name: 'Visage', 'Juggernaut': 3.088 },
    { name: 'Void Spirit', 'Juggernaut': 1.98 },
    { name: 'Warlock', 'Juggernaut': -0.174 },
    { name: 'Weaver', 'Juggernaut': 1.765 },
    { name: 'Windranger', 'Juggernaut': 1.261 },
    { name: 'Winter Wyvern', 'Juggernaut': 0.089 },
    { name: 'Witch Doctor', 'Juggernaut': 0.839 },
    { name: 'Wraith King', 'Juggernaut': -0.069 },
    { name: 'Zeus', 'Juggernaut': -0.292 },
],
"Luna": [
    { name: 'Abaddon', 'Luna': 0.601 },
    { name: 'Alchemist', 'Luna': -0.069 },
    { name: 'Ancient Apparition', 'Luna': 1.324 },
    { name: 'Anti-Mage', 'Luna': -0.954 },
    { name: 'Arc Warden', 'Luna': -1.347 },
    { name: 'Axe', 'Luna': -0.4 },
    { name: 'Bane', 'Luna': 0.112 },
    { name: 'Batrider', 'Luna': 0.141 },
    { name: 'Beastmaster', 'Luna': -0.393 },
    { name: 'Bloodseeker', 'Luna': -0.492 },
    { name: 'Bounty Hunter', 'Luna': 0.056 },
    { name: 'Brewmaster', 'Luna': -0.379 },
    { name: 'Bristleback', 'Luna': 2.526 },
    { name: 'Broodmother', 'Luna': 0.881 },
    { name: 'Centaur Warrunner', 'Luna': 0.921 },
    { name: 'Chaos Knight', 'Luna': -0.841 },
    { name: 'Chen', 'Luna': -0.276 },
    { name: 'Clinkz', 'Luna': -0.97 },
    { name: 'Clockwerk', 'Luna': 0.339 },
    { name: 'Crystal Maiden', 'Luna': -0.63 },
    { name: 'Dark Seer', 'Luna': 2.228 },
    { name: 'Dark Willow', 'Luna': -0.006 },
    { name: 'Dawnbreaker', 'Luna': -0.017 },
    { name: 'Dazzle', 'Luna': -0.046 },
    { name: 'Death Prophet', 'Luna': -1.441 },
    { name: 'Disruptor', 'Luna': -0.827 },
    { name: 'Doom', 'Luna': -0.421 },
    { name: 'Dragon Knight', 'Luna': 0.043 },
    { name: 'Drow Ranger', 'Luna': 0.319 },
    { name: 'Earth Spirit', 'Luna': -0.435 },
    { name: 'Earthshaker', 'Luna': -0.57 },
    { name: 'Elder Titan', 'Luna': 0.842 },
    { name: 'Ember Spirit', 'Luna': 0.547 },
    { name: 'Enchantress', 'Luna': 0.682 },
    { name: 'Enigma', 'Luna': 0.099 },
    { name: 'Faceless Void', 'Luna': -0.291 },
    { name: 'Grimstroke', 'Luna': -0.527 },
    { name: 'Gyrocopter', 'Luna': 0.185 },
    { name: 'Hoodwink', 'Luna': 0.157 },
    { name: 'Huskar', 'Luna': -1.432 },
    { name: 'Invoker', 'Luna': 0.632 },
    { name: 'Io', 'Luna': -1.374 },
    { name: 'Jakiro', 'Luna': 0.572 },
    { name: 'Juggernaut', 'Luna': 0.598 },
    { name: 'Keeper of the Light', 'Luna': 1.099 },
    { name: 'Kunkka', 'Luna': 1.831 },
    { name: 'Legion Commander', 'Luna': -0.445 },
    { name: 'Leshrac', 'Luna': -1.111 },
    { name: 'Lich', 'Luna': 0.572 },
    { name: 'Lifestealer', 'Luna': 0.754 },
    { name: 'Lina', 'Luna': 1.125 },
    { name: 'Lion', 'Luna': -0.7 },
    { name: 'Lone Druid', 'Luna': -1.393 },
    { name: 'Lycan', 'Luna': -1.172 },
    { name: 'Magnus', 'Luna': -0.566 },
    { name: 'Marci', 'Luna': -0.453 },
    { name: 'Mars', 'Luna': 0.363 },
    { name: 'Medusa', 'Luna': 1.747 },
    { name: 'Meepo', 'Luna': 0.346 },
    { name: 'Mirana', 'Luna': -0.264 },
    { name: 'Monkey King', 'Luna': -0.696 },
    { name: 'Morphling', 'Luna': -1.014 },
    { name: 'Muerta', 'Luna': 0.35 },
    { name: 'Naga Siren', 'Luna': -1.864 },
    { name: "Nature's Prophet", 'Luna': -0.61 },
    { name: 'Necrophos', 'Luna': -0.122 },
    { name: 'Night Stalker', 'Luna': -2.331 },
    { name: 'Nyx Assassin', 'Luna': 1.42 },
    { name: 'Ogre Magi', 'Luna': -0.269 },
    { name: 'Omniknight', 'Luna': -0.995 },
    { name: 'Oracle', 'Luna': 0.338 },
    { name: 'Outworld Destroyer', 'Luna': -0.633 },
    { name: 'Pangolier', 'Luna': -1.007 },
    { name: 'Phantom Assassin', 'Luna': -1.112 },
    { name: 'Phantom Lancer', 'Luna': 0.858 },
    { name: 'Phoenix', 'Luna': -0.612 },
    { name: 'Primal Beast', 'Luna': 0.77 },
    { name: 'Puck', 'Luna': -1.065 },
    { name: 'Pudge', 'Luna': 0.336 },
    { name: 'Pugna', 'Luna': -0.04 },
    { name: 'Queen of Pain', 'Luna': 0.717 },
    { name: 'Razor', 'Luna': -0.821 },
    { name: 'Riki', 'Luna': -1.59 },
    { name: 'Ringmaster', 'Luna': 0.342 },
    { name: 'Rubick', 'Luna': 1.177 },
    { name: 'Sand King', 'Luna': 0.837 },
    { name: 'Shadow Demon', 'Luna': 1.455 },
    { name: 'Shadow Fiend', 'Luna': -1.423 },
    { name: 'Shadow Shaman', 'Luna': -1.279 },
    { name: 'Silencer', 'Luna': -0.554 },
    { name: 'Skywrath Mage', 'Luna': 0.351 },
    { name: 'Slardar', 'Luna': -2.705 },
    { name: 'Slark', 'Luna': 0.347 },
    { name: 'Snapfire', 'Luna': 1.012 },
    { name: 'Sniper', 'Luna': 0.838 },
    { name: 'Spectre', 'Luna': 2.204 },
    { name: 'Spirit Breaker', 'Luna': 0.372 },
    { name: 'Storm Spirit', 'Luna': -0.1 },
    { name: 'Sven', 'Luna': -0.26 },
    { name: 'Techies', 'Luna': -0.115 },
    { name: 'Templar Assassin', 'Luna': 0.147 },
    { name: 'Terrorblade', 'Luna': 0.689 },
    { name: 'Tidehunter', 'Luna': 0.852 },
    { name: 'Timbersaw', 'Luna': -0.374 },
    { name: 'Tinker', 'Luna': -0.913 },
    { name: 'Tiny', 'Luna': -0.247 },
    { name: 'Treant Protector', 'Luna': 0.249 },
    { name: 'Troll Warlord', 'Luna': -0.144 },
    { name: 'Tusk', 'Luna': -0.813 },
    { name: 'Underlord', 'Luna': 2.133 },
    { name: 'Undying', 'Luna': -2.887 },
    { name: 'Ursa', 'Luna': 0.527 },
    { name: 'Vengeful Spirit', 'Luna': -1.034 },
    { name: 'Venomancer', 'Luna': 0.712 },
    { name: 'Viper', 'Luna': 0.295 },
    { name: 'Visage', 'Luna': 0.082 },
    { name: 'Void Spirit', 'Luna': -0.861 },
    { name: 'Warlock', 'Luna': 1.449 },
    { name: 'Weaver', 'Luna': -1.743 },
    { name: 'Windranger', 'Luna': -0.712 },
    { name: 'Winter Wyvern', 'Luna': -0.793 },
    { name: 'Witch Doctor', 'Luna': 1.111 },
    { name: 'Wraith King', 'Luna': 0.368 },
    { name: 'Zeus', 'Luna': 1.209 },
],
"Medusa": [
    { name: 'Abaddon', 'Medusa': -0.44 },
    { name: 'Alchemist', 'Medusa': 3.07 },
    { name: 'Ancient Apparition', 'Medusa': -1.244 },
    { name: 'Anti-Mage', 'Medusa': 9.745 },
    { name: 'Arc Warden', 'Medusa': -1.159 },
    { name: 'Axe', 'Medusa': 0.97 },
    { name: 'Bane', 'Medusa': 0.314 },
    { name: 'Batrider', 'Medusa': -0.134 },
    { name: 'Beastmaster', 'Medusa': -4.554 },
    { name: 'Bloodseeker', 'Medusa': -5.293 },
    { name: 'Bounty Hunter', 'Medusa': 0.516 },
    { name: 'Brewmaster', 'Medusa': -0.073 },
    { name: 'Bristleback', 'Medusa': 1.317 },
    { name: 'Broodmother', 'Medusa': 1.484 },
    { name: 'Centaur Warrunner', 'Medusa': 3.578 },
    { name: 'Chaos Knight', 'Medusa': -2.699 },
    { name: 'Chen', 'Medusa': 2.265 },
    { name: 'Clinkz', 'Medusa': -0.85 },
    { name: 'Clockwerk', 'Medusa': 1.789 },
    { name: 'Crystal Maiden', 'Medusa': 0.04 },
    { name: 'Dark Seer', 'Medusa': 6.559 },
    { name: 'Dark Willow', 'Medusa': 1.066 },
    { name: 'Dawnbreaker', 'Medusa': 0.244 },
    { name: 'Dazzle', 'Medusa': 1.632 },
    { name: 'Death Prophet', 'Medusa': -1.373 },
    { name: 'Disruptor', 'Medusa': -0.496 },
    { name: 'Doom', 'Medusa': -1.555 },
    { name: 'Dragon Knight', 'Medusa': 0.683 },
    { name: 'Drow Ranger', 'Medusa': -0.095 },
    { name: 'Earth Spirit', 'Medusa': 1.185 },
    { name: 'Earthshaker', 'Medusa': 1.012 },
    { name: 'Elder Titan', 'Medusa': -0.067 },
    { name: 'Ember Spirit', 'Medusa': 2.68 },
    { name: 'Enchantress', 'Medusa': -0.395 },
    { name: 'Enigma', 'Medusa': -3.393 },
    { name: 'Faceless Void', 'Medusa': 0.791 },
    { name: 'Grimstroke', 'Medusa': -1.083 },
    { name: 'Gyrocopter', 'Medusa': 0.352 },
    { name: 'Hoodwink', 'Medusa': 0.655 },
    { name: 'Huskar', 'Medusa': -0.292 },
    { name: 'Invoker', 'Medusa': 3.59 },
    { name: 'Io', 'Medusa': 0.087 },
    { name: 'Jakiro', 'Medusa': 0.719 },
    { name: 'Juggernaut', 'Medusa': 0.777 },
    { name: 'Keeper of the Light', 'Medusa': 1.391 },
    { name: 'Kunkka', 'Medusa': 1.859 },
    { name: 'Legion Commander', 'Medusa': -2.823 },
    { name: 'Leshrac', 'Medusa': -2.215 },
    { name: 'Lich', 'Medusa': 0.698 },
    { name: 'Lifestealer', 'Medusa': -0.875 },
    { name: 'Lina', 'Medusa': -0.958 },
    { name: 'Lion', 'Medusa': 0.958 },
    { name: 'Lone Druid', 'Medusa': 1.601 },
    { name: 'Luna', 'Medusa': -1.634 },
    { name: 'Lycan', 'Medusa': 0.601 },
    { name: 'Magnus', 'Medusa': 0.454 },
    { name: 'Marci', 'Medusa': -0.641 },
    { name: 'Mars', 'Medusa': 1.64 },
    { name: 'Meepo', 'Medusa': -2.595 },
    { name: 'Mirana', 'Medusa': -0.141 },
    { name: 'Monkey King', 'Medusa': 3.053 },
    { name: 'Morphling', 'Medusa': -1.414 },
    { name: 'Muerta', 'Medusa': -1.465 },
    { name: 'Naga Siren', 'Medusa': -1.323 },
    { name: "Nature's Prophet", 'Medusa': -0.258 },
    { name: 'Necrophos', 'Medusa': -1.713 },
    { name: 'Night Stalker', 'Medusa': -0.537 },
    { name: 'Nyx Assassin', 'Medusa': 1.394 },
    { name: 'Ogre Magi', 'Medusa': 0.347 },
    { name: 'Omniknight', 'Medusa': -1.103 },
    { name: 'Oracle', 'Medusa': 0.292 },
    { name: 'Outworld Destroyer', 'Medusa': -2.461 },
    { name: 'Pangolier', 'Medusa': 2.779 },
    { name: 'Phantom Assassin', 'Medusa': -0.634 },
    { name: 'Phantom Lancer', 'Medusa': 1.265 },
    { name: 'Phoenix', 'Medusa': -1.003 },
    { name: 'Primal Beast', 'Medusa': 0.012 },
    { name: 'Puck', 'Medusa': -0.773 },
    { name: 'Pudge', 'Medusa': -0.341 },
    { name: 'Pugna', 'Medusa': -1.332 },
    { name: 'Queen of Pain', 'Medusa': -3.931 },
    { name: 'Razor', 'Medusa': 0.789 },
    { name: 'Riki', 'Medusa': 1.624 },
    { name: 'Ringmaster', 'Medusa': -0.005 },
    { name: 'Rubick', 'Medusa': 1.85 },
    { name: 'Sand King', 'Medusa': 2.657 },
    { name: 'Shadow Demon', 'Medusa': 2.974 },
    { name: 'Shadow Fiend', 'Medusa': -1.638 },
    { name: 'Shadow Shaman', 'Medusa': -2.437 },
    { name: 'Silencer', 'Medusa': -1.433 },
    { name: 'Skywrath Mage', 'Medusa': -2.986 },
    { name: 'Slardar', 'Medusa': -2.034 },
    { name: 'Slark', 'Medusa': 3.681 },
    { name: 'Snapfire', 'Medusa': 1.72 },
    { name: 'Sniper', 'Medusa': 4.032 },
    { name: 'Spectre', 'Medusa': -0.884 },
    { name: 'Spirit Breaker', 'Medusa': -0.453 },
    { name: 'Storm Spirit', 'Medusa': -0.927 },
    { name: 'Sven', 'Medusa': 0.862 },
    { name: 'Techies', 'Medusa': -2.047 },
    { name: 'Templar Assassin', 'Medusa': -1.424 },
    { name: 'Terrorblade', 'Medusa': 1.58 },
    { name: 'Tidehunter', 'Medusa': 2.424 },
    { name: 'Timbersaw', 'Medusa': -3.405 },
    { name: 'Tinker', 'Medusa': 1.519 },
    { name: 'Tiny', 'Medusa': 0.617 },
    { name: 'Treant Protector', 'Medusa': 1.171 },
    { name: 'Troll Warlord', 'Medusa': -1.038 },
    { name: 'Tusk', 'Medusa': -0.044 },
    { name: 'Underlord', 'Medusa': 2.37 },
    { name: 'Undying', 'Medusa': -4.251 },
    { name: 'Ursa', 'Medusa': 0.961 },
    { name: 'Vengeful Spirit', 'Medusa': -0.766 },
    { name: 'Venomancer', 'Medusa': 0.318 },
    { name: 'Viper', 'Medusa': -2.022 },
    { name: 'Visage', 'Medusa': -1.991 },
    { name: 'Void Spirit', 'Medusa': -0.709 },
    { name: 'Warlock', 'Medusa': 1.497 },
    { name: 'Weaver', 'Medusa': -1.198 },
    { name: 'Windranger', 'Medusa': 1.579 },
    { name: 'Winter Wyvern', 'Medusa': 2.76 },
    { name: 'Witch Doctor', 'Medusa': -3.456 },
    { name: 'Wraith King', 'Medusa': -2.228 },
    { name: 'Zeus', 'Medusa': -1.949 },
],
"Meepo": [
    { name: 'Abaddon', 'Meepo': -1.837 },
    { name: 'Alchemist', 'Meepo': 2.401 },
    { name: 'Ancient Apparition', 'Meepo': 1.247 },
    { name: 'Anti-Mage', 'Meepo': -7.799 },
    { name: 'Arc Warden', 'Meepo': -5.186 },
    { name: 'Axe', 'Meepo': 1.356 },
    { name: 'Bane', 'Meepo': -3.314 },
    { name: 'Batrider', 'Meepo': -2.056 },
    { name: 'Beastmaster', 'Meepo': 0.747 },
    { name: 'Bloodseeker', 'Meepo': 0.448 },
    { name: 'Bounty Hunter', 'Meepo': -3.219 },
    { name: 'Brewmaster', 'Meepo': -0.811 },
    { name: 'Bristleback', 'Meepo': 4.488 },
    { name: 'Broodmother', 'Meepo': -3.014 },
    { name: 'Centaur Warrunner', 'Meepo': -1.136 },
    { name: 'Chaos Knight', 'Meepo': -1.077 },
    { name: 'Chen', 'Meepo': 0.974 },
    { name: 'Clinkz', 'Meepo': -2.93 },
    { name: 'Clockwerk', 'Meepo': -2.568 },
    { name: 'Crystal Maiden', 'Meepo': 2.611 },
    { name: 'Dark Seer', 'Meepo': -0.207 },
    { name: 'Dark Willow', 'Meepo': 1.422 },
    { name: 'Dawnbreaker', 'Meepo': 3.946 },
    { name: 'Dazzle', 'Meepo': 2.813 },
    { name: 'Death Prophet', 'Meepo': 0.173 },
    { name: 'Disruptor', 'Meepo': 1.23 },
    { name: 'Doom', 'Meepo': -3.509 },
    { name: 'Dragon Knight', 'Meepo': -1.632 },
    { name: 'Drow Ranger', 'Meepo': 2.2 },
    { name: 'Earth Spirit', 'Meepo': -2.808 },
    { name: 'Earthshaker', 'Meepo': 8.116 },
    { name: 'Elder Titan', 'Meepo': 11.485 },
    { name: 'Ember Spirit', 'Meepo': -3.152 },
    { name: 'Enchantress', 'Meepo': 0.716 },
    { name: 'Enigma', 'Meepo': 1.962 },
    { name: 'Faceless Void', 'Meepo': -2.796 },
    { name: 'Grimstroke', 'Meepo': -1.878 },
    { name: 'Gyrocopter', 'Meepo': -0.85 },
    { name: 'Hoodwink', 'Meepo': -0.24 },
    { name: 'Huskar', 'Meepo': 3.887 },
    { name: 'Invoker', 'Meepo': -1.598 },
    { name: 'Io', 'Meepo': 0.583 },
    { name: 'Jakiro', 'Meepo': 5.154 },
    { name: 'Juggernaut', 'Meepo': -0.927 },
    { name: 'Keeper of the Light', 'Meepo': -0.906 },
    { name: 'Kunkka', 'Meepo': 2.676 },
    { name: 'Legion Commander', 'Meepo': 1.577 },
    { name: 'Leshrac', 'Meepo': 6.063 },
    { name: 'Lich', 'Meepo': 1.228 },
    { name: 'Lifestealer', 'Meepo': -1.588 },
    { name: 'Lina', 'Meepo': 4.525 },
    { name: 'Lion', 'Meepo': -0.081 },
    { name: 'Lone Druid', 'Meepo': 0.978 },
    { name: 'Luna', 'Meepo': -0.263 },
    { name: 'Lycan', 'Meepo': -0.931 },
    { name: 'Magnus', 'Meepo': 1.451 },
    { name: 'Marci', 'Meepo': 1.379 },
    { name: 'Mars', 'Meepo': -1.124 },
    { name: 'Medusa', 'Meepo': 3.074 },
    { name: 'Mirana', 'Meepo': -1.457 },
    { name: 'Monkey King', 'Meepo': 3.424 },
    { name: 'Morphling', 'Meepo': -3.672 },
    { name: 'Muerta', 'Meepo': 2.846 },
    { name: 'Naga Siren', 'Meepo': 2.002 },
    { name: "Nature's Prophet", 'Meepo': -2.095 },
    { name: 'Necrophos', 'Meepo': 1.024 },
    { name: 'Night Stalker', 'Meepo': -3.574 },
    { name: 'Nyx Assassin', 'Meepo': -2.448 },
    { name: 'Ogre Magi', 'Meepo': -3.404 },
    { name: 'Omniknight', 'Meepo': 0.595 },
    { name: 'Oracle', 'Meepo': -2.035 },
    { name: 'Outworld Destroyer', 'Meepo': -0.014 },
    { name: 'Pangolier', 'Meepo': 2.213 },
    { name: 'Phantom Assassin', 'Meepo': -0.749 },
    { name: 'Phantom Lancer', 'Meepo': -3.251 },
    { name: 'Phoenix', 'Meepo': -1.223 },
    { name: 'Primal Beast', 'Meepo': -1.17 },
    { name: 'Puck', 'Meepo': -0.616 },
    { name: 'Pudge', 'Meepo': -0.811 },
    { name: 'Pugna', 'Meepo': 0.399 },
    { name: 'Queen of Pain', 'Meepo': -2.312 },
    { name: 'Razor', 'Meepo': -0.352 },
    { name: 'Riki', 'Meepo': -0.153 },
    { name: 'Ringmaster', 'Meepo': 1.371 },
    { name: 'Rubick', 'Meepo': -1.735 },
    { name: 'Sand King', 'Meepo': 2.932 },
    { name: 'Shadow Demon', 'Meepo': -3.552 },
    { name: 'Shadow Fiend', 'Meepo': 4.879 },
    { name: 'Shadow Shaman', 'Meepo': -1.728 },
    { name: 'Silencer', 'Meepo': 1.381 },
    { name: 'Skywrath Mage', 'Meepo': -0.729 },
    { name: 'Slardar', 'Meepo': 0.96 },
    { name: 'Slark', 'Meepo': 0.672 },
    { name: 'Snapfire', 'Meepo': 1.582 },
    { name: 'Sniper', 'Meepo': -2.184 },
    { name: 'Spectre', 'Meepo': -4.396 },
    { name: 'Spirit Breaker', 'Meepo': -2.417 },
    { name: 'Storm Spirit', 'Meepo': -5.267 },
    { name: 'Sven', 'Meepo': 3.534 },
    { name: 'Techies', 'Meepo': 3.501 },
    { name: 'Templar Assassin', 'Meepo': -0.833 },
    { name: 'Terrorblade', 'Meepo': 0.896 },
    { name: 'Tidehunter', 'Meepo': -0.808 },
    { name: 'Timbersaw', 'Meepo': 4.681 },
    { name: 'Tinker', 'Meepo': -2.908 },
    { name: 'Tiny', 'Meepo': 0.641 },
    { name: 'Treant Protector', 'Meepo': 0.598 },
    { name: 'Troll Warlord', 'Meepo': 6.677 },
    { name: 'Tusk', 'Meepo': 0.034 },
    { name: 'Underlord', 'Meepo': 3.327 },
    { name: 'Undying', 'Meepo': -1.23 },
    { name: 'Ursa', 'Meepo': -0.156 },
    { name: 'Vengeful Spirit', 'Meepo': -1.355 },
    { name: 'Venomancer', 'Meepo': 1.297 },
    { name: 'Viper', 'Meepo': -0.569 },
    { name: 'Visage', 'Meepo': 1.467 },
    { name: 'Void Spirit', 'Meepo': -5.972 },
    { name: 'Warlock', 'Meepo': 3.399 },
    { name: 'Weaver', 'Meepo': 0.225 },
    { name: 'Windranger', 'Meepo': -0.064 },
    { name: 'Winter Wyvern', 'Meepo': 6.981 },
    { name: 'Witch Doctor', 'Meepo': 2.242 },
    { name: 'Wraith King', 'Meepo': -1.929 },
    { name: 'Zeus', 'Meepo': -4.153 },
],
"Monkey King": [
    { name: 'Abaddon', 'Monkey King': -1.925 },
    { name: 'Alchemist', 'Monkey King': -1.886 },
    { name: 'Ancient Apparition', 'Monkey King': 2.057 },
    { name: 'Anti-Mage', 'Monkey King': -2.315 },
    { name: 'Arc Warden', 'Monkey King': -0.569 },
    { name: 'Axe', 'Monkey King': -0.637 },
    { name: 'Bane', 'Monkey King': -0.221 },
    { name: 'Batrider', 'Monkey King': 2.592 },
    { name: 'Beastmaster', 'Monkey King': 0.379 },
    { name: 'Bloodseeker', 'Monkey King': -1.527 },
    { name: 'Bounty Hunter', 'Monkey King': 0.088 },
    { name: 'Brewmaster', 'Monkey King': 0.054 },
    { name: 'Bristleback', 'Monkey King': 0.118 },
    { name: 'Broodmother', 'Monkey King': -2.693 },
    { name: 'Centaur Warrunner', 'Monkey King': -1.42 },
    { name: 'Chaos Knight', 'Monkey King': -2.109 },
    { name: 'Chen', 'Monkey King': -0.02 },
    { name: 'Clinkz', 'Monkey King': 0.126 },
    { name: 'Clockwerk', 'Monkey King': -0.907 },
    { name: 'Crystal Maiden', 'Monkey King': -0.875 },
    { name: 'Dark Seer', 'Monkey King': -2.071 },
    { name: 'Dark Willow', 'Monkey King': 0.037 },
    { name: 'Dawnbreaker', 'Monkey King': -0.992 },
    { name: 'Dazzle', 'Monkey King': -0.872 },
    { name: 'Death Prophet', 'Monkey King': -0.729 },
    { name: 'Disruptor', 'Monkey King': 0.002 },
    { name: 'Doom', 'Monkey King': 0.149 },
    { name: 'Dragon Knight', 'Monkey King': -0.495 },
    { name: 'Drow Ranger', 'Monkey King': -0.358 },
    { name: 'Earth Spirit', 'Monkey King': -1.024 },
    { name: 'Earthshaker', 'Monkey King': -0.389 },
    { name: 'Elder Titan', 'Monkey King': -1.03 },
    { name: 'Ember Spirit', 'Monkey King': -3.994 },
    { name: 'Enchantress', 'Monkey King': 0.633 },
    { name: 'Enigma', 'Monkey King': 0.36 },
    { name: 'Faceless Void', 'Monkey King': -0.693 },
    { name: 'Grimstroke', 'Monkey King': -0.628 },
    { name: 'Gyrocopter', 'Monkey King': 0.419 },
    { name: 'Hoodwink', 'Monkey King': 0.71 },
    { name: 'Huskar', 'Monkey King': -0.314 },
    { name: 'Invoker', 'Monkey King': 0.659 },
    { name: 'Io', 'Monkey King': -2.554 },
    { name: 'Jakiro', 'Monkey King': 0.739 },
    { name: 'Juggernaut', 'Monkey King': 0.738 },
    { name: 'Keeper of the Light', 'Monkey King': 0.556 },
    { name: 'Kunkka', 'Monkey King': -1.348 },
    { name: 'Legion Commander', 'Monkey King': -2.206 },
    { name: 'Leshrac', 'Monkey King': 0.894 },
    { name: 'Lich', 'Monkey King': 0.987 },
    { name: 'Lifestealer', 'Monkey King': -1.027 },
    { name: 'Lina', 'Monkey King': 0.255 },
    { name: 'Lion', 'Monkey King': -0.255 },
    { name: 'Lone Druid', 'Monkey King': -4.872 },
    { name: 'Luna', 'Monkey King': 0.606 },
    { name: 'Lycan', 'Monkey King': -2.846 },
    { name: 'Magnus', 'Monkey King': 0.093 },
    { name: 'Marci', 'Monkey King': -2.429 },
    { name: 'Mars', 'Monkey King': -0.933 },
    { name: 'Medusa', 'Monkey King': -3.087 },
    { name: 'Meepo', 'Monkey King': -3.488 },
    { name: 'Mirana', 'Monkey King': 0.411 },
    { name: 'Morphling', 'Monkey King': -0.251 },
    { name: 'Muerta', 'Monkey King': 2.917 },
    { name: 'Naga Siren', 'Monkey King': -1.103 },
    { name: "Nature's Prophet", 'Monkey King': -0.798 },
    { name: 'Necrophos', 'Monkey King': 0.255 },
    { name: 'Night Stalker', 'Monkey King': -1.045 },
    { name: 'Nyx Assassin', 'Monkey King': -0.051 },
    { name: 'Ogre Magi', 'Monkey King': 1.03 },
    { name: 'Omniknight', 'Monkey King': -1.667 },
    { name: 'Oracle', 'Monkey King': -1.103 },
    { name: 'Outworld Destroyer', 'Monkey King': 0.79 },
    { name: 'Pangolier', 'Monkey King': -3.647 },
    { name: 'Phantom Assassin', 'Monkey King': -0.15 },
    { name: 'Phantom Lancer', 'Monkey King': -0.391 },
    { name: 'Phoenix', 'Monkey King': 1.412 },
    { name: 'Primal Beast', 'Monkey King': -0.013 },
    { name: 'Puck', 'Monkey King': -0.948 },
    { name: 'Pudge', 'Monkey King': -1.122 },
    { name: 'Pugna', 'Monkey King': 0.481 },
    { name: 'Queen of Pain', 'Monkey King': 1.17 },
    { name: 'Razor', 'Monkey King': 0.011 },
    { name: 'Riki', 'Monkey King': -0.579 },
    { name: 'Ringmaster', 'Monkey King': 0.543 },
    { name: 'Rubick', 'Monkey King': -0.648 },
    { name: 'Sand King', 'Monkey King': -0.332 },
    { name: 'Shadow Demon', 'Monkey King': -0.663 },
    { name: 'Shadow Fiend', 'Monkey King': -0.312 },
    { name: 'Shadow Shaman', 'Monkey King': -0.239 },
    { name: 'Silencer', 'Monkey King': 0.073 },
    { name: 'Skywrath Mage', 'Monkey King': 0.299 },
    { name: 'Slardar', 'Monkey King': -0.367 },
    { name: 'Slark', 'Monkey King': 0.276 },
    { name: 'Snapfire', 'Monkey King': 1.147 },
    { name: 'Sniper', 'Monkey King': 1.359 },
    { name: 'Spectre', 'Monkey King': 0.547 },
    { name: 'Spirit Breaker', 'Monkey King': -0.058 },
    { name: 'Storm Spirit', 'Monkey King': 0.674 },
    { name: 'Sven', 'Monkey King': -1.522 },
    { name: 'Techies', 'Monkey King': 1.431 },
    { name: 'Templar Assassin', 'Monkey King': -0.172 },
    { name: 'Terrorblade', 'Monkey King': 0.54 },
    { name: 'Tidehunter', 'Monkey King': -1.586 },
    { name: 'Timbersaw', 'Monkey King': 0.962 },
    { name: 'Tinker', 'Monkey King': -1.464 },
    { name: 'Tiny', 'Monkey King': -0.915 },
    { name: 'Treant Protector', 'Monkey King': -0.759 },
    { name: 'Troll Warlord', 'Monkey King': -0.053 },
    { name: 'Tusk', 'Monkey King': -0.99 },
    { name: 'Underlord', 'Monkey King': -1.532 },
    { name: 'Undying', 'Monkey King': -1.053 },
    { name: 'Ursa', 'Monkey King': 0.074 },
    { name: 'Vengeful Spirit', 'Monkey King': -0.382 },
    { name: 'Venomancer', 'Monkey King': 1.343 },
    { name: 'Viper', 'Monkey King': 1.616 },
    { name: 'Visage', 'Monkey King': -0.522 },
    { name: 'Void Spirit', 'Monkey King': -3.092 },
    { name: 'Warlock', 'Monkey King': -0.916 },
    { name: 'Weaver', 'Monkey King': 0.245 },
    { name: 'Windranger', 'Monkey King': 1.102 },
    { name: 'Winter Wyvern', 'Monkey King': 0.14 },
    { name: 'Witch Doctor', 'Monkey King': 0.939 },
    { name: 'Wraith King', 'Monkey King': -1.63 },
    { name: 'Zeus', 'Monkey King': 2.586 },
],
"Morphling": [
    { name: 'Abaddon', 'Morphling': 0.745 },
    { name: 'Alchemist', 'Morphling': -0.406 },
    { name: 'Ancient Apparition', 'Morphling': 1.496 },
    { name: 'Anti-Mage', 'Morphling': 2.379 },
    { name: 'Arc Warden', 'Morphling': -1.824 },
    { name: 'Axe', 'Morphling': 2.605 },
    { name: 'Bane', 'Morphling': 2.254 },
    { name: 'Batrider', 'Morphling': -0.707 },
    { name: 'Beastmaster', 'Morphling': 1.587 },
    { name: 'Bloodseeker', 'Morphling': -1.472 },
    { name: 'Bounty Hunter', 'Morphling': -0.579 },
    { name: 'Brewmaster', 'Morphling': -0.623 },
    { name: 'Bristleback', 'Morphling': -2.092 },
    { name: 'Broodmother', 'Morphling': 0.563 },
    { name: 'Centaur Warrunner', 'Morphling': 0.247 },
    { name: 'Chaos Knight', 'Morphling': 0.5 },
    { name: 'Chen', 'Morphling': 0.344 },
    { name: 'Clinkz', 'Morphling': -2.308 },
    { name: 'Clockwerk', 'Morphling': -2.13 },
    { name: 'Crystal Maiden', 'Morphling': -0.121 },
    { name: 'Dark Seer', 'Morphling': 0.074 },
    { name: 'Dark Willow', 'Morphling': -0.527 },
    { name: 'Dawnbreaker', 'Morphling': -1.4 },
    { name: 'Dazzle', 'Morphling': -0.098 },
    { name: 'Death Prophet', 'Morphling': -0.184 },
    { name: 'Disruptor', 'Morphling': -0.233 },
    { name: 'Doom', 'Morphling': 1.432 },
    { name: 'Dragon Knight', 'Morphling': 1.078 },
    { name: 'Drow Ranger', 'Morphling': -0.452 },
    { name: 'Earth Spirit', 'Morphling': 1.044 },
    { name: 'Earthshaker', 'Morphling': 1.541 },
    { name: 'Elder Titan', 'Morphling': 0.84 },
    { name: 'Ember Spirit', 'Morphling': -1.957 },
    { name: 'Enchantress', 'Morphling': -0.911 },
    { name: 'Enigma', 'Morphling': -0.647 },
    { name: 'Faceless Void', 'Morphling': -0.007 },
    { name: 'Grimstroke', 'Morphling': -0.167 },
    { name: 'Gyrocopter', 'Morphling': 0.496 },
    { name: 'Hoodwink', 'Morphling': 0.043 },
    { name: 'Huskar', 'Morphling': -0.528 },
    { name: 'Invoker', 'Morphling': -0.376 },
    { name: 'Io', 'Morphling': 0.765 },
    { name: 'Jakiro', 'Morphling': -1.557 },
    { name: 'Juggernaut', 'Morphling': -2.688 },
    { name: 'Keeper of the Light', 'Morphling': 0.214 },
    { name: 'Kunkka', 'Morphling': -1.126 },
    { name: 'Legion Commander', 'Morphling': -0.253 },
    { name: 'Leshrac', 'Morphling': -0.19 },
    { name: 'Lich', 'Morphling': -0.488 },
    { name: 'Lifestealer', 'Morphling': -1.321 },
    { name: 'Lina', 'Morphling': 1.136 },
    { name: 'Lion', 'Morphling': 2.024 },
    { name: 'Lone Druid', 'Morphling': 1.229 },
    { name: 'Luna', 'Morphling': 1.115 },
    { name: 'Lycan', 'Morphling': 0.134 },
    { name: 'Magnus', 'Morphling': 0.892 },
    { name: 'Marci', 'Morphling': 0.998 },
    { name: 'Mars', 'Morphling': -0.668 },
    { name: 'Medusa', 'Morphling': 1.697 },
    { name: 'Meepo', 'Morphling': 3.302 },
    { name: 'Mirana', 'Morphling': 0.071 },
    { name: 'Monkey King', 'Morphling': 0.259 },
    { name: 'Muerta', 'Morphling': 0.68 },
    { name: 'Naga Siren', 'Morphling': 0.508 },
    { name: "Nature's Prophet", 'Morphling': -1.719 },
    { name: 'Necrophos', 'Morphling': 0.214 },
    { name: 'Night Stalker', 'Morphling': 0.686 },
    { name: 'Nyx Assassin', 'Morphling': 2.453 },
    { name: 'Ogre Magi', 'Morphling': 0.472 },
    { name: 'Omniknight', 'Morphling': 0.398 },
    { name: 'Oracle', 'Morphling': -0.375 },
    { name: 'Outworld Destroyer', 'Morphling': 0.946 },
    { name: 'Pangolier', 'Morphling': -1.24 },
    { name: 'Phantom Assassin', 'Morphling': -1.651 },
    { name: 'Phantom Lancer', 'Morphling': 0.809 },
    { name: 'Phoenix', 'Morphling': -1.986 },
    { name: 'Primal Beast', 'Morphling': -0.388 },
    { name: 'Puck', 'Morphling': 0.659 },
    { name: 'Pudge', 'Morphling': -0.572 },
    { name: 'Pugna', 'Morphling': 0.415 },
    { name: 'Queen of Pain', 'Morphling': -1.11 },
    { name: 'Razor', 'Morphling': -3.454 },
    { name: 'Riki', 'Morphling': 0.461 },
    { name: 'Ringmaster', 'Morphling': -0.126 },
    { name: 'Rubick', 'Morphling': 2.002 },
    { name: 'Sand King', 'Morphling': -0.521 },
    { name: 'Shadow Demon', 'Morphling': -0.975 },
    { name: 'Shadow Fiend', 'Morphling': 1.078 },
    { name: 'Shadow Shaman', 'Morphling': 0.982 },
    { name: 'Silencer', 'Morphling': -1.662 },
    { name: 'Skywrath Mage', 'Morphling': 0.141 },
    { name: 'Slardar', 'Morphling': -0.284 },
    { name: 'Slark', 'Morphling': 1.447 },
    { name: 'Snapfire', 'Morphling': -0.022 },
    { name: 'Sniper', 'Morphling': -3.192 },
    { name: 'Spectre', 'Morphling': -0.788 },
    { name: 'Spirit Breaker', 'Morphling': -1.98 },
    { name: 'Storm Spirit', 'Morphling': -0.192 },
    { name: 'Sven', 'Morphling': -0.247 },
    { name: 'Techies', 'Morphling': 1.306 },
    { name: 'Templar Assassin', 'Morphling': -0.866 },
    { name: 'Terrorblade', 'Morphling': 0.763 },
    { name: 'Tidehunter', 'Morphling': 0.959 },
    { name: 'Timbersaw', 'Morphling': 0.968 },
    { name: 'Tinker', 'Morphling': -0.346 },
    { name: 'Tiny', 'Morphling': 0.98 },
    { name: 'Treant Protector', 'Morphling': -0.476 },
    { name: 'Troll Warlord', 'Morphling': 0.475 },
    { name: 'Tusk', 'Morphling': -0.201 },
    { name: 'Underlord', 'Morphling': -1.333 },
    { name: 'Undying', 'Morphling': -1.392 },
    { name: 'Ursa', 'Morphling': 0.497 },
    { name: 'Vengeful Spirit', 'Morphling': -0.755 },
    { name: 'Venomancer', 'Morphling': -1.631 },
    { name: 'Viper', 'Morphling': -3.064 },
    { name: 'Visage', 'Morphling': 1.178 },
    { name: 'Void Spirit', 'Morphling': -0.319 },
    { name: 'Warlock', 'Morphling': -1.476 },
    { name: 'Weaver', 'Morphling': -1.61 },
    { name: 'Windranger', 'Morphling': -0.622 },
    { name: 'Winter Wyvern', 'Morphling': -0.873 },
    { name: 'Witch Doctor', 'Morphling': -0.779 },
    { name: 'Wraith King', 'Morphling': -0.502 },
    { name: 'Zeus', 'Morphling': 1.074 },
],
"Naga Siren": [
    { name: 'Abaddon', 'Naga Siren': 0.408 },
    { name: 'Alchemist', 'Naga Siren': 1.24 },
    { name: 'Ancient Apparition', 'Naga Siren': -1.711 },
    { name: 'Anti-Mage', 'Naga Siren': 0.414 },
    { name: 'Arc Warden', 'Naga Siren': -1.458 },
    { name: 'Axe', 'Naga Siren': 4.479 },
    { name: 'Bane', 'Naga Siren': -3.746 },
    { name: 'Batrider', 'Naga Siren': 1.258 },
    { name: 'Beastmaster', 'Naga Siren': 1.277 },
    { name: 'Bloodseeker', 'Naga Siren': -2.176 },
    { name: 'Bounty Hunter', 'Naga Siren': -1.997 },
    { name: 'Brewmaster', 'Naga Siren': 1.905 },
    { name: 'Bristleback', 'Naga Siren': -1.053 },
    { name: 'Broodmother', 'Naga Siren': -4.818 },
    { name: 'Centaur Warrunner', 'Naga Siren': 0.941 },
    { name: 'Chaos Knight', 'Naga Siren': -4.118 },
    { name: 'Chen', 'Naga Siren': -3.644 },
    { name: 'Clinkz', 'Naga Siren': -4.502 },
    { name: 'Clockwerk', 'Naga Siren': -1.288 },
    { name: 'Crystal Maiden', 'Naga Siren': 0.834 },
    { name: 'Dark Seer', 'Naga Siren': 1.402 },
    { name: 'Dark Willow', 'Naga Siren': -3.564 },
    { name: 'Dawnbreaker', 'Naga Siren': 3.412 },
    { name: 'Dazzle', 'Naga Siren': 3.797 },
    { name: 'Death Prophet', 'Naga Siren': 0.983 },
    { name: 'Disruptor', 'Naga Siren': 0.244 },
    { name: 'Doom', 'Naga Siren': 0.72 },
    { name: 'Dragon Knight', 'Naga Siren': 0.498 },
    { name: 'Drow Ranger', 'Naga Siren': -1.98 },
    { name: 'Earth Spirit', 'Naga Siren': -0.699 },
    { name: 'Earthshaker', 'Naga Siren': 3.684 },
    { name: 'Elder Titan', 'Naga Siren': 1.171 },
    { name: 'Ember Spirit', 'Naga Siren': -0.494 },
    { name: 'Enchantress', 'Naga Siren': -2.446 },
    { name: 'Enigma', 'Naga Siren': 0.091 },
    { name: 'Faceless Void', 'Naga Siren': -2.938 },
    { name: 'Grimstroke', 'Naga Siren': 0.229 },
    { name: 'Gyrocopter', 'Naga Siren': 1.376 },
    { name: 'Hoodwink', 'Naga Siren': -0.298 },
    { name: 'Huskar', 'Naga Siren': -2.902 },
    { name: 'Invoker', 'Naga Siren': 0.057 },
    { name: 'Io', 'Naga Siren': -0.398 },
    { name: 'Jakiro', 'Naga Siren': 2.404 },
    { name: 'Juggernaut', 'Naga Siren': 2.293 },
    { name: 'Keeper of the Light', 'Naga Siren': 0.026 },
    { name: 'Kunkka', 'Naga Siren': 0.028 },
    { name: 'Legion Commander', 'Naga Siren': 1.089 },
    { name: 'Leshrac', 'Naga Siren': 3.115 },
    { name: 'Lich', 'Naga Siren': 1.358 },
    { name: 'Lifestealer', 'Naga Siren': -1.394 },
    { name: 'Lina', 'Naga Siren': 5.929 },
    { name: 'Lion', 'Naga Siren': 1.014 },
    { name: 'Lone Druid', 'Naga Siren': -4.558 },
    { name: 'Luna', 'Naga Siren': 2.116 },
    { name: 'Lycan', 'Naga Siren': -5.837 },
    { name: 'Magnus', 'Naga Siren': 0.37 },
    { name: 'Marci', 'Naga Siren': -0.074 },
    { name: 'Mars', 'Naga Siren': 0.743 },
    { name: 'Medusa', 'Naga Siren': 1.933 },
    { name: 'Meepo', 'Naga Siren': -2.223 },
    { name: 'Mirana', 'Naga Siren': -1.376 },
    { name: 'Monkey King', 'Naga Siren': 0.948 },
    { name: 'Morphling', 'Naga Siren': -0.594 },
    { name: 'Muerta', 'Naga Siren': 0.412 },
    { name: "Nature's Prophet", 'Naga Siren': -0.183 },
    { name: 'Necrophos', 'Naga Siren': 3.553 },
    { name: 'Night Stalker', 'Naga Siren': -4.478 },
    { name: 'Nyx Assassin', 'Naga Siren': -1.086 },
    { name: 'Ogre Magi', 'Naga Siren': -3.383 },
    { name: 'Omniknight', 'Naga Siren': 0.249 },
    { name: 'Oracle', 'Naga Siren': -0.557 },
    { name: 'Outworld Destroyer', 'Naga Siren': -1.541 },
    { name: 'Pangolier', 'Naga Siren': 0.236 },
    { name: 'Phantom Assassin', 'Naga Siren': -0.505 },
    { name: 'Phantom Lancer', 'Naga Siren': -2.594 },
    { name: 'Phoenix', 'Naga Siren': 0.839 },
    { name: 'Primal Beast', 'Naga Siren': -0.4 },
    { name: 'Puck', 'Naga Siren': -0.16 },
    { name: 'Pudge', 'Naga Siren': 1.195 },
    { name: 'Pugna', 'Naga Siren': 0.465 },
    { name: 'Queen of Pain', 'Naga Siren': -0.773 },
    { name: 'Razor', 'Naga Siren': -1.03 },
    { name: 'Riki', 'Naga Siren': -3.469 },
    { name: 'Ringmaster', 'Naga Siren': 0.909 },
    { name: 'Rubick', 'Naga Siren': 1.095 },
    { name: 'Sand King', 'Naga Siren': 6.246 },
    { name: 'Shadow Demon', 'Naga Siren': -1.365 },
    { name: 'Shadow Fiend', 'Naga Siren': 0.857 },
    { name: 'Shadow Shaman', 'Naga Siren': -1.552 },
    { name: 'Silencer', 'Naga Siren': -2.526 },
    { name: 'Skywrath Mage', 'Naga Siren': -0.424 },
    { name: 'Slardar', 'Naga Siren': -2.317 },
    { name: 'Slark', 'Naga Siren': -1.214 },
    { name: 'Snapfire', 'Naga Siren': 1.594 },
    { name: 'Sniper', 'Naga Siren': -0.068 },
    { name: 'Spectre', 'Naga Siren': -0.253 },
    { name: 'Spirit Breaker', 'Naga Siren': -1.854 },
    { name: 'Storm Spirit', 'Naga Siren': -1.81 },
    { name: 'Sven', 'Naga Siren': 0.973 },
    { name: 'Techies', 'Naga Siren': 1.513 },
    { name: 'Templar Assassin', 'Naga Siren': -2.901 },
    { name: 'Terrorblade', 'Naga Siren': -2.377 },
    { name: 'Tidehunter', 'Naga Siren': 2.198 },
    { name: 'Timbersaw', 'Naga Siren': 3.849 },
    { name: 'Tinker', 'Naga Siren': -1.733 },
    { name: 'Tiny', 'Naga Siren': 3.528 },
    { name: 'Treant Protector', 'Naga Siren': -0.395 },
    { name: 'Troll Warlord', 'Naga Siren': -0.448 },
    { name: 'Tusk', 'Naga Siren': -1.471 },
    { name: 'Underlord', 'Naga Siren': 4.638 },
    { name: 'Undying', 'Naga Siren': -1.085 },
    { name: 'Ursa', 'Naga Siren': -2.699 },
    { name: 'Vengeful Spirit', 'Naga Siren': -1.446 },
    { name: 'Venomancer', 'Naga Siren': 0.571 },
    { name: 'Viper', 'Naga Siren': -3.701 },
    { name: 'Visage', 'Naga Siren': -2.368 },
    { name: 'Void Spirit', 'Naga Siren': -1.389 },
    { name: 'Warlock', 'Naga Siren': 3.599 },
    { name: 'Weaver', 'Naga Siren': -2.279 },
    { name: 'Windranger', 'Naga Siren': -0.536 },
    { name: 'Winter Wyvern', 'Naga Siren': -0.196 },
    { name: 'Witch Doctor', 'Naga Siren': -0.788 },
    { name: 'Wraith King', 'Naga Siren': -2.385 },
    { name: 'Zeus', 'Naga Siren': -0.775 },
],
"Phantom Assassin": [
    { name: 'Abaddon', 'Phantom Assassin': -0.622 },
    { name: 'Alchemist', 'Phantom Assassin': -0.729 },
    { name: 'Ancient Apparition', 'Phantom Assassin': -1.685 },
    { name: 'Anti-Mage', 'Phantom Assassin': -4.224 },
    { name: 'Arc Warden', 'Phantom Assassin': -1.301 },
    { name: 'Axe', 'Phantom Assassin': 3.092 },
    { name: 'Bane', 'Phantom Assassin': 1.66 },
    { name: 'Batrider', 'Phantom Assassin': 2.346 },
    { name: 'Beastmaster', 'Phantom Assassin': 0.873 },
    { name: 'Bloodseeker', 'Phantom Assassin': 0.666 },
    { name: 'Bounty Hunter', 'Phantom Assassin': 1.181 },
    { name: 'Brewmaster', 'Phantom Assassin': 0.653 },
    { name: 'Bristleback', 'Phantom Assassin': 0.191 },
    { name: 'Broodmother', 'Phantom Assassin': -1.937 },
    { name: 'Centaur Warrunner', 'Phantom Assassin': 1.366 },
    { name: 'Chaos Knight', 'Phantom Assassin': -0.221 },
    { name: 'Chen', 'Phantom Assassin': 0.414 },
    { name: 'Clinkz', 'Phantom Assassin': -1.503 },
    { name: 'Clockwerk', 'Phantom Assassin': 0.545 },
    { name: 'Crystal Maiden', 'Phantom Assassin': 0.05 },
    { name: 'Dark Seer', 'Phantom Assassin': -0.7 },
    { name: 'Dark Willow', 'Phantom Assassin': -0.23 },
    { name: 'Dawnbreaker', 'Phantom Assassin': 1.833 },
    { name: 'Dazzle', 'Phantom Assassin': 0.38 },
    { name: 'Death Prophet', 'Phantom Assassin': 1.31 },
    { name: 'Disruptor', 'Phantom Assassin': -0.246 },
    { name: 'Doom', 'Phantom Assassin': 1.499 },
    { name: 'Dragon Knight', 'Phantom Assassin': 0.888 },
    { name: 'Drow Ranger', 'Phantom Assassin': 1.0 },
    { name: 'Earth Spirit', 'Phantom Assassin': 2.042 },
    { name: 'Earthshaker', 'Phantom Assassin': -0.538 },
    { name: 'Elder Titan', 'Phantom Assassin': -2.913 },
    { name: 'Ember Spirit', 'Phantom Assassin': -0.369 },
    { name: 'Enchantress', 'Phantom Assassin': -2.916 },
    { name: 'Enigma', 'Phantom Assassin': -0.2 },
    { name: 'Faceless Void', 'Phantom Assassin': 0.829 },
    { name: 'Grimstroke', 'Phantom Assassin': 0.312 },
    { name: 'Gyrocopter', 'Phantom Assassin': 0.753 },
    { name: 'Hoodwink', 'Phantom Assassin': -0.536 },
    { name: 'Huskar', 'Phantom Assassin': -2.094 },
    { name: 'Invoker', 'Phantom Assassin': -1.18 },
    { name: 'Io', 'Phantom Assassin': 0.227 },
    { name: 'Jakiro', 'Phantom Assassin': 0.675 },
    { name: 'Juggernaut', 'Phantom Assassin': 0.201 },
    { name: 'Keeper of the Light', 'Phantom Assassin': -0.782 },
    { name: 'Kunkka', 'Phantom Assassin': -0.418 },
    { name: 'Legion Commander', 'Phantom Assassin': -0.217 },
    { name: 'Leshrac', 'Phantom Assassin': 1.407 },
    { name: 'Lich', 'Phantom Assassin': 0.427 },
    { name: 'Lifestealer', 'Phantom Assassin': -1.485 },
    { name: 'Lina', 'Phantom Assassin': -1.882 },
    { name: 'Lion', 'Phantom Assassin': 0.073 },
    { name: 'Lone Druid', 'Phantom Assassin': -2.87 },
    { name: 'Luna', 'Phantom Assassin': 1.181 },
    { name: 'Lycan', 'Phantom Assassin': -2.137 },
    { name: 'Magnus', 'Phantom Assassin': 1.53 },
    { name: 'Marci', 'Phantom Assassin': -0.31 },
    { name: 'Mars', 'Phantom Assassin': 0.002 },
    { name: 'Medusa', 'Phantom Assassin': 0.041 },
    { name: 'Meepo', 'Phantom Assassin': 1.154 },
    { name: 'Mirana', 'Phantom Assassin': 1.27 },
    { name: 'Monkey King', 'Phantom Assassin': 1.152 },
    { name: 'Morphling', 'Phantom Assassin': 2.749 },
    { name: 'Muerta', 'Phantom Assassin': 0.759 },
    { name: 'Naga Siren', 'Phantom Assassin': 1.269 },
    { name: "Nature's Prophet", 'Phantom Assassin': -1.283 },
    { name: 'Necrophos', 'Phantom Assassin': -0.156 },
    { name: 'Night Stalker', 'Phantom Assassin': -1.513 },
    { name: 'Nyx Assassin', 'Phantom Assassin': 2.017 },
    { name: 'Ogre Magi', 'Phantom Assassin': 1.442 },
    { name: 'Omniknight', 'Phantom Assassin': 0.426 },
    { name: 'Oracle', 'Phantom Assassin': -0.933 },
    { name: 'Outworld Destroyer', 'Phantom Assassin': -1.336 },
    { name: 'Pangolier', 'Phantom Assassin': 1.706 },
    { name: 'Phantom Lancer', 'Phantom Assassin': 0.445 },
    { name: 'Phoenix', 'Phantom Assassin': 2.35 },
    { name: 'Primal Beast', 'Phantom Assassin': 2.757 },
    { name: 'Puck', 'Phantom Assassin': 1.567 },
    { name: 'Pudge', 'Phantom Assassin': 2.116 },
    { name: 'Pugna', 'Phantom Assassin': 0.83 },
    { name: 'Queen of Pain', 'Phantom Assassin': 1.35 },
    { name: 'Razor', 'Phantom Assassin': 1.136 },
    { name: 'Riki', 'Phantom Assassin': -0.498 },
    { name: 'Ringmaster', 'Phantom Assassin': -1.122 },
    { name: 'Rubick', 'Phantom Assassin': -0.595 },
    { name: 'Sand King', 'Phantom Assassin': 0.377 },
    { name: 'Shadow Demon', 'Phantom Assassin': -0.463 },
    { name: 'Shadow Fiend', 'Phantom Assassin': 1.418 },
    { name: 'Shadow Shaman', 'Phantom Assassin': 0.056 },
    { name: 'Silencer', 'Phantom Assassin': -1.276 },
    { name: 'Skywrath Mage', 'Phantom Assassin': -0.457 },
    { name: 'Slardar', 'Phantom Assassin': -0.521 },
    { name: 'Slark', 'Phantom Assassin': 0.673 },
    { name: 'Snapfire', 'Phantom Assassin': 0.434 },
    { name: 'Sniper', 'Phantom Assassin': -0.862 },
    { name: 'Spectre', 'Phantom Assassin': 0.471 },
    { name: 'Spirit Breaker', 'Phantom Assassin': 1.185 },
    { name: 'Storm Spirit', 'Phantom Assassin': 1.011 },
    { name: 'Sven', 'Phantom Assassin': -0.01 },
    { name: 'Techies', 'Phantom Assassin': 1.3 },
    { name: 'Templar Assassin', 'Phantom Assassin': 2.244 },
    { name: 'Terrorblade', 'Phantom Assassin': 0.804 },
    { name: 'Tidehunter', 'Phantom Assassin': 1.298 },
    { name: 'Timbersaw', 'Phantom Assassin': 1.669 },
    { name: 'Tinker', 'Phantom Assassin': -1.271 },
    { name: 'Tiny', 'Phantom Assassin': 2.431 },
    { name: 'Treant Protector', 'Phantom Assassin': 0.648 },
    { name: 'Troll Warlord', 'Phantom Assassin': 1.918 },
    { name: 'Tusk', 'Phantom Assassin': 1.85 },
    { name: 'Underlord', 'Phantom Assassin': -1.006 },
    { name: 'Undying', 'Phantom Assassin': -0.642 },
    { name: 'Ursa', 'Phantom Assassin': 0.647 },
    { name: 'Vengeful Spirit', 'Phantom Assassin': 0.254 },
    { name: 'Venomancer', 'Phantom Assassin': -0.276 },
    { name: 'Viper', 'Phantom Assassin': 0.31 },
    { name: 'Visage', 'Phantom Assassin': -0.66 },
    { name: 'Void Spirit', 'Phantom Assassin': 0.554 },
    { name: 'Warlock', 'Phantom Assassin': -2.454 },
    { name: 'Weaver', 'Phantom Assassin': 0.952 },
    { name: 'Windranger', 'Phantom Assassin': 0.28 },
    { name: 'Winter Wyvern', 'Phantom Assassin': -1.099 },
    { name: 'Witch Doctor', 'Phantom Assassin': -1.299 },
    { name: 'Wraith King', 'Phantom Assassin': 0.153 },
    { name: 'Zeus', 'Phantom Assassin': 0.652 },
],
"Phantom Lancer": [
    { name: 'Abaddon', 'Phantom Lancer': -1.321 },
    { name: 'Alchemist', 'Phantom Lancer': 0.344 },
    { name: 'Ancient Apparition', 'Phantom Lancer': -2.384 },
    { name: 'Anti-Mage', 'Phantom Lancer': 4.03 },
    { name: 'Arc Warden', 'Phantom Lancer': -4.688 },
    { name: 'Axe', 'Phantom Lancer': 3.176 },
    { name: 'Bane', 'Phantom Lancer': -2.208 },
    { name: 'Batrider', 'Phantom Lancer': 0.35 },
    { name: 'Beastmaster', 'Phantom Lancer': 4.377 },
    { name: 'Bloodseeker', 'Phantom Lancer': 1.327 },
    { name: 'Bounty Hunter', 'Phantom Lancer': -2.015 },
    { name: 'Brewmaster', 'Phantom Lancer': -0.064 },
    { name: 'Bristleback', 'Phantom Lancer': -0.201 },
    { name: 'Broodmother', 'Phantom Lancer': -2.601 },
    { name: 'Centaur Warrunner', 'Phantom Lancer': 1.215 },
    { name: 'Chaos Knight', 'Phantom Lancer': -3.901 },
    { name: 'Chen', 'Phantom Lancer': -2.674 },
    { name: 'Clinkz', 'Phantom Lancer': -3.807 },
    { name: 'Clockwerk', 'Phantom Lancer': -2.294 },
    { name: 'Crystal Maiden', 'Phantom Lancer': 3.173 },
    { name: 'Dark Seer', 'Phantom Lancer': 3.522 },
    { name: 'Dark Willow', 'Phantom Lancer': -1.613 },
    { name: 'Dawnbreaker', 'Phantom Lancer': -0.228 },
    { name: 'Dazzle', 'Phantom Lancer': -0.884 },
    { name: 'Death Prophet', 'Phantom Lancer': -1.775 },
    { name: 'Disruptor', 'Phantom Lancer': -0.349 },
    { name: 'Doom', 'Phantom Lancer': 1.402 },
    { name: 'Dragon Knight', 'Phantom Lancer': -0.554 },
    { name: 'Drow Ranger', 'Phantom Lancer': -4.142 },
    { name: 'Earth Spirit', 'Phantom Lancer': 0.36 },
    { name: 'Earthshaker', 'Phantom Lancer': 2.943 },
    { name: 'Elder Titan', 'Phantom Lancer': -0.196 },
    { name: 'Ember Spirit', 'Phantom Lancer': 4.041 },
    { name: 'Enchantress', 'Phantom Lancer': -1.95 },
    { name: 'Enigma', 'Phantom Lancer': -0.579 },
    { name: 'Faceless Void', 'Phantom Lancer': 0.572 },
    { name: 'Grimstroke', 'Phantom Lancer': 0.456 },
    { name: 'Gyrocopter', 'Phantom Lancer': -0.11 },
    { name: 'Hoodwink', 'Phantom Lancer': -0.927 },
    { name: 'Huskar', 'Phantom Lancer': -2.38 },
    { name: 'Invoker', 'Phantom Lancer': -2.505 },
    { name: 'Io', 'Phantom Lancer': -1.087 },
    { name: 'Jakiro', 'Phantom Lancer': 1.313 },
    { name: 'Juggernaut', 'Phantom Lancer': 0.121 },
    { name: 'Keeper of the Light', 'Phantom Lancer': -0.152 },
    { name: 'Kunkka', 'Phantom Lancer': 0.473 },
    { name: 'Legion Commander', 'Phantom Lancer': 2.001 },
    { name: 'Leshrac', 'Phantom Lancer': 2.962 },
    { name: 'Lich', 'Phantom Lancer': -1.174 },
    { name: 'Lifestealer', 'Phantom Lancer': 0.221 },
    { name: 'Lina', 'Phantom Lancer': 4.344 },
    { name: 'Lion', 'Phantom Lancer': -0.552 },
    { name: 'Lone Druid', 'Phantom Lancer': -2.064 },
    { name: 'Luna', 'Phantom Lancer': -0.75 },
    { name: 'Lycan', 'Phantom Lancer': -1.758 },
    { name: 'Magnus', 'Phantom Lancer': 0.746 },
    { name: 'Marci', 'Phantom Lancer': -0.379 },
    { name: 'Mars', 'Phantom Lancer': -2.7 },
    { name: 'Medusa', 'Phantom Lancer': -0.802 },
    { name: 'Meepo', 'Phantom Lancer': 2.985 },
    { name: 'Mirana', 'Phantom Lancer': -0.36 },
    { name: 'Monkey King', 'Phantom Lancer': 0.369 },
    { name: 'Morphling', 'Phantom Lancer': -0.83 },
    { name: 'Muerta', 'Phantom Lancer': -1.076 },
    { name: 'Naga Siren', 'Phantom Lancer': 2.381 },
    { name: "Nature's Prophet", 'Phantom Lancer': 1.178 },
    { name: 'Necrophos', 'Phantom Lancer': 4.143 },
    { name: 'Night Stalker', 'Phantom Lancer': -1.21 },
    { name: 'Nyx Assassin', 'Phantom Lancer': 0.765 },
    { name: 'Ogre Magi', 'Phantom Lancer': -4.412 },
    { name: 'Omniknight', 'Phantom Lancer': -2.83 },
    { name: 'Oracle', 'Phantom Lancer': -1.784 },
    { name: 'Outworld Destroyer', 'Phantom Lancer': -3.472 },
    { name: 'Pangolier', 'Phantom Lancer': -0.553 },
    { name: 'Phantom Assassin', 'Phantom Lancer': 0.866 },
    { name: 'Phoenix', 'Phantom Lancer': 0.24 },
    { name: 'Primal Beast', 'Phantom Lancer': 0.129 },
    { name: 'Puck', 'Phantom Lancer': 2.892 },
    { name: 'Pudge', 'Phantom Lancer': 0.915 },
    { name: 'Pugna', 'Phantom Lancer': -1.462 },
    { name: 'Queen of Pain', 'Phantom Lancer': 0.08 },
    { name: 'Razor', 'Phantom Lancer': 1.561 },
    { name: 'Riki', 'Phantom Lancer': -0.88 },
    { name: 'Ringmaster', 'Phantom Lancer': -1.079 },
    { name: 'Rubick', 'Phantom Lancer': 1.467 },
    { name: 'Sand King', 'Phantom Lancer': 7.145 },
    { name: 'Shadow Demon', 'Phantom Lancer': -3.112 },
    { name: 'Shadow Fiend', 'Phantom Lancer': -1.57 },
    { name: 'Shadow Shaman', 'Phantom Lancer': -4.003 },
    { name: 'Silencer', 'Phantom Lancer': -2.051 },
    { name: 'Skywrath Mage', 'Phantom Lancer': -0.481 },
    { name: 'Slardar', 'Phantom Lancer': -2.883 },
    { name: 'Slark', 'Phantom Lancer': -0.357 },
    { name: 'Snapfire', 'Phantom Lancer': 1.212 },
    { name: 'Sniper', 'Phantom Lancer': -1.006 },
    { name: 'Spectre', 'Phantom Lancer': -0.324 },
    { name: 'Spirit Breaker', 'Phantom Lancer': -0.81 },
    { name: 'Storm Spirit', 'Phantom Lancer': 1.748 },
    { name: 'Sven', 'Phantom Lancer': 1.919 },
    { name: 'Techies', 'Phantom Lancer': 1.516 },
    { name: 'Templar Assassin', 'Phantom Lancer': -5.473 },
    { name: 'Terrorblade', 'Phantom Lancer': -3.447 },
    { name: 'Tidehunter', 'Phantom Lancer': 1.248 },
    { name: 'Timbersaw', 'Phantom Lancer': 4.651 },
    { name: 'Tinker', 'Phantom Lancer': -0.054 },
    { name: 'Tiny', 'Phantom Lancer': 0.673 },
    { name: 'Treant Protector', 'Phantom Lancer': 0.594 },
    { name: 'Troll Warlord', 'Phantom Lancer': -0.263 },
    { name: 'Tusk', 'Phantom Lancer': -1.396 },
    { name: 'Underlord', 'Phantom Lancer': 2.853 },
    { name: 'Undying', 'Phantom Lancer': -1.924 },
    { name: 'Ursa', 'Phantom Lancer': -4.221 },
    { name: 'Vengeful Spirit', 'Phantom Lancer': -3.839 },
    { name: 'Venomancer', 'Phantom Lancer': -0.911 },
    { name: 'Viper', 'Phantom Lancer': -5.37 },
    { name: 'Visage', 'Phantom Lancer': -3.16 },
    { name: 'Void Spirit', 'Phantom Lancer': 0.08 },
    { name: 'Warlock', 'Phantom Lancer': 6.15 },
    { name: 'Weaver', 'Phantom Lancer': -1.024 },
    { name: 'Windranger', 'Phantom Lancer': 0.918 },
    { name: 'Winter Wyvern', 'Phantom Lancer': -0.887 },
    { name: 'Witch Doctor', 'Phantom Lancer': 0.715 },
    { name: 'Wraith King', 'Phantom Lancer': -4.556 },
    { name: 'Zeus', 'Phantom Lancer': -0.068 },
],
"Razor": [
    { name: 'Abaddon', 'Razor': -1.572 },
    { name: 'Alchemist', 'Razor': -0.622 },
    { name: 'Ancient Apparition', 'Razor': 0.954 },
    { name: 'Anti-Mage', 'Razor': 3.376 },
    { name: 'Arc Warden', 'Razor': 0.694 },
    { name: 'Axe', 'Razor': -1.036 },
    { name: 'Bane', 'Razor': -0.461 },
    { name: 'Batrider', 'Razor': -0.035 },
    { name: 'Beastmaster', 'Razor': 0.421 },
    { name: 'Bloodseeker', 'Razor': 2.166 },
    { name: 'Bounty Hunter', 'Razor': -0.253 },
    { name: 'Brewmaster', 'Razor': 1.112 },
    { name: 'Bristleback', 'Razor': 1.929 },
    { name: 'Broodmother', 'Razor': -0.3 },
    { name: 'Centaur Warrunner', 'Razor': 1.169 },
    { name: 'Chaos Knight', 'Razor': 1.068 },
    { name: 'Chen', 'Razor': 1.12 },
    { name: 'Clinkz', 'Razor': -1.995 },
    { name: 'Clockwerk', 'Razor': 1.74 },
    { name: 'Crystal Maiden', 'Razor': 1.133 },
    { name: 'Dark Seer', 'Razor': 1.161 },
    { name: 'Dark Willow', 'Razor': 1.552 },
    { name: 'Dawnbreaker', 'Razor': -0.511 },
    { name: 'Dazzle', 'Razor': -1.833 },
    { name: 'Death Prophet', 'Razor': -2.086 },
    { name: 'Disruptor', 'Razor': -0.616 },
    { name: 'Doom', 'Razor': -0.33 },
    { name: 'Dragon Knight', 'Razor': -0.891 },
    { name: 'Drow Ranger', 'Razor': 0.62 },
    { name: 'Earth Spirit', 'Razor': 1.329 },
    { name: 'Earthshaker', 'Razor': -0.683 },
    { name: 'Elder Titan', 'Razor': 1.502 },
    { name: 'Ember Spirit', 'Razor': 2.143 },
    { name: 'Enchantress', 'Razor': 0.227 },
    { name: 'Enigma', 'Razor': 0.944 },
    { name: 'Faceless Void', 'Razor': 1.567 },
    { name: 'Grimstroke', 'Razor': -1.688 },
    { name: 'Gyrocopter', 'Razor': -0.337 },
    { name: 'Hoodwink', 'Razor': 0.039 },
    { name: 'Huskar', 'Razor': -0.692 },
    { name: 'Invoker', 'Razor': -0.043 },
    { name: 'Io', 'Razor': -1.144 },
    { name: 'Jakiro', 'Razor': -0.102 },
    { name: 'Juggernaut', 'Razor': -0.751 },
    { name: 'Keeper of the Light', 'Razor': 1.028 },
    { name: 'Kunkka', 'Razor': 0.001 },
    { name: 'Legion Commander', 'Razor': 0.263 },
    { name: 'Leshrac', 'Razor': 0.382 },
    { name: 'Lich', 'Razor': -0.299 },
    { name: 'Lifestealer', 'Razor': -2.841 },
    { name: 'Lina', 'Razor': -0.374 },
    { name: 'Lion', 'Razor': -2.379 },
    { name: 'Lone Druid', 'Razor': -2.332 },
    { name: 'Luna', 'Razor': 0.837 },
    { name: 'Lycan', 'Razor': 0.71 },
    { name: 'Magnus', 'Razor': 0.437 },
    { name: 'Marci', 'Razor': -0.068 },
    { name: 'Mars', 'Razor': -0.135 },
    { name: 'Medusa', 'Razor': -0.734 },
    { name: 'Meepo', 'Razor': 0.392 },
    { name: 'Mirana', 'Razor': 1.83 },
    { name: 'Monkey King', 'Razor': -0.176 },
    { name: 'Morphling', 'Razor': 3.521 },
    { name: 'Muerta', 'Razor': 1.592 },
    { name: 'Naga Siren', 'Razor': 1.223 },
    { name: "Nature's Prophet", 'Razor': 1.098 },
    { name: 'Necrophos', 'Razor': 0.489 },
    { name: 'Night Stalker', 'Razor': -2.231 },
    { name: 'Nyx Assassin', 'Razor': 0.898 },
    { name: 'Ogre Magi', 'Razor': -0.072 },
    { name: 'Omniknight', 'Razor': -0.922 },
    { name: 'Oracle', 'Razor': -2.94 },
    { name: 'Outworld Destroyer', 'Razor': 0.141 },
    { name: 'Pangolier', 'Razor': -0.974 },
    { name: 'Phantom Assassin', 'Razor': -1.3 },
    { name: 'Phantom Lancer', 'Razor': -1.382 },
    { name: 'Phoenix', 'Razor': 1.51 },
    { name: 'Primal Beast', 'Razor': 2.864 },
    { name: 'Puck', 'Razor': 1.259 },
    { name: 'Pudge', 'Razor': 0.654 },
    { name: 'Pugna', 'Razor': 0.466 },
    { name: 'Queen of Pain', 'Razor': 3.545 },
    { name: 'Riki', 'Razor': -1.335 },
    { name: 'Ringmaster', 'Razor': -0.261 },
    { name: 'Rubick', 'Razor': -1.264 },
    { name: 'Sand King', 'Razor': 0.578 },
    { name: 'Shadow Demon', 'Razor': -0.55 },
    { name: 'Shadow Fiend', 'Razor': 0.071 },
    { name: 'Shadow Shaman', 'Razor': -1.994 },
    { name: 'Silencer', 'Razor': -1.363 },
    { name: 'Skywrath Mage', 'Razor': -0.913 },
    { name: 'Slardar', 'Razor': -1.21 },
    { name: 'Slark', 'Razor': 0.563 },
    { name: 'Snapfire', 'Razor': -0.276 },
    { name: 'Sniper', 'Razor': 0.612 },
    { name: 'Spectre', 'Razor': 1.041 },
    { name: 'Spirit Breaker', 'Razor': -0.546 },
    { name: 'Storm Spirit', 'Razor': 1.766 },
    { name: 'Sven', 'Razor': -0.791 },
    { name: 'Techies', 'Razor': 1.707 },
    { name: 'Templar Assassin', 'Razor': -0.291 },
    { name: 'Terrorblade', 'Razor': 1.856 },
    { name: 'Tidehunter', 'Razor': -3.406 },
    { name: 'Timbersaw', 'Razor': 1.859 },
    { name: 'Tinker', 'Razor': 0.68 },
    { name: 'Tiny', 'Razor': -0.448 },
    { name: 'Treant Protector', 'Razor': 0.019 },
    { name: 'Troll Warlord', 'Razor': -4.417 },
    { name: 'Tusk', 'Razor': -0.145 },
    { name: 'Underlord', 'Razor': 1.014 },
    { name: 'Undying', 'Razor': 0.169 },
    { name: 'Ursa', 'Razor': -1.485 },
    { name: 'Vengeful Spirit', 'Razor': -0.081 },
    { name: 'Venomancer', 'Razor': 0.417 },
    { name: 'Viper', 'Razor': 1.901 },
    { name: 'Visage', 'Razor': -2.578 },
    { name: 'Void Spirit', 'Razor': 1.132 },
    { name: 'Warlock', 'Razor': 0.464 },
    { name: 'Weaver', 'Razor': 2.381 },
    { name: 'Windranger', 'Razor': -0.536 },
    { name: 'Winter Wyvern', 'Razor': -0.905 },
    { name: 'Witch Doctor', 'Razor': 2.022 },
    { name: 'Wraith King', 'Razor': -1.931 },
    { name: 'Zeus', 'Razor': -0.621 },
],
"Riki": [
    { name: 'Abaddon', 'Riki': -0.139 },
    { name: 'Alchemist', 'Riki': 0.101 },
    { name: 'Ancient Apparition', 'Riki': -0.962 },
    { name: 'Anti-Mage', 'Riki': -1.924 },
    { name: 'Arc Warden', 'Riki': 0.606 },
    { name: 'Axe', 'Riki': 1.04 },
    { name: 'Bane', 'Riki': 0.176 },
    { name: 'Batrider', 'Riki': -0.652 },
    { name: 'Beastmaster', 'Riki': 2.042 },
    { name: 'Bloodseeker', 'Riki': 2.304 },
    { name: 'Bounty Hunter', 'Riki': 1.692 },
    { name: 'Brewmaster', 'Riki': -1.248 },
    { name: 'Bristleback', 'Riki': 4.471 },
    { name: 'Broodmother', 'Riki': 0.745 },
    { name: 'Centaur Warrunner', 'Riki': 1.058 },
    { name: 'Chaos Knight', 'Riki': 0.341 },
    { name: 'Chen', 'Riki': 0.549 },
    { name: 'Clinkz', 'Riki': -2.972 },
    { name: 'Clockwerk', 'Riki': 1.102 },
    { name: 'Crystal Maiden', 'Riki': 0.449 },
    { name: 'Dark Seer', 'Riki': 1.313 },
    { name: 'Dark Willow', 'Riki': -0.53 },
    { name: 'Dawnbreaker', 'Riki': -0.383 },
    { name: 'Dazzle', 'Riki': -1.817 },
    { name: 'Death Prophet', 'Riki': 1.269 },
    { name: 'Disruptor', 'Riki': 1.748 },
    { name: 'Doom', 'Riki': 0.495 },
    { name: 'Dragon Knight', 'Riki': 1.404 },
    { name: 'Drow Ranger', 'Riki': 1.646 },
    { name: 'Earth Spirit', 'Riki': -0.7 },
    { name: 'Earthshaker', 'Riki': -0.563 },
    { name: 'Elder Titan', 'Riki': -0.469 },
    { name: 'Ember Spirit', 'Riki': -1.389 },
    { name: 'Enchantress', 'Riki': -0.442 },
    { name: 'Enigma', 'Riki': -1.788 },
    { name: 'Faceless Void', 'Riki': -1.552 },
    { name: 'Grimstroke', 'Riki': 0.151 },
    { name: 'Gyrocopter', 'Riki': 0.093 },
    { name: 'Hoodwink', 'Riki': -0.788 },
    { name: 'Huskar', 'Riki': -0.595 },
    { name: 'Invoker', 'Riki': -1.743 },
    { name: 'Io', 'Riki': 0.828 },
    { name: 'Jakiro', 'Riki': 1.922 },
    { name: 'Juggernaut', 'Riki': -2.472 },
    { name: 'Keeper of the Light', 'Riki': 0.599 },
    { name: 'Kunkka', 'Riki': 0.134 },
    { name: 'Legion Commander', 'Riki': -1.108 },
    { name: 'Leshrac', 'Riki': 2.117 },
    { name: 'Lich', 'Riki': 0.829 },
    { name: 'Lifestealer', 'Riki': -1.672 },
    { name: 'Lina', 'Riki': -0.132 },
    { name: 'Lion', 'Riki': 0.409 },
    { name: 'Lone Druid', 'Riki': 0.364 },
    { name: 'Luna', 'Riki': 1.543 },
    { name: 'Lycan', 'Riki': 0.645 },
    { name: 'Magnus', 'Riki': -0.881 },
    { name: 'Marci', 'Riki': -0.763 },
    { name: 'Mars', 'Riki': -1.409 },
    { name: 'Medusa', 'Riki': -1.576 },
    { name: 'Meepo', 'Riki': 0.206 },
    { name: 'Mirana', 'Riki': -0.41 },
    { name: 'Monkey King', 'Riki': 0.3 },
    { name: 'Morphling', 'Riki': -0.521 },
    { name: 'Muerta', 'Riki': 1.57 },
    { name: 'Naga Siren', 'Riki': 3.402 },
    { name: "Nature's Prophet", 'Riki': 0.761 },
    { name: 'Necrophos', 'Riki': 2.119 },
    { name: 'Night Stalker', 'Riki': 0.755 },
    { name: 'Nyx Assassin', 'Riki': 0.713 },
    { name: 'Ogre Magi', 'Riki': 1.136 },
    { name: 'Omniknight', 'Riki': -0.908 },
    { name: 'Oracle', 'Riki': -2.331 },
    { name: 'Outworld Destroyer', 'Riki': -1.118 },
    { name: 'Pangolier', 'Riki': -1.587 },
    { name: 'Phantom Assassin', 'Riki': 0.543 },
    { name: 'Phantom Lancer', 'Riki': 0.943 },
    { name: 'Phoenix', 'Riki': 0.312 },
    { name: 'Primal Beast', 'Riki': 0.898 },
    { name: 'Puck', 'Riki': -3.524 },
    { name: 'Pudge', 'Riki': 0.707 },
    { name: 'Pugna', 'Riki': -0.425 },
    { name: 'Queen of Pain', 'Riki': -1.469 },
    { name: 'Razor', 'Riki': 1.333 },
    { name: 'Ringmaster', 'Riki': -0.595 },
    { name: 'Rubick', 'Riki': -0.123 },
    { name: 'Sand King', 'Riki': 1.668 },
    { name: 'Shadow Demon', 'Riki': -2.344 },
    { name: 'Shadow Fiend', 'Riki': 0.828 },
    { name: 'Shadow Shaman', 'Riki': -0.616 },
    { name: 'Silencer', 'Riki': 1.243 },
    { name: 'Skywrath Mage', 'Riki': -0.775 },
    { name: 'Slardar', 'Riki': 1.043 },
    { name: 'Slark', 'Riki': -2.516 },
    { name: 'Snapfire', 'Riki': 0.424 },
    { name: 'Sniper', 'Riki': 0.868 },
    { name: 'Spectre', 'Riki': 4.015 },
    { name: 'Spirit Breaker', 'Riki': 1.043 },
    { name: 'Storm Spirit', 'Riki': -3.586 },
    { name: 'Sven', 'Riki': -0.083 },
    { name: 'Techies', 'Riki': 1.181 },
    { name: 'Templar Assassin', 'Riki': -1.164 },
    { name: 'Terrorblade', 'Riki': 0.215 },
    { name: 'Tidehunter', 'Riki': 0.299 },
    { name: 'Timbersaw', 'Riki': 0.344 },
    { name: 'Tinker', 'Riki': 0.786 },
    { name: 'Tiny', 'Riki': 2.553 },
    { name: 'Treant Protector', 'Riki': 0.273 },
    { name: 'Troll Warlord', 'Riki': 0.21 },
    { name: 'Tusk', 'Riki': -0.483 },
    { name: 'Underlord', 'Riki': 4.378 },
    { name: 'Undying', 'Riki': -1.521 },
    { name: 'Ursa', 'Riki': -3.253 },
    { name: 'Vengeful Spirit', 'Riki': -0.376 },
    { name: 'Venomancer', 'Riki': 0.548 },
    { name: 'Viper', 'Riki': -1.228 },
    { name: 'Visage', 'Riki': -0.964 },
    { name: 'Void Spirit', 'Riki': -2.191 },
    { name: 'Warlock', 'Riki': 0.35 },
    { name: 'Weaver', 'Riki': -2.845 },
    { name: 'Windranger', 'Riki': -0.336 },
    { name: 'Winter Wyvern', 'Riki': -0.192 },
    { name: 'Witch Doctor', 'Riki': -2.671 },
    { name: 'Wraith King', 'Riki': 1.971 },
    { name: 'Zeus', 'Riki': 2.597 },
],
"Shadow Fiend": [
    { name: 'Abaddon', 'Shadow Fiend': -2.387 },
    { name: 'Alchemist', 'Shadow Fiend': -0.394 },
    { name: 'Ancient Apparition', 'Shadow Fiend': 0.195 },
    { name: 'Anti-Mage', 'Shadow Fiend': -2.158 },
    { name: 'Arc Warden', 'Shadow Fiend': -1.193 },
    { name: 'Axe', 'Shadow Fiend': -0.808 },
    { name: 'Bane', 'Shadow Fiend': -0.553 },
    { name: 'Batrider', 'Shadow Fiend': 2.741 },
    { name: 'Beastmaster', 'Shadow Fiend': 0.052 },
    { name: 'Bloodseeker', 'Shadow Fiend': -0.506 },
    { name: 'Bounty Hunter', 'Shadow Fiend': 0.101 },
    { name: 'Brewmaster', 'Shadow Fiend': 0.031 },
    { name: 'Bristleback', 'Shadow Fiend': 0.684 },
    { name: 'Broodmother', 'Shadow Fiend': -2.458 },
    { name: 'Centaur Warrunner', 'Shadow Fiend': -0.366 },
    { name: 'Chaos Knight', 'Shadow Fiend': -0.934 },
    { name: 'Chen', 'Shadow Fiend': -0.249 },
    { name: 'Clinkz', 'Shadow Fiend': -0.009 },
    { name: 'Clockwerk', 'Shadow Fiend': 0.573 },
    { name: 'Crystal Maiden', 'Shadow Fiend': -1.036 },
    { name: 'Dark Seer', 'Shadow Fiend': -0.2 },
    { name: 'Dark Willow', 'Shadow Fiend': -0.937 },
    { name: 'Dawnbreaker', 'Shadow Fiend': -0.736 },
    { name: 'Dazzle', 'Shadow Fiend': -0.574 },
    { name: 'Death Prophet', 'Shadow Fiend': -0.465 },
    { name: 'Disruptor', 'Shadow Fiend': -0.428 },
    { name: 'Doom', 'Shadow Fiend': -0.582 },
    { name: 'Dragon Knight', 'Shadow Fiend': 0.846 },
    { name: 'Drow Ranger', 'Shadow Fiend': 0.681 },
    { name: 'Earth Spirit', 'Shadow Fiend': -1.164 },
    { name: 'Earthshaker', 'Shadow Fiend': 0.099 },
    { name: 'Elder Titan', 'Shadow Fiend': -1.116 },
    { name: 'Ember Spirit', 'Shadow Fiend': -2.481 },
    { name: 'Enchantress', 'Shadow Fiend': 0.048 },
    { name: 'Enigma', 'Shadow Fiend': -0.318 },
    { name: 'Faceless Void', 'Shadow Fiend': -0.354 },
    { name: 'Grimstroke', 'Shadow Fiend': -0.269 },
    { name: 'Gyrocopter', 'Shadow Fiend': 1.143 },
    { name: 'Hoodwink', 'Shadow Fiend': 1.028 },
    { name: 'Huskar', 'Shadow Fiend': -0.484 },
    { name: 'Invoker', 'Shadow Fiend': 0.97 },
    { name: 'Io', 'Shadow Fiend': -1.655 },
    { name: 'Jakiro', 'Shadow Fiend': 0.255 },
    { name: 'Juggernaut', 'Shadow Fiend': -0.13 },
    { name: 'Keeper of the Light', 'Shadow Fiend': 0.308 },
    { name: 'Kunkka', 'Shadow Fiend': 0.191 },
    { name: 'Legion Commander', 'Shadow Fiend': -1.25 },
    { name: 'Leshrac', 'Shadow Fiend': 0.111 },
    { name: 'Lich', 'Shadow Fiend': -0.044 },
    { name: 'Lifestealer', 'Shadow Fiend': 0.603 },
    { name: 'Lina', 'Shadow Fiend': 0.54 },
    { name: 'Lion', 'Shadow Fiend': 0.29 },
    { name: 'Lone Druid', 'Shadow Fiend': -2.777 },
    { name: 'Luna', 'Shadow Fiend': 1.114 },
    { name: 'Lycan', 'Shadow Fiend': -1.376 },
    { name: 'Magnus', 'Shadow Fiend': 0.149 },
    { name: 'Marci', 'Shadow Fiend': -1.343 },
    { name: 'Mars', 'Shadow Fiend': 0.257 },
    { name: 'Medusa', 'Shadow Fiend': 1.007 },
    { name: 'Meepo', 'Shadow Fiend': -4.249 },
    { name: 'Mirana', 'Shadow Fiend': 0.25 },
    { name: 'Monkey King', 'Shadow Fiend': 0.6 },
    { name: 'Morphling', 'Shadow Fiend': -0.449 },
    { name: 'Muerta', 'Shadow Fiend': -0.073 },
    { name: 'Naga Siren', 'Shadow Fiend': -0.539 },
    { name: "Nature's Prophet", 'Shadow Fiend': -0.152 },
    { name: 'Necrophos', 'Shadow Fiend': -2.323 },
    { name: 'Night Stalker', 'Shadow Fiend': -0.554 },
    { name: 'Nyx Assassin', 'Shadow Fiend': 1.018 },
    { name: 'Ogre Magi', 'Shadow Fiend': 0.639 },
    { name: 'Omniknight', 'Shadow Fiend': -2.163 },
    { name: 'Oracle', 'Shadow Fiend': -2.396 },
    { name: 'Outworld Destroyer', 'Shadow Fiend': 0.136 },
    { name: 'Pangolier', 'Shadow Fiend': -0.577 },
    { name: 'Phantom Assassin', 'Shadow Fiend': -1.268 },
    { name: 'Phantom Lancer', 'Shadow Fiend': 1.726 },
    { name: 'Phoenix', 'Shadow Fiend': 0.328 },
    { name: 'Primal Beast', 'Shadow Fiend': 0.715 },
    { name: 'Puck', 'Shadow Fiend': -0.552 },
    { name: 'Pudge', 'Shadow Fiend': -0.33 },
    { name: 'Pugna', 'Shadow Fiend': -0.603 },
    { name: 'Queen of Pain', 'Shadow Fiend': 0.061 },
    { name: 'Razor', 'Shadow Fiend': -0.333 },
    { name: 'Riki', 'Shadow Fiend': -1.127 },
    { name: 'Ringmaster', 'Shadow Fiend': -0.363 },
    { name: 'Rubick', 'Shadow Fiend': -0.099 },
    { name: 'Sand King', 'Shadow Fiend': -0.702 },
    { name: 'Shadow Demon', 'Shadow Fiend': 0.599 },
    { name: 'Shadow Shaman', 'Shadow Fiend': 0.288 },
    { name: 'Silencer', 'Shadow Fiend': -1.003 },
    { name: 'Skywrath Mage', 'Shadow Fiend': -0.441 },
    { name: 'Slardar', 'Shadow Fiend': -0.04 },
    { name: 'Slark', 'Shadow Fiend': -1.601 },
    { name: 'Snapfire', 'Shadow Fiend': 1.005 },
    { name: 'Sniper', 'Shadow Fiend': 0.736 },
    { name: 'Spectre', 'Shadow Fiend': -0.055 },
    { name: 'Spirit Breaker', 'Shadow Fiend': 1.631 },
    { name: 'Storm Spirit', 'Shadow Fiend': -1.358 },
    { name: 'Sven', 'Shadow Fiend': 0.461 },
    { name: 'Techies', 'Shadow Fiend': 0.501 },
    { name: 'Templar Assassin', 'Shadow Fiend': 0.895 },
    { name: 'Terrorblade', 'Shadow Fiend': 1.104 },
    { name: 'Tidehunter', 'Shadow Fiend': -0.9 },
    { name: 'Timbersaw', 'Shadow Fiend': -1.117 },
    { name: 'Tinker', 'Shadow Fiend': -2.73 },
    { name: 'Tiny', 'Shadow Fiend': 1.601 },
    { name: 'Treant Protector', 'Shadow Fiend': -0.332 },
    { name: 'Troll Warlord', 'Shadow Fiend': 0.845 },
    { name: 'Tusk', 'Shadow Fiend': 0.572 },
    { name: 'Underlord', 'Shadow Fiend': -0.787 },
    { name: 'Undying', 'Shadow Fiend': -0.662 },
    { name: 'Ursa', 'Shadow Fiend': 0.976 },
    { name: 'Vengeful Spirit', 'Shadow Fiend': 0.129 },
    { name: 'Venomancer', 'Shadow Fiend': 1.206 },
    { name: 'Viper', 'Shadow Fiend': 0.041 },
    { name: 'Visage', 'Shadow Fiend': 0.925 },
    { name: 'Void Spirit', 'Shadow Fiend': -1.552 },
    { name: 'Warlock', 'Shadow Fiend': -2.351 },
    { name: 'Weaver', 'Shadow Fiend': -0.512 },
    { name: 'Windranger', 'Shadow Fiend': 0.634 },
    { name: 'Winter Wyvern', 'Shadow Fiend': 0.505 },
    { name: 'Witch Doctor', 'Shadow Fiend': -0.605 },
    { name: 'Wraith King', 'Shadow Fiend': 0.603 },
    { name: 'Zeus', 'Shadow Fiend': 2.133 },
],
"Slark": [
    { name: 'Abaddon', 'Slark': -2.221 },
    { name: 'Alchemist', 'Slark': -0.778 },
    { name: 'Ancient Apparition', 'Slark': 1.189 },
    { name: 'Anti-Mage', 'Slark': -0.594 },
    { name: 'Arc Warden', 'Slark': -0.287 },
    { name: 'Axe', 'Slark': 0.394 },
    { name: 'Bane', 'Slark': -2.293 },
    { name: 'Batrider', 'Slark': -1.418 },
    { name: 'Beastmaster', 'Slark': -0.159 },
    { name: 'Bloodseeker', 'Slark': 2.754 },
    { name: 'Bounty Hunter', 'Slark': -2.114 },
    { name: 'Brewmaster', 'Slark': -2.17 },
    { name: 'Bristleback', 'Slark': -4.979 },
    { name: 'Broodmother', 'Slark': -0.41 },
    { name: 'Centaur Warrunner', 'Slark': 0.002 },
    { name: 'Chaos Knight', 'Slark': 0.936 },
    { name: 'Chen', 'Slark': -1.556 },
    { name: 'Clinkz', 'Slark': -0.312 },
    { name: 'Clockwerk', 'Slark': 1.948 },
    { name: 'Crystal Maiden', 'Slark': 1.707 },
    { name: 'Dark Seer', 'Slark': 0.387 },
    { name: 'Dark Willow', 'Slark': 0.533 },
    { name: 'Dawnbreaker', 'Slark': 2.598 },
    { name: 'Dazzle', 'Slark': -1.634 },
    { name: 'Death Prophet', 'Slark': -0.065 },
    { name: 'Disruptor', 'Slark': 4.224 },
    { name: 'Doom', 'Slark': 1.333 },
    { name: 'Dragon Knight', 'Slark': -1.886 },
    { name: 'Drow Ranger', 'Slark': 1.056 },
    { name: 'Earth Spirit', 'Slark': -0.629 },
    { name: 'Earthshaker', 'Slark': 2.866 },
    { name: 'Elder Titan', 'Slark': 3.233 },
    { name: 'Ember Spirit', 'Slark': -3.971 },
    { name: 'Enchantress', 'Slark': -1.213 },
    { name: 'Enigma', 'Slark': 1.775 },
    { name: 'Faceless Void', 'Slark': -0.945 },
    { name: 'Grimstroke', 'Slark': 3.957 },
    { name: 'Gyrocopter', 'Slark': 1.194 },
    { name: 'Hoodwink', 'Slark': 0.296 },
    { name: 'Huskar', 'Slark': 0.604 },
    { name: 'Invoker', 'Slark': 1.423 },
    { name: 'Io', 'Slark': -0.531 },
    { name: 'Jakiro', 'Slark': -1.029 },
    { name: 'Juggernaut', 'Slark': -0.999 },
    { name: 'Keeper of the Light', 'Slark': -0.51 },
    { name: 'Kunkka', 'Slark': 0.495 },
    { name: 'Legion Commander', 'Slark': 1.032 },
    { name: 'Leshrac', 'Slark': 3.704 },
    { name: 'Lich', 'Slark': -0.879 },
    { name: 'Lifestealer', 'Slark': -0.39 },
    { name: 'Lina', 'Slark': 0.048 },
    { name: 'Lion', 'Slark': 1.652 },
    { name: 'Lone Druid', 'Slark': -0.03 },
    { name: 'Luna', 'Slark': -0.337 },
    { name: 'Lycan', 'Slark': -1.211 },
    { name: 'Magnus', 'Slark': 1.108 },
    { name: 'Marci', 'Slark': -0.263 },
    { name: 'Mars', 'Slark': 3.723 },
    { name: 'Medusa', 'Slark': -3.725 },
    { name: 'Meepo', 'Slark': -0.523 },
    { name: 'Mirana', 'Slark': 0.459 },
    { name: 'Monkey King', 'Slark': -0.089 },
    { name: 'Morphling', 'Slark': -0.982 },
    { name: 'Muerta', 'Slark': 2.284 },
    { name: 'Naga Siren', 'Slark': 1.277 },
    { name: "Nature's Prophet", 'Slark': -0.185 },
    { name: 'Necrophos', 'Slark': 2.325 },
    { name: 'Night Stalker', 'Slark': 2.545 },
    { name: 'Nyx Assassin', 'Slark': 0.078 },
    { name: 'Ogre Magi', 'Slark': -2.235 },
    { name: 'Omniknight', 'Slark': 2.094 },
    { name: 'Oracle', 'Slark': -0.278 },
    { name: 'Outworld Destroyer', 'Slark': 1.815 },
    { name: 'Pangolier', 'Slark': -1.014 },
    { name: 'Phantom Assassin', 'Slark': -0.538 },
    { name: 'Phantom Lancer', 'Slark': 0.656 },
    { name: 'Phoenix', 'Slark': -1.18 },
    { name: 'Primal Beast', 'Slark': 1.493 },
    { name: 'Puck', 'Slark': 2.02 },
    { name: 'Pudge', 'Slark': 0.418 },
    { name: 'Pugna', 'Slark': 2.288 },
    { name: 'Queen of Pain', 'Slark': 0.352 },
    { name: 'Razor', 'Slark': -0.603 },
    { name: 'Riki', 'Slark': 2.212 },
    { name: 'Ringmaster', 'Slark': 2.199 },
    { name: 'Rubick', 'Slark': -0.745 },
    { name: 'Sand King', 'Slark': 1.371 },
    { name: 'Shadow Demon', 'Slark': 0.273 },
    { name: 'Shadow Fiend', 'Slark': 1.583 },
    { name: 'Shadow Shaman', 'Slark': 0.087 },
    { name: 'Silencer', 'Slark': -1.947 },
    { name: 'Skywrath Mage', 'Slark': 0.445 },
    { name: 'Slardar', 'Slark': -0.833 },
    { name: 'Snapfire', 'Slark': 1.103 },
    { name: 'Sniper', 'Slark': -1.844 },
    { name: 'Spectre', 'Slark': -0.338 },
    { name: 'Spirit Breaker', 'Slark': 0.074 },
    { name: 'Storm Spirit', 'Slark': -1.565 },
    { name: 'Sven', 'Slark': -0.418 },
    { name: 'Techies', 'Slark': 3.026 },
    { name: 'Templar Assassin', 'Slark': -1.826 },
    { name: 'Terrorblade', 'Slark': -0.876 },
    { name: 'Tidehunter', 'Slark': -2.361 },
    { name: 'Timbersaw', 'Slark': 2.326 },
    { name: 'Tinker', 'Slark': 0.122 },
    { name: 'Tiny', 'Slark': 1.469 },
    { name: 'Treant Protector', 'Slark': -1.869 },
    { name: 'Troll Warlord', 'Slark': -2.884 },
    { name: 'Tusk', 'Slark': 1.269 },
    { name: 'Underlord', 'Slark': -0.934 },
    { name: 'Undying', 'Slark': -0.853 },
    { name: 'Ursa', 'Slark': -0.574 },
    { name: 'Vengeful Spirit', 'Slark': 0.073 },
    { name: 'Venomancer', 'Slark': -1.865 },
    { name: 'Viper', 'Slark': -1.254 },
    { name: 'Visage', 'Slark': 0.465 },
    { name: 'Void Spirit', 'Slark': -1.009 },
    { name: 'Warlock', 'Slark': -1.383 },
    { name: 'Weaver', 'Slark': -0.607 },
    { name: 'Windranger', 'Slark': 0.331 },
    { name: 'Winter Wyvern', 'Slark': -1.584 },
    { name: 'Witch Doctor', 'Slark': -0.608 },
    { name: 'Wraith King', 'Slark': -3.162 },
    { name: 'Zeus', 'Slark': 0.83 },
],
"Sniper": [
    { name: 'Abaddon', 'Sniper': -0.805 },
    { name: 'Alchemist', 'Sniper': -0.562 },
    { name: 'Ancient Apparition', 'Sniper': -0.548 },
    { name: 'Anti-Mage', 'Sniper': 0.006 },
    { name: 'Arc Warden', 'Sniper': 0.235 },
    { name: 'Axe', 'Sniper': 1.457 },
    { name: 'Bane', 'Sniper': 0.009 },
    { name: 'Batrider', 'Sniper': 0.482 },
    { name: 'Beastmaster', 'Sniper': -0.05 },
    { name: 'Bloodseeker', 'Sniper': -0.483 },
    { name: 'Bounty Hunter', 'Sniper': 0.185 },
    { name: 'Brewmaster', 'Sniper': 0.159 },
    { name: 'Bristleback', 'Sniper': -0.995 },
    { name: 'Broodmother', 'Sniper': -0.318 },
    { name: 'Centaur Warrunner', 'Sniper': 2.632 },
    { name: 'Chaos Knight', 'Sniper': 0.905 },
    { name: 'Chen', 'Sniper': 2.395 },
    { name: 'Clinkz', 'Sniper': -0.653 },
    { name: 'Clockwerk', 'Sniper': 1.641 },
    { name: 'Crystal Maiden', 'Sniper': -0.68 },
    { name: 'Dark Seer', 'Sniper': -0.094 },
    { name: 'Dark Willow', 'Sniper': -0.086 },
    { name: 'Dawnbreaker', 'Sniper': -0.948 },
    { name: 'Dazzle', 'Sniper': -0.246 },
    { name: 'Death Prophet', 'Sniper': -1.125 },
    { name: 'Disruptor', 'Sniper': -2.196 },
    { name: 'Doom', 'Sniper': 0.105 },
    { name: 'Dragon Knight', 'Sniper': -0.077 },
    { name: 'Drow Ranger', 'Sniper': -1.368 },
    { name: 'Earth Spirit', 'Sniper': 2.212 },
    { name: 'Earthshaker', 'Sniper': -1.801 },
    { name: 'Elder Titan', 'Sniper': -1.23 },
    { name: 'Ember Spirit', 'Sniper': -1.715 },
    { name: 'Enchantress', 'Sniper': 1.204 },
    { name: 'Enigma', 'Sniper': -2.265 },
    { name: 'Faceless Void', 'Sniper': 0.688 },
    { name: 'Grimstroke', 'Sniper': -1.083 },
    { name: 'Gyrocopter', 'Sniper': -0.561 },
    { name: 'Hoodwink', 'Sniper': 0.091 },
    { name: 'Huskar', 'Sniper': -1.082 },
    { name: 'Invoker', 'Sniper': 0.525 },
    { name: 'Io', 'Sniper': -0.184 },
    { name: 'Jakiro', 'Sniper': -0.945 },
    { name: 'Juggernaut', 'Sniper': -0.7 },
    { name: 'Keeper of the Light', 'Sniper': -0.362 },
    { name: 'Kunkka', 'Sniper': 0.039 },
    { name: 'Legion Commander', 'Sniper': -0.301 },
    { name: 'Leshrac', 'Sniper': -1.1 },
    { name: 'Lich', 'Sniper': -0.897 },
    { name: 'Lifestealer', 'Sniper': -0.297 },
    { name: 'Lina', 'Sniper': -1.586 },
    { name: 'Lion', 'Sniper': -0.397 },
    { name: 'Lone Druid', 'Sniper': -0.687 },
    { name: 'Luna', 'Sniper': -0.781 },
    { name: 'Lycan', 'Sniper': 2.18 },
    { name: 'Magnus', 'Sniper': 0.622 },
    { name: 'Marci', 'Sniper': -0.321 },
    { name: 'Mars', 'Sniper': 2.468 },
    { name: 'Medusa', 'Sniper': -4.146 },
    { name: 'Meepo', 'Sniper': 1.98 },
    { name: 'Mirana', 'Sniper': 1.273 },
    { name: 'Monkey King', 'Sniper': -0.689 },
    { name: 'Morphling', 'Sniper': 3.506 },
    { name: 'Muerta', 'Sniper': 0.037 },
    { name: 'Naga Siren', 'Sniper': 0.507 },
    { name: "Nature's Prophet", 'Sniper': 1.138 },
    { name: 'Necrophos', 'Sniper': -2.058 },
    { name: 'Night Stalker', 'Sniper': -1.151 },
    { name: 'Nyx Assassin', 'Sniper': 2.324 },
    { name: 'Ogre Magi', 'Sniper': 0.134 },
    { name: 'Omniknight', 'Sniper': -0.39 },
    { name: 'Oracle', 'Sniper': -0.456 },
    { name: 'Outworld Destroyer', 'Sniper': -0.509 },
    { name: 'Pangolier', 'Sniper': 0.902 },
    { name: 'Phantom Assassin', 'Sniper': 0.969 },
    { name: 'Phantom Lancer', 'Sniper': 1.561 },
    { name: 'Phoenix', 'Sniper': -0.648 },
    { name: 'Primal Beast', 'Sniper': 2.479 },
    { name: 'Puck', 'Sniper': -0.916 },
    { name: 'Pudge', 'Sniper': 2.955 },
    { name: 'Pugna', 'Sniper': -1.079 },
    { name: 'Queen of Pain', 'Sniper': 0.656 },
    { name: 'Razor', 'Sniper': -0.715 },
    { name: 'Riki', 'Sniper': -1.049 },
    { name: 'Ringmaster', 'Sniper': -0.476 },
    { name: 'Rubick', 'Sniper': -0.051 },
    { name: 'Sand King', 'Sniper': -1.513 },
    { name: 'Shadow Demon', 'Sniper': -0.121 },
    { name: 'Shadow Fiend', 'Sniper': -0.681 },
    { name: 'Shadow Shaman', 'Sniper': -1.297 },
    { name: 'Silencer', 'Sniper': -2.111 },
    { name: 'Skywrath Mage', 'Sniper': -0.118 },
    { name: 'Slardar', 'Sniper': -0.403 },
    { name: 'Slark', 'Sniper': 1.687 },
    { name: 'Snapfire', 'Sniper': 1.003 },
    { name: 'Spectre', 'Sniper': 3.46 },
    { name: 'Spirit Breaker', 'Sniper': 1.61 },
    { name: 'Storm Spirit', 'Sniper': 3.06 },
    { name: 'Sven', 'Sniper': -0.272 },
    { name: 'Techies', 'Sniper': 0.709 },
    { name: 'Templar Assassin', 'Sniper': -1.083 },
    { name: 'Terrorblade', 'Sniper': 0.102 },
    { name: 'Tidehunter', 'Sniper': -0.973 },
    { name: 'Timbersaw', 'Sniper': 1.344 },
    { name: 'Tinker', 'Sniper': -1.805 },
    { name: 'Tiny', 'Sniper': 0.943 },
    { name: 'Treant Protector', 'Sniper': -0.922 },
    { name: 'Troll Warlord', 'Sniper': -0.066 },
    { name: 'Tusk', 'Sniper': 0.746 },
    { name: 'Underlord', 'Sniper': -2.035 },
    { name: 'Undying', 'Sniper': -1.459 },
    { name: 'Ursa', 'Sniper': 0.601 },
    { name: 'Vengeful Spirit', 'Sniper': -0.547 },
    { name: 'Venomancer', 'Sniper': -0.943 },
    { name: 'Viper', 'Sniper': -1.2 },
    { name: 'Visage', 'Sniper': -1.052 },
    { name: 'Void Spirit', 'Sniper': 1.351 },
    { name: 'Warlock', 'Sniper': -1.661 },
    { name: 'Weaver', 'Sniper': 1.03 },
    { name: 'Windranger', 'Sniper': 0.209 },
    { name: 'Winter Wyvern', 'Sniper': -1.337 },
    { name: 'Witch Doctor', 'Sniper': -1.45 },
    { name: 'Wraith King', 'Sniper': -0.934 },
    { name: 'Zeus', 'Sniper': 1.4 },
],
"Spectre": [
    { name: 'Abaddon', 'Spectre': 3.888 },
    { name: 'Alchemist', 'Spectre': 0.481 },
    { name: 'Ancient Apparition', 'Spectre': -1.275 },
    { name: 'Anti-Mage', 'Spectre': 0.691 },
    { name: 'Arc Warden', 'Spectre': -1.41 },
    { name: 'Axe', 'Spectre': -3.008 },
    { name: 'Bane', 'Spectre': -1.952 },
    { name: 'Batrider', 'Spectre': 0.94 },
    { name: 'Beastmaster', 'Spectre': 3.959 },
    { name: 'Bloodseeker', 'Spectre': 0.422 },
    { name: 'Bounty Hunter', 'Spectre': 0.43 },
    { name: 'Brewmaster', 'Spectre': 1.003 },
    { name: 'Bristleback', 'Spectre': 0.619 },
    { name: 'Broodmother', 'Spectre': -1.131 },
    { name: 'Centaur Warrunner', 'Spectre': 1.227 },
    { name: 'Chaos Knight', 'Spectre': 0.275 },
    { name: 'Chen', 'Spectre': 1.989 },
    { name: 'Clinkz', 'Spectre': -2.709 },
    { name: 'Clockwerk', 'Spectre': 0.96 },
    { name: 'Crystal Maiden', 'Spectre': 0.641 },
    { name: 'Dark Seer', 'Spectre': 1.577 },
    { name: 'Dark Willow', 'Spectre': -0.326 },
    { name: 'Dawnbreaker', 'Spectre': 2.013 },
    { name: 'Dazzle', 'Spectre': 1.342 },
    { name: 'Death Prophet', 'Spectre': -0.098 },
    { name: 'Disruptor', 'Spectre': 0.308 },
    { name: 'Doom', 'Spectre': -1.331 },
    { name: 'Dragon Knight', 'Spectre': -0.232 },
    { name: 'Drow Ranger', 'Spectre': -3.473 },
    { name: 'Earth Spirit', 'Spectre': 1.162 },
    { name: 'Earthshaker', 'Spectre': -1.727 },
    { name: 'Elder Titan', 'Spectre': 1.032 },
    { name: 'Ember Spirit', 'Spectre': 3.296 },
    { name: 'Enchantress', 'Spectre': 2.848 },
    { name: 'Enigma', 'Spectre': -2.187 },
    { name: 'Faceless Void', 'Spectre': -1.428 },
    { name: 'Grimstroke', 'Spectre': -1.102 },
    { name: 'Gyrocopter', 'Spectre': -1.036 },
    { name: 'Hoodwink', 'Spectre': -0.664 },
    { name: 'Huskar', 'Spectre': -0.137 },
    { name: 'Invoker', 'Spectre': 0.706 },
    { name: 'Io', 'Spectre': 2.038 },
    { name: 'Jakiro', 'Spectre': 1.195 },
    { name: 'Juggernaut', 'Spectre': 1.492 },
    { name: 'Keeper of the Light', 'Spectre': 0.627 },
    { name: 'Kunkka', 'Spectre': 0.854 },
    { name: 'Legion Commander', 'Spectre': 0.171 },
    { name: 'Leshrac', 'Spectre': 1.588 },
    { name: 'Lich', 'Spectre': -0.275 },
    { name: 'Lifestealer', 'Spectre': 3.271 },
    { name: 'Lina', 'Spectre': 0.577 },
    { name: 'Lion', 'Spectre': -1.12 },
    { name: 'Lone Druid', 'Spectre': 1.318 },
    { name: 'Luna', 'Spectre': -2.215 },
    { name: 'Lycan', 'Spectre': 1.706 },
    { name: 'Magnus', 'Spectre': -0.156 },
    { name: 'Marci', 'Spectre': 0.993 },
    { name: 'Mars', 'Spectre': -0.821 },
    { name: 'Medusa', 'Spectre': 0.928 },
    { name: 'Meepo', 'Spectre': 4.197 },
    { name: 'Mirana', 'Spectre': -1.654 },
    { name: 'Monkey King', 'Spectre': -0.619 },
    { name: 'Morphling', 'Spectre': 0.878 },
    { name: 'Muerta', 'Spectre': -3.353 },
    { name: 'Naga Siren', 'Spectre': 0.418 },
    { name: "Nature's Prophet", 'Spectre': -3.489 },
    { name: 'Necrophos', 'Spectre': 3.905 },
    { name: 'Night Stalker', 'Spectre': -0.844 },
    { name: 'Nyx Assassin', 'Spectre': -0.674 },
    { name: 'Ogre Magi', 'Spectre': 0.052 },
    { name: 'Omniknight', 'Spectre': 2.61 },
    { name: 'Oracle', 'Spectre': 2.261 },
    { name: 'Outworld Destroyer', 'Spectre': 0.153 },
    { name: 'Pangolier', 'Spectre': 0.911 },
    { name: 'Phantom Assassin', 'Spectre': -0.33 },
    { name: 'Phantom Lancer', 'Spectre': 0.485 },
    { name: 'Phoenix', 'Spectre': 1.724 },
    { name: 'Primal Beast', 'Spectre': 1.677 },
    { name: 'Puck', 'Spectre': -2.482 },
    { name: 'Pudge', 'Spectre': 1.652 },
    { name: 'Pugna', 'Spectre': 0.601 },
    { name: 'Queen of Pain', 'Spectre': 0.873 },
    { name: 'Razor', 'Spectre': -1.051 },
    { name: 'Riki', 'Spectre': -3.981 },
    { name: 'Ringmaster', 'Spectre': 0.118 },
    { name: 'Rubick', 'Spectre': -0.068 },
    { name: 'Sand King', 'Spectre': 0.261 },
    { name: 'Shadow Demon', 'Spectre': 0.324 },
    { name: 'Shadow Fiend', 'Spectre': -0.109 },
    { name: 'Shadow Shaman', 'Spectre': -2.864 },
    { name: 'Silencer', 'Spectre': -2.742 },
    { name: 'Skywrath Mage', 'Spectre': 0.425 },
    { name: 'Slardar', 'Spectre': 1.966 },
    { name: 'Slark', 'Spectre': 0.335 },
    { name: 'Snapfire', 'Spectre': 0.391 },
    { name: 'Sniper', 'Spectre': -4.22 },
    { name: 'Spirit Breaker', 'Spectre': -1.443 },
    { name: 'Storm Spirit', 'Spectre': -0.438 },
    { name: 'Sven', 'Spectre': 0.879 },
    { name: 'Techies', 'Spectre': -0.681 },
    { name: 'Templar Assassin', 'Spectre': -2.315 },
    { name: 'Terrorblade', 'Spectre': 1.822 },
    { name: 'Tidehunter', 'Spectre': 2.454 },
    { name: 'Timbersaw', 'Spectre': 0.174 },
    { name: 'Tinker', 'Spectre': 3.225 },
    { name: 'Tiny', 'Spectre': -1.026 },
    { name: 'Treant Protector', 'Spectre': 0.796 },
    { name: 'Troll Warlord', 'Spectre': 0.194 },
    { name: 'Tusk', 'Spectre': -0.535 },
    { name: 'Underlord', 'Spectre': 3.096 },
    { name: 'Undying', 'Spectre': 3.876 },
    { name: 'Ursa', 'Spectre': -1.702 },
    { name: 'Vengeful Spirit', 'Spectre': -0.747 },
    { name: 'Venomancer', 'Spectre': 1.454 },
    { name: 'Viper', 'Spectre': 2.938 },
    { name: 'Visage', 'Spectre': -0.054 },
    { name: 'Void Spirit', 'Spectre': 0.103 },
    { name: 'Warlock', 'Spectre': 1.949 },
    { name: 'Weaver', 'Spectre': 0.415 },
    { name: 'Windranger', 'Spectre': -2.011 },
    { name: 'Winter Wyvern', 'Spectre': -1.173 },
    { name: 'Witch Doctor', 'Spectre': 1.383 },
    { name: 'Wraith King', 'Spectre': 0.661 },
    { name: 'Zeus', 'Spectre': -1.553 },
],
"Templar Assassin": [
    { name: 'Abaddon', 'Templar Assassin': -0.18 },
    { name: 'Alchemist', 'Templar Assassin': 0.848 },
    { name: 'Ancient Apparition', 'Templar Assassin': -0.944 },
    { name: 'Anti-Mage', 'Templar Assassin': -1.785 },
    { name: 'Arc Warden', 'Templar Assassin': 2.68 },
    { name: 'Axe', 'Templar Assassin': 0.717 },
    { name: 'Bane', 'Templar Assassin': 0.028 },
    { name: 'Batrider', 'Templar Assassin': 2.646 },
    { name: 'Beastmaster', 'Templar Assassin': -0.618 },
    { name: 'Bloodseeker', 'Templar Assassin': -0.407 },
    { name: 'Bounty Hunter', 'Templar Assassin': 0.935 },
    { name: 'Brewmaster', 'Templar Assassin': 0.143 },
    { name: 'Bristleback', 'Templar Assassin': 1.434 },
    { name: 'Broodmother', 'Templar Assassin': -0.149 },
    { name: 'Centaur Warrunner', 'Templar Assassin': -1.229 },
    { name: 'Chaos Knight', 'Templar Assassin': 1.636 },
    { name: 'Chen', 'Templar Assassin': -0.259 },
    { name: 'Clinkz', 'Templar Assassin': 1.325 },
    { name: 'Clockwerk', 'Templar Assassin': 0.18 },
    { name: 'Crystal Maiden', 'Templar Assassin': 1.303 },
    { name: 'Dark Seer', 'Templar Assassin': 1.722 },
    { name: 'Dark Willow', 'Templar Assassin': 0.724 },
    { name: 'Dawnbreaker', 'Templar Assassin': -0.658 },
    { name: 'Dazzle', 'Templar Assassin': 0.331 },
    { name: 'Death Prophet', 'Templar Assassin': 0.116 },
    { name: 'Disruptor', 'Templar Assassin': -1.116 },
    { name: 'Doom', 'Templar Assassin': -0.064 },
    { name: 'Dragon Knight', 'Templar Assassin': 1.23 },
    { name: 'Drow Ranger', 'Templar Assassin': 0.811 },
    { name: 'Earth Spirit', 'Templar Assassin': -0.485 },
    { name: 'Earthshaker', 'Templar Assassin': -1.795 },
    { name: 'Elder Titan', 'Templar Assassin': -2.752 },
    { name: 'Ember Spirit', 'Templar Assassin': -2.092 },
    { name: 'Enchantress', 'Templar Assassin': 0.067 },
    { name: 'Enigma', 'Templar Assassin': -0.245 },
    { name: 'Faceless Void', 'Templar Assassin': 1.13 },
    { name: 'Grimstroke', 'Templar Assassin': -0.185 },
    { name: 'Gyrocopter', 'Templar Assassin': 0.72 },
    { name: 'Hoodwink', 'Templar Assassin': 0.506 },
    { name: 'Huskar', 'Templar Assassin': 0.826 },
    { name: 'Invoker', 'Templar Assassin': -1.697 },
    { name: 'Io', 'Templar Assassin': 0.068 },
    { name: 'Jakiro', 'Templar Assassin': 1.018 },
    { name: 'Juggernaut', 'Templar Assassin': -0.977 },
    { name: 'Keeper of the Light', 'Templar Assassin': -0.365 },
    { name: 'Kunkka', 'Templar Assassin': -0.398 },
    { name: 'Legion Commander', 'Templar Assassin': -1.642 },
    { name: 'Leshrac', 'Templar Assassin': 0.748 },
    { name: 'Lich', 'Templar Assassin': -0.263 },
    { name: 'Lifestealer', 'Templar Assassin': -0.323 },
    { name: 'Lina', 'Templar Assassin': -2.976 },
    { name: 'Lion', 'Templar Assassin': -0.661 },
    { name: 'Lone Druid', 'Templar Assassin': 0.043 },
    { name: 'Luna', 'Templar Assassin': -0.018 },
    { name: 'Lycan', 'Templar Assassin': -0.577 },
    { name: 'Magnus', 'Templar Assassin': -0.644 },
    { name: 'Marci', 'Templar Assassin': -0.929 },
    { name: 'Mars', 'Templar Assassin': 0.352 },
    { name: 'Medusa', 'Templar Assassin': 1.823 },
    { name: 'Meepo', 'Templar Assassin': 0.651 },
    { name: 'Mirana', 'Templar Assassin': -0.697 },
    { name: 'Monkey King', 'Templar Assassin': 0.222 },
    { name: 'Morphling', 'Templar Assassin': 0.877 },
    { name: 'Muerta', 'Templar Assassin': -0.774 },
    { name: 'Naga Siren', 'Templar Assassin': 2.753 },
    { name: "Nature's Prophet", 'Templar Assassin': -0.95 },
    { name: 'Necrophos', 'Templar Assassin': 1.362 },
    { name: 'Night Stalker', 'Templar Assassin': -2.625 },
    { name: 'Nyx Assassin', 'Templar Assassin': -1.75 },
    { name: 'Ogre Magi', 'Templar Assassin': 0.997 },
    { name: 'Omniknight', 'Templar Assassin': -1.248 },
    { name: 'Oracle', 'Templar Assassin': -2.95 },
    { name: 'Outworld Destroyer', 'Templar Assassin': -3.231 },
    { name: 'Pangolier', 'Templar Assassin': -1.418 },
    { name: 'Phantom Assassin', 'Templar Assassin': -1.105 },
    { name: 'Phantom Lancer', 'Templar Assassin': 5.471 },
    { name: 'Phoenix', 'Templar Assassin': 2.564 },
    { name: 'Primal Beast', 'Templar Assassin': 0.793 },
    { name: 'Puck', 'Templar Assassin': -2.077 },
    { name: 'Pudge', 'Templar Assassin': 0.808 },
    { name: 'Pugna', 'Templar Assassin': 1.187 },
    { name: 'Queen of Pain', 'Templar Assassin': -1.994 },
    { name: 'Razor', 'Templar Assassin': 0.424 },
    { name: 'Riki', 'Templar Assassin': 1.201 },
    { name: 'Ringmaster', 'Templar Assassin': -0.282 },
    { name: 'Rubick', 'Templar Assassin': -2.222 },
    { name: 'Sand King', 'Templar Assassin': 2.233 },
    { name: 'Shadow Demon', 'Templar Assassin': -0.169 },
    { name: 'Shadow Fiend', 'Templar Assassin': -0.363 },
    { name: 'Shadow Shaman', 'Templar Assassin': 1.884 },
    { name: 'Silencer', 'Templar Assassin': 0.326 },
    { name: 'Skywrath Mage', 'Templar Assassin': -2.241 },
    { name: 'Slardar', 'Templar Assassin': 0.946 },
    { name: 'Slark', 'Templar Assassin': 2.375 },
    { name: 'Snapfire', 'Templar Assassin': -0.903 },
    { name: 'Sniper', 'Templar Assassin': 2.156 },
    { name: 'Spectre', 'Templar Assassin': 2.549 },
    { name: 'Spirit Breaker', 'Templar Assassin': 0.219 },
    { name: 'Storm Spirit', 'Templar Assassin': 0.531 },
    { name: 'Sven', 'Templar Assassin': -1.866 },
    { name: 'Techies', 'Templar Assassin': -2.058 },
    { name: 'Terrorblade', 'Templar Assassin': 2.788 },
    { name: 'Tidehunter', 'Templar Assassin': -0.314 },
    { name: 'Timbersaw', 'Templar Assassin': -2.179 },
    { name: 'Tinker', 'Templar Assassin': -0.779 },
    { name: 'Tiny', 'Templar Assassin': 0.226 },
    { name: 'Treant Protector', 'Templar Assassin': 2.01 },
    { name: 'Troll Warlord', 'Templar Assassin': 2.203 },
    { name: 'Tusk', 'Templar Assassin': -0.838 },
    { name: 'Underlord', 'Templar Assassin': 0.798 },
    { name: 'Undying', 'Templar Assassin': 0.638 },
    { name: 'Ursa', 'Templar Assassin': -1.639 },
    { name: 'Vengeful Spirit', 'Templar Assassin': -0.241 },
    { name: 'Venomancer', 'Templar Assassin': 1.968 },
    { name: 'Viper', 'Templar Assassin': 1.833 },
    { name: 'Visage', 'Templar Assassin': 0.11 },
    { name: 'Void Spirit', 'Templar Assassin': -0.608 },
    { name: 'Warlock', 'Templar Assassin': 0.385 },
    { name: 'Weaver', 'Templar Assassin': -0.674 },
    { name: 'Windranger', 'Templar Assassin': 0.061 },
    { name: 'Winter Wyvern', 'Templar Assassin': 0.452 },
    { name: 'Witch Doctor', 'Templar Assassin': -0.838 },
    { name: 'Wraith King', 'Templar Assassin': 0.917 },
    { name: 'Zeus', 'Templar Assassin': -0.6 },
],
"Terrorblade": [
    { name: 'Abaddon', 'Terrorblade': -1.353 },
    { name: 'Alchemist', 'Terrorblade': -1.83 },
    { name: 'Ancient Apparition', 'Terrorblade': -0.261 },
    { name: 'Anti-Mage', 'Terrorblade': -2.621 },
    { name: 'Arc Warden', 'Terrorblade': -1.014 },
    { name: 'Axe', 'Terrorblade': 5.354 },
    { name: 'Bane', 'Terrorblade': -1.68 },
    { name: 'Batrider', 'Terrorblade': -0.513 },
    { name: 'Beastmaster', 'Terrorblade': 0.248 },
    { name: 'Bloodseeker', 'Terrorblade': -0.847 },
    { name: 'Bounty Hunter', 'Terrorblade': -0.647 },
    { name: 'Brewmaster', 'Terrorblade': 2.35 },
    { name: 'Bristleback', 'Terrorblade': 0.864 },
    { name: 'Broodmother', 'Terrorblade': -5.733 },
    { name: 'Centaur Warrunner', 'Terrorblade': -0.11 },
    { name: 'Chaos Knight', 'Terrorblade': -1.996 },
    { name: 'Chen', 'Terrorblade': -0.844 },
    { name: 'Clinkz', 'Terrorblade': -2.118 },
    { name: 'Clockwerk', 'Terrorblade': -1.062 },
    { name: 'Crystal Maiden', 'Terrorblade': 1.006 },
    { name: 'Dark Seer', 'Terrorblade': 3.072 },
    { name: 'Dark Willow', 'Terrorblade': -0.386 },
    { name: 'Dawnbreaker', 'Terrorblade': 0.662 },
    { name: 'Dazzle', 'Terrorblade': 1.247 },
    { name: 'Death Prophet', 'Terrorblade': -2.513 },
    { name: 'Disruptor', 'Terrorblade': -0.921 },
    { name: 'Doom', 'Terrorblade': -0.842 },
    { name: 'Dragon Knight', 'Terrorblade': -1.904 },
    { name: 'Drow Ranger', 'Terrorblade': 0.699 },
    { name: 'Earth Spirit', 'Terrorblade': -0.746 },
    { name: 'Earthshaker', 'Terrorblade': 1.978 },
    { name: 'Elder Titan', 'Terrorblade': 1.069 },
    { name: 'Ember Spirit', 'Terrorblade': 1.103 },
    { name: 'Enchantress', 'Terrorblade': -0.826 },
    { name: 'Enigma', 'Terrorblade': -0.118 },
    { name: 'Faceless Void', 'Terrorblade': -0.137 },
    { name: 'Grimstroke', 'Terrorblade': 0.208 },
    { name: 'Gyrocopter', 'Terrorblade': 0.323 },
    { name: 'Hoodwink', 'Terrorblade': -0.054 },
    { name: 'Huskar', 'Terrorblade': -2.223 },
    { name: 'Invoker', 'Terrorblade': -0.495 },
    { name: 'Io', 'Terrorblade': -1.342 },
    { name: 'Jakiro', 'Terrorblade': 1.349 },
    { name: 'Juggernaut', 'Terrorblade': 0.46 },
    { name: 'Keeper of the Light', 'Terrorblade': 0.276 },
    { name: 'Kunkka', 'Terrorblade': 0.318 },
    { name: 'Legion Commander', 'Terrorblade': 2.028 },
    { name: 'Leshrac', 'Terrorblade': 1.017 },
    { name: 'Lich', 'Terrorblade': 1.887 },
    { name: 'Lifestealer', 'Terrorblade': 0.277 },
    { name: 'Lina', 'Terrorblade': 2.282 },
    { name: 'Lion', 'Terrorblade': 0.907 },
    { name: 'Lone Druid', 'Terrorblade': -3.115 },
    { name: 'Luna', 'Terrorblade': -0.54 },
    { name: 'Lycan', 'Terrorblade': -3.015 },
    { name: 'Magnus', 'Terrorblade': 0.21 },
    { name: 'Marci', 'Terrorblade': -0.891 },
    { name: 'Mars', 'Terrorblade': -0.141 },
    { name: 'Medusa', 'Terrorblade': -1.202 },
    { name: 'Meepo', 'Terrorblade': -0.92 },
    { name: 'Mirana', 'Terrorblade': -1.305 },
    { name: 'Monkey King', 'Terrorblade': -0.661 },
    { name: 'Morphling', 'Terrorblade': -0.842 },
    { name: 'Muerta', 'Terrorblade': 1.699 },
    { name: 'Naga Siren', 'Terrorblade': 2.389 },
    { name: "Nature's Prophet", 'Terrorblade': -0.8 },
    { name: 'Necrophos', 'Terrorblade': 0.439 },
    { name: 'Night Stalker', 'Terrorblade': -3.724 },
    { name: 'Nyx Assassin', 'Terrorblade': -0.221 },
    { name: 'Ogre Magi', 'Terrorblade': -0.999 },
    { name: 'Omniknight', 'Terrorblade': -0.517 },
    { name: 'Oracle', 'Terrorblade': -2.744 },
    { name: 'Outworld Destroyer', 'Terrorblade': -0.382 },
    { name: 'Pangolier', 'Terrorblade': -1.981 },
    { name: 'Phantom Assassin', 'Terrorblade': 0.553 },
    { name: 'Phantom Lancer', 'Terrorblade': 3.493 },
    { name: 'Phoenix', 'Terrorblade': 1.176 },
    { name: 'Primal Beast', 'Terrorblade': 1.931 },
    { name: 'Puck', 'Terrorblade': 0.343 },
    { name: 'Pudge', 'Terrorblade': -1.595 },
    { name: 'Pugna', 'Terrorblade': 0.317 },
    { name: 'Queen of Pain', 'Terrorblade': 0.505 },
    { name: 'Razor', 'Terrorblade': -1.801 },
    { name: 'Riki', 'Terrorblade': -0.099 },
    { name: 'Ringmaster', 'Terrorblade': 0.76 },
    { name: 'Rubick', 'Terrorblade': -0.001 },
    { name: 'Sand King', 'Terrorblade': 3.584 },
    { name: 'Shadow Demon', 'Terrorblade': -1.136 },
    { name: 'Shadow Fiend', 'Terrorblade': -0.789 },
    { name: 'Shadow Shaman', 'Terrorblade': -1.268 },
    { name: 'Silencer', 'Terrorblade': -1.671 },
    { name: 'Skywrath Mage', 'Terrorblade': 0.659 },
    { name: 'Slardar', 'Terrorblade': -3.941 },
    { name: 'Slark', 'Terrorblade': 1.313 },
    { name: 'Snapfire', 'Terrorblade': 1.121 },
    { name: 'Sniper', 'Terrorblade': 0.628 },
    { name: 'Spectre', 'Terrorblade': -1.687 },
    { name: 'Spirit Breaker', 'Terrorblade': 0.039 },
    { name: 'Storm Spirit', 'Terrorblade': -0.455 },
    { name: 'Sven', 'Terrorblade': -0.718 },
    { name: 'Techies', 'Terrorblade': 1.561 },
    { name: 'Templar Assassin', 'Terrorblade': -2.893 },
    { name: 'Tidehunter', 'Terrorblade': -0.576 },
    { name: 'Timbersaw', 'Terrorblade': 1.985 },
    { name: 'Tinker', 'Terrorblade': 0.259 },
    { name: 'Tiny', 'Terrorblade': -0.029 },
    { name: 'Treant Protector', 'Terrorblade': -0.547 },
    { name: 'Troll Warlord', 'Terrorblade': 0.342 },
    { name: 'Tusk', 'Terrorblade': -1.91 },
    { name: 'Underlord', 'Terrorblade': 1.224 },
    { name: 'Undying', 'Terrorblade': -0.445 },
    { name: 'Ursa', 'Terrorblade': -1.352 },
    { name: 'Vengeful Spirit', 'Terrorblade': -2.13 },
    { name: 'Venomancer', 'Terrorblade': -0.61 },
    { name: 'Viper', 'Terrorblade': -3.923 },
    { name: 'Visage', 'Terrorblade': -2.896 },
    { name: 'Void Spirit', 'Terrorblade': -1.191 },
    { name: 'Warlock', 'Terrorblade': 5.101 },
    { name: 'Weaver', 'Terrorblade': 0.05 },
    { name: 'Windranger', 'Terrorblade': 0.79 },
    { name: 'Winter Wyvern', 'Terrorblade': 2.01 },
    { name: 'Witch Doctor', 'Terrorblade': 1.08 },
    { name: 'Wraith King', 'Terrorblade': -1.866 },
    { name: 'Zeus', 'Terrorblade': 1.582 },
],
"Troll Warlord": [
    { name: 'Abaddon', 'Troll Warlord': 0.98 },
    { name: 'Alchemist', 'Troll Warlord': -0.079 },
    { name: 'Ancient Apparition', 'Troll Warlord': 1.458 },
    { name: 'Anti-Mage', 'Troll Warlord': -4.407 },
    { name: 'Arc Warden', 'Troll Warlord': -1.186 },
    { name: 'Axe', 'Troll Warlord': 4.851 },
    { name: 'Bane', 'Troll Warlord': 1.116 },
    { name: 'Batrider', 'Troll Warlord': 1.781 },
    { name: 'Beastmaster', 'Troll Warlord': -0.882 },
    { name: 'Bloodseeker', 'Troll Warlord': -1.605 },
    { name: 'Bounty Hunter', 'Troll Warlord': -1.824 },
    { name: 'Brewmaster', 'Troll Warlord': 1.498 },
    { name: 'Bristleback', 'Troll Warlord': 2.059 },
    { name: 'Broodmother', 'Troll Warlord': -2.019 },
    { name: 'Centaur Warrunner', 'Troll Warlord': 0.364 },
    { name: 'Chaos Knight', 'Troll Warlord': -1.535 },
    { name: 'Chen', 'Troll Warlord': -1.416 },
    { name: 'Clinkz', 'Troll Warlord': -0.57 },
    { name: 'Clockwerk', 'Troll Warlord': -0.239 },
    { name: 'Crystal Maiden', 'Troll Warlord': 1.357 },
    { name: 'Dark Seer', 'Troll Warlord': 0.437 },
    { name: 'Dark Willow', 'Troll Warlord': 0.814 },
    { name: 'Dawnbreaker', 'Troll Warlord': -1.567 },
    { name: 'Dazzle', 'Troll Warlord': -0.68 },
    { name: 'Death Prophet', 'Troll Warlord': 1.751 },
    { name: 'Disruptor', 'Troll Warlord': 0.37 },
    { name: 'Doom', 'Troll Warlord': 1.416 },
    { name: 'Dragon Knight', 'Troll Warlord': 0.175 },
    { name: 'Drow Ranger', 'Troll Warlord': 0.489 },
    { name: 'Earth Spirit', 'Troll Warlord': -3.434 },
    { name: 'Earthshaker', 'Troll Warlord': -0.491 },
    { name: 'Elder Titan', 'Troll Warlord': -4.423 },
    { name: 'Ember Spirit', 'Troll Warlord': -2.129 },
    { name: 'Enchantress', 'Troll Warlord': -0.177 },
    { name: 'Enigma', 'Troll Warlord': -0.462 },
    { name: 'Faceless Void', 'Troll Warlord': -0.994 },
    { name: 'Grimstroke', 'Troll Warlord': 0.806 },
    { name: 'Gyrocopter', 'Troll Warlord': 0.818 },
    { name: 'Hoodwink', 'Troll Warlord': 0.487 },
    { name: 'Huskar', 'Troll Warlord': -1.302 },
    { name: 'Invoker', 'Troll Warlord': 1.229 },
    { name: 'Io', 'Troll Warlord': -3.392 },
    { name: 'Jakiro', 'Troll Warlord': 1.009 },
    { name: 'Juggernaut', 'Troll Warlord': -0.268 },
    { name: 'Keeper of the Light', 'Troll Warlord': 0.51 },
    { name: 'Kunkka', 'Troll Warlord': 1.205 },
    { name: 'Legion Commander', 'Troll Warlord': -1.341 },
    { name: 'Leshrac', 'Troll Warlord': 1.248 },
    { name: 'Lich', 'Troll Warlord': 0.198 },
    { name: 'Lifestealer', 'Troll Warlord': -0.902 },
    { name: 'Lina', 'Troll Warlord': 1.384 },
    { name: 'Lion', 'Troll Warlord': 0.985 },
    { name: 'Lone Druid', 'Troll Warlord': -1.884 },
    { name: 'Luna', 'Troll Warlord': 0.123 },
    { name: 'Lycan', 'Troll Warlord': -4.732 },
    { name: 'Magnus', 'Troll Warlord': -2.154 },
    { name: 'Marci', 'Troll Warlord': -3.767 },
    { name: 'Mars', 'Troll Warlord': 0.921 },
    { name: 'Medusa', 'Troll Warlord': 1.203 },
    { name: 'Meepo', 'Troll Warlord': -6.358 },
    { name: 'Mirana', 'Troll Warlord': 1.001 },
    { name: 'Monkey King', 'Troll Warlord': -0.158 },
    { name: 'Morphling', 'Troll Warlord': -0.51 },
    { name: 'Muerta', 'Troll Warlord': 2.145 },
    { name: 'Naga Siren', 'Troll Warlord': 0.498 },
    { name: "Nature's Prophet", 'Troll Warlord': 0.122 },
    { name: 'Necrophos', 'Troll Warlord': 1.616 },
    { name: 'Night Stalker', 'Troll Warlord': -2.536 },
    { name: 'Nyx Assassin', 'Troll Warlord': -0.769 },
    { name: 'Ogre Magi', 'Troll Warlord': 1.893 },
    { name: 'Omniknight', 'Troll Warlord': -0.814 },
    { name: 'Oracle', 'Troll Warlord': 0.68 },
    { name: 'Outworld Destroyer', 'Troll Warlord': 3.541 },
    { name: 'Pangolier', 'Troll Warlord': 0.412 },
    { name: 'Phantom Assassin', 'Troll Warlord': -2.157 },
    { name: 'Phantom Lancer', 'Troll Warlord': 0.271 },
    { name: 'Phoenix', 'Troll Warlord': 0.69 },
    { name: 'Primal Beast', 'Troll Warlord': 0.695 },
    { name: 'Puck', 'Troll Warlord': 0.186 },
    { name: 'Pudge', 'Troll Warlord': -0.435 },
    { name: 'Pugna', 'Troll Warlord': 2.811 },
    { name: 'Queen of Pain', 'Troll Warlord': 0.371 },
    { name: 'Razor', 'Troll Warlord': 4.49 },
    { name: 'Riki', 'Troll Warlord': -0.231 },
    { name: 'Ringmaster', 'Troll Warlord': 1.95 },
    { name: 'Rubick', 'Troll Warlord': 0.4 },
    { name: 'Sand King', 'Troll Warlord': -0.784 },
    { name: 'Shadow Demon', 'Troll Warlord': 0.331 },
    { name: 'Shadow Fiend', 'Troll Warlord': -1.291 },
    { name: 'Shadow Shaman', 'Troll Warlord': 1.112 },
    { name: 'Silencer', 'Troll Warlord': 0.407 },
    { name: 'Skywrath Mage', 'Troll Warlord': 0.808 },
    { name: 'Slardar', 'Troll Warlord': -3.708 },
    { name: 'Slark', 'Troll Warlord': 3.047 },
    { name: 'Snapfire', 'Troll Warlord': 0.036 },
    { name: 'Sniper', 'Troll Warlord': -0.024 },
    { name: 'Spectre', 'Troll Warlord': -0.215 },
    { name: 'Spirit Breaker', 'Troll Warlord': -0.606 },
    { name: 'Storm Spirit', 'Troll Warlord': -2.195 },
    { name: 'Sven', 'Troll Warlord': -2.404 },
    { name: 'Techies', 'Troll Warlord': 0.942 },
    { name: 'Templar Assassin', 'Troll Warlord': -2.122 },
    { name: 'Terrorblade', 'Troll Warlord': -0.161 },
    { name: 'Tidehunter', 'Troll Warlord': 1.279 },
    { name: 'Timbersaw', 'Troll Warlord': -0.988 },
    { name: 'Tinker', 'Troll Warlord': 0.505 },
    { name: 'Tiny', 'Troll Warlord': 0.263 },
    { name: 'Treant Protector', 'Troll Warlord': -0.441 },
    { name: 'Tusk', 'Troll Warlord': 0.377 },
    { name: 'Underlord', 'Troll Warlord': 1.897 },
    { name: 'Undying', 'Troll Warlord': -0.722 },
    { name: 'Ursa', 'Troll Warlord': -1.956 },
    { name: 'Vengeful Spirit', 'Troll Warlord': -0.334 },
    { name: 'Venomancer', 'Troll Warlord': 0.218 },
    { name: 'Viper', 'Troll Warlord': -0.084 },
    { name: 'Visage', 'Troll Warlord': 0.314 },
    { name: 'Void Spirit', 'Troll Warlord': -2.845 },
    { name: 'Warlock', 'Troll Warlord': -0.394 },
    { name: 'Weaver', 'Troll Warlord': -0.023 },
    { name: 'Windranger', 'Troll Warlord': 2.305 },
    { name: 'Winter Wyvern', 'Troll Warlord': 2.708 },
    { name: 'Witch Doctor', 'Troll Warlord': 0.559 },
    { name: 'Wraith King', 'Troll Warlord': -0.573 },
    { name: 'Zeus', 'Troll Warlord': 0.656 },
],
"Ursa": [
    { name: 'Abaddon', 'Ursa': -0.897 },
    { name: 'Alchemist', 'Ursa': 0.73 },
    { name: 'Ancient Apparition', 'Ursa': -1.016 },
    { name: 'Anti-Mage', 'Ursa': -1.719 },
    { name: 'Arc Warden', 'Ursa': 0.128 },
    { name: 'Axe', 'Ursa': -1.071 },
    { name: 'Bane', 'Ursa': -0.562 },
    { name: 'Batrider', 'Ursa': 1.272 },
    { name: 'Beastmaster', 'Ursa': 0.983 },
    { name: 'Bloodseeker', 'Ursa': -0.337 },
    { name: 'Bounty Hunter', 'Ursa': 0.201 },
    { name: 'Brewmaster', 'Ursa': 1.422 },
    { name: 'Bristleback', 'Ursa': 3.249 },
    { name: 'Broodmother', 'Ursa': 1.383 },
    { name: 'Centaur Warrunner', 'Ursa': -1.355 },
    { name: 'Chaos Knight', 'Ursa': 1.747 },
    { name: 'Chen', 'Ursa': 1.295 },
    { name: 'Clinkz', 'Ursa': 2.152 },
    { name: 'Clockwerk', 'Ursa': -0.734 },
    { name: 'Crystal Maiden', 'Ursa': 0.255 },
    { name: 'Dark Seer', 'Ursa': -2.704 },
    { name: 'Dark Willow', 'Ursa': 0.337 },
    { name: 'Dawnbreaker', 'Ursa': -1.008 },
    { name: 'Dazzle', 'Ursa': 1.014 },
    { name: 'Death Prophet', 'Ursa': 1.207 },
    { name: 'Disruptor', 'Ursa': 1.461 },
    { name: 'Doom', 'Ursa': 0.564 },
    { name: 'Dragon Knight', 'Ursa': -0.222 },
    { name: 'Drow Ranger', 'Ursa': 0.966 },
    { name: 'Earth Spirit', 'Ursa': -1.01 },
    { name: 'Earthshaker', 'Ursa': 0.428 },
    { name: 'Elder Titan', 'Ursa': -0.83 },
    { name: 'Ember Spirit', 'Ursa': -0.373 },
    { name: 'Enchantress', 'Ursa': 0.457 },
    { name: 'Enigma', 'Ursa': 1.767 },
    { name: 'Faceless Void', 'Ursa': 0.388 },
    { name: 'Grimstroke', 'Ursa': 0.142 },
    { name: 'Gyrocopter', 'Ursa': -0.012 },
    { name: 'Hoodwink', 'Ursa': 0.482 },
    { name: 'Huskar', 'Ursa': 0.711 },
    { name: 'Invoker', 'Ursa': 0.201 },
    { name: 'Io', 'Ursa': -0.439 },
    { name: 'Jakiro', 'Ursa': -0.433 },
    { name: 'Juggernaut', 'Ursa': -1.518 },
    { name: 'Keeper of the Light', 'Ursa': 0.184 },
    { name: 'Kunkka', 'Ursa': -0.522 },
    { name: 'Legion Commander', 'Ursa': -1.04 },
    { name: 'Leshrac', 'Ursa': -1.721 },
    { name: 'Lich', 'Ursa': -0.514 },
    { name: 'Lifestealer', 'Ursa': -0.341 },
    { name: 'Lina', 'Ursa': -1.307 },
    { name: 'Lion', 'Ursa': 0.468 },
    { name: 'Lone Druid', 'Ursa': -0.223 },
    { name: 'Luna', 'Ursa': -0.505 },
    { name: 'Lycan', 'Ursa': -0.885 },
    { name: 'Magnus', 'Ursa': -0.53 },
    { name: 'Marci', 'Ursa': -0.348 },
    { name: 'Mars', 'Ursa': -0.007 },
    { name: 'Medusa', 'Ursa': -1.082 },
    { name: 'Meepo', 'Ursa': 0.24 },
    { name: 'Mirana', 'Ursa': 1.004 },
    { name: 'Monkey King', 'Ursa': 0.109 },
    { name: 'Morphling', 'Ursa': -0.026 },
    { name: 'Muerta', 'Ursa': 0.333 },
    { name: 'Naga Siren', 'Ursa': 2.684 },
    { name: "Nature's Prophet", 'Ursa': 0.173 },
    { name: 'Necrophos', 'Ursa': 1.189 },
    { name: 'Night Stalker', 'Ursa': 0.718 },
    { name: 'Nyx Assassin', 'Ursa': -2.084 },
    { name: 'Ogre Magi', 'Ursa': 2.355 },
    { name: 'Omniknight', 'Ursa': 0.432 },
    { name: 'Oracle', 'Ursa': -1.032 },
    { name: 'Outworld Destroyer', 'Ursa': -0.035 },
    { name: 'Pangolier', 'Ursa': -0.137 },
    { name: 'Phantom Assassin', 'Ursa': -0.519 },
    { name: 'Phantom Lancer', 'Ursa': 4.234 },
    { name: 'Phoenix', 'Ursa': -0.193 },
    { name: 'Primal Beast', 'Ursa': -0.194 },
    { name: 'Puck', 'Ursa': -0.311 },
    { name: 'Pudge', 'Ursa': -2.735 },
    { name: 'Pugna', 'Ursa': -0.235 },
    { name: 'Queen of Pain', 'Ursa': -0.345 },
    { name: 'Razor', 'Ursa': 1.334 },
    { name: 'Riki', 'Ursa': 2.942 },
    { name: 'Ringmaster', 'Ursa': -0.026 },
    { name: 'Rubick', 'Ursa': -1.627 },
    { name: 'Sand King', 'Ursa': -1.009 },
    { name: 'Shadow Demon', 'Ursa': 0.305 },
    { name: 'Shadow Fiend', 'Ursa': -1.131 },
    { name: 'Shadow Shaman', 'Ursa': 2.399 },
    { name: 'Silencer', 'Ursa': 0.833 },
    { name: 'Skywrath Mage', 'Ursa': -0.003 },
    { name: 'Slardar', 'Ursa': 2.194 },
    { name: 'Slark', 'Ursa': 0.571 },
    { name: 'Snapfire', 'Ursa': -0.531 },
    { name: 'Sniper', 'Ursa': -0.666 },
    { name: 'Spectre', 'Ursa': 1.617 },
    { name: 'Spirit Breaker', 'Ursa': -0.054 },
    { name: 'Storm Spirit', 'Ursa': 0.463 },
    { name: 'Sven', 'Ursa': 0.035 },
    { name: 'Techies', 'Ursa': 0.673 },
    { name: 'Templar Assassin', 'Ursa': 1.915 },
    { name: 'Terrorblade', 'Ursa': 1.622 },
    { name: 'Tidehunter', 'Ursa': -1.826 },
    { name: 'Timbersaw', 'Ursa': -2.827 },
    { name: 'Tinker', 'Ursa': -0.446 },
    { name: 'Tiny', 'Ursa': 0.28 },
    { name: 'Treant Protector', 'Ursa': -0.604 },
    { name: 'Troll Warlord', 'Ursa': 1.721 },
    { name: 'Tusk', 'Ursa': 0.906 },
    { name: 'Underlord', 'Ursa': -2.906 },
    { name: 'Undying', 'Ursa': 1.989 },
    { name: 'Vengeful Spirit', 'Ursa': 0.498 },
    { name: 'Venomancer', 'Ursa': 1.969 },
    { name: 'Viper', 'Ursa': 0.924 },
    { name: 'Visage', 'Ursa': 0.149 },
    { name: 'Void Spirit', 'Ursa': -0.447 },
    { name: 'Warlock', 'Ursa': -1.411 },
    { name: 'Weaver', 'Ursa': 2.053 },
    { name: 'Windranger', 'Ursa': 1.722 },
    { name: 'Winter Wyvern', 'Ursa': 1.199 },
    { name: 'Witch Doctor', 'Ursa': -0.597 },
    { name: 'Wraith King', 'Ursa': 1.892 },
    { name: 'Zeus', 'Ursa': 0.643 },
],
"Viper": [
    { name: 'Abaddon', 'Viper': -0.612 },
    { name: 'Alchemist', 'Viper': -0.956 },
    { name: 'Ancient Apparition', 'Viper': -0.053 },
    { name: 'Anti-Mage', 'Viper': -0.137 },
    { name: 'Arc Warden', 'Viper': 1.938 },
    { name: 'Axe', 'Viper': -1.367 },
    { name: 'Bane', 'Viper': -0.13 },
    { name: 'Batrider', 'Viper': -1.46 },
    { name: 'Beastmaster', 'Viper': -0.802 },
    { name: 'Bloodseeker', 'Viper': -2.185 },
    { name: 'Bounty Hunter', 'Viper': -0.733 },
    { name: 'Brewmaster', 'Viper': 1.507 },
    { name: 'Bristleback', 'Viper': -5.498 },
    { name: 'Broodmother', 'Viper': 0.484 },
    { name: 'Centaur Warrunner', 'Viper': 0.079 },
    { name: 'Chaos Knight', 'Viper': 3.839 },
    { name: 'Chen', 'Viper': -1.064 },
    { name: 'Clinkz', 'Viper': 0.876 },
    { name: 'Clockwerk', 'Viper': -1.566 },
    { name: 'Crystal Maiden', 'Viper': 0.793 },
    { name: 'Dark Seer', 'Viper': 3.424 },
    { name: 'Dark Willow', 'Viper': -0.366 },
    { name: 'Dawnbreaker', 'Viper': -0.288 },
    { name: 'Dazzle', 'Viper': -1.105 },
    { name: 'Death Prophet', 'Viper': -0.081 },
    { name: 'Disruptor', 'Viper': -1.288 },
    { name: 'Doom', 'Viper': -1.021 },
    { name: 'Dragon Knight', 'Viper': -3.122 },
    { name: 'Drow Ranger', 'Viper': 1.643 },
    { name: 'Earth Spirit', 'Viper': 0.857 },
    { name: 'Earthshaker', 'Viper': -1.633 },
    { name: 'Elder Titan', 'Viper': -0.582 },
    { name: 'Ember Spirit', 'Viper': -0.992 },
    { name: 'Enchantress', 'Viper': -0.255 },
    { name: 'Enigma', 'Viper': 0.633 },
    { name: 'Faceless Void', 'Viper': -0.134 },
    { name: 'Grimstroke', 'Viper': 0.581 },
    { name: 'Gyrocopter', 'Viper': -1.308 },
    { name: 'Hoodwink', 'Viper': 0.582 },
    { name: 'Huskar', 'Viper': -6.269 },
    { name: 'Invoker', 'Viper': 1.537 },
    { name: 'Io', 'Viper': -0.834 },
    { name: 'Jakiro', 'Viper': -0.051 },
    { name: 'Juggernaut', 'Viper': 2.101 },
    { name: 'Keeper of the Light', 'Viper': -0.645 },
    { name: 'Kunkka', 'Viper': -0.411 },
    { name: 'Legion Commander', 'Viper': 1.804 },
    { name: 'Leshrac', 'Viper': -0.107 },
    { name: 'Lich', 'Viper': -0.076 },
    { name: 'Lifestealer', 'Viper': -1.475 },
    { name: 'Lina', 'Viper': 0.509 },
    { name: 'Lion', 'Viper': -0.755 },
    { name: 'Lone Druid', 'Viper': -2.18 },
    { name: 'Luna', 'Viper': -0.304 },
    { name: 'Lycan', 'Viper': 1.556 },
    { name: 'Magnus', 'Viper': 0.892 },
    { name: 'Marci', 'Viper': 0.509 },
    { name: 'Mars', 'Viper': 0.827 },
    { name: 'Medusa', 'Viper': 2.015 },
    { name: 'Meepo', 'Viper': 0.613 },
    { name: 'Mirana', 'Viper': 1.427 },
    { name: 'Monkey King', 'Viper': -1.649 },
    { name: 'Morphling', 'Viper': 3.138 },
    { name: 'Muerta', 'Viper': -0.339 },
    { name: 'Naga Siren', 'Viper': 3.649 },
    { name: "Nature's Prophet", 'Viper': 0.025 },
    { name: 'Necrophos', 'Viper': -2.775 },
    { name: 'Night Stalker', 'Viper': -2.451 },
    { name: 'Nyx Assassin', 'Viper': -0.721 },
    { name: 'Ogre Magi', 'Viper': -0.551 },
    { name: 'Omniknight', 'Viper': -0.665 },
    { name: 'Oracle', 'Viper': -0.607 },
    { name: 'Outworld Destroyer', 'Viper': -0.161 },
    { name: 'Pangolier', 'Viper': 0.85 },
    { name: 'Phantom Assassin', 'Viper': -0.286 },
    { name: 'Phantom Lancer', 'Viper': 5.387 },
    { name: 'Phoenix', 'Viper': -0.161 },
    { name: 'Primal Beast', 'Viper': -0.056 },
    { name: 'Puck', 'Viper': 0.589 },
    { name: 'Pudge', 'Viper': 2.418 },
    { name: 'Pugna', 'Viper': -0.396 },
    { name: 'Queen of Pain', 'Viper': -0.223 },
    { name: 'Razor', 'Viper': -1.895 },
    { name: 'Riki', 'Viper': 1.126 },
    { name: 'Ringmaster', 'Viper': 0.858 },
    { name: 'Rubick', 'Viper': 0.02 },
    { name: 'Sand King', 'Viper': 0.482 },
    { name: 'Shadow Demon', 'Viper': 2.904 },
    { name: 'Shadow Fiend', 'Viper': -0.29 },
    { name: 'Shadow Shaman', 'Viper': 0.9 },
    { name: 'Silencer', 'Viper': -0.622 },
    { name: 'Skywrath Mage', 'Viper': 0.235 },
    { name: 'Slardar', 'Viper': -0.301 },
    { name: 'Slark', 'Viper': 1.254 },
    { name: 'Snapfire', 'Viper': 0.566 },
    { name: 'Sniper', 'Viper': 1.222 },
    { name: 'Spectre', 'Viper': -2.897 },
    { name: 'Spirit Breaker', 'Viper': -0.419 },
    { name: 'Storm Spirit', 'Viper': 1.024 },
    { name: 'Sven', 'Viper': -1.578 },
    { name: 'Techies', 'Viper': 0.331 },
    { name: 'Templar Assassin', 'Viper': -1.515 },
    { name: 'Terrorblade', 'Viper': 3.997 },
    { name: 'Tidehunter', 'Viper': 0.07 },
    { name: 'Timbersaw', 'Viper': -0.207 },
    { name: 'Tinker', 'Viper': -0.56 },
    { name: 'Tiny', 'Viper': -2.408 },
    { name: 'Treant Protector', 'Viper': -1.448 },
    { name: 'Troll Warlord', 'Viper': 0.05 },
    { name: 'Tusk', 'Viper': 0.083 },
    { name: 'Underlord', 'Viper': -1.266 },
    { name: 'Undying', 'Viper': 0.403 },
    { name: 'Ursa', 'Viper': -1.007 },
    { name: 'Vengeful Spirit', 'Viper': 0.053 },
    { name: 'Venomancer', 'Viper': -0.829 },
    { name: 'Visage', 'Viper': -1.306 },
    { name: 'Void Spirit', 'Viper': 0.398 },
    { name: 'Warlock', 'Viper': 1.055 },
    { name: 'Weaver', 'Viper': 1.587 },
    { name: 'Windranger', 'Viper': 3.173 },
    { name: 'Winter Wyvern', 'Viper': -0.991 },
    { name: 'Witch Doctor', 'Viper': 2.234 },
    { name: 'Wraith King', 'Viper': -0.206 },
    { name: 'Zeus', 'Viper': -0.614 },
],
"Weaver": [
    { name: 'Abaddon', 'Weaver': -0.827 },
    { name: 'Alchemist', 'Weaver': -0.482 },
    { name: 'Ancient Apparition', 'Weaver': -2.343 },
    { name: 'Anti-Mage', 'Weaver': 0.721 },
    { name: 'Arc Warden', 'Weaver': 1.367 },
    { name: 'Axe', 'Weaver': 1.648 },
    { name: 'Bane', 'Weaver': 1.136 },
    { name: 'Batrider', 'Weaver': -2.044 },
    { name: 'Beastmaster', 'Weaver': -0.293 },
    { name: 'Bloodseeker', 'Weaver': 0.864 },
    { name: 'Bounty Hunter', 'Weaver': 1.499 },
    { name: 'Brewmaster', 'Weaver': -3.192 },
    { name: 'Bristleback', 'Weaver': 0.852 },
    { name: 'Broodmother', 'Weaver': 1.391 },
    { name: 'Centaur Warrunner', 'Weaver': -0.545 },
    { name: 'Chaos Knight', 'Weaver': 0.64 },
    { name: 'Chen', 'Weaver': -1.079 },
    { name: 'Clinkz', 'Weaver': 1.355 },
    { name: 'Clockwerk', 'Weaver': 0.29 },
    { name: 'Crystal Maiden', 'Weaver': 0.776 },
    { name: 'Dark Seer', 'Weaver': -2.449 },
    { name: 'Dark Willow', 'Weaver': 2.244 },
    { name: 'Dawnbreaker', 'Weaver': -1.261 },
    { name: 'Dazzle', 'Weaver': -1.615 },
    { name: 'Death Prophet', 'Weaver': -1.315 },
    { name: 'Disruptor', 'Weaver': -0.357 },
    { name: 'Doom', 'Weaver': -0.346 },
    { name: 'Dragon Knight', 'Weaver': 1.079 },
    { name: 'Drow Ranger', 'Weaver': 2.648 },
    { name: 'Earth Spirit', 'Weaver': -0.561 },
    { name: 'Earthshaker', 'Weaver': 0.485 },
    { name: 'Elder Titan', 'Weaver': -1.063 },
    { name: 'Ember Spirit', 'Weaver': -1.173 },
    { name: 'Enchantress', 'Weaver': -0.131 },
    { name: 'Enigma', 'Weaver': 0.618 },
    { name: 'Faceless Void', 'Weaver': 3.791 },
    { name: 'Grimstroke', 'Weaver': -0.444 },
    { name: 'Gyrocopter', 'Weaver': 1.078 },
    { name: 'Hoodwink', 'Weaver': -0.267 },
    { name: 'Huskar', 'Weaver': 0.81 },
    { name: 'Invoker', 'Weaver': -0.462 },
    { name: 'Io', 'Weaver': 0.304 },
    { name: 'Jakiro', 'Weaver': -0.892 },
    { name: 'Juggernaut', 'Weaver': -1.787 },
    { name: 'Keeper of the Light', 'Weaver': -1.888 },
    { name: 'Kunkka', 'Weaver': -0.678 },
    { name: 'Legion Commander', 'Weaver': 1.917 },
    { name: 'Leshrac', 'Weaver': -1.158 },
    { name: 'Lich', 'Weaver': -2.161 },
    { name: 'Lifestealer', 'Weaver': -0.691 },
    { name: 'Lina', 'Weaver': -0.423 },
    { name: 'Lion', 'Weaver': 1.09 },
    { name: 'Lone Druid', 'Weaver': -0.463 },
    { name: 'Luna', 'Weaver': 1.688 },
    { name: 'Lycan', 'Weaver': -0.727 },
    { name: 'Magnus', 'Weaver': -1.287 },
    { name: 'Marci', 'Weaver': -0.738 },
    { name: 'Mars', 'Weaver': 0.126 },
    { name: 'Medusa', 'Weaver': 1.082 },
    { name: 'Meepo', 'Weaver': -0.12 },
    { name: 'Mirana', 'Weaver': 0.908 },
    { name: 'Monkey King', 'Weaver': -0.137 },
    { name: 'Morphling', 'Weaver': 1.852 },
    { name: 'Muerta', 'Weaver': 2.128 },
    { name: 'Naga Siren', 'Weaver': 2.387 },
    { name: "Nature's Prophet", 'Weaver': 1.123 },
    { name: 'Necrophos', 'Weaver': 0.722 },
    { name: 'Night Stalker', 'Weaver': 0.182 },
    { name: 'Nyx Assassin', 'Weaver': 2.505 },
    { name: 'Ogre Magi', 'Weaver': 0.908 },
    { name: 'Omniknight', 'Weaver': -0.747 },
    { name: 'Oracle', 'Weaver': -1.057 },
    { name: 'Outworld Destroyer', 'Weaver': -0.164 },
    { name: 'Pangolier', 'Weaver': -1.503 },
    { name: 'Phantom Assassin', 'Weaver': -0.962 },
    { name: 'Phantom Lancer', 'Weaver': 1.306 },
    { name: 'Phoenix', 'Weaver': -1.318 },
    { name: 'Primal Beast', 'Weaver': -1.312 },
    { name: 'Puck', 'Weaver': 1.771 },
    { name: 'Pudge', 'Weaver': -1.856 },
    { name: 'Pugna', 'Weaver': -0.269 },
    { name: 'Queen of Pain', 'Weaver': -0.3 },
    { name: 'Razor', 'Weaver': -2.322 },
    { name: 'Riki', 'Weaver': 2.57 },
    { name: 'Ringmaster', 'Weaver': -0.546 },
    { name: 'Rubick', 'Weaver': 2.864 },
    { name: 'Sand King', 'Weaver': -0.356 },
    { name: 'Shadow Demon', 'Weaver': -2.258 },
    { name: 'Shadow Fiend', 'Weaver': 0.383 },
    { name: 'Shadow Shaman', 'Weaver': 1.179 },
    { name: 'Silencer', 'Weaver': 1.089 },
    { name: 'Skywrath Mage', 'Weaver': 0.479 },
    { name: 'Slardar', 'Weaver': 1.074 },
    { name: 'Slark', 'Weaver': 0.59 },
    { name: 'Snapfire', 'Weaver': -0.662 },
    { name: 'Sniper', 'Weaver': -1.202 },
    { name: 'Spectre', 'Weaver': -0.404 },
    { name: 'Spirit Breaker', 'Weaver': 0.277 },
    { name: 'Storm Spirit', 'Weaver': 0.435 },
    { name: 'Sven', 'Weaver': -0.336 },
    { name: 'Techies', 'Weaver': 0.93 },
    { name: 'Templar Assassin', 'Weaver': 0.968 },
    { name: 'Terrorblade', 'Weaver': 0.318 },
    { name: 'Tidehunter', 'Weaver': -1.249 },
    { name: 'Timbersaw', 'Weaver': -2.073 },
    { name: 'Tinker', 'Weaver': 0.725 },
    { name: 'Tiny', 'Weaver': 0.102 },
    { name: 'Treant Protector', 'Weaver': -0.719 },
    { name: 'Troll Warlord', 'Weaver': -0.016 },
    { name: 'Tusk', 'Weaver': -1.029 },
    { name: 'Underlord', 'Weaver': 0.106 },
    { name: 'Undying', 'Weaver': -1.314 },
    { name: 'Ursa', 'Weaver': -2.085 },
    { name: 'Vengeful Spirit', 'Weaver': 0.183 },
    { name: 'Venomancer', 'Weaver': -1.598 },
    { name: 'Viper', 'Weaver': -1.554 },
    { name: 'Visage', 'Weaver': -1.206 },
    { name: 'Void Spirit', 'Weaver': 0.567 },
    { name: 'Warlock', 'Weaver': -2.468 },
    { name: 'Windranger', 'Weaver': -0.949 },
    { name: 'Winter Wyvern', 'Weaver': -1.568 },
    { name: 'Witch Doctor', 'Weaver': -0.191 },
    { name: 'Wraith King', 'Weaver': -0.175 },
    { name: 'Zeus', 'Weaver': 0.703 },
],
"Ancient Apparition": [
    { name: 'Abaddon', 'Ancient Apparition': -2.178 },
    { name: 'Alchemist', 'Ancient Apparition': -1.746 },
    { name: 'Anti-Mage', 'Ancient Apparition': 3.108 },
    { name: 'Arc Warden', 'Ancient Apparition': 2.14 },
    { name: 'Axe', 'Ancient Apparition': -0.978 },
    { name: 'Bane', 'Ancient Apparition': 0.599 },
    { name: 'Batrider', 'Ancient Apparition': 1.808 },
    { name: 'Beastmaster', 'Ancient Apparition': 0.424 },
    { name: 'Bloodseeker', 'Ancient Apparition': -1.345 },
    { name: 'Bounty Hunter', 'Ancient Apparition': 0.551 },
    { name: 'Brewmaster', 'Ancient Apparition': 2.71 },
    { name: 'Bristleback', 'Ancient Apparition': -2.946 },
    { name: 'Broodmother', 'Ancient Apparition': 0.858 },
    { name: 'Centaur Warrunner', 'Ancient Apparition': 0.05 },
    { name: 'Chaos Knight', 'Ancient Apparition': 0.933 },
    { name: 'Chen', 'Ancient Apparition': 1.082 },
    { name: 'Clinkz', 'Ancient Apparition': 1.379 },
    { name: 'Clockwerk', 'Ancient Apparition': -0.654 },
    { name: 'Crystal Maiden', 'Ancient Apparition': 0.01 },
    { name: 'Dark Seer', 'Ancient Apparition': 0.306 },
    { name: 'Dark Willow', 'Ancient Apparition': 0.017 },
    { name: 'Dawnbreaker', 'Ancient Apparition': -1.599 },
    { name: 'Dazzle', 'Ancient Apparition': -2.269 },
    { name: 'Death Prophet', 'Ancient Apparition': -1.717 },
    { name: 'Disruptor', 'Ancient Apparition': -0.826 },
    { name: 'Doom', 'Ancient Apparition': 0.748 },
    { name: 'Dragon Knight', 'Ancient Apparition': -1.124 },
    { name: 'Drow Ranger', 'Ancient Apparition': 0.446 },
    { name: 'Earth Spirit', 'Ancient Apparition': 0.729 },
    { name: 'Earthshaker', 'Ancient Apparition': -0.456 },
    { name: 'Elder Titan', 'Ancient Apparition': 0.28 },
    { name: 'Ember Spirit', 'Ancient Apparition': 0.724 },
    { name: 'Enchantress', 'Ancient Apparition': 0.114 },
    { name: 'Enigma', 'Ancient Apparition': 1.013 },
    { name: 'Faceless Void', 'Ancient Apparition': 0.256 },
    { name: 'Grimstroke', 'Ancient Apparition': 0.472 },
    { name: 'Gyrocopter', 'Ancient Apparition': -1.537 },
    { name: 'Hoodwink', 'Ancient Apparition': 0.987 },
    { name: 'Huskar', 'Ancient Apparition': -7.752 },
    { name: 'Invoker', 'Ancient Apparition': 0.665 },
    { name: 'Io', 'Ancient Apparition': -2.15 },
    { name: 'Jakiro', 'Ancient Apparition': -0.07 },
    { name: 'Juggernaut', 'Ancient Apparition': 0.2 },
    { name: 'Keeper of the Light', 'Ancient Apparition': 0.91 },
    { name: 'Kunkka', 'Ancient Apparition': -1.127 },
    { name: 'Legion Commander', 'Ancient Apparition': 0.272 },
    { name: 'Leshrac', 'Ancient Apparition': -2.461 },
    { name: 'Lich', 'Ancient Apparition': -0.115 },
    { name: 'Lifestealer', 'Ancient Apparition': -0.815 },
    { name: 'Lina', 'Ancient Apparition': -0.007 },
    { name: 'Lion', 'Ancient Apparition': 0.433 },
    { name: 'Lone Druid', 'Ancient Apparition': -0.473 },
    { name: 'Luna', 'Ancient Apparition': -1.343 },
    { name: 'Lycan', 'Ancient Apparition': 3.102 },
    { name: 'Magnus', 'Ancient Apparition': 0.117 },
    { name: 'Marci', 'Ancient Apparition': -1.237 },
    { name: 'Mars', 'Ancient Apparition': -0.128 },
    { name: 'Medusa', 'Ancient Apparition': 1.301 },
    { name: 'Meepo', 'Ancient Apparition': -1.113 },
    { name: 'Mirana', 'Ancient Apparition': 1.555 },
    { name: 'Monkey King', 'Ancient Apparition': -2.215 },
    { name: 'Morphling', 'Ancient Apparition': -1.356 },
    { name: 'Muerta', 'Ancient Apparition': -0.11 },
    { name: 'Naga Siren', 'Ancient Apparition': 1.763 },
    { name: "Nature's Prophet", 'Ancient Apparition': 1.975 },
    { name: 'Necrophos', 'Ancient Apparition': -5.042 },
    { name: 'Night Stalker', 'Ancient Apparition': 1.799 },
    { name: 'Nyx Assassin', 'Ancient Apparition': 0.568 },
    { name: 'Ogre Magi', 'Ancient Apparition': -0.311 },
    { name: 'Omniknight', 'Ancient Apparition': -3.068 },
    { name: 'Oracle', 'Ancient Apparition': 0.358 },
    { name: 'Outworld Destroyer', 'Ancient Apparition': 1.642 },
    { name: 'Pangolier', 'Ancient Apparition': 1.437 },
    { name: 'Phantom Assassin', 'Ancient Apparition': 1.919 },
    { name: 'Phantom Lancer', 'Ancient Apparition': 2.501 },
    { name: 'Phoenix', 'Ancient Apparition': 0.166 },
    { name: 'Primal Beast', 'Ancient Apparition': 0.506 },
    { name: 'Puck', 'Ancient Apparition': 1.834 },
    { name: 'Pudge', 'Ancient Apparition': 0.194 },
    { name: 'Pugna', 'Ancient Apparition': -0.43 },
    { name: 'Queen of Pain', 'Ancient Apparition': 0.821 },
    { name: 'Razor', 'Ancient Apparition': -0.968 },
    { name: 'Riki', 'Ancient Apparition': 0.948 },
    { name: 'Ringmaster', 'Ancient Apparition': -1.57 },
    { name: 'Rubick', 'Ancient Apparition': -0.206 },
    { name: 'Sand King', 'Ancient Apparition': -1.466 },
    { name: 'Shadow Demon', 'Ancient Apparition': 0.197 },
    { name: 'Shadow Fiend', 'Ancient Apparition': -0.566 },
    { name: 'Shadow Shaman', 'Ancient Apparition': 0.242 },
    { name: 'Silencer', 'Ancient Apparition': 1.815 },
    { name: 'Skywrath Mage', 'Ancient Apparition': 1.117 },
    { name: 'Slardar', 'Ancient Apparition': 1.162 },
    { name: 'Slark', 'Ancient Apparition': -1.313 },
    { name: 'Snapfire', 'Ancient Apparition': 0.478 },
    { name: 'Sniper', 'Ancient Apparition': 0.328 },
    { name: 'Spectre', 'Ancient Apparition': 1.263 },
    { name: 'Spirit Breaker', 'Ancient Apparition': 1.282 },
    { name: 'Storm Spirit', 'Ancient Apparition': 3.441 },
    { name: 'Sven', 'Ancient Apparition': -0.623 },
    { name: 'Techies', 'Ancient Apparition': -0.501 },
    { name: 'Templar Assassin', 'Ancient Apparition': 1.068 },
    { name: 'Terrorblade', 'Ancient Apparition': 0.469 },
    { name: 'Tidehunter', 'Ancient Apparition': -0.629 },
    { name: 'Timbersaw', 'Ancient Apparition': -0.835 },
    { name: 'Tinker', 'Ancient Apparition': -1.026 },
    { name: 'Tiny', 'Ancient Apparition': -0.556 },
    { name: 'Treant Protector', 'Ancient Apparition': -2.18 },
    { name: 'Troll Warlord', 'Ancient Apparition': -1.448 },
    { name: 'Tusk', 'Ancient Apparition': 0.21 },
    { name: 'Underlord', 'Ancient Apparition': -1.623 },
    { name: 'Undying', 'Ancient Apparition': -2.112 },
    { name: 'Ursa', 'Ancient Apparition': 0.956 },
    { name: 'Vengeful Spirit', 'Ancient Apparition': 0.999 },
    { name: 'Venomancer', 'Ancient Apparition': -0.405 },
    { name: 'Viper', 'Ancient Apparition': 0.041 },
    { name: 'Visage', 'Ancient Apparition': 0.074 },
    { name: 'Void Spirit', 'Ancient Apparition': 2.047 },
    { name: 'Warlock', 'Ancient Apparition': -0.68 },
    { name: 'Weaver', 'Ancient Apparition': 2.371 },
    { name: 'Windranger', 'Ancient Apparition': 1.633 },
    { name: 'Winter Wyvern', 'Ancient Apparition': -2.039 },
    { name: 'Witch Doctor', 'Ancient Apparition': -0.445 },
    { name: 'Wraith King', 'Ancient Apparition': 0.206 },
    { name: 'Zeus', 'Ancient Apparition': 0.969 },
],
"Crystal Maiden": [
    { name: 'Abaddon', 'Crystal Maiden': -0.643 },
    { name: 'Alchemist', 'Crystal Maiden': -0.781 },
    { name: 'Ancient Apparition', 'Crystal Maiden': -0.114 },
    { name: 'Anti-Mage', 'Crystal Maiden': -0.932 },
    { name: 'Arc Warden', 'Crystal Maiden': -0.632 },
    { name: 'Axe', 'Crystal Maiden': 0.314 },
    { name: 'Bane', 'Crystal Maiden': 0.225 },
    { name: 'Batrider', 'Crystal Maiden': -0.551 },
    { name: 'Beastmaster', 'Crystal Maiden': -0.606 },
    { name: 'Bloodseeker', 'Crystal Maiden': -0.708 },
    { name: 'Bounty Hunter', 'Crystal Maiden': -1.415 },
    { name: 'Brewmaster', 'Crystal Maiden': -0.021 },
    { name: 'Bristleback', 'Crystal Maiden': 1.017 },
    { name: 'Broodmother', 'Crystal Maiden': -3.334 },
    { name: 'Centaur Warrunner', 'Crystal Maiden': -0.116 },
    { name: 'Chaos Knight', 'Crystal Maiden': -1.79 },
    { name: 'Chen', 'Crystal Maiden': -1.747 },
    { name: 'Clinkz', 'Crystal Maiden': -0.79 },
    { name: 'Clockwerk', 'Crystal Maiden': 0.342 },
    { name: 'Dark Seer', 'Crystal Maiden': -1.08 },
    { name: 'Dark Willow', 'Crystal Maiden': -0.741 },
    { name: 'Dawnbreaker', 'Crystal Maiden': 1.822 },
    { name: 'Dazzle', 'Crystal Maiden': -0.354 },
    { name: 'Death Prophet', 'Crystal Maiden': 0.459 },
    { name: 'Disruptor', 'Crystal Maiden': 0.699 },
    { name: 'Doom', 'Crystal Maiden': -1.087 },
    { name: 'Dragon Knight', 'Crystal Maiden': -1.293 },
    { name: 'Drow Ranger', 'Crystal Maiden': 1.313 },
    { name: 'Earth Spirit', 'Crystal Maiden': 1.016 },
    { name: 'Earthshaker', 'Crystal Maiden': 0.195 },
    { name: 'Elder Titan', 'Crystal Maiden': -0.335 },
    { name: 'Ember Spirit', 'Crystal Maiden': -0.755 },
    { name: 'Enchantress', 'Crystal Maiden': -1.33 },
    { name: 'Enigma', 'Crystal Maiden': 1.613 },
    { name: 'Faceless Void', 'Crystal Maiden': -0.097 },
    { name: 'Grimstroke', 'Crystal Maiden': 0.602 },
    { name: 'Gyrocopter', 'Crystal Maiden': 0.34 },
    { name: 'Hoodwink', 'Crystal Maiden': 0.581 },
    { name: 'Huskar', 'Crystal Maiden': 0.112 },
    { name: 'Invoker', 'Crystal Maiden': 0.084 },
    { name: 'Io', 'Crystal Maiden': -1.79 },
    { name: 'Jakiro', 'Crystal Maiden': 0.639 },
    { name: 'Juggernaut', 'Crystal Maiden': 1.524 },
    { name: 'Keeper of the Light', 'Crystal Maiden': -0.847 },
    { name: 'Kunkka', 'Crystal Maiden': 0.3 },
    { name: 'Legion Commander', 'Crystal Maiden': 0.617 },
    { name: 'Leshrac', 'Crystal Maiden': 0.431 },
    { name: 'Lich', 'Crystal Maiden': -0.119 },
    { name: 'Lifestealer', 'Crystal Maiden': -0.289 },
    { name: 'Lina', 'Crystal Maiden': 0.288 },
    { name: 'Lion', 'Crystal Maiden': 0.051 },
    { name: 'Lone Druid', 'Crystal Maiden': -2.013 },
    { name: 'Luna', 'Crystal Maiden': 0.591 },
    { name: 'Lycan', 'Crystal Maiden': -1.851 },
    { name: 'Magnus', 'Crystal Maiden': 0.423 },
    { name: 'Marci', 'Crystal Maiden': -0.665 },
    { name: 'Mars', 'Crystal Maiden': 0.843 },
    { name: 'Medusa', 'Crystal Maiden': -0.207 },
    { name: 'Meepo', 'Crystal Maiden': -2.267 },
    { name: 'Mirana', 'Crystal Maiden': 0.104 },
    { name: 'Monkey King', 'Crystal Maiden': 1.022 },
    { name: 'Morphling', 'Crystal Maiden': 0.504 },
    { name: 'Muerta', 'Crystal Maiden': 0.036 },
    { name: 'Naga Siren', 'Crystal Maiden': -0.5 },
    { name: "Nature's Prophet", 'Crystal Maiden': -1.059 },
    { name: 'Necrophos', 'Crystal Maiden': -1.582 },
    { name: 'Night Stalker', 'Crystal Maiden': -0.848 },
    { name: 'Nyx Assassin', 'Crystal Maiden': 0.085 },
    { name: 'Ogre Magi', 'Crystal Maiden': -0.394 },
    { name: 'Omniknight', 'Crystal Maiden': -0.755 },
    { name: 'Oracle', 'Crystal Maiden': -1.211 },
    { name: 'Outworld Destroyer', 'Crystal Maiden': -1.082 },
    { name: 'Pangolier', 'Crystal Maiden': 0.723 },
    { name: 'Phantom Assassin', 'Crystal Maiden': 0.068 },
    { name: 'Phantom Lancer', 'Crystal Maiden': -2.539 },
    { name: 'Phoenix', 'Crystal Maiden': 1.196 },
    { name: 'Primal Beast', 'Crystal Maiden': -0.16 },
    { name: 'Puck', 'Crystal Maiden': 0.752 },
    { name: 'Pudge', 'Crystal Maiden': 1.892 },
    { name: 'Pugna', 'Crystal Maiden': 1.07 },
    { name: 'Queen of Pain', 'Crystal Maiden': 0.132 },
    { name: 'Razor', 'Crystal Maiden': -1.131 },
    { name: 'Riki', 'Crystal Maiden': -0.523 },
    { name: 'Ringmaster', 'Crystal Maiden': 0.299 },
    { name: 'Rubick', 'Crystal Maiden': 2.492 },
    { name: 'Sand King', 'Crystal Maiden': 0.458 },
    { name: 'Shadow Demon', 'Crystal Maiden': -0.347 },
    { name: 'Shadow Fiend', 'Crystal Maiden': 0.963 },
    { name: 'Shadow Shaman', 'Crystal Maiden': 0.723 },
    { name: 'Silencer', 'Crystal Maiden': 0.37 },
    { name: 'Skywrath Mage', 'Crystal Maiden': -0.136 },
    { name: 'Slardar', 'Crystal Maiden': -0.14 },
    { name: 'Slark', 'Crystal Maiden': -1.694 },
    { name: 'Snapfire', 'Crystal Maiden': 0.523 },
    { name: 'Sniper', 'Crystal Maiden': 0.686 },
    { name: 'Spectre', 'Crystal Maiden': -0.629 },
    { name: 'Spirit Breaker', 'Crystal Maiden': -0.994 },
    { name: 'Storm Spirit', 'Crystal Maiden': -1.65 },
    { name: 'Sven', 'Crystal Maiden': -0.471 },
    { name: 'Techies', 'Crystal Maiden': 0.105 },
    { name: 'Templar Assassin', 'Crystal Maiden': -0.81 },
    { name: 'Terrorblade', 'Crystal Maiden': -0.463 },
    { name: 'Tidehunter', 'Crystal Maiden': 1.236 },
    { name: 'Timbersaw', 'Crystal Maiden': -0.422 },
    { name: 'Tinker', 'Crystal Maiden': -0.983 },
    { name: 'Tiny', 'Crystal Maiden': 0.062 },
    { name: 'Treant Protector', 'Crystal Maiden': -0.814 },
    { name: 'Troll Warlord', 'Crystal Maiden': -1.307 },
    { name: 'Tusk', 'Crystal Maiden': -0.551 },
    { name: 'Underlord', 'Crystal Maiden': -0.258 },
    { name: 'Undying', 'Crystal Maiden': 0.54 },
    { name: 'Ursa', 'Crystal Maiden': -0.282 },
    { name: 'Vengeful Spirit', 'Crystal Maiden': -0.481 },
    { name: 'Venomancer', 'Crystal Maiden': -0.151 },
    { name: 'Viper', 'Crystal Maiden': -0.808 },
    { name: 'Visage', 'Crystal Maiden': -1.16 },
    { name: 'Void Spirit', 'Crystal Maiden': 0.155 },
    { name: 'Warlock', 'Crystal Maiden': -0.339 },
    { name: 'Weaver', 'Crystal Maiden': -0.777 },
    { name: 'Windranger', 'Crystal Maiden': -0.349 },
    { name: 'Winter Wyvern', 'Crystal Maiden': -0.466 },
    { name: 'Witch Doctor', 'Crystal Maiden': 0.892 },
    { name: 'Wraith King', 'Crystal Maiden': -1.64 },
    { name: 'Zeus', 'Crystal Maiden': 1.003 },
],
"Death Prophet": [
    { name: 'Abaddon', 'Death Prophet': -1.111 },
    { name: 'Alchemist', 'Death Prophet': -0.325 },
    { name: 'Ancient Apparition', 'Death Prophet': 1.827 },
    { name: 'Anti-Mage', 'Death Prophet': 1.7 },
    { name: 'Arc Warden', 'Death Prophet': 0.46 },
    { name: 'Axe', 'Death Prophet': -2.02 },
    { name: 'Bane', 'Death Prophet': -0.635 },
    { name: 'Batrider', 'Death Prophet': 1.542 },
    { name: 'Beastmaster', 'Death Prophet': 0.45 },
    { name: 'Bloodseeker', 'Death Prophet': 0.702 },
    { name: 'Bounty Hunter', 'Death Prophet': 1.889 },
    { name: 'Brewmaster', 'Death Prophet': -0.779 },
    { name: 'Bristleback', 'Death Prophet': 1.076 },
    { name: 'Broodmother', 'Death Prophet': 1.488 },
    { name: 'Centaur Warrunner', 'Death Prophet': -1.367 },
    { name: 'Chaos Knight', 'Death Prophet': 1.78 },
    { name: 'Chen', 'Death Prophet': -0.165 },
    { name: 'Clinkz', 'Death Prophet': 1.429 },
    { name: 'Clockwerk', 'Death Prophet': 0.339 },
    { name: 'Crystal Maiden', 'Death Prophet': -0.462 },
    { name: 'Dark Seer', 'Death Prophet': 0.792 },
    { name: 'Dark Willow', 'Death Prophet': 1.097 },
    { name: 'Dawnbreaker', 'Death Prophet': -1.612 },
    { name: 'Dazzle', 'Death Prophet': -1.08 },
    { name: 'Disruptor', 'Death Prophet': 0.995 },
    { name: 'Doom', 'Death Prophet': 0.602 },
    { name: 'Dragon Knight', 'Death Prophet': 1.127 },
    { name: 'Drow Ranger', 'Death Prophet': 1.05 },
    { name: 'Earth Spirit', 'Death Prophet': -1.28 },
    { name: 'Earthshaker', 'Death Prophet': -0.937 },
    { name: 'Elder Titan', 'Death Prophet': 0.884 },
    { name: 'Ember Spirit', 'Death Prophet': 0.293 },
    { name: 'Enchantress', 'Death Prophet': 0.696 },
    { name: 'Enigma', 'Death Prophet': 0.143 },
    { name: 'Faceless Void', 'Death Prophet': -1.093 },
    { name: 'Grimstroke', 'Death Prophet': -0.026 },
    { name: 'Gyrocopter', 'Death Prophet': 1.205 },
    { name: 'Hoodwink', 'Death Prophet': 0.927 },
    { name: 'Huskar', 'Death Prophet': -0.656 },
    { name: 'Invoker', 'Death Prophet': -0.284 },
    { name: 'Io', 'Death Prophet': -0.408 },
    { name: 'Jakiro', 'Death Prophet': 0.037 },
    { name: 'Juggernaut', 'Death Prophet': -1.967 },
    { name: 'Keeper of the Light', 'Death Prophet': 2.393 },
    { name: 'Kunkka', 'Death Prophet': -0.56 },
    { name: 'Legion Commander', 'Death Prophet': 0.031 },
    { name: 'Leshrac', 'Death Prophet': -0.86 },
    { name: 'Lich', 'Death Prophet': -0.198 },
    { name: 'Lifestealer', 'Death Prophet': -2.139 },
    { name: 'Lina', 'Death Prophet': 1.658 },
    { name: 'Lion', 'Death Prophet': -0.699 },
    { name: 'Lone Druid', 'Death Prophet': 0.812 },
    { name: 'Luna', 'Death Prophet': 1.492 },
    { name: 'Lycan', 'Death Prophet': 1.452 },
    { name: 'Magnus', 'Death Prophet': 0.398 },
    { name: 'Marci', 'Death Prophet': 0.698 },
    { name: 'Mars', 'Death Prophet': -0.962 },
    { name: 'Medusa', 'Death Prophet': 1.7 },
    { name: 'Meepo', 'Death Prophet': -0.165 },
    { name: 'Mirana', 'Death Prophet': 1.925 },
    { name: 'Monkey King', 'Death Prophet': 0.491 },
    { name: 'Morphling', 'Death Prophet': 0.088 },
    { name: 'Muerta', 'Death Prophet': 1.574 },
    { name: 'Naga Siren', 'Death Prophet': -0.909 },
    { name: "Nature's Prophet", 'Death Prophet': 1.644 },
    { name: 'Necrophos', 'Death Prophet': -3.964 },
    { name: 'Night Stalker', 'Death Prophet': 0.77 },
    { name: 'Nyx Assassin', 'Death Prophet': -0.788 },
    { name: 'Ogre Magi', 'Death Prophet': 0.296 },
    { name: 'Omniknight', 'Death Prophet': -2.626 },
    { name: 'Oracle', 'Death Prophet': -2.576 },
    { name: 'Outworld Destroyer', 'Death Prophet': -0.459 },
    { name: 'Pangolier', 'Death Prophet': 0.939 },
    { name: 'Phantom Assassin', 'Death Prophet': -1.211 },
    { name: 'Phantom Lancer', 'Death Prophet': 1.68 },
    { name: 'Phoenix', 'Death Prophet': -1.79 },
    { name: 'Primal Beast', 'Death Prophet': -0.827 },
    { name: 'Puck', 'Death Prophet': -1.873 },
    { name: 'Pudge', 'Death Prophet': -0.516 },
    { name: 'Pugna', 'Death Prophet': -0.116 },
    { name: 'Queen of Pain', 'Death Prophet': 0.702 },
    { name: 'Razor', 'Death Prophet': 2.193 },
    { name: 'Riki', 'Death Prophet': -1.271 },
    { name: 'Ringmaster', 'Death Prophet': -1.005 },
    { name: 'Rubick', 'Death Prophet': 0.367 },
    { name: 'Sand King', 'Death Prophet': -0.247 },
    { name: 'Shadow Demon', 'Death Prophet': 1.253 },
    { name: 'Shadow Fiend', 'Death Prophet': 0.322 },
    { name: 'Shadow Shaman', 'Death Prophet': 0.182 },
    { name: 'Silencer', 'Death Prophet': 1.616 },
    { name: 'Skywrath Mage', 'Death Prophet': -0.502 },
    { name: 'Slardar', 'Death Prophet': 0.96 },
    { name: 'Slark', 'Death Prophet': 0.074 },
    { name: 'Snapfire', 'Death Prophet': -0.077 },
    { name: 'Sniper', 'Death Prophet': 1.338 },
    { name: 'Spectre', 'Death Prophet': 0.09 },
    { name: 'Spirit Breaker', 'Death Prophet': 0.759 },
    { name: 'Storm Spirit', 'Death Prophet': -1.36 },
    { name: 'Sven', 'Death Prophet': 0.888 },
    { name: 'Techies', 'Death Prophet': -1.001 },
    { name: 'Templar Assassin', 'Death Prophet': -0.095 },
    { name: 'Terrorblade', 'Death Prophet': 2.54 },
    { name: 'Tidehunter', 'Death Prophet': -1.753 },
    { name: 'Timbersaw', 'Death Prophet': -2.089 },
    { name: 'Tinker', 'Death Prophet': -1.27 },
    { name: 'Tiny', 'Death Prophet': -1.562 },
    { name: 'Treant Protector', 'Death Prophet': -1.32 },
    { name: 'Troll Warlord', 'Death Prophet': -1.826 },
    { name: 'Tusk', 'Death Prophet': -0.344 },
    { name: 'Underlord', 'Death Prophet': -0.545 },
    { name: 'Undying', 'Death Prophet': 2.263 },
    { name: 'Ursa', 'Death Prophet': -1.271 },
    { name: 'Vengeful Spirit', 'Death Prophet': 0.365 },
    { name: 'Venomancer', 'Death Prophet': 0.717 },
    { name: 'Viper', 'Death Prophet': 0.141 },
    { name: 'Visage', 'Death Prophet': -0.005 },
    { name: 'Void Spirit', 'Death Prophet': -0.588 },
    { name: 'Warlock', 'Death Prophet': 0.763 },
    { name: 'Weaver', 'Death Prophet': 1.478 },
    { name: 'Windranger', 'Death Prophet': 1.078 },
    { name: 'Winter Wyvern', 'Death Prophet': -0.507 },
    { name: 'Witch Doctor', 'Death Prophet': -0.187 },
    { name: 'Wraith King', 'Death Prophet': -0.374 },
    { name: 'Zeus', 'Death Prophet': -0.492 },
],
"Disruptor": [
    { name: 'Abaddon', 'Disruptor': 0.026 },
    { name: 'Alchemist', 'Disruptor': 0.703 },
    { name: 'Ancient Apparition', 'Disruptor': 0.844 },
    { name: 'Anti-Mage', 'Disruptor': -1.539 },
    { name: 'Arc Warden', 'Disruptor': 1.763 },
    { name: 'Axe', 'Disruptor': 0.142 },
    { name: 'Bane', 'Disruptor': 1.045 },
    { name: 'Batrider', 'Disruptor': -0.176 },
    { name: 'Beastmaster', 'Disruptor': 0.972 },
    { name: 'Bloodseeker', 'Disruptor': 1.396 },
    { name: 'Bounty Hunter', 'Disruptor': 0.546 },
    { name: 'Brewmaster', 'Disruptor': -0.843 },
    { name: 'Bristleback', 'Disruptor': -0.81 },
    { name: 'Broodmother', 'Disruptor': -0.54 },
    { name: 'Centaur Warrunner', 'Disruptor': -1.169 },
    { name: 'Chaos Knight', 'Disruptor': 0.42 },
    { name: 'Chen', 'Disruptor': 0.873 },
    { name: 'Clinkz', 'Disruptor': 1.208 },
    { name: 'Clockwerk', 'Disruptor': -1.159 },
    { name: 'Crystal Maiden', 'Disruptor': -0.767 },
    { name: 'Dark Seer', 'Disruptor': -1.159 },
    { name: 'Dark Willow', 'Disruptor': 0.537 },
    { name: 'Dawnbreaker', 'Disruptor': -0.663 },
    { name: 'Dazzle', 'Disruptor': 0.587 },
    { name: 'Death Prophet', 'Disruptor': -0.967 },
    { name: 'Doom', 'Disruptor': 0.124 },
    { name: 'Dragon Knight', 'Disruptor': 0.994 },
    { name: 'Drow Ranger', 'Disruptor': 1.188 },
    { name: 'Earth Spirit', 'Disruptor': 0.554 },
    { name: 'Earthshaker', 'Disruptor': -1.009 },
    { name: 'Elder Titan', 'Disruptor': -0.112 },
    { name: 'Ember Spirit', 'Disruptor': -0.038 },
    { name: 'Enchantress', 'Disruptor': -0.111 },
    { name: 'Enigma', 'Disruptor': -0.118 },
    { name: 'Faceless Void', 'Disruptor': -0.45 },
    { name: 'Grimstroke', 'Disruptor': -0.405 },
    { name: 'Gyrocopter', 'Disruptor': 0.532 },
    { name: 'Hoodwink', 'Disruptor': 0.635 },
    { name: 'Huskar', 'Disruptor': 1.423 },
    { name: 'Invoker', 'Disruptor': 0.608 },
    { name: 'Io', 'Disruptor': -0.8 },
    { name: 'Jakiro', 'Disruptor': 0.092 },
    { name: 'Juggernaut', 'Disruptor': -1.281 },
    { name: 'Keeper of the Light', 'Disruptor': 1.627 },
    { name: 'Kunkka', 'Disruptor': 0.134 },
    { name: 'Legion Commander', 'Disruptor': 0.992 },
    { name: 'Leshrac', 'Disruptor': -0.693 },
    { name: 'Lich', 'Disruptor': -0.373 },
    { name: 'Lifestealer', 'Disruptor': 0.2 },
    { name: 'Lina', 'Disruptor': 0.74 },
    { name: 'Lion', 'Disruptor': -0.182 },
    { name: 'Lone Druid', 'Disruptor': 0.777 },
    { name: 'Luna', 'Disruptor': 0.838 },
    { name: 'Lycan', 'Disruptor': -0.289 },
    { name: 'Magnus', 'Disruptor': -1.125 },
    { name: 'Marci', 'Disruptor': -0.741 },
    { name: 'Mars', 'Disruptor': -0.181 },
    { name: 'Medusa', 'Disruptor': 0.577 },
    { name: 'Meepo', 'Disruptor': -1.116 },
    { name: 'Mirana', 'Disruptor': 0.071 },
    { name: 'Monkey King', 'Disruptor': -0.127 },
    { name: 'Morphling', 'Disruptor': 0.272 },
    { name: 'Muerta', 'Disruptor': 0.381 },
    { name: 'Naga Siren', 'Disruptor': -0.091 },
    { name: "Nature's Prophet", 'Disruptor': 1.934 },
    { name: 'Necrophos', 'Disruptor': -1.404 },
    { name: 'Night Stalker', 'Disruptor': -0.89 },
    { name: 'Nyx Assassin', 'Disruptor': -1.29 },
    { name: 'Ogre Magi', 'Disruptor': 0.38 },
    { name: 'Omniknight', 'Disruptor': 0.522 },
    { name: 'Oracle', 'Disruptor': 0.958 },
    { name: 'Outworld Destroyer', 'Disruptor': -0.371 },
    { name: 'Pangolier', 'Disruptor': 0.813 },
    { name: 'Phantom Assassin', 'Disruptor': 0.502 },
    { name: 'Phantom Lancer', 'Disruptor': 0.379 },
    { name: 'Phoenix', 'Disruptor': -0.618 },
    { name: 'Primal Beast', 'Disruptor': -2.74 },
    { name: 'Puck', 'Disruptor': -0.485 },
    { name: 'Pudge', 'Disruptor': -0.394 },
    { name: 'Pugna', 'Disruptor': 1.353 },
    { name: 'Queen of Pain', 'Disruptor': -0.097 },
    { name: 'Razor', 'Disruptor': 0.584 },
    { name: 'Riki', 'Disruptor': -1.762 },
    { name: 'Ringmaster', 'Disruptor': 0.273 },
    { name: 'Rubick', 'Disruptor': -0.323 },
    { name: 'Sand King', 'Disruptor': -1.137 },
    { name: 'Shadow Demon', 'Disruptor': 1.057 },
    { name: 'Shadow Fiend', 'Disruptor': 0.218 },
    { name: 'Shadow Shaman', 'Disruptor': 0.42 },
    { name: 'Silencer', 'Disruptor': -0.431 },
    { name: 'Skywrath Mage', 'Disruptor': -0.189 },
    { name: 'Slardar', 'Disruptor': -0.336 },
    { name: 'Slark', 'Disruptor': -4.483 },
    { name: 'Snapfire', 'Disruptor': -0.277 },
    { name: 'Sniper', 'Disruptor': 2.419 },
    { name: 'Spectre', 'Disruptor': -0.313 },
    { name: 'Spirit Breaker', 'Disruptor': -0.868 },
    { name: 'Storm Spirit', 'Disruptor': -1.623 },
    { name: 'Sven', 'Disruptor': -0.089 },
    { name: 'Techies', 'Disruptor': -0.196 },
    { name: 'Templar Assassin', 'Disruptor': 1.197 },
    { name: 'Terrorblade', 'Disruptor': 1.048 },
    { name: 'Tidehunter', 'Disruptor': -0.471 },
    { name: 'Timbersaw', 'Disruptor': -0.67 },
    { name: 'Tinker', 'Disruptor': 0.789 },
    { name: 'Tiny', 'Disruptor': 0.148 },
    { name: 'Treant Protector', 'Disruptor': 0.017 },
    { name: 'Troll Warlord', 'Disruptor': -0.412 },
    { name: 'Tusk', 'Disruptor': 0.44 },
    { name: 'Underlord', 'Disruptor': 0.294 },
    { name: 'Undying', 'Disruptor': 0.564 },
    { name: 'Ursa', 'Disruptor': -1.56 },
    { name: 'Vengeful Spirit', 'Disruptor': 0.23 },
    { name: 'Venomancer', 'Disruptor': 0.676 },
    { name: 'Viper', 'Disruptor': 1.267 },
    { name: 'Visage', 'Disruptor': -1.275 },
    { name: 'Void Spirit', 'Disruptor': -0.146 },
    { name: 'Warlock', 'Disruptor': -0.037 },
    { name: 'Weaver', 'Disruptor': 0.37 },
    { name: 'Windranger', 'Disruptor': 0.732 },
    { name: 'Winter Wyvern', 'Disruptor': 0.955 },
    { name: 'Witch Doctor', 'Disruptor': -0.72 },
    { name: 'Wraith King', 'Disruptor': 0.817 },
    { name: 'Zeus', 'Disruptor': 0.193 },
],
"Enchantress": [
    { name: 'Abaddon', 'Enchantress': -0.939 },
    { name: 'Alchemist', 'Enchantress': -0.553 },
    { name: 'Ancient Apparition', 'Enchantress': 0.015 },
    { name: 'Anti-Mage', 'Enchantress': -2.193 },
    { name: 'Arc Warden', 'Enchantress': -0.241 },
    { name: 'Axe', 'Enchantress': -0.069 },
    { name: 'Bane', 'Enchantress': -0.494 },
    { name: 'Batrider', 'Enchantress': 0.006 },
    { name: 'Beastmaster', 'Enchantress': -0.704 },
    { name: 'Bloodseeker', 'Enchantress': -1.376 },
    { name: 'Bounty Hunter', 'Enchantress': 0.353 },
    { name: 'Brewmaster', 'Enchantress': -0.756 },
    { name: 'Bristleback', 'Enchantress': 1.726 },
    { name: 'Broodmother', 'Enchantress': -0.1 },
    { name: 'Centaur Warrunner', 'Enchantress': -0.085 },
    { name: 'Chaos Knight', 'Enchantress': 1.148 },
    { name: 'Chen', 'Enchantress': -0.178 },
    { name: 'Clinkz', 'Enchantress': -2.522 },
    { name: 'Clockwerk', 'Enchantress': -0.527 },
    { name: 'Crystal Maiden', 'Enchantress': 1.66 },
    { name: 'Dark Seer', 'Enchantress': -1.723 },
    { name: 'Dark Willow', 'Enchantress': -0.465 },
    { name: 'Dawnbreaker', 'Enchantress': -0.488 },
    { name: 'Dazzle', 'Enchantress': 0.997 },
    { name: 'Death Prophet', 'Enchantress': -0.742 },
    { name: 'Disruptor', 'Enchantress': 0.147 },
    { name: 'Doom', 'Enchantress': 0.457 },
    { name: 'Dragon Knight', 'Enchantress': -0.275 },
    { name: 'Drow Ranger', 'Enchantress': -0.884 },
    { name: 'Earth Spirit', 'Enchantress': 0.702 },
    { name: 'Earthshaker', 'Enchantress': -0.982 },
    { name: 'Elder Titan', 'Enchantress': -0.931 },
    { name: 'Ember Spirit', 'Enchantress': -1.14 },
    { name: 'Enigma', 'Enchantress': -0.476 },
    { name: 'Faceless Void', 'Enchantress': -2.496 },
    { name: 'Grimstroke', 'Enchantress': -0.425 },
    { name: 'Gyrocopter', 'Enchantress': -0.219 },
    { name: 'Hoodwink', 'Enchantress': 0.737 },
    { name: 'Huskar', 'Enchantress': -1.081 },
    { name: 'Invoker', 'Enchantress': -0.642 },
    { name: 'Io', 'Enchantress': 0.393 },
    { name: 'Jakiro', 'Enchantress': -0.472 },
    { name: 'Juggernaut', 'Enchantress': -0.553 },
    { name: 'Keeper of the Light', 'Enchantress': 0.434 },
    { name: 'Kunkka', 'Enchantress': -1.066 },
    { name: 'Legion Commander', 'Enchantress': 0.345 },
    { name: 'Leshrac', 'Enchantress': -0.296 },
    { name: 'Lich', 'Enchantress': -0.084 },
    { name: 'Lifestealer', 'Enchantress': -1.604 },
    { name: 'Lina', 'Enchantress': -0.35 },
    { name: 'Lion', 'Enchantress': 0.558 },
    { name: 'Lone Druid', 'Enchantress': -0.303 },
    { name: 'Luna', 'Enchantress': -0.621 },
    { name: 'Lycan', 'Enchantress': -1.843 },
    { name: 'Magnus', 'Enchantress': 0.251 },
    { name: 'Marci', 'Enchantress': 2.069 },
    { name: 'Mars', 'Enchantress': 2.1 },
    { name: 'Medusa', 'Enchantress': 0.728 },
    { name: 'Meepo', 'Enchantress': -0.815 },
    { name: 'Mirana', 'Enchantress': 0.293 },
    { name: 'Monkey King', 'Enchantress': -0.904 },
    { name: 'Morphling', 'Enchantress': 0.864 },
    { name: 'Muerta', 'Enchantress': -2.105 },
    { name: 'Naga Siren', 'Enchantress': 2.451 },
    { name: "Nature's Prophet", 'Enchantress': -0.435 },
    { name: 'Necrophos', 'Enchantress': -0.193 },
    { name: 'Night Stalker', 'Enchantress': -2.34 },
    { name: 'Nyx Assassin', 'Enchantress': 0.602 },
    { name: 'Ogre Magi', 'Enchantress': 1.621 },
    { name: 'Omniknight', 'Enchantress': -1.292 },
    { name: 'Oracle', 'Enchantress': -1.5 },
    { name: 'Outworld Destroyer', 'Enchantress': -1.163 },
    { name: 'Pangolier', 'Enchantress': 1.368 },
    { name: 'Phantom Assassin', 'Enchantress': 4.371 },
    { name: 'Phantom Lancer', 'Enchantress': 1.925 },
    { name: 'Phoenix', 'Enchantress': -1.012 },
    { name: 'Primal Beast', 'Enchantress': 1.357 },
    { name: 'Puck', 'Enchantress': -0.022 },
    { name: 'Pudge', 'Enchantress': 1.967 },
    { name: 'Pugna', 'Enchantress': -0.188 },
    { name: 'Queen of Pain', 'Enchantress': -0.149 },
    { name: 'Razor', 'Enchantress': -0.175 },
    { name: 'Riki', 'Enchantress': 0.499 },
    { name: 'Ringmaster', 'Enchantress': -0.426 },
    { name: 'Rubick', 'Enchantress': -0.735 },
    { name: 'Sand King', 'Enchantress': 1.059 },
    { name: 'Shadow Demon', 'Enchantress': 0.337 },
    { name: 'Shadow Fiend', 'Enchantress': -0.032 },
    { name: 'Shadow Shaman', 'Enchantress': 0.623 },
    { name: 'Silencer', 'Enchantress': -2.106 },
    { name: 'Skywrath Mage', 'Enchantress': 0.378 },
    { name: 'Slardar', 'Enchantress': -0.439 },
    { name: 'Slark', 'Enchantress': 1.463 },
    { name: 'Snapfire', 'Enchantress': -0.101 },
    { name: 'Sniper', 'Enchantress': -1.114 },
    { name: 'Spectre', 'Enchantress': -2.934 },
    { name: 'Spirit Breaker', 'Enchantress': -0.927 },
    { name: 'Storm Spirit', 'Enchantress': 0.307 },
    { name: 'Sven', 'Enchantress': -1.218 },
    { name: 'Techies', 'Enchantress': 0.195 },
    { name: 'Templar Assassin', 'Enchantress': -0.18 },
    { name: 'Terrorblade', 'Enchantress': 0.764 },
    { name: 'Tidehunter', 'Enchantress': -0.53 },
    { name: 'Timbersaw', 'Enchantress': -0.609 },
    { name: 'Tinker', 'Enchantress': -1.045 },
    { name: 'Tiny', 'Enchantress': -0.337 },
    { name: 'Treant Protector', 'Enchantress': -0.334 },
    { name: 'Troll Warlord', 'Enchantress': 0.176 },
    { name: 'Tusk', 'Enchantress': 0.084 },
    { name: 'Underlord', 'Enchantress': -0.252 },
    { name: 'Undying', 'Enchantress': -0.367 },
    { name: 'Ursa', 'Enchantress': -0.316 },
    { name: 'Vengeful Spirit', 'Enchantress': -0.472 },
    { name: 'Venomancer', 'Enchantress': -0.658 },
    { name: 'Viper', 'Enchantress': 0.391 },
    { name: 'Visage', 'Enchantress': -0.451 },
    { name: 'Void Spirit', 'Enchantress': -0.975 },
    { name: 'Warlock', 'Enchantress': 0.134 },
    { name: 'Weaver', 'Enchantress': 0.306 },
    { name: 'Windranger', 'Enchantress': 1.741 },
    { name: 'Winter Wyvern', 'Enchantress': -0.849 },
    { name: 'Witch Doctor', 'Enchantress': 0.836 },
    { name: 'Wraith King', 'Enchantress': 0.127 },
    { name: 'Zeus', 'Enchantress': -0.949 },
],
"Grimstroke": [
    { name: 'Abaddon', 'Grimstroke': -1.228 },
    { name: 'Alchemist', 'Grimstroke': -0.374 },
    { name: 'Ancient Apparition', 'Grimstroke': -0.415 },
    { name: 'Anti-Mage', 'Grimstroke': 0.728 },
    { name: 'Arc Warden', 'Grimstroke': 1.752 },
    { name: 'Axe', 'Grimstroke': 0.007 },
    { name: 'Bane', 'Grimstroke': 0.45 },
    { name: 'Batrider', 'Grimstroke': -1.494 },
    { name: 'Beastmaster', 'Grimstroke': 0.735 },
    { name: 'Bloodseeker', 'Grimstroke': -0.327 },
    { name: 'Bounty Hunter', 'Grimstroke': -0.531 },
    { name: 'Brewmaster', 'Grimstroke': -0.361 },
    { name: 'Bristleback', 'Grimstroke': 0.214 },
    { name: 'Broodmother', 'Grimstroke': -2.059 },
    { name: 'Centaur Warrunner', 'Grimstroke': 0.363 },
    { name: 'Chaos Knight', 'Grimstroke': 0.564 },
    { name: 'Chen', 'Grimstroke': -0.038 },
    { name: 'Clinkz', 'Grimstroke': 1.35 },
    { name: 'Clockwerk', 'Grimstroke': -0.722 },
    { name: 'Crystal Maiden', 'Grimstroke': -0.52 },
    { name: 'Dark Seer', 'Grimstroke': -0.844 },
    { name: 'Dark Willow', 'Grimstroke': 0.524 },
    { name: 'Dawnbreaker', 'Grimstroke': -0.327 },
    { name: 'Dazzle', 'Grimstroke': -0.088 },
    { name: 'Death Prophet', 'Grimstroke': -0.007 },
    { name: 'Disruptor', 'Grimstroke': 0.427 },
    { name: 'Doom', 'Grimstroke': -0.119 },
    { name: 'Dragon Knight', 'Grimstroke': 0.592 },
    { name: 'Drow Ranger', 'Grimstroke': 0.821 },
    { name: 'Earth Spirit', 'Grimstroke': -0.463 },
    { name: 'Earthshaker', 'Grimstroke': -0.881 },
    { name: 'Elder Titan', 'Grimstroke': 0.766 },
    { name: 'Ember Spirit', 'Grimstroke': -1.459 },
    { name: 'Enchantress', 'Grimstroke': 0.377 },
    { name: 'Enigma', 'Grimstroke': -0.678 },
    { name: 'Faceless Void', 'Grimstroke': -1.03 },
    { name: 'Gyrocopter', 'Grimstroke': 1.201 },
    { name: 'Hoodwink', 'Grimstroke': 0.166 },
    { name: 'Huskar', 'Grimstroke': 0.599 },
    { name: 'Invoker', 'Grimstroke': 0.256 },
    { name: 'Io', 'Grimstroke': 0.78 },
    { name: 'Jakiro', 'Grimstroke': 0.989 },
    { name: 'Juggernaut', 'Grimstroke': -0.998 },
    { name: 'Keeper of the Light', 'Grimstroke': 0.52 },
    { name: 'Kunkka', 'Grimstroke': -0.228 },
    { name: 'Legion Commander', 'Grimstroke': 0.465 },
    { name: 'Leshrac', 'Grimstroke': 0.67 },
    { name: 'Lich', 'Grimstroke': -0.371 },
    { name: 'Lifestealer', 'Grimstroke': -1.627 },
    { name: 'Lina', 'Grimstroke': 0.835 },
    { name: 'Lion', 'Grimstroke': -0.73 },
    { name: 'Lone Druid', 'Grimstroke': 1.155 },
    { name: 'Luna', 'Grimstroke': 0.554 },
    { name: 'Lycan', 'Grimstroke': 0.753 },
    { name: 'Magnus', 'Grimstroke': -0.73 },
    { name: 'Marci', 'Grimstroke': -0.57 },
    { name: 'Mars', 'Grimstroke': 0.722 },
    { name: 'Medusa', 'Grimstroke': 1.329 },
    { name: 'Meepo', 'Grimstroke': 1.776 },
    { name: 'Mirana', 'Grimstroke': 0.974 },
    { name: 'Monkey King', 'Grimstroke': 0.488 },
    { name: 'Morphling', 'Grimstroke': 0.156 },
    { name: 'Muerta', 'Grimstroke': 0.843 },
    { name: 'Naga Siren', 'Grimstroke': -0.08 },
    { name: "Nature's Prophet", 'Grimstroke': 1.423 },
    { name: 'Necrophos', 'Grimstroke': -2.657 },
    { name: 'Night Stalker', 'Grimstroke': 0.754 },
    { name: 'Nyx Assassin', 'Grimstroke': -1.255 },
    { name: 'Ogre Magi', 'Grimstroke': -0.568 },
    { name: 'Omniknight', 'Grimstroke': -0.736 },
    { name: 'Oracle', 'Grimstroke': -0.331 },
    { name: 'Outworld Destroyer', 'Grimstroke': -0.505 },
    { name: 'Pangolier', 'Grimstroke': -1.749 },
    { name: 'Phantom Assassin', 'Grimstroke': 0.32 },
    { name: 'Phantom Lancer', 'Grimstroke': -0.458 },
    { name: 'Phoenix', 'Grimstroke': 0.178 },
    { name: 'Primal Beast', 'Grimstroke': -2.596 },
    { name: 'Puck', 'Grimstroke': -0.153 },
    { name: 'Pudge', 'Grimstroke': -1.078 },
    { name: 'Pugna', 'Grimstroke': -0.569 },
    { name: 'Queen of Pain', 'Grimstroke': -0.475 },
    { name: 'Razor', 'Grimstroke': 1.729 },
    { name: 'Riki', 'Grimstroke': -0.142 },
    { name: 'Ringmaster', 'Grimstroke': 0.373 },
    { name: 'Rubick', 'Grimstroke': -0.236 },
    { name: 'Sand King', 'Grimstroke': 0.1 },
    { name: 'Shadow Demon', 'Grimstroke': 2.023 },
    { name: 'Shadow Fiend', 'Grimstroke': 0.298 },
    { name: 'Shadow Shaman', 'Grimstroke': 0.302 },
    { name: 'Silencer', 'Grimstroke': 1.157 },
    { name: 'Skywrath Mage', 'Grimstroke': -0.529 },
    { name: 'Slardar', 'Grimstroke': -0.229 },
    { name: 'Slark', 'Grimstroke': -4.113 },
    { name: 'Snapfire', 'Grimstroke': -0.17 },
    { name: 'Sniper', 'Grimstroke': 1.514 },
    { name: 'Spectre', 'Grimstroke': 1.167 },
    { name: 'Spirit Breaker', 'Grimstroke': -0.138 },
    { name: 'Storm Spirit', 'Grimstroke': -1.542 },
    { name: 'Sven', 'Grimstroke': 0.214 },
    { name: 'Techies', 'Grimstroke': -0.486 },
    { name: 'Templar Assassin', 'Grimstroke': 0.222 },
    { name: 'Terrorblade', 'Grimstroke': -0.174 },
    { name: 'Tidehunter', 'Grimstroke': -1.663 },
    { name: 'Timbersaw', 'Grimstroke': -2.93 },
    { name: 'Tinker', 'Grimstroke': -0.05 },
    { name: 'Tiny', 'Grimstroke': -0.267 },
    { name: 'Treant Protector', 'Grimstroke': -0.699 },
    { name: 'Troll Warlord', 'Grimstroke': -0.818 },
    { name: 'Tusk', 'Grimstroke': 0.159 },
    { name: 'Underlord', 'Grimstroke': -0.153 },
    { name: 'Undying', 'Grimstroke': -0.566 },
    { name: 'Ursa', 'Grimstroke': -0.01 },
    { name: 'Vengeful Spirit', 'Grimstroke': 0.574 },
    { name: 'Venomancer', 'Grimstroke': 0.439 },
    { name: 'Viper', 'Grimstroke': -0.499 },
    { name: 'Visage', 'Grimstroke': 1.666 },
    { name: 'Void Spirit', 'Grimstroke': -0.692 },
    { name: 'Warlock', 'Grimstroke': 0.896 },
    { name: 'Weaver', 'Grimstroke': 0.58 },
    { name: 'Windranger', 'Grimstroke': 0.657 },
    { name: 'Winter Wyvern', 'Grimstroke': -0.423 },
    { name: 'Witch Doctor', 'Grimstroke': 0.152 },
    { name: 'Wraith King', 'Grimstroke': 1.295 },
    { name: 'Zeus', 'Grimstroke': 0.46 },
],
"Jakiro": [
    { name: 'Abaddon', 'Jakiro': 0.836 },
    { name: 'Alchemist', 'Jakiro': 0.058 },
    { name: 'Ancient Apparition', 'Jakiro': -0.052 },
    { name: 'Anti-Mage', 'Jakiro': -1.416 },
    { name: 'Arc Warden', 'Jakiro': -1.458 },
    { name: 'Axe', 'Jakiro': -0.943 },
    { name: 'Bane', 'Jakiro': 1.049 },
    { name: 'Batrider', 'Jakiro': 0.801 },
    { name: 'Beastmaster', 'Jakiro': -0.506 },
    { name: 'Bloodseeker', 'Jakiro': 0.035 },
    { name: 'Bounty Hunter', 'Jakiro': -0.178 },
    { name: 'Brewmaster', 'Jakiro': -0.048 },
    { name: 'Bristleback', 'Jakiro': -0.208 },
    { name: 'Broodmother', 'Jakiro': -1.351 },
    { name: 'Centaur Warrunner', 'Jakiro': 0.356 },
    { name: 'Chaos Knight', 'Jakiro': -2.207 },
    { name: 'Chen', 'Jakiro': 1.302 },
    { name: 'Clinkz', 'Jakiro': 0.626 },
    { name: 'Clockwerk', 'Jakiro': -1.129 },
    { name: 'Crystal Maiden', 'Jakiro': -0.616 },
    { name: 'Dark Seer', 'Jakiro': -0.573 },
    { name: 'Dark Willow', 'Jakiro': -0.395 },
    { name: 'Dawnbreaker', 'Jakiro': -1.016 },
    { name: 'Dazzle', 'Jakiro': -0.261 },
    { name: 'Death Prophet', 'Jakiro': 0.12 },
    { name: 'Disruptor', 'Jakiro': -0.044 },
    { name: 'Doom', 'Jakiro': -0.031 },
    { name: 'Dragon Knight', 'Jakiro': -0.095 },
    { name: 'Drow Ranger', 'Jakiro': 0.598 },
    { name: 'Earth Spirit', 'Jakiro': 0.543 },
    { name: 'Earthshaker', 'Jakiro': -0.727 },
    { name: 'Elder Titan', 'Jakiro': 0.204 },
    { name: 'Ember Spirit', 'Jakiro': 0.44 },
    { name: 'Enchantress', 'Jakiro': 0.701 },
    { name: 'Enigma', 'Jakiro': -0.799 },
    { name: 'Faceless Void', 'Jakiro': 0.782 },
    { name: 'Grimstroke', 'Jakiro': -0.588 },
    { name: 'Gyrocopter', 'Jakiro': 0.207 },
    { name: 'Hoodwink', 'Jakiro': 0.512 },
    { name: 'Huskar', 'Jakiro': 0.424 },
    { name: 'Invoker', 'Jakiro': 0.571 },
    { name: 'Io', 'Jakiro': -0.825 },
    { name: 'Juggernaut', 'Jakiro': 0.857 },
    { name: 'Keeper of the Light', 'Jakiro': 1.035 },
    { name: 'Kunkka', 'Jakiro': -0.483 },
    { name: 'Legion Commander', 'Jakiro': 1.141 },
    { name: 'Leshrac', 'Jakiro': -0.07 },
    { name: 'Lich', 'Jakiro': -0.659 },
    { name: 'Lifestealer', 'Jakiro': 0.752 },
    { name: 'Lina', 'Jakiro': 0.43 },
    { name: 'Lion', 'Jakiro': -0.07 },
    { name: 'Lone Druid', 'Jakiro': -2.359 },
    { name: 'Luna', 'Jakiro': -0.453 },
    { name: 'Lycan', 'Jakiro': -0.925 },
    { name: 'Magnus', 'Jakiro': 0.494 },
    { name: 'Marci', 'Jakiro': -0.403 },
    { name: 'Mars', 'Jakiro': -0.081 },
    { name: 'Medusa', 'Jakiro': -0.967 },
    { name: 'Meepo', 'Jakiro': -4.369 },
    { name: 'Mirana', 'Jakiro': 0.952 },
    { name: 'Monkey King', 'Jakiro': -0.266 },
    { name: 'Morphling', 'Jakiro': 2.107 },
    { name: 'Muerta', 'Jakiro': 0.012 },
    { name: 'Naga Siren', 'Jakiro': -1.736 },
    { name: "Nature's Prophet", 'Jakiro': 0.782 },
    { name: 'Necrophos', 'Jakiro': -0.353 },
    { name: 'Night Stalker', 'Jakiro': -0.142 },
    { name: 'Nyx Assassin', 'Jakiro': -0.298 },
    { name: 'Ogre Magi', 'Jakiro': -0.136 },
    { name: 'Omniknight', 'Jakiro': 0.083 },
    { name: 'Oracle', 'Jakiro': 0.311 },
    { name: 'Outworld Destroyer', 'Jakiro': -0.029 },
    { name: 'Pangolier', 'Jakiro': 1.42 },
    { name: 'Phantom Assassin', 'Jakiro': -0.68 },
    { name: 'Phantom Lancer', 'Jakiro': -0.641 },
    { name: 'Phoenix', 'Jakiro': -0.66 },
    { name: 'Primal Beast', 'Jakiro': -0.628 },
    { name: 'Puck', 'Jakiro': 0.065 },
    { name: 'Pudge', 'Jakiro': 0.566 },
    { name: 'Pugna', 'Jakiro': 1.414 },
    { name: 'Queen of Pain', 'Jakiro': 0.759 },
    { name: 'Razor', 'Jakiro': 0.042 },
    { name: 'Riki', 'Jakiro': -1.83 },
    { name: 'Ringmaster', 'Jakiro': 0.297 },
    { name: 'Rubick', 'Jakiro': 4.145 },
    { name: 'Sand King', 'Jakiro': -0.464 },
    { name: 'Shadow Demon', 'Jakiro': 0.15 },
    { name: 'Shadow Fiend', 'Jakiro': -0.283 },
    { name: 'Shadow Shaman', 'Jakiro': -0.486 },
    { name: 'Silencer', 'Jakiro': -0.383 },
    { name: 'Skywrath Mage', 'Jakiro': -0.07 },
    { name: 'Slardar', 'Jakiro': -0.734 },
    { name: 'Slark', 'Jakiro': 1.023 },
    { name: 'Snapfire', 'Jakiro': 0.706 },
    { name: 'Sniper', 'Jakiro': 0.935 },
    { name: 'Spectre', 'Jakiro': -1.075 },
    { name: 'Spirit Breaker', 'Jakiro': -1.407 },
    { name: 'Storm Spirit', 'Jakiro': 0.575 },
    { name: 'Sven', 'Jakiro': 0.327 },
    { name: 'Techies', 'Jakiro': -0.597 },
    { name: 'Templar Assassin', 'Jakiro': -0.255 },
    { name: 'Terrorblade', 'Jakiro': -0.625 },
    { name: 'Tidehunter', 'Jakiro': 1.278 },
    { name: 'Timbersaw', 'Jakiro': -0.333 },
    { name: 'Tinker', 'Jakiro': 0.262 },
    { name: 'Tiny', 'Jakiro': -0.25 },
    { name: 'Treant Protector', 'Jakiro': -0.195 },
    { name: 'Troll Warlord', 'Jakiro': -0.898 },
    { name: 'Tusk', 'Jakiro': 0.328 },
    { name: 'Underlord', 'Jakiro': -0.13 },
    { name: 'Undying', 'Jakiro': -0.515 },
    { name: 'Ursa', 'Jakiro': 0.437 },
    { name: 'Vengeful Spirit', 'Jakiro': -0.84 },
    { name: 'Venomancer', 'Jakiro': 0.577 },
    { name: 'Viper', 'Jakiro': 0.015 },
    { name: 'Visage', 'Jakiro': -0.83 },
    { name: 'Void Spirit', 'Jakiro': -0.237 },
    { name: 'Warlock', 'Jakiro': -1.613 },
    { name: 'Weaver', 'Jakiro': 0.85 },
    { name: 'Windranger', 'Jakiro': 1.325 },
    { name: 'Winter Wyvern', 'Jakiro': 0.787 },
    { name: 'Witch Doctor', 'Jakiro': -0.7 },
    { name: 'Wraith King', 'Jakiro': -2.232 },
    { name: 'Zeus', 'Jakiro': 0.659 },
],
"Keeper of the Light": [
    { name: 'Abaddon', 'Keeper of the Light': -0.545 },
    { name: 'Alchemist', 'Keeper of the Light': -1.687 },
    { name: 'Ancient Apparition', 'Keeper of the Light': -0.713 },
    { name: 'Anti-Mage', 'Keeper of the Light': 1.002 },
    { name: 'Arc Warden', 'Keeper of the Light': 0.471 },
    { name: 'Axe', 'Keeper of the Light': -0.85 },
    { name: 'Bane', 'Keeper of the Light': -0.277 },
    { name: 'Batrider', 'Keeper of the Light': 1.701 },
    { name: 'Beastmaster', 'Keeper of the Light': -0.439 },
    { name: 'Bloodseeker', 'Keeper of the Light': -2.476 },
    { name: 'Bounty Hunter', 'Keeper of the Light': 0.14 },
    { name: 'Brewmaster', 'Keeper of the Light': 0.78 },
    { name: 'Bristleback', 'Keeper of the Light': -0.661 },
    { name: 'Broodmother', 'Keeper of the Light': 0.245 },
    { name: 'Centaur Warrunner', 'Keeper of the Light': -0.72 },
    { name: 'Chaos Knight', 'Keeper of the Light': 0.505 },
    { name: 'Chen', 'Keeper of the Light': -3.343 },
    { name: 'Clinkz', 'Keeper of the Light': 0.68 },
    { name: 'Clockwerk', 'Keeper of the Light': -0.985 },
    { name: 'Crystal Maiden', 'Keeper of the Light': 1.383 },
    { name: 'Dark Seer', 'Keeper of the Light': -1.546 },
    { name: 'Dark Willow', 'Keeper of the Light': -0.012 },
    { name: 'Dawnbreaker', 'Keeper of the Light': -1.531 },
    { name: 'Dazzle', 'Keeper of the Light': 0.071 },
    { name: 'Death Prophet', 'Keeper of the Light': -2.42 },
    { name: 'Disruptor', 'Keeper of the Light': -1.536 },
    { name: 'Doom', 'Keeper of the Light': -1.44 },
    { name: 'Dragon Knight', 'Keeper of the Light': -0.312 },
    { name: 'Drow Ranger', 'Keeper of the Light': -0.599 },
    { name: 'Earth Spirit', 'Keeper of the Light': 1.556 },
    { name: 'Earthshaker', 'Keeper of the Light': 0.625 },
    { name: 'Elder Titan', 'Keeper of the Light': -1.3 },
    { name: 'Ember Spirit', 'Keeper of the Light': -0.274 },
    { name: 'Enchantress', 'Keeper of the Light': -0.436 },
    { name: 'Enigma', 'Keeper of the Light': 0.06 },
    { name: 'Faceless Void', 'Keeper of the Light': 2.42 },
    { name: 'Grimstroke', 'Keeper of the Light': -0.518 },
    { name: 'Gyrocopter', 'Keeper of the Light': 0.413 },
    { name: 'Hoodwink', 'Keeper of the Light': 0.09 },
    { name: 'Huskar', 'Keeper of the Light': 2.795 },
    { name: 'Invoker', 'Keeper of the Light': -0.85 },
    { name: 'Io', 'Keeper of the Light': 0.453 },
    { name: 'Jakiro', 'Keeper of the Light': -0.629 },
    { name: 'Juggernaut', 'Keeper of the Light': 0.745 },
    { name: 'Kunkka', 'Keeper of the Light': -1.611 },
    { name: 'Legion Commander', 'Keeper of the Light': 1.664 },
    { name: 'Leshrac', 'Keeper of the Light': -1.177 },
    { name: 'Lich', 'Keeper of the Light': -0.568 },
    { name: 'Lifestealer', 'Keeper of the Light': -0.019 },
    { name: 'Lina', 'Keeper of the Light': 0.204 },
    { name: 'Lion', 'Keeper of the Light': 0.619 },
    { name: 'Lone Druid', 'Keeper of the Light': 0.071 },
    { name: 'Luna', 'Keeper of the Light': -0.975 },
    { name: 'Lycan', 'Keeper of the Light': -0.287 },
    { name: 'Magnus', 'Keeper of the Light': 0.772 },
    { name: 'Marci', 'Keeper of the Light': -0.456 },
    { name: 'Mars', 'Keeper of the Light': -1.314 },
    { name: 'Medusa', 'Keeper of the Light': -1.03 },
    { name: 'Meepo', 'Keeper of the Light': 0.808 },
    { name: 'Mirana', 'Keeper of the Light': 0.031 },
    { name: 'Monkey King', 'Keeper of the Light': -0.774 },
    { name: 'Morphling', 'Keeper of the Light': -0.266 },
    { name: 'Muerta', 'Keeper of the Light': -0.33 },
    { name: 'Naga Siren', 'Keeper of the Light': 0.02 },
    { name: "Nature's Prophet", 'Keeper of the Light': 0.899 },
    { name: 'Necrophos', 'Keeper of the Light': -2.077 },
    { name: 'Night Stalker', 'Keeper of the Light': -0.548 },
    { name: 'Nyx Assassin', 'Keeper of the Light': 1.304 },
    { name: 'Ogre Magi', 'Keeper of the Light': 0.927 },
    { name: 'Omniknight', 'Keeper of the Light': -1.538 },
    { name: 'Oracle', 'Keeper of the Light': -0.144 },
    { name: 'Outworld Destroyer', 'Keeper of the Light': -0.658 },
    { name: 'Pangolier', 'Keeper of the Light': -0.068 },
    { name: 'Phantom Assassin', 'Keeper of the Light': 2.004 },
    { name: 'Phantom Lancer', 'Keeper of the Light': 0.06 },
    { name: 'Phoenix', 'Keeper of the Light': -2.23 },
    { name: 'Primal Beast', 'Keeper of the Light': 0.068 },
    { name: 'Puck', 'Keeper of the Light': -0.778 },
    { name: 'Pudge', 'Keeper of the Light': 0.42 },
    { name: 'Pugna', 'Keeper of the Light': 0.629 },
    { name: 'Queen of Pain', 'Keeper of the Light': 1.361 },
    { name: 'Razor', 'Keeper of the Light': -0.92 },
    { name: 'Riki', 'Keeper of the Light': -0.517 },
    { name: 'Ringmaster', 'Keeper of the Light': -0.547 },
    { name: 'Rubick', 'Keeper of the Light': -0.959 },
    { name: 'Sand King', 'Keeper of the Light': 0.085 },
    { name: 'Shadow Demon', 'Keeper of the Light': -1.22 },
    { name: 'Shadow Fiend', 'Keeper of the Light': -0.108 },
    { name: 'Shadow Shaman', 'Keeper of the Light': 1.183 },
    { name: 'Silencer', 'Keeper of the Light': -0.24 },
    { name: 'Skywrath Mage', 'Keeper of the Light': 1.003 },
    { name: 'Slardar', 'Keeper of the Light': 0.191 },
    { name: 'Slark', 'Keeper of the Light': 0.822 },
    { name: 'Snapfire', 'Keeper of the Light': 0.301 },
    { name: 'Sniper', 'Keeper of the Light': 1.031 },
    { name: 'Spectre', 'Keeper of the Light': -0.453 },
    { name: 'Spirit Breaker', 'Keeper of the Light': 1.385 },
    { name: 'Storm Spirit', 'Keeper of the Light': 2.564 },
    { name: 'Sven', 'Keeper of the Light': -0.839 },
    { name: 'Techies', 'Keeper of the Light': -0.41 },
    { name: 'Templar Assassin', 'Keeper of the Light': 0.343 },
    { name: 'Terrorblade', 'Keeper of the Light': -0.328 },
    { name: 'Tidehunter', 'Keeper of the Light': -0.172 },
    { name: 'Timbersaw', 'Keeper of the Light': -0.658 },
    { name: 'Tinker', 'Keeper of the Light': -0.655 },
    { name: 'Tiny', 'Keeper of the Light': -0.658 },
    { name: 'Treant Protector', 'Keeper of the Light': -0.742 },
    { name: 'Troll Warlord', 'Keeper of the Light': -0.427 },
    { name: 'Tusk', 'Keeper of the Light': 0.193 },
    { name: 'Underlord', 'Keeper of the Light': -0.935 },
    { name: 'Undying', 'Keeper of the Light': -0.459 },
    { name: 'Ursa', 'Keeper of the Light': 0.072 },
    { name: 'Vengeful Spirit', 'Keeper of the Light': 0.479 },
    { name: 'Venomancer', 'Keeper of the Light': 0.165 },
    { name: 'Viper', 'Keeper of the Light': 0.956 },
    { name: 'Visage', 'Keeper of the Light': 0.286 },
    { name: 'Void Spirit', 'Keeper of the Light': 1.36 },
    { name: 'Warlock', 'Keeper of the Light': -1.68 },
    { name: 'Weaver', 'Keeper of the Light': 2.291 },
    { name: 'Windranger', 'Keeper of the Light': 0.792 },
    { name: 'Winter Wyvern', 'Keeper of the Light': -0.611 },
    { name: 'Witch Doctor', 'Keeper of the Light': -0.266 },
    { name: 'Wraith King', 'Keeper of the Light': -1.009 },
    { name: 'Zeus', 'Keeper of the Light': -1.022 },
],
"Leshrac": [
    { name: 'Abaddon', 'Leshrac': -0.827 },
    { name: 'Alchemist', 'Leshrac': -1.733 },
    { name: 'Ancient Apparition', 'Leshrac': 2.623 },
    { name: 'Anti-Mage', 'Leshrac': 0.215 },
    { name: 'Arc Warden', 'Leshrac': -0.565 },
    { name: 'Axe', 'Leshrac': -3.735 },
    { name: 'Bane', 'Leshrac': -0.393 },
    { name: 'Batrider', 'Leshrac': 0.187 },
    { name: 'Beastmaster', 'Leshrac': 0.436 },
    { name: 'Bloodseeker', 'Leshrac': -0.201 },
    { name: 'Bounty Hunter', 'Leshrac': -0.112 },
    { name: 'Brewmaster', 'Leshrac': -2.915 },
    { name: 'Bristleback', 'Leshrac': 2.724 },
    { name: 'Broodmother', 'Leshrac': 0.191 },
    { name: 'Centaur Warrunner', 'Leshrac': 0.263 },
    { name: 'Chaos Knight', 'Leshrac': -1.357 },
    { name: 'Chen', 'Leshrac': 0.334 },
    { name: 'Clinkz', 'Leshrac': 2.146 },
    { name: 'Clockwerk', 'Leshrac': 0.012 },
    { name: 'Crystal Maiden', 'Leshrac': -0.46 },
    { name: 'Dark Seer', 'Leshrac': -1.414 },
    { name: 'Dark Willow', 'Leshrac': -0.153 },
    { name: 'Dawnbreaker', 'Leshrac': 1.006 },
    { name: 'Dazzle', 'Leshrac': 0.637 },
    { name: 'Death Prophet', 'Leshrac': 0.886 },
    { name: 'Disruptor', 'Leshrac': 0.735 },
    { name: 'Doom', 'Leshrac': 0.74 },
    { name: 'Dragon Knight', 'Leshrac': -0.03 },
    { name: 'Drow Ranger', 'Leshrac': 2.304 },
    { name: 'Earth Spirit', 'Leshrac': -0.504 },
    { name: 'Earthshaker', 'Leshrac': -0.112 },
    { name: 'Elder Titan', 'Leshrac': -0.82 },
    { name: 'Ember Spirit', 'Leshrac': 1.187 },
    { name: 'Enchantress', 'Leshrac': 0.297 },
    { name: 'Enigma', 'Leshrac': -0.112 },
    { name: 'Faceless Void', 'Leshrac': -0.242 },
    { name: 'Grimstroke', 'Leshrac': -0.719 },
    { name: 'Gyrocopter', 'Leshrac': 0.981 },
    { name: 'Hoodwink', 'Leshrac': 0.714 },
    { name: 'Huskar', 'Leshrac': -0.211 },
    { name: 'Invoker', 'Leshrac': 0.911 },
    { name: 'Io', 'Leshrac': -0.624 },
    { name: 'Jakiro', 'Leshrac': 0.184 },
    { name: 'Juggernaut', 'Leshrac': 0.122 },
    { name: 'Keeper of the Light', 'Leshrac': 1.176 },
    { name: 'Kunkka', 'Leshrac': 0.663 },
    { name: 'Legion Commander', 'Leshrac': 0.723 },
    { name: 'Lich', 'Leshrac': -0.643 },
    { name: 'Lifestealer', 'Leshrac': 1.382 },
    { name: 'Lina', 'Leshrac': 2.583 },
    { name: 'Lion', 'Leshrac': 0.778 },
    { name: 'Lone Druid', 'Leshrac': -1.641 },
    { name: 'Luna', 'Leshrac': 1.12 },
    { name: 'Lycan', 'Leshrac': -0.622 },
    { name: 'Magnus', 'Leshrac': 0.337 },
    { name: 'Marci', 'Leshrac': 0.877 },
    { name: 'Mars', 'Leshrac': 0.285 },
    { name: 'Medusa', 'Leshrac': 2.562 },
    { name: 'Meepo', 'Leshrac': -5.887 },
    { name: 'Mirana', 'Leshrac': 0.931 },
    { name: 'Monkey King', 'Leshrac': -1.253 },
    { name: 'Morphling', 'Leshrac': 0.098 },
    { name: 'Muerta', 'Leshrac': -0.044 },
    { name: 'Naga Siren', 'Leshrac': -2.997 },
    { name: "Nature's Prophet", 'Leshrac': -0.016 },
    { name: 'Necrophos', 'Leshrac': -4.482 },
    { name: 'Night Stalker', 'Leshrac': 0.285 },
    { name: 'Nyx Assassin', 'Leshrac': 0.541 },
    { name: 'Ogre Magi', 'Leshrac': 0.634 },
    { name: 'Omniknight', 'Leshrac': -1.451 },
    { name: 'Oracle', 'Leshrac': 0.835 },
    { name: 'Outworld Destroyer', 'Leshrac': 2.11 },
    { name: 'Pangolier', 'Leshrac': 0.217 },
    { name: 'Phantom Assassin', 'Leshrac': -1.372 },
    { name: 'Phantom Lancer', 'Leshrac': -3.079 },
    { name: 'Phoenix', 'Leshrac': -0.025 },
    { name: 'Primal Beast', 'Leshrac': -0.716 },
    { name: 'Puck', 'Leshrac': 0.033 },
    { name: 'Pudge', 'Leshrac': -0.269 },
    { name: 'Pugna', 'Leshrac': -0.244 },
    { name: 'Queen of Pain', 'Leshrac': 2.028 },
    { name: 'Razor', 'Leshrac': -0.33 },
    { name: 'Riki', 'Leshrac': -2.132 },
    { name: 'Ringmaster', 'Leshrac': 0.389 },
    { name: 'Rubick', 'Leshrac': -0.368 },
    { name: 'Sand King', 'Leshrac': -1.704 },
    { name: 'Shadow Demon', 'Leshrac': 0.141 },
    { name: 'Shadow Fiend', 'Leshrac': -0.378 },
    { name: 'Shadow Shaman', 'Leshrac': -0.073 },
    { name: 'Silencer', 'Leshrac': 1.435 },
    { name: 'Skywrath Mage', 'Leshrac': 1.137 },
    { name: 'Slardar', 'Leshrac': 1.11 },
    { name: 'Slark', 'Leshrac': -4.047 },
    { name: 'Snapfire', 'Leshrac': 0.933 },
    { name: 'Sniper', 'Leshrac': 1.259 },
    { name: 'Spectre', 'Leshrac': -1.687 },
    { name: 'Spirit Breaker', 'Leshrac': -0.576 },
    { name: 'Storm Spirit', 'Leshrac': -0.641 },
    { name: 'Sven', 'Leshrac': 0.496 },
    { name: 'Techies', 'Leshrac': -1.24 },
    { name: 'Templar Assassin', 'Leshrac': -0.84 },
    { name: 'Terrorblade', 'Leshrac': -0.948 },
    { name: 'Tidehunter', 'Leshrac': -3.112 },
    { name: 'Timbersaw', 'Leshrac': -1.044 },
    { name: 'Tinker', 'Leshrac': -1.308 },
    { name: 'Tiny', 'Leshrac': -1.852 },
    { name: 'Treant Protector', 'Leshrac': -0.835 },
    { name: 'Troll Warlord', 'Leshrac': -1.272 },
    { name: 'Tusk', 'Leshrac': 0.103 },
    { name: 'Underlord', 'Leshrac': 0.323 },
    { name: 'Undying', 'Leshrac': 3.413 },
    { name: 'Ursa', 'Leshrac': 1.852 },
    { name: 'Vengeful Spirit', 'Leshrac': 0.259 },
    { name: 'Venomancer', 'Leshrac': 0.139 },
    { name: 'Viper', 'Leshrac': 0.09 },
    { name: 'Visage', 'Leshrac': 0.809 },
    { name: 'Void Spirit', 'Leshrac': -0.035 },
    { name: 'Warlock', 'Leshrac': 0.292 },
    { name: 'Weaver', 'Leshrac': 1.244 },
    { name: 'Windranger', 'Leshrac': 1.062 },
    { name: 'Winter Wyvern', 'Leshrac': -1.052 },
    { name: 'Witch Doctor', 'Leshrac': 0.919 },
    { name: 'Wraith King', 'Leshrac': -1.068 },
    { name: 'Zeus', 'Leshrac': 0.586 },
],
"Lich": [
    { name: 'Abaddon', 'Lich': -0.808 },
    { name: 'Alchemist', 'Lich': -0.14 },
    { name: 'Ancient Apparition', 'Lich': 0.147 },
    { name: 'Anti-Mage', 'Lich': 1.871 },
    { name: 'Arc Warden', 'Lich': -1.56 },
    { name: 'Axe', 'Lich': -0.144 },
    { name: 'Bane', 'Lich': 0.266 },
    { name: 'Batrider', 'Lich': -0.046 },
    { name: 'Beastmaster', 'Lich': -0.279 },
    { name: 'Bloodseeker', 'Lich': 0.029 },
    { name: 'Bounty Hunter', 'Lich': 0.552 },
    { name: 'Brewmaster', 'Lich': 1.919 },
    { name: 'Bristleback', 'Lich': 1.634 },
    { name: 'Broodmother', 'Lich': -0.102 },
    { name: 'Centaur Warrunner', 'Lich': 1.191 },
    { name: 'Chaos Knight', 'Lich': -1.233 },
    { name: 'Chen', 'Lich': 0.307 },
    { name: 'Clinkz', 'Lich': 0.721 },
    { name: 'Clockwerk', 'Lich': -0.692 },
    { name: 'Crystal Maiden', 'Lich': -0.004 },
    { name: 'Dark Seer', 'Lich': 0.164 },
    { name: 'Dark Willow', 'Lich': 0.761 },
    { name: 'Dawnbreaker', 'Lich': -0.468 },
    { name: 'Dazzle', 'Lich': 0.441 },
    { name: 'Death Prophet', 'Lich': 0.407 },
    { name: 'Disruptor', 'Lich': 0.451 },
    { name: 'Doom', 'Lich': 0.454 },
    { name: 'Dragon Knight', 'Lich': -0.886 },
    { name: 'Drow Ranger', 'Lich': 0.323 },
    { name: 'Earth Spirit', 'Lich': 1.094 },
    { name: 'Earthshaker', 'Lich': -0.342 },
    { name: 'Elder Titan', 'Lich': -0.225 },
    { name: 'Ember Spirit', 'Lich': 0.863 },
    { name: 'Enchantress', 'Lich': 0.432 },
    { name: 'Enigma', 'Lich': 0.424 },
    { name: 'Faceless Void', 'Lich': 0.819 },
    { name: 'Grimstroke', 'Lich': 0.608 },
    { name: 'Gyrocopter', 'Lich': 0.296 },
    { name: 'Hoodwink', 'Lich': 0.396 },
    { name: 'Huskar', 'Lich': -0.179 },
    { name: 'Invoker', 'Lich': 0.601 },
    { name: 'Io', 'Lich': -1.075 },
    { name: 'Jakiro', 'Lich': 0.477 },
    { name: 'Juggernaut', 'Lich': 0.463 },
    { name: 'Keeper of the Light', 'Lich': 1.038 },
    { name: 'Kunkka', 'Lich': 0.318 },
    { name: 'Legion Commander', 'Lich': -0.582 },
    { name: 'Leshrac', 'Lich': 0.842 },
    { name: 'Lifestealer', 'Lich': -0.198 },
    { name: 'Lina', 'Lich': 0.134 },
    { name: 'Lion', 'Lich': -0.281 },
    { name: 'Lone Druid', 'Lich': -1.076 },
    { name: 'Luna', 'Lich': -0.454 },
    { name: 'Lycan', 'Lich': 1.162 },
    { name: 'Magnus', 'Lich': -0.304 },
    { name: 'Marci', 'Lich': -1.019 },
    { name: 'Mars', 'Lich': -0.404 },
    { name: 'Medusa', 'Lich': -0.714 },
    { name: 'Meepo', 'Lich': -0.833 },
    { name: 'Mirana', 'Lich': 0.987 },
    { name: 'Monkey King', 'Lich': -0.886 },
    { name: 'Morphling', 'Lich': 0.853 },
    { name: 'Muerta', 'Lich': -0.383 },
    { name: 'Naga Siren', 'Lich': -0.855 },
    { name: "Nature's Prophet", 'Lich': 0.796 },
    { name: 'Necrophos', 'Lich': -1.145 },
    { name: 'Night Stalker', 'Lich': -0.551 },
    { name: 'Nyx Assassin', 'Lich': 0.144 },
    { name: 'Ogre Magi', 'Lich': -0.549 },
    { name: 'Omniknight', 'Lich': 0.531 },
    { name: 'Oracle', 'Lich': 1.552 },
    { name: 'Outworld Destroyer', 'Lich': 1.292 },
    { name: 'Pangolier', 'Lich': 1.964 },
    { name: 'Phantom Assassin', 'Lich': -0.826 },
    { name: 'Phantom Lancer', 'Lich': 1.487 },
    { name: 'Phoenix', 'Lich': 1.026 },
    { name: 'Primal Beast', 'Lich': 0.065 },
    { name: 'Puck', 'Lich': 0.972 },
    { name: 'Pudge', 'Lich': 0.237 },
    { name: 'Pugna', 'Lich': 1.949 },
    { name: 'Queen of Pain', 'Lich': 1.345 },
    { name: 'Razor', 'Lich': 0.346 },
    { name: 'Riki', 'Lich': -0.765 },
    { name: 'Ringmaster', 'Lich': 0.753 },
    { name: 'Rubick', 'Lich': 0.302 },
    { name: 'Sand King', 'Lich': -0.038 },
    { name: 'Shadow Demon', 'Lich': 2.162 },
    { name: 'Shadow Fiend', 'Lich': -0.386 },
    { name: 'Shadow Shaman', 'Lich': 0.451 },
    { name: 'Silencer', 'Lich': 0.712 },
    { name: 'Skywrath Mage', 'Lich': -0.124 },
    { name: 'Slardar', 'Lich': 0.102 },
    { name: 'Slark', 'Lich': 0.8 },
    { name: 'Snapfire', 'Lich': 0.643 },
    { name: 'Sniper', 'Lich': 0.545 },
    { name: 'Spectre', 'Lich': 0.331 },
    { name: 'Spirit Breaker', 'Lich': -1.386 },
    { name: 'Storm Spirit', 'Lich': 0.325 },
    { name: 'Sven', 'Lich': -0.687 },
    { name: 'Techies', 'Lich': -0.013 },
    { name: 'Templar Assassin', 'Lich': 0.696 },
    { name: 'Terrorblade', 'Lich': -1.283 },
    { name: 'Tidehunter', 'Lich': -0.418 },
    { name: 'Timbersaw', 'Lich': 1.32 },
    { name: 'Tinker', 'Lich': -0.441 },
    { name: 'Tiny', 'Lich': -0.652 },
    { name: 'Treant Protector', 'Lich': -0.314 },
    { name: 'Troll Warlord', 'Lich': -0.035 },
    { name: 'Tusk', 'Lich': -0.855 },
    { name: 'Underlord', 'Lich': 0.255 },
    { name: 'Undying', 'Lich': 0.803 },
    { name: 'Ursa', 'Lich': 0.431 },
    { name: 'Vengeful Spirit', 'Lich': -0.745 },
    { name: 'Venomancer', 'Lich': 0.343 },
    { name: 'Viper', 'Lich': 0.086 },
    { name: 'Visage', 'Lich': 0.914 },
    { name: 'Void Spirit', 'Lich': 0.439 },
    { name: 'Warlock', 'Lich': 0.381 },
    { name: 'Weaver', 'Lich': 2.155 },
    { name: 'Windranger', 'Lich': 0.758 },
    { name: 'Winter Wyvern', 'Lich': -0.604 },
    { name: 'Witch Doctor', 'Lich': 0.257 },
    { name: 'Wraith King', 'Lich': -1.071 },
    { name: 'Zeus', 'Lich': 0.819 },
],
"Lina": [
    { name: 'Abaddon', 'Lina': -1.863 },
    { name: 'Alchemist', 'Lina': -0.684 },
    { name: 'Ancient Apparition', 'Lina': -0.191 },
    { name: 'Anti-Mage', 'Lina': -0.401 },
    { name: 'Arc Warden', 'Lina': -0.934 },
    { name: 'Axe', 'Lina': 1.943 },
    { name: 'Bane', 'Lina': 1.163 },
    { name: 'Batrider', 'Lina': 1.234 },
    { name: 'Beastmaster', 'Lina': -1.262 },
    { name: 'Bloodseeker', 'Lina': -1.671 },
    { name: 'Bounty Hunter', 'Lina': 0.135 },
    { name: 'Brewmaster', 'Lina': -0.816 },
    { name: 'Bristleback', 'Lina': 1.28 },
    { name: 'Broodmother', 'Lina': -2.086 },
    { name: 'Centaur Warrunner', 'Lina': 0.163 },
    { name: 'Chaos Knight', 'Lina': -1.93 },
    { name: 'Chen', 'Lina': 0.859 },
    { name: 'Clinkz', 'Lina': 1.245 },
    { name: 'Clockwerk', 'Lina': -0.569 },
    { name: 'Crystal Maiden', 'Lina': -0.329 },
    { name: 'Dark Seer', 'Lina': -2.435 },
    { name: 'Dark Willow', 'Lina': 0.024 },
    { name: 'Dawnbreaker', 'Lina': -0.965 },
    { name: 'Dazzle', 'Lina': 0.613 },
    { name: 'Death Prophet', 'Lina': -1.456 },
    { name: 'Disruptor', 'Lina': -0.734 },
    { name: 'Doom', 'Lina': -1.804 },
    { name: 'Dragon Knight', 'Lina': -0.251 },
    { name: 'Drow Ranger', 'Lina': 1.378 },
    { name: 'Earth Spirit', 'Lina': -0.366 },
    { name: 'Earthshaker', 'Lina': -0.613 },
    { name: 'Elder Titan', 'Lina': -0.333 },
    { name: 'Ember Spirit', 'Lina': -0.49 },
    { name: 'Enchantress', 'Lina': 0.487 },
    { name: 'Enigma', 'Lina': -0.778 },
    { name: 'Faceless Void', 'Lina': 1.353 },
    { name: 'Grimstroke', 'Lina': -0.602 },
    { name: 'Gyrocopter', 'Lina': 0.127 },
    { name: 'Hoodwink', 'Lina': 0.585 },
    { name: 'Huskar', 'Lina': -0.802 },
    { name: 'Invoker', 'Lina': 1.286 },
    { name: 'Io', 'Lina': -0.606 },
    { name: 'Jakiro', 'Lina': -0.416 },
    { name: 'Juggernaut', 'Lina': -0.131 },
    { name: 'Keeper of the Light', 'Lina': 0.177 },
    { name: 'Kunkka', 'Lina': 0.428 },
    { name: 'Legion Commander', 'Lina': 0.748 },
    { name: 'Leshrac', 'Lina': -2.268 },
    { name: 'Lich', 'Lina': -0.41 },
    { name: 'Lifestealer', 'Lina': -0.369 },
    { name: 'Lion', 'Lina': -0.178 },
    { name: 'Lone Druid', 'Lina': -1.468 },
    { name: 'Luna', 'Lina': -1.074 },
    { name: 'Lycan', 'Lina': -0.194 },
    { name: 'Magnus', 'Lina': 0.596 },
    { name: 'Marci', 'Lina': 0.162 },
    { name: 'Mars', 'Lina': 1.087 },
    { name: 'Medusa', 'Lina': 0.485 },
    { name: 'Meepo', 'Lina': -3.852 },
    { name: 'Mirana', 'Lina': 0.724 },
    { name: 'Monkey King', 'Lina': 0.143 },
    { name: 'Morphling', 'Lina': -0.423 },
    { name: 'Muerta', 'Lina': -1.087 },
    { name: 'Naga Siren', 'Lina': -4.824 },
    { name: "Nature's Prophet", 'Lina': -0.017 },
    { name: 'Necrophos', 'Lina': -3.344 },
    { name: 'Night Stalker', 'Lina': -0.754 },
    { name: 'Nyx Assassin', 'Lina': 0.833 },
    { name: 'Ogre Magi', 'Lina': -0.04 },
    { name: 'Omniknight', 'Lina': -1.229 },
    { name: 'Oracle', 'Lina': -0.496 },
    { name: 'Outworld Destroyer', 'Lina': 0.095 },
    { name: 'Pangolier', 'Lina': 1.14 },
    { name: 'Phantom Assassin', 'Lina': 2.155 },
    { name: 'Phantom Lancer', 'Lina': -3.437 },
    { name: 'Phoenix', 'Lina': -2.784 },
    { name: 'Primal Beast', 'Lina': -0.614 },
    { name: 'Puck', 'Lina': 0.54 },
    { name: 'Pudge', 'Lina': 0.453 },
    { name: 'Pugna', 'Lina': -0.198 },
    { name: 'Queen of Pain', 'Lina': -1.626 },
    { name: 'Razor', 'Lina': 0.188 },
    { name: 'Riki', 'Lina': -0.139 },
    { name: 'Ringmaster', 'Lina': 0.349 },
    { name: 'Rubick', 'Lina': 1.118 },
    { name: 'Sand King', 'Lina': -1.938 },
    { name: 'Shadow Demon', 'Lina': 0.483 },
    { name: 'Shadow Fiend', 'Lina': -0.552 },
    { name: 'Shadow Shaman', 'Lina': 0.435 },
    { name: 'Silencer', 'Lina': -0.187 },
    { name: 'Skywrath Mage', 'Lina': -0.41 },
    { name: 'Slardar', 'Lina': 1.313 },
    { name: 'Slark', 'Lina': -0.056 },
    { name: 'Snapfire', 'Lina': 0.394 },
    { name: 'Sniper', 'Lina': 1.648 },
    { name: 'Spectre', 'Lina': -0.568 },
    { name: 'Spirit Breaker', 'Lina': 1.131 },
    { name: 'Storm Spirit', 'Lina': 0.083 },
    { name: 'Sven', 'Lina': -0.165 },
    { name: 'Techies', 'Lina': -0.149 },
    { name: 'Templar Assassin', 'Lina': 3.263 },
    { name: 'Terrorblade', 'Lina': -1.452 },
    { name: 'Tidehunter', 'Lina': 0.764 },
    { name: 'Timbersaw', 'Lina': -1.994 },
    { name: 'Tinker', 'Lina': -2.944 },
    { name: 'Tiny', 'Lina': 0.511 },
    { name: 'Treant Protector', 'Lina': -1.164 },
    { name: 'Troll Warlord', 'Lina': -1.345 },
    { name: 'Tusk', 'Lina': 0.857 },
    { name: 'Underlord', 'Lina': -2.317 },
    { name: 'Undying', 'Lina': -0.723 },
    { name: 'Ursa', 'Lina': 1.223 },
    { name: 'Vengeful Spirit', 'Lina': 0.359 },
    { name: 'Venomancer', 'Lina': -0.82 },
    { name: 'Viper', 'Lina': -0.583 },
    { name: 'Visage', 'Lina': -0.809 },
    { name: 'Void Spirit', 'Lina': 0.01 },
    { name: 'Warlock', 'Lina': -1.91 },
    { name: 'Weaver', 'Lina': 0.331 },
    { name: 'Windranger', 'Lina': 1.076 },
    { name: 'Winter Wyvern', 'Lina': 1.084 },
    { name: 'Witch Doctor', 'Lina': 0.111 },
    { name: 'Wraith King', 'Lina': -0.222 },
    { name: 'Zeus', 'Lina': -0.162 },
],
"Lion": [
    { name: 'Abaddon', 'Lion': -1.553 },
    { name: 'Alchemist', 'Lion': -0.397 },
    { name: 'Ancient Apparition', 'Lion': -0.772 },
    { name: 'Anti-Mage', 'Lion': -0.845 },
    { name: 'Arc Warden', 'Lion': 0.458 },
    { name: 'Axe', 'Lion': -0.121 },
    { name: 'Bane', 'Lion': 0.202 },
    { name: 'Batrider', 'Lion': 0.582 },
    { name: 'Beastmaster', 'Lion': 2.062 },
    { name: 'Bloodseeker', 'Lion': -0.754 },
    { name: 'Bounty Hunter', 'Lion': -1.355 },
    { name: 'Brewmaster', 'Lion': 0.596 },
    { name: 'Bristleback', 'Lion': -0.361 },
    { name: 'Broodmother', 'Lion': -1.337 },
    { name: 'Centaur Warrunner', 'Lion': 0.305 },
    { name: 'Chaos Knight', 'Lion': 0.125 },
    { name: 'Chen', 'Lion': 1.142 },
    { name: 'Clinkz', 'Lion': -0.354 },
    { name: 'Clockwerk', 'Lion': -0.389 },
    { name: 'Crystal Maiden', 'Lion': -0.192 },
    { name: 'Dark Seer', 'Lion': 0.256 },
    { name: 'Dark Willow', 'Lion': -0.415 },
    { name: 'Dawnbreaker', 'Lion': -0.036 },
    { name: 'Dazzle', 'Lion': 1.106 },
    { name: 'Death Prophet', 'Lion': 0.512 },
    { name: 'Disruptor', 'Lion': -0.023 },
    { name: 'Doom', 'Lion': 0.613 },
    { name: 'Dragon Knight', 'Lion': 1.013 },
    { name: 'Drow Ranger', 'Lion': 1.009 },
    { name: 'Earth Spirit', 'Lion': -0.559 },
    { name: 'Earthshaker', 'Lion': -0.888 },
    { name: 'Elder Titan', 'Lion': -0.718 },
    { name: 'Ember Spirit', 'Lion': -0.052 },
    { name: 'Enchantress', 'Lion': -0.295 },
    { name: 'Enigma', 'Lion': 0.1 },
    { name: 'Faceless Void', 'Lion': -0.646 },
    { name: 'Grimstroke', 'Lion': 0.804 },
    { name: 'Gyrocopter', 'Lion': 1.226 },
    { name: 'Hoodwink', 'Lion': 0.488 },
    { name: 'Huskar', 'Lion': -0.38 },
    { name: 'Invoker', 'Lion': 0.783 },
    { name: 'Io', 'Lion': 0.033 },
    { name: 'Jakiro', 'Lion': -0.055 },
    { name: 'Juggernaut', 'Lion': -1.885 },
    { name: 'Keeper of the Light', 'Lion': -0.055 },
    { name: 'Kunkka', 'Lion': 0.243 },
    { name: 'Legion Commander', 'Lion': -1.625 },
    { name: 'Leshrac', 'Lion': -0.785 },
    { name: 'Lich', 'Lion': -0.359 },
    { name: 'Lifestealer', 'Lion': 0.198 },
    { name: 'Lina', 'Lion': 0.148 },
    { name: 'Lone Druid', 'Lion': 0.916 },
    { name: 'Luna', 'Lion': 0.53 },
    { name: 'Lycan', 'Lion': 0.494 },
    { name: 'Magnus', 'Lion': -0.126 },
    { name: 'Marci', 'Lion': -0.816 },
    { name: 'Mars', 'Lion': 1.008 },
    { name: 'Medusa', 'Lion': -1.494 },
    { name: 'Meepo', 'Lion': 0.11 },
    { name: 'Mirana', 'Lion': 0.5 },
    { name: 'Monkey King', 'Lion': 0.835 },
    { name: 'Morphling', 'Lion': -1.014 },
    { name: 'Muerta', 'Lion': 0.189 },
    { name: 'Naga Siren', 'Lion': -0.473 },
    { name: "Nature's Prophet", 'Lion': 0.516 },
    { name: 'Necrophos', 'Lion': -0.561 },
    { name: 'Night Stalker', 'Lion': -0.206 },
    { name: 'Nyx Assassin', 'Lion': -1.621 },
    { name: 'Ogre Magi', 'Lion': -0.329 },
    { name: 'Omniknight', 'Lion': -0.031 },
    { name: 'Oracle', 'Lion': -0.156 },
    { name: 'Outworld Destroyer', 'Lion': 0.397 },
    { name: 'Pangolier', 'Lion': 0.492 },
    { name: 'Phantom Assassin', 'Lion': -0.064 },
    { name: 'Phantom Lancer', 'Lion': 1.144 },
    { name: 'Phoenix', 'Lion': -0.799 },
    { name: 'Primal Beast', 'Lion': -1.101 },
    { name: 'Puck', 'Lion': -0.936 },
    { name: 'Pudge', 'Lion': 0.279 },
    { name: 'Pugna', 'Lion': -0.192 },
    { name: 'Queen of Pain', 'Lion': -0.494 },
    { name: 'Razor', 'Lion': 1.767 },
    { name: 'Riki', 'Lion': -0.784 },
    { name: 'Ringmaster', 'Lion': -0.531 },
    { name: 'Rubick', 'Lion': 1.481 },
    { name: 'Sand King', 'Lion': -0.274 },
    { name: 'Shadow Demon', 'Lion': -0.082 },
    { name: 'Shadow Fiend', 'Lion': -0.225 },
    { name: 'Shadow Shaman', 'Lion': 0.037 },
    { name: 'Silencer', 'Lion': 0.465 },
    { name: 'Skywrath Mage', 'Lion': -0.584 },
    { name: 'Slardar', 'Lion': -0.207 },
    { name: 'Slark', 'Lion': -1.568 },
    { name: 'Snapfire', 'Lion': 0.854 },
    { name: 'Sniper', 'Lion': 0.355 },
    { name: 'Spectre', 'Lion': 0.812 },
    { name: 'Spirit Breaker', 'Lion': -0.086 },
    { name: 'Storm Spirit', 'Lion': -1.547 },
    { name: 'Sven', 'Lion': 0.284 },
    { name: 'Techies', 'Lion': -0.155 },
    { name: 'Templar Assassin', 'Lion': 1.408 },
    { name: 'Terrorblade', 'Lion': -0.099 },
    { name: 'Tidehunter', 'Lion': 0.725 },
    { name: 'Timbersaw', 'Lion': -1.262 },
    { name: 'Tinker', 'Lion': -1.639 },
    { name: 'Tiny', 'Lion': 0.829 },
    { name: 'Treant Protector', 'Lion': 0.011 },
    { name: 'Troll Warlord', 'Lion': -1.044 },
    { name: 'Tusk', 'Lion': 0.167 },
    { name: 'Underlord', 'Lion': -0.282 },
    { name: 'Undying', 'Lion': -0.657 },
    { name: 'Ursa', 'Lion': -0.502 },
    { name: 'Vengeful Spirit', 'Lion': 0.126 },
    { name: 'Venomancer', 'Lion': 0.962 },
    { name: 'Viper', 'Lion': 0.423 },
    { name: 'Visage', 'Lion': 1.285 },
    { name: 'Void Spirit', 'Lion': -0.674 },
    { name: 'Warlock', 'Lion': -0.204 },
    { name: 'Weaver', 'Lion': -1.125 },
    { name: 'Windranger', 'Lion': -0.401 },
    { name: 'Winter Wyvern', 'Lion': 0.846 },
    { name: 'Witch Doctor', 'Lion': -0.85 },
    { name: 'Wraith King', 'Lion': -0.176 },
    { name: 'Zeus', 'Lion': -0.236 },
],
"Muerta": [
    { name: 'Abaddon', 'Muerta': 0.285 },
    { name: 'Alchemist', 'Muerta': -1.315 },
    { name: 'Ancient Apparition', 'Muerta': 0.284 },
    { name: 'Anti-Mage', 'Muerta': 1.47 },
    { name: 'Arc Warden', 'Muerta': 0.667 },
    { name: 'Axe', 'Muerta': 2.076 },
    { name: 'Bane', 'Muerta': 1.006 },
    { name: 'Batrider', 'Muerta': 1.823 },
    { name: 'Beastmaster', 'Muerta': 0.86 },
    { name: 'Bloodseeker', 'Muerta': 0.011 },
    { name: 'Bounty Hunter', 'Muerta': 0.833 },
    { name: 'Brewmaster', 'Muerta': 1.342 },
    { name: 'Bristleback', 'Muerta': 1.336 },
    { name: 'Broodmother', 'Muerta': 1.525 },
    { name: 'Centaur Warrunner', 'Muerta': 3.229 },
    { name: 'Chaos Knight', 'Muerta': -0.083 },
    { name: 'Chen', 'Muerta': 1.611 },
    { name: 'Clinkz', 'Muerta': 0.602 },
    { name: 'Clockwerk', 'Muerta': 0.915 },
    { name: 'Crystal Maiden', 'Muerta': 0.238 },
    { name: 'Dark Seer', 'Muerta': 0.982 },
    { name: 'Dark Willow', 'Muerta': -0.424 },
    { name: 'Dawnbreaker', 'Muerta': -0.665 },
    { name: 'Dazzle', 'Muerta': -0.11 },
    { name: 'Death Prophet', 'Muerta': -1.562 },
    { name: 'Disruptor', 'Muerta': -0.364 },
    { name: 'Doom', 'Muerta': -0.579 },
    { name: 'Dragon Knight', 'Muerta': -1.073 },
    { name: 'Drow Ranger', 'Muerta': -1.94 },
    { name: 'Earth Spirit', 'Muerta': -0.309 },
    { name: 'Earthshaker', 'Muerta': -0.725 },
    { name: 'Elder Titan', 'Muerta': -2.142 },
    { name: 'Ember Spirit', 'Muerta': -1.706 },
    { name: 'Enchantress', 'Muerta': 2.111 },
    { name: 'Enigma', 'Muerta': -0.653 },
    { name: 'Faceless Void', 'Muerta': 0.464 },
    { name: 'Grimstroke', 'Muerta': -0.847 },
    { name: 'Gyrocopter', 'Muerta': -0.156 },
    { name: 'Hoodwink', 'Muerta': 0.212 },
    { name: 'Huskar', 'Muerta': -0.985 },
    { name: 'Invoker', 'Muerta': 1.063 },
    { name: 'Io', 'Muerta': -0.805 },
    { name: 'Jakiro', 'Muerta': 0.405 },
    { name: 'Juggernaut', 'Muerta': -2.258 },
    { name: 'Keeper of the Light', 'Muerta': 0.308 },
    { name: 'Kunkka', 'Muerta': 2.163 },
    { name: 'Legion Commander', 'Muerta': 2.278 },
    { name: 'Leshrac', 'Muerta': 0.072 },
    { name: 'Lich', 'Muerta': 0.791 },
    { name: 'Lifestealer', 'Muerta': -0.196 },
    { name: 'Lina', 'Muerta': 1.552 },
    { name: 'Lion', 'Muerta': 0.127 },
    { name: 'Lone Druid', 'Muerta': -0.901 },
    { name: 'Luna', 'Muerta': -0.281 },
    { name: 'Lycan', 'Muerta': 1.017 },
    { name: 'Magnus', 'Muerta': -0.483 },
    { name: 'Marci', 'Muerta': -1.873 },
    { name: 'Mars', 'Muerta': 0.027 },
    { name: 'Medusa', 'Muerta': 1.93 },
    { name: 'Meepo', 'Muerta': -2.914 },
    { name: 'Mirana', 'Muerta': -0.272 },
    { name: 'Monkey King', 'Muerta': -3.27 },
    { name: 'Morphling', 'Muerta': -0.759 },
    { name: 'Naga Siren', 'Muerta': -0.509 },
    { name: "Nature's Prophet", 'Muerta': 0.955 },
    { name: 'Necrophos', 'Muerta': -5.029 },
    { name: 'Night Stalker', 'Muerta': -0.051 },
    { name: 'Nyx Assassin', 'Muerta': 1.967 },
    { name: 'Ogre Magi', 'Muerta': 0.677 },
    { name: 'Omniknight', 'Muerta': -2.892 },
    { name: 'Oracle', 'Muerta': 0.677 },
    { name: 'Outworld Destroyer', 'Muerta': 0.112 },
    { name: 'Pangolier', 'Muerta': 0.19 },
    { name: 'Phantom Assassin', 'Muerta': 0.066 },
    { name: 'Phantom Lancer', 'Muerta': 1.105 },
    { name: 'Phoenix', 'Muerta': -1.944 },
    { name: 'Primal Beast', 'Muerta': 2.092 },
    { name: 'Puck', 'Muerta': -2.788 },
    { name: 'Pudge', 'Muerta': 2.162 },
    { name: 'Pugna', 'Muerta': -1.335 },
    { name: 'Queen of Pain', 'Muerta': -0.35 },
    { name: 'Razor', 'Muerta': -1.512 },
    { name: 'Riki', 'Muerta': -1.521 },
    { name: 'Ringmaster', 'Muerta': 0.67 },
    { name: 'Rubick', 'Muerta': 1.197 },
    { name: 'Sand King', 'Muerta': -1.924 },
    { name: 'Shadow Demon', 'Muerta': 2.755 },
    { name: 'Shadow Fiend', 'Muerta': 0.186 },
    { name: 'Shadow Shaman', 'Muerta': -0.28 },
    { name: 'Silencer', 'Muerta': -0.742 },
    { name: 'Skywrath Mage', 'Muerta': 1.996 },
    { name: 'Slardar', 'Muerta': -3.123 },
    { name: 'Slark', 'Muerta': -2.287 },
    { name: 'Snapfire', 'Muerta': 0.591 },
    { name: 'Sniper', 'Muerta': 0.382 },
    { name: 'Spectre', 'Muerta': 3.6 },
    { name: 'Spirit Breaker', 'Muerta': 0.073 },
    { name: 'Storm Spirit', 'Muerta': -1.198 },
    { name: 'Sven', 'Muerta': -0.742 },
    { name: 'Techies', 'Muerta': -0.232 },
    { name: 'Templar Assassin', 'Muerta': 0.744 },
    { name: 'Terrorblade', 'Muerta': -1.733 },
    { name: 'Tidehunter', 'Muerta': -1.812 },
    { name: 'Timbersaw', 'Muerta': -0.657 },
    { name: 'Tinker', 'Muerta': -0.211 },
    { name: 'Tiny', 'Muerta': 0.377 },
    { name: 'Treant Protector', 'Muerta': -0.386 },
    { name: 'Troll Warlord', 'Muerta': -2.154 },
    { name: 'Tusk', 'Muerta': -1.29 },
    { name: 'Underlord', 'Muerta': -0.357 },
    { name: 'Undying', 'Muerta': -0.534 },
    { name: 'Ursa', 'Muerta': -0.16 },
    { name: 'Vengeful Spirit', 'Muerta': -1.046 },
    { name: 'Venomancer', 'Muerta': -0.889 },
    { name: 'Viper', 'Muerta': 0.524 },
    { name: 'Visage', 'Muerta': 0.151 },
    { name: 'Void Spirit', 'Muerta': -0.929 },
    { name: 'Warlock', 'Muerta': 0.063 },
    { name: 'Weaver', 'Muerta': -2.047 },
    { name: 'Windranger', 'Muerta': -0.261 },
    { name: 'Winter Wyvern', 'Muerta': -0.865 },
    { name: 'Witch Doctor', 'Muerta': -1.046 },
    { name: 'Wraith King', 'Muerta': 2.126 },
    { name: 'Zeus', 'Muerta': 0.873 },
],
"Nature's Prophet": [
    { name: 'Abaddon', "Nature's Prophet": -1.01 },
    { name: 'Alchemist', "Nature's Prophet": -0.429 },
    { name: 'Ancient Apparition', "Nature's Prophet": -2.021 },
    { name: 'Anti-Mage', "Nature's Prophet": 1.313 },
    { name: 'Arc Warden', "Nature's Prophet": 1.562 },
    { name: 'Axe', "Nature's Prophet": 0.031 },
    { name: 'Bane', "Nature's Prophet": 0.122 },
    { name: 'Batrider', "Nature's Prophet": 0.753 },
    { name: 'Beastmaster', "Nature's Prophet": 1.089 },
    { name: 'Bloodseeker', "Nature's Prophet": -2.479 },
    { name: 'Bounty Hunter', "Nature's Prophet": -0.028 },
    { name: 'Brewmaster', "Nature's Prophet": -0.943 },
    { name: 'Bristleback', "Nature's Prophet": -0.711 },
    { name: 'Broodmother', "Nature's Prophet": 1.348 },
    { name: 'Centaur Warrunner', "Nature's Prophet": -0.753 },
    { name: 'Chaos Knight', "Nature's Prophet": 1.19 },
    { name: 'Chen', "Nature's Prophet": -0.991 },
    { name: 'Clinkz', "Nature's Prophet": -0.112 },
    { name: 'Clockwerk', "Nature's Prophet": 0.323 },
    { name: 'Crystal Maiden', "Nature's Prophet": 1.16 },
    { name: 'Dark Seer', "Nature's Prophet": -1.464 },
    { name: 'Dark Willow', "Nature's Prophet": -0.842 },
    { name: 'Dawnbreaker', "Nature's Prophet": 1.674 },
    { name: 'Dazzle', "Nature's Prophet": 0.35 },
    { name: 'Death Prophet', "Nature's Prophet": -1.66 },
    { name: 'Disruptor', "Nature's Prophet": -1.983 },
    { name: 'Doom', "Nature's Prophet": -1.068 },
    { name: 'Dragon Knight', "Nature's Prophet": 0.95 },
    { name: 'Drow Ranger', "Nature's Prophet": -1.194 },
    { name: 'Earth Spirit', "Nature's Prophet": 0.633 },
    { name: 'Earthshaker', "Nature's Prophet": 0.073 },
    { name: 'Elder Titan', "Nature's Prophet": -3.118 },
    { name: 'Ember Spirit', "Nature's Prophet": -1.335 },
    { name: 'Enchantress', "Nature's Prophet": 0.363 },
    { name: 'Enigma', "Nature's Prophet": -1.311 },
    { name: 'Faceless Void', "Nature's Prophet": -0.913 },
    { name: 'Grimstroke', "Nature's Prophet": -1.394 },
    { name: 'Gyrocopter', "Nature's Prophet": -0.207 },
    { name: 'Hoodwink', "Nature's Prophet": 0.546 },
    { name: 'Huskar', "Nature's Prophet": -0.015 },
    { name: 'Invoker', "Nature's Prophet": -0.416 },
    { name: 'Io', "Nature's Prophet": 1.002 },
    { name: 'Jakiro', "Nature's Prophet": -0.633 },
    { name: 'Juggernaut', "Nature's Prophet": -2.135 },
    { name: 'Keeper of the Light', "Nature's Prophet": -0.798 },
    { name: 'Kunkka', "Nature's Prophet": -0.737 },
    { name: 'Legion Commander', "Nature's Prophet": 0.412 },
    { name: 'Leshrac', "Nature's Prophet": -0.137 },
    { name: 'Lich', "Nature's Prophet": -0.793 },
    { name: 'Lifestealer', "Nature's Prophet": -2.935 },
    { name: 'Lina', "Nature's Prophet": 0.17 },
    { name: 'Lion', "Nature's Prophet": -0.348 },
    { name: 'Lone Druid', "Nature's Prophet": 1.017 },
    { name: 'Luna', "Nature's Prophet": 0.529 },
    { name: 'Lycan', "Nature's Prophet": 0.356 },
    { name: 'Magnus', "Nature's Prophet": 0.275 },
    { name: 'Marci', "Nature's Prophet": -0.119 },
    { name: 'Mars', "Nature's Prophet": 0.526 },
    { name: 'Medusa', "Nature's Prophet": 0.172 },
    { name: 'Meepo', "Nature's Prophet": 1.82 },
    { name: 'Mirana', "Nature's Prophet": 0.83 },
    { name: 'Monkey King', "Nature's Prophet": 0.813 },
    { name: 'Morphling', "Nature's Prophet": 1.793 },
    { name: 'Muerta', "Nature's Prophet": -0.89 },
    { name: 'Naga Siren', "Nature's Prophet": 0.18 },
    { name: 'Necrophos', "Nature's Prophet": -0.747 },
    { name: 'Night Stalker', "Nature's Prophet": 0.688 },
    { name: 'Nyx Assassin', "Nature's Prophet": 0.849 },
    { name: 'Ogre Magi', "Nature's Prophet": -0.544 },
    { name: 'Omniknight', "Nature's Prophet": -1.321 },
    { name: 'Oracle', "Nature's Prophet": -1.306 },
    { name: 'Outworld Destroyer', "Nature's Prophet": -1.038 },
    { name: 'Pangolier', "Nature's Prophet": -0.255 },
    { name: 'Phantom Assassin', "Nature's Prophet": 2.109 },
    { name: 'Phantom Lancer', "Nature's Prophet": -0.98 },
    { name: 'Phoenix', "Nature's Prophet": -0.827 },
    { name: 'Primal Beast', "Nature's Prophet": 2.073 },
    { name: 'Puck', "Nature's Prophet": -1.257 },
    { name: 'Pudge', "Nature's Prophet": 0.846 },
    { name: 'Pugna', "Nature's Prophet": -0.314 },
    { name: 'Queen of Pain', "Nature's Prophet": -0.623 },
    { name: 'Razor', "Nature's Prophet": -1.202 },
    { name: 'Riki', "Nature's Prophet": -0.926 },
    { name: 'Ringmaster', "Nature's Prophet": -0.877 },
    { name: 'Rubick', "Nature's Prophet": -0.668 },
    { name: 'Sand King', "Nature's Prophet": -0.136 },
    { name: 'Shadow Demon', "Nature's Prophet": -1.326 },
    { name: 'Shadow Fiend', "Nature's Prophet": 0.235 },
    { name: 'Shadow Shaman', "Nature's Prophet": 0.014 },
    { name: 'Silencer', "Nature's Prophet": -1.105 },
    { name: 'Skywrath Mage', "Nature's Prophet": -0.149 },
    { name: 'Slardar', "Nature's Prophet": 0.488 },
    { name: 'Slark', "Nature's Prophet": 0.243 },
    { name: 'Snapfire', "Nature's Prophet": 0.788 },
    { name: 'Sniper', "Nature's Prophet": -1.031 },
    { name: 'Spectre', "Nature's Prophet": 3.318 },
    { name: 'Spirit Breaker', "Nature's Prophet": 0.73 },
    { name: 'Storm Spirit', "Nature's Prophet": 0.132 },
    { name: 'Sven', "Nature's Prophet": -0.626 },
    { name: 'Techies', "Nature's Prophet": 0.28 },
    { name: 'Templar Assassin', "Nature's Prophet": 1.055 },
    { name: 'Terrorblade', "Nature's Prophet": 0.896 },
    { name: 'Tidehunter', "Nature's Prophet": -0.961 },
    { name: 'Timbersaw', "Nature's Prophet": 0.157 },
    { name: 'Tinker', "Nature's Prophet": -0.214 },
    { name: 'Tiny', "Nature's Prophet": 0.62 },
    { name: 'Treant Protector', "Nature's Prophet": 1.677 },
    { name: 'Troll Warlord', "Nature's Prophet": -0.213 },
    { name: 'Tusk', "Nature's Prophet": 0.04 },
    { name: 'Underlord', "Nature's Prophet": 0.391 },
    { name: 'Undying', "Nature's Prophet": -0.62 },
    { name: 'Ursa', "Nature's Prophet": -0.131 },
    { name: 'Vengeful Spirit', "Nature's Prophet": 0.221 },
    { name: 'Venomancer', "Nature's Prophet": -1.38 },
    { name: 'Viper', "Nature's Prophet": -0.096 },
    { name: 'Visage', "Nature's Prophet": -0.035 },
    { name: 'Void Spirit', "Nature's Prophet": -0.091 },
    { name: 'Warlock', "Nature's Prophet": -1.443 },
    { name: 'Weaver', "Nature's Prophet": -1.12 },
    { name: 'Windranger', "Nature's Prophet": -1.647 },
    { name: 'Winter Wyvern', "Nature's Prophet": -0.07 },
    { name: 'Witch Doctor', "Nature's Prophet": -0.708 },
    { name: 'Wraith King', "Nature's Prophet": 0.612 },
    { name: 'Zeus', "Nature's Prophet": 0.454 },
],
"Necrophos": [
    { name: 'Abaddon', 'Necrophos': -0.326 },
    { name: 'Alchemist', 'Necrophos': -0.135 },
    { name: 'Ancient Apparition', 'Necrophos': 4.878 },
    { name: 'Anti-Mage', 'Necrophos': 1.879 },
    { name: 'Arc Warden', 'Necrophos': 2.621 },
    { name: 'Axe', 'Necrophos': -3.117 },
    { name: 'Bane', 'Necrophos': -1.436 },
    { name: 'Batrider', 'Necrophos': 0.893 },
    { name: 'Beastmaster', 'Necrophos': 2.179 },
    { name: 'Bloodseeker', 'Necrophos': -0.297 },
    { name: 'Bounty Hunter', 'Necrophos': -0.19 },
    { name: 'Brewmaster', 'Necrophos': 0.149 },
    { name: 'Bristleback', 'Necrophos': -1.717 },
    { name: 'Broodmother', 'Necrophos': -1.142 },
    { name: 'Centaur Warrunner', 'Necrophos': -1.732 },
    { name: 'Chaos Knight', 'Necrophos': -0.958 },
    { name: 'Chen', 'Necrophos': -0.28 },
    { name: 'Clinkz', 'Necrophos': 1.526 },
    { name: 'Clockwerk', 'Necrophos': -0.589 },
    { name: 'Crystal Maiden', 'Necrophos': 1.55 },
    { name: 'Dark Seer', 'Necrophos': 2.003 },
    { name: 'Dark Willow', 'Necrophos': 0.317 },
    { name: 'Dawnbreaker', 'Necrophos': 0.898 },
    { name: 'Dazzle', 'Necrophos': 1.525 },
    { name: 'Death Prophet', 'Necrophos': 3.862 },
    { name: 'Disruptor', 'Necrophos': 1.41 },
    { name: 'Doom', 'Necrophos': 4.304 },
    { name: 'Dragon Knight', 'Necrophos': -1.369 },
    { name: 'Drow Ranger', 'Necrophos': 2.508 },
    { name: 'Earth Spirit', 'Necrophos': -0.647 },
    { name: 'Earthshaker', 'Necrophos': -1.348 },
    { name: 'Elder Titan', 'Necrophos': 0.57 },
    { name: 'Ember Spirit', 'Necrophos': -0.126 },
    { name: 'Enchantress', 'Necrophos': 0.467 },
    { name: 'Enigma', 'Necrophos': -1.05 },
    { name: 'Faceless Void', 'Necrophos': 0.31 },
    { name: 'Grimstroke', 'Necrophos': 2.719 },
    { name: 'Gyrocopter', 'Necrophos': 0.81 },
    { name: 'Hoodwink', 'Necrophos': 0.81 },
    { name: 'Huskar', 'Necrophos': -4.361 },
    { name: 'Invoker', 'Necrophos': -0.399 },
    { name: 'Io', 'Necrophos': 0.64 },
    { name: 'Jakiro', 'Necrophos': 0.252 },
    { name: 'Juggernaut', 'Necrophos': -0.488 },
    { name: 'Keeper of the Light', 'Necrophos': 2.349 },
    { name: 'Kunkka', 'Necrophos': -1.348 },
    { name: 'Legion Commander', 'Necrophos': 0.741 },
    { name: 'Leshrac', 'Necrophos': 4.338 },
    { name: 'Lich', 'Necrophos': 1.133 },
    { name: 'Lifestealer', 'Necrophos': -0.613 },
    { name: 'Lina', 'Necrophos': 3.364 },
    { name: 'Lion', 'Necrophos': 0.205 },
    { name: 'Lone Druid', 'Necrophos': -0.891 },
    { name: 'Luna', 'Necrophos': 0.181 },
    { name: 'Lycan', 'Necrophos': -1.794 },
    { name: 'Magnus', 'Necrophos': -0.672 },
    { name: 'Marci', 'Necrophos': 1.07 },
    { name: 'Mars', 'Necrophos': -0.415 },
    { name: 'Medusa', 'Necrophos': 1.631 },
    { name: 'Meepo', 'Necrophos': -0.762 },
    { name: 'Mirana', 'Necrophos': 0.11 },
    { name: 'Monkey King', 'Necrophos': -0.137 },
    { name: 'Morphling', 'Necrophos': 0.171 },
    { name: 'Muerta', 'Necrophos': 5.008 },
    { name: 'Naga Siren', 'Necrophos': -2.882 },
    { name: "Nature's Prophet", 'Necrophos': 0.758 },
    { name: 'Night Stalker', 'Necrophos': 0.033 },
    { name: 'Nyx Assassin', 'Necrophos': 0.562 },
    { name: 'Ogre Magi', 'Necrophos': -0.712 },
    { name: 'Omniknight', 'Necrophos': -0.692 },
    { name: 'Oracle', 'Necrophos': 3.255 },
    { name: 'Outworld Destroyer', 'Necrophos': 1.427 },
    { name: 'Pangolier', 'Necrophos': 0.565 },
    { name: 'Phantom Assassin', 'Necrophos': 0.016 },
    { name: 'Phantom Lancer', 'Necrophos': -3.398 },
    { name: 'Phoenix', 'Necrophos': 0.042 },
    { name: 'Primal Beast', 'Necrophos': -0.04 },
    { name: 'Puck', 'Necrophos': 0.576 },
    { name: 'Pudge', 'Necrophos': 1.969 },
    { name: 'Pugna', 'Necrophos': 3.164 },
    { name: 'Queen of Pain', 'Necrophos': 0.772 },
    { name: 'Razor', 'Necrophos': -0.449 },
    { name: 'Riki', 'Necrophos': -1.965 },
    { name: 'Ringmaster', 'Necrophos': -0.485 },
    { name: 'Rubick', 'Necrophos': -0.242 },
    { name: 'Sand King', 'Necrophos': 1.044 },
    { name: 'Shadow Demon', 'Necrophos': 2.183 },
    { name: 'Shadow Fiend', 'Necrophos': 2.183 },
    { name: 'Shadow Shaman', 'Necrophos': -1.951 },
    { name: 'Silencer', 'Necrophos': 2.377 },
    { name: 'Skywrath Mage', 'Necrophos': 4.081 },
    { name: 'Slardar', 'Necrophos': -0.211 },
    { name: 'Slark', 'Necrophos': -2.404 },
    { name: 'Snapfire', 'Necrophos': 1.082 },
    { name: 'Sniper', 'Necrophos': 1.974 },
    { name: 'Spectre', 'Necrophos': -3.737 },
    { name: 'Spirit Breaker', 'Necrophos': -2.774 },
    { name: 'Storm Spirit', 'Necrophos': -0.022 },
    { name: 'Sven', 'Necrophos': -0.494 },
    { name: 'Techies', 'Necrophos': -0.578 },
    { name: 'Templar Assassin', 'Necrophos': -0.888 },
    { name: 'Terrorblade', 'Necrophos': 0.042 },
    { name: 'Tidehunter', 'Necrophos': -2.092 },
    { name: 'Timbersaw', 'Necrophos': 0.847 },
    { name: 'Tinker', 'Necrophos': -0.198 },
    { name: 'Tiny', 'Necrophos': -3.103 },
    { name: 'Treant Protector', 'Necrophos': -1.272 },
    { name: 'Troll Warlord', 'Necrophos': -1.485 },
    { name: 'Tusk', 'Necrophos': -0.595 },
    { name: 'Underlord', 'Necrophos': 0.995 },
    { name: 'Undying', 'Necrophos': -0.483 },
    { name: 'Ursa', 'Necrophos': -1.258 },
    { name: 'Vengeful Spirit', 'Necrophos': -1.269 },
    { name: 'Venomancer', 'Necrophos': 1.069 },
    { name: 'Viper', 'Necrophos': 2.749 },
    { name: 'Visage', 'Necrophos': 1.809 },
    { name: 'Void Spirit', 'Necrophos': -1.571 },
    { name: 'Warlock', 'Necrophos': 1.243 },
    { name: 'Weaver', 'Necrophos': -0.747 },
    { name: 'Windranger', 'Necrophos': -0.808 },
    { name: 'Winter Wyvern', 'Necrophos': -2.148 },
    { name: 'Witch Doctor', 'Necrophos': -0.01 },
    { name: 'Wraith King', 'Necrophos': -3.08 },
    { name: 'Zeus', 'Necrophos': -0.814 },
],
"Oracle": [
    { name: 'Abaddon', 'Oracle': -0.848 },
    { name: 'Alchemist', 'Oracle': 1.67 },
    { name: 'Ancient Apparition', 'Oracle': -0.433 },
    { name: 'Anti-Mage', 'Oracle': 2.699 },
    { name: 'Arc Warden', 'Oracle': -0.677 },
    { name: 'Axe', 'Oracle': 0.528 },
    { name: 'Bane', 'Oracle': 0.056 },
    { name: 'Batrider', 'Oracle': -0.207 },
    { name: 'Beastmaster', 'Oracle': 0.381 },
    { name: 'Bloodseeker', 'Oracle': -1.471 },
    { name: 'Bounty Hunter', 'Oracle': -1.254 },
    { name: 'Brewmaster', 'Oracle': -0.248 },
    { name: 'Bristleback', 'Oracle': 1.199 },
    { name: 'Broodmother', 'Oracle': 2.133 },
    { name: 'Centaur Warrunner', 'Oracle': -2.281 },
    { name: 'Chaos Knight', 'Oracle': 2.195 },
    { name: 'Chen', 'Oracle': 1.309 },
    { name: 'Clinkz', 'Oracle': -0.106 },
    { name: 'Clockwerk', 'Oracle': -1.065 },
    { name: 'Crystal Maiden', 'Oracle': 1.052 },
    { name: 'Dark Seer', 'Oracle': -2.452 },
    { name: 'Dark Willow', 'Oracle': -0.454 },
    { name: 'Dawnbreaker', 'Oracle': 1.188 },
    { name: 'Dazzle', 'Oracle': 1.307 },
    { name: 'Death Prophet', 'Oracle': 2.598 },
    { name: 'Disruptor', 'Oracle': -1.06 },
    { name: 'Doom', 'Oracle': 1.039 },
    { name: 'Dragon Knight', 'Oracle': 0.731 },
    { name: 'Drow Ranger', 'Oracle': 1.119 },
    { name: 'Earth Spirit', 'Oracle': -0.338 },
    { name: 'Earthshaker', 'Oracle': 1.49 },
    { name: 'Elder Titan', 'Oracle': -0.913 },
    { name: 'Ember Spirit', 'Oracle': -2.776 },
    { name: 'Enchantress', 'Oracle': 1.546 },
    { name: 'Enigma', 'Oracle': 2.5 },
    { name: 'Faceless Void', 'Oracle': -0.781 },
    { name: 'Grimstroke', 'Oracle': 0.316 },
    { name: 'Gyrocopter', 'Oracle': -1.198 },
    { name: 'Hoodwink', 'Oracle': -0.344 },
    { name: 'Huskar', 'Oracle': 1.955 },
    { name: 'Invoker', 'Oracle': 0.988 },
    { name: 'Io', 'Oracle': 2.721 },
    { name: 'Jakiro', 'Oracle': -0.753 },
    { name: 'Juggernaut', 'Oracle': -0.751 },
    { name: 'Keeper of the Light', 'Oracle': 0.212 },
    { name: 'Kunkka', 'Oracle': 0.033 },
    { name: 'Legion Commander', 'Oracle': -2.532 },
    { name: 'Leshrac', 'Oracle': -0.789 },
    { name: 'Lich', 'Oracle': -1.643 },
    { name: 'Lifestealer', 'Oracle': -0.377 },
    { name: 'Lina', 'Oracle': 0.046 },
    { name: 'Lion', 'Oracle': -0.729 },
    { name: 'Lone Druid', 'Oracle': 3.438 },
    { name: 'Luna', 'Oracle': -0.405 },
    { name: 'Lycan', 'Oracle': 3.17 },
    { name: 'Magnus', 'Oracle': 1.062 },
    { name: 'Marci', 'Oracle': 3.731 },
    { name: 'Mars', 'Oracle': 0.072 },
    { name: 'Medusa', 'Oracle': -0.269 },
    { name: 'Meepo', 'Oracle': 2.11 },
    { name: 'Mirana', 'Oracle': -0.239 },
    { name: 'Monkey King', 'Oracle': 0.779 },
    { name: 'Morphling', 'Oracle': 0.237 },
    { name: 'Muerta', 'Oracle': -0.594 },
    { name: 'Naga Siren', 'Oracle': 0.602 },
    { name: "Nature's Prophet", 'Oracle': 1.056 },
    { name: 'Necrophos', 'Oracle': -3.47 },
    { name: 'Night Stalker', 'Oracle': 1.29 },
    { name: 'Nyx Assassin', 'Oracle': 0.003 },
    { name: 'Ogre Magi', 'Oracle': -1.397 },
    { name: 'Omniknight', 'Oracle': 1.224 },
    { name: 'Outworld Destroyer', 'Oracle': 3.214 },
    { name: 'Pangolier', 'Oracle': 2.019 },
    { name: 'Phantom Assassin', 'Oracle': 0.682 },
    { name: 'Phantom Lancer', 'Oracle': 1.766 },
    { name: 'Phoenix', 'Oracle': -1.883 },
    { name: 'Primal Beast', 'Oracle': -0.207 },
    { name: 'Puck', 'Oracle': -0.474 },
    { name: 'Pudge', 'Oracle': 0.825 },
    { name: 'Pugna', 'Oracle': 1.406 },
    { name: 'Queen of Pain', 'Oracle': -1.957 },
    { name: 'Razor', 'Oracle': 2.947 },
    { name: 'Riki', 'Oracle': 2.324 },
    { name: 'Ringmaster', 'Oracle': -0.34 },
    { name: 'Rubick', 'Oracle': -1.014 },
    { name: 'Sand King', 'Oracle': -0.847 },
    { name: 'Shadow Demon', 'Oracle': 3.927 },
    { name: 'Shadow Fiend', 'Oracle': 1.969 },
    { name: 'Shadow Shaman', 'Oracle': 1.317 },
    { name: 'Silencer', 'Oracle': 0.359 },
    { name: 'Skywrath Mage', 'Oracle': -1.689 },
    { name: 'Slardar', 'Oracle': 1.248 },
    { name: 'Slark', 'Oracle': 0.026 },
    { name: 'Snapfire', 'Oracle': -0.086 },
    { name: 'Sniper', 'Oracle': -0.009 },
    { name: 'Spectre', 'Oracle': -2.415 },
    { name: 'Spirit Breaker', 'Oracle': -1.241 },
    { name: 'Storm Spirit', 'Oracle': -2.313 },
    { name: 'Sven', 'Oracle': 0.573 },
    { name: 'Techies', 'Oracle': -0.476 },
    { name: 'Templar Assassin', 'Oracle': 2.957 },
    { name: 'Terrorblade', 'Oracle': 2.86 },
    { name: 'Tidehunter', 'Oracle': -0.004 },
    { name: 'Timbersaw', 'Oracle': -0.211 },
    { name: 'Tinker', 'Oracle': 0.767 },
    { name: 'Tiny', 'Oracle': 0.67 },
    { name: 'Treant Protector', 'Oracle': -0.652 },
    { name: 'Troll Warlord', 'Oracle': -0.742 },
    { name: 'Tusk', 'Oracle': 2.174 },
    { name: 'Underlord', 'Oracle': -1.217 },
    { name: 'Undying', 'Oracle': 2.603 },
    { name: 'Ursa', 'Oracle': 0.819 },
    { name: 'Vengeful Spirit', 'Oracle': 1.442 },
    { name: 'Venomancer', 'Oracle': -0.636 },
    { name: 'Viper', 'Oracle': 0.513 },
    { name: 'Visage', 'Oracle': 1.14 },
    { name: 'Void Spirit', 'Oracle': -0.668 },
    { name: 'Warlock', 'Oracle': -0.61 },
    { name: 'Weaver', 'Oracle': 0.916 },
    { name: 'Windranger', 'Oracle': -0.712 },
    { name: 'Winter Wyvern', 'Oracle': 0.695 },
    { name: 'Witch Doctor', 'Oracle': -0.632 },
    { name: 'Wraith King', 'Oracle': -0.679 },
    { name: 'Zeus', 'Oracle': -1.802 },
],
"Outworld Destroyer": [
    { name: 'Abaddon', 'Outworld Destroyer': -2.949 },
    { name: 'Alchemist', 'Outworld Destroyer': -0.299 },
    { name: 'Ancient Apparition', 'Outworld Destroyer': -1.65 },
    { name: 'Anti-Mage', 'Outworld Destroyer': 1.332 },
    { name: 'Arc Warden', 'Outworld Destroyer': 2.928 },
    { name: 'Axe', 'Outworld Destroyer': -3.58 },
    { name: 'Bane', 'Outworld Destroyer': -0.01 },
    { name: 'Batrider', 'Outworld Destroyer': 1.27 },
    { name: 'Beastmaster', 'Outworld Destroyer': 1.761 },
    { name: 'Bloodseeker', 'Outworld Destroyer': -0.02 },
    { name: 'Bounty Hunter', 'Outworld Destroyer': 0.16 },
    { name: 'Brewmaster', 'Outworld Destroyer': 1.051 },
    { name: 'Bristleback', 'Outworld Destroyer': 1.083 },
    { name: 'Broodmother', 'Outworld Destroyer': 4.2 },
    { name: 'Centaur Warrunner', 'Outworld Destroyer': -0.432 },
    { name: 'Chaos Knight', 'Outworld Destroyer': 2.745 },
    { name: 'Chen', 'Outworld Destroyer': 0.953 },
    { name: 'Clinkz', 'Outworld Destroyer': 1.122 },
    { name: 'Clockwerk', 'Outworld Destroyer': -1.133 },
    { name: 'Crystal Maiden', 'Outworld Destroyer': 1.004 },
    { name: 'Dark Seer', 'Outworld Destroyer': -1.029 },
    { name: 'Dark Willow', 'Outworld Destroyer': 0.774 },
    { name: 'Dawnbreaker', 'Outworld Destroyer': -1.268 },
    { name: 'Dazzle', 'Outworld Destroyer': -0.52 },
    { name: 'Death Prophet', 'Outworld Destroyer': 0.519 },
    { name: 'Disruptor', 'Outworld Destroyer': 0.391 },
    { name: 'Doom', 'Outworld Destroyer': -0.223 },
    { name: 'Dragon Knight', 'Outworld Destroyer': -1.068 },
    { name: 'Drow Ranger', 'Outworld Destroyer': -0.563 },
    { name: 'Earth Spirit', 'Outworld Destroyer': 0.743 },
    { name: 'Earthshaker', 'Outworld Destroyer': 0.488 },
    { name: 'Elder Titan', 'Outworld Destroyer': 0.815 },
    { name: 'Ember Spirit', 'Outworld Destroyer': 0.219 },
    { name: 'Enchantress', 'Outworld Destroyer': 1.245 },
    { name: 'Enigma', 'Outworld Destroyer': 1.97 },
    { name: 'Faceless Void', 'Outworld Destroyer': -1.29 },
    { name: 'Grimstroke', 'Outworld Destroyer': 0.567 },
    { name: 'Gyrocopter', 'Outworld Destroyer': -1.17 },
    { name: 'Hoodwink', 'Outworld Destroyer': 0.156 },
    { name: 'Huskar', 'Outworld Destroyer': 1.267 },
    { name: 'Invoker', 'Outworld Destroyer': 0.128 },
    { name: 'Io', 'Outworld Destroyer': 0.345 },
    { name: 'Jakiro', 'Outworld Destroyer': -0.12 },
    { name: 'Juggernaut', 'Outworld Destroyer': -0.49 },
    { name: 'Keeper of the Light', 'Outworld Destroyer': 0.858 },
    { name: 'Kunkka', 'Outworld Destroyer': 0.601 },
    { name: 'Legion Commander', 'Outworld Destroyer': -1.147 },
    { name: 'Leshrac', 'Outworld Destroyer': -2.02 },
    { name: 'Lich', 'Outworld Destroyer': -1.291 },
    { name: 'Lifestealer', 'Outworld Destroyer': 0.119 },
    { name: 'Lina', 'Outworld Destroyer': -0.398 },
    { name: 'Lion', 'Outworld Destroyer': -0.963 },
    { name: 'Lone Druid', 'Outworld Destroyer': 1.755 },
    { name: 'Luna', 'Outworld Destroyer': 0.631 },
    { name: 'Lycan', 'Outworld Destroyer': 2.06 },
    { name: 'Magnus', 'Outworld Destroyer': 0.401 },
    { name: 'Marci', 'Outworld Destroyer': 1.764 },
    { name: 'Mars', 'Outworld Destroyer': 0.287 },
    { name: 'Medusa', 'Outworld Destroyer': 2.602 },
    { name: 'Meepo', 'Outworld Destroyer': 0.063 },
    { name: 'Mirana', 'Outworld Destroyer': -0.878 },
    { name: 'Monkey King', 'Outworld Destroyer': -1.028 },
    { name: 'Morphling', 'Outworld Destroyer': -0.901 },
    { name: 'Muerta', 'Outworld Destroyer': 0.031 },
    { name: 'Naga Siren', 'Outworld Destroyer': 1.641 },
    { name: "Nature's Prophet", 'Outworld Destroyer': 0.918 },
    { name: 'Necrophos', 'Outworld Destroyer': -1.477 },
    { name: 'Night Stalker', 'Outworld Destroyer': 0.885 },
    { name: 'Nyx Assassin', 'Outworld Destroyer': 2.592 },
    { name: 'Ogre Magi', 'Outworld Destroyer': 0.743 },
    { name: 'Omniknight', 'Outworld Destroyer': -1.581 },
    { name: 'Oracle', 'Outworld Destroyer': -3.181 },
    { name: 'Pangolier', 'Outworld Destroyer': 1.99 },
    { name: 'Phantom Assassin', 'Outworld Destroyer': 1.43 },
    { name: 'Phantom Lancer', 'Outworld Destroyer': 3.516 },
    { name: 'Phoenix', 'Outworld Destroyer': 0.549 },
    { name: 'Primal Beast', 'Outworld Destroyer': -1.539 },
    { name: 'Puck', 'Outworld Destroyer': 1.356 },
    { name: 'Pudge', 'Outworld Destroyer': -0.355 },
    { name: 'Pugna', 'Outworld Destroyer': 0.45 },
    { name: 'Queen of Pain', 'Outworld Destroyer': 0.363 },
    { name: 'Razor', 'Outworld Destroyer': -0.146 },
    { name: 'Riki', 'Outworld Destroyer': 1.148 },
    { name: 'Ringmaster', 'Outworld Destroyer': 0.692 },
    { name: 'Rubick', 'Outworld Destroyer': 2.312 },
    { name: 'Sand King', 'Outworld Destroyer': 0.987 },
    { name: 'Shadow Demon', 'Outworld Destroyer': 0.645 },
    { name: 'Shadow Fiend', 'Outworld Destroyer': -0.633 },
    { name: 'Shadow Shaman', 'Outworld Destroyer': 1.657 },
    { name: 'Silencer', 'Outworld Destroyer': 1.078 },
    { name: 'Skywrath Mage', 'Outworld Destroyer': 0.743 },
    { name: 'Slardar', 'Outworld Destroyer': 0.144 },
    { name: 'Slark', 'Outworld Destroyer': -2.074 },
    { name: 'Snapfire', 'Outworld Destroyer': -0.987 },
    { name: 'Sniper', 'Outworld Destroyer': 0.245 },
    { name: 'Spectre', 'Outworld Destroyer': -0.195 },
    { name: 'Spirit Breaker', 'Outworld Destroyer': 0.386 },
    { name: 'Storm Spirit', 'Outworld Destroyer': 1.331 },
    { name: 'Sven', 'Outworld Destroyer': -0.108 },
    { name: 'Techies', 'Outworld Destroyer': -0.47 },
    { name: 'Templar Assassin', 'Outworld Destroyer': 3.308 },
    { name: 'Terrorblade', 'Outworld Destroyer': 0.573 },
    { name: 'Tidehunter', 'Outworld Destroyer': -1.966 },
    { name: 'Timbersaw', 'Outworld Destroyer': -3.745 },
    { name: 'Tinker', 'Outworld Destroyer': -0.755 },
    { name: 'Tiny', 'Outworld Destroyer': -0.349 },
    { name: 'Treant Protector', 'Outworld Destroyer': 0.27 },
    { name: 'Troll Warlord', 'Outworld Destroyer': -3.513 },
    { name: 'Tusk', 'Outworld Destroyer': 1.018 },
    { name: 'Underlord', 'Outworld Destroyer': -2.311 },
    { name: 'Undying', 'Outworld Destroyer': 0.783 },
    { name: 'Ursa', 'Outworld Destroyer': -0.096 },
    { name: 'Vengeful Spirit', 'Outworld Destroyer': -0.027 },
    { name: 'Venomancer', 'Outworld Destroyer': 0.319 },
    { name: 'Viper', 'Outworld Destroyer': 0.131 },
    { name: 'Visage', 'Outworld Destroyer': 2.027 },
    { name: 'Void Spirit', 'Outworld Destroyer': -0.17 },
    { name: 'Warlock', 'Outworld Destroyer': -0.048 },
    { name: 'Weaver', 'Outworld Destroyer': 0.113 },
    { name: 'Windranger', 'Outworld Destroyer': 0.215 },
    { name: 'Winter Wyvern', 'Outworld Destroyer': -0.959 },
    { name: 'Witch Doctor', 'Outworld Destroyer': 0.238 },
    { name: 'Wraith King', 'Outworld Destroyer': 1.443 },
    { name: 'Zeus', 'Outworld Destroyer': 0.529 },
],
"Puck": [
    { name: 'Abaddon', 'Puck': 1.311 },
    { name: 'Alchemist', 'Puck': 0.95 },
    { name: 'Ancient Apparition', 'Puck': -1.786 },
    { name: 'Anti-Mage', 'Puck': -1.245 },
    { name: 'Arc Warden', 'Puck': 1.305 },
    { name: 'Axe', 'Puck': 1.149 },
    { name: 'Bane', 'Puck': 1.853 },
    { name: 'Batrider', 'Puck': 0.841 },
    { name: 'Beastmaster', 'Puck': 1.298 },
    { name: 'Bloodseeker', 'Puck': -0.5 },
    { name: 'Bounty Hunter', 'Puck': 0.102 },
    { name: 'Brewmaster', 'Puck': -0.51 },
    { name: 'Bristleback', 'Puck': -1.008 },
    { name: 'Broodmother', 'Puck': 2.971 },
    { name: 'Centaur Warrunner', 'Puck': 0.523 },
    { name: 'Chaos Knight', 'Puck': -0.217 },
    { name: 'Chen', 'Puck': 2.091 },
    { name: 'Clinkz', 'Puck': 0.333 },
    { name: 'Clockwerk', 'Puck': -0.533 },
    { name: 'Crystal Maiden', 'Puck': -0.679 },
    { name: 'Dark Seer', 'Puck': -1.464 },
    { name: 'Dark Willow', 'Puck': -1.464 },
    { name: 'Dawnbreaker', 'Puck': -0.335 },
    { name: 'Dazzle', 'Puck': -0.557 },
    { name: 'Death Prophet', 'Puck': 1.808 },
    { name: 'Disruptor', 'Puck': 0.489 },
    { name: 'Doom', 'Puck': 0.459 },
    { name: 'Dragon Knight', 'Puck': 2.503 },
    { name: 'Drow Ranger', 'Puck': 0.065 },
    { name: 'Earth Spirit', 'Puck': 0.949 },
    { name: 'Earthshaker', 'Puck': 0.051 },
    { name: 'Elder Titan', 'Puck': -0.708 },
    { name: 'Ember Spirit', 'Puck': -0.819 },
    { name: 'Enchantress', 'Puck': 0.069 },
    { name: 'Enigma', 'Puck': 0.967 },
    { name: 'Faceless Void', 'Puck': 0.47 },
    { name: 'Grimstroke', 'Puck': 0.174 },
    { name: 'Gyrocopter', 'Puck': 0.853 },
    { name: 'Hoodwink', 'Puck': -1.254 },
    { name: 'Huskar', 'Puck': 2.763 },
    { name: 'Invoker', 'Puck': -1.975 },
    { name: 'Io', 'Puck': 1.479 },
    { name: 'Jakiro', 'Puck': 0.19 },
    { name: 'Juggernaut', 'Puck': -1.075 },
    { name: 'Keeper of the Light', 'Puck': 0.813 },
    { name: 'Kunkka', 'Puck': -0.309 },
    { name: 'Legion Commander', 'Puck': 1.594 },
    { name: 'Leshrac', 'Puck': -0.07 },
    { name: 'Lich', 'Puck': -0.769 },
    { name: 'Lifestealer', 'Puck': 0.277 },
    { name: 'Lina', 'Puck': -0.426 },
    { name: 'Lion', 'Puck': 1.243 },
    { name: 'Lone Druid', 'Puck': 2.615 },
    { name: 'Luna', 'Puck': 1.108 },
    { name: 'Lycan', 'Puck': 1.435 },
    { name: 'Magnus', 'Puck': -1.339 },
    { name: 'Marci', 'Puck': -0.3 },
    { name: 'Mars', 'Puck': -1.527 },
    { name: 'Medusa', 'Puck': 0.95 },
    { name: 'Meepo', 'Puck': 0.6 },
    { name: 'Mirana', 'Puck': -1.919 },
    { name: 'Monkey King', 'Puck': 0.878 },
    { name: 'Morphling', 'Puck': -0.665 },
    { name: 'Muerta', 'Puck': 2.771 },
    { name: 'Naga Siren', 'Puck': 0.157 },
    { name: "Nature's Prophet", 'Puck': 1.259 },
    { name: 'Necrophos', 'Puck': -0.389 },
    { name: 'Night Stalker', 'Puck': 4.443 },
    { name: 'Nyx Assassin', 'Puck': 2.418 },
    { name: 'Ogre Magi', 'Puck': -0.219 },
    { name: 'Omniknight', 'Puck': 0.588 },
    { name: 'Oracle', 'Puck': 0.364 },
    { name: 'Outworld Destroyer', 'Puck': -1.378 },
    { name: 'Pangolier', 'Puck': -2.626 },
    { name: 'Phantom Assassin', 'Puck': -1.255 },
    { name: 'Phantom Lancer', 'Puck': -2.758 },
    { name: 'Phoenix', 'Puck': -0.815 },
    { name: 'Primal Beast', 'Puck': -0.623 },
    { name: 'Pudge', 'Puck': -1.117 },
    { name: 'Pugna', 'Puck': 0.927 },
    { name: 'Queen of Pain', 'Puck': -2.442 },
    { name: 'Razor', 'Puck': -1.223 },
    { name: 'Riki', 'Puck': 3.461 },
    { name: 'Ringmaster', 'Puck': -1.008 },
    { name: 'Rubick', 'Puck': 0.624 },
    { name: 'Sand King', 'Puck': -0.153 },
    { name: 'Shadow Demon', 'Puck': -1.429 },
    { name: 'Shadow Fiend', 'Puck': 0.619 },
    { name: 'Shadow Shaman', 'Puck': 0.896 },
    { name: 'Silencer', 'Puck': 2.547 },
    { name: 'Skywrath Mage', 'Puck': 0.203 },
    { name: 'Slardar', 'Puck': -0.037 },
    { name: 'Slark', 'Puck': -2.042 },
    { name: 'Snapfire', 'Puck': -0.57 },
    { name: 'Sniper', 'Puck': 1.24 },
    { name: 'Spectre', 'Puck': 2.573 },
    { name: 'Spirit Breaker', 'Puck': 0.331 },
    { name: 'Storm Spirit', 'Puck': -2.018 },
    { name: 'Sven', 'Puck': 1.279 },
    { name: 'Techies', 'Puck': 0.038 },
    { name: 'Templar Assassin', 'Puck': 2.101 },
    { name: 'Terrorblade', 'Puck': -0.238 },
    { name: 'Tidehunter', 'Puck': 1.009 },
    { name: 'Timbersaw', 'Puck': -1.475 },
    { name: 'Tinker', 'Puck': 2.057 },
    { name: 'Tiny', 'Puck': 1.908 },
    { name: 'Treant Protector', 'Puck': 0.085 },
    { name: 'Troll Warlord', 'Puck': -0.2 },
    { name: 'Tusk', 'Puck': -0.922 },
    { name: 'Underlord', 'Puck': 0.337 },
    { name: 'Undying', 'Puck': 1.454 },
    { name: 'Ursa', 'Puck': 0.438 },
    { name: 'Vengeful Spirit', 'Puck': -0.622 },
    { name: 'Venomancer', 'Puck': -0.284 },
    { name: 'Viper', 'Puck': -0.495 },
    { name: 'Visage', 'Puck': -1.736 },
    { name: 'Void Spirit', 'Puck': 0.992 },
    { name: 'Warlock', 'Puck': -0.32 },
    { name: 'Weaver', 'Puck': -1.762 },
    { name: 'Windranger', 'Puck': -1.566 },
    { name: 'Winter Wyvern', 'Puck': -1.12 },
    { name: 'Witch Doctor', 'Puck': -1.447 },
    { name: 'Wraith King', 'Puck': 1.9 },
    { name: 'Zeus', 'Puck': 0.394 },
],
"Pugna": [
    { name: 'Abaddon', 'Pugna': -1.083 },
    { name: 'Alchemist', 'Pugna': -0.078 },
    { name: 'Ancient Apparition', 'Pugna': 0.552 },
    { name: 'Anti-Mage', 'Pugna': 2.26 },
    { name: 'Arc Warden', 'Pugna': -0.434 },
    { name: 'Axe', 'Pugna': -1.386 },
    { name: 'Bane', 'Pugna': -0.705 },
    { name: 'Batrider', 'Pugna': 1.706 },
    { name: 'Beastmaster', 'Pugna': -0.22 },
    { name: 'Bloodseeker', 'Pugna': -1.153 },
    { name: 'Bounty Hunter', 'Pugna': 0.913 },
    { name: 'Brewmaster', 'Pugna': -1.607 },
    { name: 'Bristleback', 'Pugna': 0.749 },
    { name: 'Broodmother', 'Pugna': -0.603 },
    { name: 'Centaur Warrunner', 'Pugna': 0.523 },
    { name: 'Chaos Knight', 'Pugna': 2.057 },
    { name: 'Chen', 'Pugna': 3.232 },
    { name: 'Clinkz', 'Pugna': 0.034 },
    { name: 'Clockwerk', 'Pugna': 0.814 },
    { name: 'Crystal Maiden', 'Pugna': -1.0 },
    { name: 'Dark Seer', 'Pugna': -0.832 },
    { name: 'Dark Willow', 'Pugna': -0.565 },
    { name: 'Dawnbreaker', 'Pugna': -0.832 },
    { name: 'Dazzle', 'Pugna': -0.692 },
    { name: 'Death Prophet', 'Pugna': 0.118 },
    { name: 'Disruptor', 'Pugna': -1.374 },
    { name: 'Doom', 'Pugna': 0.664 },
    { name: 'Dragon Knight', 'Pugna': 0.365 },
    { name: 'Drow Ranger', 'Pugna': 1.501 },
    { name: 'Earth Spirit', 'Pugna': -0.345 },
    { name: 'Earthshaker', 'Pugna': 1.436 },
    { name: 'Elder Titan', 'Pugna': -1.205 },
    { name: 'Ember Spirit', 'Pugna': -1.182 },
    { name: 'Enchantress', 'Pugna': 0.2 },
    { name: 'Enigma', 'Pugna': 0.345 },
    { name: 'Faceless Void', 'Pugna': 1.618 },
    { name: 'Grimstroke', 'Pugna': 0.608 },
    { name: 'Gyrocopter', 'Pugna': 0.03 },
    { name: 'Hoodwink', 'Pugna': 0.118 },
    { name: 'Huskar', 'Pugna': 2.889 },
    { name: 'Invoker', 'Pugna': 0.214 },
    { name: 'Io', 'Pugna': -0.12 },
    { name: 'Jakiro', 'Pugna': -1.276 },
    { name: 'Juggernaut', 'Pugna': -1.039 },
    { name: 'Keeper of the Light', 'Pugna': -0.568 },
    { name: 'Kunkka', 'Pugna': -0.456 },
    { name: 'Legion Commander', 'Pugna': -0.933 },
    { name: 'Leshrac', 'Pugna': 0.248 },
    { name: 'Lich', 'Pugna': -1.737 },
    { name: 'Lifestealer', 'Pugna': -0.42 },
    { name: 'Lina', 'Pugna': 0.441 },
    { name: 'Lion', 'Pugna': 0.486 },
    { name: 'Lone Druid', 'Pugna': 0.391 },
    { name: 'Luna', 'Pugna': 0.087 },
    { name: 'Lycan', 'Pugna': 1.266 },
    { name: 'Magnus', 'Pugna': 0.884 },
    { name: 'Marci', 'Pugna': 1.364 },
    { name: 'Mars', 'Pugna': -0.598 },
    { name: 'Medusa', 'Pugna': 1.69 },
    { name: 'Meepo', 'Pugna': -0.448 },
    { name: 'Mirana', 'Pugna': 0.236 },
    { name: 'Monkey King', 'Pugna': -0.706 },
    { name: 'Morphling', 'Pugna': -0.445 },
    { name: 'Muerta', 'Pugna': 1.329 },
    { name: 'Naga Siren', 'Pugna': -0.475 },
    { name: "Nature's Prophet", 'Pugna': 0.314 },
    { name: 'Necrophos', 'Pugna': -3.123 },
    { name: 'Night Stalker', 'Pugna': 1.121 },
    { name: 'Nyx Assassin', 'Pugna': 1.521 },
    { name: 'Ogre Magi', 'Pugna': 0.549 },
    { name: 'Omniknight', 'Pugna': -2.105 },
    { name: 'Oracle', 'Pugna': -1.407 },
    { name: 'Outworld Destroyer', 'Pugna': -0.427 },
    { name: 'Pangolier', 'Pugna': -0.264 },
    { name: 'Phantom Assassin', 'Pugna': -0.308 },
    { name: 'Phantom Lancer', 'Pugna': 1.41 },
    { name: 'Phoenix', 'Pugna': -0.769 },
    { name: 'Primal Beast', 'Pugna': 1.242 },
    { name: 'Puck', 'Pugna': -0.953 },
    { name: 'Pudge', 'Pugna': 3.042 },
    { name: 'Queen of Pain', 'Pugna': -1.286 },
    { name: 'Razor', 'Pugna': -0.409 },
    { name: 'Riki', 'Pugna': 0.431 },
    { name: 'Ringmaster', 'Pugna': -1.005 },
    { name: 'Rubick', 'Pugna': -0.396 },
    { name: 'Sand King', 'Pugna': -1.404 },
    { name: 'Shadow Demon', 'Pugna': -0.797 },
    { name: 'Shadow Fiend', 'Pugna': 0.7 },
    { name: 'Shadow Shaman', 'Pugna': 0.486 },
    { name: 'Silencer', 'Pugna': 2.219 },
    { name: 'Skywrath Mage', 'Pugna': 0.142 },
    { name: 'Slardar', 'Pugna': 1.422 },
    { name: 'Slark', 'Pugna': -2.341 },
    { name: 'Snapfire', 'Pugna': 0.938 },
    { name: 'Sniper', 'Pugna': 1.535 },
    { name: 'Spectre', 'Pugna': -0.555 },
    { name: 'Spirit Breaker', 'Pugna': 1.623 },
    { name: 'Storm Spirit', 'Pugna': -2.753 },
    { name: 'Sven', 'Pugna': 0.76 },
    { name: 'Techies', 'Pugna': -0.068 },
    { name: 'Templar Assassin', 'Pugna': -1.27 },
    { name: 'Terrorblade', 'Pugna': -0.35 },
    { name: 'Tidehunter', 'Pugna': -2.574 },
    { name: 'Timbersaw', 'Pugna': -3.872 },
    { name: 'Tinker', 'Pugna': -0.962 },
    { name: 'Tiny', 'Pugna': 0.189 },
    { name: 'Treant Protector', 'Pugna': -1.564 },
    { name: 'Troll Warlord', 'Pugna': -2.845 },
    { name: 'Tusk', 'Pugna': -0.215 },
    { name: 'Underlord', 'Pugna': -1.683 },
    { name: 'Undying', 'Pugna': -0.999 },
    { name: 'Ursa', 'Pugna': 0.344 },
    { name: 'Vengeful Spirit', 'Pugna': 1.528 },
    { name: 'Venomancer', 'Pugna': -0.625 },
    { name: 'Viper', 'Pugna': 0.542 },
    { name: 'Visage', 'Pugna': 0.902 },
    { name: 'Void Spirit', 'Pugna': -0.569 },
    { name: 'Warlock', 'Pugna': 0.728 },
    { name: 'Weaver', 'Pugna': 0.436 },
    { name: 'Windranger', 'Pugna': -0.639 },
    { name: 'Winter Wyvern', 'Pugna': -2.188 },
    { name: 'Witch Doctor', 'Pugna': -0.291 },
    { name: 'Wraith King', 'Pugna': 0.758 },
    { name: 'Zeus', 'Pugna': -0.717 },
],
"Queen of Pain": [
    { name: 'Abaddon', 'Queen of Pain': 0.725 },
    { name: 'Alchemist', 'Queen of Pain': -1.19 },
    { name: 'Ancient Apparition', 'Queen of Pain': -0.867 },
    { name: 'Anti-Mage', 'Queen of Pain': 1.899 },
    { name: 'Arc Warden', 'Queen of Pain': 0.87 },
    { name: 'Axe', 'Queen of Pain': -1.207 },
    { name: 'Bane', 'Queen of Pain': 1.008 },
    { name: 'Batrider', 'Queen of Pain': -0.868 },
    { name: 'Beastmaster', 'Queen of Pain': 0.431 },
    { name: 'Bloodseeker', 'Queen of Pain': -0.413 },
    { name: 'Bounty Hunter', 'Queen of Pain': -0.283 },
    { name: 'Brewmaster', 'Queen of Pain': -0.086 },
    { name: 'Bristleback', 'Queen of Pain': -0.051 },
    { name: 'Broodmother', 'Queen of Pain': 0.072 },
    { name: 'Centaur Warrunner', 'Queen of Pain': -0.258 },
    { name: 'Chaos Knight', 'Queen of Pain': 1.382 },
    { name: 'Chen', 'Queen of Pain': 1.616 },
    { name: 'Clinkz', 'Queen of Pain': 0.616 },
    { name: 'Clockwerk', 'Queen of Pain': -1.62 },
    { name: 'Crystal Maiden', 'Queen of Pain': -0.122 },
    { name: 'Dark Seer', 'Queen of Pain': -1.594 },
    { name: 'Dark Willow', 'Queen of Pain': -0.684 },
    { name: 'Dawnbreaker', 'Queen of Pain': 0.066 },
    { name: 'Dazzle', 'Queen of Pain': -0.173 },
    { name: 'Death Prophet', 'Queen of Pain': -0.589 },
    { name: 'Disruptor', 'Queen of Pain': 0.085 },
    { name: 'Doom', 'Queen of Pain': -0.07 },
    { name: 'Dragon Knight', 'Queen of Pain': 0.384 },
    { name: 'Drow Ranger', 'Queen of Pain': -0.068 },
    { name: 'Earth Spirit', 'Queen of Pain': 1.43 },
    { name: 'Earthshaker', 'Queen of Pain': 0.019 },
    { name: 'Elder Titan', 'Queen of Pain': 0.496 },
    { name: 'Ember Spirit', 'Queen of Pain': 3.535 },
    { name: 'Enchantress', 'Queen of Pain': 0.278 },
    { name: 'Enigma', 'Queen of Pain': 0.85 },
    { name: 'Faceless Void', 'Queen of Pain': 1.671 },
    { name: 'Grimstroke', 'Queen of Pain': 0.567 },
    { name: 'Gyrocopter', 'Queen of Pain': -0.141 },
    { name: 'Hoodwink', 'Queen of Pain': -0.383 },
    { name: 'Huskar', 'Queen of Pain': 3.231 },
    { name: 'Invoker', 'Queen of Pain': 0.08 },
    { name: 'Io', 'Queen of Pain': 1.007 },
    { name: 'Jakiro', 'Queen of Pain': -0.764 },
    { name: 'Juggernaut', 'Queen of Pain': -1.053 },
    { name: 'Keeper of the Light', 'Queen of Pain': -0.945 },
    { name: 'Kunkka', 'Queen of Pain': -0.018 },
    { name: 'Legion Commander', 'Queen of Pain': 1.834 },
    { name: 'Leshrac', 'Queen of Pain': -1.819 },
    { name: 'Lich', 'Queen of Pain': -1.391 },
    { name: 'Lifestealer', 'Queen of Pain': -1.451 },
    { name: 'Lina', 'Queen of Pain': 1.687 },
    { name: 'Lion', 'Queen of Pain': 0.435 },
    { name: 'Lone Druid', 'Queen of Pain': 1.469 },
    { name: 'Luna', 'Queen of Pain': -0.663 },
    { name: 'Lycan', 'Queen of Pain': 0.416 },
    { name: 'Magnus', 'Queen of Pain': -0.002 },
    { name: 'Marci', 'Queen of Pain': 0.464 },
    { name: 'Mars', 'Queen of Pain': -1.86 },
    { name: 'Medusa', 'Queen of Pain': 3.625 },
    { name: 'Meepo', 'Queen of Pain': 2.229 },
    { name: 'Mirana', 'Queen of Pain': 0.242 },
    { name: 'Monkey King', 'Queen of Pain': -0.927 },
    { name: 'Morphling', 'Queen of Pain': 1.45 },
    { name: 'Muerta', 'Queen of Pain': 0.568 },
    { name: 'Naga Siren', 'Queen of Pain': 0.916 },
    { name: "Nature's Prophet", 'Queen of Pain': 0.673 },
    { name: 'Necrophos', 'Queen of Pain': -0.805 },
    { name: 'Night Stalker', 'Queen of Pain': 1.355 },
    { name: 'Nyx Assassin', 'Queen of Pain': 1.051 },
    { name: 'Ogre Magi', 'Queen of Pain': 0.07 },
    { name: 'Omniknight', 'Queen of Pain': -0.563 },
    { name: 'Oracle', 'Queen of Pain': 1.565 },
    { name: 'Outworld Destroyer', 'Queen of Pain': -0.461 },
    { name: 'Pangolier', 'Queen of Pain': 0.418 },
    { name: 'Phantom Assassin', 'Queen of Pain': -1.334 },
    { name: 'Phantom Lancer', 'Queen of Pain': 0.261 },
    { name: 'Phoenix', 'Queen of Pain': -0.605 },
    { name: 'Primal Beast', 'Queen of Pain': -1.167 },
    { name: 'Puck', 'Queen of Pain': 2.413 },
    { name: 'Pudge', 'Queen of Pain': -0.706 },
    { name: 'Pugna', 'Queen of Pain': 1.329 },
    { name: 'Razor', 'Queen of Pain': -3.429 },
    { name: 'Riki', 'Queen of Pain': 1.3 },
    { name: 'Ringmaster', 'Queen of Pain': -0.612 },
    { name: 'Rubick', 'Queen of Pain': -0.347 },
    { name: 'Sand King', 'Queen of Pain': -1.607 },
    { name: 'Shadow Demon', 'Queen of Pain': -0.75 },
    { name: 'Shadow Fiend', 'Queen of Pain': -0.161 },
    { name: 'Shadow Shaman', 'Queen of Pain': 0.783 },
    { name: 'Silencer', 'Queen of Pain': 0.773 },
    { name: 'Skywrath Mage', 'Queen of Pain': 1.184 },
    { name: 'Slardar', 'Queen of Pain': 0.357 },
    { name: 'Slark', 'Queen of Pain': -0.328 },
    { name: 'Snapfire', 'Queen of Pain': 0.38 },
    { name: 'Sniper', 'Queen of Pain': -0.73 },
    { name: 'Spectre', 'Queen of Pain': -0.852 },
    { name: 'Spirit Breaker', 'Queen of Pain': -0.599 },
    { name: 'Storm Spirit', 'Queen of Pain': 0.302 },
    { name: 'Sven', 'Queen of Pain': -0.17 },
    { name: 'Techies', 'Queen of Pain': 0.017 },
    { name: 'Templar Assassin', 'Queen of Pain': 2.265 },
    { name: 'Terrorblade', 'Queen of Pain': -0.101 },
    { name: 'Tidehunter', 'Queen of Pain': 0.187 },
    { name: 'Timbersaw', 'Queen of Pain': -2.245 },
    { name: 'Tinker', 'Queen of Pain': 0.846 },
    { name: 'Tiny', 'Queen of Pain': -0.513 },
    { name: 'Treant Protector', 'Queen of Pain': -0.604 },
    { name: 'Troll Warlord', 'Queen of Pain': -0.347 },
    { name: 'Tusk', 'Queen of Pain': -0.123 },
    { name: 'Underlord', 'Queen of Pain': 0.282 },
    { name: 'Undying', 'Queen of Pain': 0.346 },
    { name: 'Ursa', 'Queen of Pain': 0.363 },
    { name: 'Vengeful Spirit', 'Queen of Pain': 0.158 },
    { name: 'Venomancer', 'Queen of Pain': -0.77 },
    { name: 'Viper', 'Queen of Pain': 0.177 },
    { name: 'Visage', 'Queen of Pain': 0.336 },
    { name: 'Void Spirit', 'Queen of Pain': 1.33 },
    { name: 'Warlock', 'Queen of Pain': -0.725 },
    { name: 'Weaver', 'Queen of Pain': 0.28 },
    { name: 'Windranger', 'Queen of Pain': -0.562 },
    { name: 'Winter Wyvern', 'Queen of Pain': -1.216 },
    { name: 'Witch Doctor', 'Queen of Pain': 0.673 },
    { name: 'Wraith King', 'Queen of Pain': 0.021 },
    { name: 'Zeus', 'Queen of Pain': -1.593 },
],
"Ringmaster": [
    { name: 'Abaddon', 'Ringmaster': -0.078 },
    { name: 'Alchemist', 'Ringmaster': 0.442 },
    { name: 'Ancient Apparition', 'Ringmaster': 1.471 },
    { name: 'Anti-Mage', 'Ringmaster': 1.254 },
    { name: 'Arc Warden', 'Ringmaster': -0.232 },
    { name: 'Axe', 'Ringmaster': -1.199 },
    { name: 'Bane', 'Ringmaster': -0.218 },
    { name: 'Batrider', 'Ringmaster': 1.568 },
    { name: 'Beastmaster', 'Ringmaster': 0.6 },
    { name: 'Bloodseeker', 'Ringmaster': 1.288 },
    { name: 'Bounty Hunter', 'Ringmaster': 0.404 },
    { name: 'Brewmaster', 'Ringmaster': 1.197 },
    { name: 'Bristleback', 'Ringmaster': 1.646 },
    { name: 'Broodmother', 'Ringmaster': 0.91 },
    { name: 'Centaur Warrunner', 'Ringmaster': -0.296 },
    { name: 'Chaos Knight', 'Ringmaster': 0.57 },
    { name: 'Chen', 'Ringmaster': 3.257 },
    { name: 'Clinkz', 'Ringmaster': 0.669 },
    { name: 'Clockwerk', 'Ringmaster': -0.654 },
    { name: 'Crystal Maiden', 'Ringmaster': -0.254 },
    { name: 'Dark Seer', 'Ringmaster': -0.025 },
    { name: 'Dark Willow', 'Ringmaster': 0.245 },
    { name: 'Dawnbreaker', 'Ringmaster': -0.525 },
    { name: 'Dazzle', 'Ringmaster': 0.91 },
    { name: 'Death Prophet', 'Ringmaster': 1.102 },
    { name: 'Disruptor', 'Ringmaster': -0.127 },
    { name: 'Doom', 'Ringmaster': 0.35 },
    { name: 'Dragon Knight', 'Ringmaster': -0.926 },
    { name: 'Drow Ranger', 'Ringmaster': -0.61 },
    { name: 'Earth Spirit', 'Ringmaster': 0.879 },
    { name: 'Earthshaker', 'Ringmaster': 0.485 },
    { name: 'Elder Titan', 'Ringmaster': 0.892 },
    { name: 'Ember Spirit', 'Ringmaster': -2.182 },
    { name: 'Enchantress', 'Ringmaster': 0.69 },
    { name: 'Enigma', 'Ringmaster': 0.29 },
    { name: 'Faceless Void', 'Ringmaster': 0.408 },
    { name: 'Grimstroke', 'Ringmaster': -0.018 },
    { name: 'Gyrocopter', 'Ringmaster': 0.133 },
    { name: 'Hoodwink', 'Ringmaster': 0.305 },
    { name: 'Huskar', 'Ringmaster': 1.267 },
    { name: 'Invoker', 'Ringmaster': 0.839 },
    { name: 'Io', 'Ringmaster': 0.734 },
    { name: 'Jakiro', 'Ringmaster': -0.327 },
    { name: 'Juggernaut', 'Ringmaster': 1.363 },
    { name: 'Keeper of the Light', 'Ringmaster': 0.987 },
    { name: 'Kunkka', 'Ringmaster': 0.117 },
    { name: 'Legion Commander', 'Ringmaster': -1.675 },
    { name: 'Leshrac', 'Ringmaster': -0.126 },
    { name: 'Lich', 'Ringmaster': -0.806 },
    { name: 'Lifestealer', 'Ringmaster': -0.223 },
    { name: 'Lina', 'Ringmaster': -0.398 },
    { name: 'Lion', 'Ringmaster': 0.34 },
    { name: 'Lone Druid', 'Ringmaster': -0.601 },
    { name: 'Luna', 'Ringmaster': -0.186 },
    { name: 'Lycan', 'Ringmaster': 1.986 },
    { name: 'Magnus', 'Ringmaster': 1.189 },
    { name: 'Marci', 'Ringmaster': -0.033 },
    { name: 'Mars', 'Ringmaster': -0.747 },
    { name: 'Medusa', 'Ringmaster': -0.159 },
    { name: 'Meepo', 'Ringmaster': -0.908 },
    { name: 'Mirana', 'Ringmaster': -0.203 },
    { name: 'Monkey King', 'Ringmaster': -0.09 },
    { name: 'Morphling', 'Ringmaster': 0.786 },
    { name: 'Muerta', 'Ringmaster': -0.169 },
    { name: 'Naga Siren', 'Ringmaster': -0.29 },
    { name: "Nature's Prophet", 'Ringmaster': 1.083 },
    { name: 'Necrophos', 'Ringmaster': 0.435 },
    { name: 'Night Stalker', 'Ringmaster': 0.038 },
    { name: 'Nyx Assassin', 'Ringmaster': 1.191 },
    { name: 'Ogre Magi', 'Ringmaster': -0.266 },
    { name: 'Omniknight', 'Ringmaster': 0.767 },
    { name: 'Oracle', 'Ringmaster': 0.156 },
    { name: 'Outworld Destroyer', 'Ringmaster': -0.638 },
    { name: 'Pangolier', 'Ringmaster': 1.116 },
    { name: 'Phantom Assassin', 'Ringmaster': 1.189 },
    { name: 'Phantom Lancer', 'Ringmaster': 1.487 },
    { name: 'Phoenix', 'Ringmaster': 0.931 },
    { name: 'Primal Beast', 'Ringmaster': -1.102 },
    { name: 'Puck', 'Ringmaster': 1.224 },
    { name: 'Pudge', 'Ringmaster': -0.584 },
    { name: 'Pugna', 'Ringmaster': 1.231 },
    { name: 'Queen of Pain', 'Ringmaster': 0.666 },
    { name: 'Razor', 'Ringmaster': 0.284 },
    { name: 'Riki', 'Ringmaster': 0.554 },
    { name: 'Rubick', 'Ringmaster': 0.138 },
    { name: 'Sand King', 'Ringmaster': 0.021 },
    { name: 'Shadow Demon', 'Ringmaster': 0.663 },
    { name: 'Shadow Fiend', 'Ringmaster': 0.293 },
    { name: 'Shadow Shaman', 'Ringmaster': -0.38 },
    { name: 'Silencer', 'Ringmaster': -0.146 },
    { name: 'Skywrath Mage', 'Ringmaster': 0.141 },
    { name: 'Slardar', 'Ringmaster': 0.367 },
    { name: 'Slark', 'Ringmaster': -2.102 },
    { name: 'Snapfire', 'Ringmaster': 1.114 },
    { name: 'Sniper', 'Ringmaster': 0.398 },
    { name: 'Spectre', 'Ringmaster': -0.009 },
    { name: 'Spirit Breaker', 'Ringmaster': -0.356 },
    { name: 'Storm Spirit', 'Ringmaster': 0.665 },
    { name: 'Sven', 'Ringmaster': -0.99 },
    { name: 'Techies', 'Ringmaster': 1.584 },
    { name: 'Templar Assassin', 'Ringmaster': 0.897 },
    { name: 'Terrorblade', 'Ringmaster': -0.066 },
    { name: 'Tidehunter', 'Ringmaster': -0.092 },
    { name: 'Timbersaw', 'Ringmaster': 0.876 },
    { name: 'Tinker', 'Ringmaster': -1.405 },
    { name: 'Tiny', 'Ringmaster': -0.294 },
    { name: 'Treant Protector', 'Ringmaster': -0.621 },
    { name: 'Troll Warlord', 'Ringmaster': -1.763 },
    { name: 'Tusk', 'Ringmaster': 0.645 },
    { name: 'Underlord', 'Ringmaster': -0.826 },
    { name: 'Undying', 'Ringmaster': 0.392 },
    { name: 'Ursa', 'Ringmaster': 0.088 },
    { name: 'Vengeful Spirit', 'Ringmaster': 0.033 },
    { name: 'Venomancer', 'Ringmaster': 1.124 },
    { name: 'Viper', 'Ringmaster': -0.764 },
    { name: 'Visage', 'Ringmaster': 0.264 },
    { name: 'Void Spirit', 'Ringmaster': 0.53 },
    { name: 'Warlock', 'Ringmaster': -0.125 },
    { name: 'Weaver', 'Ringmaster': 0.557 },
    { name: 'Windranger', 'Ringmaster': 0.818 },
    { name: 'Winter Wyvern', 'Ringmaster': 0.132 },
    { name: 'Witch Doctor', 'Ringmaster': -0.565 },
    { name: 'Wraith King', 'Ringmaster': 0.737 },
    { name: 'Zeus', 'Ringmaster': 0.661 },
],
"Rubick": [
    { name: 'Abaddon', 'Rubick': -2.162 },
    { name: 'Alchemist', 'Rubick': 0.221 },
    { name: 'Ancient Apparition', 'Rubick': -0.356 },
    { name: 'Anti-Mage', 'Rubick': 0.265 },
    { name: 'Arc Warden', 'Rubick': -1.455 },
    { name: 'Axe', 'Rubick': 0.83 },
    { name: 'Bane', 'Rubick': 0.424 },
    { name: 'Batrider', 'Rubick': 1.576 },
    { name: 'Beastmaster', 'Rubick': -0.933 },
    { name: 'Bloodseeker', 'Rubick': -0.405 },
    { name: 'Bounty Hunter', 'Rubick': -0.397 },
    { name: 'Brewmaster', 'Rubick': -0.256 },
    { name: 'Bristleback', 'Rubick': 0.163 },
    { name: 'Broodmother', 'Rubick': 0.033 },
    { name: 'Centaur Warrunner', 'Rubick': -0.105 },
    { name: 'Chaos Knight', 'Rubick': -0.625 },
    { name: 'Chen', 'Rubick': 1.368 },
    { name: 'Clinkz', 'Rubick': 0.903 },
    { name: 'Clockwerk', 'Rubick': -0.162 },
    { name: 'Crystal Maiden', 'Rubick': -2.535 },
    { name: 'Dark Seer', 'Rubick': -1.59 },
    { name: 'Dark Willow', 'Rubick': 0.292 },
    { name: 'Dawnbreaker', 'Rubick': -0.684 },
    { name: 'Dazzle', 'Rubick': 1.09 },
    { name: 'Death Prophet', 'Rubick': -0.619 },
    { name: 'Disruptor', 'Rubick': -0.092 },
    { name: 'Doom', 'Rubick': -1.177 },
    { name: 'Dragon Knight', 'Rubick': 0.028 },
    { name: 'Drow Ranger', 'Rubick': 0.819 },
    { name: 'Earth Spirit', 'Rubick': 1.565 },
    { name: 'Earthshaker', 'Rubick': 0.239 },
    { name: 'Elder Titan', 'Rubick': 1.301 },
    { name: 'Ember Spirit', 'Rubick': 1.14 },
    { name: 'Enchantress', 'Rubick': 0.587 },
    { name: 'Enigma', 'Rubick': -4.002 },
    { name: 'Faceless Void', 'Rubick': 0.045 },
    { name: 'Grimstroke', 'Rubick': 0.151 },
    { name: 'Gyrocopter', 'Rubick': 0.507 },
    { name: 'Hoodwink', 'Rubick': 0.763 },
    { name: 'Huskar', 'Rubick': 1.311 },
    { name: 'Invoker', 'Rubick': 0.613 },
    { name: 'Io', 'Rubick': -0.024 },
    { name: 'Jakiro', 'Rubick': -4.098 },
    { name: 'Juggernaut', 'Rubick': -0.021 },
    { name: 'Keeper of the Light', 'Rubick': 0.966 },
    { name: 'Kunkka', 'Rubick': -1.866 },
    { name: 'Legion Commander', 'Rubick': 0.74 },
    { name: 'Leshrac', 'Rubick': -0.037 },
    { name: 'Lich', 'Rubick': -0.95 },
    { name: 'Lifestealer', 'Rubick': 0.821 },
    { name: 'Lina', 'Rubick': -1.152 },
    { name: 'Lion', 'Rubick': -1.478 },
    { name: 'Lone Druid', 'Rubick': -0.261 },
    { name: 'Luna', 'Rubick': -1.286 },
    { name: 'Lycan', 'Rubick': -0.715 },
    { name: 'Magnus', 'Rubick': -0.533 },
    { name: 'Marci', 'Rubick': 0.132 },
    { name: 'Mars', 'Rubick': 0.58 },
    { name: 'Medusa', 'Rubick': -2.45 },
    { name: 'Meepo', 'Rubick': 1.213 },
    { name: 'Mirana', 'Rubick': -0.107 },
    { name: 'Monkey King', 'Rubick': 0.968 },
    { name: 'Morphling', 'Rubick': -1.15 },
    { name: 'Muerta', 'Rubick': -0.966 },
    { name: 'Naga Siren', 'Rubick': -0.816 },
    { name: "Nature's Prophet", 'Rubick': 0.624 },
    { name: 'Necrophos', 'Rubick': -0.249 },
    { name: 'Night Stalker', 'Rubick': 0.202 },
    { name: 'Nyx Assassin', 'Rubick': -0.409 },
    { name: 'Ogre Magi', 'Rubick': -0.183 },
    { name: 'Omniknight', 'Rubick': -0.799 },
    { name: 'Oracle', 'Rubick': -0.096 },
    { name: 'Outworld Destroyer', 'Rubick': -2.545 },
    { name: 'Pangolier', 'Rubick': 0.826 },
    { name: 'Phantom Assassin', 'Rubick': 0.717 },
    { name: 'Phantom Lancer', 'Rubick': -0.96 },
    { name: 'Phoenix', 'Rubick': 0.852 },
    { name: 'Primal Beast', 'Rubick': 0.377 },
    { name: 'Puck', 'Rubick': -0.593 },
    { name: 'Pudge', 'Rubick': 0.907 },
    { name: 'Pugna', 'Rubick': 0.278 },
    { name: 'Queen of Pain', 'Rubick': 0.119 },
    { name: 'Razor', 'Rubick': 0.659 },
    { name: 'Riki', 'Rubick': -0.416 },
    { name: 'Ringmaster', 'Rubick': -0.432 },
    { name: 'Sand King', 'Rubick': -0.017 },
    { name: 'Shadow Demon', 'Rubick': 0.485 },
    { name: 'Shadow Fiend', 'Rubick': 0.077 },
    { name: 'Shadow Shaman', 'Rubick': -0.709 },
    { name: 'Silencer', 'Rubick': -1.179 },
    { name: 'Skywrath Mage', 'Rubick': 0.184 },
    { name: 'Slardar', 'Rubick': 0.468 },
    { name: 'Slark', 'Rubick': 0.492 },
    { name: 'Snapfire', 'Rubick': 0.782 },
    { name: 'Sniper', 'Rubick': 0.016 },
    { name: 'Spectre', 'Rubick': -0.229 },
    { name: 'Spirit Breaker', 'Rubick': -0.177 },
    { name: 'Storm Spirit', 'Rubick': 0.625 },
    { name: 'Sven', 'Rubick': -1.07 },
    { name: 'Techies', 'Rubick': -0.163 },
    { name: 'Templar Assassin', 'Rubick': 2.428 },
    { name: 'Terrorblade', 'Rubick': 0.355 },
    { name: 'Tidehunter', 'Rubick': -0.451 },
    { name: 'Timbersaw', 'Rubick': -0.534 },
    { name: 'Tinker', 'Rubick': -0.739 },
    { name: 'Tiny', 'Rubick': 0.193 },
    { name: 'Treant Protector', 'Rubick': -0.66 },
    { name: 'Troll Warlord', 'Rubick': -0.728 },
    { name: 'Tusk', 'Rubick': 1.654 },
    { name: 'Underlord', 'Rubick': -6.92 },
    { name: 'Undying', 'Rubick': -1.432 },
    { name: 'Ursa', 'Rubick': 1.294 },
    { name: 'Vengeful Spirit', 'Rubick': -0.937 },
    { name: 'Venomancer', 'Rubick': -0.326 },
    { name: 'Viper', 'Rubick': -0.361 },
    { name: 'Visage', 'Rubick': 0.188 },
    { name: 'Void Spirit', 'Rubick': -0.855 },
    { name: 'Warlock', 'Rubick': -0.92 },
    { name: 'Weaver', 'Rubick': -2.877 },
    { name: 'Windranger', 'Rubick': -0.479 },
    { name: 'Winter Wyvern', 'Rubick': 0.115 },
    { name: 'Witch Doctor', 'Rubick': -0.707 },
    { name: 'Wraith King', 'Rubick': -2.368 },
    { name: 'Zeus', 'Rubick': -0.401 },
],
"Shadow Demon": [
    { name: 'Abaddon', 'Shadow Demon': -3.295 },
    { name: 'Alchemist', 'Shadow Demon': 0.117 },
    { name: 'Ancient Apparition', 'Shadow Demon': -0.11 },
    { name: 'Anti-Mage', 'Shadow Demon': 1.471 },
    { name: 'Arc Warden', 'Shadow Demon': -0.904 },
    { name: 'Axe', 'Shadow Demon': -0.996 },
    { name: 'Bane', 'Shadow Demon': 1.437 },
    { name: 'Batrider', 'Shadow Demon': 0.68 },
    { name: 'Beastmaster', 'Shadow Demon': 0.674 },
    { name: 'Bloodseeker', 'Shadow Demon': -0.376 },
    { name: 'Bounty Hunter', 'Shadow Demon': 0.961 },
    { name: 'Brewmaster', 'Shadow Demon': 0.845 },
    { name: 'Bristleback', 'Shadow Demon': -1.752 },
    { name: 'Broodmother', 'Shadow Demon': -1.124 },
    { name: 'Centaur Warrunner', 'Shadow Demon': -0.333 },
    { name: 'Chaos Knight', 'Shadow Demon': 1.616 },
    { name: 'Chen', 'Shadow Demon': 4.418 },
    { name: 'Clinkz', 'Shadow Demon': -0.4 },
    { name: 'Clockwerk', 'Shadow Demon': -0.924 },
    { name: 'Crystal Maiden', 'Shadow Demon': 0.493 },
    { name: 'Dark Seer', 'Shadow Demon': 0.184 },
    { name: 'Dark Willow', 'Shadow Demon': -0.371 },
    { name: 'Dawnbreaker', 'Shadow Demon': 1.8 },
    { name: 'Dazzle', 'Shadow Demon': 0.409 },
    { name: 'Death Prophet', 'Shadow Demon': -1.228 },
    { name: 'Disruptor', 'Shadow Demon': -1.031 },
    { name: 'Doom', 'Shadow Demon': 0.399 },
    { name: 'Dragon Knight', 'Shadow Demon': 0.026 },
    { name: 'Drow Ranger', 'Shadow Demon': -0.358 },
    { name: 'Earth Spirit', 'Shadow Demon': 2.06 },
    { name: 'Earthshaker', 'Shadow Demon': 1.81 },
    { name: 'Elder Titan', 'Shadow Demon': -0.783 },
    { name: 'Ember Spirit', 'Shadow Demon': 1.343 },
    { name: 'Enchantress', 'Shadow Demon': -0.342 },
    { name: 'Enigma', 'Shadow Demon': 0.753 },
    { name: 'Faceless Void', 'Shadow Demon': 1.03 },
    { name: 'Grimstroke', 'Shadow Demon': -2.042 },
    { name: 'Gyrocopter', 'Shadow Demon': -0.508 },
    { name: 'Hoodwink', 'Shadow Demon': -0.577 },
    { name: 'Huskar', 'Shadow Demon': 0.116 },
    { name: 'Invoker', 'Shadow Demon': -1.018 },
    { name: 'Io', 'Shadow Demon': 0.903 },
    { name: 'Jakiro', 'Shadow Demon': 0.045 },
    { name: 'Juggernaut', 'Shadow Demon': 0.413 },
    { name: 'Keeper of the Light', 'Shadow Demon': 1.262 },
    { name: 'Kunkka', 'Shadow Demon': 0.481 },
    { name: 'Legion Commander', 'Shadow Demon': -2.207 },
    { name: 'Leshrac', 'Shadow Demon': -0.15 },
    { name: 'Lich', 'Shadow Demon': -2.017 },
    { name: 'Lifestealer', 'Shadow Demon': 0.347 },
    { name: 'Lina', 'Shadow Demon': -0.484 },
    { name: 'Lion', 'Shadow Demon': 0.126 },
    { name: 'Lone Druid', 'Shadow Demon': 2.544 },
    { name: 'Luna', 'Shadow Demon': -1.472 },
    { name: 'Lycan', 'Shadow Demon': 1.927 },
    { name: 'Magnus', 'Shadow Demon': 1.322 },
    { name: 'Marci', 'Shadow Demon': 0.979 },
    { name: 'Mars', 'Shadow Demon': -0.651 },
    { name: 'Medusa', 'Shadow Demon': -2.841 },
    { name: 'Meepo', 'Shadow Demon': 3.548 },
    { name: 'Mirana', 'Shadow Demon': -0.327 },
    { name: 'Monkey King', 'Shadow Demon': 0.472 },
    { name: 'Morphling', 'Shadow Demon': 0.885 },
    { name: 'Muerta', 'Shadow Demon': -2.771 },
    { name: 'Naga Siren', 'Shadow Demon': 1.452 },
    { name: "Nature's Prophet", 'Shadow Demon': 1.307 },
    { name: 'Necrophos', 'Shadow Demon': -2.063 },
    { name: 'Night Stalker', 'Shadow Demon': -0.011 },
    { name: 'Nyx Assassin', 'Shadow Demon': 0.458 },
    { name: 'Ogre Magi', 'Shadow Demon': -0.434 },
    { name: 'Omniknight', 'Shadow Demon': -4.267 },
    { name: 'Oracle', 'Shadow Demon': -3.911 },
    { name: 'Outworld Destroyer', 'Shadow Demon': -0.578 },
    { name: 'Pangolier', 'Shadow Demon': 0.675 },
    { name: 'Phantom Assassin', 'Shadow Demon': 1.068 },
    { name: 'Phantom Lancer', 'Shadow Demon': 3.051 },
    { name: 'Phoenix', 'Shadow Demon': 0.231 },
    { name: 'Primal Beast', 'Shadow Demon': -1.126 },
    { name: 'Puck', 'Shadow Demon': 1.429 },
    { name: 'Pudge', 'Shadow Demon': 0.606 },
    { name: 'Pugna', 'Shadow Demon': 0.81 },
    { name: 'Queen of Pain', 'Shadow Demon': 0.971 },
    { name: 'Razor', 'Shadow Demon': 0.659 },
    { name: 'Riki', 'Shadow Demon': 2.436 },
    { name: 'Ringmaster', 'Shadow Demon': -0.361 },
    { name: 'Rubick', 'Shadow Demon': -0.806 },
    { name: 'Sand King', 'Shadow Demon': 1.981 },
    { name: 'Shadow Fiend', 'Shadow Demon': -0.804 },
    { name: 'Shadow Shaman', 'Shadow Demon': 0.221 },
    { name: 'Silencer', 'Shadow Demon': 0.377 },
    { name: 'Skywrath Mage', 'Shadow Demon': -1.641 },
    { name: 'Slardar', 'Shadow Demon': 0.132 },
    { name: 'Slark', 'Shadow Demon': -0.181 },
    { name: 'Snapfire', 'Shadow Demon': 0.819 },
    { name: 'Sniper', 'Shadow Demon': 0.289 },
    { name: 'Spectre', 'Shadow Demon': -0.268 },
    { name: 'Spirit Breaker', 'Shadow Demon': 0.125 },
    { name: 'Storm Spirit', 'Shadow Demon': 0.446 },
    { name: 'Sven', 'Shadow Demon': 0.193 },
    { name: 'Techies', 'Shadow Demon': 0.402 },
    { name: 'Templar Assassin', 'Shadow Demon': 0.169 },
    { name: 'Terrorblade', 'Shadow Demon': 1.137 },
    { name: 'Tidehunter', 'Shadow Demon': 1.339 },
    { name: 'Timbersaw', 'Shadow Demon': 2.261 },
    { name: 'Tinker', 'Shadow Demon': -0.58 },
    { name: 'Tiny', 'Shadow Demon': -0.024 },
    { name: 'Treant Protector', 'Shadow Demon': -0.536 },
    { name: 'Troll Warlord', 'Shadow Demon': -0.331 },
    { name: 'Tusk', 'Shadow Demon': 1.331 },
    { name: 'Underlord', 'Shadow Demon': 0.981 },
    { name: 'Undying', 'Shadow Demon': 2.171 },
    { name: 'Ursa', 'Shadow Demon': -0.239 },
    { name: 'Vengeful Spirit', 'Shadow Demon': 0.086 },
    { name: 'Venomancer', 'Shadow Demon': -1.826 },
    { name: 'Viper', 'Shadow Demon': -2.954 },
    { name: 'Visage', 'Shadow Demon': -0.838 },
    { name: 'Void Spirit', 'Shadow Demon': 1.625 },
    { name: 'Warlock', 'Shadow Demon': 1.731 },
    { name: 'Weaver', 'Shadow Demon': 2.513 },
    { name: 'Windranger', 'Shadow Demon': -0.992 },
    { name: 'Winter Wyvern', 'Shadow Demon': 0.331 },
    { name: 'Witch Doctor', 'Shadow Demon': 0.635 },
    { name: 'Wraith King', 'Shadow Demon': -0.829 },
    { name: 'Zeus', 'Shadow Demon': 0.519 },
],
"Shadow Shaman": [
    { name: 'Abaddon', 'Shadow Shaman': 0.978 },
    { name: 'Alchemist', 'Shadow Shaman': 1.805 },
    { name: 'Ancient Apparition', 'Shadow Shaman': -0.196 },
    { name: 'Anti-Mage', 'Shadow Shaman': -1.117 },
    { name: 'Arc Warden', 'Shadow Shaman': 0.422 },
    { name: 'Axe', 'Shadow Shaman': 0.363 },
    { name: 'Bane', 'Shadow Shaman': -0.508 },
    { name: 'Batrider', 'Shadow Shaman': -0.137 },
    { name: 'Beastmaster', 'Shadow Shaman': 1.615 },
    { name: 'Bloodseeker', 'Shadow Shaman': 0.035 },
    { name: 'Bounty Hunter', 'Shadow Shaman': -2.392 },
    { name: 'Brewmaster', 'Shadow Shaman': 0.331 },
    { name: 'Bristleback', 'Shadow Shaman': -1.012 },
    { name: 'Broodmother', 'Shadow Shaman': -0.305 },
    { name: 'Centaur Warrunner', 'Shadow Shaman': -0.434 },
    { name: 'Chaos Knight', 'Shadow Shaman': 2.1 },
    { name: 'Chen', 'Shadow Shaman': -0.319 },
    { name: 'Clinkz', 'Shadow Shaman': -0.277 },
    { name: 'Clockwerk', 'Shadow Shaman': 0.252 },
    { name: 'Crystal Maiden', 'Shadow Shaman': -0.744 },
    { name: 'Dark Seer', 'Shadow Shaman': 2.217 },
    { name: 'Dark Willow', 'Shadow Shaman': -0.346 },
    { name: 'Dawnbreaker', 'Shadow Shaman': 1.536 },
    { name: 'Dazzle', 'Shadow Shaman': 0.293 },
    { name: 'Death Prophet', 'Shadow Shaman': 0.04 },
    { name: 'Disruptor', 'Shadow Shaman': -0.255 },
    { name: 'Doom', 'Shadow Shaman': 0.857 },
    { name: 'Dragon Knight', 'Shadow Shaman': 0.562 },
    { name: 'Drow Ranger', 'Shadow Shaman': 1.31 },
    { name: 'Earth Spirit', 'Shadow Shaman': -1.145 },
    { name: 'Earthshaker', 'Shadow Shaman': -0.033 },
    { name: 'Elder Titan', 'Shadow Shaman': -0.585 },
    { name: 'Ember Spirit', 'Shadow Shaman': -0.147 },
    { name: 'Enchantress', 'Shadow Shaman': -0.222 },
    { name: 'Enigma', 'Shadow Shaman': 0.911 },
    { name: 'Faceless Void', 'Shadow Shaman': -1.082 },
    { name: 'Grimstroke', 'Shadow Shaman': 0.065 },
    { name: 'Gyrocopter', 'Shadow Shaman': 1.954 },
    { name: 'Hoodwink', 'Shadow Shaman': 0.727 },
    { name: 'Huskar', 'Shadow Shaman': -0.706 },
    { name: 'Invoker', 'Shadow Shaman': 0.175 },
    { name: 'Io', 'Shadow Shaman': 0.5 },
    { name: 'Jakiro', 'Shadow Shaman': 0.401 },
    { name: 'Juggernaut', 'Shadow Shaman': -0.594 },
    { name: 'Keeper of the Light', 'Shadow Shaman': -0.602 },
    { name: 'Kunkka', 'Shadow Shaman': 0.241 },
    { name: 'Legion Commander', 'Shadow Shaman': -0.476 },
    { name: 'Leshrac', 'Shadow Shaman': 0.255 },
    { name: 'Lich', 'Shadow Shaman': -0.466 },
    { name: 'Lifestealer', 'Shadow Shaman': -0.006 },
    { name: 'Lina', 'Shadow Shaman': -0.585 },
    { name: 'Lion', 'Shadow Shaman': -0.503 },
    { name: 'Lone Druid', 'Shadow Shaman': 2.384 },
    { name: 'Luna', 'Shadow Shaman': 1.33 },
    { name: 'Lycan', 'Shadow Shaman': -1.004 },
    { name: 'Magnus', 'Shadow Shaman': 0.132 },
    { name: 'Marci', 'Shadow Shaman': -0.463 },
    { name: 'Mars', 'Shadow Shaman': 1.07 },
    { name: 'Medusa', 'Shadow Shaman': 2.261 },
    { name: 'Meepo', 'Shadow Shaman': 1.951 },
    { name: 'Mirana', 'Shadow Shaman': 0.503 },
    { name: 'Monkey King', 'Shadow Shaman': 0.502 },
    { name: 'Morphling', 'Shadow Shaman': -0.442 },
    { name: 'Muerta', 'Shadow Shaman': 0.694 },
    { name: 'Naga Siren', 'Shadow Shaman': 1.926 },
    { name: "Nature's Prophet", 'Shadow Shaman': 0.119 },
    { name: 'Necrophos', 'Shadow Shaman': 1.909 },
    { name: 'Night Stalker', 'Shadow Shaman': -0.657 },
    { name: 'Nyx Assassin', 'Shadow Shaman': -1.184 },
    { name: 'Ogre Magi', 'Shadow Shaman': -0.841 },
    { name: 'Omniknight', 'Shadow Shaman': 0.946 },
    { name: 'Oracle', 'Shadow Shaman': -1.284 },
    { name: 'Outworld Destroyer', 'Shadow Shaman': -1.546 },
    { name: 'Pangolier', 'Shadow Shaman': 0.127 },
    { name: 'Phantom Assassin', 'Shadow Shaman': -0.229 },
    { name: 'Phantom Lancer', 'Shadow Shaman': 4.287 },
    { name: 'Phoenix', 'Shadow Shaman': 0.283 },
    { name: 'Primal Beast', 'Shadow Shaman': -0.617 },
    { name: 'Puck', 'Shadow Shaman': -0.586 },
    { name: 'Pudge', 'Shadow Shaman': 1.271 },
    { name: 'Pugna', 'Shadow Shaman': -0.11 },
    { name: 'Queen of Pain', 'Shadow Shaman': -0.781 },
    { name: 'Razor', 'Shadow Shaman': 1.959 },
    { name: 'Riki', 'Shadow Shaman': 0.618 },
    { name: 'Ringmaster', 'Shadow Shaman': 0.341 },
    { name: 'Rubick', 'Shadow Shaman': 0.287 },
    { name: 'Sand King', 'Shadow Shaman': 1.986 },
    { name: 'Shadow Demon', 'Shadow Shaman': 0.121 },
    { name: 'Shadow Fiend', 'Shadow Shaman': -0.533 },
    { name: 'Silencer', 'Shadow Shaman': 3.036 },
    { name: 'Skywrath Mage', 'Shadow Shaman': -0.945 },
    { name: 'Slardar', 'Shadow Shaman': -1.456 },
    { name: 'Slark', 'Shadow Shaman': -0.075 },
    { name: 'Snapfire', 'Shadow Shaman': 1.08 },
    { name: 'Sniper', 'Shadow Shaman': 1.14 },
    { name: 'Spectre', 'Shadow Shaman': 2.832 },
    { name: 'Spirit Breaker', 'Shadow Shaman': 0.595 },
    { name: 'Storm Spirit', 'Shadow Shaman': -1.913 },
    { name: 'Sven', 'Shadow Shaman': -0.309 },
    { name: 'Techies', 'Shadow Shaman': 0.376 },
    { name: 'Templar Assassin', 'Shadow Shaman': -1.092 },
    { name: 'Terrorblade', 'Shadow Shaman': 1.786 },
    { name: 'Tidehunter', 'Shadow Shaman': 0.538 },
    { name: 'Timbersaw', 'Shadow Shaman': -0.16 },
    { name: 'Tinker', 'Shadow Shaman': -0.698 },
    { name: 'Tiny', 'Shadow Shaman': 1.221 },
    { name: 'Treant Protector', 'Shadow Shaman': 0.821 },
    { name: 'Troll Warlord', 'Shadow Shaman': -0.893 },
    { name: 'Tusk', 'Shadow Shaman': 0.423 },
    { name: 'Underlord', 'Shadow Shaman': 0.919 },
    { name: 'Undying', 'Shadow Shaman': 0.445 },
    { name: 'Ursa', 'Shadow Shaman': -2.389 },
    { name: 'Vengeful Spirit', 'Shadow Shaman': 0.498 },
    { name: 'Venomancer', 'Shadow Shaman': 0.44 },
    { name: 'Viper', 'Shadow Shaman': -0.817 },
    { name: 'Visage', 'Shadow Shaman': 0.275 },
    { name: 'Void Spirit', 'Shadow Shaman': -0.518 },
    { name: 'Warlock', 'Shadow Shaman': 1.768 },
    { name: 'Weaver', 'Shadow Shaman': -1.115 },
    { name: 'Windranger', 'Shadow Shaman': -0.475 },
    { name: 'Winter Wyvern', 'Shadow Shaman': 0.201 },
    { name: 'Witch Doctor', 'Shadow Shaman': 0.692 },
    { name: 'Wraith King', 'Shadow Shaman': 0.861 },
    { name: 'Zeus', 'Shadow Shaman': 0.206 },
],
"Silencer": [
    { name: 'Abaddon', 'Silencer': 2.091 },
    { name: 'Alchemist', 'Silencer': 1.034 },
    { name: 'Ancient Apparition', 'Silencer': -1.763 },
    { name: 'Anti-Mage', 'Silencer': 0.729 },
    { name: 'Arc Warden', 'Silencer': 2.348 },
    { name: 'Axe', 'Silencer': -1.199 },
    { name: 'Bane', 'Silencer': -2.699 },
    { name: 'Batrider', 'Silencer': -0.434 },
    { name: 'Beastmaster', 'Silencer': 0.335 },
    { name: 'Bloodseeker', 'Silencer': 0.529 },
    { name: 'Bounty Hunter', 'Silencer': 0.724 },
    { name: 'Brewmaster', 'Silencer': 2.72 },
    { name: 'Bristleback', 'Silencer': -1.921 },
    { name: 'Broodmother', 'Silencer': 2.448 },
    { name: 'Centaur Warrunner', 'Silencer': 1.342 },
    { name: 'Chaos Knight', 'Silencer': 1.521 },
    { name: 'Chen', 'Silencer': 2.209 },
    { name: 'Clinkz', 'Silencer': -0.592 },
    { name: 'Clockwerk', 'Silencer': 1.023 },
    { name: 'Crystal Maiden', 'Silencer': -0.483 },
    { name: 'Dark Seer', 'Silencer': 1.019 },
    { name: 'Dark Willow', 'Silencer': 0.502 },
    { name: 'Dawnbreaker', 'Silencer': -2.142 },
    { name: 'Dazzle', 'Silencer': -0.404 },
    { name: 'Death Prophet', 'Silencer': -1.369 },
    { name: 'Disruptor', 'Silencer': 0.478 },
    { name: 'Doom', 'Silencer': -0.265 },
    { name: 'Dragon Knight', 'Silencer': 1.377 },
    { name: 'Drow Ranger', 'Silencer': -0.65 },
    { name: 'Earth Spirit', 'Silencer': -1.209 },
    { name: 'Earthshaker', 'Silencer': -1.433 },
    { name: 'Elder Titan', 'Silencer': -0.859 },
    { name: 'Ember Spirit', 'Silencer': -0.558 },
    { name: 'Enchantress', 'Silencer': 2.216 },
    { name: 'Enigma', 'Silencer': -1.188 },
    { name: 'Faceless Void', 'Silencer': -1.718 },
    { name: 'Grimstroke', 'Silencer': -0.91 },
    { name: 'Gyrocopter', 'Silencer': -0.176 },
    { name: 'Hoodwink', 'Silencer': 0.617 },
    { name: 'Huskar', 'Silencer': 0.665 },
    { name: 'Invoker', 'Silencer': 0.934 },
    { name: 'Io', 'Silencer': -0.245 },
    { name: 'Jakiro', 'Silencer': 0.269 },
    { name: 'Juggernaut', 'Silencer': -0.818 },
    { name: 'Keeper of the Light', 'Silencer': 0.583 },
    { name: 'Kunkka', 'Silencer': 0.112 },
    { name: 'Legion Commander', 'Silencer': 1.734 },
    { name: 'Leshrac', 'Silencer': -1.236 },
    { name: 'Lich', 'Silencer': -0.718 },
    { name: 'Lifestealer', 'Silencer': -0.598 },
    { name: 'Lina', 'Silencer': -0.009 },
    { name: 'Lion', 'Silencer': -1.079 },
    { name: 'Lone Druid', 'Silencer': 3.314 },
    { name: 'Luna', 'Silencer': 0.615 },
    { name: 'Lycan', 'Silencer': 4.203 },
    { name: 'Magnus', 'Silencer': -0.431 },
    { name: 'Marci', 'Silencer': 0.088 },
    { name: 'Mars', 'Silencer': 0.507 },
    { name: 'Medusa', 'Silencer': 1.402 },
    { name: 'Meepo', 'Silencer': -1.101 },
    { name: 'Mirana', 'Silencer': 1.344 },
    { name: 'Monkey King', 'Silencer': -0.049 },
    { name: 'Morphling', 'Silencer': 1.856 },
    { name: 'Muerta', 'Silencer': 1.002 },
    { name: 'Naga Siren', 'Silencer': 2.706 },
    { name: "Nature's Prophet", 'Silencer': 1.069 },
    { name: 'Necrophos', 'Silencer': -2.41 },
    { name: 'Night Stalker', 'Silencer': 1.679 },
    { name: 'Nyx Assassin', 'Silencer': 0.368 },
    { name: 'Ogre Magi', 'Silencer': 1.379 },
    { name: 'Omniknight', 'Silencer': -1.03 },
    { name: 'Oracle', 'Silencer': -0.402 },
    { name: 'Outworld Destroyer', 'Silencer': -1.018 },
    { name: 'Pangolier', 'Silencer': -0.308 },
    { name: 'Phantom Assassin', 'Silencer': 1.284 },
    { name: 'Phantom Lancer', 'Silencer': 2.329 },
    { name: 'Phoenix', 'Silencer': -0.164 },
    { name: 'Primal Beast', 'Silencer': -0.683 },
    { name: 'Puck', 'Silencer': -2.361 },
    { name: 'Pudge', 'Silencer': -0.876 },
    { name: 'Pugna', 'Silencer': -1.88 },
    { name: 'Queen of Pain', 'Silencer': -0.871 },
    { name: 'Razor', 'Silencer': 1.35 },
    { name: 'Riki', 'Silencer': -1.188 },
    { name: 'Ringmaster', 'Silencer': 0.119 },
    { name: 'Rubick', 'Silencer': 0.726 },
    { name: 'Sand King', 'Silencer': -1.351 },
    { name: 'Shadow Demon', 'Silencer': -0.166 },
    { name: 'Shadow Fiend', 'Silencer': 0.749 },
    { name: 'Shadow Shaman', 'Silencer': -3.131 },
    { name: 'Skywrath Mage', 'Silencer': -1.166 },
    { name: 'Slardar', 'Silencer': -0.82 },
    { name: 'Slark', 'Silencer': 1.917 },
    { name: 'Snapfire', 'Silencer': -0.077 },
    { name: 'Sniper', 'Silencer': 2.002 },
    { name: 'Spectre', 'Silencer': 2.716 },
    { name: 'Spirit Breaker', 'Silencer': 1.445 },
    { name: 'Storm Spirit', 'Silencer': -1.839 },
    { name: 'Sven', 'Silencer': 0.05 },
    { name: 'Techies', 'Silencer': 0.42 },
    { name: 'Templar Assassin', 'Silencer': 0.004 },
    { name: 'Terrorblade', 'Silencer': 1.917 },
    { name: 'Tidehunter', 'Silencer': 2.475 },
    { name: 'Timbersaw', 'Silencer': -4.181 },
    { name: 'Tinker', 'Silencer': -0.749 },
    { name: 'Tiny', 'Silencer': 0.074 },
    { name: 'Treant Protector', 'Silencer': -0.314 },
    { name: 'Troll Warlord', 'Silencer': -0.347 },
    { name: 'Tusk', 'Silencer': 0.617 },
    { name: 'Underlord', 'Silencer': 0.609 },
    { name: 'Undying', 'Silencer': -0.99 },
    { name: 'Ursa', 'Silencer': -0.929 },
    { name: 'Vengeful Spirit', 'Silencer': -0.068 },
    { name: 'Venomancer', 'Silencer': 1.326 },
    { name: 'Viper', 'Silencer': 0.625 },
    { name: 'Visage', 'Silencer': 0.068 },
    { name: 'Void Spirit', 'Silencer': 0.383 },
    { name: 'Warlock', 'Silencer': -0.243 },
    { name: 'Weaver', 'Silencer': -1.133 },
    { name: 'Windranger', 'Silencer': 1.042 },
    { name: 'Winter Wyvern', 'Silencer': -0.484 },
    { name: 'Witch Doctor', 'Silencer': -1.721 },
    { name: 'Wraith King', 'Silencer': 2.355 },
    { name: 'Zeus', 'Silencer': -0.222 },
],
"Skywrath Mage": [
    { name: 'Abaddon', 'Skywrath Mage': 2.338 },
    { name: 'Alchemist', 'Skywrath Mage': -0.442 },
    { name: 'Ancient Apparition', 'Skywrath Mage': -1.098 },
    { name: 'Anti-Mage', 'Skywrath Mage': 1.558 },
    { name: 'Arc Warden', 'Skywrath Mage': 0.611 },
    { name: 'Axe', 'Skywrath Mage': -1.094 },
    { name: 'Bane', 'Skywrath Mage': 0.287 },
    { name: 'Batrider', 'Skywrath Mage': -0.17 },
    { name: 'Beastmaster', 'Skywrath Mage': 1.593 },
    { name: 'Bloodseeker', 'Skywrath Mage': -1.587 },
    { name: 'Bounty Hunter', 'Skywrath Mage': -0.436 },
    { name: 'Brewmaster', 'Skywrath Mage': 0.776 },
    { name: 'Bristleback', 'Skywrath Mage': 1.493 },
    { name: 'Broodmother', 'Skywrath Mage': 1.949 },
    { name: 'Centaur Warrunner', 'Skywrath Mage': 0.484 },
    { name: 'Chaos Knight', 'Skywrath Mage': 2.245 },
    { name: 'Chen', 'Skywrath Mage': 1.131 },
    { name: 'Clinkz', 'Skywrath Mage': 0.416 },
    { name: 'Clockwerk', 'Skywrath Mage': -0.36 },
    { name: 'Crystal Maiden', 'Skywrath Mage': 0.042 },
    { name: 'Dark Seer', 'Skywrath Mage': 0.027 },
    { name: 'Dark Willow', 'Skywrath Mage': 0.1 },
    { name: 'Dawnbreaker', 'Skywrath Mage': 0.331 },
    { name: 'Dazzle', 'Skywrath Mage': 0.975 },
    { name: 'Death Prophet', 'Skywrath Mage': 0.554 },
    { name: 'Disruptor', 'Skywrath Mage': 0.163 },
    { name: 'Doom', 'Skywrath Mage': -0.621 },
    { name: 'Dragon Knight', 'Skywrath Mage': 1.568 },
    { name: 'Drow Ranger', 'Skywrath Mage': -0.406 },
    { name: 'Earth Spirit', 'Skywrath Mage': -0.742 },
    { name: 'Earthshaker', 'Skywrath Mage': -0.638 },
    { name: 'Elder Titan', 'Skywrath Mage': -1.076 },
    { name: 'Ember Spirit', 'Skywrath Mage': -1.157 },
    { name: 'Enchantress', 'Skywrath Mage': -0.207 },
    { name: 'Enigma', 'Skywrath Mage': -0.729 },
    { name: 'Faceless Void', 'Skywrath Mage': -1.389 },
    { name: 'Grimstroke', 'Skywrath Mage': 0.611 },
    { name: 'Gyrocopter', 'Skywrath Mage': -0.359 },
    { name: 'Hoodwink', 'Skywrath Mage': -0.196 },
    { name: 'Huskar', 'Skywrath Mage': 0.703 },
    { name: 'Invoker', 'Skywrath Mage': -0.094 },
    { name: 'Io', 'Skywrath Mage': 1.931 },
    { name: 'Jakiro', 'Skywrath Mage': -0.017 },
    { name: 'Juggernaut', 'Skywrath Mage': -2.019 },
    { name: 'Keeper of the Light', 'Skywrath Mage': -0.724 },
    { name: 'Kunkka', 'Skywrath Mage': 0.358 },
    { name: 'Legion Commander', 'Skywrath Mage': 0.174 },
    { name: 'Leshrac', 'Skywrath Mage': -1.033 },
    { name: 'Lich', 'Skywrath Mage': 0.149 },
    { name: 'Lifestealer', 'Skywrath Mage': -0.771 },
    { name: 'Lina', 'Skywrath Mage': 0.251 },
    { name: 'Lion', 'Skywrath Mage': 0.233 },
    { name: 'Lone Druid', 'Skywrath Mage': 2.725 },
    { name: 'Luna', 'Skywrath Mage': -0.355 },
    { name: 'Lycan', 'Skywrath Mage': 3.51 },
    { name: 'Magnus', 'Skywrath Mage': 0.593 },
    { name: 'Marci', 'Skywrath Mage': 0.93 },
    { name: 'Mars', 'Skywrath Mage': 0.931 },
    { name: 'Medusa', 'Skywrath Mage': 3.011 },
    { name: 'Meepo', 'Skywrath Mage': 0.854 },
    { name: 'Mirana', 'Skywrath Mage': 0.66 },
    { name: 'Monkey King', 'Skywrath Mage': -0.38 },
    { name: 'Morphling', 'Skywrath Mage': -0.008 },
    { name: 'Muerta', 'Skywrath Mage': -1.722 },
    { name: 'Naga Siren', 'Skywrath Mage': 0.683 },
    { name: "Nature's Prophet", 'Skywrath Mage': 0.059 },
    { name: 'Necrophos', 'Skywrath Mage': -4.159 },
    { name: 'Night Stalker', 'Skywrath Mage': 1.458 },
    { name: 'Nyx Assassin', 'Skywrath Mage': -0.613 },
    { name: 'Ogre Magi', 'Skywrath Mage': -0.114 },
    { name: 'Omniknight', 'Skywrath Mage': 0.382 },
    { name: 'Oracle', 'Skywrath Mage': 1.575 },
    { name: 'Outworld Destroyer', 'Skywrath Mage': -0.748 },
    { name: 'Pangolier', 'Skywrath Mage': 0.741 },
    { name: 'Phantom Assassin', 'Skywrath Mage': 0.477 },
    { name: 'Phantom Lancer', 'Skywrath Mage': 0.689 },
    { name: 'Phoenix', 'Skywrath Mage': -1.846 },
    { name: 'Primal Beast', 'Skywrath Mage': -0.562 },
    { name: 'Puck', 'Skywrath Mage': -0.166 },
    { name: 'Pudge', 'Skywrath Mage': -0.664 },
    { name: 'Pugna', 'Skywrath Mage': -0.014 },
    { name: 'Queen of Pain', 'Skywrath Mage': -1.322 },
    { name: 'Razor', 'Skywrath Mage': 0.889 },
    { name: 'Riki', 'Skywrath Mage': 0.733 },
    { name: 'Ringmaster', 'Skywrath Mage': -0.119 },
    { name: 'Rubick', 'Skywrath Mage': -0.774 },
    { name: 'Sand King', 'Skywrath Mage': -0.978 },
    { name: 'Shadow Demon', 'Skywrath Mage': 1.673 },
    { name: 'Shadow Fiend', 'Skywrath Mage': 0.15 },
    { name: 'Shadow Shaman', 'Skywrath Mage': 1.012 },
    { name: 'Silencer', 'Skywrath Mage': 1.208 },
    { name: 'Slardar', 'Skywrath Mage': 0.898 },
    { name: 'Slark', 'Skywrath Mage': -0.571 },
    { name: 'Snapfire', 'Skywrath Mage': 0.35 },
    { name: 'Sniper', 'Skywrath Mage': -0.133 },
    { name: 'Spectre', 'Skywrath Mage': -0.442 },
    { name: 'Spirit Breaker', 'Skywrath Mage': 1.07 },
    { name: 'Storm Spirit', 'Skywrath Mage': -1.192 },
    { name: 'Sven', 'Skywrath Mage': 1.032 },
    { name: 'Techies', 'Skywrath Mage': 0.141 },
    { name: 'Templar Assassin', 'Skywrath Mage': 2.399 },
    { name: 'Terrorblade', 'Skywrath Mage': -0.297 },
    { name: 'Tidehunter', 'Skywrath Mage': 2.104 },
    { name: 'Timbersaw', 'Skywrath Mage': -2.702 },
    { name: 'Tinker', 'Skywrath Mage': -0.702 },
    { name: 'Tiny', 'Skywrath Mage': 0.176 },
    { name: 'Treant Protector', 'Skywrath Mage': -0.395 },
    { name: 'Troll Warlord', 'Skywrath Mage': -0.809 },
    { name: 'Tusk', 'Skywrath Mage': 1.682 },
    { name: 'Underlord', 'Skywrath Mage': -0.175 },
    { name: 'Undying', 'Skywrath Mage': -0.262 },
    { name: 'Ursa', 'Skywrath Mage': -0.05 },
    { name: 'Vengeful Spirit', 'Skywrath Mage': 1.095 },
    { name: 'Venomancer', 'Skywrath Mage': 0.111 },
    { name: 'Viper', 'Skywrath Mage': -0.287 },
    { name: 'Visage', 'Skywrath Mage': 1.02 },
    { name: 'Void Spirit', 'Skywrath Mage': 0.883 },
    { name: 'Warlock', 'Skywrath Mage': 0.905 },
    { name: 'Weaver', 'Skywrath Mage': -0.548 },
    { name: 'Windranger', 'Skywrath Mage': 0.266 },
    { name: 'Winter Wyvern', 'Skywrath Mage': -0.584 },
    { name: 'Witch Doctor', 'Skywrath Mage': -0.82 },
    { name: 'Wraith King', 'Skywrath Mage': 2.583 },
    { name: 'Zeus', 'Skywrath Mage': -1.074 },
],
"Storm Spirit": [
    { name: 'Abaddon', 'Storm Spirit': 1.069 },
    { name: 'Alchemist', 'Storm Spirit': -0.429 },
    { name: 'Ancient Apparition', 'Storm Spirit': -3.452 },
    { name: 'Anti-Mage', 'Storm Spirit': 4.184 },
    { name: 'Arc Warden', 'Storm Spirit': 1.15 },
    { name: 'Axe', 'Storm Spirit': 1.517 },
    { name: 'Bane', 'Storm Spirit': 2.261 },
    { name: 'Batrider', 'Storm Spirit': -1.171 },
    { name: 'Beastmaster', 'Storm Spirit': 0.986 },
    { name: 'Bloodseeker', 'Storm Spirit': -2.882 },
    { name: 'Bounty Hunter', 'Storm Spirit': -1.969 },
    { name: 'Brewmaster', 'Storm Spirit': -0.766 },
    { name: 'Bristleback', 'Storm Spirit': -2.198 },
    { name: 'Broodmother', 'Storm Spirit': -0.254 },
    { name: 'Centaur Warrunner', 'Storm Spirit': 0.483 },
    { name: 'Chaos Knight', 'Storm Spirit': 0.388 },
    { name: 'Chen', 'Storm Spirit': 0.356 },
    { name: 'Clinkz', 'Storm Spirit': 0.108 },
    { name: 'Clockwerk', 'Storm Spirit': -1.582 },
    { name: 'Crystal Maiden', 'Storm Spirit': 1.698 },
    { name: 'Dark Seer', 'Storm Spirit': 0.173 },
    { name: 'Dark Willow', 'Storm Spirit': 0.492 },
    { name: 'Dawnbreaker', 'Storm Spirit': 0.731 },
    { name: 'Dazzle', 'Storm Spirit': 1.164 },
    { name: 'Death Prophet', 'Storm Spirit': 1.34 },
    { name: 'Disruptor', 'Storm Spirit': 1.601 },
    { name: 'Doom', 'Storm Spirit': 0.0 },
    { name: 'Dragon Knight', 'Storm Spirit': 0.612 },
    { name: 'Drow Ranger', 'Storm Spirit': -1.554 },
    { name: 'Earth Spirit', 'Storm Spirit': 0.441 },
    { name: 'Earthshaker', 'Storm Spirit': 0.46 },
    { name: 'Elder Titan', 'Storm Spirit': -1.704 },
    { name: 'Ember Spirit', 'Storm Spirit': 2.16 },
    { name: 'Enchantress', 'Storm Spirit': -0.259 },
    { name: 'Enigma', 'Storm Spirit': 1.904 },
    { name: 'Faceless Void', 'Storm Spirit': -0.522 },
    { name: 'Grimstroke', 'Storm Spirit': 1.538 },
    { name: 'Gyrocopter', 'Storm Spirit': -0.116 },
    { name: 'Hoodwink', 'Storm Spirit': -0.149 },
    { name: 'Huskar', 'Storm Spirit': 1.835 },
    { name: 'Invoker', 'Storm Spirit': 0.264 },
    { name: 'Io', 'Storm Spirit': 1.461 },
    { name: 'Jakiro', 'Storm Spirit': -0.594 },
    { name: 'Juggernaut', 'Storm Spirit': -1.234 },
    { name: 'Keeper of the Light', 'Storm Spirit': -2.317 },
    { name: 'Kunkka', 'Storm Spirit': -0.22 },
    { name: 'Legion Commander', 'Storm Spirit': 1.044 },
    { name: 'Leshrac', 'Storm Spirit': 0.651 },
    { name: 'Lich', 'Storm Spirit': -0.249 },
    { name: 'Lifestealer', 'Storm Spirit': -1.487 },
    { name: 'Lina', 'Storm Spirit': -0.169 },
    { name: 'Lion', 'Storm Spirit': 1.632 },
    { name: 'Lone Druid', 'Storm Spirit': 3.599 },
    { name: 'Luna', 'Storm Spirit': 0.114 },
    { name: 'Lycan', 'Storm Spirit': -0.316 },
    { name: 'Magnus', 'Storm Spirit': -0.453 },
    { name: 'Marci', 'Storm Spirit': 0.426 },
    { name: 'Mars', 'Storm Spirit': -1.84 },
    { name: 'Medusa', 'Storm Spirit': 0.986 },
    { name: 'Meepo', 'Storm Spirit': 5.05 },
    { name: 'Mirana', 'Storm Spirit': -0.925 },
    { name: 'Monkey King', 'Storm Spirit': -0.777 },
    { name: 'Morphling', 'Storm Spirit': 0.281 },
    { name: 'Muerta', 'Storm Spirit': 1.22 },
    { name: 'Naga Siren', 'Storm Spirit': 1.917 },
    { name: "Nature's Prophet", 'Storm Spirit': -0.208 },
    { name: 'Necrophos', 'Storm Spirit': 0.103 },
    { name: 'Night Stalker', 'Storm Spirit': 2.231 },
    { name: 'Nyx Assassin', 'Storm Spirit': 1.332 },
    { name: 'Ogre Magi', 'Storm Spirit': -0.739 },
    { name: 'Omniknight', 'Storm Spirit': 1.613 },
    { name: 'Oracle', 'Storm Spirit': 2.187 },
    { name: 'Outworld Destroyer', 'Storm Spirit': -1.332 },
    { name: 'Pangolier', 'Storm Spirit': -1.352 },
    { name: 'Phantom Assassin', 'Storm Spirit': -0.981 },
    { name: 'Phantom Lancer', 'Storm Spirit': -1.551 },
    { name: 'Phoenix', 'Storm Spirit': -0.818 },
    { name: 'Primal Beast', 'Storm Spirit': -0.637 },
    { name: 'Puck', 'Storm Spirit': 2.017 },
    { name: 'Pudge', 'Storm Spirit': -1.044 },
    { name: 'Pugna', 'Storm Spirit': 2.74 },
    { name: 'Queen of Pain', 'Storm Spirit': -0.318 },
    { name: 'Razor', 'Storm Spirit': -1.758 },
    { name: 'Riki', 'Storm Spirit': 3.51 },
    { name: 'Ringmaster', 'Storm Spirit': -0.609 },
    { name: 'Rubick', 'Storm Spirit': -1.098 },
    { name: 'Sand King', 'Storm Spirit': -1.911 },
    { name: 'Shadow Demon', 'Storm Spirit': -0.383 },
    { name: 'Shadow Fiend', 'Storm Spirit': 1.276 },
    { name: 'Shadow Shaman', 'Storm Spirit': 2.11 },
    { name: 'Silencer', 'Storm Spirit': 1.941 },
    { name: 'Skywrath Mage', 'Storm Spirit': 1.206 },
    { name: 'Slardar', 'Storm Spirit': -0.132 },
    { name: 'Slark', 'Storm Spirit': 1.631 },
    { name: 'Snapfire', 'Storm Spirit': -0.548 },
    { name: 'Sniper', 'Storm Spirit': -3.56 },
    { name: 'Spectre', 'Storm Spirit': 0.449 },
    { name: 'Spirit Breaker', 'Storm Spirit': 0.409 },
    { name: 'Sven', 'Storm Spirit': 0.168 },
    { name: 'Techies', 'Storm Spirit': -0.041 },
    { name: 'Templar Assassin', 'Storm Spirit': -0.388 },
    { name: 'Terrorblade', 'Storm Spirit': 0.609 },
    { name: 'Tidehunter', 'Storm Spirit': 1.176 },
    { name: 'Timbersaw', 'Storm Spirit': -1.236 },
    { name: 'Tinker', 'Storm Spirit': 0.816 },
    { name: 'Tiny', 'Storm Spirit': 0.822 },
    { name: 'Treant Protector', 'Storm Spirit': 0.614 },
    { name: 'Troll Warlord', 'Storm Spirit': 2.143 },
    { name: 'Tusk', 'Storm Spirit': -0.088 },
    { name: 'Underlord', 'Storm Spirit': 0.521 },
    { name: 'Undying', 'Storm Spirit': -0.156 },
    { name: 'Ursa', 'Storm Spirit': -0.516 },
    { name: 'Vengeful Spirit', 'Storm Spirit': -0.494 },
    { name: 'Venomancer', 'Storm Spirit': -2.42 },
    { name: 'Viper', 'Storm Spirit': -1.045 },
    { name: 'Visage', 'Storm Spirit': -0.033 },
    { name: 'Void Spirit', 'Storm Spirit': 0.082 },
    { name: 'Warlock', 'Storm Spirit': -1.962 },
    { name: 'Weaver', 'Storm Spirit': -0.414 },
    { name: 'Windranger', 'Storm Spirit': 0.663 },
    { name: 'Winter Wyvern', 'Storm Spirit': -1.215 },
    { name: 'Witch Doctor', 'Storm Spirit': 0.401 },
    { name: 'Wraith King', 'Storm Spirit': 0.459 },
    { name: 'Zeus', 'Storm Spirit': -2.92 },
],
"Tinker": [
    { name: 'Abaddon', 'Tinker': -2.018 },
    { name: 'Alchemist', 'Tinker': 1.694 },
    { name: 'Ancient Apparition', 'Tinker': 1.022 },
    { name: 'Anti-Mage', 'Tinker': 2.544 },
    { name: 'Arc Warden', 'Tinker': 1.187 },
    { name: 'Axe', 'Tinker': -1.245 },
    { name: 'Bane', 'Tinker': 0.735 },
    { name: 'Batrider', 'Tinker': 2.343 },
    { name: 'Beastmaster', 'Tinker': 0.502 },
    { name: 'Bloodseeker', 'Tinker': -1.435 },
    { name: 'Bounty Hunter', 'Tinker': 0.386 },
    { name: 'Brewmaster', 'Tinker': -1.87 },
    { name: 'Bristleback', 'Tinker': -0.034 },
    { name: 'Broodmother', 'Tinker': 1.456 },
    { name: 'Centaur Warrunner', 'Tinker': -2.059 },
    { name: 'Chaos Knight', 'Tinker': 1.653 },
    { name: 'Chen', 'Tinker': 3.734 },
    { name: 'Clinkz', 'Tinker': 1.218 },
    { name: 'Clockwerk', 'Tinker': -1.232 },
    { name: 'Crystal Maiden', 'Tinker': 0.781 },
    { name: 'Dark Seer', 'Tinker': -0.106 },
    { name: 'Dark Willow', 'Tinker': 0.118 },
    { name: 'Dawnbreaker', 'Tinker': 0.527 },
    { name: 'Dazzle', 'Tinker': 0.585 },
    { name: 'Death Prophet', 'Tinker': 1.422 },
    { name: 'Disruptor', 'Tinker': -0.735 },
    { name: 'Doom', 'Tinker': 1.505 },
    { name: 'Dragon Knight', 'Tinker': -0.1 },
    { name: 'Drow Ranger', 'Tinker': 1.787 },
    { name: 'Earth Spirit', 'Tinker': -0.398 },
    { name: 'Earthshaker', 'Tinker': 1.407 },
    { name: 'Elder Titan', 'Tinker': 2.633 },
    { name: 'Ember Spirit', 'Tinker': -2.325 },
    { name: 'Enchantress', 'Tinker': 1.285 },
    { name: 'Enigma', 'Tinker': 2.613 },
    { name: 'Faceless Void', 'Tinker': 1.394 },
    { name: 'Grimstroke', 'Tinker': 0.195 },
    { name: 'Gyrocopter', 'Tinker': -0.591 },
    { name: 'Hoodwink', 'Tinker': -0.191 },
    { name: 'Huskar', 'Tinker': 1.752 },
    { name: 'Invoker', 'Tinker': 1.681 },
    { name: 'Io', 'Tinker': 2.701 },
    { name: 'Jakiro', 'Tinker': -0.734 },
    { name: 'Juggernaut', 'Tinker': 0.604 },
    { name: 'Keeper of the Light', 'Tinker': 0.996 },
    { name: 'Kunkka', 'Tinker': -1.516 },
    { name: 'Legion Commander', 'Tinker': -0.419 },
    { name: 'Leshrac', 'Tinker': 1.44 },
    { name: 'Lich', 'Tinker': 0.412 },
    { name: 'Lifestealer', 'Tinker': -0.259 },
    { name: 'Lina', 'Tinker': 2.686 },
    { name: 'Lion', 'Tinker': 0.874 },
    { name: 'Lone Druid', 'Tinker': 1.13 },
    { name: 'Luna', 'Tinker': 0.886 },
    { name: 'Lycan', 'Tinker': 0.594 },
    { name: 'Magnus', 'Tinker': 1.73 },
    { name: 'Marci', 'Tinker': 3.029 },
    { name: 'Mars', 'Tinker': -1.09 },
    { name: 'Medusa', 'Tinker': -1.553 },
    { name: 'Meepo', 'Tinker': 3.072 },
    { name: 'Mirana', 'Tinker': 0.186 },
    { name: 'Monkey King', 'Tinker': 1.327 },
    { name: 'Morphling', 'Tinker': 0.54 },
    { name: 'Muerta', 'Tinker': 0.496 },
    { name: 'Naga Siren', 'Tinker': 1.996 },
    { name: "Nature's Prophet", 'Tinker': 0.008 },
    { name: 'Necrophos', 'Tinker': 0.133 },
    { name: 'Night Stalker', 'Tinker': -0.127 },
    { name: 'Nyx Assassin', 'Tinker': 0.595 },
    { name: 'Ogre Magi', 'Tinker': 0.254 },
    { name: 'Omniknight', 'Tinker': 0.465 },
    { name: 'Oracle', 'Tinker': -0.704 },
    { name: 'Outworld Destroyer', 'Tinker': 0.782 },
    { name: 'Pangolier', 'Tinker': -0.041 },
    { name: 'Phantom Assassin', 'Tinker': 0.856 },
    { name: 'Phantom Lancer', 'Tinker': 0.279 },
    { name: 'Phoenix', 'Tinker': -1.857 },
    { name: 'Primal Beast', 'Tinker': 1.314 },
    { name: 'Puck', 'Tinker': -1.929 },
    { name: 'Pudge', 'Tinker': 1.835 },
    { name: 'Pugna', 'Tinker': 1.121 },
    { name: 'Queen of Pain', 'Tinker': -1.169 },
    { name: 'Razor', 'Tinker': -0.668 },
    { name: 'Riki', 'Tinker': -0.692 },
    { name: 'Ringmaster', 'Tinker': 1.314 },
    { name: 'Rubick', 'Tinker': -0.409 },
    { name: 'Sand King', 'Tinker': -0.991 },
    { name: 'Shadow Demon', 'Tinker': 0.805 },
    { name: 'Shadow Fiend', 'Tinker': 2.224 },
    { name: 'Shadow Shaman', 'Tinker': 0.611 },
    { name: 'Silencer', 'Tinker': 0.719 },
    { name: 'Skywrath Mage', 'Tinker': 0.681 },
    { name: 'Slardar', 'Tinker': 1.674 },
    { name: 'Slark', 'Tinker': -0.37 },
    { name: 'Snapfire', 'Tinker': 0.903 },
    { name: 'Sniper', 'Tinker': 1.397 },
    { name: 'Spectre', 'Tinker': -3.262 },
    { name: 'Spirit Breaker', 'Tinker': -0.422 },
    { name: 'Storm Spirit', 'Tinker': -0.814 },
    { name: 'Sven', 'Tinker': 1.47 },
    { name: 'Techies', 'Tinker': 0.282 },
    { name: 'Templar Assassin', 'Tinker': 1.025 },
    { name: 'Terrorblade', 'Tinker': 0.142 },
    { name: 'Tidehunter', 'Tinker': -0.929 },
    { name: 'Timbersaw', 'Tinker': -0.859 },
    { name: 'Tiny', 'Tinker': -0.227 },
    { name: 'Treant Protector', 'Tinker': -0.226 },
    { name: 'Troll Warlord', 'Tinker': -0.416 },
    { name: 'Tusk', 'Tinker': 1.016 },
    { name: 'Underlord', 'Tinker': -1.061 },
    { name: 'Undying', 'Tinker': -0.746 },
    { name: 'Ursa', 'Tinker': 0.2 },
    { name: 'Vengeful Spirit', 'Tinker': 0.093 },
    { name: 'Venomancer', 'Tinker': -1.534 },
    { name: 'Viper', 'Tinker': 0.519 },
    { name: 'Visage', 'Tinker': 1.029 },
    { name: 'Void Spirit', 'Tinker': -1.939 },
    { name: 'Warlock', 'Tinker': -1.647 },
    { name: 'Weaver', 'Tinker': -0.902 },
    { name: 'Windranger', 'Tinker': 0.658 },
    { name: 'Winter Wyvern', 'Tinker': 0.393 },
    { name: 'Witch Doctor', 'Tinker': 0.888 },
    { name: 'Wraith King', 'Tinker': -2.994 },
    { name: 'Zeus', 'Tinker': -0.916 },
],
"Warlock": [
    { name: 'Abaddon', 'Warlock': 0.094 },
    { name: 'Alchemist', 'Warlock': 0.068 },
    { name: 'Ancient Apparition', 'Warlock': 0.712 },
    { name: 'Anti-Mage', 'Warlock': 1.148 },
    { name: 'Arc Warden', 'Warlock': -3.458 },
    { name: 'Axe', 'Warlock': -2.015 },
    { name: 'Bane', 'Warlock': -1.01 },
    { name: 'Batrider', 'Warlock': -0.002 },
    { name: 'Beastmaster', 'Warlock': -1.621 },
    { name: 'Bloodseeker', 'Warlock': 0.291 },
    { name: 'Bounty Hunter', 'Warlock': 1.511 },
    { name: 'Brewmaster', 'Warlock': 1.124 },
    { name: 'Bristleback', 'Warlock': 2.684 },
    { name: 'Broodmother', 'Warlock': -0.677 },
    { name: 'Centaur Warrunner', 'Warlock': -1.108 },
    { name: 'Chaos Knight', 'Warlock': -4.666 },
    { name: 'Chen', 'Warlock': -0.41 },
    { name: 'Clinkz', 'Warlock': 0.806 },
    { name: 'Clockwerk', 'Warlock': -0.345 },
    { name: 'Crystal Maiden', 'Warlock': 0.164 },
    { name: 'Dark Seer', 'Warlock': -0.053 },
    { name: 'Dark Willow', 'Warlock': 0.008 },
    { name: 'Dawnbreaker', 'Warlock': 0.571 },
    { name: 'Dazzle', 'Warlock': -0.291 },
    { name: 'Death Prophet', 'Warlock': -0.51 },
    { name: 'Disruptor', 'Warlock': 0.12 },
    { name: 'Doom', 'Warlock': 0.23 },
    { name: 'Dragon Knight', 'Warlock': -0.554 },
    { name: 'Drow Ranger', 'Warlock': -0.092 },
    { name: 'Earth Spirit', 'Warlock': 1.312 },
    { name: 'Earthshaker', 'Warlock': 1.516 },
    { name: 'Elder Titan', 'Warlock': 0.91 },
    { name: 'Ember Spirit', 'Warlock': 1.221 },
    { name: 'Enchantress', 'Warlock': 0.218 },
    { name: 'Enigma', 'Warlock': -3.522 },
    { name: 'Faceless Void', 'Warlock': 1.255 },
    { name: 'Grimstroke', 'Warlock': -0.616 },
    { name: 'Gyrocopter', 'Warlock': 0.064 },
    { name: 'Hoodwink', 'Warlock': 0.694 },
    { name: 'Huskar', 'Warlock': 0.064 },
    { name: 'Invoker', 'Warlock': 0.507 },
    { name: 'Io', 'Warlock': -0.551 },
    { name: 'Jakiro', 'Warlock': 1.375 },
    { name: 'Juggernaut', 'Warlock': -0.103 },
    { name: 'Keeper of the Light', 'Warlock': 2.046 },
    { name: 'Kunkka', 'Warlock': -0.478 },
    { name: 'Legion Commander', 'Warlock': 0.254 },
    { name: 'Leshrac', 'Warlock': -0.057 },
    { name: 'Lich', 'Warlock': -0.424 },
    { name: 'Lifestealer', 'Warlock': 0.857 },
    { name: 'Lina', 'Warlock': 1.629 },
    { name: 'Lion', 'Warlock': -0.699 },
    { name: 'Lone Druid', 'Warlock': -2.584 },
    { name: 'Luna', 'Warlock': -1.373 },
    { name: 'Lycan', 'Warlock': 1.916 },
    { name: 'Magnus', 'Warlock': 0.592 },
    { name: 'Marci', 'Warlock': 0.708 },
    { name: 'Mars', 'Warlock': -0.374 },
    { name: 'Medusa', 'Warlock': -1.496 },
    { name: 'Meepo', 'Warlock': -2.88 },
    { name: 'Mirana', 'Warlock': 1.381 },
    { name: 'Monkey King', 'Warlock': 0.926 },
    { name: 'Morphling', 'Warlock': 1.752 },
    { name: 'Muerta', 'Warlock': 0.317 },
    { name: 'Naga Siren', 'Warlock': -2.975 },
    { name: "Nature's Prophet", 'Warlock': 1.402 },
    { name: 'Necrophos', 'Warlock': -1.293 },
    { name: 'Night Stalker', 'Warlock': 0.597 },
    { name: 'Nyx Assassin', 'Warlock': 1.787 },
    { name: 'Ogre Magi', 'Warlock': -2.098 },
    { name: 'Omniknight', 'Warlock': -1.225 },
    { name: 'Oracle', 'Warlock': 0.647 },
    { name: 'Outworld Destroyer', 'Warlock': 0.126 },
    { name: 'Pangolier', 'Warlock': 2.166 },
    { name: 'Phantom Assassin', 'Warlock': 2.227 },
    { name: 'Phantom Lancer', 'Warlock': -5.509 },
    { name: 'Phoenix', 'Warlock': 2.686 },
    { name: 'Primal Beast', 'Warlock': 1.017 },
    { name: 'Puck', 'Warlock': 0.485 },
    { name: 'Pudge', 'Warlock': -0.099 },
    { name: 'Pugna', 'Warlock': -0.394 },
    { name: 'Queen of Pain', 'Warlock': 0.637 },
    { name: 'Razor', 'Warlock': -0.391 },
    { name: 'Riki', 'Warlock': -0.236 },
    { name: 'Ringmaster', 'Warlock': -0.043 },
    { name: 'Rubick', 'Warlock': 0.02 },
    { name: 'Sand King', 'Warlock': 2.31 },
    { name: 'Shadow Demon', 'Warlock': -1.334 },
    { name: 'Shadow Fiend', 'Warlock': 1.994 },
    { name: 'Shadow Shaman', 'Warlock': -1.925 },
    { name: 'Silencer', 'Warlock': 0.242 },
    { name: 'Skywrath Mage', 'Warlock': -0.893 },
    { name: 'Slardar', 'Warlock': 1.375 },
    { name: 'Slark', 'Warlock': 1.259 },
    { name: 'Snapfire', 'Warlock': 1.768 },
    { name: 'Sniper', 'Warlock': 1.203 },
    { name: 'Spectre', 'Warlock': -1.883 },
    { name: 'Spirit Breaker', 'Warlock': -0.165 },
    { name: 'Storm Spirit', 'Warlock': 1.993 },
    { name: 'Sven', 'Warlock': 1.074 },
    { name: 'Techies', 'Warlock': -0.145 },
    { name: 'Templar Assassin', 'Warlock': 0.02 },
    { name: 'Terrorblade', 'Warlock': -4.391 },
    { name: 'Tidehunter', 'Warlock': 2.256 },
    { name: 'Timbersaw', 'Warlock': 1.089 },
    { name: 'Tinker', 'Warlock': 1.608 },
    { name: 'Tiny', 'Warlock': -1.06 },
    { name: 'Treant Protector', 'Warlock': 0.205 },
    { name: 'Troll Warlord', 'Warlock': 0.518 },
    { name: 'Tusk', 'Warlock': 0.947 },
    { name: 'Underlord', 'Warlock': 0.281 },
    { name: 'Undying', 'Warlock': -0.232 },
    { name: 'Ursa', 'Warlock': 1.307 },
    { name: 'Vengeful Spirit', 'Warlock': -0.972 },
    { name: 'Venomancer', 'Warlock': 0.37 },
    { name: 'Viper', 'Warlock': -1.054 },
    { name: 'Visage', 'Warlock': -0.754 },
    { name: 'Void Spirit', 'Warlock': 0.384 },
    { name: 'Weaver', 'Warlock': 2.423 },
    { name: 'Windranger', 'Warlock': 1.545 },
    { name: 'Winter Wyvern', 'Warlock': -0.625 },
    { name: 'Witch Doctor', 'Warlock': -0.1 },
    { name: 'Wraith King', 'Warlock': -2.623 },
    { name: 'Zeus', 'Warlock': -0.397 },
],
"Witch Doctor": [
    { name: 'Abaddon', 'Witch Doctor': 0.302 },
    { name: 'Alchemist', 'Witch Doctor': 0.262 },
    { name: 'Ancient Apparition', 'Witch Doctor': 0.477 },
    { name: 'Anti-Mage', 'Witch Doctor': -0.52 },
    { name: 'Arc Warden', 'Witch Doctor': -0.477 },
    { name: 'Axe', 'Witch Doctor': -2.493 },
    { name: 'Bane', 'Witch Doctor': -0.392 },
    { name: 'Batrider', 'Witch Doctor': 0.259 },
    { name: 'Beastmaster', 'Witch Doctor': -0.413 },
    { name: 'Bloodseeker', 'Witch Doctor': -2.146 },
    { name: 'Bounty Hunter', 'Witch Doctor': 0.682 },
    { name: 'Brewmaster', 'Witch Doctor': 1.312 },
    { name: 'Bristleback', 'Witch Doctor': 2.248 },
    { name: 'Broodmother', 'Witch Doctor': 2.469 },
    { name: 'Centaur Warrunner', 'Witch Doctor': -1.751 },
    { name: 'Chaos Knight', 'Witch Doctor': -0.842 },
    { name: 'Chen', 'Witch Doctor': 1.582 },
    { name: 'Clinkz', 'Witch Doctor': 1.04 },
    { name: 'Clockwerk', 'Witch Doctor': -0.896 },
    { name: 'Crystal Maiden', 'Witch Doctor': -0.722 },
    { name: 'Dark Seer', 'Witch Doctor': -1.092 },
    { name: 'Dark Willow', 'Witch Doctor': 1.393 },
    { name: 'Dawnbreaker', 'Witch Doctor': -0.072 },
    { name: 'Dazzle', 'Witch Doctor': 0.515 },
    { name: 'Death Prophet', 'Witch Doctor': 0.523 },
    { name: 'Disruptor', 'Witch Doctor': 0.878 },
    { name: 'Doom', 'Witch Doctor': -0.777 },
    { name: 'Dragon Knight', 'Witch Doctor': -0.901 },
    { name: 'Drow Ranger', 'Witch Doctor': 1.208 },
    { name: 'Earth Spirit', 'Witch Doctor': -0.799 },
    { name: 'Earthshaker', 'Witch Doctor': 0.624 },
    { name: 'Elder Titan', 'Witch Doctor': 0.442 },
    { name: 'Ember Spirit', 'Witch Doctor': -0.218 },
    { name: 'Enchantress', 'Witch Doctor': -0.156 },
    { name: 'Enigma', 'Witch Doctor': 0.644 },
    { name: 'Faceless Void', 'Witch Doctor': 1.886 },
    { name: 'Grimstroke', 'Witch Doctor': 0.423 },
    { name: 'Gyrocopter', 'Witch Doctor': 0.005 },
    { name: 'Hoodwink', 'Witch Doctor': 1.279 },
    { name: 'Huskar', 'Witch Doctor': -0.863 },
    { name: 'Invoker', 'Witch Doctor': 0.972 },
    { name: 'Io', 'Witch Doctor': -0.294 },
    { name: 'Jakiro', 'Witch Doctor': 0.703 },
    { name: 'Juggernaut', 'Witch Doctor': -0.857 },
    { name: 'Keeper of the Light', 'Witch Doctor': 0.995 },
    { name: 'Kunkka', 'Witch Doctor': 0.028 },
    { name: 'Legion Commander', 'Witch Doctor': 0.261 },
    { name: 'Leshrac', 'Witch Doctor': -0.493 },
    { name: 'Lich', 'Witch Doctor': -0.333 },
    { name: 'Lifestealer', 'Witch Doctor': -1.305 },
    { name: 'Lina', 'Witch Doctor': -0.096 },
    { name: 'Lion', 'Witch Doctor': 0.645 },
    { name: 'Lone Druid', 'Witch Doctor': -0.845 },
    { name: 'Luna', 'Witch Doctor': -0.749 },
    { name: 'Lycan', 'Witch Doctor': 0.322 },
    { name: 'Magnus', 'Witch Doctor': 0.738 },
    { name: 'Marci', 'Witch Doctor': 0.036 },
    { name: 'Mars', 'Witch Doctor': 1.164 },
    { name: 'Medusa', 'Witch Doctor': 2.993 },
    { name: 'Meepo', 'Witch Doctor': -1.476 },
    { name: 'Mirana', 'Witch Doctor': 1.192 },
    { name: 'Monkey King', 'Witch Doctor': -0.264 },
    { name: 'Morphling', 'Witch Doctor': 1.621 },
    { name: 'Muerta', 'Witch Doctor': 1.573 },
    { name: 'Naga Siren', 'Witch Doctor': 1.347 },
    { name: "Nature's Prophet", 'Witch Doctor': 1.006 },
    { name: 'Necrophos', 'Witch Doctor': 0.003 },
    { name: 'Night Stalker', 'Witch Doctor': 1.106 },
    { name: 'Nyx Assassin', 'Witch Doctor': 0.438 },
    { name: 'Ogre Magi', 'Witch Doctor': -0.415 },
    { name: 'Omniknight', 'Witch Doctor': -1.549 },
    { name: 'Oracle', 'Witch Doctor': 0.484 },
    { name: 'Outworld Destroyer', 'Witch Doctor': -0.151 },
    { name: 'Pangolier', 'Witch Doctor': 1.887 },
    { name: 'Phantom Assassin', 'Witch Doctor': 1.266 },
    { name: 'Phantom Lancer', 'Witch Doctor': 0.185 },
    { name: 'Phoenix', 'Witch Doctor': 0.696 },
    { name: 'Primal Beast', 'Witch Doctor': -1.7 },
    { name: 'Puck', 'Witch Doctor': 1.759 },
    { name: 'Pudge', 'Witch Doctor': -0.144 },
    { name: 'Pugna', 'Witch Doctor': 0.783 },
    { name: 'Queen of Pain', 'Witch Doctor': -0.528 },
    { name: 'Razor', 'Witch Doctor': -1.721 },
    { name: 'Riki', 'Witch Doctor': 2.465 },
    { name: 'Ringmaster', 'Witch Doctor': 0.571 },
    { name: 'Rubick', 'Witch Doctor': 0.506 },
    { name: 'Sand King', 'Witch Doctor': 0.257 },
    { name: 'Shadow Demon', 'Witch Doctor': -0.137 },
    { name: 'Shadow Fiend', 'Witch Doctor': 0.6 },
    { name: 'Shadow Shaman', 'Witch Doctor': -0.659 },
    { name: 'Silencer', 'Witch Doctor': 1.587 },
    { name: 'Skywrath Mage', 'Witch Doctor': 0.835 },
    { name: 'Slardar', 'Witch Doctor': 0.807 },
    { name: 'Slark', 'Witch Doctor': 0.722 },
    { name: 'Snapfire', 'Witch Doctor': 1.514 },
    { name: 'Sniper', 'Witch Doctor': 1.391 },
    { name: 'Spectre', 'Witch Doctor': -1.05 },
    { name: 'Spirit Breaker', 'Witch Doctor': -0.388 },
    { name: 'Storm Spirit', 'Witch Doctor': -0.136 },
    { name: 'Sven', 'Witch Doctor': -0.316 },
    { name: 'Techies', 'Witch Doctor': 0.239 },
    { name: 'Templar Assassin', 'Witch Doctor': 1.744 },
    { name: 'Terrorblade', 'Witch Doctor': -0.054 },
    { name: 'Tidehunter', 'Witch Doctor': -1.549 },
    { name: 'Timbersaw', 'Witch Doctor': -1.549 },
    { name: 'Tinker', 'Witch Doctor': -1.027 },
    { name: 'Tiny', 'Witch Doctor': -0.291 },
    { name: 'Treant Protector', 'Witch Doctor': -0.009 },
    { name: 'Troll Warlord', 'Witch Doctor': -0.279 },
    { name: 'Tusk', 'Witch Doctor': 0.729 },
    { name: 'Underlord', 'Witch Doctor': -1.157 },
    { name: 'Undying', 'Witch Doctor': -1.756 },
    { name: 'Ursa', 'Witch Doctor': 0.71 },
    { name: 'Vengeful Spirit', 'Witch Doctor': -0.017 },
    { name: 'Venomancer', 'Witch Doctor': 0.072 },
    { name: 'Viper', 'Witch Doctor': -1.925 },
    { name: 'Visage', 'Witch Doctor': 1.19 },
    { name: 'Void Spirit', 'Witch Doctor': 1.093 },
    { name: 'Warlock', 'Witch Doctor': -0.112 },
    { name: 'Weaver', 'Witch Doctor': 0.307 },
    { name: 'Windranger', 'Witch Doctor': 0.976 },
    { name: 'Winter Wyvern', 'Witch Doctor': 0.087 },
    { name: 'Wraith King', 'Witch Doctor': 1.119 },
    { name: 'Zeus', 'Witch Doctor': 1.455 },
],
"Zeus": [
    { name: 'Abaddon', 'Zeus': 0.086 },
    { name: 'Alchemist', 'Zeus': 0.083 },
    { name: 'Ancient Apparition', 'Zeus': -1.03 },
    { name: 'Anti-Mage', 'Zeus': 2.612 },
    { name: 'Arc Warden', 'Zeus': 0.067 },
    { name: 'Axe', 'Zeus': -1.465 },
    { name: 'Bane', 'Zeus': 0.159 },
    { name: 'Batrider', 'Zeus': 0.3 },
    { name: 'Beastmaster', 'Zeus': 1.531 },
    { name: 'Bloodseeker', 'Zeus': 0.204 },
    { name: 'Bounty Hunter', 'Zeus': -0.847 },
    { name: 'Brewmaster', 'Zeus': 1.864 },
    { name: 'Bristleback', 'Zeus': 1.252 },
    { name: 'Broodmother', 'Zeus': 1.733 },
    { name: 'Centaur Warrunner', 'Zeus': 1.172 },
    { name: 'Chaos Knight', 'Zeus': 1.803 },
    { name: 'Chen', 'Zeus': 2.969 },
    { name: 'Clinkz', 'Zeus': 0.207 },
    { name: 'Clockwerk', 'Zeus': 0.27 },
    { name: 'Crystal Maiden', 'Zeus': -1.008 },
    { name: 'Dark Seer', 'Zeus': 0.739 },
    { name: 'Dark Willow', 'Zeus': 0.441 },
    { name: 'Dawnbreaker', 'Zeus': 0.276 },
    { name: 'Dazzle', 'Zeus': 0.205 },
    { name: 'Death Prophet', 'Zeus': 0.435 },
    { name: 'Disruptor', 'Zeus': -0.192 },
    { name: 'Doom', 'Zeus': 0.205 },
    { name: 'Dragon Knight', 'Zeus': 0.455 },
    { name: 'Drow Ranger', 'Zeus': -2.024 },
    { name: 'Earth Spirit', 'Zeus': 0.448 },
    { name: 'Earthshaker', 'Zeus': -0.637 },
    { name: 'Elder Titan', 'Zeus': -1.4 },
    { name: 'Ember Spirit', 'Zeus': 1.875 },
    { name: 'Enchantress', 'Zeus': 1.004 },
    { name: 'Enigma', 'Zeus': -0.554 },
    { name: 'Faceless Void', 'Zeus': 1.451 },
    { name: 'Grimstroke', 'Zeus': -0.315 },
    { name: 'Gyrocopter', 'Zeus': -0.108 },
    { name: 'Hoodwink', 'Zeus': -0.499 },
    { name: 'Huskar', 'Zeus': 2.017 },
    { name: 'Invoker', 'Zeus': -0.544 },
    { name: 'Io', 'Zeus': 0.675 },
    { name: 'Jakiro', 'Zeus': -0.659 },
    { name: 'Juggernaut', 'Zeus': 0.405 },
    { name: 'Keeper of the Light', 'Zeus': 1.199 },
    { name: 'Kunkka', 'Zeus': -0.061 },
    { name: 'Legion Commander', 'Zeus': -0.955 },
    { name: 'Leshrac', 'Zeus': -0.563 },
    { name: 'Lich', 'Zeus': -0.928 },
    { name: 'Lifestealer', 'Zeus': 1.381 },
    { name: 'Lina', 'Zeus': 0.141 },
    { name: 'Lion', 'Zeus': 0.186 },
    { name: 'Lone Druid', 'Zeus': 0.058 },
    { name: 'Luna', 'Zeus': -1.165 },
    { name: 'Lycan', 'Zeus': 2.152 },
    { name: 'Magnus', 'Zeus': 0.612 },
    { name: 'Marci', 'Zeus': -0.015 },
    { name: 'Mars', 'Zeus': 1.082 },
    { name: 'Medusa', 'Zeus': 1.65 },
    { name: 'Meepo', 'Zeus': 3.828 },
    { name: 'Mirana', 'Zeus': -0.475 },
    { name: 'Monkey King', 'Zeus': -2.308 },
    { name: 'Morphling', 'Zeus': -0.658 },
    { name: 'Muerta', 'Zeus': -0.582 },
    { name: 'Naga Siren', 'Zeus': 0.938 },
    { name: "Nature's Prophet", 'Zeus': -0.371 },
    { name: 'Necrophos', 'Zeus': 0.717 },
    { name: 'Night Stalker', 'Zeus': 0.096 },
    { name: 'Nyx Assassin', 'Zeus': -0.729 },
    { name: 'Ogre Magi', 'Zeus': 0.478 },
    { name: 'Omniknight', 'Zeus': 0.332 },
    { name: 'Oracle', 'Zeus': 1.413 },
    { name: 'Outworld Destroyer', 'Zeus': -0.671 },
    { name: 'Pangolier', 'Zeus': 1.681 },
    { name: 'Phantom Assassin', 'Zeus': -0.521 },
    { name: 'Phantom Lancer', 'Zeus': 0.391 },
    { name: 'Phoenix', 'Zeus': 0.167 },
    { name: 'Primal Beast', 'Zeus': -0.698 },
    { name: 'Puck', 'Zeus': -0.271 },
    { name: 'Pudge', 'Zeus': 0.619 },
    { name: 'Pugna', 'Zeus': 0.81 },
    { name: 'Queen of Pain', 'Zeus': 1.597 },
    { name: 'Razor', 'Zeus': 0.481 },
    { name: 'Riki', 'Zeus': -2.548 },
    { name: 'Ringmaster', 'Zeus': -0.637 },
    { name: 'Rubick', 'Zeus': 0.25 },
    { name: 'Sand King', 'Zeus': -2.709 },
    { name: 'Shadow Demon', 'Zeus': -0.413 },
    { name: 'Shadow Fiend', 'Zeus': -2.306 },
    { name: 'Shadow Shaman', 'Zeus': -0.249 },
    { name: 'Silencer', 'Zeus': 0.103 },
    { name: 'Skywrath Mage', 'Zeus': 0.907 },
    { name: 'Slardar', 'Zeus': 0.014 },
    { name: 'Slark', 'Zeus': -0.824 },
    { name: 'Snapfire', 'Zeus': 0.108 },
    { name: 'Sniper', 'Zeus': -1.512 },
    { name: 'Spectre', 'Zeus': 1.469 },
    { name: 'Spirit Breaker', 'Zeus': -0.002 },
    { name: 'Storm Spirit', 'Zeus': 2.74 },
    { name: 'Sven', 'Zeus': 0.644 },
    { name: 'Techies', 'Zeus': -0.284 },
    { name: 'Templar Assassin', 'Zeus': 0.969 },
    { name: 'Terrorblade', 'Zeus': -1.051 },
    { name: 'Tidehunter', 'Zeus': -0.067 },
    { name: 'Timbersaw', 'Zeus': -0.285 },
    { name: 'Tinker', 'Zeus': 0.575 },
    { name: 'Tiny', 'Zeus': -0.069 },
    { name: 'Treant Protector', 'Zeus': -0.42 },
    { name: 'Troll Warlord', 'Zeus': -0.656 },
    { name: 'Tusk', 'Zeus': 0.617 },
    { name: 'Underlord', 'Zeus': 0.716 },
    { name: 'Undying', 'Zeus': 0.531 },
    { name: 'Ursa', 'Zeus': -0.655 },
    { name: 'Vengeful Spirit', 'Zeus': 0.638 },
    { name: 'Venomancer', 'Zeus': 0.554 },
    { name: 'Viper', 'Zeus': 0.515 },
    { name: 'Visage', 'Zeus': 1.321 },
    { name: 'Void Spirit', 'Zeus': 1.722 },
    { name: 'Warlock', 'Zeus': 0.173 },
    { name: 'Weaver', 'Zeus': -0.711 },
    { name: 'Windranger', 'Zeus': -0.879 },
    { name: 'Winter Wyvern', 'Zeus': -0.758 },
    { name: 'Witch Doctor', 'Zeus': -1.388 },
    { name: 'Wraith King', 'Zeus': 0.165 },
],
"Abaddon": [
    { name: 'Alchemist', 'Abaddon': 1.662 },
    { name: 'Ancient Apparition', 'Abaddon': 2.197 },
    { name: 'Anti-Mage', 'Abaddon': 2.923 },
    { name: 'Arc Warden', 'Abaddon': -1.791 },
    { name: 'Axe', 'Abaddon': -3.119 },
    { name: 'Bane', 'Abaddon': -1.511 },
    { name: 'Batrider', 'Abaddon': 0.128 },
    { name: 'Beastmaster', 'Abaddon': -0.129 },
    { name: 'Bloodseeker', 'Abaddon': 0.125 },
    { name: 'Bounty Hunter', 'Abaddon': -1.803 },
    { name: 'Brewmaster', 'Abaddon': -0.288 },
    { name: 'Bristleback', 'Abaddon': -0.273 },
    { name: 'Broodmother', 'Abaddon': 2.141 },
    { name: 'Centaur Warrunner', 'Abaddon': -0.285 },
    { name: 'Chaos Knight', 'Abaddon': 0.497 },
    { name: 'Chen', 'Abaddon': 1.116 },
    { name: 'Clinkz', 'Abaddon': -0.716 },
    { name: 'Clockwerk', 'Abaddon': 0.771 },
    { name: 'Crystal Maiden', 'Abaddon': 0.405 },
    { name: 'Dark Seer', 'Abaddon': 2.564 },
    { name: 'Dark Willow', 'Abaddon': 0.834 },
    { name: 'Dawnbreaker', 'Abaddon': 0.834 },
    { name: 'Dazzle', 'Abaddon': -0.971 },
    { name: 'Death Prophet', 'Abaddon': 1.303 },
    { name: 'Disruptor', 'Abaddon': 0.048 },
    { name: 'Doom', 'Abaddon': 3.134 },
    { name: 'Dragon Knight', 'Abaddon': 0.183 },
    { name: 'Drow Ranger', 'Abaddon': 0.617 },
    { name: 'Earth Spirit', 'Abaddon': -1.191 },
    { name: 'Earthshaker', 'Abaddon': 2.306 },
    { name: 'Elder Titan', 'Abaddon': 3.577 },
    { name: 'Ember Spirit', 'Abaddon': 0.409 },
    { name: 'Enchantress', 'Abaddon': 1.214 },
    { name: 'Enigma', 'Abaddon': 0.691 },
    { name: 'Faceless Void', 'Abaddon': -0.434 },
    { name: 'Grimstroke', 'Abaddon': 1.38 },
    { name: 'Gyrocopter', 'Abaddon': 0.315 },
    { name: 'Hoodwink', 'Abaddon': 0.263 },
    { name: 'Huskar', 'Abaddon': -0.244 },
    { name: 'Invoker', 'Abaddon': 0.57 },
    { name: 'Io', 'Abaddon': 0.898 },
    { name: 'Jakiro', 'Abaddon': -1.436 },
    { name: 'Juggernaut', 'Abaddon': -0.297 },
    { name: 'Keeper of the Light', 'Abaddon': 0.986 },
    { name: 'Kunkka', 'Abaddon': 0.827 },
    { name: 'Legion Commander', 'Abaddon': -1.309 },
    { name: 'Leshrac', 'Abaddon': 1.032 },
    { name: 'Lich', 'Abaddon': 0.752 },
    { name: 'Lifestealer', 'Abaddon': -0.079 },
    { name: 'Lina', 'Abaddon': 1.415 },
    { name: 'Lion', 'Abaddon': 0.664 },
    { name: 'Lone Druid', 'Abaddon': 2.766 },
    { name: 'Luna', 'Abaddon': -0.545 },
    { name: 'Lycan', 'Abaddon': 1.871 },
    { name: 'Magnus', 'Abaddon': 1.229 },
    { name: 'Marci', 'Abaddon': 2.706 },
    { name: 'Mars', 'Abaddon': 0.767 },
    { name: 'Medusa', 'Abaddon': 0.437 },
    { name: 'Meepo', 'Abaddon': 2.167 },
    { name: 'Mirana', 'Abaddon': -0.143 },
    { name: 'Monkey King', 'Abaddon': 1.82 },
    { name: 'Morphling', 'Abaddon': -0.456 },
    { name: 'Muerta', 'Abaddon': 0.124 },
    { name: 'Naga Siren', 'Abaddon': -0.008 },
    { name: "Nature's Prophet", 'Abaddon': 0.871 },
    { name: 'Necrophos', 'Abaddon': 0.226 },
    { name: 'Night Stalker', 'Abaddon': -0.109 },
    { name: 'Nyx Assassin', 'Abaddon': 0.825 },
    { name: 'Ogre Magi', 'Abaddon': -0.911 },
    { name: 'Omniknight', 'Abaddon': 0.757 },
    { name: 'Oracle', 'Abaddon': 0.902 },
    { name: 'Outworld Destroyer', 'Abaddon': 2.979 },
    { name: 'Pangolier', 'Abaddon': 1.854 },
    { name: 'Phantom Assassin', 'Abaddon': -0.062 },
    { name: 'Phantom Lancer', 'Abaddon': 1.639 },
    { name: 'Phoenix', 'Abaddon': -0.844 },
    { name: 'Primal Beast', 'Abaddon': 2.169 },
    { name: 'Puck', 'Abaddon': -1.148 },
    { name: 'Pudge', 'Abaddon': -0.187 },
    { name: 'Pugna', 'Abaddon': 1.346 },
    { name: 'Queen of Pain', 'Abaddon': -1.057 },
    { name: 'Razor', 'Abaddon': 1.593 },
    { name: 'Riki', 'Abaddon': 0.224 },
    { name: 'Ringmaster', 'Abaddon': -0.238 },
    { name: 'Rubick', 'Abaddon': 1.249 },
    { name: 'Sand King', 'Abaddon': -0.341 },
    { name: 'Shadow Demon', 'Abaddon': 3.501 },
    { name: 'Shadow Fiend', 'Abaddon': 1.852 },
    { name: 'Shadow Shaman', 'Abaddon': -1.16 },
    { name: 'Silencer', 'Abaddon': -2.182 },
    { name: 'Skywrath Mage', 'Abaddon': -2.344 },
    { name: 'Slardar', 'Abaddon': -0.399 },
    { name: 'Slark', 'Abaddon': 2.018 },
    { name: 'Snapfire', 'Abaddon': 0.483 },
    { name: 'Sniper', 'Abaddon': 0.137 },
    { name: 'Spectre', 'Abaddon': -3.898 },
    { name: 'Spirit Breaker', 'Abaddon': -0.184 },
    { name: 'Storm Spirit', 'Abaddon': -1.035 },
    { name: 'Sven', 'Abaddon': 1.802 },
    { name: 'Techies', 'Abaddon': 1.258 },
    { name: 'Templar Assassin', 'Abaddon': 0.476 },
    { name: 'Terrorblade', 'Abaddon': 1.735 },
    { name: 'Tidehunter', 'Abaddon': 0.275 },
    { name: 'Timbersaw', 'Abaddon': -0.356 },
    { name: 'Tinker', 'Abaddon': 2.018 },
    { name: 'Tiny', 'Abaddon': 0.712 },
    { name: 'Treant Protector', 'Abaddon': 0.288 },
    { name: 'Troll Warlord', 'Abaddon': -0.873 },
    { name: 'Tusk', 'Abaddon': 2.24 },
    { name: 'Underlord', 'Abaddon': 1.12 },
    { name: 'Undying', 'Abaddon': 2.753 },
    { name: 'Ursa', 'Abaddon': 0.675 },
    { name: 'Vengeful Spirit', 'Abaddon': -0.303 },
    { name: 'Venomancer', 'Abaddon': -0.602 },
    { name: 'Viper', 'Abaddon': 0.569 },
    { name: 'Visage', 'Abaddon': -0.49 },
    { name: 'Void Spirit', 'Abaddon': -0.893 },
    { name: 'Warlock', 'Abaddon': -0.116 },
    { name: 'Weaver', 'Abaddon': 0.671 },
    { name: 'Windranger', 'Abaddon': 0.881 },
    { name: 'Winter Wyvern', 'Abaddon': -0.08 },
    { name: 'Witch Doctor', 'Abaddon': -0.67 },
    { name: 'Wraith King', 'Abaddon': -2.99 },
    { name: 'Zeus', 'Abaddon': -0.382 },
],
"Bane": [
    { name: 'Abaddon', 'Bane': 1.922 },
    { name: 'Alchemist', 'Bane': -0.971 },
    { name: 'Ancient Apparition', 'Bane': -0.559 },
    { name: 'Anti-Mage', 'Bane': -0.419 },
    { name: 'Arc Warden', 'Bane': 1.433 },
    { name: 'Axe', 'Bane': -0.601 },
    { name: 'Batrider', 'Bane': 0.087 },
    { name: 'Beastmaster', 'Bane': 2.045 },
    { name: 'Bloodseeker', 'Bane': -0.095 },
    { name: 'Bounty Hunter', 'Bane': -0.478 },
    { name: 'Brewmaster', 'Bane': 1.003 },
    { name: 'Bristleback', 'Bane': 0.35 },
    { name: 'Broodmother', 'Bane': -0.355 },
    { name: 'Centaur Warrunner', 'Bane': -0.095 },
    { name: 'Chaos Knight', 'Bane': 2.131 },
    { name: 'Chen', 'Bane': 0.482 },
    { name: 'Clinkz', 'Bane': -0.397 },
    { name: 'Clockwerk', 'Bane': 0.52 },
    { name: 'Crystal Maiden', 'Bane': -0.158 },
    { name: 'Dark Seer', 'Bane': 0.872 },
    { name: 'Dark Willow', 'Bane': 0.27 },
    { name: 'Dawnbreaker', 'Bane': 0.906 },
    { name: 'Dazzle', 'Bane': -0.207 },
    { name: 'Death Prophet', 'Bane': 0.651 },
    { name: 'Disruptor', 'Bane': -1.007 },
    { name: 'Doom', 'Bane': -0.359 },
    { name: 'Dragon Knight', 'Bane': -0.421 },
    { name: 'Drow Ranger', 'Bane': 0.626 },
    { name: 'Earth Spirit', 'Bane': 0.017 },
    { name: 'Earthshaker', 'Bane': 0.277 },
    { name: 'Elder Titan', 'Bane': -0.4 },
    { name: 'Ember Spirit', 'Bane': -2.336 },
    { name: 'Enchantress', 'Bane': 0.501 },
    { name: 'Enigma', 'Bane': -0.205 },
    { name: 'Faceless Void', 'Bane': -0.567 },
    { name: 'Grimstroke', 'Bane': -0.477 },
    { name: 'Gyrocopter', 'Bane': -0.007 },
    { name: 'Hoodwink', 'Bane': -1.661 },
    { name: 'Huskar', 'Bane': 0.789 },
    { name: 'Invoker', 'Bane': -0.559 },
    { name: 'Io', 'Bane': 1.152 },
    { name: 'Jakiro', 'Bane': -0.95 },
    { name: 'Juggernaut', 'Bane': -0.616 },
    { name: 'Keeper of the Light', 'Bane': 0.333 },
    { name: 'Kunkka', 'Bane': -0.868 },
    { name: 'Legion Commander', 'Bane': -0.628 },
    { name: 'Leshrac', 'Bane': 0.362 },
    { name: 'Lich', 'Bane': 0.02 },
    { name: 'Lifestealer', 'Bane': -1.482 },
    { name: 'Lina', 'Bane': -1.291 },
    { name: 'Lion', 'Bane': -0.294 },
    { name: 'Lone Druid', 'Bane': 1.282 },
    { name: 'Luna', 'Bane': -0.09 },
    { name: 'Lycan', 'Bane': 0.589 },
    { name: 'Magnus', 'Bane': -0.132 },
    { name: 'Marci', 'Bane': 0.512 },
    { name: 'Mars', 'Bane': 0.399 },
    { name: 'Medusa', 'Bane': -0.024 },
    { name: 'Meepo', 'Bane': 3.193 },
    { name: 'Mirana', 'Bane': -0.838 },
    { name: 'Monkey King', 'Bane': -0.104 },
    { name: 'Morphling', 'Bane': -2.441 },
    { name: 'Muerta', 'Bane': -1.04 },
    { name: 'Naga Siren', 'Bane': 3.739 },
    { name: "Nature's Prophet", 'Bane': -0.237 },
    { name: 'Necrophos', 'Bane': 1.768 },
    { name: 'Night Stalker', 'Bane': -0.944 },
    { name: 'Nyx Assassin', 'Bane': -0.398 },
    { name: 'Ogre Magi', 'Bane': 0.576 },
    { name: 'Omniknight', 'Bane': -1.29 },
    { name: 'Oracle', 'Bane': -0.002 },
    { name: 'Outworld Destroyer', 'Bane': 0.158 },
    { name: 'Pangolier', 'Bane': -0.812 },
    { name: 'Phantom Assassin', 'Bane': -1.51 },
    { name: 'Phantom Lancer', 'Bane': 2.235 },
    { name: 'Phoenix', 'Bane': -0.601 },
    { name: 'Primal Beast', 'Bane': -1.123 },
    { name: 'Puck', 'Bane': -1.936 },
    { name: 'Pudge', 'Bane': 1.774 },
    { name: 'Pugna', 'Bane': 0.697 },
    { name: 'Queen of Pain', 'Bane': -1.049 },
    { name: 'Razor', 'Bane': 0.564 },
    { name: 'Riki', 'Bane': -0.115 },
    { name: 'Ringmaster', 'Bane': 0.472 },
    { name: 'Rubick', 'Bane': -0.787 },
    { name: 'Sand King', 'Bane': 1.203 },
    { name: 'Shadow Demon', 'Bane': -1.418 },
    { name: 'Shadow Fiend', 'Bane': 0.461 },
    { name: 'Shadow Shaman', 'Bane': 0.909 },
    { name: 'Silencer', 'Bane': 3.114 },
    { name: 'Skywrath Mage', 'Bane': -0.201 },
    { name: 'Slardar', 'Bane': 1.001 },
    { name: 'Slark', 'Bane': 2.526 },
    { name: 'Snapfire', 'Bane': -0.345 },
    { name: 'Sniper', 'Bane': 0.104 },
    { name: 'Spectre', 'Bane': 2.089 },
    { name: 'Spirit Breaker', 'Bane': 1.124 },
    { name: 'Storm Spirit', 'Bane': -2.298 },
    { name: 'Sven', 'Bane': -0.569 },
    { name: 'Techies', 'Bane': -0.418 },
    { name: 'Templar Assassin', 'Bane': -0.077 },
    { name: 'Terrorblade', 'Bane': 1.713 },
    { name: 'Tidehunter', 'Bane': 1.685 },
    { name: 'Timbersaw', 'Bane': -0.895 },
    { name: 'Tinker', 'Bane': -0.48 },
    { name: 'Tiny', 'Bane': 0.259 },
    { name: 'Treant Protector', 'Bane': 0.352 },
    { name: 'Troll Warlord', 'Bane': -1.137 },
    { name: 'Tusk', 'Bane': 0.95 },
    { name: 'Underlord', 'Bane': -0.255 },
    { name: 'Undying', 'Bane': 0.518 },
    { name: 'Ursa', 'Bane': 0.655 },
    { name: 'Vengeful Spirit', 'Bane': -0.058 },
    { name: 'Venomancer', 'Bane': 0.214 },
    { name: 'Viper', 'Bane': 0.221 },
    { name: 'Visage', 'Bane': 2.524 },
    { name: 'Void Spirit', 'Bane': -0.737 },
    { name: 'Warlock', 'Bane': 1.351 },
    { name: 'Weaver', 'Bane': -1.113 },
    { name: 'Windranger', 'Bane': -1.507 },
    { name: 'Winter Wyvern', 'Bane': -0.381 },
    { name: 'Witch Doctor', 'Bane': 0.964 },
    { name: 'Wraith King', 'Bane': 0.423 },
    { name: 'Zeus', 'Bane': -0.142 },
],
"Batrider": [
    { name: 'Abaddon', 'Batrider': 0.695 },
    { name: 'Alchemist', 'Batrider': -1.497 },
    { name: 'Ancient Apparition', 'Batrider': -1.365 },
    { name: 'Anti-Mage', 'Batrider': 0.185 },
    { name: 'Arc Warden', 'Batrider': -1.125 },
    { name: 'Axe', 'Batrider': -1.133 },
    { name: 'Bane', 'Batrider': 0.073 },
    { name: 'Beastmaster', 'Batrider': -1.537 },
    { name: 'Bloodseeker', 'Batrider': -1.069 },
    { name: 'Bounty Hunter', 'Batrider': -0.439 },
    { name: 'Brewmaster', 'Batrider': 0.959 },
    { name: 'Bristleback', 'Batrider': -0.357 },
    { name: 'Broodmother', 'Batrider': -4.415 },
    { name: 'Centaur Warrunner', 'Batrider': -0.222 },
    { name: 'Chaos Knight', 'Batrider': -0.011 },
    { name: 'Chen', 'Batrider': -2.824 },
    { name: 'Clinkz', 'Batrider': 0.253 },
    { name: 'Clockwerk', 'Batrider': -1.723 },
    { name: 'Crystal Maiden', 'Batrider': 1.516 },
    { name: 'Dark Seer', 'Batrider': -2.593 },
    { name: 'Dark Willow', 'Batrider': 1.223 },
    { name: 'Dawnbreaker', 'Batrider': 1.471 },
    { name: 'Dazzle', 'Batrider': 0.214 },
    { name: 'Death Prophet', 'Batrider': -1.371 },
    { name: 'Disruptor', 'Batrider': 0.619 },
    { name: 'Doom', 'Batrider': -1.485 },
    { name: 'Dragon Knight', 'Batrider': -0.853 },
    { name: 'Drow Ranger', 'Batrider': -0.171 },
    { name: 'Earth Spirit', 'Batrider': -2.494 },
    { name: 'Earthshaker', 'Batrider': 0.38 },
    { name: 'Elder Titan', 'Batrider': -4.105 },
    { name: 'Ember Spirit', 'Batrider': 1.028 },
    { name: 'Enchantress', 'Batrider': 0.157 },
    { name: 'Enigma', 'Batrider': 0.351 },
    { name: 'Faceless Void', 'Batrider': 1.443 },
    { name: 'Grimstroke', 'Batrider': 1.641 },
    { name: 'Gyrocopter', 'Batrider': 0.167 },
    { name: 'Hoodwink', 'Batrider': 0.363 },
    { name: 'Huskar', 'Batrider': 1.626 },
    { name: 'Invoker', 'Batrider': 0.18 },
    { name: 'Io', 'Batrider': -0.925 },
    { name: 'Jakiro', 'Batrider': 0.427 },
    { name: 'Juggernaut', 'Batrider': 0.938 },
    { name: 'Keeper of the Light', 'Batrider': -1.646 },
    { name: 'Kunkka', 'Batrider': -0.419 },
    { name: 'Legion Commander', 'Batrider': 0.661 },
    { name: 'Leshrac', 'Batrider': -0.011 },
    { name: 'Lich', 'Batrider': 1.034 },
    { name: 'Lifestealer', 'Batrider': -0.92 },
    { name: 'Lina', 'Batrider': -0.306 },
    { name: 'Lion', 'Batrider': 0.798 },
    { name: 'Lone Druid', 'Batrider': -4.129 },
    { name: 'Luna', 'Batrider': 0.246 },
    { name: 'Lycan', 'Batrider': -2.385 },
    { name: 'Magnus', 'Batrider': -0.456 },
    { name: 'Marci', 'Batrider': -3.069 },
    { name: 'Mars', 'Batrider': -0.577 },
    { name: 'Medusa', 'Batrider': 0.965 },
    { name: 'Meepo', 'Batrider': 2.093 },
    { name: 'Mirana', 'Batrider': 1.316 },
    { name: 'Monkey King', 'Batrider': -2.601 },
    { name: 'Morphling', 'Batrider': 0.734 },
    { name: 'Muerta', 'Batrider': -1.697 },
    { name: 'Naga Siren', 'Batrider': -1.205 },
    { name: "Nature's Prophet", 'Batrider': -0.369 },
    { name: 'Necrophos', 'Batrider': -0.08 },
    { name: 'Night Stalker', 'Batrider': -1.062 },
    { name: 'Nyx Assassin', 'Batrider': -0.688 },
    { name: 'Ogre Magi', 'Batrider': 0.817 },
    { name: 'Omniknight', 'Batrider': -1.875 },
    { name: 'Oracle', 'Batrider': 0.55 },
    { name: 'Outworld Destroyer', 'Batrider': -0.842 },
    { name: 'Pangolier', 'Batrider': -0.972 },
    { name: 'Phantom Assassin', 'Batrider': -0.768 },
    { name: 'Phantom Lancer', 'Batrider': -0.249 },
    { name: 'Phoenix', 'Batrider': -2.222 },
    { name: 'Primal Beast', 'Batrider': -0.491 },
    { name: 'Puck', 'Batrider': -0.661 },
    { name: 'Pudge', 'Batrider': 0.075 },
    { name: 'Pugna', 'Batrider': -1.612 },
    { name: 'Queen of Pain', 'Batrider': 1.862 },
    { name: 'Razor', 'Batrider': 0.477 },
    { name: 'Riki', 'Batrider': 0.957 },
    { name: 'Ringmaster', 'Batrider': -0.736 },
    { name: 'Rubick', 'Batrider': -0.813 },
    { name: 'Sand King', 'Batrider': 0.979 },
    { name: 'Shadow Demon', 'Batrider': -0.578 },
    { name: 'Shadow Fiend', 'Batrider': -2.093 },
    { name: 'Shadow Shaman', 'Batrider': 1.193 },
    { name: 'Silencer', 'Batrider': 1.172 },
    { name: 'Skywrath Mage', 'Batrider': 0.759 },
    { name: 'Slardar', 'Batrider': -1.394 },
    { name: 'Slark', 'Batrider': 2.368 },
    { name: 'Snapfire', 'Batrider': 0.458 },
    { name: 'Sniper', 'Batrider': 0.831 },
    { name: 'Spectre', 'Batrider': -0.531 },
    { name: 'Spirit Breaker', 'Batrider': 1.582 },
    { name: 'Storm Spirit', 'Batrider': 1.649 },
    { name: 'Sven', 'Batrider': -0.66 },
    { name: 'Techies', 'Batrider': 0.197 },
    { name: 'Templar Assassin', 'Batrider': -2.711 },
    { name: 'Terrorblade', 'Batrider': 0.552 },
    { name: 'Tidehunter', 'Batrider': 1.436 },
    { name: 'Timbersaw', 'Batrider': -2.109 },
    { name: 'Tinker', 'Batrider': -1.688 },
    { name: 'Tiny', 'Batrider': 0.135 },
    { name: 'Treant Protector', 'Batrider': -0.683 },
    { name: 'Troll Warlord', 'Batrider': -1.526 },
    { name: 'Tusk', 'Batrider': -0.844 },
    { name: 'Underlord', 'Batrider': -0.028 },
    { name: 'Undying', 'Batrider': 1.775 },
    { name: 'Ursa', 'Batrider': -0.705 },
    { name: 'Vengeful Spirit', 'Batrider': 1.31 },
    { name: 'Venomancer', 'Batrider': 1.939 },
    { name: 'Viper', 'Batrider': 2.166 },
    { name: 'Visage', 'Batrider': -1.206 },
    { name: 'Void Spirit', 'Batrider': -0.447 },
    { name: 'Warlock', 'Batrider': 0.821 },
    { name: 'Weaver', 'Batrider': 2.945 },
    { name: 'Windranger', 'Batrider': 0.68 },
    { name: 'Winter Wyvern', 'Batrider': 1.741 },
    { name: 'Witch Doctor', 'Batrider': 1.23 },
    { name: 'Wraith King', 'Batrider': 0.917 },
    { name: 'Zeus', 'Batrider': 0.43 },
],
"Beastmaster": [
    { name: 'Abaddon', 'Beastmaster': 0.747 },
    { name: 'Alchemist', 'Beastmaster': 0.622 },
    { name: 'Ancient Apparition', 'Beastmaster': -0.128 },
    { name: 'Anti-Mage', 'Beastmaster': -2.302 },
    { name: 'Arc Warden', 'Beastmaster': -1.647 },
    { name: 'Axe', 'Beastmaster': -0.997 },
    { name: 'Bane', 'Beastmaster': -2.098 },
    { name: 'Batrider', 'Beastmaster': 1.535 },
    { name: 'Bloodseeker', 'Beastmaster': -1.965 },
    { name: 'Bounty Hunter', 'Beastmaster': -0.777 },
    { name: 'Brewmaster', 'Beastmaster': 1.092 },
    { name: 'Bristleback', 'Beastmaster': 3.402 },
    { name: 'Broodmother', 'Beastmaster': -2.059 },
    { name: 'Centaur Warrunner', 'Beastmaster': -0.585 },
    { name: 'Chaos Knight', 'Beastmaster': -0.476 },
    { name: 'Chen', 'Beastmaster': 3.348 },
    { name: 'Clinkz', 'Beastmaster': 1.684 },
    { name: 'Clockwerk', 'Beastmaster': -3.386 },
    { name: 'Crystal Maiden', 'Beastmaster': 1.148 },
    { name: 'Dark Seer', 'Beastmaster': 0.021 },
    { name: 'Dark Willow', 'Beastmaster': 0.021 },
    { name: 'Dawnbreaker', 'Beastmaster': 2.23 },
    { name: 'Dazzle', 'Beastmaster': 1.045 },
    { name: 'Death Prophet', 'Beastmaster': -0.417 },
    { name: 'Disruptor', 'Beastmaster': -0.738 },
    { name: 'Doom', 'Beastmaster': -0.388 },
    { name: 'Dragon Knight', 'Beastmaster': -0.809 },
    { name: 'Drow Ranger', 'Beastmaster': 0.495 },
    { name: 'Earth Spirit', 'Beastmaster': 0.185 },
    { name: 'Earthshaker', 'Beastmaster': 1.381 },
    { name: 'Elder Titan', 'Beastmaster': 0.715 },
    { name: 'Ember Spirit', 'Beastmaster': 2.881 },
    { name: 'Enchantress', 'Beastmaster': 0.742 },
    { name: 'Enigma', 'Beastmaster': -1.183 },
    { name: 'Faceless Void', 'Beastmaster': -0.997 },
    { name: 'Grimstroke', 'Beastmaster': -0.684 },
    { name: 'Gyrocopter', 'Beastmaster': 0.735 },
    { name: 'Hoodwink', 'Beastmaster': 0.635 },
    { name: 'Huskar', 'Beastmaster': 0.179 },
    { name: 'Invoker', 'Beastmaster': -1.461 },
    { name: 'Io', 'Beastmaster': -0.505 },
    { name: 'Jakiro', 'Beastmaster': 1.517 },
    { name: 'Juggernaut', 'Beastmaster': -0.419 },
    { name: 'Keeper of the Light', 'Beastmaster': 0.403 },
    { name: 'Kunkka', 'Beastmaster': 2.165 },
    { name: 'Legion Commander', 'Beastmaster': 0.156 },
    { name: 'Leshrac', 'Beastmaster': -0.394 },
    { name: 'Lich', 'Beastmaster': 0.977 },
    { name: 'Lifestealer', 'Beastmaster': -1.066 },
    { name: 'Lina', 'Beastmaster': 2.205 },
    { name: 'Lion', 'Beastmaster': -1.499 },
    { name: 'Lone Druid', 'Beastmaster': -2.67 },
    { name: 'Luna', 'Beastmaster': 0.603 },
    { name: 'Lycan', 'Beastmaster': -2.359 },
    { name: 'Magnus', 'Beastmaster': 0.564 },
    { name: 'Marci', 'Beastmaster': 0.19 },
    { name: 'Mars', 'Beastmaster': -0.445 },
    { name: 'Medusa', 'Beastmaster': 5.416 },
    { name: 'Meepo', 'Beastmaster': -0.765 },
    { name: 'Mirana', 'Beastmaster': -0.94 },
    { name: 'Monkey King', 'Beastmaster': -0.402 },
    { name: 'Morphling', 'Beastmaster': -1.597 },
    { name: 'Muerta', 'Beastmaster': -0.881 },
    { name: 'Naga Siren', 'Beastmaster': -1.274 },
    { name: "Nature's Prophet", 'Beastmaster': -0.933 },
    { name: 'Necrophos', 'Beastmaster': -1.753 },
    { name: 'Night Stalker', 'Beastmaster': -3.056 },
    { name: 'Nyx Assassin', 'Beastmaster': -1.824 },
    { name: 'Ogre Magi', 'Beastmaster': -1.946 },
    { name: 'Omniknight', 'Beastmaster': -1.982 },
    { name: 'Oracle', 'Beastmaster': -0.22 },
    { name: 'Outworld Destroyer', 'Beastmaster': -1.582 },
    { name: 'Pangolier', 'Beastmaster': 1.067 },
    { name: 'Phantom Assassin', 'Beastmaster': 0.548 },
    { name: 'Phantom Lancer', 'Beastmaster': -4.494 },
    { name: 'Phoenix', 'Beastmaster': 1.268 },
    { name: 'Primal Beast', 'Beastmaster': 1.346 },
    { name: 'Puck', 'Beastmaster': -1.19 },
    { name: 'Pudge', 'Beastmaster': -1.693 },
    { name: 'Pugna', 'Beastmaster': 0.26 },
    { name: 'Queen of Pain', 'Beastmaster': 0.093 },
    { name: 'Razor', 'Beastmaster': -0.173 },
    { name: 'Riki', 'Beastmaster': -1.98 },
    { name: 'Ringmaster', 'Beastmaster': -0.067 },
    { name: 'Rubick', 'Beastmaster': 1.806 },
    { name: 'Sand King', 'Beastmaster': 3.021 },
    { name: 'Shadow Demon', 'Beastmaster': -0.669 },
    { name: 'Shadow Fiend', 'Beastmaster': 0.558 },
    { name: 'Shadow Shaman', 'Beastmaster': -0.964 },
    { name: 'Silencer', 'Beastmaster': 0.122 },
    { name: 'Skywrath Mage', 'Beastmaster': -1.305 },
    { name: 'Slardar', 'Beastmaster': -0.464 },
    { name: 'Slark', 'Beastmaster': 0.611 },
    { name: 'Snapfire', 'Beastmaster': 1.804 },
    { name: 'Sniper', 'Beastmaster': 0.918 },
    { name: 'Spectre', 'Beastmaster': -3.871 },
    { name: 'Spirit Breaker', 'Beastmaster': -0.999 },
    { name: 'Storm Spirit', 'Beastmaster': -0.713 },
    { name: 'Sven', 'Beastmaster': 0.901 },
    { name: 'Techies', 'Beastmaster': 0.388 },
    { name: 'Templar Assassin', 'Beastmaster': 0.562 },
    { name: 'Terrorblade', 'Beastmaster': -0.241 },
    { name: 'Tidehunter', 'Beastmaster': -0.158 },
    { name: 'Timbersaw', 'Beastmaster': -1.004 },
    { name: 'Tinker', 'Beastmaster': 0.058 },
    { name: 'Tiny', 'Beastmaster': -0.059 },
    { name: 'Treant Protector', 'Beastmaster': 0.116 },
    { name: 'Troll Warlord', 'Beastmaster': 1.054 },
    { name: 'Tusk', 'Beastmaster': 0.094 },
    { name: 'Underlord', 'Beastmaster': 0.805 },
    { name: 'Undying', 'Beastmaster': 0.736 },
    { name: 'Ursa', 'Beastmaster': -0.529 },
    { name: 'Vengeful Spirit', 'Beastmaster': -0.687 },
    { name: 'Venomancer', 'Beastmaster': 0.705 },
    { name: 'Viper', 'Beastmaster': 1.191 },
    { name: 'Visage', 'Beastmaster': -1.224 },
    { name: 'Void Spirit', 'Beastmaster': -1.588 },
    { name: 'Warlock', 'Beastmaster': 2.327 },
    { name: 'Weaver', 'Beastmaster': 0.778 },
    { name: 'Windranger', 'Beastmaster': 1.577 },
    { name: 'Winter Wyvern', 'Beastmaster': 0.339 },
    { name: 'Witch Doctor', 'Beastmaster': 1.641 },
    { name: 'Wraith King', 'Beastmaster': -1.911 },
    { name: 'Zeus', 'Beastmaster': -1.199 },
],
"Brewmaster": [
    { name: 'Abaddon', 'Brewmaster': 0.686 },
    { name: 'Alchemist', 'Brewmaster': 0.72 },
    { name: 'Ancient Apparition', 'Brewmaster': -2.635 },
    { name: 'Anti-Mage', 'Brewmaster': 0.55 },
    { name: 'Arc Warden', 'Brewmaster': 0.235 },
    { name: 'Axe', 'Brewmaster': 0.412 },
    { name: 'Bane', 'Brewmaster': -1.027 },
    { name: 'Batrider', 'Brewmaster': -0.973 },
    { name: 'Beastmaster', 'Brewmaster': -1.172 },
    { name: 'Bloodseeker', 'Brewmaster': -1.937 },
    { name: 'Bounty Hunter', 'Brewmaster': -0.231 },
    { name: 'Bristleback', 'Brewmaster': 1.622 },
    { name: 'Broodmother', 'Brewmaster': 0.937 },
    { name: 'Centaur Warrunner', 'Brewmaster': -1.25 },
    { name: 'Chaos Knight', 'Brewmaster': -1.914 },
    { name: 'Chen', 'Brewmaster': -1.877 },
    { name: 'Clinkz', 'Brewmaster': -0.916 },
    { name: 'Clockwerk', 'Brewmaster': 0.227 },
    { name: 'Crystal Maiden', 'Brewmaster': 0.067 },
    { name: 'Dark Seer', 'Brewmaster': 2.027 },
    { name: 'Dark Willow', 'Brewmaster': -0.535 },
    { name: 'Dawnbreaker', 'Brewmaster': 2.02 },
    { name: 'Dazzle', 'Brewmaster': 0.443 },
    { name: 'Death Prophet', 'Brewmaster': 0.811 },
    { name: 'Disruptor', 'Brewmaster': 0.997 },
    { name: 'Doom', 'Brewmaster': -0.732 },
    { name: 'Dragon Knight', 'Brewmaster': -0.239 },
    { name: 'Drow Ranger', 'Brewmaster': -0.74 },
    { name: 'Earth Spirit', 'Brewmaster': 0.238 },
    { name: 'Earthshaker', 'Brewmaster': 1.678 },
    { name: 'Elder Titan', 'Brewmaster': -1.451 },
    { name: 'Ember Spirit', 'Brewmaster': 0.062 },
    { name: 'Enchantress', 'Brewmaster': 0.785 },
    { name: 'Enigma', 'Brewmaster': -0.214 },
    { name: 'Faceless Void', 'Brewmaster': 0.938 },
    { name: 'Grimstroke', 'Brewmaster': 0.33 },
    { name: 'Gyrocopter', 'Brewmaster': 0.18 },
    { name: 'Hoodwink', 'Brewmaster': -0.756 },
    { name: 'Huskar', 'Brewmaster': -0.29 },
    { name: 'Invoker', 'Brewmaster': 0.091 },
    { name: 'Io', 'Brewmaster': 2.099 },
    { name: 'Jakiro', 'Brewmaster': 0.296 },
    { name: 'Juggernaut', 'Brewmaster': 0.832 },
    { name: 'Keeper of the Light', 'Brewmaster': -0.88 },
    { name: 'Kunkka', 'Brewmaster': 0.224 },
    { name: 'Legion Commander', 'Brewmaster': 1.275 },
    { name: 'Leshrac', 'Brewmaster': 2.97 },
    { name: 'Lich', 'Brewmaster': -1.731 },
    { name: 'Lifestealer', 'Brewmaster': 1.188 },
    { name: 'Lina', 'Brewmaster': 1.032 },
    { name: 'Lion', 'Brewmaster': -0.746 },
    { name: 'Lone Druid', 'Brewmaster': -1.555 },
    { name: 'Luna', 'Brewmaster': 0.554 },
    { name: 'Lycan', 'Brewmaster': -1.444 },
    { name: 'Magnus', 'Brewmaster': -0.338 },
    { name: 'Marci', 'Brewmaster': 0.517 },
    { name: 'Mars', 'Brewmaster': -0.141 },
    { name: 'Medusa', 'Brewmaster': 0.452 },
    { name: 'Meepo', 'Brewmaster': 0.812 },
    { name: 'Mirana', 'Brewmaster': 1.314 },
    { name: 'Monkey King', 'Brewmaster': -0.428 },
    { name: 'Morphling', 'Brewmaster': 0.393 },
    { name: 'Muerta', 'Brewmaster': -1.465 },
    { name: 'Naga Siren', 'Brewmaster': -2.054 },
    { name: "Nature's Prophet", 'Brewmaster': 0.854 },
    { name: 'Necrophos', 'Brewmaster': 0.117 },
    { name: 'Night Stalker', 'Brewmaster': -0.664 },
    { name: 'Nyx Assassin', 'Brewmaster': -0.026 },
    { name: 'Ogre Magi', 'Brewmaster': -0.335 },
    { name: 'Omniknight', 'Brewmaster': 0.823 },
    { name: 'Oracle', 'Brewmaster': 0.514 },
    { name: 'Outworld Destroyer', 'Brewmaster': -1.021 },
    { name: 'Pangolier', 'Brewmaster': 1.293 },
    { name: 'Phantom Assassin', 'Brewmaster': -0.199 },
    { name: 'Phantom Lancer', 'Brewmaster': -0.145 },
    { name: 'Phoenix', 'Brewmaster': -0.253 },
    { name: 'Primal Beast', 'Brewmaster': -0.305 },
    { name: 'Puck', 'Brewmaster': 0.504 },
    { name: 'Pudge', 'Brewmaster': 0.388 },
    { name: 'Pugna', 'Brewmaster': 1.694 },
    { name: 'Queen of Pain', 'Brewmaster': 0.205 },
    { name: 'Razor', 'Brewmaster': -1.039 },
    { name: 'Riki', 'Brewmaster': 1.304 },
    { name: 'Ringmaster', 'Brewmaster': -0.993 },
    { name: 'Rubick', 'Brewmaster': 0.009 },
    { name: 'Sand King', 'Brewmaster': 1.973 },
    { name: 'Shadow Demon', 'Brewmaster': -0.93 },
    { name: 'Shadow Fiend', 'Brewmaster': -0.246 },
    { name: 'Shadow Shaman', 'Brewmaster': -0.009 },
    { name: 'Silencer', 'Brewmaster': -2.682 },
    { name: 'Skywrath Mage', 'Brewmaster': -0.751 },
    { name: 'Slardar', 'Brewmaster': 0.281 },
    { name: 'Slark', 'Brewmaster': 2.446 },
    { name: 'Snapfire', 'Brewmaster': -0.976 },
    { name: 'Sniper', 'Brewmaster': -0.056 },
    { name: 'Spectre', 'Brewmaster': -1.028 },
    { name: 'Spirit Breaker', 'Brewmaster': 0.355 },
    { name: 'Storm Spirit', 'Brewmaster': 0.892 },
    { name: 'Sven', 'Brewmaster': 2.095 },
    { name: 'Techies', 'Brewmaster': -0.032 },
    { name: 'Templar Assassin', 'Brewmaster': -0.332 },
    { name: 'Terrorblade', 'Brewmaster': -2.448 },
    { name: 'Tidehunter', 'Brewmaster': 0.607 },
    { name: 'Timbersaw', 'Brewmaster': 0.106 },
    { name: 'Tinker', 'Brewmaster': 2.413 },
    { name: 'Tiny', 'Brewmaster': -1.153 },
    { name: 'Treant Protector', 'Brewmaster': -0.127 },
    { name: 'Troll Warlord', 'Brewmaster': -1.568 },
    { name: 'Tusk', 'Brewmaster': 1.368 },
    { name: 'Underlord', 'Brewmaster': 0.255 },
    { name: 'Undying', 'Brewmaster': 0.241 },
    { name: 'Ursa', 'Brewmaster': -1.573 },
    { name: 'Vengeful Spirit', 'Brewmaster': -1.125 },
    { name: 'Venomancer', 'Brewmaster': -0.949 },
    { name: 'Viper', 'Brewmaster': -1.561 },
    { name: 'Visage', 'Brewmaster': -2.525 },
    { name: 'Void Spirit', 'Brewmaster': 0.735 },
    { name: 'Warlock', 'Brewmaster': -0.791 },
    { name: 'Weaver', 'Brewmaster': 3.591 },
    { name: 'Windranger', 'Brewmaster': 0.849 },
    { name: 'Winter Wyvern', 'Brewmaster': 0.746 },
    { name: 'Witch Doctor', 'Brewmaster': -0.948 },
    { name: 'Wraith King', 'Brewmaster': -2.12 },
    { name: 'Zeus', 'Brewmaster': -1.972 },
],
"Broodmother": [
    { name: 'Abaddon', 'Broodmother': -1.963 },
    { name: 'Alchemist', 'Broodmother': 0.875 },
    { name: 'Ancient Apparition', 'Broodmother': -0.85 },
    { name: 'Anti-Mage', 'Broodmother': -2.045 },
    { name: 'Arc Warden', 'Broodmother': -4.86 },
    { name: 'Axe', 'Broodmother': 3.729 },
    { name: 'Bane', 'Broodmother': 0.328 },
    { name: 'Batrider', 'Broodmother': 4.444 },
    { name: 'Beastmaster', 'Broodmother': 2.093 },
    { name: 'Bloodseeker', 'Broodmother': -1.359 },
    { name: 'Bounty Hunter', 'Broodmother': 1.306 },
    { name: 'Brewmaster', 'Broodmother': -0.841 },
    { name: 'Bristleback', 'Broodmother': 1.883 },
    { name: 'Centaur Warrunner', 'Broodmother': -0.455 },
    { name: 'Chaos Knight', 'Broodmother': 0.926 },
    { name: 'Chen', 'Broodmother': -0.741 },
    { name: 'Clinkz', 'Broodmother': -2.504 },
    { name: 'Clockwerk', 'Broodmother': -1.098 },
    { name: 'Crystal Maiden', 'Broodmother': 3.68 },
    { name: 'Dark Seer', 'Broodmother': 2.295 },
    { name: 'Dark Willow', 'Broodmother': -1.44 },
    { name: 'Dawnbreaker', 'Broodmother': 0.584 },
    { name: 'Dazzle', 'Broodmother': 0.92 },
    { name: 'Death Prophet', 'Broodmother': -1.503 },
    { name: 'Disruptor', 'Broodmother': 0.582 },
    { name: 'Doom', 'Broodmother': -2.333 },
    { name: 'Dragon Knight', 'Broodmother': 0.468 },
    { name: 'Drow Ranger', 'Broodmother': -0.472 },
    { name: 'Earth Spirit', 'Broodmother': -0.576 },
    { name: 'Earthshaker', 'Broodmother': 7.791 },
    { name: 'Elder Titan', 'Broodmother': -0.601 },
    { name: 'Ember Spirit', 'Broodmother': -3.583 },
    { name: 'Enchantress', 'Broodmother': 0.073 },
    { name: 'Enigma', 'Broodmother': 1.291 },
    { name: 'Faceless Void', 'Broodmother': -2.014 },
    { name: 'Grimstroke', 'Broodmother': 2.039 },
    { name: 'Gyrocopter', 'Broodmother': -0.099 },
    { name: 'Hoodwink', 'Broodmother': -0.502 },
    { name: 'Huskar', 'Broodmother': -0.039 },
    { name: 'Invoker', 'Broodmother': -3.214 },
    { name: 'Io', 'Broodmother': -0.544 },
    { name: 'Jakiro', 'Broodmother': 1.591 },
    { name: 'Juggernaut', 'Broodmother': -2.089 },
    { name: 'Keeper of the Light', 'Broodmother': -0.221 },
    { name: 'Kunkka', 'Broodmother': 1.864 },
    { name: 'Legion Commander', 'Broodmother': 1.435 },
    { name: 'Leshrac', 'Broodmother': -0.175 },
    { name: 'Lich', 'Broodmother': 0.329 },
    { name: 'Lifestealer', 'Broodmother': -4.038 },
    { name: 'Lina', 'Broodmother': 2.335 },
    { name: 'Lion', 'Broodmother': 1.347 },
    { name: 'Lone Druid', 'Broodmother': -3.007 },
    { name: 'Luna', 'Broodmother': -0.936 },
    { name: 'Lycan', 'Broodmother': -1.394 },
    { name: 'Magnus', 'Broodmother': 0.969 },
    { name: 'Marci', 'Broodmother': 0.621 },
    { name: 'Mars', 'Broodmother': 0.248 },
    { name: 'Medusa', 'Broodmother': -1.314 },
    { name: 'Meepo', 'Broodmother': 2.956 },
    { name: 'Mirana', 'Broodmother': -0.589 },
    { name: 'Monkey King', 'Broodmother': 2.485 },
    { name: 'Morphling', 'Broodmother': -0.759 },
    { name: 'Muerta', 'Broodmother': -1.529 },
    { name: 'Naga Siren', 'Broodmother': 4.736 },
    { name: "Nature's Prophet", 'Broodmother': -1.668 },
    { name: 'Necrophos', 'Broodmother': 1.389 },
    { name: 'Night Stalker', 'Broodmother': -3.063 },
    { name: 'Nyx Assassin', 'Broodmother': -1.144 },
    { name: 'Ogre Magi', 'Broodmother': -2.098 },
    { name: 'Omniknight', 'Broodmother': -0.911 },
    { name: 'Oracle', 'Broodmother': -2.103 },
    { name: 'Outworld Destroyer', 'Broodmother': -4.259 },
    { name: 'Pangolier', 'Broodmother': 0.469 },
    { name: 'Phantom Assassin', 'Broodmother': 2.644 },
    { name: 'Phantom Lancer', 'Broodmother': 2.559 },
    { name: 'Phoenix', 'Broodmother': 0.37 },
    { name: 'Primal Beast', 'Broodmother': 1.767 },
    { name: 'Puck', 'Broodmother': -3.148 },
    { name: 'Pudge', 'Broodmother': -1.83 },
    { name: 'Pugna', 'Broodmother': 0.538 },
    { name: 'Queen of Pain', 'Broodmother': -0.053 },
    { name: 'Razor', 'Broodmother': 0.309 },
    { name: 'Riki', 'Broodmother': -0.722 },
    { name: 'Ringmaster', 'Broodmother': -0.798 },
    { name: 'Rubick', 'Broodmother': -0.518 },
    { name: 'Sand King', 'Broodmother': 1.831 },
    { name: 'Shadow Demon', 'Broodmother': 1.154 },
    { name: 'Shadow Fiend', 'Broodmother': 2.551 },
    { name: 'Shadow Shaman', 'Broodmother': 0.595 },
    { name: 'Silencer', 'Broodmother': -2.459 },
    { name: 'Skywrath Mage', 'Broodmother': -1.944 },
    { name: 'Slardar', 'Broodmother': 1.634 },
    { name: 'Slark', 'Broodmother': 0.417 },
    { name: 'Snapfire', 'Broodmother': 0.102 },
    { name: 'Sniper', 'Broodmother': 0.292 },
    { name: 'Spectre', 'Broodmother': 1.185 },
    { name: 'Spirit Breaker', 'Broodmother': 4.037 },
    { name: 'Storm Spirit', 'Broodmother': 0.289 },
    { name: 'Sven', 'Broodmother': -0.021 },
    { name: 'Techies', 'Broodmother': 2.191 },
    { name: 'Templar Assassin', 'Broodmother': 0.086 },
    { name: 'Terrorblade', 'Broodmother': 5.873 },
    { name: 'Tidehunter', 'Broodmother': 0.384 },
    { name: 'Timbersaw', 'Broodmother': -1.025 },
    { name: 'Tinker', 'Broodmother': -1.329 },
    { name: 'Tiny', 'Broodmother': 2.453 },
    { name: 'Treant Protector', 'Broodmother': 0.154 },
    { name: 'Troll Warlord', 'Broodmother': 2.092 },
    { name: 'Tusk', 'Broodmother': 0.334 },
    { name: 'Underlord', 'Broodmother': -1.46 },
    { name: 'Undying', 'Broodmother': -1.283 },
    { name: 'Ursa', 'Broodmother': -1.527 },
    { name: 'Vengeful Spirit', 'Broodmother': -0.778 },
    { name: 'Venomancer', 'Broodmother': -0.323 },
    { name: 'Viper', 'Broodmother': -0.444 },
    { name: 'Visage', 'Broodmother': -0.123 },
    { name: 'Void Spirit', 'Broodmother': -1.666 },
    { name: 'Warlock', 'Broodmother': 0.926 },
    { name: 'Weaver', 'Broodmother': -1.477 },
    { name: 'Windranger', 'Broodmother': -0.045 },
    { name: 'Winter Wyvern', 'Broodmother': 3.153 },
    { name: 'Witch Doctor', 'Broodmother': -2.453 },
    { name: 'Wraith King', 'Broodmother': -2.323 },
    { name: 'Zeus', 'Broodmother': -1.912 },
],
"Chen": [
    { name: 'Abaddon', 'Chen': -0.35 },
    { name: 'Alchemist', 'Chen': 0.079 },
    { name: 'Ancient Apparition', 'Chen': -0.582 },
    { name: 'Anti-Mage', 'Chen': 0.971 },
    { name: 'Arc Warden', 'Chen': 1.171 },
    { name: 'Axe', 'Chen': 2.42 },
    { name: 'Bane', 'Chen': -0.417 },
    { name: 'Batrider', 'Chen': 2.851 },
    { name: 'Beastmaster', 'Chen': -3.276 },
    { name: 'Bloodseeker', 'Chen': -0.103 },
    { name: 'Bounty Hunter', 'Chen': 1.316 },
    { name: 'Brewmaster', 'Chen': 2.065 },
    { name: 'Bristleback', 'Chen': -0.032 },
    { name: 'Broodmother', 'Chen': 1.035 },
    { name: 'Centaur Warrunner', 'Chen': -3.107 },
    { name: 'Chaos Knight', 'Chen': 3.486 },
    { name: 'Clinkz', 'Chen': 1.146 },
    { name: 'Clockwerk', 'Chen': -1.166 },
    { name: 'Crystal Maiden', 'Chen': 2.841 },
    { name: 'Dark Seer', 'Chen': -0.625 },
    { name: 'Dark Willow', 'Chen': 1.135 },
    { name: 'Dawnbreaker', 'Chen': 2.007 },
    { name: 'Dazzle', 'Chen': 1.267 },
    { name: 'Death Prophet', 'Chen': 0.279 },
    { name: 'Disruptor', 'Chen': -0.601 },
    { name: 'Doom', 'Chen': 1.641 },
    { name: 'Dragon Knight', 'Chen': -0.634 },
    { name: 'Drow Ranger', 'Chen': -2.031 },
    { name: 'Earth Spirit', 'Chen': -0.268 },
    { name: 'Earthshaker', 'Chen': 2.144 },
    { name: 'Elder Titan', 'Chen': -0.779 },
    { name: 'Ember Spirit', 'Chen': -1.835 },
    { name: 'Enchantress', 'Chen': 0.308 },
    { name: 'Enigma', 'Chen': 0.584 },
    { name: 'Faceless Void', 'Chen': 0.212 },
    { name: 'Grimstroke', 'Chen': 0.291 },
    { name: 'Gyrocopter', 'Chen': -3.331 },
    { name: 'Hoodwink', 'Chen': -0.544 },
    { name: 'Huskar', 'Chen': 2.567 },
    { name: 'Invoker', 'Chen': 0.411 },
    { name: 'Io', 'Chen': 4.185 },
    { name: 'Jakiro', 'Chen': -0.354 },
    { name: 'Juggernaut', 'Chen': -1.907 },
    { name: 'Keeper of the Light', 'Chen': 3.529 },
    { name: 'Kunkka', 'Chen': 2.935 },
    { name: 'Legion Commander', 'Chen': 0.844 },
    { name: 'Leshrac', 'Chen': -0.074 },
    { name: 'Lich', 'Chen': 0.688 },
    { name: 'Lifestealer', 'Chen': -0.696 },
    { name: 'Lina', 'Chen': 0.179 },
    { name: 'Lion', 'Chen': 0.042 },
    { name: 'Lone Druid', 'Chen': 0.736 },
    { name: 'Luna', 'Chen': 0.777 },
    { name: 'Lycan', 'Chen': 0.896 },
    { name: 'Magnus', 'Chen': -0.879 },
    { name: 'Marci', 'Chen': 1.201 },
    { name: 'Mars', 'Chen': -1.028 },
    { name: 'Medusa', 'Chen': -1.565 },
    { name: 'Meepo', 'Chen': -0.928 },
    { name: 'Mirana', 'Chen': -2.454 },
    { name: 'Monkey King', 'Chen': 0.111 },
    { name: 'Morphling', 'Chen': -0.358 },
    { name: 'Muerta', 'Chen': -1.658 },
    { name: 'Naga Siren', 'Chen': 3.837 },
    { name: "Nature's Prophet", 'Chen': 1.469 },
    { name: 'Necrophos', 'Chen': 1.209 },
    { name: 'Night Stalker', 'Chen': -2.11 },
    { name: 'Nyx Assassin', 'Chen': -1.168 },
    { name: 'Ogre Magi', 'Chen': -0.783 },
    { name: 'Omniknight', 'Chen': -0.172 },
    { name: 'Oracle', 'Chen': -1.129 },
    { name: 'Outworld Destroyer', 'Chen': -0.5 },
    { name: 'Pangolier', 'Chen': -3.807 },
    { name: 'Phantom Assassin', 'Chen': 1.838 },
    { name: 'Phantom Lancer', 'Chen': 2.877 },
    { name: 'Phoenix', 'Chen': -0.163 },
    { name: 'Primal Beast', 'Chen': 0.573 },
    { name: 'Puck', 'Chen': -1.948 },
    { name: 'Pudge', 'Chen': 0.638 },
    { name: 'Pugna', 'Chen': -3.33 },
    { name: 'Queen of Pain', 'Chen': -0.982 },
    { name: 'Razor', 'Chen': -0.83 },
    { name: 'Riki', 'Chen': -0.261 },
    { name: 'Ringmaster', 'Chen': -2.529 },
    { name: 'Rubick', 'Chen': -0.595 },
    { name: 'Sand King', 'Chen': 0.441 },
    { name: 'Shadow Demon', 'Chen': -4.226 },
    { name: 'Shadow Fiend', 'Chen': 1.449 },
    { name: 'Shadow Shaman', 'Chen': 1.58 },
    { name: 'Silencer', 'Chen': -1.674 },
    { name: 'Skywrath Mage', 'Chen': -0.516 },
    { name: 'Slardar', 'Chen': 0.397 },
    { name: 'Slark', 'Chen': 2.436 },
    { name: 'Snapfire', 'Chen': 0.169 },
    { name: 'Sniper', 'Chen': -1.398 },
    { name: 'Spectre', 'Chen': -1.67 },
    { name: 'Spirit Breaker', 'Chen': 2.378 },
    { name: 'Storm Spirit', 'Chen': 0.001 },
    { name: 'Sven', 'Chen': 0.74 },
    { name: 'Techies', 'Chen': -0.451 },
    { name: 'Templar Assassin', 'Chen': 0.213 },
    { name: 'Terrorblade', 'Chen': 1.029 },
    { name: 'Tidehunter', 'Chen': -7.637 },
    { name: 'Timbersaw', 'Chen': 1.184 },
    { name: 'Tinker', 'Chen': -3.227 },
    { name: 'Tiny', 'Chen': 0.564 },
    { name: 'Treant Protector', 'Chen': 1.118 },
    { name: 'Troll Warlord', 'Chen': 1.973 },
    { name: 'Tusk', 'Chen': 0.165 },
    { name: 'Underlord', 'Chen': -0.522 },
    { name: 'Undying', 'Chen': -0.111 },
    { name: 'Ursa', 'Chen': -0.545 },
    { name: 'Vengeful Spirit', 'Chen': -0.881 },
    { name: 'Venomancer', 'Chen': -0.313 },
    { name: 'Viper', 'Chen': 1.705 },
    { name: 'Visage', 'Chen': 0.661 },
    { name: 'Void Spirit', 'Chen': -1.992 },
    { name: 'Warlock', 'Chen': 1.436 },
    { name: 'Weaver', 'Chen': 1.93 },
    { name: 'Windranger', 'Chen': 0.864 },
    { name: 'Winter Wyvern', 'Chen': -0.758 },
    { name: 'Witch Doctor', 'Chen': -0.21 },
    { name: 'Wraith King', 'Chen': -2.164 },
    { name: 'Zeus', 'Chen': -2.411 },
],
"Clockwerk": [
    { name: 'Abaddon', 'Clockwerk': -0.679 },
    { name: 'Alchemist', 'Clockwerk': 0.673 },
    { name: 'Ancient Apparition', 'Clockwerk': 0.651 },
    { name: 'Anti-Mage', 'Clockwerk': 2.51 },
    { name: 'Arc Warden', 'Clockwerk': 0.083 },
    { name: 'Axe', 'Clockwerk': -0.98 },
    { name: 'Bane', 'Clockwerk': -0.469 },
    { name: 'Batrider', 'Clockwerk': 2.06 },
    { name: 'Beastmaster', 'Clockwerk': 3.492 },
    { name: 'Bloodseeker', 'Clockwerk': -0.358 },
    { name: 'Bounty Hunter', 'Clockwerk': 0.049 },
    { name: 'Brewmaster', 'Clockwerk': -0.149 },
    { name: 'Bristleback', 'Clockwerk': -0.314 },
    { name: 'Broodmother', 'Clockwerk': 1.086 },
    { name: 'Centaur Warrunner', 'Clockwerk': -0.421 },
    { name: 'Chaos Knight', 'Clockwerk': 0.974 },
    { name: 'Chen', 'Clockwerk': 1.336 },
    { name: 'Clinkz', 'Clockwerk': -0.436 },
    { name: 'Crystal Maiden', 'Clockwerk': -0.516 },
    { name: 'Dark Seer', 'Clockwerk': 0.442 },
    { name: 'Dark Willow', 'Clockwerk': 0.244 },
    { name: 'Dawnbreaker', 'Clockwerk': -0.521 },
    { name: 'Dazzle', 'Clockwerk': 0.868 },
    { name: 'Death Prophet', 'Clockwerk': -0.32 },
    { name: 'Disruptor', 'Clockwerk': 1.136 },
    { name: 'Doom', 'Clockwerk': 0.199 },
    { name: 'Dragon Knight', 'Clockwerk': 0.395 },
    { name: 'Drow Ranger', 'Clockwerk': -1.769 },
    { name: 'Earth Spirit', 'Clockwerk': -0.187 },
    { name: 'Earthshaker', 'Clockwerk': -2.092 },
    { name: 'Elder Titan', 'Clockwerk': 0.476 },
    { name: 'Ember Spirit', 'Clockwerk': 2.637 },
    { name: 'Enchantress', 'Clockwerk': 0.564 },
    { name: 'Enigma', 'Clockwerk': 1.308 },
    { name: 'Faceless Void', 'Clockwerk': 1.427 },
    { name: 'Grimstroke', 'Clockwerk': 0.702 },
    { name: 'Gyrocopter', 'Clockwerk': -0.014 },
    { name: 'Hoodwink', 'Clockwerk': -0.856 },
    { name: 'Huskar', 'Clockwerk': 2.703 },
    { name: 'Invoker', 'Clockwerk': 0.55 },
    { name: 'Io', 'Clockwerk': 0.098 },
    { name: 'Jakiro', 'Clockwerk': 1.122 },
    { name: 'Juggernaut', 'Clockwerk': 1.898 },
    { name: 'Keeper of the Light', 'Clockwerk': 1.072 },
    { name: 'Kunkka', 'Clockwerk': 0.276 },
    { name: 'Legion Commander', 'Clockwerk': -0.081 },
    { name: 'Leshrac', 'Clockwerk': 0.044 },
    { name: 'Lich', 'Clockwerk': 0.78 },
    { name: 'Lifestealer', 'Clockwerk': 1.223 },
    { name: 'Lina', 'Clockwerk': 0.365 },
    { name: 'Lion', 'Clockwerk': -0.057 },
    { name: 'Lone Druid', 'Clockwerk': 1.957 },
    { name: 'Luna', 'Clockwerk': -0.391 },
    { name: 'Lycan', 'Clockwerk': 0.529 },
    { name: 'Magnus', 'Clockwerk': -0.156 },
    { name: 'Marci', 'Clockwerk': -0.252 },
    { name: 'Mars', 'Clockwerk': -0.045 },
    { name: 'Medusa', 'Clockwerk': -1.769 },
    { name: 'Meepo', 'Clockwerk': 2.545 },
    { name: 'Mirana', 'Clockwerk': 0.21 },
    { name: 'Monkey King', 'Clockwerk': 0.697 },
    { name: 'Morphling', 'Clockwerk': 2.091 },
    { name: 'Muerta', 'Clockwerk': -0.827 },
    { name: 'Naga Siren', 'Clockwerk': 1.405 },
    { name: "Nature's Prophet", 'Clockwerk': -0.546 },
    { name: 'Necrophos', 'Clockwerk': 0.641 },
    { name: 'Night Stalker', 'Clockwerk': -0.452 },
    { name: 'Nyx Assassin', 'Clockwerk': -0.26 },
    { name: 'Ogre Magi', 'Clockwerk': 0.185 },
    { name: 'Omniknight', 'Clockwerk': 1.114 },
    { name: 'Oracle', 'Clockwerk': 1.051 },
    { name: 'Outworld Destroyer', 'Clockwerk': 1.134 },
    { name: 'Pangolier', 'Clockwerk': 0.495 },
    { name: 'Phantom Assassin', 'Clockwerk': -0.718 },
    { name: 'Phantom Lancer', 'Clockwerk': 2.351 },
    { name: 'Phoenix', 'Clockwerk': 0.727 },
    { name: 'Primal Beast', 'Clockwerk': -2.352 },
    { name: 'Puck', 'Clockwerk': 0.529 },
    { name: 'Pudge', 'Clockwerk': -1.409 },
    { name: 'Pugna', 'Clockwerk': -0.736 },
    { name: 'Queen of Pain', 'Clockwerk': 1.61 },
    { name: 'Razor', 'Clockwerk': -1.774 },
    { name: 'Riki', 'Clockwerk': -1.116 },
    { name: 'Ringmaster', 'Clockwerk': 0.707 },
    { name: 'Rubick', 'Clockwerk': -0.527 },
    { name: 'Sand King', 'Clockwerk': 0.316 },
    { name: 'Shadow Demon', 'Clockwerk': 0.997 },
    { name: 'Shadow Fiend', 'Clockwerk': -1.063 },
    { name: 'Shadow Shaman', 'Clockwerk': -0.223 },
    { name: 'Silencer', 'Clockwerk': -1.058 },
    { name: 'Skywrath Mage', 'Clockwerk': 0.348 },
    { name: 'Slardar', 'Clockwerk': -0.218 },
    { name: 'Slark', 'Clockwerk': -2.227 },
    { name: 'Snapfire', 'Clockwerk': 0.981 },
    { name: 'Sniper', 'Clockwerk': -2.202 },
    { name: 'Spectre', 'Clockwerk': -1.041 },
    { name: 'Spirit Breaker', 'Clockwerk': -3.063 },
    { name: 'Storm Spirit', 'Clockwerk': 1.594 },
    { name: 'Sven', 'Clockwerk': -0.465 },
    { name: 'Techies', 'Clockwerk': -0.615 },
    { name: 'Templar Assassin', 'Clockwerk': -0.116 },
    { name: 'Terrorblade', 'Clockwerk': 1.157 },
    { name: 'Tidehunter', 'Clockwerk': -0.883 },
    { name: 'Timbersaw', 'Clockwerk': 0.252 },
    { name: 'Tinker', 'Clockwerk': 1.306 },
    { name: 'Tiny', 'Clockwerk': -1.223 },
    { name: 'Treant Protector', 'Clockwerk': 0.068 },
    { name: 'Troll Warlord', 'Clockwerk': 0.222 },
    { name: 'Tusk', 'Clockwerk': 0.798 },
    { name: 'Underlord', 'Clockwerk': 1.19 },
    { name: 'Undying', 'Clockwerk': 1.419 },
    { name: 'Ursa', 'Clockwerk': 0.644 },
    { name: 'Vengeful Spirit', 'Clockwerk': 0.103 },
    { name: 'Venomancer', 'Clockwerk': 1.678 },
    { name: 'Viper', 'Clockwerk': 1.588 },
    { name: 'Visage', 'Clockwerk': 0.878 },
    { name: 'Void Spirit', 'Clockwerk': 1.363 },
    { name: 'Warlock', 'Clockwerk': 0.437 },
    { name: 'Weaver', 'Clockwerk': -0.406 },
    { name: 'Windranger', 'Clockwerk': -1.4 },
    { name: 'Winter Wyvern', 'Clockwerk': 0.359 },
    { name: 'Witch Doctor', 'Clockwerk': 1.099 },
    { name: 'Wraith King', 'Clockwerk': 0.16 },
    { name: 'Zeus', 'Clockwerk': -0.44 },
],
"Dark Seer": [
    { name: 'Abaddon', 'Dark Seer': -2.424 },
    { name: 'Alchemist', 'Dark Seer': -0.122 },
    { name: 'Ancient Apparition', 'Dark Seer': -0.329 },
    { name: 'Anti-Mage', 'Dark Seer': 1.397 },
    { name: 'Arc Warden', 'Dark Seer': -0.426 },
    { name: 'Axe', 'Dark Seer': 1.299 },
    { name: 'Bane', 'Dark Seer': -0.847 },
    { name: 'Batrider', 'Dark Seer': 2.717 },
    { name: 'Beastmaster', 'Dark Seer': 0.058 },
    { name: 'Bloodseeker', 'Dark Seer': 1.91 },
    { name: 'Bounty Hunter', 'Dark Seer': -0.126 },
    { name: 'Brewmaster', 'Dark Seer': -1.955 },
    { name: 'Bristleback', 'Dark Seer': 0.69 },
    { name: 'Broodmother', 'Dark Seer': -2.282 },
    { name: 'Centaur Warrunner', 'Dark Seer': -0.248 },
    { name: 'Chaos Knight', 'Dark Seer': -4.336 },
    { name: 'Chen', 'Dark Seer': 0.724 },
    { name: 'Clinkz', 'Dark Seer': 1.539 },
    { name: 'Clockwerk', 'Dark Seer': -0.485 },
    { name: 'Crystal Maiden', 'Dark Seer': 1.132 },
    { name: 'Dark Willow', 'Dark Seer': 1.006 },
    { name: 'Dawnbreaker', 'Dark Seer': -0.495 },
    { name: 'Dazzle', 'Dark Seer': -0.493 },
    { name: 'Death Prophet', 'Dark Seer': -0.784 },
    { name: 'Disruptor', 'Dark Seer': 1.179 },
    { name: 'Doom', 'Dark Seer': 2.428 },
    { name: 'Dragon Knight', 'Dark Seer': -1.494 },
    { name: 'Drow Ranger', 'Dark Seer': -2.59 },
    { name: 'Earth Spirit', 'Dark Seer': 0.751 },
    { name: 'Earthshaker', 'Dark Seer': 2.462 },
    { name: 'Elder Titan', 'Dark Seer': -2.003 },
    { name: 'Ember Spirit', 'Dark Seer': 2.333 },
    { name: 'Enchantress', 'Dark Seer': 1.72 },
    { name: 'Enigma', 'Dark Seer': 0.932 },
    { name: 'Faceless Void', 'Dark Seer': 2.428 },
    { name: 'Grimstroke', 'Dark Seer': 0.798 },
    { name: 'Gyrocopter', 'Dark Seer': 0.938 },
    { name: 'Hoodwink', 'Dark Seer': 1.22 },
    { name: 'Huskar', 'Dark Seer': -0.678 },
    { name: 'Invoker', 'Dark Seer': 0.365 },
    { name: 'Io', 'Dark Seer': -0.135 },
    { name: 'Jakiro', 'Dark Seer': 0.588 },
    { name: 'Juggernaut', 'Dark Seer': 0.042 },
    { name: 'Keeper of the Light', 'Dark Seer': 1.56 },
    { name: 'Kunkka', 'Dark Seer': -0.308 },
    { name: 'Legion Commander', 'Dark Seer': 1.27 },
    { name: 'Leshrac', 'Dark Seer': 1.404 },
    { name: 'Lich', 'Dark Seer': 0.021 },
    { name: 'Lifestealer', 'Dark Seer': -0.586 },
    { name: 'Lina', 'Dark Seer': 2.541 },
    { name: 'Lion', 'Dark Seer': -0.684 },
    { name: 'Lone Druid', 'Dark Seer': -1.931 },
    { name: 'Luna', 'Dark Seer': -2.39 },
    { name: 'Lycan', 'Dark Seer': -1.209 },
    { name: 'Magnus', 'Dark Seer': -0.117 },
    { name: 'Marci', 'Dark Seer': 0.427 },
    { name: 'Mars', 'Dark Seer': -0.771 },
    { name: 'Medusa', 'Dark Seer': -6.72 },
    { name: 'Meepo', 'Dark Seer': 0.21 },
    { name: 'Mirana', 'Dark Seer': 0.325 },
    { name: 'Monkey King', 'Dark Seer': 1.804 },
    { name: 'Morphling', 'Dark Seer': -0.263 },
    { name: 'Muerta', 'Dark Seer': -0.992 },
    { name: 'Naga Siren', 'Dark Seer': -1.353 },
    { name: "Nature's Prophet", 'Dark Seer': 1.362 },
    { name: 'Necrophos', 'Dark Seer': -2.043 },
    { name: 'Night Stalker', 'Dark Seer': -0.981 },
    { name: 'Nyx Assassin', 'Dark Seer': 0.159 },
    { name: 'Ogre Magi', 'Dark Seer': -1.075 },
    { name: 'Omniknight', 'Dark Seer': -1.37 },
    { name: 'Oracle', 'Dark Seer': 2.493 },
    { name: 'Outworld Destroyer', 'Dark Seer': 1.093 },
    { name: 'Pangolier', 'Dark Seer': 0.817 },
    { name: 'Phantom Assassin', 'Dark Seer': 0.895 },
    { name: 'Phantom Lancer', 'Dark Seer': -3.641 },
    { name: 'Phoenix', 'Dark Seer': -0.144 },
    { name: 'Primal Beast', 'Dark Seer': -0.292 },
    { name: 'Puck', 'Dark Seer': 1.427 },
    { name: 'Pudge', 'Dark Seer': -0.327 },
    { name: 'Pugna', 'Dark Seer': 0.857 },
    { name: 'Queen of Pain', 'Dark Seer': 1.665 },
    { name: 'Razor', 'Dark Seer': -1.206 },
    { name: 'Riki', 'Dark Seer': -1.362 },
    { name: 'Ringmaster', 'Dark Seer': 0.123 },
    { name: 'Rubick', 'Dark Seer': 1.241 },
    { name: 'Sand King', 'Dark Seer': 1.272 },
    { name: 'Shadow Demon', 'Dark Seer': -0.161 },
    { name: 'Shadow Fiend', 'Dark Seer': -0.237 },
    { name: 'Shadow Shaman', 'Dark Seer': -2.267 },
    { name: 'Silencer', 'Dark Seer': -0.987 },
    { name: 'Skywrath Mage', 'Dark Seer': -0.025 },
    { name: 'Slardar', 'Dark Seer': 0.155 },
    { name: 'Slark', 'Dark Seer': -0.505 },
    { name: 'Snapfire', 'Dark Seer': -0.055 },
    { name: 'Sniper', 'Dark Seer': -0.086 },
    { name: 'Spectre', 'Dark Seer': -1.693 },
    { name: 'Spirit Breaker', 'Dark Seer': -0.863 },
    { name: 'Storm Spirit', 'Dark Seer': -0.207 },
    { name: 'Sven', 'Dark Seer': 1.92 },
    { name: 'Techies', 'Dark Seer': -0.275 },
    { name: 'Templar Assassin', 'Dark Seer': -1.816 },
    { name: 'Terrorblade', 'Dark Seer': -3.035 },
    { name: 'Tidehunter', 'Dark Seer': -1.346 },
    { name: 'Timbersaw', 'Dark Seer': 2.283 },
    { name: 'Tinker', 'Dark Seer': 0.303 },
    { name: 'Tiny', 'Dark Seer': 0.191 },
    { name: 'Treant Protector', 'Dark Seer': -0.609 },
    { name: 'Troll Warlord', 'Dark Seer': -0.453 },
    { name: 'Tusk', 'Dark Seer': -0.986 },
    { name: 'Underlord', 'Dark Seer': -0.19 },
    { name: 'Undying', 'Dark Seer': -0.061 },
    { name: 'Ursa', 'Dark Seer': 2.849 },
    { name: 'Vengeful Spirit', 'Dark Seer': -1.652 },
    { name: 'Venomancer', 'Dark Seer': -2.593 },
    { name: 'Viper', 'Dark Seer': -3.631 },
    { name: 'Visage', 'Dark Seer': -1.302 },
    { name: 'Void Spirit', 'Dark Seer': -0.807 },
    { name: 'Warlock', 'Dark Seer': 0.249 },
    { name: 'Weaver', 'Dark Seer': 2.577 },
    { name: 'Windranger', 'Dark Seer': 2.578 },
    { name: 'Winter Wyvern', 'Dark Seer': 1.125 },
    { name: 'Witch Doctor', 'Dark Seer': 1.426 },
    { name: 'Wraith King', 'Dark Seer': -2.934 },
    { name: 'Zeus', 'Dark Seer': -0.922 },
],
"Dark Willow": [
    { name: 'Abaddon', 'Dark Willow': -0.719 },
    { name: 'Alchemist', 'Dark Willow': 0.435 },
    { name: 'Ancient Apparition', 'Dark Willow': -0.006 },
    { name: 'Anti-Mage', 'Dark Willow': 0.766 },
    { name: 'Arc Warden', 'Dark Willow': 1.128 },
    { name: 'Axe', 'Dark Willow': 2.374 },
    { name: 'Bane', 'Dark Willow': -0.3 },
    { name: 'Batrider', 'Dark Willow': -0.858 },
    { name: 'Beastmaster', 'Dark Willow': 0.098 },
    { name: 'Bloodseeker', 'Dark Willow': -0.257 },
    { name: 'Bounty Hunter', 'Dark Willow': -1.08 },
    { name: 'Brewmaster', 'Dark Willow': 0.505 },
    { name: 'Bristleback', 'Dark Willow': 1.04 },
    { name: 'Broodmother', 'Dark Willow': 1.31 },
    { name: 'Centaur Warrunner', 'Dark Willow': -0.516 },
    { name: 'Chaos Knight', 'Dark Willow': 0.139 },
    { name: 'Chen', 'Dark Willow': -0.915 },
    { name: 'Clinkz', 'Dark Willow': -0.134 },
    { name: 'Clockwerk', 'Dark Willow': -0.231 },
    { name: 'Crystal Maiden', 'Dark Willow': 0.849 },
    { name: 'Dark Seer', 'Dark Willow': -1.02 },
    { name: 'Dawnbreaker', 'Dark Willow': -2.021 },
    { name: 'Dazzle', 'Dark Willow': 0.087 },
    { name: 'Death Prophet', 'Dark Willow': -1.11 },
    { name: 'Disruptor', 'Dark Willow': -0.55 },
    { name: 'Doom', 'Dark Willow': -0.412 },
    { name: 'Dragon Knight', 'Dark Willow': -0.408 },
    { name: 'Drow Ranger', 'Dark Willow': -0.276 },
    { name: 'Earth Spirit', 'Dark Willow': 0.053 },
    { name: 'Earthshaker', 'Dark Willow': -0.186 },
    { name: 'Elder Titan', 'Dark Willow': -0.238 },
    { name: 'Ember Spirit', 'Dark Willow': -1.923 },
    { name: 'Enchantress', 'Dark Willow': 0.467 },
    { name: 'Enigma', 'Dark Willow': 1.028 },
    { name: 'Faceless Void', 'Dark Willow': -0.014 },
    { name: 'Grimstroke', 'Dark Willow': -0.536 },
    { name: 'Gyrocopter', 'Dark Willow': -0.224 },
    { name: 'Hoodwink', 'Dark Willow': 0.593 },
    { name: 'Huskar', 'Dark Willow': 0.505 },
    { name: 'Invoker', 'Dark Willow': 0.242 },
    { name: 'Io', 'Dark Willow': -1.287 },
    { name: 'Jakiro', 'Dark Willow': 0.582 },
    { name: 'Juggernaut', 'Dark Willow': 1.732 },
    { name: 'Keeper of the Light', 'Dark Willow': 0.095 },
    { name: 'Kunkka', 'Dark Willow': -0.174 },
    { name: 'Legion Commander', 'Dark Willow': 1.372 },
    { name: 'Leshrac', 'Dark Willow': 0.094 },
    { name: 'Lich', 'Dark Willow': -0.64 },
    { name: 'Lifestealer', 'Dark Willow': 0.26 },
    { name: 'Lina', 'Dark Willow': -0.003 },
    { name: 'Lion', 'Dark Willow': 0.439 },
    { name: 'Lone Druid', 'Dark Willow': -2.163 },
    { name: 'Luna', 'Dark Willow': -0.022 },
    { name: 'Lycan', 'Dark Willow': -1.0 },
    { name: 'Magnus', 'Dark Willow': -0.437 },
    { name: 'Marci', 'Dark Willow': -2.282 },
    { name: 'Mars', 'Dark Willow': -0.426 },
    { name: 'Medusa', 'Dark Willow': -0.959 },
    { name: 'Meepo', 'Dark Willow': -1.324 },
    { name: 'Mirana', 'Dark Willow': -0.458 },
    { name: 'Monkey King', 'Dark Willow': -0.216 },
    { name: 'Morphling', 'Dark Willow': 0.524 },
    { name: 'Muerta', 'Dark Willow': 0.454 },
    { name: 'Naga Siren', 'Dark Willow': 3.45 },
    { name: "Nature's Prophet", 'Dark Willow': 0.793 },
    { name: 'Necrophos', 'Dark Willow': -0.185 },
    { name: 'Night Stalker', 'Dark Willow': -2.077 },
    { name: 'Nyx Assassin', 'Dark Willow': -0.056 },
    { name: 'Ogre Magi', 'Dark Willow': -0.593 },
    { name: 'Omniknight', 'Dark Willow': 0.073 },
    { name: 'Oracle', 'Dark Willow': 0.399 },
    { name: 'Outworld Destroyer', 'Dark Willow': -0.785 },
    { name: 'Pangolier', 'Dark Willow': -0.534 },
    { name: 'Phantom Assassin', 'Dark Willow': 0.69 },
    { name: 'Phantom Lancer', 'Dark Willow': 1.626 },
    { name: 'Phoenix', 'Dark Willow': 0.229 },
    { name: 'Primal Beast', 'Dark Willow': -1.122 },
    { name: 'Puck', 'Dark Willow': 1.497 },
    { name: 'Pudge', 'Dark Willow': 0.316 },
    { name: 'Pugna', 'Dark Willow': 0.57 },
    { name: 'Queen of Pain', 'Dark Willow': 0.803 },
    { name: 'Razor', 'Dark Willow': -1.556 },
    { name: 'Riki', 'Dark Willow': 0.495 },
    { name: 'Ringmaster', 'Dark Willow': -0.037 },
    { name: 'Rubick', 'Dark Willow': -0.598 },
    { name: 'Sand King', 'Dark Willow': 0.434 },
    { name: 'Shadow Demon', 'Dark Willow': 0.349 },
    { name: 'Shadow Fiend', 'Dark Willow': 0.908 },
    { name: 'Shadow Shaman', 'Dark Willow': 0.617 },
    { name: 'Silencer', 'Dark Willow': -0.428 },
    { name: 'Skywrath Mage', 'Dark Willow': -0.112 },
    { name: 'Slardar', 'Dark Willow': -1.368 },
    { name: 'Slark', 'Dark Willow': -0.548 },
    { name: 'Snapfire', 'Dark Willow': 0.317 },
    { name: 'Sniper', 'Dark Willow': 0.176 },
    { name: 'Spectre', 'Dark Willow': 0.338 },
    { name: 'Spirit Breaker', 'Dark Willow': -1.076 },
    { name: 'Storm Spirit', 'Dark Willow': -0.505 },
    { name: 'Sven', 'Dark Willow': -1.422 },
    { name: 'Techies', 'Dark Willow': 1.276 },
    { name: 'Templar Assassin', 'Dark Willow': -0.663 },
    { name: 'Terrorblade', 'Dark Willow': 0.493 },
    { name: 'Tidehunter', 'Dark Willow': -0.486 },
    { name: 'Timbersaw', 'Dark Willow': -0.422 },
    { name: 'Tinker', 'Dark Willow': -0.036 },
    { name: 'Tiny', 'Dark Willow': 0.078 },
    { name: 'Treant Protector', 'Dark Willow': -0.256 },
    { name: 'Troll Warlord', 'Dark Willow': -0.864 },
    { name: 'Tusk', 'Dark Willow': -1.57 },
    { name: 'Underlord', 'Dark Willow': 0.602 },
    { name: 'Undying', 'Dark Willow': -0.003 },
    { name: 'Ursa', 'Dark Willow': -0.341 },
    { name: 'Vengeful Spirit', 'Dark Willow': -1.062 },
    { name: 'Venomancer', 'Dark Willow': 0.426 },
    { name: 'Viper', 'Dark Willow': 0.366 },
    { name: 'Visage', 'Dark Willow': -1.765 },
    { name: 'Void Spirit', 'Dark Willow': -0.013 },
    { name: 'Warlock', 'Dark Willow': 0.123 },
    { name: 'Weaver', 'Dark Willow': -2.303 },
    { name: 'Windranger', 'Dark Willow': -0.824 },
    { name: 'Winter Wyvern', 'Dark Willow': 0.276 },
    { name: 'Witch Doctor', 'Dark Willow': -1.146 },
    { name: 'Wraith King', 'Dark Willow': 0.731 },
    { name: 'Zeus', 'Dark Willow': -0.457 },
],
"Dazzle": [
    { name: 'Abaddon', 'Dazzle': 0.961 },
    { name: 'Alchemist', 'Dazzle': -0.434 },
    { name: 'Ancient Apparition', 'Dazzle': 2.192 },
    { name: 'Anti-Mage', 'Dazzle': -0.497 },
    { name: 'Arc Warden', 'Dazzle': -1.853 },
    { name: 'Axe', 'Dazzle': 1.597 },
    { name: 'Bane', 'Dazzle': 0.121 },
    { name: 'Batrider', 'Dazzle': 0.22 },
    { name: 'Beastmaster', 'Dazzle': -0.805 },
    { name: 'Bloodseeker', 'Dazzle': -0.616 },
    { name: 'Bounty Hunter', 'Dazzle': 0.315 },
    { name: 'Brewmaster', 'Dazzle': -0.477 },
    { name: 'Bristleback', 'Dazzle': -1.444 },
    { name: 'Broodmother', 'Dazzle': -1.028 },
    { name: 'Centaur Warrunner', 'Dazzle': -2.333 },
    { name: 'Chaos Knight', 'Dazzle': -0.971 },
    { name: 'Chen', 'Dazzle': -0.869 },
    { name: 'Clinkz', 'Dazzle': -1.769 },
    { name: 'Clockwerk', 'Dazzle': -0.956 },
    { name: 'Crystal Maiden', 'Dazzle': 0.451 },
    { name: 'Dark Seer', 'Dazzle': 0.289 },
    { name: 'Dark Willow', 'Dazzle': -0.134 },
    { name: 'Dawnbreaker', 'Dazzle': 0.293 },
    { name: 'Death Prophet', 'Dazzle': 0.929 },
    { name: 'Disruptor', 'Dazzle': -0.621 },
    { name: 'Doom', 'Dazzle': 0.255 },
    { name: 'Dragon Knight', 'Dazzle': -0.412 },
    { name: 'Drow Ranger', 'Dazzle': 0.722 },
    { name: 'Earth Spirit', 'Dazzle': 0.717 },
    { name: 'Earthshaker', 'Dazzle': -0.003 },
    { name: 'Elder Titan', 'Dazzle': -0.826 },
    { name: 'Ember Spirit', 'Dazzle': -0.819 },
    { name: 'Enchantress', 'Dazzle': -0.996 },
    { name: 'Enigma', 'Dazzle': -0.599 },
    { name: 'Faceless Void', 'Dazzle': 0.7 },
    { name: 'Grimstroke', 'Dazzle': 0.066 },
    { name: 'Gyrocopter', 'Dazzle': 1.764 },
    { name: 'Hoodwink', 'Dazzle': 0.094 },
    { name: 'Huskar', 'Dazzle': -0.133 },
    { name: 'Invoker', 'Dazzle': -0.76 },
    { name: 'Io', 'Dazzle': -0.509 },
    { name: 'Jakiro', 'Dazzle': 0.532 },
    { name: 'Juggernaut', 'Dazzle': -0.505 },
    { name: 'Keeper of the Light', 'Dazzle': -0.044 },
    { name: 'Kunkka', 'Dazzle': -0.95 },
    { name: 'Legion Commander', 'Dazzle': -1.808 },
    { name: 'Leshrac', 'Dazzle': -0.668 },
    { name: 'Lich', 'Dazzle': -0.379 },
    { name: 'Lifestealer', 'Dazzle': -1.929 },
    { name: 'Lina', 'Dazzle': -0.532 },
    { name: 'Lion', 'Dazzle': -1.078 },
    { name: 'Lone Druid', 'Dazzle': -1.322 },
    { name: 'Luna', 'Dazzle': 0.024 },
    { name: 'Lycan', 'Dazzle': -0.772 },
    { name: 'Magnus', 'Dazzle': 0.184 },
    { name: 'Marci', 'Dazzle': 0.055 },
    { name: 'Mars', 'Dazzle': 0.565 },
    { name: 'Medusa', 'Dazzle': -1.623 },
    { name: 'Meepo', 'Dazzle': -2.641 },
    { name: 'Mirana', 'Dazzle': 0.618 },
    { name: 'Monkey King', 'Dazzle': 0.874 },
    { name: 'Morphling', 'Dazzle': 0.217 },
    { name: 'Muerta', 'Dazzle': 0.126 },
    { name: 'Naga Siren', 'Dazzle': -3.636 },
    { name: "Nature's Prophet", 'Dazzle': -0.383 },
    { name: 'Necrophos', 'Dazzle': -1.482 },
    { name: 'Night Stalker', 'Dazzle': 0.013 },
    { name: 'Nyx Assassin', 'Dazzle': -0.63 },
    { name: 'Ogre Magi', 'Dazzle': 0.201 },
    { name: 'Omniknight', 'Dazzle': -0.835 },
    { name: 'Oracle', 'Dazzle': -1.495 },
    { name: 'Outworld Destroyer', 'Dazzle': 0.379 },
    { name: 'Pangolier', 'Dazzle': -0.528 },
    { name: 'Phantom Assassin', 'Dazzle': 0.151 },
    { name: 'Phantom Lancer', 'Dazzle': 0.957 },
    { name: 'Phoenix', 'Dazzle': 0.446 },
    { name: 'Primal Beast', 'Dazzle': 0.459 },
    { name: 'Puck', 'Dazzle': 0.538 },
    { name: 'Pudge', 'Dazzle': -0.292 },
    { name: 'Pugna', 'Dazzle': 0.635 },
    { name: 'Queen of Pain', 'Dazzle': 0.253 },
    { name: 'Razor', 'Dazzle': 1.752 },
    { name: 'Riki', 'Dazzle': 1.6 },
    { name: 'Ringmaster', 'Dazzle': -0.695 },
    { name: 'Rubick', 'Dazzle': -1.285 },
    { name: 'Sand King', 'Dazzle': 1.129 },
    { name: 'Shadow Demon', 'Dazzle': -0.448 },
    { name: 'Shadow Fiend', 'Dazzle': 0.658 },
    { name: 'Shadow Shaman', 'Dazzle': -0.061 },
    { name: 'Silencer', 'Dazzle': 0.452 },
    { name: 'Skywrath Mage', 'Dazzle': -1.002 },
    { name: 'Slardar', 'Dazzle': -0.161 },
    { name: 'Slark', 'Dazzle': 1.766 },
    { name: 'Snapfire', 'Dazzle': 0.321 },
    { name: 'Sniper', 'Dazzle': 0.504 },
    { name: 'Spectre', 'Dazzle': -1.353 },
    { name: 'Spirit Breaker', 'Dazzle': -0.224 },
    { name: 'Storm Spirit', 'Dazzle': -1.182 },
    { name: 'Sven', 'Dazzle': 0.216 },
    { name: 'Techies', 'Dazzle': -0.168 },
    { name: 'Templar Assassin', 'Dazzle': -0.22 },
    { name: 'Terrorblade', 'Dazzle': -1.069 },
    { name: 'Tidehunter', 'Dazzle': 1.649 },
    { name: 'Timbersaw', 'Dazzle': -0.553 },
    { name: 'Tinker', 'Dazzle': -0.62 },
    { name: 'Tiny', 'Dazzle': -0.029 },
    { name: 'Treant Protector', 'Dazzle': -0.08 },
    { name: 'Troll Warlord', 'Dazzle': 0.59 },
    { name: 'Tusk', 'Dazzle': 0.499 },
    { name: 'Underlord', 'Dazzle': 0.028 },
    { name: 'Undying', 'Dazzle': 0.619 },
    { name: 'Ursa', 'Dazzle': -0.991 },
    { name: 'Vengeful Spirit', 'Dazzle': -0.239 },
    { name: 'Venomancer', 'Dazzle': 1.464 },
    { name: 'Viper', 'Dazzle': 1.118 },
    { name: 'Visage', 'Dazzle': -0.891 },
    { name: 'Void Spirit', 'Dazzle': -0.705 },
    { name: 'Warlock', 'Dazzle': 0.334 },
    { name: 'Weaver', 'Dazzle': 1.708 },
    { name: 'Windranger', 'Dazzle': 0.177 },
    { name: 'Winter Wyvern', 'Dazzle': 0.461 },
    { name: 'Witch Doctor', 'Dazzle': -0.076 },
    { name: 'Wraith King', 'Dazzle': -2.141 },
    { name: 'Zeus', 'Dazzle': -0.152 },
],
"Enigma": [
    { name: 'Abaddon', 'Enigma': -0.504 },
    { name: 'Alchemist', 'Enigma': 2.464 },
    { name: 'Ancient Apparition', 'Enigma': -0.964 },
    { name: 'Anti-Mage', 'Enigma': -0.899 },
    { name: 'Arc Warden', 'Enigma': -1.134 },
    { name: 'Axe', 'Enigma': 1.697 },
    { name: 'Bane', 'Enigma': 0.259 },
    { name: 'Batrider', 'Enigma': -0.178 },
    { name: 'Beastmaster', 'Enigma': 1.242 },
    { name: 'Bloodseeker', 'Enigma': -0.276 },
    { name: 'Bounty Hunter', 'Enigma': -1.68 },
    { name: 'Brewmaster', 'Enigma': 0.26 },
    { name: 'Bristleback', 'Enigma': 3.712 },
    { name: 'Broodmother', 'Enigma': -1.235 },
    { name: 'Centaur Warrunner', 'Enigma': -1.383 },
    { name: 'Chaos Knight', 'Enigma': -1.552 },
    { name: 'Chen', 'Enigma': -0.398 },
    { name: 'Clinkz', 'Enigma': 2.078 },
    { name: 'Clockwerk', 'Enigma': -1.253 },
    { name: 'Crystal Maiden', 'Enigma': -1.7 },
    { name: 'Dark Seer', 'Enigma': -0.899 },
    { name: 'Dark Willow', 'Enigma': -1.031 },
    { name: 'Dawnbreaker', 'Enigma': 0.683 },
    { name: 'Dazzle', 'Enigma': 0.513 },
    { name: 'Death Prophet', 'Enigma': -0.143 },
    { name: 'Disruptor', 'Enigma': 0.109 },
    { name: 'Doom', 'Enigma': -1.576 },
    { name: 'Dragon Knight', 'Enigma': -0.437 },
    { name: 'Drow Ranger', 'Enigma': 0.928 },
    { name: 'Earth Spirit', 'Enigma': -2.296 },
    { name: 'Earthshaker', 'Enigma': 0.857 },
    { name: 'Elder Titan', 'Enigma': 0.354 },
    { name: 'Ember Spirit', 'Enigma': -1.341 },
    { name: 'Enchantress', 'Enigma': 0.492 },
    { name: 'Faceless Void', 'Enigma': 0.06 },
    { name: 'Grimstroke', 'Enigma': 0.684 },
    { name: 'Gyrocopter', 'Enigma': 1.695 },
    { name: 'Hoodwink', 'Enigma': -0.153 },
    { name: 'Huskar', 'Enigma': -1.747 },
    { name: 'Invoker', 'Enigma': -0.655 },
    { name: 'Io', 'Enigma': -0.506 },
    { name: 'Jakiro', 'Enigma': 1.049 },
    { name: 'Juggernaut', 'Enigma': -0.75 },
    { name: 'Keeper of the Light', 'Enigma': -0.049 },
    { name: 'Kunkka', 'Enigma': 1.433 },
    { name: 'Legion Commander', 'Enigma': 0.763 },
    { name: 'Leshrac', 'Enigma': 0.122 },
    { name: 'Lich', 'Enigma': -0.2 },
    { name: 'Lifestealer', 'Enigma': -3.336 },
    { name: 'Lina', 'Enigma': 0.899 },
    { name: 'Lion', 'Enigma': -0.212 },
    { name: 'Lone Druid', 'Enigma': 0.055 },
    { name: 'Luna', 'Enigma': -0.114 },
    { name: 'Lycan', 'Enigma': -1.707 },
    { name: 'Magnus', 'Enigma': -2.484 },
    { name: 'Marci', 'Enigma': -2.29 },
    { name: 'Mars', 'Enigma': -1.451 },
    { name: 'Medusa', 'Enigma': 3.806 },
    { name: 'Meepo', 'Enigma': -1.936 },
    { name: 'Mirana', 'Enigma': -0.916 },
    { name: 'Monkey King', 'Enigma': -0.68 },
    { name: 'Morphling', 'Enigma': 0.526 },
    { name: 'Muerta', 'Enigma': 0.647 },
    { name: 'Naga Siren', 'Enigma': 0.032 },
    { name: "Nature's Prophet", 'Enigma': 1.269 },
    { name: 'Necrophos', 'Enigma': 1.279 },
    { name: 'Night Stalker', 'Enigma': -1.406 },
    { name: 'Nyx Assassin', 'Enigma': -0.146 },
    { name: 'Ogre Magi', 'Enigma': -1.788 },
    { name: 'Omniknight', 'Enigma': -2.255 },
    { name: 'Oracle', 'Enigma': -2.458 },
    { name: 'Outworld Destroyer', 'Enigma': -1.953 },
    { name: 'Pangolier', 'Enigma': -1.086 },
    { name: 'Phantom Assassin', 'Enigma': 0.677 },
    { name: 'Phantom Lancer', 'Enigma': 0.533 },
    { name: 'Phoenix', 'Enigma': 0.438 },
    { name: 'Primal Beast', 'Enigma': -1.31 },
    { name: 'Puck', 'Enigma': -0.98 },
    { name: 'Pudge', 'Enigma': -0.815 },
    { name: 'Pugna', 'Enigma': -0.304 },
    { name: 'Queen of Pain', 'Enigma': -0.84 },
    { name: 'Razor', 'Enigma': -0.969 },
    { name: 'Riki', 'Enigma': 1.8 },
    { name: 'Ringmaster', 'Enigma': -0.149 },
    { name: 'Rubick', 'Enigma': 4.462 },
    { name: 'Sand King', 'Enigma': 1.608 },
    { name: 'Shadow Demon', 'Enigma': -0.725 },
    { name: 'Shadow Fiend', 'Enigma': 0.142 },
    { name: 'Shadow Shaman', 'Enigma': -0.737 },
    { name: 'Silencer', 'Enigma': 1.515 },
    { name: 'Skywrath Mage', 'Enigma': 0.843 },
    { name: 'Slardar', 'Enigma': -1.631 },
    { name: 'Slark', 'Enigma': -1.9 },
    { name: 'Snapfire', 'Enigma': 0.622 },
    { name: 'Sniper', 'Enigma': 2.686 },
    { name: 'Spectre', 'Enigma': 2.29 },
    { name: 'Spirit Breaker', 'Enigma': -0.759 },
    { name: 'Storm Spirit', 'Enigma': -1.961 },
    { name: 'Sven', 'Enigma': -0.603 },
    { name: 'Techies', 'Enigma': -1.444 },
    { name: 'Templar Assassin', 'Enigma': 0.188 },
    { name: 'Terrorblade', 'Enigma': 0.177 },
    { name: 'Tidehunter', 'Enigma': 0.336 },
    { name: 'Timbersaw', 'Enigma': -0.596 },
    { name: 'Tinker', 'Enigma': -2.519 },
    { name: 'Tiny', 'Enigma': 0.408 },
    { name: 'Treant Protector', 'Enigma': -0.36 },
    { name: 'Troll Warlord', 'Enigma': 0.47 },
    { name: 'Tusk', 'Enigma': -0.207 },
    { name: 'Underlord', 'Enigma': -0.394 },
    { name: 'Undying', 'Enigma': 0.84 },
    { name: 'Ursa', 'Enigma': -1.875 },
    { name: 'Vengeful Spirit', 'Enigma': 0.263 },
    { name: 'Venomancer', 'Enigma': -0.825 },
    { name: 'Viper', 'Enigma': -0.624 },
    { name: 'Visage', 'Enigma': -0.766 },
    { name: 'Void Spirit', 'Enigma': -0.632 },
    { name: 'Warlock', 'Enigma': 3.934 },
    { name: 'Weaver', 'Enigma': -0.612 },
    { name: 'Windranger', 'Enigma': -0.879 },
    { name: 'Winter Wyvern', 'Enigma': 1.498 },
    { name: 'Witch Doctor', 'Enigma': -0.342 },
    { name: 'Wraith King', 'Enigma': 3.03 },
    { name: 'Zeus', 'Enigma': 0.604 },
],
"Invoker": [
    { name: 'Abaddon', 'Invoker': -1.089 },
    { name: 'Alchemist', 'Invoker': -0.709 },
    { name: 'Ancient Apparition', 'Invoker': -0.958 },
    { name: 'Anti-Mage', 'Invoker': 0.389 },
    { name: 'Arc Warden', 'Invoker': -0.717 },
    { name: 'Axe', 'Invoker': -0.077 },
    { name: 'Bane', 'Invoker': 0.195 },
    { name: 'Batrider', 'Invoker': 0.346 },
    { name: 'Beastmaster', 'Invoker': 1.505 },
    { name: 'Bloodseeker', 'Invoker': -0.155 },
    { name: 'Bounty Hunter', 'Invoker': 1.05 },
    { name: 'Brewmaster', 'Invoker': -0.413 },
    { name: 'Bristleback', 'Invoker': 1.452 },
    { name: 'Broodmother', 'Invoker': 2.416 },
    { name: 'Centaur Warrunner', 'Invoker': -1.482 },
    { name: 'Chaos Knight', 'Invoker': -1.443 },
    { name: 'Chen', 'Invoker': 0.076 },
    { name: 'Clinkz', 'Invoker': 0.294 },
    { name: 'Clockwerk', 'Invoker': -0.916 },
    { name: 'Crystal Maiden', 'Invoker': -0.155 },
    { name: 'Dark Seer', 'Invoker': -0.763 },
    { name: 'Dark Willow', 'Invoker': -0.477 },
    { name: 'Dawnbreaker', 'Invoker': -0.611 },
    { name: 'Dazzle', 'Invoker': 0.639 },
    { name: 'Death Prophet', 'Invoker': 0.025 },
    { name: 'Disruptor', 'Invoker': -0.814 },
    { name: 'Doom', 'Invoker': -1.832 },
    { name: 'Dragon Knight', 'Invoker': -0.257 },
    { name: 'Drow Ranger', 'Invoker': -0.812 },
    { name: 'Earth Spirit', 'Invoker': 0.063 },
    { name: 'Earthshaker', 'Invoker': -0.069 },
    { name: 'Elder Titan', 'Invoker': -0.962 },
    { name: 'Ember Spirit', 'Invoker': -0.537 },
    { name: 'Enchantress', 'Invoker': 0.456 },
    { name: 'Enigma', 'Invoker': 0.281 },
    { name: 'Faceless Void', 'Invoker': 1.098 },
    { name: 'Grimstroke', 'Invoker': -0.386 },
    { name: 'Gyrocopter', 'Invoker': 0.603 },
    { name: 'Hoodwink', 'Invoker': 1.143 },
    { name: 'Huskar', 'Invoker': -0.069 },
    { name: 'Io', 'Invoker': 0.562 },
    { name: 'Jakiro', 'Invoker': -0.517 },
    { name: 'Juggernaut', 'Invoker': -0.438 },
    { name: 'Keeper of the Light', 'Invoker': 0.778 },
    { name: 'Kunkka', 'Invoker': -0.906 },
    { name: 'Legion Commander', 'Invoker': -0.275 },
    { name: 'Leshrac', 'Invoker': -1.137 },
    { name: 'Lich', 'Invoker': -0.864 },
    { name: 'Lifestealer', 'Invoker': 0.553 },
    { name: 'Lina', 'Invoker': -1.235 },
    { name: 'Lion', 'Invoker': -0.596 },
    { name: 'Lone Druid', 'Invoker': -0.068 },
    { name: 'Luna', 'Invoker': -0.812 },
    { name: 'Lycan', 'Invoker': 1.311 },
    { name: 'Magnus', 'Invoker': 0.155 },
    { name: 'Marci', 'Invoker': 0.441 },
    { name: 'Mars', 'Invoker': -0.045 },
    { name: 'Medusa', 'Invoker': -3.806 },
    { name: 'Meepo', 'Invoker': 1.106 },
    { name: 'Mirana', 'Invoker': 0.246 },
    { name: 'Monkey King', 'Invoker': -0.509 },
    { name: 'Morphling', 'Invoker': 0.576 },
    { name: 'Muerta', 'Invoker': -1.048 },
    { name: 'Naga Siren', 'Invoker': -0.166 },
    { name: "Nature's Prophet", 'Invoker': 0.355 },
    { name: 'Necrophos', 'Invoker': 0.182 },
    { name: 'Night Stalker', 'Invoker': -0.5 },
    { name: 'Nyx Assassin', 'Invoker': 0.181 },
    { name: 'Ogre Magi', 'Invoker': -1.042 },
    { name: 'Omniknight', 'Invoker': -0.254 },
    { name: 'Oracle', 'Invoker': -1.563 },
    { name: 'Outworld Destroyer', 'Invoker': -0.518 },
    { name: 'Pangolier', 'Invoker': 1.246 },
    { name: 'Phantom Assassin', 'Invoker': 1.799 },
    { name: 'Phantom Lancer', 'Invoker': 2.42 },
    { name: 'Phoenix', 'Invoker': -0.472 },
    { name: 'Primal Beast', 'Invoker': -1.309 },
    { name: 'Puck', 'Invoker': 1.661 },
    { name: 'Pudge', 'Invoker': -0.77 },
    { name: 'Pugna', 'Invoker': -0.358 },
    { name: 'Queen of Pain', 'Invoker': -0.145 },
    { name: 'Razor', 'Invoker': -0.262 },
    { name: 'Riki', 'Invoker': 1.154 },
    { name: 'Ringmaster', 'Invoker': -0.81 },
    { name: 'Rubick', 'Invoker': -0.565 },
    { name: 'Sand King', 'Invoker': 1.06 },
    { name: 'Shadow Demon', 'Invoker': 0.663 },
    { name: 'Shadow Fiend', 'Invoker': -0.932 },
    { name: 'Shadow Shaman', 'Invoker': -0.297 },
    { name: 'Silencer', 'Invoker': -1.162 },
    { name: 'Skywrath Mage', 'Invoker': -0.24 },
    { name: 'Slardar', 'Invoker': 0.946 },
    { name: 'Slark', 'Invoker': -1.463 },
    { name: 'Snapfire', 'Invoker': 0.534 },
    { name: 'Sniper', 'Invoker': -0.359 },
    { name: 'Spectre', 'Invoker': -0.843 },
    { name: 'Spirit Breaker', 'Invoker': -1.183 },
    { name: 'Storm Spirit', 'Invoker': -0.488 },
    { name: 'Sven', 'Invoker': -0.799 },
    { name: 'Techies', 'Invoker': 0.398 },
    { name: 'Templar Assassin', 'Invoker': 1.757 },
    { name: 'Terrorblade', 'Invoker': 0.553 },
    { name: 'Tidehunter', 'Invoker': 0.034 },
    { name: 'Timbersaw', 'Invoker': -0.658 },
    { name: 'Tinker', 'Invoker': -2.156 },
    { name: 'Tiny', 'Invoker': 0.211 },
    { name: 'Treant Protector', 'Invoker': -0.787 },
    { name: 'Troll Warlord', 'Invoker': -1.445 },
    { name: 'Tusk', 'Invoker': 0.98 },
    { name: 'Underlord', 'Invoker': -1.36 },
    { name: 'Undying', 'Invoker': -0.657 },
    { name: 'Ursa', 'Invoker': -0.268 },
    { name: 'Vengeful Spirit', 'Invoker': 0.044 },
    { name: 'Venomancer', 'Invoker': 0.092 },
    { name: 'Viper', 'Invoker': -1.689 },
    { name: 'Visage', 'Invoker': 0.224 },
    { name: 'Void Spirit', 'Invoker': 1.388 },
    { name: 'Warlock', 'Invoker': -0.88 },
    { name: 'Weaver', 'Invoker': 0.278 },
    { name: 'Windranger', 'Invoker': -0.397 },
    { name: 'Winter Wyvern', 'Invoker': 0.485 },
    { name: 'Witch Doctor', 'Invoker': -0.823 },
    { name: 'Wraith King', 'Invoker': -1.543 },
    { name: 'Zeus', 'Invoker': 0.49 },
],
"Io": [
    { name: 'Abaddon', 'Io': -0.721 },
    { name: 'Alchemist', 'Io': 0.13 },
    { name: 'Ancient Apparition', 'Io': 2.307 },
    { name: 'Anti-Mage', 'Io': -2.013 },
    { name: 'Arc Warden', 'Io': -2.528 },
    { name: 'Axe', 'Io': -0.202 },
    { name: 'Bane', 'Io': -1.204 },
    { name: 'Batrider', 'Io': 1.064 },
    { name: 'Beastmaster', 'Io': 0.513 },
    { name: 'Bloodseeker', 'Io': -1.805 },
    { name: 'Bounty Hunter', 'Io': -0.073 },
    { name: 'Brewmaster', 'Io': -2.093 },
    { name: 'Bristleback', 'Io': 0.99 },
    { name: 'Broodmother', 'Io': 0.488 },
    { name: 'Centaur Warrunner', 'Io': -1.287 },
    { name: 'Chaos Knight', 'Io': 0.183 },
    { name: 'Chen', 'Io': -3.841 },
    { name: 'Clinkz', 'Io': -0.902 },
    { name: 'Clockwerk', 'Io': -0.068 },
    { name: 'Crystal Maiden', 'Io': 1.951 },
    { name: 'Dark Seer', 'Io': 0.125 },
    { name: 'Dark Willow', 'Io': 1.254 },
    { name: 'Dawnbreaker', 'Io': -0.205 },
    { name: 'Dazzle', 'Io': 0.386 },
    { name: 'Death Prophet', 'Io': 0.368 },
    { name: 'Disruptor', 'Io': 0.75 },
    { name: 'Doom', 'Io': 1.736 },
    { name: 'Dragon Knight', 'Io': 1.186 },
    { name: 'Drow Ranger', 'Io': 1.356 },
    { name: 'Earth Spirit', 'Io': -2.617 },
    { name: 'Earthshaker', 'Io': 2.211 },
    { name: 'Elder Titan', 'Io': 1.324 },
    { name: 'Ember Spirit', 'Io': -0.507 },
    { name: 'Enchantress', 'Io': -0.389 },
    { name: 'Enigma', 'Io': 0.476 },
    { name: 'Faceless Void', 'Io': -0.113 },
    { name: 'Grimstroke', 'Io': -0.83 },
    { name: 'Gyrocopter', 'Io': 0.02 },
    { name: 'Hoodwink', 'Io': 0.555 },
    { name: 'Huskar', 'Io': 1.472 },
    { name: 'Invoker', 'Io': -1.13 },
    { name: 'Jakiro', 'Io': 1.016 },
    { name: 'Juggernaut', 'Io': -1.637 },
    { name: 'Keeper of the Light', 'Io': -0.388 },
    { name: 'Kunkka', 'Io': 1.646 },
    { name: 'Legion Commander', 'Io': -1.005 },
    { name: 'Leshrac', 'Io': 0.633 },
    { name: 'Lich', 'Io': 1.314 },
    { name: 'Lifestealer', 'Io': -1.803 },
    { name: 'Lina', 'Io': 0.596 },
    { name: 'Lion', 'Io': -0.302 },
    { name: 'Lone Druid', 'Io': 1.019 },
    { name: 'Luna', 'Io': 1.384 },
    { name: 'Lycan', 'Io': 0.027 },
    { name: 'Magnus', 'Io': 0.521 },
    { name: 'Marci', 'Io': 1.713 },
    { name: 'Mars', 'Io': 0.676 },
    { name: 'Medusa', 'Io': 0.058 },
    { name: 'Meepo', 'Io': -0.512 },
    { name: 'Mirana', 'Io': 0.381 },
    { name: 'Monkey King', 'Io': 2.352 },
    { name: 'Morphling', 'Io': -0.937 },
    { name: 'Muerta', 'Io': 0.767 },
    { name: 'Naga Siren', 'Io': 0.395 },
    { name: "Nature's Prophet", 'Io': -1.266 },
    { name: 'Necrophos', 'Io': -0.504 },
    { name: 'Night Stalker', 'Io': -0.907 },
    { name: 'Nyx Assassin', 'Io': -1.169 },
    { name: 'Ogre Magi', 'Io': -0.196 },
    { name: 'Omniknight', 'Io': -1.344 },
    { name: 'Oracle', 'Io': -2.75 },
    { name: 'Outworld Destroyer', 'Io': -0.367 },
    { name: 'Pangolier', 'Io': -0.363 },
    { name: 'Phantom Assassin', 'Io': 0.007 },
    { name: 'Phantom Lancer', 'Io': 1.081 },
    { name: 'Phoenix', 'Io': -1.76 },
    { name: 'Primal Beast', 'Io': 0.404 },
    { name: 'Puck', 'Io': -1.563 },
    { name: 'Pudge', 'Io': -0.61 },
    { name: 'Pugna', 'Io': 0.134 },
    { name: 'Queen of Pain', 'Io': -1.131 },
    { name: 'Razor', 'Io': 1.162 },
    { name: 'Riki', 'Io': -0.829 },
    { name: 'Ringmaster', 'Io': -0.672 },
    { name: 'Rubick', 'Io': -0.445 },
    { name: 'Sand King', 'Io': 0.023 },
    { name: 'Shadow Demon', 'Io': -0.845 },
    { name: 'Shadow Fiend', 'Io': 1.575 },
    { name: 'Shadow Shaman', 'Io': -0.369 },
    { name: 'Silencer', 'Io': 0.308 },
    { name: 'Skywrath Mage', 'Io': -1.994 },
    { name: 'Slardar', 'Io': 1.41 },
    { name: 'Slark', 'Io': 0.502 },
    { name: 'Snapfire', 'Io': 0.161 },
    { name: 'Sniper', 'Io': 0.123 },
    { name: 'Spectre', 'Io': -2.098 },
    { name: 'Spirit Breaker', 'Io': -0.113 },
    { name: 'Storm Spirit', 'Io': -1.521 },
    { name: 'Sven', 'Io': 1.941 },
    { name: 'Techies', 'Io': 1.905 },
    { name: 'Templar Assassin', 'Io': -0.164 },
    { name: 'Terrorblade', 'Io': 1.337 },
    { name: 'Tidehunter', 'Io': -0.229 },
    { name: 'Timbersaw', 'Io': -0.785 },
    { name: 'Tinker', 'Io': -2.628 },
    { name: 'Tiny', 'Io': 0.762 },
    { name: 'Treant Protector', 'Io': 0.381 },
    { name: 'Troll Warlord', 'Io': 3.482 },
    { name: 'Tusk', 'Io': 0.969 },
    { name: 'Underlord', 'Io': 2.584 },
    { name: 'Undying', 'Io': 0.361 },
    { name: 'Ursa', 'Io': 0.439 },
    { name: 'Vengeful Spirit', 'Io': 0.776 },
    { name: 'Venomancer', 'Io': 1.718 },
    { name: 'Viper', 'Io': 0.865 },
    { name: 'Visage', 'Io': -1.07 },
    { name: 'Void Spirit', 'Io': -1.686 },
    { name: 'Warlock', 'Io': 0.76 },
    { name: 'Weaver', 'Io': -0.281 },
    { name: 'Windranger', 'Io': 1.01 },
    { name: 'Winter Wyvern', 'Io': 1.21 },
    { name: 'Witch Doctor', 'Io': 0.715 },
    { name: 'Wraith King', 'Io': -1.395 },
    { name: 'Zeus', 'Io': -0.806 },
],
"Lone Druid": [
    { name: 'Abaddon', 'Lone Druid': -2.657 },
    { name: 'Alchemist', 'Lone Druid': 0.174 },
    { name: 'Ancient Apparition', 'Lone Druid': 0.511 },
    { name: 'Anti-Mage', 'Lone Druid': -6.066 },
    { name: 'Arc Warden', 'Lone Druid': -2.224 },
    { name: 'Axe', 'Lone Druid': 0.383 },
    { name: 'Bane', 'Lone Druid': -1.287 },
    { name: 'Batrider', 'Lone Druid': 4.087 },
    { name: 'Beastmaster', 'Lone Druid': 2.634 },
    { name: 'Bloodseeker', 'Lone Druid': 1.864 },
    { name: 'Bounty Hunter', 'Lone Druid': -0.079 },
    { name: 'Brewmaster', 'Lone Druid': 1.621 },
    { name: 'Bristleback', 'Lone Druid': 2.602 },
    { name: 'Broodmother', 'Lone Druid': 3.047 },
    { name: 'Centaur Warrunner', 'Lone Druid': -2.037 },
    { name: 'Chaos Knight', 'Lone Druid': 1.946 },
    { name: 'Chen', 'Lone Druid': -0.656 },
    { name: 'Clinkz', 'Lone Druid': 0.402 },
    { name: 'Clockwerk', 'Lone Druid': -1.937 },
    { name: 'Crystal Maiden', 'Lone Druid': 2.159 },
    { name: 'Dark Seer', 'Lone Druid': 1.985 },
    { name: 'Dark Willow', 'Lone Druid': 2.213 },
    { name: 'Dawnbreaker', 'Lone Druid': -1.069 },
    { name: 'Dazzle', 'Lone Druid': 1.189 },
    { name: 'Death Prophet', 'Lone Druid': -0.837 },
    { name: 'Disruptor', 'Lone Druid': -0.883 },
    { name: 'Doom', 'Lone Druid': -1.668 },
    { name: 'Dragon Knight', 'Lone Druid': -0.139 },
    { name: 'Drow Ranger', 'Lone Druid': -0.992 },
    { name: 'Earth Spirit', 'Lone Druid': -2.566 },
    { name: 'Earthshaker', 'Lone Druid': 2.72 },
    { name: 'Elder Titan', 'Lone Druid': 4.287 },
    { name: 'Ember Spirit', 'Lone Druid': -4.125 },
    { name: 'Enchantress', 'Lone Druid': 0.285 },
    { name: 'Enigma', 'Lone Druid': -0.088 },
    { name: 'Faceless Void', 'Lone Druid': -3.094 },
    { name: 'Grimstroke', 'Lone Druid': -1.214 },
    { name: 'Gyrocopter', 'Lone Druid': 1.275 },
    { name: 'Hoodwink', 'Lone Druid': 1.275 },
    { name: 'Huskar', 'Lone Druid': 1.792 },
    { name: 'Invoker', 'Lone Druid': -0.472 },
    { name: 'Io', 'Lone Druid': -0.951 },
    { name: 'Jakiro', 'Lone Druid': 2.659 },
    { name: 'Juggernaut', 'Lone Druid': -2.339 },
    { name: 'Keeper of the Light', 'Lone Druid': -0.088 },
    { name: 'Kunkka', 'Lone Druid': 1.046 },
    { name: 'Legion Commander', 'Lone Druid': -1.648 },
    { name: 'Leshrac', 'Lone Druid': 1.612 },
    { name: 'Lich', 'Lone Druid': 1.377 },
    { name: 'Lifestealer', 'Lone Druid': -1.859 },
    { name: 'Lina', 'Lone Druid': 1.498 },
    { name: 'Lion', 'Lone Druid': -1.485 },
    { name: 'Luna', 'Lone Druid': 1.424 },
    { name: 'Lycan', 'Lone Druid': 0.605 },
    { name: 'Magnus', 'Lone Druid': -0.286 },
    { name: 'Marci', 'Lone Druid': 1.306 },
    { name: 'Mars', 'Lone Druid': -2.727 },
    { name: 'Medusa', 'Lone Druid': -1.469 },
    { name: 'Meepo', 'Lone Druid': -0.945 },
    { name: 'Mirana', 'Lone Druid': -1.096 },
    { name: 'Monkey King', 'Lone Druid': 4.725 },
    { name: 'Morphling', 'Lone Druid': -1.544 },
    { name: 'Muerta', 'Lone Druid': 0.906 },
    { name: 'Naga Siren', 'Lone Druid': 4.574 },
    { name: "Nature's Prophet", 'Lone Druid': -1.393 },
    { name: 'Necrophos', 'Lone Druid': 1.105 },
    { name: 'Night Stalker', 'Lone Druid': -3.959 },
    { name: 'Nyx Assassin', 'Lone Druid': -1.998 },
    { name: 'Ogre Magi', 'Lone Druid': -1.349 },
    { name: 'Omniknight', 'Lone Druid': -0.588 },
    { name: 'Oracle', 'Lone Druid': -3.438 },
    { name: 'Outworld Destroyer', 'Lone Druid': -1.758 },
    { name: 'Pangolier', 'Lone Druid': 2.403 },
    { name: 'Phantom Assassin', 'Lone Druid': 3.651 },
    { name: 'Phantom Lancer', 'Lone Druid': 1.948 },
    { name: 'Phoenix', 'Lone Druid': 3.563 },
    { name: 'Primal Beast', 'Lone Druid': -0.061 },
    { name: 'Puck', 'Lone Druid': -2.81 },
    { name: 'Pudge', 'Lone Druid': -1.315 },
    { name: 'Pugna', 'Lone Druid': -0.477 },
    { name: 'Queen of Pain', 'Lone Druid': -1.728 },
    { name: 'Razor', 'Lone Druid': 2.472 },
    { name: 'Riki', 'Lone Druid': -0.413 },
    { name: 'Ringmaster', 'Lone Druid': 0.712 },
    { name: 'Rubick', 'Lone Druid': -0.351 },
    { name: 'Sand King', 'Lone Druid': 2.41 },
    { name: 'Shadow Demon', 'Lone Druid': -2.613 },
    { name: 'Shadow Fiend', 'Lone Druid': 2.778 },
    { name: 'Shadow Shaman', 'Lone Druid': -2.439 },
    { name: 'Silencer', 'Lone Druid': -3.388 },
    { name: 'Skywrath Mage', 'Lone Druid': -2.824 },
    { name: 'Slardar', 'Lone Druid': 0.084 },
    { name: 'Slark', 'Lone Druid': -0.08 },
    { name: 'Snapfire', 'Lone Druid': 0.649 },
    { name: 'Sniper', 'Lone Druid': 0.616 },
    { name: 'Spectre', 'Lone Druid': -1.438 },
    { name: 'Spirit Breaker', 'Lone Druid': -0.911 },
    { name: 'Storm Spirit', 'Lone Druid': -3.766 },
    { name: 'Sven', 'Lone Druid': 0.16 },
    { name: 'Techies', 'Lone Druid': 2.605 },
    { name: 'Templar Assassin', 'Lone Druid': -0.14 },
    { name: 'Terrorblade', 'Lone Druid': 3.095 },
    { name: 'Tidehunter', 'Lone Druid': 0.843 },
    { name: 'Timbersaw', 'Lone Druid': 1.643 },
    { name: 'Tinker', 'Lone Druid': -0.996 },
    { name: 'Tiny', 'Lone Druid': 0.059 },
    { name: 'Treant Protector', 'Lone Druid': 1.192 },
    { name: 'Troll Warlord', 'Lone Druid': 1.927 },
    { name: 'Tusk', 'Lone Druid': 1.914 },
    { name: 'Underlord', 'Lone Druid': 4.607 },
    { name: 'Undying', 'Lone Druid': 1.378 },
    { name: 'Ursa', 'Lone Druid': 0.124 },
    { name: 'Vengeful Spirit', 'Lone Druid': -1.719 },
    { name: 'Venomancer', 'Lone Druid': 3.753 },
    { name: 'Viper', 'Lone Druid': 2.327 },
    { name: 'Visage', 'Lone Druid': 0.047 },
    { name: 'Void Spirit', 'Lone Druid': -4.174 },
    { name: 'Warlock', 'Lone Druid': 3.022 },
    { name: 'Weaver', 'Lone Druid': 0.492 },
    { name: 'Windranger', 'Lone Druid': 2.457 },
    { name: 'Winter Wyvern', 'Lone Druid': 4.699 },
    { name: 'Witch Doctor', 'Lone Druid': 1.247 },
    { name: 'Wraith King', 'Lone Druid': -4.946 },
    { name: 'Zeus', 'Lone Druid': -0.174 },
],
"Lycan": [
    { name: 'Abaddon', 'Lycan': -1.648 },
    { name: 'Alchemist', 'Lycan': 1.289 },
    { name: 'Ancient Apparition', 'Lycan': -3.181 },
    { name: 'Anti-Mage', 'Lycan': -1.634 },
    { name: 'Arc Warden', 'Lycan': -2.851 },
    { name: 'Axe', 'Lycan': 2.535 },
    { name: 'Bane', 'Lycan': -0.569 },
    { name: 'Batrider', 'Lycan': 2.543 },
    { name: 'Beastmaster', 'Lycan': 2.412 },
    { name: 'Bloodseeker', 'Lycan': 0.96 },
    { name: 'Bounty Hunter', 'Lycan': 1.463 },
    { name: 'Brewmaster', 'Lycan': 1.41 },
    { name: 'Bristleback', 'Lycan': 1.932 },
    { name: 'Broodmother', 'Lycan': 1.418 },
    { name: 'Centaur Warrunner', 'Lycan': -1.949 },
    { name: 'Chaos Knight', 'Lycan': 1.361 },
    { name: 'Chen', 'Lycan': -0.75 },
    { name: 'Clinkz', 'Lycan': -0.592 },
    { name: 'Clockwerk', 'Lycan': -0.456 },
    { name: 'Crystal Maiden', 'Lycan': 2.01 },
    { name: 'Dark Seer', 'Lycan': 1.194 },
    { name: 'Dark Willow', 'Lycan': 0.989 },
    { name: 'Dawnbreaker', 'Lycan': -1.265 },
    { name: 'Dazzle', 'Lycan': 0.658 },
    { name: 'Death Prophet', 'Lycan': -1.481 },
    { name: 'Disruptor', 'Lycan': 0.287 },
    { name: 'Doom', 'Lycan': -0.998 },
    { name: 'Dragon Knight', 'Lycan': 1.72 },
    { name: 'Drow Ranger', 'Lycan': -2.475 },
    { name: 'Earth Spirit', 'Lycan': -0.006 },
    { name: 'Earthshaker', 'Lycan': 3.248 },
    { name: 'Elder Titan', 'Lycan': -0.229 },
    { name: 'Ember Spirit', 'Lycan': 0.439 },
    { name: 'Enchantress', 'Lycan': 1.86 },
    { name: 'Enigma', 'Lycan': 1.681 },
    { name: 'Faceless Void', 'Lycan': -0.963 },
    { name: 'Grimstroke', 'Lycan': -0.844 },
    { name: 'Gyrocopter', 'Lycan': 0.659 },
    { name: 'Hoodwink', 'Lycan': 0.538 },
    { name: 'Huskar', 'Lycan': 2.394 },
    { name: 'Invoker', 'Lycan': -1.946 },
    { name: 'Io', 'Lycan': -0.066 },
    { name: 'Jakiro', 'Lycan': 1.152 },
    { name: 'Juggernaut', 'Lycan': -1.808 },
    { name: 'Keeper of the Light', 'Lycan': 0.284 },
    { name: 'Kunkka', 'Lycan': 0.677 },
    { name: 'Legion Commander', 'Lycan': 0.014 },
    { name: 'Leshrac', 'Lycan': 0.661 },
    { name: 'Lich', 'Lycan': -0.982 },
    { name: 'Lifestealer', 'Lycan': 0.56 },
    { name: 'Lina', 'Lycan': 0.117 },
    { name: 'Lion', 'Lycan': -0.817 },
    { name: 'Lone Druid', 'Lycan': -0.592 },
    { name: 'Luna', 'Lycan': 1.243 },
    { name: 'Magnus', 'Lycan': 0.496 },
    { name: 'Marci', 'Lycan': 0.483 },
    { name: 'Mars', 'Lycan': 0.035 },
    { name: 'Medusa', 'Lycan': -0.389 },
    { name: 'Meepo', 'Lycan': 0.87 },
    { name: 'Mirana', 'Lycan': 0.266 },
    { name: 'Monkey King', 'Lycan': 2.653 },
    { name: 'Morphling', 'Lycan': -0.306 },
    { name: 'Muerta', 'Lycan': -1.005 },
    { name: 'Naga Siren', 'Lycan': 5.778 },
    { name: "Nature's Prophet", 'Lycan': -0.566 },
    { name: 'Necrophos', 'Lycan': 2.133 },
    { name: 'Night Stalker', 'Lycan': -3.188 },
    { name: 'Nyx Assassin', 'Lycan': -0.441 },
    { name: 'Ogre Magi', 'Lycan': 0.006 },
    { name: 'Omniknight', 'Lycan': -0.773 },
    { name: 'Oracle', 'Lycan': -3.22 },
    { name: 'Outworld Destroyer', 'Lycan': -2.062 },
    { name: 'Pangolier', 'Lycan': 1.543 },
    { name: 'Phantom Assassin', 'Lycan': 2.888 },
    { name: 'Phantom Lancer', 'Lycan': 1.654 },
    { name: 'Phoenix', 'Lycan': 1.7 },
    { name: 'Primal Beast', 'Lycan': 0.844 },
    { name: 'Puck', 'Lycan': -1.497 },
    { name: 'Pudge', 'Lycan': -2.49 },
    { name: 'Pugna', 'Lycan': -1.283 },
    { name: 'Queen of Pain', 'Lycan': -0.452 },
    { name: 'Razor', 'Lycan': -0.703 },
    { name: 'Riki', 'Lycan': -0.682 },
    { name: 'Ringmaster', 'Lycan': -2.018 },
    { name: 'Rubick', 'Lycan': 0.336 },
    { name: 'Sand King', 'Lycan': 1.14 },
    { name: 'Shadow Demon', 'Lycan': -1.986 },
    { name: 'Shadow Fiend', 'Lycan': 1.269 },
    { name: 'Shadow Shaman', 'Lycan': 1.299 },
    { name: 'Silencer', 'Lycan': -4.336 },
    { name: 'Skywrath Mage', 'Lycan': -3.644 },
    { name: 'Slardar', 'Lycan': 1.392 },
    { name: 'Slark', 'Lycan': 1.324 },
    { name: 'Snapfire', 'Lycan': -0.517 },
    { name: 'Sniper', 'Lycan': -2.595 },
    { name: 'Spectre', 'Lycan': -1.797 },
    { name: 'Spirit Breaker', 'Lycan': 2.527 },
    { name: 'Storm Spirit', 'Lycan': 0.361 },
    { name: 'Sven', 'Lycan': 2.82 },
    { name: 'Techies', 'Lycan': 1.021 },
    { name: 'Templar Assassin', 'Lycan': 0.439 },
    { name: 'Terrorblade', 'Lycan': 2.979 },
    { name: 'Tidehunter', 'Lycan': 1.369 },
    { name: 'Timbersaw', 'Lycan': -1.679 },
    { name: 'Tinker', 'Lycan': -0.392 },
    { name: 'Tiny', 'Lycan': 0.235 },
    { name: 'Treant Protector', 'Lycan': 0.145 },
    { name: 'Troll Warlord', 'Lycan': 4.873 },
    { name: 'Tusk', 'Lycan': 2.319 },
    { name: 'Underlord', 'Lycan': 1.247 },
    { name: 'Undying', 'Lycan': -0.342 },
    { name: 'Ursa', 'Lycan': 0.931 },
    { name: 'Vengeful Spirit', 'Lycan': 0.448 },
    { name: 'Venomancer', 'Lycan': -1.078 },
    { name: 'Viper', 'Lycan': -1.658 },
    { name: 'Visage', 'Lycan': -0.393 },
    { name: 'Void Spirit', 'Lycan': -1.754 },
    { name: 'Warlock', 'Lycan': -1.752 },
    { name: 'Weaver', 'Lycan': 0.809 },
    { name: 'Windranger', 'Lycan': 0.779 },
    { name: 'Winter Wyvern', 'Lycan': 1.225 },
    { name: 'Witch Doctor', 'Lycan': 0.02 },
    { name: 'Wraith King', 'Lycan': -1.388 },
    { name: 'Zeus', 'Lycan': -2.375 },
],
"Magnus": [
    { name: 'Abaddon', 'Magnus': -1.075 },
    { name: 'Alchemist', 'Magnus': 0.422 },
    { name: 'Ancient Apparition', 'Magnus': -0.086 },
    { name: 'Anti-Mage', 'Magnus': -1.188 },
    { name: 'Arc Warden', 'Magnus': -0.672 },
    { name: 'Axe', 'Magnus': 0.334 },
    { name: 'Bane', 'Magnus': 0.102 },
    { name: 'Batrider', 'Magnus': 0.666 },
    { name: 'Beastmaster', 'Magnus': -0.454 },
    { name: 'Bloodseeker', 'Magnus': 0.917 },
    { name: 'Bounty Hunter', 'Magnus': -1.439 },
    { name: 'Brewmaster', 'Magnus': 0.26 },
    { name: 'Bristleback', 'Magnus': -0.001 },
    { name: 'Broodmother', 'Magnus': -0.954 },
    { name: 'Centaur Warrunner', 'Magnus': 0.201 },
    { name: 'Chaos Knight', 'Magnus': -1.115 },
    { name: 'Chen', 'Magnus': 0.973 },
    { name: 'Clinkz', 'Magnus': -0.325 },
    { name: 'Clockwerk', 'Magnus': 0.148 },
    { name: 'Crystal Maiden', 'Magnus': -0.265 },
    { name: 'Dark Seer', 'Magnus': 0.015 },
    { name: 'Dark Willow', 'Magnus': 0.431 },
    { name: 'Dawnbreaker', 'Magnus': 0.348 },
    { name: 'Dazzle', 'Magnus': -0.177 },
    { name: 'Death Prophet', 'Magnus': -0.417 },
    { name: 'Disruptor', 'Magnus': 1.133 },
    { name: 'Doom', 'Magnus': -0.578 },
    { name: 'Dragon Knight', 'Magnus': -0.334 },
    { name: 'Drow Ranger', 'Magnus': -1.18 },
    { name: 'Earth Spirit', 'Magnus': -0.413 },
    { name: 'Earthshaker', 'Magnus': 1.202 },
    { name: 'Elder Titan', 'Magnus': 0.199 },
    { name: 'Ember Spirit', 'Magnus': 1.3 },
    { name: 'Enchantress', 'Magnus': -0.24 },
    { name: 'Enigma', 'Magnus': 2.348 },
    { name: 'Faceless Void', 'Magnus': 1.71 },
    { name: 'Grimstroke', 'Magnus': 0.723 },
    { name: 'Gyrocopter', 'Magnus': 0.888 },
    { name: 'Hoodwink', 'Magnus': -0.054 },
    { name: 'Huskar', 'Magnus': 1.03 },
    { name: 'Invoker', 'Magnus': -0.205 },
    { name: 'Io', 'Magnus': -0.619 },
    { name: 'Jakiro', 'Magnus': -0.12 },
    { name: 'Juggernaut', 'Magnus': 1.074 },
    { name: 'Keeper of the Light', 'Magnus': -0.708 },
    { name: 'Kunkka', 'Magnus': 0.161 },
    { name: 'Legion Commander', 'Magnus': -0.55 },
    { name: 'Leshrac', 'Magnus': -0.42 },
    { name: 'Lich', 'Magnus': 0.57 },
    { name: 'Lifestealer', 'Magnus': -0.312 },
    { name: 'Lina', 'Magnus': -0.381 },
    { name: 'Lion', 'Magnus': 0.58 },
    { name: 'Lone Druid', 'Magnus': 0.155 },
    { name: 'Luna', 'Magnus': 0.59 },
    { name: 'Lycan', 'Magnus': -0.565 },
    { name: 'Marci', 'Magnus': -2.332 },
    { name: 'Mars', 'Magnus': 0.395 },
    { name: 'Medusa', 'Magnus': -0.282 },
    { name: 'Meepo', 'Magnus': -1.519 },
    { name: 'Mirana', 'Magnus': -0.026 },
    { name: 'Monkey King', 'Magnus': -0.174 },
    { name: 'Morphling', 'Magnus': -0.854 },
    { name: 'Muerta', 'Magnus': 0.459 },
    { name: 'Naga Siren', 'Magnus': -0.33 },
    { name: "Nature's Prophet", 'Magnus': -0.266 },
    { name: 'Necrophos', 'Magnus': 0.92 },
    { name: 'Night Stalker', 'Magnus': 0.232 },
    { name: 'Nyx Assassin', 'Magnus': -0.925 },
    { name: 'Ogre Magi', 'Magnus': -0.876 },
    { name: 'Omniknight', 'Magnus': -1.372 },
    { name: 'Oracle', 'Magnus': -1.151 },
    { name: 'Outworld Destroyer', 'Magnus': -0.369 },
    { name: 'Pangolier', 'Magnus': -0.503 },
    { name: 'Phantom Assassin', 'Magnus': -0.984 },
    { name: 'Phantom Lancer', 'Magnus': -0.708 },
    { name: 'Phoenix', 'Magnus': 0.82 },
    { name: 'Primal Beast', 'Magnus': 0.092 },
    { name: 'Puck', 'Magnus': 1.365 },
    { name: 'Pudge', 'Magnus': -1.931 },
    { name: 'Pugna', 'Magnus': -0.886 },
    { name: 'Queen of Pain', 'Magnus': 0.224 },
    { name: 'Razor', 'Magnus': -0.381 },
    { name: 'Riki', 'Magnus': 0.84 },
    { name: 'Ringmaster', 'Magnus': -0.856 },
    { name: 'Rubick', 'Magnus': 0.766 },
    { name: 'Sand King', 'Magnus': -0.587 },
    { name: 'Shadow Demon', 'Magnus': -1.323 },
    { name: 'Shadow Fiend', 'Magnus': -0.009 },
    { name: 'Shadow Shaman', 'Magnus': 0.232 },
    { name: 'Silencer', 'Magnus': 0.618 },
    { name: 'Skywrath Mage', 'Magnus': -0.532 },
    { name: 'Slardar', 'Magnus': -0.634 },
    { name: 'Slark', 'Magnus': -0.971 },
    { name: 'Snapfire', 'Magnus': -0.302 },
    { name: 'Sniper', 'Magnus': -0.304 },
    { name: 'Spectre', 'Magnus': 0.237 },
    { name: 'Spirit Breaker', 'Magnus': 0.196 },
    { name: 'Storm Spirit', 'Magnus': 0.529 },
    { name: 'Sven', 'Magnus': 0.114 },
    { name: 'Techies', 'Magnus': -0.435 },
    { name: 'Templar Assassin', 'Magnus': 0.675 },
    { name: 'Terrorblade', 'Magnus': -0.166 },
    { name: 'Tidehunter', 'Magnus': 1.064 },
    { name: 'Timbersaw', 'Magnus': -1.015 },
    { name: 'Tinker', 'Magnus': -1.628 },
    { name: 'Tiny', 'Magnus': 0.209 },
    { name: 'Treant Protector', 'Magnus': -1.012 },
    { name: 'Troll Warlord', 'Magnus': 2.12 },
    { name: 'Tusk', 'Magnus': -1.005 },
    { name: 'Underlord', 'Magnus': -0.471 },
    { name: 'Undying', 'Magnus': -0.317 },
    { name: 'Ursa', 'Magnus': 0.758 },
    { name: 'Vengeful Spirit', 'Magnus': -0.389 },
    { name: 'Venomancer', 'Magnus': -0.616 },
    { name: 'Viper', 'Magnus': -0.836 },
    { name: 'Visage', 'Magnus': -0.227 },
    { name: 'Void Spirit', 'Magnus': 0.593 },
    { name: 'Warlock', 'Magnus': -0.364 },
    { name: 'Weaver', 'Magnus': 1.501 },
    { name: 'Windranger', 'Magnus': 0.591 },
    { name: 'Winter Wyvern', 'Magnus': 1.41 },
    { name: 'Witch Doctor', 'Magnus': -0.151 },
    { name: 'Wraith King', 'Magnus': 1.292 },
    { name: 'Zeus', 'Magnus': -0.462 },
],
"Marci": [
    { name: 'Abaddon', 'Marci': -2.616 },
    { name: 'Alchemist', 'Marci': -0.547 },
    { name: 'Ancient Apparition', 'Marci': 1.255 },
    { name: 'Anti-Mage', 'Marci': -2.981 },
    { name: 'Arc Warden', 'Marci': -2.433 },
    { name: 'Axe', 'Marci': 1.778 },
    { name: 'Bane', 'Marci': -0.491 },
    { name: 'Batrider', 'Marci': 3.294 },
    { name: 'Beastmaster', 'Marci': 0.006 },
    { name: 'Bloodseeker', 'Marci': 0.222 },
    { name: 'Bounty Hunter', 'Marci': -0.017 },
    { name: 'Brewmaster', 'Marci': -0.469 },
    { name: 'Bristleback', 'Marci': 0.843 },
    { name: 'Broodmother', 'Marci': -0.621 },
    { name: 'Centaur Warrunner', 'Marci': 0.394 },
    { name: 'Chaos Knight', 'Marci': 0.409 },
    { name: 'Chen', 'Marci': -0.833 },
    { name: 'Clinkz', 'Marci': 0.404 },
    { name: 'Clockwerk', 'Marci': 0.237 },
    { name: 'Crystal Maiden', 'Marci': 0.74 },
    { name: 'Dark Seer', 'Marci': -0.44 },
    { name: 'Dark Willow', 'Marci': 2.273 },
    { name: 'Dawnbreaker', 'Marci': 1.124 },
    { name: 'Dazzle', 'Marci': -0.079 },
    { name: 'Death Prophet', 'Marci': -0.654 },
    { name: 'Disruptor', 'Marci': 0.75 },
    { name: 'Doom', 'Marci': -1.41 },
    { name: 'Dragon Knight', 'Marci': -0.052 },
    { name: 'Drow Ranger', 'Marci': 0.174 },
    { name: 'Earth Spirit', 'Marci': -2.086 },
    { name: 'Earthshaker', 'Marci': 1.515 },
    { name: 'Elder Titan', 'Marci': -0.43 },
    { name: 'Ember Spirit', 'Marci': 0.218 },
    { name: 'Enchantress', 'Marci': -1.953 },
    { name: 'Enigma', 'Marci': 2.226 },
    { name: 'Faceless Void', 'Marci': 0.003 },
    { name: 'Grimstroke', 'Marci': 0.589 },
    { name: 'Gyrocopter', 'Marci': 0.44 },
    { name: 'Hoodwink', 'Marci': 0.964 },
    { name: 'Huskar', 'Marci': 0.181 },
    { name: 'Invoker', 'Marci': -0.795 },
    { name: 'Io', 'Marci': -1.676 },
    { name: 'Jakiro', 'Marci': 0.541 },
    { name: 'Juggernaut', 'Marci': 0.225 },
    { name: 'Keeper of the Light', 'Marci': 0.581 },
    { name: 'Kunkka', 'Marci': 2.13 },
    { name: 'Legion Commander', 'Marci': -0.966 },
    { name: 'Leshrac', 'Marci': -0.849 },
    { name: 'Lich', 'Marci': 1.216 },
    { name: 'Lifestealer', 'Marci': -1.892 },
    { name: 'Lina', 'Marci': -0.22 },
    { name: 'Lion', 'Marci': 0.821 },
    { name: 'Lone Druid', 'Marci': -1.262 },
    { name: 'Luna', 'Marci': 0.41 },
    { name: 'Lycan', 'Marci': -0.441 },
    { name: 'Magnus', 'Marci': 2.322 },
    { name: 'Mars', 'Marci': 0.945 },
    { name: 'Medusa', 'Marci': 0.775 },
    { name: 'Meepo', 'Marci': -1.302 },
    { name: 'Mirana', 'Marci': 1.073 },
    { name: 'Monkey King', 'Marci': 2.33 },
    { name: 'Morphling', 'Marci': -1.003 },
    { name: 'Muerta', 'Marci': 1.862 },
    { name: 'Naga Siren', 'Marci': 0.212 },
    { name: "Nature's Prophet", 'Marci': 0.017 },
    { name: 'Necrophos', 'Marci': -1.018 },
    { name: 'Night Stalker', 'Marci': -2.598 },
    { name: 'Nyx Assassin', 'Marci': -1.164 },
    { name: 'Ogre Magi', 'Marci': -0.326 },
    { name: 'Omniknight', 'Marci': -0.96 },
    { name: 'Oracle', 'Marci': -3.715 },
    { name: 'Outworld Destroyer', 'Marci': -1.775 },
    { name: 'Pangolier', 'Marci': 0.252 },
    { name: 'Phantom Assassin', 'Marci': 0.673 },
    { name: 'Phantom Lancer', 'Marci': 0.416 },
    { name: 'Phoenix', 'Marci': -1.636 },
    { name: 'Primal Beast', 'Marci': 0.47 },
    { name: 'Puck', 'Marci': 0.287 },
    { name: 'Pudge', 'Marci': -2.433 },
    { name: 'Pugna', 'Marci': -1.287 },
    { name: 'Queen of Pain', 'Marci': -0.464 },
    { name: 'Razor', 'Marci': 0.122 },
    { name: 'Riki', 'Marci': 0.764 },
    { name: 'Ringmaster', 'Marci': 0.2 },
    { name: 'Rubick', 'Marci': -0.511 },
    { name: 'Sand King', 'Marci': 0.251 },
    { name: 'Shadow Demon', 'Marci': -0.939 },
    { name: 'Shadow Fiend', 'Marci': 1.268 },
    { name: 'Shadow Shaman', 'Marci': 0.642 },
    { name: 'Silencer', 'Marci': -0.051 },
    { name: 'Skywrath Mage', 'Marci': -0.938 },
    { name: 'Slardar', 'Marci': 1.082 },
    { name: 'Slark', 'Marci': 0.267 },
    { name: 'Snapfire', 'Marci': 0.222 },
    { name: 'Sniper', 'Marci': 0.335 },
    { name: 'Spectre', 'Marci': -0.984 },
    { name: 'Spirit Breaker', 'Marci': 0.628 },
    { name: 'Storm Spirit', 'Marci': -0.411 },
    { name: 'Sven', 'Marci': 0.776 },
    { name: 'Techies', 'Marci': 1.622 },
    { name: 'Templar Assassin', 'Marci': 0.995 },
    { name: 'Terrorblade', 'Marci': 1.004 },
    { name: 'Tidehunter', 'Marci': 0.776 },
    { name: 'Timbersaw', 'Marci': -1.525 },
    { name: 'Tinker', 'Marci': -2.982 },
    { name: 'Tiny', 'Marci': 0.882 },
    { name: 'Treant Protector', 'Marci': 1.358 },
    { name: 'Troll Warlord', 'Marci': 3.752 },
    { name: 'Tusk', 'Marci': 0.561 },
    { name: 'Underlord', 'Marci': 0.217 },
    { name: 'Undying', 'Marci': -0.791 },
    { name: 'Ursa', 'Marci': 0.36 },
    { name: 'Vengeful Spirit', 'Marci': -0.795 },
    { name: 'Venomancer', 'Marci': -0.103 },
    { name: 'Viper', 'Marci': -0.503 },
    { name: 'Visage', 'Marci': -1.418 },
    { name: 'Void Spirit', 'Marci': -1.798 },
    { name: 'Warlock', 'Marci': -0.588 },
    { name: 'Weaver', 'Marci': 0.821 },
    { name: 'Windranger', 'Marci': 2.062 },
    { name: 'Winter Wyvern', 'Marci': 1.936 },
    { name: 'Witch Doctor', 'Marci': 0.259 },
    { name: 'Wraith King', 'Marci': -0.627 },
    { name: 'Zeus', 'Marci': -0.0 },
],
"Mirana": [
    { name: 'Abaddon', 'Mirana': 0.158 },
    { name: 'Alchemist', 'Mirana': -0.688 },
    { name: 'Ancient Apparition', 'Mirana': -1.578 },
    { name: 'Anti-Mage', 'Mirana': -1.013 },
    { name: 'Arc Warden', 'Mirana': 0.589 },
    { name: 'Axe', 'Mirana': 1.372 },
    { name: 'Bane', 'Mirana': 0.729 },
    { name: 'Batrider', 'Mirana': -0.954 },
    { name: 'Beastmaster', 'Mirana': 1.015 },
    { name: 'Bloodseeker', 'Mirana': 0.62 },
    { name: 'Bounty Hunter', 'Mirana': 0.897 },
    { name: 'Brewmaster', 'Mirana': -1.396 },
    { name: 'Bristleback', 'Mirana': -0.186 },
    { name: 'Broodmother', 'Mirana': 0.469 },
    { name: 'Centaur Warrunner', 'Mirana': 1.052 },
    { name: 'Chaos Knight', 'Mirana': 1.012 },
    { name: 'Chen', 'Mirana': 2.487 },
    { name: 'Clinkz', 'Mirana': -1.189 },
    { name: 'Clockwerk', 'Mirana': -0.316 },
    { name: 'Crystal Maiden', 'Mirana': 0.051 },
    { name: 'Dark Seer', 'Mirana': -0.469 },
    { name: 'Dark Willow', 'Mirana': 0.397 },
    { name: 'Dawnbreaker', 'Mirana': -0.866 },
    { name: 'Dazzle', 'Mirana': -0.606 },
    { name: 'Death Prophet', 'Mirana': -1.983 },
    { name: 'Disruptor', 'Mirana': -0.081 },
    { name: 'Doom', 'Mirana': -0.881 },
    { name: 'Dragon Knight', 'Mirana': 0.439 },
    { name: 'Drow Ranger', 'Mirana': -0.682 },
    { name: 'Earth Spirit', 'Mirana': 0.542 },
    { name: 'Earthshaker', 'Mirana': -0.281 },
    { name: 'Elder Titan', 'Mirana': -1.539 },
    { name: 'Ember Spirit', 'Mirana': -0.157 },
    { name: 'Enchantress', 'Mirana': -0.318 },
    { name: 'Enigma', 'Mirana': 0.757 },
    { name: 'Faceless Void', 'Mirana': 0.255 },
    { name: 'Grimstroke', 'Mirana': -1.02 },
    { name: 'Gyrocopter', 'Mirana': -0.334 },
    { name: 'Hoodwink', 'Mirana': -0.146 },
    { name: 'Huskar', 'Mirana': -0.336 },
    { name: 'Invoker', 'Mirana': -0.262 },
    { name: 'Io', 'Mirana': -0.412 },
    { name: 'Jakiro', 'Mirana': -0.696 },
    { name: 'Juggernaut', 'Mirana': -0.28 },
    { name: 'Keeper of the Light', 'Mirana': 0.015 },
    { name: 'Kunkka', 'Mirana': 0.47 },
    { name: 'Legion Commander', 'Mirana': 0.638 },
    { name: 'Leshrac', 'Mirana': -1.017 },
    { name: 'Lich', 'Mirana': -0.848 },
    { name: 'Lifestealer', 'Mirana': -0.542 },
    { name: 'Lina', 'Mirana': -0.515 },
    { name: 'Lion', 'Mirana': -0.201 },
    { name: 'Lone Druid', 'Mirana': 0.92 },
    { name: 'Luna', 'Mirana': 0.247 },
    { name: 'Lycan', 'Mirana': -0.394 },
    { name: 'Magnus', 'Mirana': 0.042 },
    { name: 'Marci', 'Mirana': -1.105 },
    { name: 'Mars', 'Mirana': 1.839 },
    { name: 'Medusa', 'Mirana': 0.234 },
    { name: 'Meepo', 'Mirana': 1.362 },
    { name: 'Monkey King', 'Mirana': -0.444 },
    { name: 'Morphling', 'Mirana': 0.017 },
    { name: 'Muerta', 'Mirana': 0.211 },
    { name: 'Naga Siren', 'Mirana': 1.272 },
    { name: "Nature's Prophet", 'Mirana': -0.826 },
    { name: 'Necrophos', 'Mirana': 0.057 },
    { name: 'Night Stalker', 'Mirana': -0.577 },
    { name: 'Nyx Assassin', 'Mirana': 1.013 },
    { name: 'Ogre Magi', 'Mirana': 0.067 },
    { name: 'Omniknight', 'Mirana': -0.329 },
    { name: 'Oracle', 'Mirana': 0.069 },
    { name: 'Outworld Destroyer', 'Mirana': 0.802 },
    { name: 'Pangolier', 'Mirana': -0.124 },
    { name: 'Phantom Assassin', 'Mirana': -0.692 },
    { name: 'Phantom Lancer', 'Mirana': 0.42 },
    { name: 'Phoenix', 'Mirana': -1.741 },
    { name: 'Primal Beast', 'Mirana': -0.644 },
    { name: 'Puck', 'Mirana': 1.85 },
    { name: 'Pudge', 'Mirana': -0.353 },
    { name: 'Pugna', 'Mirana': -0.24 },
    { name: 'Queen of Pain', 'Mirana': -0.083 },
    { name: 'Razor', 'Mirana': -1.834 },
    { name: 'Riki', 'Mirana': 0.277 },
    { name: 'Ringmaster', 'Mirana': 0.737 },
    { name: 'Rubick', 'Mirana': 0.26 },
    { name: 'Sand King', 'Mirana': -0.888 },
    { name: 'Shadow Demon', 'Mirana': 0.222 },
    { name: 'Shadow Fiend', 'Mirana': -0.183 },
    { name: 'Shadow Shaman', 'Mirana': -0.269 },
    { name: 'Silencer', 'Mirana': -1.245 },
    { name: 'Skywrath Mage', 'Mirana': -0.665 },
    { name: 'Slardar', 'Mirana': -1.007 },
    { name: 'Slark', 'Mirana': -0.329 },
    { name: 'Snapfire', 'Mirana': -0.348 },
    { name: 'Sniper', 'Mirana': -1.068 },
    { name: 'Spectre', 'Mirana': 1.65 },
    { name: 'Spirit Breaker', 'Mirana': 0.742 },
    { name: 'Storm Spirit', 'Mirana': 0.955 },
    { name: 'Sven', 'Mirana': -0.82 },
    { name: 'Techies', 'Mirana': -0.324 },
    { name: 'Templar Assassin', 'Mirana': 0.756 },
    { name: 'Terrorblade', 'Mirana': 1.299 },
    { name: 'Tidehunter', 'Mirana': -0.036 },
    { name: 'Timbersaw', 'Mirana': -1.432 },
    { name: 'Tinker', 'Mirana': -0.224 },
    { name: 'Tiny', 'Mirana': 0.368 },
    { name: 'Treant Protector', 'Mirana': 0.682 },
    { name: 'Troll Warlord', 'Mirana': -1.063 },
    { name: 'Tusk', 'Mirana': 0.087 },
    { name: 'Underlord', 'Mirana': -0.396 },
    { name: 'Undying', 'Mirana': -0.133 },
    { name: 'Ursa', 'Mirana': -0.909 },
    { name: 'Vengeful Spirit', 'Mirana': 0.355 },
    { name: 'Venomancer', 'Mirana': -1.344 },
    { name: 'Viper', 'Mirana': -1.385 },
    { name: 'Visage', 'Mirana': 0.116 },
    { name: 'Void Spirit', 'Mirana': 0.433 },
    { name: 'Warlock', 'Mirana': -1.304 },
    { name: 'Weaver', 'Mirana': -0.836 },
    { name: 'Windranger', 'Mirana': 0.6 },
    { name: 'Winter Wyvern', 'Mirana': 0.583 },
    { name: 'Witch Doctor', 'Mirana': -0.761 },
    { name: 'Wraith King', 'Mirana': 1.132 },
    { name: 'Zeus', 'Mirana': 0.662 },
],
"Nyx Assassin": [
    { name: 'Abaddon', 'Nyx Assassin': -0.898 },
    { name: 'Alchemist', 'Nyx Assassin': -1.16 },
    { name: 'Ancient Apparition', 'Nyx Assassin': -0.592 },
    { name: 'Anti-Mage', 'Nyx Assassin': -1.541 },
    { name: 'Arc Warden', 'Nyx Assassin': 0.798 },
    { name: 'Axe', 'Nyx Assassin': 1.17 },
    { name: 'Bane', 'Nyx Assassin': 0.462 },
    { name: 'Batrider', 'Nyx Assassin': 1.247 },
    { name: 'Beastmaster', 'Nyx Assassin': 2.105 },
    { name: 'Bloodseeker', 'Nyx Assassin': -0.351 },
    { name: 'Bounty Hunter', 'Nyx Assassin': -0.166 },
    { name: 'Brewmaster', 'Nyx Assassin': 0.071 },
    { name: 'Bristleback', 'Nyx Assassin': -1.07 },
    { name: 'Broodmother', 'Nyx Assassin': 1.091 },
    { name: 'Centaur Warrunner', 'Nyx Assassin': 0.011 },
    { name: 'Chaos Knight', 'Nyx Assassin': 2.327 },
    { name: 'Chen', 'Nyx Assassin': 1.751 },
    { name: 'Clinkz', 'Nyx Assassin': -0.28 },
    { name: 'Clockwerk', 'Nyx Assassin': 0.185 },
    { name: 'Crystal Maiden', 'Nyx Assassin': -0.086 },
    { name: 'Dark Seer', 'Nyx Assassin': -0.19 },
    { name: 'Dark Willow', 'Nyx Assassin': 0.074 },
    { name: 'Dawnbreaker', 'Nyx Assassin': -1.739 },
    { name: 'Dazzle', 'Nyx Assassin': 0.637 },
    { name: 'Death Prophet', 'Nyx Assassin': 0.793 },
    { name: 'Disruptor', 'Nyx Assassin': 1.267 },
    { name: 'Doom', 'Nyx Assassin': 0.706 },
    { name: 'Dragon Knight', 'Nyx Assassin': 1.718 },
    { name: 'Drow Ranger', 'Nyx Assassin': -0.426 },
    { name: 'Earth Spirit', 'Nyx Assassin': 1.724 },
    { name: 'Earthshaker', 'Nyx Assassin': 0.094 },
    { name: 'Elder Titan', 'Nyx Assassin': -0.163 },
    { name: 'Ember Spirit', 'Nyx Assassin': 1.865 },
    { name: 'Enchantress', 'Nyx Assassin': -0.457 },
    { name: 'Enigma', 'Nyx Assassin': 0.175 },
    { name: 'Faceless Void', 'Nyx Assassin': 0.348 },
    { name: 'Grimstroke', 'Nyx Assassin': 1.323 },
    { name: 'Gyrocopter', 'Nyx Assassin': -0.074 },
    { name: 'Hoodwink', 'Nyx Assassin': 0.256 },
    { name: 'Huskar', 'Nyx Assassin': 1.497 },
    { name: 'Invoker', 'Nyx Assassin': -0.371 },
    { name: 'Io', 'Nyx Assassin': 1.144 },
    { name: 'Jakiro', 'Nyx Assassin': 0.325 },
    { name: 'Juggernaut', 'Nyx Assassin': 1.08 },
    { name: 'Keeper of the Light', 'Nyx Assassin': -0.977 },
    { name: 'Kunkka', 'Nyx Assassin': 1.174 },
    { name: 'Legion Commander', 'Nyx Assassin': 0.232 },
    { name: 'Leshrac', 'Nyx Assassin': -0.43 },
    { name: 'Lich', 'Nyx Assassin': -0.121 },
    { name: 'Lifestealer', 'Nyx Assassin': 2.196 },
    { name: 'Lina', 'Nyx Assassin': -0.953 },
    { name: 'Lion', 'Nyx Assassin': 1.666 },
    { name: 'Lone Druid', 'Nyx Assassin': 1.814 },
    { name: 'Luna', 'Nyx Assassin': -1.369 },
    { name: 'Lycan', 'Nyx Assassin': 0.438 },
    { name: 'Magnus', 'Nyx Assassin': 1.029 },
    { name: 'Marci', 'Nyx Assassin': 1.164 },
    { name: 'Mars', 'Nyx Assassin': -0.365 },
    { name: 'Medusa', 'Nyx Assassin': -1.413 },
    { name: 'Meepo', 'Nyx Assassin': 2.381 },
    { name: 'Mirana', 'Nyx Assassin': -0.915 },
    { name: 'Monkey King', 'Nyx Assassin': 0.074 },
    { name: 'Morphling', 'Nyx Assassin': -2.159 },
    { name: 'Muerta', 'Nyx Assassin': -1.692 },
    { name: 'Naga Siren', 'Nyx Assassin': 1.231 },
    { name: "Nature's Prophet", 'Nyx Assassin': -0.854 },
    { name: 'Necrophos', 'Nyx Assassin': -0.548 },
    { name: 'Night Stalker', 'Nyx Assassin': 1.39 },
    { name: 'Ogre Magi', 'Nyx Assassin': 0.697 },
    { name: 'Omniknight', 'Nyx Assassin': 0.193 },
    { name: 'Oracle', 'Nyx Assassin': -0.134 },
    { name: 'Outworld Destroyer', 'Nyx Assassin': -2.539 },
    { name: 'Pangolier', 'Nyx Assassin': 1.034 },
    { name: 'Phantom Assassin', 'Nyx Assassin': -2.166 },
    { name: 'Phantom Lancer', 'Nyx Assassin': -0.502 },
    { name: 'Phoenix', 'Nyx Assassin': -0.728 },
    { name: 'Primal Beast', 'Nyx Assassin': 0.074 },
    { name: 'Puck', 'Nyx Assassin': -2.267 },
    { name: 'Pudge', 'Nyx Assassin': 0.576 },
    { name: 'Pugna', 'Nyx Assassin': -1.327 },
    { name: 'Queen of Pain', 'Nyx Assassin': -1.084 },
    { name: 'Razor', 'Nyx Assassin': -0.885 },
    { name: 'Riki', 'Nyx Assassin': -0.743 },
    { name: 'Ringmaster', 'Nyx Assassin': -1.189 },
    { name: 'Rubick', 'Nyx Assassin': 0.088 },
    { name: 'Sand King', 'Nyx Assassin': -1.26 },
    { name: 'Shadow Demon', 'Nyx Assassin': -0.347 },
    { name: 'Shadow Fiend', 'Nyx Assassin': -1.278 },
    { name: 'Shadow Shaman', 'Nyx Assassin': 1.288 },
    { name: 'Silencer', 'Nyx Assassin': -0.386 },
    { name: 'Skywrath Mage', 'Nyx Assassin': 0.594 },
    { name: 'Slardar', 'Nyx Assassin': 1.959 },
    { name: 'Slark', 'Nyx Assassin': -0.097 },
    { name: 'Snapfire', 'Nyx Assassin': -0.287 },
    { name: 'Sniper', 'Nyx Assassin': -2.641 },
    { name: 'Spectre', 'Nyx Assassin': 0.669 },
    { name: 'Spirit Breaker', 'Nyx Assassin': 0.167 },
    { name: 'Storm Spirit', 'Nyx Assassin': -1.308 },
    { name: 'Sven', 'Nyx Assassin': 1.192 },
    { name: 'Techies', 'Nyx Assassin': -0.199 },
    { name: 'Templar Assassin', 'Nyx Assassin': 1.993 },
    { name: 'Terrorblade', 'Nyx Assassin': 0.507 },
    { name: 'Tidehunter', 'Nyx Assassin': 0.173 },
    { name: 'Timbersaw', 'Nyx Assassin': -1.069 },
    { name: 'Tinker', 'Nyx Assassin': -0.707 },
    { name: 'Tiny', 'Nyx Assassin': 1.004 },
    { name: 'Treant Protector', 'Nyx Assassin': 0.122 },
    { name: 'Troll Warlord', 'Nyx Assassin': 0.733 },
    { name: 'Tusk', 'Nyx Assassin': 1.144 },
    { name: 'Underlord', 'Nyx Assassin': 0.112 },
    { name: 'Undying', 'Nyx Assassin': -0.153 },
    { name: 'Ursa', 'Nyx Assassin': 2.121 },
    { name: 'Vengeful Spirit', 'Nyx Assassin': 1.255 },
    { name: 'Venomancer', 'Nyx Assassin': 0.607 },
    { name: 'Viper', 'Nyx Assassin': 0.7 },
    { name: 'Visage', 'Nyx Assassin': 1.516 },
    { name: 'Void Spirit', 'Nyx Assassin': 0.283 },
    { name: 'Warlock', 'Nyx Assassin': -1.8 },
    { name: 'Weaver', 'Nyx Assassin': -2.533 },
    { name: 'Windranger', 'Nyx Assassin': -1.018 },
    { name: 'Winter Wyvern', 'Nyx Assassin': 0.508 },
    { name: 'Witch Doctor', 'Nyx Assassin': -0.318 },
    { name: 'Wraith King', 'Nyx Assassin': 1.354 },
    { name: 'Zeus', 'Nyx Assassin': 0.728 },
],
"Pangolier": [
    { name: 'Abaddon', 'Pangolier': -1.479 },
    { name: 'Alchemist', 'Pangolier': 0.805 },
    { name: 'Ancient Apparition', 'Pangolier': -1.256 },
    { name: 'Anti-Mage', 'Pangolier': -1.353 },
    { name: 'Arc Warden', 'Pangolier': -2.799 },
    { name: 'Axe', 'Pangolier': 0.683 },
    { name: 'Bane', 'Pangolier': 0.81 },
    { name: 'Batrider', 'Pangolier': 1.016 },
    { name: 'Beastmaster', 'Pangolier': -1.075 },
    { name: 'Bloodseeker', 'Pangolier': 3.543 },
    { name: 'Bounty Hunter', 'Pangolier': 1.508 },
    { name: 'Brewmaster', 'Pangolier': -1.279 },
    { name: 'Bristleback', 'Pangolier': 0.712 },
    { name: 'Broodmother', 'Pangolier': -0.507 },
    { name: 'Centaur Warrunner', 'Pangolier': -1.157 },
    { name: 'Chaos Knight', 'Pangolier': -1.572 },
    { name: 'Chen', 'Pangolier': 3.659 },
    { name: 'Clinkz', 'Pangolier': 1.569 },
    { name: 'Clockwerk', 'Pangolier': -0.391 },
    { name: 'Crystal Maiden', 'Pangolier': -0.384 },
    { name: 'Dark Seer', 'Pangolier': -0.798 },
    { name: 'Dark Willow', 'Pangolier': 0.661 },
    { name: 'Dawnbreaker', 'Pangolier': 0.078 },
    { name: 'Dazzle', 'Pangolier': 0.645 },
    { name: 'Death Prophet', 'Pangolier': -0.96 },
    { name: 'Disruptor', 'Pangolier': -0.692 },
    { name: 'Doom', 'Pangolier': 0.276 },
    { name: 'Dragon Knight', 'Pangolier': 1.019 },
    { name: 'Drow Ranger', 'Pangolier': 2.478 },
    { name: 'Earth Spirit', 'Pangolier': -0.99 },
    { name: 'Earthshaker', 'Pangolier': 0.833 },
    { name: 'Elder Titan', 'Pangolier': 0.036 },
    { name: 'Ember Spirit', 'Pangolier': 1.24 },
    { name: 'Enchantress', 'Pangolier': -1.38 },
    { name: 'Enigma', 'Pangolier': 1.121 },
    { name: 'Faceless Void', 'Pangolier': 1.067 },
    { name: 'Grimstroke', 'Pangolier': 1.793 },
    { name: 'Gyrocopter', 'Pangolier': 1.541 },
    { name: 'Hoodwink', 'Pangolier': 0.355 },
    { name: 'Huskar', 'Pangolier': 4.445 },
    { name: 'Invoker', 'Pangolier': -1.338 },
    { name: 'Io', 'Pangolier': 0.325 },
    { name: 'Jakiro', 'Pangolier': -0.944 },
    { name: 'Juggernaut', 'Pangolier': -0.471 },
    { name: 'Keeper of the Light', 'Pangolier': 0.023 },
    { name: 'Kunkka', 'Pangolier': -1.215 },
    { name: 'Legion Commander', 'Pangolier': -0.397 },
    { name: 'Leshrac', 'Pangolier': -0.174 },
    { name: 'Lich', 'Pangolier': -1.605 },
    { name: 'Lifestealer', 'Pangolier': -1.132 },
    { name: 'Lina', 'Pangolier': -0.778 },
    { name: 'Lion', 'Pangolier': 0.021 },
    { name: 'Lone Druid', 'Pangolier': -2.414 },
    { name: 'Luna', 'Pangolier': 1.153 },
    { name: 'Lycan', 'Pangolier': -1.566 },
    { name: 'Magnus', 'Pangolier': 0.551 },
    { name: 'Marci', 'Pangolier': -0.151 },
    { name: 'Mars', 'Pangolier': -0.105 },
    { name: 'Medusa', 'Pangolier': -2.474 },
    { name: 'Meepo', 'Pangolier': -2.219 },
    { name: 'Mirana', 'Pangolier': 0.166 },
    { name: 'Monkey King', 'Pangolier': 3.743 },
    { name: 'Morphling', 'Pangolier': 1.186 },
    { name: 'Muerta', 'Pangolier': -0.234 },
    { name: 'Naga Siren', 'Pangolier': -0.227 },
    { name: "Nature's Prophet", 'Pangolier': 0.345 },
    { name: 'Necrophos', 'Pangolier': -0.187 },
    { name: 'Night Stalker', 'Pangolier': 0.538 },
    { name: 'Nyx Assassin', 'Pangolier': -0.763 },
    { name: 'Ogre Magi', 'Pangolier': 1.007 },
    { name: 'Omniknight', 'Pangolier': 0.63 },
    { name: 'Oracle', 'Pangolier': -1.929 },
    { name: 'Outworld Destroyer', 'Pangolier': -1.865 },
    { name: 'Phantom Assassin', 'Pangolier': -0.949 },
    { name: 'Phantom Lancer', 'Pangolier': 0.481 },
    { name: 'Phoenix', 'Pangolier': -0.05 },
    { name: 'Primal Beast', 'Pangolier': 0.622 },
    { name: 'Puck', 'Pangolier': 2.79 },
    { name: 'Pudge', 'Pangolier': -1.688 },
    { name: 'Pugna', 'Pangolier': 0.265 },
    { name: 'Queen of Pain', 'Pangolier': -0.052 },
    { name: 'Razor', 'Pangolier': 1.148 },
    { name: 'Riki', 'Pangolier': 1.745 },
    { name: 'Ringmaster', 'Pangolier': -0.586 },
    { name: 'Rubick', 'Pangolier': -0.662 },
    { name: 'Sand King', 'Pangolier': -0.507 },
    { name: 'Shadow Demon', 'Pangolier': -0.666 },
    { name: 'Shadow Fiend', 'Pangolier': 0.982 },
    { name: 'Shadow Shaman', 'Pangolier': 0.423 },
    { name: 'Silencer', 'Pangolier': 0.668 },
    { name: 'Skywrath Mage', 'Pangolier': -0.571 },
    { name: 'Slardar', 'Pangolier': 1.494 },
    { name: 'Slark', 'Pangolier': 1.435 },
    { name: 'Snapfire', 'Pangolier': -0.139 },
    { name: 'Sniper', 'Pangolier': -0.443 },
    { name: 'Spectre', 'Pangolier': -0.824 },
    { name: 'Spirit Breaker', 'Pangolier': 0.766 },
    { name: 'Storm Spirit', 'Pangolier': 1.594 },
    { name: 'Sven', 'Pangolier': 1.244 },
    { name: 'Techies', 'Pangolier': -0.113 },
    { name: 'Templar Assassin', 'Pangolier': 1.418 },
    { name: 'Terrorblade', 'Pangolier': 1.969 },
    { name: 'Tidehunter', 'Pangolier': -1.031 },
    { name: 'Timbersaw', 'Pangolier': -0.098 },
    { name: 'Tinker', 'Pangolier': 0.469 },
    { name: 'Tiny', 'Pangolier': -0.191 },
    { name: 'Treant Protector', 'Pangolier': 1.433 },
    { name: 'Troll Warlord', 'Pangolier': -0.339 },
    { name: 'Tusk', 'Pangolier': 0.828 },
    { name: 'Underlord', 'Pangolier': -0.476 },
    { name: 'Undying', 'Pangolier': 0.114 },
    { name: 'Ursa', 'Pangolier': 0.541 },
    { name: 'Vengeful Spirit', 'Pangolier': 0.105 },
    { name: 'Venomancer', 'Pangolier': -0.473 },
    { name: 'Viper', 'Pangolier': -0.699 },
    { name: 'Visage', 'Pangolier': -1.912 },
    { name: 'Void Spirit', 'Pangolier': -1.191 },
    { name: 'Warlock', 'Pangolier': -1.803 },
    { name: 'Weaver', 'Pangolier': 1.924 },
    { name: 'Windranger', 'Pangolier': 0.291 },
    { name: 'Winter Wyvern', 'Pangolier': 2.753 },
    { name: 'Witch Doctor', 'Pangolier': -1.306 },
    { name: 'Wraith King', 'Pangolier': -2.156 },
    { name: 'Zeus', 'Pangolier': -1.506 },
],
"Phoenix": [
    { name: 'Abaddon', 'Phoenix': 0.999 },
    { name: 'Alchemist', 'Phoenix': 1.221 },
    { name: 'Ancient Apparition', 'Phoenix': -0.192 },
    { name: 'Anti-Mage', 'Phoenix': -0.764 },
    { name: 'Arc Warden', 'Phoenix': 0.771 },
    { name: 'Axe', 'Phoenix': -1.29 },
    { name: 'Bane', 'Phoenix': 0.561 },
    { name: 'Batrider', 'Phoenix': 2.468 },
    { name: 'Beastmaster', 'Phoenix': -1.085 },
    { name: 'Bloodseeker', 'Phoenix': 2.27 },
    { name: 'Bounty Hunter', 'Phoenix': -0.964 },
    { name: 'Brewmaster', 'Phoenix': 0.242 },
    { name: 'Bristleback', 'Phoenix': 0.525 },
    { name: 'Broodmother', 'Phoenix': -0.36 },
    { name: 'Centaur Warrunner', 'Phoenix': 0.206 },
    { name: 'Chaos Knight', 'Phoenix': -3.636 },
    { name: 'Chen', 'Phoenix': 0.554 },
    { name: 'Clinkz', 'Phoenix': 2.06 },
    { name: 'Clockwerk', 'Phoenix': -0.747 },
    { name: 'Crystal Maiden', 'Phoenix': -1.239 },
    { name: 'Dark Seer', 'Phoenix': 0.075 },
    { name: 'Dark Willow', 'Phoenix': -0.246 },
    { name: 'Dawnbreaker', 'Phoenix': -2.35 },
    { name: 'Dazzle', 'Phoenix': -0.475 },
    { name: 'Death Prophet', 'Phoenix': 1.761 },
    { name: 'Disruptor', 'Phoenix': 0.632 },
    { name: 'Doom', 'Phoenix': -0.018 },
    { name: 'Dragon Knight', 'Phoenix': -0.42 },
    { name: 'Drow Ranger', 'Phoenix': 1.668 },
    { name: 'Earth Spirit', 'Phoenix': -0.191 },
    { name: 'Earthshaker', 'Phoenix': -0.3 },
    { name: 'Elder Titan', 'Phoenix': 0.042 },
    { name: 'Ember Spirit', 'Phoenix': 0.107 },
    { name: 'Enchantress', 'Phoenix': 1.013 },
    { name: 'Enigma', 'Phoenix': -0.43 },
    { name: 'Faceless Void', 'Phoenix': -0.696 },
    { name: 'Grimstroke', 'Phoenix': -0.153 },
    { name: 'Gyrocopter', 'Phoenix': -0.501 },
    { name: 'Hoodwink', 'Phoenix': 0.364 },
    { name: 'Huskar', 'Phoenix': 2.671 },
    { name: 'Invoker', 'Phoenix': 0.256 },
    { name: 'Io', 'Phoenix': 1.728 },
    { name: 'Jakiro', 'Phoenix': 0.881 },
    { name: 'Juggernaut', 'Phoenix': 2.761 },
    { name: 'Keeper of the Light', 'Phoenix': 2.258 },
    { name: 'Kunkka', 'Phoenix': -0.419 },
    { name: 'Legion Commander', 'Phoenix': 1.792 },
    { name: 'Leshrac', 'Phoenix': -0.001 },
    { name: 'Lich', 'Phoenix': -0.896 },
    { name: 'Lifestealer', 'Phoenix': 2.651 },
    { name: 'Lina', 'Phoenix': 3.151 },
    { name: 'Lion', 'Phoenix': 0.876 },
    { name: 'Lone Druid', 'Phoenix': -3.5 },
    { name: 'Luna', 'Phoenix': 0.614 },
    { name: 'Lycan', 'Phoenix': -1.648 },
    { name: 'Magnus', 'Phoenix': -0.818 },
    { name: 'Marci', 'Phoenix': 1.65 },
    { name: 'Mars', 'Phoenix': -0.795 },
    { name: 'Medusa', 'Phoenix': 1.148 },
    { name: 'Meepo', 'Phoenix': 1.209 },
    { name: 'Mirana', 'Phoenix': 1.725 },
    { name: 'Monkey King', 'Phoenix': -1.609 },
    { name: 'Morphling', 'Phoenix': 1.995 },
    { name: 'Muerta', 'Phoenix': 1.933 },
    { name: 'Naga Siren', 'Phoenix': -0.772 },
    { name: "Nature's Prophet", 'Phoenix': 0.796 },
    { name: 'Necrophos', 'Phoenix': 0.084 },
    { name: 'Night Stalker', 'Phoenix': -2.524 },
    { name: 'Nyx Assassin', 'Phoenix': 0.765 },
    { name: 'Ogre Magi', 'Phoenix': 0.246 },
    { name: 'Omniknight', 'Phoenix': -0.482 },
    { name: 'Oracle', 'Phoenix': 1.813 },
    { name: 'Outworld Destroyer', 'Phoenix': -0.57 },
    { name: 'Pangolier', 'Phoenix': 0.128 },
    { name: 'Phantom Assassin', 'Phoenix': -2.393 },
    { name: 'Phantom Lancer', 'Phoenix': -0.199 },
    { name: 'Primal Beast', 'Phoenix': 0.235 },
    { name: 'Puck', 'Phoenix': 0.834 },
    { name: 'Pudge', 'Phoenix': -0.247 },
    { name: 'Pugna', 'Phoenix': 0.76 },
    { name: 'Queen of Pain', 'Phoenix': 0.67 },
    { name: 'Razor', 'Phoenix': -1.5 },
    { name: 'Riki', 'Phoenix': -0.294 },
    { name: 'Ringmaster', 'Phoenix': -0.784 },
    { name: 'Rubick', 'Phoenix': -1.304 },
    { name: 'Sand King', 'Phoenix': -1.456 },
    { name: 'Shadow Demon', 'Phoenix': -0.225 },
    { name: 'Shadow Fiend', 'Phoenix': -0.543 },
    { name: 'Shadow Shaman', 'Phoenix': -0.108 },
    { name: 'Silencer', 'Phoenix': 0.252 },
    { name: 'Skywrath Mage', 'Phoenix': 1.887 },
    { name: 'Slardar', 'Phoenix': -1.531 },
    { name: 'Slark', 'Phoenix': 1.275 },
    { name: 'Snapfire', 'Phoenix': 0.724 },
    { name: 'Sniper', 'Phoenix': 0.807 },
    { name: 'Spectre', 'Phoenix': -1.774 },
    { name: 'Spirit Breaker', 'Phoenix': -0.069 },
    { name: 'Storm Spirit', 'Phoenix': 0.851 },
    { name: 'Sven', 'Phoenix': -0.068 },
    { name: 'Techies', 'Phoenix': -0.355 },
    { name: 'Templar Assassin', 'Phoenix': -2.472 },
    { name: 'Terrorblade', 'Phoenix': -1.051 },
    { name: 'Tidehunter', 'Phoenix': 0.835 },
    { name: 'Timbersaw', 'Phoenix': -0.074 },
    { name: 'Tinker', 'Phoenix': 1.969 },
    { name: 'Tiny', 'Phoenix': -1.498 },
    { name: 'Treant Protector', 'Phoenix': -2.659 },
    { name: 'Troll Warlord', 'Phoenix': -0.684 },
    { name: 'Tusk', 'Phoenix': -0.412 },
    { name: 'Underlord', 'Phoenix': 0.669 },
    { name: 'Undying', 'Phoenix': -0.911 },
    { name: 'Ursa', 'Phoenix': 0.259 },
    { name: 'Vengeful Spirit', 'Phoenix': 0.015 },
    { name: 'Venomancer', 'Phoenix': -0.682 },
    { name: 'Viper', 'Phoenix': 0.193 },
    { name: 'Visage', 'Phoenix': 0.25 },
    { name: 'Void Spirit', 'Phoenix': 0.56 },
    { name: 'Warlock', 'Phoenix': -2.598 },
    { name: 'Weaver', 'Phoenix': 1.4 },
    { name: 'Windranger', 'Phoenix': 0.046 },
    { name: 'Winter Wyvern', 'Phoenix': -1.072 },
    { name: 'Witch Doctor', 'Phoenix': -0.393 },
    { name: 'Wraith King', 'Phoenix': -3.086 },
    { name: 'Zeus', 'Phoenix': -0.152 },
],
"Sand King": [
    { name: 'Abaddon', 'Sand King': 0.234 },
    { name: 'Alchemist', 'Sand King': -0.842 },
    { name: 'Ancient Apparition', 'Sand King': 1.427 },
    { name: 'Anti-Mage', 'Sand King': 1.407 },
    { name: 'Arc Warden', 'Sand King': -2.899 },
    { name: 'Axe', 'Sand King': -1.209 },
    { name: 'Bane', 'Sand King': -0.922 },
    { name: 'Batrider', 'Sand King': -0.213 },
    { name: 'Beastmaster', 'Sand King': -2.272 },
    { name: 'Bloodseeker', 'Sand King': 1.61 },
    { name: 'Bounty Hunter', 'Sand King': 1.758 },
    { name: 'Brewmaster', 'Sand King': -1.57 },
    { name: 'Bristleback', 'Sand King': 2.127 },
    { name: 'Broodmother', 'Sand King': -1.559 },
    { name: 'Centaur Warrunner', 'Sand King': 1.911 },
    { name: 'Chaos Knight', 'Sand King': -4.428 },
    { name: 'Chen', 'Sand King': 0.33 },
    { name: 'Clinkz', 'Sand King': 1.414 },
    { name: 'Clockwerk', 'Sand King': -0.276 },
    { name: 'Crystal Maiden', 'Sand King': -0.522 },
    { name: 'Dark Seer', 'Sand King': -1.144 },
    { name: 'Dark Willow', 'Sand King': -0.301 },
    { name: 'Dawnbreaker', 'Sand King': 0.482 },
    { name: 'Dazzle', 'Sand King': -1.048 },
    { name: 'Death Prophet', 'Sand King': 0.409 },
    { name: 'Disruptor', 'Sand King': 1.133 },
    { name: 'Doom', 'Sand King': 0.633 },
    { name: 'Dragon Knight', 'Sand King': -1.229 },
    { name: 'Drow Ranger', 'Sand King': -0.038 },
    { name: 'Earth Spirit', 'Sand King': 1.239 },
    { name: 'Earthshaker', 'Sand King': -0.904 },
    { name: 'Elder Titan', 'Sand King': 0.659 },
    { name: 'Ember Spirit', 'Sand King': 2.382 },
    { name: 'Enchantress', 'Sand King': -0.774 },
    { name: 'Enigma', 'Sand King': -1.407 },
    { name: 'Faceless Void', 'Sand King': 1.762 },
    { name: 'Grimstroke', 'Sand King': 0.1 },
    { name: 'Gyrocopter', 'Sand King': 0.469 },
    { name: 'Hoodwink', 'Sand King': 0.42 },
    { name: 'Huskar', 'Sand King': 1.154 },
    { name: 'Invoker', 'Sand King': -1.353 },
    { name: 'Io', 'Sand King': 0.08 },
    { name: 'Jakiro', 'Sand King': 0.391 },
    { name: 'Juggernaut', 'Sand King': 1.795 },
    { name: 'Keeper of the Light', 'Sand King': 0.292 },
    { name: 'Kunkka', 'Sand King': 0.238 },
    { name: 'Legion Commander', 'Sand King': 0.649 },
    { name: 'Leshrac', 'Sand King': 1.727 },
    { name: 'Lich', 'Sand King': 0.017 },
    { name: 'Lifestealer', 'Sand King': 1.727 },
    { name: 'Lina', 'Sand King': 1.882 },
    { name: 'Lion', 'Sand King': -0.111 },
    { name: 'Lone Druid', 'Sand King': -2.155 },
    { name: 'Luna', 'Sand King': -0.776 },
    { name: 'Lycan', 'Sand King': -0.954 },
    { name: 'Magnus', 'Sand King': 0.745 },
    { name: 'Marci', 'Sand King': -0.165 },
    { name: 'Mars', 'Sand King': -0.469 },
    { name: 'Medusa', 'Sand King': -2.657 },
    { name: 'Meepo', 'Sand King': -2.492 },
    { name: 'Mirana', 'Sand King': 1.0 },
    { name: 'Monkey King', 'Sand King': 0.411 },
    { name: 'Morphling', 'Sand King': 0.831 },
    { name: 'Muerta', 'Sand King': 2.059 },
    { name: 'Naga Siren', 'Sand King': -5.453 },
    { name: "Nature's Prophet", 'Sand King': 0.111 },
    { name: 'Necrophos', 'Sand King': -1.06 },
    { name: 'Night Stalker', 'Sand King': 0.945 },
    { name: 'Nyx Assassin', 'Sand King': 1.264 },
    { name: 'Ogre Magi', 'Sand King': -0.334 },
    { name: 'Omniknight', 'Sand King': 0.342 },
    { name: 'Oracle', 'Sand King': 0.705 },
    { name: 'Outworld Destroyer', 'Sand King': -0.931 },
    { name: 'Pangolier', 'Sand King': 0.923 },
    { name: 'Phantom Assassin', 'Sand King': -0.552 },
    { name: 'Phantom Lancer', 'Sand King': -6.473 },
    { name: 'Phoenix', 'Sand King': 1.484 },
    { name: 'Primal Beast', 'Sand King': -0.288 },
    { name: 'Puck', 'Sand King': 0.276 },
    { name: 'Pudge', 'Sand King': 0.823 },
    { name: 'Pugna', 'Sand King': 1.549 },
    { name: 'Queen of Pain', 'Sand King': 1.611 },
    { name: 'Razor', 'Sand King': -0.533 },
    { name: 'Riki', 'Sand King': -1.592 },
    { name: 'Ringmaster', 'Sand King': -0.044 },
    { name: 'Rubick', 'Sand King': -0.529 },
    { name: 'Shadow Demon', 'Sand King': -1.624 },
    { name: 'Shadow Fiend', 'Sand King': 0.448 },
    { name: 'Shadow Shaman', 'Sand King': -2.025 },
    { name: 'Silencer', 'Sand King': 1.323 },
    { name: 'Skywrath Mage', 'Sand King': 0.962 },
    { name: 'Slardar', 'Sand King': 1.054 },
    { name: 'Slark', 'Sand King': -1.437 },
    { name: 'Snapfire', 'Sand King': 1.485 },
    { name: 'Sniper', 'Sand King': 1.406 },
    { name: 'Spectre', 'Sand King': -0.202 },
    { name: 'Spirit Breaker', 'Sand King': -1.158 },
    { name: 'Storm Spirit', 'Sand King': 1.917 },
    { name: 'Sven', 'Sand King': 0.546 },
    { name: 'Techies', 'Sand King': 0.084 },
    { name: 'Templar Assassin', 'Sand King': -1.757 },
    { name: 'Terrorblade', 'Sand King': -2.949 },
    { name: 'Tidehunter', 'Sand King': 0.438 },
    { name: 'Timbersaw', 'Sand King': 1.217 },
    { name: 'Tinker', 'Sand King': 0.877 },
    { name: 'Tiny', 'Sand King': -1.112 },
    { name: 'Treant Protector', 'Sand King': -0.64 },
    { name: 'Troll Warlord', 'Sand King': 0.818 },
    { name: 'Tusk', 'Sand King': -1.176 },
    { name: 'Underlord', 'Sand King': 1.115 },
    { name: 'Undying', 'Sand King': -0.788 },
    { name: 'Ursa', 'Sand King': 0.95 },
    { name: 'Vengeful Spirit', 'Sand King': -1.08 },
    { name: 'Venomancer', 'Sand King': 0.466 },
    { name: 'Viper', 'Sand King': -0.449 },
    { name: 'Visage', 'Sand King': -0.721 },
    { name: 'Void Spirit', 'Sand King': 0.068 },
    { name: 'Warlock', 'Sand King': -2.31 },
    { name: 'Weaver', 'Sand King': 0.325 },
    { name: 'Windranger', 'Sand King': 0.419 },
    { name: 'Winter Wyvern', 'Sand King': -2.878 },
    { name: 'Witch Doctor', 'Sand King': -0.246 },
    { name: 'Wraith King', 'Sand King': -1.95 },
    { name: 'Zeus', 'Sand King': 2.726 },
],
"Snapfire": [
    { name: 'Abaddon', 'Snapfire': -0.362 },
    { name: 'Alchemist', 'Snapfire': 0.193 },
    { name: 'Ancient Apparition', 'Snapfire': -0.463 },
    { name: 'Anti-Mage', 'Snapfire': -0.461 },
    { name: 'Arc Warden', 'Snapfire': -1.99 },
    { name: 'Axe', 'Snapfire': -0.1 },
    { name: 'Bane', 'Snapfire': 0.168 },
    { name: 'Batrider', 'Snapfire': -0.322 },
    { name: 'Beastmaster', 'Snapfire': -1.705 },
    { name: 'Bloodseeker', 'Snapfire': 0.624 },
    { name: 'Bounty Hunter', 'Snapfire': 0.406 },
    { name: 'Brewmaster', 'Snapfire': 0.689 },
    { name: 'Bristleback', 'Snapfire': 0.188 },
    { name: 'Broodmother', 'Snapfire': -0.236 },
    { name: 'Centaur Warrunner', 'Snapfire': 1.027 },
    { name: 'Chaos Knight', 'Snapfire': -0.715 },
    { name: 'Chen', 'Snapfire': -0.109 },
    { name: 'Clinkz', 'Snapfire': -0.36 },
    { name: 'Clockwerk', 'Snapfire': -1.055 },
    { name: 'Crystal Maiden', 'Snapfire': -0.26 },
    { name: 'Dark Seer', 'Snapfire': -0.184 },
    { name: 'Dark Willow', 'Snapfire': -0.377 },
    { name: 'Dawnbreaker', 'Snapfire': -0.823 },
    { name: 'Dazzle', 'Snapfire': -0.261 },
    { name: 'Death Prophet', 'Snapfire': -0.062 },
    { name: 'Disruptor', 'Snapfire': 0.273 },
    { name: 'Doom', 'Snapfire': 0.509 },
    { name: 'Dragon Knight', 'Snapfire': 0.28 },
    { name: 'Drow Ranger', 'Snapfire': -0.645 },
    { name: 'Earth Spirit', 'Snapfire': -0.392 },
    { name: 'Earthshaker', 'Snapfire': -0.455 },
    { name: 'Elder Titan', 'Snapfire': -1.37 },
    { name: 'Ember Spirit', 'Snapfire': 0.116 },
    { name: 'Enchantress', 'Snapfire': 0.014 },
    { name: 'Enigma', 'Snapfire': -0.716 },
    { name: 'Faceless Void', 'Snapfire': 1.168 },
    { name: 'Grimstroke', 'Snapfire': 0.125 },
    { name: 'Gyrocopter', 'Snapfire': -0.114 },
    { name: 'Hoodwink', 'Snapfire': 0.097 },
    { name: 'Huskar', 'Snapfire': 0.698 },
    { name: 'Invoker', 'Snapfire': -0.464 },
    { name: 'Io', 'Snapfire': -0.324 },
    { name: 'Jakiro', 'Snapfire': -0.173 },
    { name: 'Juggernaut', 'Snapfire': -0.12 },
    { name: 'Keeper of the Light', 'Snapfire': -0.366 },
    { name: 'Kunkka', 'Snapfire': 0.287 },
    { name: 'Legion Commander', 'Snapfire': -0.125 },
    { name: 'Leshrac', 'Snapfire': -1.045 },
    { name: 'Lich', 'Snapfire': -0.335 },
    { name: 'Lifestealer', 'Snapfire': 0.259 },
    { name: 'Lina', 'Snapfire': 0.037 },
    { name: 'Lion', 'Snapfire': -0.23 },
    { name: 'Lone Druid', 'Snapfire': -0.845 },
    { name: 'Luna', 'Snapfire': -1.024 },
    { name: 'Lycan', 'Snapfire': 0.275 },
    { name: 'Magnus', 'Snapfire': 0.278 },
    { name: 'Marci', 'Snapfire': -0.242 },
    { name: 'Mars', 'Snapfire': 0.22 },
    { name: 'Medusa', 'Snapfire': -1.57 },
    { name: 'Meepo', 'Snapfire': -1.724 },
    { name: 'Mirana', 'Snapfire': 0.389 },
    { name: 'Monkey King', 'Snapfire': -1.201 },
    { name: 'Morphling', 'Snapfire': 0.064 },
    { name: 'Muerta', 'Snapfire': -0.644 },
    { name: 'Naga Siren', 'Snapfire': -1.63 },
    { name: "Nature's Prophet", 'Snapfire': -0.737 },
    { name: 'Necrophos', 'Snapfire': -0.853 },
    { name: 'Night Stalker', 'Snapfire': 0.697 },
    { name: 'Nyx Assassin', 'Snapfire': 0.446 },
    { name: 'Ogre Magi', 'Snapfire': 0.715 },
    { name: 'Omniknight', 'Snapfire': -0.936 },
    { name: 'Oracle', 'Snapfire': -0.097 },
    { name: 'Outworld Destroyer', 'Snapfire': 0.961 },
    { name: 'Pangolier', 'Snapfire': 0.075 },
    { name: 'Phantom Assassin', 'Snapfire': 0.695 },
    { name: 'Phantom Lancer', 'Snapfire': -1.208 },
    { name: 'Phoenix', 'Snapfire': -0.797 },
    { name: 'Primal Beast', 'Snapfire': 0.263 },
    { name: 'Puck', 'Snapfire': 0.545 },
    { name: 'Pudge', 'Snapfire': 0.178 },
    { name: 'Pugna', 'Snapfire': -1.006 },
    { name: 'Queen of Pain', 'Snapfire': -0.106 },
    { name: 'Razor', 'Snapfire': 0.263 },
    { name: 'Riki', 'Snapfire': -0.534 },
    { name: 'Ringmaster', 'Snapfire': -0.728 },
    { name: 'Rubick', 'Snapfire': -0.448 },
    { name: 'Sand King', 'Snapfire': -1.328 },
    { name: 'Shadow Demon', 'Snapfire': -0.95 },
    { name: 'Shadow Fiend', 'Snapfire': -0.761 },
    { name: 'Shadow Shaman', 'Snapfire': -0.69 },
    { name: 'Silencer', 'Snapfire': 0.25 },
    { name: 'Skywrath Mage', 'Snapfire': -0.276 },
    { name: 'Slardar', 'Snapfire': 0.903 },
    { name: 'Slark', 'Snapfire': -0.891 },
    { name: 'Sniper', 'Snapfire': -0.48 },
    { name: 'Spectre', 'Snapfire': -0.332 },
    { name: 'Spirit Breaker', 'Snapfire': 0.378 },
    { name: 'Storm Spirit', 'Snapfire': 0.609 },
    { name: 'Sven', 'Snapfire': 1.005 },
    { name: 'Techies', 'Snapfire': -0.708 },
    { name: 'Templar Assassin', 'Snapfire': 0.902 },
    { name: 'Terrorblade', 'Snapfire': -1.088 },
    { name: 'Tidehunter', 'Snapfire': -1.601 },
    { name: 'Timbersaw', 'Snapfire': -0.218 },
    { name: 'Tinker', 'Snapfire': -0.851 },
    { name: 'Tiny', 'Snapfire': -0.335 },
    { name: 'Treant Protector', 'Snapfire': -0.818 },
    { name: 'Troll Warlord', 'Snapfire': -0.086 },
    { name: 'Tusk', 'Snapfire': 0.197 },
    { name: 'Underlord', 'Snapfire': -1.942 },
    { name: 'Undying', 'Snapfire': -0.486 },
    { name: 'Ursa', 'Snapfire': 0.847 },
    { name: 'Vengeful Spirit', 'Snapfire': -0.155 },
    { name: 'Venomancer', 'Snapfire': -0.465 },
    { name: 'Viper', 'Snapfire': -0.475 },
    { name: 'Visage', 'Snapfire': -0.75 },
    { name: 'Void Spirit', 'Snapfire': -0.113 },
    { name: 'Warlock', 'Snapfire': -1.578 },
    { name: 'Weaver', 'Snapfire': 0.885 },
    { name: 'Windranger', 'Snapfire': 1.282 },
    { name: 'Winter Wyvern', 'Snapfire': -0.083 },
    { name: 'Witch Doctor', 'Snapfire': -0.816 },
    { name: 'Wraith King', 'Snapfire': 0.168 },
    { name: 'Zeus', 'Snapfire': 0.187 },
],
"Techies": [
    { name: 'Abaddon', 'Techies': -1.296 },
    { name: 'Alchemist', 'Techies': -0.229 },
    { name: 'Ancient Apparition', 'Techies': 0.407 },
    { name: 'Anti-Mage', 'Techies': 0.346 },
    { name: 'Arc Warden', 'Techies': -1.43 },
    { name: 'Axe', 'Techies': -0.026 },
    { name: 'Bane', 'Techies': 0.321 },
    { name: 'Batrider', 'Techies': 0.109 },
    { name: 'Beastmaster', 'Techies': -0.226 },
    { name: 'Bloodseeker', 'Techies': -0.238 },
    { name: 'Bounty Hunter', 'Techies': -1.085 },
    { name: 'Brewmaster', 'Techies': -0.152 },
    { name: 'Bristleback', 'Techies': 1.412 },
    { name: 'Broodmother', 'Techies': -2.233 },
    { name: 'Centaur Warrunner', 'Techies': 0.951 },
    { name: 'Chaos Knight', 'Techies': -1.275 },
    { name: 'Chen', 'Techies': 0.81 },
    { name: 'Clinkz', 'Techies': -1.35 },
    { name: 'Clockwerk', 'Techies': 0.513 },
    { name: 'Crystal Maiden', 'Techies': 0.008 },
    { name: 'Dark Seer', 'Techies': 0.078 },
    { name: 'Dark Willow', 'Techies': -1.341 },
    { name: 'Dawnbreaker', 'Techies': -0.032 },
    { name: 'Dazzle', 'Techies': 0.158 },
    { name: 'Death Prophet', 'Techies': 0.819 },
    { name: 'Disruptor', 'Techies': 0.158 },
    { name: 'Doom', 'Techies': -0.124 },
    { name: 'Dragon Knight', 'Techies': 0.222 },
    { name: 'Drow Ranger', 'Techies': 0.076 },
    { name: 'Earth Spirit', 'Techies': 0.126 },
    { name: 'Earthshaker', 'Techies': -0.591 },
    { name: 'Elder Titan', 'Techies': -1.557 },
    { name: 'Ember Spirit', 'Techies': -1.134 },
    { name: 'Enchantress', 'Techies': -0.25 },
    { name: 'Enigma', 'Techies': 1.285 },
    { name: 'Faceless Void', 'Techies': 0.781 },
    { name: 'Grimstroke', 'Techies': 0.403 },
    { name: 'Gyrocopter', 'Techies': -0.258 },
    { name: 'Hoodwink', 'Techies': -0.153 },
    { name: 'Huskar', 'Techies': 0.141 },
    { name: 'Invoker', 'Techies': -0.468 },
    { name: 'Io', 'Techies': -1.991 },
    { name: 'Jakiro', 'Techies': 0.935 },
    { name: 'Juggernaut', 'Techies': 1.212 },
    { name: 'Keeper of the Light', 'Techies': 0.401 },
    { name: 'Kunkka', 'Techies': -0.09 },
    { name: 'Legion Commander', 'Techies': -1.632 },
    { name: 'Leshrac', 'Techies': 1.09 },
    { name: 'Lich', 'Techies': 0.108 },
    { name: 'Lifestealer', 'Techies': 1.828 },
    { name: 'Lina', 'Techies': 0.357 },
    { name: 'Lion', 'Techies': 0.52 },
    { name: 'Lone Druid', 'Techies': -2.631 },
    { name: 'Luna', 'Techies': 0.078 },
    { name: 'Lycan', 'Techies': -1.103 },
    { name: 'Magnus', 'Techies': 0.43 },
    { name: 'Marci', 'Techies': -1.663 },
    { name: 'Mars', 'Techies': 0.677 },
    { name: 'Medusa', 'Techies': 2.072 },
    { name: 'Meepo', 'Techies': -3.399 },
    { name: 'Mirana', 'Techies': 0.312 },
    { name: 'Monkey King', 'Techies': -1.465 },
    { name: 'Morphling', 'Techies': -1.214 },
    { name: 'Muerta', 'Techies': 0.234 },
    { name: 'Naga Siren', 'Techies': -1.418 },
    { name: "Nature's Prophet", 'Techies': -0.282 },
    { name: 'Necrophos', 'Techies': 0.687 },
    { name: 'Night Stalker', 'Techies': -1.383 },
    { name: 'Nyx Assassin', 'Techies': 0.25 },
    { name: 'Ogre Magi', 'Techies': -0.256 },
    { name: 'Omniknight', 'Techies': -0.49 },
    { name: 'Oracle', 'Techies': 0.232 },
    { name: 'Outworld Destroyer', 'Techies': 0.369 },
    { name: 'Pangolier', 'Techies': 0.145 },
    { name: 'Phantom Assassin', 'Techies': -0.817 },
    { name: 'Phantom Lancer', 'Techies': -1.38 },
    { name: 'Phoenix', 'Techies': 0.285 },
    { name: 'Primal Beast', 'Techies': -0.314 },
    { name: 'Puck', 'Techies': -0.046 },
    { name: 'Pudge', 'Techies': 0.413 },
    { name: 'Pugna', 'Techies': 0.036 },
    { name: 'Queen of Pain', 'Techies': 0.108 },
    { name: 'Razor', 'Techies': -1.736 },
    { name: 'Riki', 'Techies': -1.245 },
    { name: 'Ringmaster', 'Techies': -1.38 },
    { name: 'Rubick', 'Techies': 0.245 },
    { name: 'Sand King', 'Techies': -0.033 },
    { name: 'Shadow Demon', 'Techies': -0.456 },
    { name: 'Shadow Fiend', 'Techies': -0.46 },
    { name: 'Shadow Shaman', 'Techies': -0.17 },
    { name: 'Silencer', 'Techies': -0.382 },
    { name: 'Skywrath Mage', 'Techies': -0.148 },
    { name: 'Slardar', 'Techies': -0.566 },
    { name: 'Slark', 'Techies': -3.05 },
    { name: 'Snapfire', 'Techies': 0.72 },
    { name: 'Sniper', 'Techies': -0.462 },
    { name: 'Spectre', 'Techies': 0.665 },
    { name: 'Spirit Breaker', 'Techies': 0.027 },
    { name: 'Storm Spirit', 'Techies': 0.026 },
    { name: 'Sven', 'Techies': -0.669 },
    { name: 'Templar Assassin', 'Techies': 2.082 },
    { name: 'Terrorblade', 'Techies': -1.363 },
    { name: 'Tidehunter', 'Techies': 1.021 },
    { name: 'Timbersaw', 'Techies': 1.274 },
    { name: 'Tinker', 'Techies': -0.382 },
    { name: 'Tiny', 'Techies': 0.334 },
    { name: 'Treant Protector', 'Techies': -0.97 },
    { name: 'Troll Warlord', 'Techies': -1.016 },
    { name: 'Tusk', 'Techies': -0.255 },
    { name: 'Underlord', 'Techies': 0.614 },
    { name: 'Undying', 'Techies': 0.021 },
    { name: 'Ursa', 'Techies': -0.595 },
    { name: 'Vengeful Spirit', 'Techies': 0.23 },
    { name: 'Venomancer', 'Techies': -0.074 },
    { name: 'Viper', 'Techies': -0.322 },
    { name: 'Visage', 'Techies': 0.456 },
    { name: 'Void Spirit', 'Techies': 0.206 },
    { name: 'Warlock', 'Techies': 0.19 },
    { name: 'Weaver', 'Techies': -0.885 },
    { name: 'Windranger', 'Techies': -1.425 },
    { name: 'Winter Wyvern', 'Techies': -1.02 },
    { name: 'Witch Doctor', 'Techies': 0.267 },
    { name: 'Wraith King', 'Techies': 1.492 },
    { name: 'Zeus', 'Techies': 0.41 },
],
"Timbersaw": [
    { name: 'Abaddon', 'Timbersaw': 0.339 },
    { name: 'Alchemist', 'Timbersaw': -1.262 },
    { name: 'Ancient Apparition', 'Timbersaw': 0.809 },
    { name: 'Anti-Mage', 'Timbersaw': -2.09 },
    { name: 'Arc Warden', 'Timbersaw': -0.044 },
    { name: 'Axe', 'Timbersaw': -2.831 },
    { name: 'Bane', 'Timbersaw': 0.895 },
    { name: 'Batrider', 'Timbersaw': 2.408 },
    { name: 'Beastmaster', 'Timbersaw': 1.266 },
    { name: 'Bloodseeker', 'Timbersaw': 2.003 },
    { name: 'Bounty Hunter', 'Timbersaw': 1.061 },
    { name: 'Brewmaster', 'Timbersaw': -0.065 },
    { name: 'Bristleback', 'Timbersaw': 0.552 },
    { name: 'Broodmother', 'Timbersaw': 1.016 },
    { name: 'Centaur Warrunner', 'Timbersaw': -1.583 },
    { name: 'Chaos Knight', 'Timbersaw': -2.575 },
    { name: 'Chen', 'Timbersaw': -0.628 },
    { name: 'Clinkz', 'Timbersaw': 0.584 },
    { name: 'Clockwerk', 'Timbersaw': -0.313 },
    { name: 'Crystal Maiden', 'Timbersaw': 0.413 },
    { name: 'Dark Seer', 'Timbersaw': -2.233 },
    { name: 'Dark Willow', 'Timbersaw': 0.404 },
    { name: 'Dawnbreaker', 'Timbersaw': -1.064 },
    { name: 'Dazzle', 'Timbersaw': 0.541 },
    { name: 'Death Prophet', 'Timbersaw': 2.043 },
    { name: 'Disruptor', 'Timbersaw': 0.651 },
    { name: 'Doom', 'Timbersaw': 1.663 },
    { name: 'Dragon Knight', 'Timbersaw': -0.813 },
    { name: 'Drow Ranger', 'Timbersaw': -0.208 },
    { name: 'Earth Spirit', 'Timbersaw': 0.889 },
    { name: 'Earthshaker', 'Timbersaw': 0.222 },
    { name: 'Elder Titan', 'Timbersaw': 1.44 },
    { name: 'Ember Spirit', 'Timbersaw': 0.326 },
    { name: 'Enchantress', 'Timbersaw': 0.681 },
    { name: 'Enigma', 'Timbersaw': 0.567 },
    { name: 'Faceless Void', 'Timbersaw': 2.398 },
    { name: 'Grimstroke', 'Timbersaw': 2.904 },
    { name: 'Gyrocopter', 'Timbersaw': 0.808 },
    { name: 'Hoodwink', 'Timbersaw': -1.572 },
    { name: 'Huskar', 'Timbersaw': -2.079 },
    { name: 'Invoker', 'Timbersaw': 0.473 },
    { name: 'Io', 'Timbersaw': 0.723 },
    { name: 'Jakiro', 'Timbersaw': 0.392 },
    { name: 'Juggernaut', 'Timbersaw': 0.312 },
    { name: 'Keeper of the Light', 'Timbersaw': 0.76 },
    { name: 'Kunkka', 'Timbersaw': -2.045 },
    { name: 'Legion Commander', 'Timbersaw': -0.213 },
    { name: 'Leshrac', 'Timbersaw': 0.988 },
    { name: 'Lich', 'Timbersaw': -1.303 },
    { name: 'Lifestealer', 'Timbersaw': 0.866 },
    { name: 'Lina', 'Timbersaw': 2.083 },
    { name: 'Lion', 'Timbersaw': 1.258 },
    { name: 'Lone Druid', 'Timbersaw': -1.647 },
    { name: 'Luna', 'Timbersaw': 0.376 },
    { name: 'Lycan', 'Timbersaw': 1.61 },
    { name: 'Magnus', 'Timbersaw': 1.069 },
    { name: 'Marci', 'Timbersaw': 1.496 },
    { name: 'Mars', 'Timbersaw': 1.225 },
    { name: 'Medusa', 'Timbersaw': 3.451 },
    { name: 'Meepo', 'Timbersaw': -4.327 },
    { name: 'Mirana', 'Timbersaw': 1.443 },
    { name: 'Monkey King', 'Timbersaw': -1.037 },
    { name: 'Morphling', 'Timbersaw': -0.815 },
    { name: 'Muerta', 'Timbersaw': 0.74 },
    { name: 'Naga Siren', 'Timbersaw': -3.484 },
    { name: "Nature's Prophet", 'Timbersaw': -0.228 },
    { name: 'Necrophos', 'Timbersaw': -0.831 },
    { name: 'Night Stalker', 'Timbersaw': 1.868 },
    { name: 'Nyx Assassin', 'Timbersaw': 1.094 },
    { name: 'Ogre Magi', 'Timbersaw': 0.482 },
    { name: 'Omniknight', 'Timbersaw': 0.046 },
    { name: 'Oracle', 'Timbersaw': 0.083 },
    { name: 'Outworld Destroyer', 'Timbersaw': 3.644 },
    { name: 'Pangolier', 'Timbersaw': 0.268 },
    { name: 'Phantom Assassin', 'Timbersaw': -1.771 },
    { name: 'Phantom Lancer', 'Timbersaw': -4.418 },
    { name: 'Phoenix', 'Timbersaw': 0.051 },
    { name: 'Primal Beast', 'Timbersaw': 0.319 },
    { name: 'Puck', 'Timbersaw': 1.511 },
    { name: 'Pudge', 'Timbersaw': -2.02 },
    { name: 'Pugna', 'Timbersaw': 3.764 },
    { name: 'Queen of Pain', 'Timbersaw': 2.342 },
    { name: 'Razor', 'Timbersaw': -1.858 },
    { name: 'Riki', 'Timbersaw': -0.429 },
    { name: 'Ringmaster', 'Timbersaw': -0.831 },
    { name: 'Rubick', 'Timbersaw': 0.263 },
    { name: 'Sand King', 'Timbersaw': -1.209 },
    { name: 'Shadow Demon', 'Timbersaw': -2.109 },
    { name: 'Shadow Fiend', 'Timbersaw': 1.032 },
    { name: 'Shadow Shaman', 'Timbersaw': 0.257 },
    { name: 'Silencer', 'Timbersaw': 4.266 },
    { name: 'Skywrath Mage', 'Timbersaw': 2.672 },
    { name: 'Slardar', 'Timbersaw': 0.975 },
    { name: 'Slark', 'Timbersaw': -2.444 },
    { name: 'Snapfire', 'Timbersaw': 0.294 },
    { name: 'Sniper', 'Timbersaw': -1.522 },
    { name: 'Spectre', 'Timbersaw': -0.207 },
    { name: 'Spirit Breaker', 'Timbersaw': -0.746 },
    { name: 'Storm Spirit', 'Timbersaw': 1.219 },
    { name: 'Sven', 'Timbersaw': 0.279 },
    { name: 'Techies', 'Timbersaw': -1.279 },
    { name: 'Templar Assassin', 'Timbersaw': 2.34 },
    { name: 'Terrorblade', 'Timbersaw': -1.631 },
    { name: 'Tidehunter', 'Timbersaw': -1.98 },
    { name: 'Tinker', 'Timbersaw': 0.805 },
    { name: 'Tiny', 'Timbersaw': -1.224 },
    { name: 'Treant Protector', 'Timbersaw': -1.948 },
    { name: 'Troll Warlord', 'Timbersaw': 0.921 },
    { name: 'Tusk', 'Timbersaw': -1.659 },
    { name: 'Underlord', 'Timbersaw': -1.617 },
    { name: 'Undying', 'Timbersaw': -1.843 },
    { name: 'Ursa', 'Timbersaw': 2.954 },
    { name: 'Vengeful Spirit', 'Timbersaw': 0.258 },
    { name: 'Venomancer', 'Timbersaw': -1.12 },
    { name: 'Viper', 'Timbersaw': 0.193 },
    { name: 'Visage', 'Timbersaw': 0.593 },
    { name: 'Void Spirit', 'Timbersaw': -0.495 },
    { name: 'Warlock', 'Timbersaw': -1.074 },
    { name: 'Weaver', 'Timbersaw': 2.124 },
    { name: 'Windranger', 'Timbersaw': 1.227 },
    { name: 'Winter Wyvern', 'Timbersaw': -3.337 },
    { name: 'Witch Doctor', 'Timbersaw': 1.874 },
    { name: 'Wraith King', 'Timbersaw': -1.271 },
    { name: 'Zeus', 'Timbersaw': 0.252 },
],
"Vengeful Spirit": [
    { name: 'Abaddon', 'Vengeful Spirit': 0.214 },
    { name: 'Alchemist', 'Vengeful Spirit': 0.253 },
    { name: 'Ancient Apparition', 'Vengeful Spirit': -0.959 },
    { name: 'Anti-Mage', 'Vengeful Spirit': 0.642 },
    { name: 'Arc Warden', 'Vengeful Spirit': 0.034 },
    { name: 'Axe', 'Vengeful Spirit': 0.018 },
    { name: 'Bane', 'Vengeful Spirit': 0.235 },
    { name: 'Batrider', 'Vengeful Spirit': -0.513 },
    { name: 'Beastmaster', 'Vengeful Spirit': 1.19 },
    { name: 'Bloodseeker', 'Vengeful Spirit': 0.044 },
    { name: 'Bounty Hunter', 'Vengeful Spirit': 0.159 },
    { name: 'Brewmaster', 'Vengeful Spirit': 1.24 },
    { name: 'Bristleback', 'Vengeful Spirit': -0.361 },
    { name: 'Broodmother', 'Vengeful Spirit': 0.879 },
    { name: 'Centaur Warrunner', 'Vengeful Spirit': -0.195 },
    { name: 'Chaos Knight', 'Vengeful Spirit': 1.877 },
    { name: 'Chen', 'Vengeful Spirit': 1.661 },
    { name: 'Clinkz', 'Vengeful Spirit': -0.761 },
    { name: 'Clockwerk', 'Vengeful Spirit': -0.091 },
    { name: 'Crystal Maiden', 'Vengeful Spirit': 0.441 },
    { name: 'Dark Seer', 'Vengeful Spirit': 1.587 },
    { name: 'Dark Willow', 'Vengeful Spirit': 1.124 },
    { name: 'Dawnbreaker', 'Vengeful Spirit': 0.013 },
    { name: 'Dazzle', 'Vengeful Spirit': 0.296 },
    { name: 'Death Prophet', 'Vengeful Spirit': -0.229 },
    { name: 'Disruptor', 'Vengeful Spirit': -0.18 },
    { name: 'Doom', 'Vengeful Spirit': -0.197 },
    { name: 'Dragon Knight', 'Vengeful Spirit': 0.543 },
    { name: 'Drow Ranger', 'Vengeful Spirit': -0.057 },
    { name: 'Earth Spirit', 'Vengeful Spirit': -0.097 },
    { name: 'Earthshaker', 'Vengeful Spirit': 0.277 },
    { name: 'Elder Titan', 'Vengeful Spirit': 0.715 },
    { name: 'Ember Spirit', 'Vengeful Spirit': 1.31 },
    { name: 'Enchantress', 'Vengeful Spirit': 0.613 },
    { name: 'Enigma', 'Vengeful Spirit': -0.063 },
    { name: 'Faceless Void', 'Vengeful Spirit': -0.428 },
    { name: 'Grimstroke', 'Vengeful Spirit': -0.361 },
    { name: 'Gyrocopter', 'Vengeful Spirit': 0.347 },
    { name: 'Hoodwink', 'Vengeful Spirit': -0.609 },
    { name: 'Huskar', 'Vengeful Spirit': -0.48 },
    { name: 'Invoker', 'Vengeful Spirit': -0.3 },
    { name: 'Io', 'Vengeful Spirit': -0.604 },
    { name: 'Jakiro', 'Vengeful Spirit': 0.795 },
    { name: 'Juggernaut', 'Vengeful Spirit': 0.501 },
    { name: 'Keeper of the Light', 'Vengeful Spirit': -0.122 },
    { name: 'Kunkka', 'Vengeful Spirit': 0.892 },
    { name: 'Legion Commander', 'Vengeful Spirit': -1.68 },
    { name: 'Leshrac', 'Vengeful Spirit': -0.142 },
    { name: 'Lich', 'Vengeful Spirit': 0.751 },
    { name: 'Lifestealer', 'Vengeful Spirit': 0.62 },
    { name: 'Lina', 'Vengeful Spirit': -0.551 },
    { name: 'Lion', 'Vengeful Spirit': -0.55 },
    { name: 'Lone Druid', 'Vengeful Spirit': 1.659 },
    { name: 'Luna', 'Vengeful Spirit': 1.07 },
    { name: 'Lycan', 'Vengeful Spirit': -0.284 },
    { name: 'Magnus', 'Vengeful Spirit': 0.577 },
    { name: 'Marci', 'Vengeful Spirit': 0.854 },
    { name: 'Mars', 'Vengeful Spirit': 0.271 },
    { name: 'Medusa', 'Vengeful Spirit': 0.713 },
    { name: 'Meepo', 'Vengeful Spirit': 1.457 },
    { name: 'Mirana', 'Vengeful Spirit': -0.25 },
    { name: 'Monkey King', 'Vengeful Spirit': 0.457 },
    { name: 'Morphling', 'Vengeful Spirit': 1.063 },
    { name: 'Muerta', 'Vengeful Spirit': 1.281 },
    { name: 'Naga Siren', 'Vengeful Spirit': 1.714 },
    { name: "Nature's Prophet", 'Vengeful Spirit': -0.22 },
    { name: 'Necrophos', 'Vengeful Spirit': 1.279 },
    { name: 'Night Stalker', 'Vengeful Spirit': -1.312 },
    { name: 'Nyx Assassin', 'Vengeful Spirit': -1.25 },
    { name: 'Ogre Magi', 'Vengeful Spirit': 0.037 },
    { name: 'Omniknight', 'Vengeful Spirit': 0.571 },
    { name: 'Oracle', 'Vengeful Spirit': -1.445 },
    { name: 'Outworld Destroyer', 'Vengeful Spirit': 0.02 },
    { name: 'Pangolier', 'Vengeful Spirit': 0.295 },
    { name: 'Phantom Assassin', 'Vengeful Spirit': -0.386 },
    { name: 'Phantom Lancer', 'Vengeful Spirit': 3.975 },
    { name: 'Phoenix', 'Vengeful Spirit': 0.089 },
    { name: 'Primal Beast', 'Vengeful Spirit': 0.614 },
    { name: 'Puck', 'Vengeful Spirit': 0.772 },
    { name: 'Pudge', 'Vengeful Spirit': -0.678 },
    { name: 'Pugna', 'Vengeful Spirit': -1.272 },
    { name: 'Queen of Pain', 'Vengeful Spirit': -0.213 },
    { name: 'Razor', 'Vengeful Spirit': 0.08 },
    { name: 'Riki', 'Vengeful Spirit': 0.342 },
    { name: 'Ringmaster', 'Vengeful Spirit': -0.043 },
    { name: 'Rubick', 'Vengeful Spirit': 0.491 },
    { name: 'Sand King', 'Vengeful Spirit': 1.054 },
    { name: 'Shadow Demon', 'Vengeful Spirit': 0.144 },
    { name: 'Shadow Fiend', 'Vengeful Spirit': -0.452 },
    { name: 'Shadow Shaman', 'Vengeful Spirit': -0.499 },
    { name: 'Silencer', 'Vengeful Spirit': 0.059 },
    { name: 'Skywrath Mage', 'Vengeful Spirit': -1.058 },
    { name: 'Slardar', 'Vengeful Spirit': -0.672 },
    { name: 'Slark', 'Vengeful Spirit': -0.14 },
    { name: 'Snapfire', 'Vengeful Spirit': 0.378 },
    { name: 'Sniper', 'Vengeful Spirit': 0.375 },
    { name: 'Spectre', 'Vengeful Spirit': 0.777 },
    { name: 'Spirit Breaker', 'Vengeful Spirit': 0.184 },
    { name: 'Storm Spirit', 'Vengeful Spirit': 0.497 },
    { name: 'Sven', 'Vengeful Spirit': 0.427 },
    { name: 'Techies', 'Vengeful Spirit': -0.15 },
    { name: 'Templar Assassin', 'Vengeful Spirit': 0.618 },
    { name: 'Terrorblade', 'Vengeful Spirit': 2.402 },
    { name: 'Tidehunter', 'Vengeful Spirit': 1.586 },
    { name: 'Timbersaw', 'Vengeful Spirit': -0.224 },
    { name: 'Tinker', 'Vengeful Spirit': -0.168 },
    { name: 'Tiny', 'Vengeful Spirit': 0.582 },
    { name: 'Treant Protector', 'Vengeful Spirit': 0.693 },
    { name: 'Troll Warlord', 'Vengeful Spirit': 0.384 },
    { name: 'Tusk', 'Vengeful Spirit': 0.781 },
    { name: 'Underlord', 'Vengeful Spirit': 1.078 },
    { name: 'Undying', 'Vengeful Spirit': 0.399 },
    { name: 'Ursa', 'Vengeful Spirit': -0.555 },
    { name: 'Venomancer', 'Vengeful Spirit': 0.099 },
    { name: 'Viper', 'Vengeful Spirit': -0.061 },
    { name: 'Visage', 'Vengeful Spirit': 0.801 },
    { name: 'Void Spirit', 'Vengeful Spirit': -0.139 },
    { name: 'Warlock', 'Vengeful Spirit': 0.912 },
    { name: 'Weaver', 'Vengeful Spirit': -0.21 },
    { name: 'Windranger', 'Vengeful Spirit': 0.082 },
    { name: 'Winter Wyvern', 'Vengeful Spirit': 1.266 },
    { name: 'Witch Doctor', 'Vengeful Spirit': 0.059 },
    { name: 'Wraith King', 'Vengeful Spirit': -0.357 },
    { name: 'Zeus', 'Vengeful Spirit': -0.718 },
],
"Venomancer": [
    { name: 'Abaddon', 'Venomancer': 0.228 },
    { name: 'Alchemist', 'Venomancer': -0.873 },
    { name: 'Ancient Apparition', 'Venomancer': 0.195 },
    { name: 'Anti-Mage', 'Venomancer': 0.448 },
    { name: 'Arc Warden', 'Venomancer': -0.367 },
    { name: 'Axe', 'Venomancer': -3.321 },
    { name: 'Bane', 'Venomancer': -0.366 },
    { name: 'Batrider', 'Venomancer': -1.388 },
    { name: 'Beastmaster', 'Venomancer': -0.411 },
    { name: 'Bloodseeker', 'Venomancer': -0.491 },
    { name: 'Bounty Hunter', 'Venomancer': -0.812 },
    { name: 'Brewmaster', 'Venomancer': 0.653 },
    { name: 'Bristleback', 'Venomancer': -2.614 },
    { name: 'Broodmother', 'Venomancer': 0.157 },
    { name: 'Centaur Warrunner', 'Venomancer': -1.117 },
    { name: 'Chaos Knight', 'Venomancer': -1.017 },
    { name: 'Chen', 'Venomancer': 0.632 },
    { name: 'Clinkz', 'Venomancer': 0.492 },
    { name: 'Clockwerk', 'Venomancer': -1.845 },
    { name: 'Crystal Maiden', 'Venomancer': 0.179 },
    { name: 'Dark Seer', 'Venomancer': 2.099 },
    { name: 'Dark Willow', 'Venomancer': -0.522 },
    { name: 'Dawnbreaker', 'Venomancer': -0.572 },
    { name: 'Dazzle', 'Venomancer': -1.44 },
    { name: 'Death Prophet', 'Venomancer': -0.841 },
    { name: 'Disruptor', 'Venomancer': -0.766 },
    { name: 'Doom', 'Venomancer': -0.782 },
    { name: 'Dragon Knight', 'Venomancer': -1.776 },
    { name: 'Drow Ranger', 'Venomancer': -0.602 },
    { name: 'Earth Spirit', 'Venomancer': 0.89 },
    { name: 'Earthshaker', 'Venomancer': -1.472 },
    { name: 'Elder Titan', 'Venomancer': -1.072 },
    { name: 'Ember Spirit', 'Venomancer': 0.856 },
    { name: 'Enchantress', 'Venomancer': 0.529 },
    { name: 'Enigma', 'Venomancer': 0.639 },
    { name: 'Faceless Void', 'Venomancer': 0.413 },
    { name: 'Grimstroke', 'Venomancer': -0.472 },
    { name: 'Gyrocopter', 'Venomancer': -0.635 },
    { name: 'Hoodwink', 'Venomancer': 0.094 },
    { name: 'Huskar', 'Venomancer': 0.137 },
    { name: 'Invoker', 'Venomancer': -0.126 },
    { name: 'Io', 'Venomancer': -1.811 },
    { name: 'Jakiro', 'Venomancer': -0.411 },
    { name: 'Juggernaut', 'Venomancer': 1.247 },
    { name: 'Keeper of the Light', 'Venomancer': -0.116 },
    { name: 'Kunkka', 'Venomancer': -1.721 },
    { name: 'Legion Commander', 'Venomancer': 1.715 },
    { name: 'Leshrac', 'Venomancer': -0.298 },
    { name: 'Lich', 'Venomancer': -0.39 },
    { name: 'Lifestealer', 'Venomancer': -0.43 },
    { name: 'Lina', 'Venomancer': 0.994 },
    { name: 'Lion', 'Venomancer': -0.865 },
    { name: 'Lone Druid', 'Venomancer': -3.735 },
    { name: 'Luna', 'Venomancer': -0.781 },
    { name: 'Lycan', 'Venomancer': 0.773 },
    { name: 'Magnus', 'Venomancer': 0.614 },
    { name: 'Marci', 'Venomancer': -0.042 },
    { name: 'Mars', 'Venomancer': -0.547 },
    { name: 'Medusa', 'Venomancer': -0.519 },
    { name: 'Meepo', 'Venomancer': -1.322 },
    { name: 'Mirana', 'Venomancer': 1.311 },
    { name: 'Monkey King', 'Venomancer': -1.246 },
    { name: 'Morphling', 'Venomancer': 1.724 },
    { name: 'Muerta', 'Venomancer': 0.824 },
    { name: 'Naga Siren', 'Venomancer': -0.486 },
    { name: "Nature's Prophet", 'Venomancer': 1.356 },
    { name: 'Necrophos', 'Venomancer': -1.096 },
    { name: 'Night Stalker', 'Venomancer': -2.446 },
    { name: 'Nyx Assassin', 'Venomancer': -0.648 },
    { name: 'Ogre Magi', 'Venomancer': -0.878 },
    { name: 'Omniknight', 'Venomancer': -1.245 },
    { name: 'Oracle', 'Venomancer': 0.183 },
    { name: 'Outworld Destroyer', 'Venomancer': -0.511 },
    { name: 'Pangolier', 'Venomancer': 0.519 },
    { name: 'Phantom Assassin', 'Venomancer': 0.872 },
    { name: 'Phantom Lancer', 'Venomancer': 1.002 },
    { name: 'Phoenix', 'Venomancer': 0.544 },
    { name: 'Primal Beast', 'Venomancer': -1.089 },
    { name: 'Puck', 'Venomancer': 0.217 },
    { name: 'Pudge', 'Venomancer': 0.502 },
    { name: 'Pugna', 'Venomancer': 0.558 },
    { name: 'Queen of Pain', 'Venomancer': 0.845 },
    { name: 'Razor', 'Venomancer': -0.543 },
    { name: 'Riki', 'Venomancer': -0.747 },
    { name: 'Ringmaster', 'Venomancer': -1.024 },
    { name: 'Rubick', 'Venomancer': 0.41 },
    { name: 'Sand King', 'Venomancer': -0.524 },
    { name: 'Shadow Demon', 'Venomancer': 1.55 },
    { name: 'Shadow Fiend', 'Venomancer': -1.22 },
    { name: 'Shadow Shaman', 'Venomancer': -0.371 },
    { name: 'Silencer', 'Venomancer': -1.386 },
    { name: 'Skywrath Mage', 'Venomancer': -0.236 },
    { name: 'Slardar', 'Venomancer': -1.478 },
    { name: 'Slark', 'Venomancer': 1.931 },
    { name: 'Snapfire', 'Venomancer': 0.541 },
    { name: 'Sniper', 'Venomancer': 1.273 },
    { name: 'Spectre', 'Venomancer': -1.525 },
    { name: 'Spirit Breaker', 'Venomancer': -1.892 },
    { name: 'Storm Spirit', 'Venomancer': 2.198 },
    { name: 'Sven', 'Venomancer': -0.931 },
    { name: 'Techies', 'Venomancer': 0.063 },
    { name: 'Templar Assassin', 'Venomancer': -1.71 },
    { name: 'Terrorblade', 'Venomancer': 0.709 },
    { name: 'Tidehunter', 'Venomancer': -0.787 },
    { name: 'Timbersaw', 'Venomancer': 1.011 },
    { name: 'Tinker', 'Venomancer': 1.157 },
    { name: 'Tiny', 'Venomancer': -1.667 },
    { name: 'Treant Protector', 'Venomancer': -0.761 },
    { name: 'Troll Warlord', 'Venomancer': -0.379 },
    { name: 'Tusk', 'Venomancer': 0.356 },
    { name: 'Underlord', 'Venomancer': -0.554 },
    { name: 'Undying', 'Venomancer': -1.932 },
    { name: 'Ursa', 'Venomancer': -1.981 },
    { name: 'Vengeful Spirit', 'Venomancer': -0.149 },
    { name: 'Viper', 'Venomancer': 0.745 },
    { name: 'Visage', 'Venomancer': -1.02 },
    { name: 'Void Spirit', 'Venomancer': -0.04 },
    { name: 'Warlock', 'Venomancer': -0.537 },
    { name: 'Weaver', 'Venomancer': 1.581 },
    { name: 'Windranger', 'Venomancer': 2.309 },
    { name: 'Winter Wyvern', 'Venomancer': -0.978 },
    { name: 'Witch Doctor', 'Venomancer': 0.265 },
    { name: 'Wraith King', 'Venomancer': -2.562 },
    { name: 'Zeus', 'Venomancer': -0.521 },
],
"Visage": [
    { name: 'Abaddon', 'Visage': 0.79 },
    { name: 'Alchemist', 'Visage': 1.24 },
    { name: 'Ancient Apparition', 'Visage': -0.094 },
    { name: 'Anti-Mage', 'Visage': -0.046 },
    { name: 'Arc Warden', 'Visage': -1.038 },
    { name: 'Axe', 'Visage': 2.597 },
    { name: 'Bane', 'Visage': -2.534 },
    { name: 'Batrider', 'Visage': 1.411 },
    { name: 'Beastmaster', 'Visage': 1.275 },
    { name: 'Bloodseeker', 'Visage': 0.591 },
    { name: 'Bounty Hunter', 'Visage': 0.475 },
    { name: 'Brewmaster', 'Visage': 2.505 },
    { name: 'Bristleback', 'Visage': 3.351 },
    { name: 'Broodmother', 'Visage': 0.085 },
    { name: 'Centaur Warrunner', 'Visage': 0.157 },
    { name: 'Chaos Knight', 'Visage': 2.455 },
    { name: 'Chen', 'Visage': -0.448 },
    { name: 'Clinkz', 'Visage': 1.12 },
    { name: 'Clockwerk', 'Visage': -0.886 },
    { name: 'Crystal Maiden', 'Visage': 1.136 },
    { name: 'Dark Seer', 'Visage': 1.32 },
    { name: 'Dark Willow', 'Visage': 1.72 },
    { name: 'Dawnbreaker', 'Visage': -0.867 },
    { name: 'Dazzle', 'Visage': 0.765 },
    { name: 'Death Prophet', 'Visage': -0.032 },
    { name: 'Disruptor', 'Visage': 1.265 },
    { name: 'Doom', 'Visage': -0.472 },
    { name: 'Dragon Knight', 'Visage': -0.648 },
    { name: 'Drow Ranger', 'Visage': -0.037 },
    { name: 'Earth Spirit', 'Visage': -0.141 },
    { name: 'Earthshaker', 'Visage': 2.119 },
    { name: 'Elder Titan', 'Visage': -3.083 },
    { name: 'Ember Spirit', 'Visage': 1.939 },
    { name: 'Enchantress', 'Visage': 0.4 },
    { name: 'Enigma', 'Visage': 0.739 },
    { name: 'Faceless Void', 'Visage': 0.911 },
    { name: 'Grimstroke', 'Visage': -1.769 },
    { name: 'Gyrocopter', 'Visage': 0.216 },
    { name: 'Hoodwink', 'Visage': 1.33 },
    { name: 'Huskar', 'Visage': 0.242 },
    { name: 'Invoker', 'Visage': -0.819 },
    { name: 'Io', 'Visage': 1.13 },
    { name: 'Jakiro', 'Visage': 0.845 },
    { name: 'Juggernaut', 'Visage': -3.463 },
    { name: 'Keeper of the Light', 'Visage': -0.218 },
    { name: 'Kunkka', 'Visage': -0.828 },
    { name: 'Legion Commander', 'Visage': -0.571 },
    { name: 'Leshrac', 'Visage': -0.793 },
    { name: 'Lich', 'Visage': -0.835 },
    { name: 'Lifestealer', 'Visage': -2.234 },
    { name: 'Lina', 'Visage': 0.639 },
    { name: 'Lion', 'Visage': -2.02 },
    { name: 'Lone Druid', 'Visage': -0.021 },
    { name: 'Luna', 'Visage': -0.11 },
    { name: 'Lycan', 'Visage': 0.37 },
    { name: 'Magnus', 'Visage': 0.114 },
    { name: 'Marci', 'Visage': 1.439 },
    { name: 'Mars', 'Visage': -0.099 },
    { name: 'Medusa', 'Visage': 2.317 },
    { name: 'Meepo', 'Visage': -1.425 },
    { name: 'Mirana', 'Visage': -0.314 },
    { name: 'Monkey King', 'Visage': 0.145 },
    { name: 'Morphling', 'Visage': -1.47 },
    { name: 'Muerta', 'Visage': -0.167 },
    { name: 'Naga Siren', 'Visage': 2.353 },
    { name: "Nature's Prophet", 'Visage': -0.246 },
    { name: 'Necrophos', 'Visage': -1.846 },
    { name: 'Night Stalker', 'Visage': -1.559 },
    { name: 'Nyx Assassin', 'Visage': -1.697 },
    { name: 'Ogre Magi', 'Visage': 0.087 },
    { name: 'Omniknight', 'Visage': -0.402 },
    { name: 'Oracle', 'Visage': -1.116 },
    { name: 'Outworld Destroyer', 'Visage': -2.072 },
    { name: 'Pangolier', 'Visage': 1.933 },
    { name: 'Phantom Assassin', 'Visage': 0.809 },
    { name: 'Phantom Lancer', 'Visage': 3.108 },
    { name: 'Phoenix', 'Visage': -0.334 },
    { name: 'Primal Beast', 'Visage': 0.135 },
    { name: 'Puck', 'Visage': 1.713 },
    { name: 'Pudge', 'Visage': -0.76 },
    { name: 'Pugna', 'Visage': -0.959 },
    { name: 'Queen of Pain', 'Visage': -0.522 },
    { name: 'Razor', 'Visage': 2.662 },
    { name: 'Riki', 'Visage': 0.937 },
    { name: 'Ringmaster', 'Visage': -0.262 },
    { name: 'Rubick', 'Visage': -1.031 },
    { name: 'Sand King', 'Visage': 0.842 },
    { name: 'Shadow Demon', 'Visage': 0.822 },
    { name: 'Shadow Fiend', 'Visage': -1.682 },
    { name: 'Shadow Shaman', 'Visage': -0.197 },
    { name: 'Silencer', 'Visage': -0.069 },
    { name: 'Skywrath Mage', 'Visage': -1.043 },
    { name: 'Slardar', 'Visage': -0.17 },
    { name: 'Slark', 'Visage': -0.63 },
    { name: 'Snapfire', 'Visage': 0.557 },
    { name: 'Sniper', 'Visage': 0.939 },
    { name: 'Spectre', 'Visage': 0.009 },
    { name: 'Spirit Breaker', 'Visage': -0.547 },
    { name: 'Storm Spirit', 'Visage': 0.002 },
    { name: 'Sven', 'Visage': 0.999 },
    { name: 'Techies', 'Visage': -0.706 },
    { name: 'Templar Assassin', 'Visage': -0.23 },
    { name: 'Terrorblade', 'Visage': 2.872 },
    { name: 'Tidehunter', 'Visage': 0.038 },
    { name: 'Timbersaw', 'Visage': -0.65 },
    { name: 'Tinker', 'Visage': -0.879 },
    { name: 'Tiny', 'Visage': -0.366 },
    { name: 'Treant Protector', 'Visage': 0.889 },
    { name: 'Troll Warlord', 'Visage': -0.329 },
    { name: 'Tusk', 'Visage': 0.191 },
    { name: 'Underlord', 'Visage': 1.536 },
    { name: 'Undying', 'Visage': 1.149 },
    { name: 'Ursa', 'Visage': -0.307 },
    { name: 'Vengeful Spirit', 'Visage': -0.811 },
    { name: 'Venomancer', 'Visage': 0.761 },
    { name: 'Viper', 'Visage': 1.338 },
    { name: 'Void Spirit', 'Visage': 0.223 },
    { name: 'Warlock', 'Visage': 0.969 },
    { name: 'Weaver', 'Visage': 1.206 },
    { name: 'Windranger', 'Visage': 2.157 },
    { name: 'Winter Wyvern', 'Visage': 2.107 },
    { name: 'Witch Doctor', 'Visage': -1.145 },
    { name: 'Wraith King', 'Visage': -2.577 },
    { name: 'Zeus', 'Visage': -1.595 },
],
"Void Spirit": [
    { name: 'Abaddon', 'Void Spirit': 1.029 },
    { name: 'Alchemist', 'Void Spirit': 2.035 },
    { name: 'Ancient Apparition', 'Void Spirit': -2.08 },
    { name: 'Anti-Mage', 'Void Spirit': 1.608 },
    { name: 'Arc Warden', 'Void Spirit': -0.928 },
    { name: 'Axe', 'Void Spirit': 1.746 },
    { name: 'Bane', 'Void Spirit': 0.77 },
    { name: 'Batrider', 'Void Spirit': 0.783 },
    { name: 'Beastmaster', 'Void Spirit': 1.745 },
    { name: 'Bloodseeker', 'Void Spirit': 1.158 },
    { name: 'Bounty Hunter', 'Void Spirit': 0.902 },
    { name: 'Brewmaster', 'Void Spirit': -0.589 },
    { name: 'Bristleback', 'Void Spirit': -1.191 },
    { name: 'Broodmother', 'Void Spirit': 1.706 },
    { name: 'Centaur Warrunner', 'Void Spirit': 0.041 },
    { name: 'Chaos Knight', 'Void Spirit': 0.302 },
    { name: 'Chen', 'Void Spirit': 2.366 },
    { name: 'Clinkz', 'Void Spirit': -2.525 },
    { name: 'Clockwerk', 'Void Spirit': -1.387 },
    { name: 'Crystal Maiden', 'Void Spirit': -0.264 },
    { name: 'Dark Seer', 'Void Spirit': 0.786 },
    { name: 'Dark Willow', 'Void Spirit': 0.016 },
    { name: 'Dawnbreaker', 'Void Spirit': 1.056 },
    { name: 'Dazzle', 'Void Spirit': 0.616 },
    { name: 'Death Prophet', 'Void Spirit': 0.625 },
    { name: 'Disruptor', 'Void Spirit': 0.139 },
    { name: 'Doom', 'Void Spirit': 0.372 },
    { name: 'Dragon Knight', 'Void Spirit': 1.407 },
    { name: 'Drow Ranger', 'Void Spirit': -1.099 },
    { name: 'Earth Spirit', 'Void Spirit': -0.161 },
    { name: 'Earthshaker', 'Void Spirit': 0.719 },
    { name: 'Elder Titan', 'Void Spirit': 0.933 },
    { name: 'Ember Spirit', 'Void Spirit': 2.252 },
    { name: 'Enchantress', 'Void Spirit': 1.034 },
    { name: 'Enigma', 'Void Spirit': 0.679 },
    { name: 'Faceless Void', 'Void Spirit': -0.566 },
    { name: 'Grimstroke', 'Void Spirit': 0.68 },
    { name: 'Gyrocopter', 'Void Spirit': 0.735 },
    { name: 'Hoodwink', 'Void Spirit': -1.165 },
    { name: 'Huskar', 'Void Spirit': 3.854 },
    { name: 'Invoker', 'Void Spirit': -2.116 },
    { name: 'Io', 'Void Spirit': 1.659 },
    { name: 'Jakiro', 'Void Spirit': 0.108 },
    { name: 'Juggernaut', 'Void Spirit': -2.209 },
    { name: 'Keeper of the Light', 'Void Spirit': -1.205 },
    { name: 'Kunkka', 'Void Spirit': -0.181 },
    { name: 'Legion Commander', 'Void Spirit': 0.685 },
    { name: 'Leshrac', 'Void Spirit': 0.071 },
    { name: 'Lich', 'Void Spirit': -0.366 },
    { name: 'Lifestealer', 'Void Spirit': -0.141 },
    { name: 'Lina', 'Void Spirit': -0.301 },
    { name: 'Lion', 'Void Spirit': 0.322 },
    { name: 'Lone Druid', 'Void Spirit': 4.076 },
    { name: 'Luna', 'Void Spirit': 0.838 },
    { name: 'Lycan', 'Void Spirit': 1.768 },
    { name: 'Magnus', 'Void Spirit': -0.627 },
    { name: 'Marci', 'Void Spirit': 1.82 },
    { name: 'Mars', 'Void Spirit': -1.918 },
    { name: 'Medusa', 'Void Spirit': 0.822 },
    { name: 'Meepo', 'Void Spirit': 5.793 },
    { name: 'Mirana', 'Void Spirit': -0.526 },
    { name: 'Monkey King', 'Void Spirit': 2.917 },
    { name: 'Morphling', 'Void Spirit': 0.281 },
    { name: 'Muerta', 'Void Spirit': 1.016 },
    { name: 'Naga Siren', 'Void Spirit': 1.498 },
    { name: "Nature's Prophet", 'Void Spirit': -0.123 },
    { name: 'Necrophos', 'Void Spirit': 1.68 },
    { name: 'Night Stalker', 'Void Spirit': 3.105 },
    { name: 'Nyx Assassin', 'Void Spirit': -0.339 },
    { name: 'Ogre Magi', 'Void Spirit': 0.192 },
    { name: 'Omniknight', 'Void Spirit': 1.562 },
    { name: 'Oracle', 'Void Spirit': 0.677 },
    { name: 'Outworld Destroyer', 'Void Spirit': 0.2 },
    { name: 'Pangolier', 'Void Spirit': 1.26 },
    { name: 'Phantom Assassin', 'Void Spirit': -0.757 },
    { name: 'Phantom Lancer', 'Void Spirit': -0.023 },
    { name: 'Phoenix', 'Void Spirit': -0.585 },
    { name: 'Primal Beast', 'Void Spirit': -0.622 },
    { name: 'Puck', 'Void Spirit': -1.042 },
    { name: 'Pudge', 'Void Spirit': -1.866 },
    { name: 'Pugna', 'Void Spirit': 0.611 },
    { name: 'Queen of Pain', 'Void Spirit': -1.565 },
    { name: 'Razor', 'Void Spirit': -1.167 },
    { name: 'Riki', 'Void Spirit': 2.154 },
    { name: 'Ringmaster', 'Void Spirit': -0.581 },
    { name: 'Rubick', 'Void Spirit': 0.245 },
    { name: 'Sand King', 'Void Spirit': -0.047 },
    { name: 'Shadow Demon', 'Void Spirit': -1.504 },
    { name: 'Shadow Fiend', 'Void Spirit': 1.313 },
    { name: 'Shadow Shaman', 'Void Spirit': 0.623 },
    { name: 'Silencer', 'Void Spirit': -0.362 },
    { name: 'Skywrath Mage', 'Void Spirit': -0.941 },
    { name: 'Slardar', 'Void Spirit': 0.788 },
    { name: 'Slark', 'Void Spirit': 0.95 },
    { name: 'Snapfire', 'Void Spirit': -0.004 },
    { name: 'Sniper', 'Void Spirit': -1.856 },
    { name: 'Spectre', 'Void Spirit': -0.133 },
    { name: 'Spirit Breaker', 'Void Spirit': -0.266 },
    { name: 'Storm Spirit', 'Void Spirit': -0.093 },
    { name: 'Sven', 'Void Spirit': 2.25 },
    { name: 'Techies', 'Void Spirit': -0.369 },
    { name: 'Templar Assassin', 'Void Spirit': 0.657 },
    { name: 'Terrorblade', 'Void Spirit': 1.301 },
    { name: 'Tidehunter', 'Void Spirit': 1.268 },
    { name: 'Timbersaw', 'Void Spirit': 0.454 },
    { name: 'Tinker', 'Void Spirit': 2.089 },
    { name: 'Tiny', 'Void Spirit': 0.836 },
    { name: 'Treant Protector', 'Void Spirit': 0.389 },
    { name: 'Troll Warlord', 'Void Spirit': 2.805 },
    { name: 'Tusk', 'Void Spirit': -0.048 },
    { name: 'Underlord', 'Void Spirit': 2.513 },
    { name: 'Undying', 'Void Spirit': 0.152 },
    { name: 'Ursa', 'Void Spirit': 0.34 },
    { name: 'Vengeful Spirit', 'Void Spirit': 0.178 },
    { name: 'Venomancer', 'Void Spirit': -0.227 },
    { name: 'Viper', 'Void Spirit': -0.453 },
    { name: 'Visage', 'Void Spirit': -0.19 },
    { name: 'Warlock', 'Void Spirit': -0.255 },
    { name: 'Weaver', 'Void Spirit': -0.666 },
    { name: 'Windranger', 'Void Spirit': -0.248 },
    { name: 'Winter Wyvern', 'Void Spirit': 1.273 },
    { name: 'Witch Doctor', 'Void Spirit': -1.072 },
    { name: 'Wraith King', 'Void Spirit': 0.589 },
    { name: 'Zeus', 'Void Spirit': -2.033 },
],
"Windranger": [
    { name: 'Abaddon', 'Windranger': -1.32 },
    { name: 'Alchemist', 'Windranger': 0.269 },
    { name: 'Ancient Apparition', 'Windranger': -1.701 },
    { name: 'Anti-Mage', 'Windranger': 0.071 },
    { name: 'Arc Warden', 'Windranger': 1.252 },
    { name: 'Axe', 'Windranger': 2.123 },
    { name: 'Bane', 'Windranger': 1.357 },
    { name: 'Batrider', 'Windranger': 0.231 },
    { name: 'Beastmaster', 'Windranger': -0.812 },
    { name: 'Bloodseeker', 'Windranger': 1.875 },
    { name: 'Bounty Hunter', 'Windranger': 0.691 },
    { name: 'Brewmaster', 'Windranger': -0.786 },
    { name: 'Bristleback', 'Windranger': 2.463 },
    { name: 'Broodmother', 'Windranger': -0.021 },
    { name: 'Centaur Warrunner', 'Windranger': 2.722 },
    { name: 'Chaos Knight', 'Windranger': -0.541 },
    { name: 'Chen', 'Windranger': 0.085 },
    { name: 'Clinkz', 'Windranger': 0.778 },
    { name: 'Clockwerk', 'Windranger': 1.028 },
    { name: 'Crystal Maiden', 'Windranger': 0.321 },
    { name: 'Dark Seer', 'Windranger': -2.451 },
    { name: 'Dark Willow', 'Windranger': 0.746 },
    { name: 'Dawnbreaker', 'Windranger': -0.876 },
    { name: 'Dazzle', 'Windranger': -0.062 },
    { name: 'Death Prophet', 'Windranger': -0.964 },
    { name: 'Disruptor', 'Windranger': -0.746 },
    { name: 'Doom', 'Windranger': -0.477 },
    { name: 'Dragon Knight', 'Windranger': -0.485 },
    { name: 'Drow Ranger', 'Windranger': 0.688 },
    { name: 'Earth Spirit', 'Windranger': 1.219 },
    { name: 'Earthshaker', 'Windranger': -0.254 },
    { name: 'Elder Titan', 'Windranger': -1.83 },
    { name: 'Ember Spirit', 'Windranger': -0.381 },
    { name: 'Enchantress', 'Windranger': -1.428 },
    { name: 'Enigma', 'Windranger': 0.768 },
    { name: 'Faceless Void', 'Windranger': 2.86 },
    { name: 'Grimstroke', 'Windranger': -0.485 },
    { name: 'Gyrocopter', 'Windranger': 0.417 },
    { name: 'Hoodwink', 'Windranger': -0.782 },
    { name: 'Huskar', 'Windranger': -1.861 },
    { name: 'Invoker', 'Windranger': 0.41 },
    { name: 'Io', 'Windranger': -0.972 },
    { name: 'Jakiro', 'Windranger': -1.334 },
    { name: 'Juggernaut', 'Windranger': -1.23 },
    { name: 'Keeper of the Light', 'Windranger': -0.391 },
    { name: 'Kunkka', 'Windranger': 0.6 },
    { name: 'Legion Commander', 'Windranger': 0.427 },
    { name: 'Leshrac', 'Windranger': -1.003 },
    { name: 'Lich', 'Windranger': -0.944 },
    { name: 'Lifestealer', 'Windranger': -1.014 },
    { name: 'Lina', 'Windranger': -1.077 },
    { name: 'Lion', 'Windranger': 0.397 },
    { name: 'Lone Druid', 'Windranger': -2.348 },
    { name: 'Luna', 'Windranger': 0.637 },
    { name: 'Lycan', 'Windranger': -0.755 },
    { name: 'Magnus', 'Windranger': -0.309 },
    { name: 'Marci', 'Windranger': -1.929 },
    { name: 'Mars', 'Windranger': 2.1 },
    { name: 'Medusa', 'Windranger': -1.849 },
    { name: 'Meepo', 'Windranger': 0.104 },
    { name: 'Mirana', 'Windranger': -0.38 },
    { name: 'Monkey King', 'Windranger': -0.752 },
    { name: 'Morphling', 'Windranger': 1.127 },
    { name: 'Muerta', 'Windranger': 0.432 },
    { name: 'Naga Siren', 'Windranger': 0.705 },
    { name: "Nature's Prophet", 'Windranger': 1.667 },
    { name: 'Necrophos', 'Windranger': 0.6 },
    { name: 'Night Stalker', 'Windranger': -1.453 },
    { name: 'Nyx Assassin', 'Windranger': 0.905 },
    { name: 'Ogre Magi', 'Windranger': 1.077 },
    { name: 'Omniknight', 'Windranger': -0.776 },
    { name: 'Oracle', 'Windranger': 0.23 },
    { name: 'Outworld Destroyer', 'Windranger': -0.461 },
    { name: 'Pangolier', 'Windranger': 0.137 },
    { name: 'Phantom Assassin', 'Windranger': -0.123 },
    { name: 'Phantom Lancer', 'Windranger': -0.429 },
    { name: 'Phoenix', 'Windranger': -0.061 },
    { name: 'Primal Beast', 'Windranger': 0.845 },
    { name: 'Puck', 'Windranger': 1.548 },
    { name: 'Pudge', 'Windranger': 0.379 },
    { name: 'Pugna', 'Windranger': 0.713 },
    { name: 'Queen of Pain', 'Windranger': 0.523 },
    { name: 'Razor', 'Windranger': 0.343 },
    { name: 'Riki', 'Windranger': 0.102 },
    { name: 'Ringmaster', 'Windranger': -0.826 },
    { name: 'Rubick', 'Windranger': 0.396 },
    { name: 'Sand King', 'Windranger': -0.559 },
    { name: 'Shadow Demon', 'Windranger': 0.892 },
    { name: 'Shadow Fiend', 'Windranger': -0.674 },
    { name: 'Shadow Shaman', 'Windranger': 0.381 },
    { name: 'Silencer', 'Windranger': -1.177 },
    { name: 'Skywrath Mage', 'Windranger': -0.392 },
    { name: 'Slardar', 'Windranger': -1.061 },
    { name: 'Slark', 'Windranger': -0.353 },
    { name: 'Snapfire', 'Windranger': -0.815 },
    { name: 'Sniper', 'Windranger': -0.225 },
    { name: 'Spectre', 'Windranger': 1.805 },
    { name: 'Spirit Breaker', 'Windranger': 1.022 },
    { name: 'Storm Spirit', 'Windranger': -0.658 },
    { name: 'Sven', 'Windranger': -0.311 },
    { name: 'Techies', 'Windranger': 1.482 },
    { name: 'Templar Assassin', 'Windranger': 0.436 },
    { name: 'Terrorblade', 'Windranger': -0.294 },
    { name: 'Tidehunter', 'Windranger': -0.263 },
    { name: 'Timbersaw', 'Windranger': -1.202 },
    { name: 'Tinker', 'Windranger': -1.052 },
    { name: 'Tiny', 'Windranger': 1.65 },
    { name: 'Treant Protector', 'Windranger': 0.247 },
    { name: 'Troll Warlord', 'Windranger': -2.191 },
    { name: 'Tusk', 'Windranger': 0.759 },
    { name: 'Underlord', 'Windranger': -1.425 },
    { name: 'Undying', 'Windranger': -0.386 },
    { name: 'Ursa', 'Windranger': -1.685 },
    { name: 'Vengeful Spirit', 'Windranger': -0.219 },
    { name: 'Venomancer', 'Windranger': -2.097 },
    { name: 'Viper', 'Windranger': -3.077 },
    { name: 'Visage', 'Windranger': -2.144 },
    { name: 'Void Spirit', 'Windranger': 0.016 },
    { name: 'Warlock', 'Windranger': -1.788 },
    { name: 'Weaver', 'Windranger': 0.848 },
    { name: 'Winter Wyvern', 'Windranger': -0.063 },
    { name: 'Witch Doctor', 'Windranger': -0.932 },
    { name: 'Wraith King', 'Windranger': -0.103 },
    { name: 'Zeus', 'Windranger': 0.849 },
],
"Winter Wyvern": [
    { name: 'Abaddon', 'Winter Wyvern': 0.36 },
    { name: 'Alchemist', 'Winter Wyvern': -0.867 },
    { name: 'Ancient Apparition', 'Winter Wyvern': 2.112 },
    { name: 'Anti-Mage', 'Winter Wyvern': 2.867 },
    { name: 'Arc Warden', 'Winter Wyvern': 0.326 },
    { name: 'Axe', 'Winter Wyvern': 0.553 },
    { name: 'Bane', 'Winter Wyvern': 0.385 },
    { name: 'Batrider', 'Winter Wyvern': -1.509 },
    { name: 'Beastmaster', 'Winter Wyvern': -0.245 },
    { name: 'Bloodseeker', 'Winter Wyvern': -0.204 },
    { name: 'Bounty Hunter', 'Winter Wyvern': 0.754 },
    { name: 'Brewmaster', 'Winter Wyvern': -0.706 },
    { name: 'Bristleback', 'Winter Wyvern': -0.741 },
    { name: 'Broodmother', 'Winter Wyvern': -3.121 },
    { name: 'Centaur Warrunner', 'Winter Wyvern': 0.689 },
    { name: 'Chaos Knight', 'Winter Wyvern': -1.531 },
    { name: 'Chen', 'Winter Wyvern': 0.965 },
    { name: 'Clinkz', 'Winter Wyvern': 0.389 },
    { name: 'Clockwerk', 'Winter Wyvern': -0.335 },
    { name: 'Crystal Maiden', 'Winter Wyvern': 0.492 },
    { name: 'Dark Seer', 'Winter Wyvern': -1.153 },
    { name: 'Dark Willow', 'Winter Wyvern': -0.321 },
    { name: 'Dawnbreaker', 'Winter Wyvern': -1.461 },
    { name: 'Dazzle', 'Winter Wyvern': -0.568 },
    { name: 'Death Prophet', 'Winter Wyvern': 0.459 },
    { name: 'Disruptor', 'Winter Wyvern': -0.969 },
    { name: 'Doom', 'Winter Wyvern': 1.458 },
    { name: 'Dragon Knight', 'Winter Wyvern': -1.435 },
    { name: 'Drow Ranger', 'Winter Wyvern': -0.605 },
    { name: 'Earth Spirit', 'Winter Wyvern': 0.178 },
    { name: 'Earthshaker', 'Winter Wyvern': -0.499 },
    { name: 'Elder Titan', 'Winter Wyvern': -1.577 },
    { name: 'Ember Spirit', 'Winter Wyvern': -0.146 },
    { name: 'Enchantress', 'Winter Wyvern': 0.821 },
    { name: 'Enigma', 'Winter Wyvern': -1.459 },
    { name: 'Faceless Void', 'Winter Wyvern': 0.124 },
    { name: 'Grimstroke', 'Winter Wyvern': 0.403 },
    { name: 'Gyrocopter', 'Winter Wyvern': 1.5 },
    { name: 'Hoodwink', 'Winter Wyvern': -0.123 },
    { name: 'Huskar', 'Winter Wyvern': 2.037 },
    { name: 'Invoker', 'Winter Wyvern': -0.939 },
    { name: 'Io', 'Winter Wyvern': -1.208 },
    { name: 'Jakiro', 'Winter Wyvern': -0.814 },
    { name: 'Juggernaut', 'Winter Wyvern': 0.139 },
    { name: 'Keeper of the Light', 'Winter Wyvern': 0.654 },
    { name: 'Kunkka', 'Winter Wyvern': -1.392 },
    { name: 'Legion Commander', 'Winter Wyvern': -2.776 },
    { name: 'Leshrac', 'Winter Wyvern': 1.018 },
    { name: 'Lich', 'Winter Wyvern': 0.829 },
    { name: 'Lifestealer', 'Winter Wyvern': -1.322 },
    { name: 'Lina', 'Winter Wyvern': -1.344 },
    { name: 'Lion', 'Winter Wyvern': -1.235 },
    { name: 'Lone Druid', 'Winter Wyvern': -4.626 },
    { name: 'Luna', 'Winter Wyvern': 0.823 },
    { name: 'Lycan', 'Winter Wyvern': -1.197 },
    { name: 'Magnus', 'Winter Wyvern': -1.495 },
    { name: 'Marci', 'Winter Wyvern': -1.987 },
    { name: 'Mars', 'Winter Wyvern': 0.357 },
    { name: 'Medusa', 'Winter Wyvern': -2.68 },
    { name: 'Meepo', 'Winter Wyvern': -6.853 },
    { name: 'Mirana', 'Winter Wyvern': -0.715 },
    { name: 'Monkey King', 'Winter Wyvern': -0.425 },
    { name: 'Morphling', 'Winter Wyvern': 0.789 },
    { name: 'Muerta', 'Winter Wyvern': 0.901 },
    { name: 'Naga Siren', 'Winter Wyvern': 0.279 },
    { name: "Nature's Prophet", 'Winter Wyvern': -0.11 },
    { name: 'Necrophos', 'Winter Wyvern': 2.46 },
    { name: 'Night Stalker', 'Winter Wyvern': -1.122 },
    { name: 'Nyx Assassin', 'Winter Wyvern': -0.506 },
    { name: 'Ogre Magi', 'Winter Wyvern': -0.392 },
    { name: 'Omniknight', 'Winter Wyvern': -0.984 },
    { name: 'Oracle', 'Winter Wyvern': -0.673 },
    { name: 'Outworld Destroyer', 'Winter Wyvern': 1.052 },
    { name: 'Pangolier', 'Winter Wyvern': -2.727 },
    { name: 'Phantom Assassin', 'Winter Wyvern': 1.62 },
    { name: 'Phantom Lancer', 'Winter Wyvern': 0.826 },
    { name: 'Phoenix', 'Winter Wyvern': 1.085 },
    { name: 'Primal Beast', 'Winter Wyvern': 0.255 },
    { name: 'Puck', 'Winter Wyvern': 1.073 },
    { name: 'Pudge', 'Winter Wyvern': 1.124 },
    { name: 'Pugna', 'Winter Wyvern': 2.204 },
    { name: 'Queen of Pain', 'Winter Wyvern': 1.326 },
    { name: 'Razor', 'Winter Wyvern': 0.929 },
    { name: 'Riki', 'Winter Wyvern': 0.201 },
    { name: 'Ringmaster', 'Winter Wyvern': 0.05 },
    { name: 'Rubick', 'Winter Wyvern': -0.657 },
    { name: 'Sand King', 'Winter Wyvern': 3.148 },
    { name: 'Shadow Demon', 'Winter Wyvern': -0.325 },
    { name: 'Shadow Fiend', 'Winter Wyvern': -0.878 },
    { name: 'Shadow Shaman', 'Winter Wyvern': -0.012 },
    { name: 'Silencer', 'Winter Wyvern': 0.627 },
    { name: 'Skywrath Mage', 'Winter Wyvern': 0.687 },
    { name: 'Slardar', 'Winter Wyvern': -0.734 },
    { name: 'Slark', 'Winter Wyvern': 1.689 },
    { name: 'Snapfire', 'Winter Wyvern': -0.074 },
    { name: 'Sniper', 'Winter Wyvern': 1.487 },
    { name: 'Spectre', 'Winter Wyvern': 1.192 },
    { name: 'Spirit Breaker', 'Winter Wyvern': -0.874 },
    { name: 'Storm Spirit', 'Winter Wyvern': 1.245 },
    { name: 'Sven', 'Winter Wyvern': -2.433 },
    { name: 'Techies', 'Winter Wyvern': 0.947 },
    { name: 'Templar Assassin', 'Winter Wyvern': -0.468 },
    { name: 'Terrorblade', 'Winter Wyvern': -1.929 },
    { name: 'Tidehunter', 'Winter Wyvern': 1.04 },
    { name: 'Timbersaw', 'Winter Wyvern': 3.477 },
    { name: 'Tinker', 'Winter Wyvern': -0.201 },
    { name: 'Tiny', 'Winter Wyvern': -0.89 },
    { name: 'Treant Protector', 'Winter Wyvern': 0.026 },
    { name: 'Troll Warlord', 'Winter Wyvern': -2.772 },
    { name: 'Tusk', 'Winter Wyvern': -1.0 },
    { name: 'Underlord', 'Winter Wyvern': -0.218 },
    { name: 'Undying', 'Winter Wyvern': 1.899 },
    { name: 'Ursa', 'Winter Wyvern': -1.343 },
    { name: 'Vengeful Spirit', 'Winter Wyvern': -1.204 },
    { name: 'Venomancer', 'Winter Wyvern': 0.842 },
    { name: 'Viper', 'Winter Wyvern': 1.064 },
    { name: 'Visage', 'Winter Wyvern': -2.085 },
    { name: 'Void Spirit', 'Winter Wyvern': -1.266 },
    { name: 'Warlock', 'Winter Wyvern': 0.906 },
    { name: 'Weaver', 'Winter Wyvern': 1.674 },
    { name: 'Windranger', 'Winter Wyvern': -0.027 },
    { name: 'Witch Doctor', 'Winter Wyvern': 0.26 },
    { name: 'Wraith King', 'Winter Wyvern': -1.248 },
    { name: 'Zeus', 'Winter Wyvern': 0.794 },
],
}


function updateTableData(selectedItems, ownComfortHeroes, oppositeComfortHeroes, isEnemyTable, setColumns, setData) {
    const nameColumn = {
        name: 'Counter',
        selector: row => row.name,
        sortable: true,
    };

    const comfortColumn = {
        name: 'Comfort',
        selector: row => row.comfort || '', // Add comfort emoji if available
        sortable: true,
    };

    const grandTotalColumn = {
        name: 'Grand Total',
        selector: row => row.grandTotal?.toFixed(3) || '0.000', // Round to 3 decimal places, fallback to 0
        sortable: true,
        sortFunction: (a, b) => b.grandTotal - a.grandTotal // Custom sorting function
    };
    
    // Ensure that these base columns are always present
    let selectedColumns = [nameColumn, comfortColumn, grandTotalColumn];

    if (selectedItems.length > 0) {
        // Filter out any undefined columns before adding them to selectedColumns
        const heroColumns = selectedItems
            .map(item => allColumns[item.value])
            .filter(column => column !== undefined);

        selectedColumns = [nameColumn, comfortColumn, ...heroColumns, grandTotalColumn];
    }

    setColumns(selectedColumns);

    if (selectedItems.length > 0) {
        const heroMap = {};
        selectedItems.forEach(item => {
            if (allData[item.value]) {
                allData[item.value].forEach(row => {
                    if (!heroMap[row.name]) {
                        heroMap[row.name] = { name: row.name, grandTotal: 0, comfort: '' };
                    }
                });
            }
        });

        selectedItems.forEach(item => {
            if (allData[item.value]) {
                allData[item.value].forEach((row) => {
                    if (heroMap[row.name]) {
                        const value = row.name === item.value ? 0 : row[item.value];
                        heroMap[row.name][item.value] = value;
                        heroMap[row.name].grandTotal = (heroMap[row.name].grandTotal || 0) + value;
                    }
                });
            }
        });

        Object.keys(heroMap).forEach(key => {
            let comfortEmojis = '';
            ownComfortHeroes.forEach(hero => {
                if (key === hero.value) {
                    comfortEmojis += isEnemyTable ? '😈' : '😊'; // Own comfort emoji
                }
            });
            oppositeComfortHeroes.forEach(hero => {
                if (key === hero.value) {
                    comfortEmojis += isEnemyTable ? '😊' : '😈'; // Opposite comfort emoji
                }
            });
            heroMap[key].comfort = comfortEmojis; // Assign the concatenated emojis to the comfort column
        });

        const selectedData = Object.values(heroMap);
        selectedData.sort((a, b) => b.grandTotal - a.grandTotal);

        setData(selectedData);
    } else {
        setData([]);
    }
}

function SelectTable({ onComfortSelect, ownComfortHeroes, oppositeComfortHeroes, isEnemyTable, urlParam }) {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    
    useEffect(() => {
        // Initialize selected options from URL parameters
        const initialComfort = searchParams.get(`${urlParam}Comfort`)?.split(',') || [];
        const initialPicks = searchParams.get(`${urlParam}Picks`)?.split(',') || [];

        // Map the values to the format expected by the Select component
        const initialComfortOptions = initialComfort.map(value => ({ value, label: value }));
        const initialPickOptions = initialPicks.map(value => ({ value, label: value }));

        // Set the initial values for the selects
        onComfortSelect(initialComfortOptions);
        setSelectedOptions(initialPickOptions);
    }, [searchParams, onComfortSelect, urlParam]);

    useEffect(() => {
        updateTableData(selectedOptions, ownComfortHeroes, oppositeComfortHeroes, isEnemyTable, setColumns, setData);
    }, [ownComfortHeroes, oppositeComfortHeroes, selectedOptions, isEnemyTable]);

    function onSelect(selectedItems) {
        setSelectedOptions(selectedItems);
        const pickValues = selectedItems.map(item => item.value);
        searchParams.set(`${urlParam}Picks`, pickValues.join(','));
        setSearchParams(searchParams);
    }


    return (
        <div className="">
            <div>
                <Select
                    defaultValue={searchParams.get(`${urlParam}Comfort`)?.split(',').map(value => ({ value, label: value })) || []}
                    isMulti
                    onChange={selectedItems => {
                        onComfortSelect(selectedItems);
                        const comfortValues = selectedItems.map(item => item.value);
                        searchParams.set(`${urlParam}Comfort`, comfortValues.join(','));
                        setSearchParams(searchParams);
                    }}
                    name="comfortSelect"
                    options={options}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Comfort Heroes..."
                />
            </div>
            <div>
                <Select
                    defaultValue={searchParams.get(`${urlParam}Picks`)?.split(',').map(value => ({ value, label: value })) || []}
                    isMulti
                    onChange = {onSelect}
                    name="pickSelect"
                    options={options}
                    className="basic-multi-select"
                    classNamePrefix="select" 
                    placeholder="Select Picked Heroes..."
                />
            </div>
            <div>
                <DataTable
                columns={columns}
                data={data}
                defaultSortField="Grand Total" // Sort by Grand Total
                defaultSortAsc={false} // Descending order
                />
            </div>
        </div>
    );
}


function DualSelectTable() {
    const [enemyComfortHeroes, setEnemyComfortHeroes] = useState([]);
    const [allyComfortHeroes, setAllyComfortHeroes] = useState([]);
    const [enemyNotes, setEnemyNotes] = useState('');  // State for enemy notes
    const [allyNotes, setAllyNotes] = useState('');    // State for ally notes

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ flex: 1, marginRight: '10px' }}>
                <h2>Enemy Team Picks</h2>
                <textarea 
                    value={enemyNotes} 
                    onChange={(e) => setEnemyNotes(e.target.value)} 
                    placeholder="Write notes for Enemy Team..." 
                    style={{ width: '100%', marginBottom: '10px' }} 
                />
                <SelectTable 
                    onComfortSelect={setEnemyComfortHeroes} // Affects Enemy table
                    ownComfortHeroes={enemyComfortHeroes} // Enemy comfort affects Enemy table
                    oppositeComfortHeroes={allyComfortHeroes} // Ally comfort affects Enemy table
                    isEnemyTable={true} // Flag to indicate it's the enemy table
                    urlParam="enemy"
                />
            </div>
            <div style={{ flex: 1, marginLeft: '10px' }}>
                <h2>Ally Team Picks</h2>
                <textarea 
                    value={allyNotes} 
                    onChange={(e) => setAllyNotes(e.target.value)} 
                    placeholder="Write notes for Ally Team..." 
                    style={{ width: '100%', marginBottom: '10px' }} 
                />
                <SelectTable 
                    onComfortSelect={setAllyComfortHeroes} // Affects Ally table
                    ownComfortHeroes={allyComfortHeroes} // Ally comfort affects Ally table
                    oppositeComfortHeroes={enemyComfortHeroes} // Enemy comfort affects Ally table
                    isEnemyTable={false} // Flag to indicate it's the ally table
                    urlParam="ally"
                />
            </div>
        </div>
    );
}

export default DualSelectTable;